<div fxLayout="column" class="bg-content-global" *ngIf="isLoading == false">
    <div class="bg-content-detail-global" fxLayout="row">
        <a fxFlex="5%" style="cursor: pointer; text-decoration: none" href="post-personal"><img
                src="assets/images/icons/icon-27.svg"></a>
        <div fxFlex fxLayout="column">
            <p fxFlex="30px" class="title-page-global mt-4">{{dataInfoPost.title}}</p>
            <div fxFlex fxLayout="row">
                <div fxFlex fxLayout="column">
                    <div fxFlex="55px" fxLayoutAlign="space-between none">
                        <span class="show-more-limit" style="background: #FF8038">Cá nhân</span>
                    </div>
                    <div fxFlex="70px" fxLayout="row" fxLayoutAlign="start none">
                        <div fxFlex="25%" fxLayout="column" fxLayoutAlign="space-between none">
                            <span class="sub-title">Từ: {{fullName}}</span>
                            <div fxFlex="40px" class="mt-1">
                            <span style="font-style: normal;font-weight: normal;font-size: 14px;line-height: 17px;"
                              class="mt-1"> {{parseMinute(dataInfoPost.createdAtEpochTime)}}</span>
                            </div>
                        </div>

                    </div>

                    <div fxFlex>
                        <div fxLayout="row" fxLayoutAlign="space-between none">
                            <img src="{{dataInfoPost.avatar}}" class="avatar-post mr-3">
                            <p style="font-style: normal;word-break: break-word;font-weight: 500;font-size: 18px;line-height: 22px;letter-spacing: -0.28px;color: #000000;">
                                {{dataInfoPost.summaryContent}}
                            </p>
                        </div>
                        <p [innerHTML]="dataInfoPost.content" class="mt-2 pb-5"
                           style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;letter-spacing: -0.28px;color: #000000;opacity: 0.8;border-bottom: solid 1px #9a9a9a">
                        </p>
                    </div>
                </div>
                <div fxFlex="1.5%"></div>
                <div fxFlex="30.5%" fxLayout="column" class="comment-post">
                    <div fxFlex="35px" class="comment-post-header">
                        <span>Bình luận</span>
                    </div>
                    <div fxFlex="30px" fxLayout="row" fxLayoutAlign="space-between none" class="comment-post-sidebar">
                        <div><img src="assets/images/icons/icon-26.svg"><span
                                class="total-comment-post">4 bình luận</span></div>
                        <span class="view-all-comment-post">Xem tất cả bình luận</span>
                    </div>
                    <div fxFlex class="box-comment-post">
                        <div *ngFor="let card of listCommentParent, let i = index" fxLayout="row"
                             fxLayoutAlign="start none"
                             class="item-post-comment">
                            <div fxFlex="70px">
                                <div class="img-module ml-2">
                                    <img src="https://cdn.tgdd.vn/Files/2019/01/01/1142002/s8ori_800x600.jpg">
                                </div>
                            </div>
                            <br>
                            <div fxFlex class="mt-2">
                                <div class="comment-body">
                                    <div class="custom-card-parent">
                                        <span class="item-post-comment-title ">{{card.fullName}}</span>
                                        <p *ngIf="card.isEditable === false" class="item-post-comment-content "
                                           style="text-overflow: ellipsis;"> {{card.content}}
                                            <!--                                            {{card.id}}-->
                                        </p>
                                        <ng-container *ngIf="card.isEditable === true">
                                            <input style="width: 100%" class="form-control item-post-comment-content"
                                                   [value]="card.content" (keypress)="updateComment(card, $event)">
                                            <img class="remove-comment" src="assets/images/svg/remove.svg" alt=""
                                                 (click)="cancelComment(i, 'listCommentParent')">
                                        </ng-container>
                                        <br>
                                    </div>
                                    <div class="item-post-comment-profile mt-2" fxLayout="row"
                                         fxLayoutAlign="space-between none">
                                        <div class="custom-reply" (click)="reply(i)">Trả lời</div>
                                        <div>{{card.createdAtEpochTime | timeago}}</div>

                                        <div fxLayoutAlign="end center" class="custom-menu-comment">
                                            <span [matMenuTriggerFor]="menu" class="btn-menu-action"
                                                  style="cursor: pointer">
                                                <mat-icon>more_horiz</mat-icon>
                                            </span>
                                            <mat-menu #menu="matMenu" class="menu-more-global">
                                                <button mat-menu-item
                                                        (click)="editComment(i, 'listCommentParent')">Chỉnh sửa
                                                </button>
                                                <button mat-menu-item class="text-danger"
                                                        (click)="removeComment(card.id)">Xoá
                                                </button>
                                                <!--                                <button mat-menu-item class="text-danger" *ngIf="item.status === 3" (click)="confirmCancelSend(item.title)">Hủy</button>-->
                                            </mat-menu>
                                        </div>
                                    </div>
                                    <ng-container *ngFor="let cardChild of listCommentChild, let keyChild = index">
                                        <div style="width: 100%" fxLayoutAlign="end center"
                                             *ngIf="cardChild.parentCommentId === card.id">
                                            <div fxLayoutAlign="start center" class="comment-child">
                                                <div class="col-md-12">
                                                    <span class="item-post-comment-title">{{cardChild.fullName}}</span>
                                                    <p *ngIf="cardChild.isEditable === false"
                                                       class="item-post-comment-content "
                                                       style="text-overflow: ellipsis;">
                                                        {{cardChild.content}}
                                                    </p>
                                                    <ng-container *ngIf="cardChild.isEditable === true">
                                                        <input style="width: 100%"
                                                               class="form-control item-post-comment-content"
                                                               [value]="cardChild.content"
                                                               (keypress)="updateComment(cardChild, $event)">
                                                        <img class="remove-comment" src="assets/images/svg/remove.svg"
                                                             alt=""
                                                             (click)="cancelComment(keyChild, 'listCommentChild')">
                                                    </ng-container>
                                                    <br>
                                                </div>

                                            </div>
                                        </div>
                                        <div *ngIf="cardChild.parentCommentId === card.id">
                                            <span style="position: relative; left: 50px; width: 30%">{{cardChild.createdAtEpochTime | timeago}}</span>
                                            <div fxLayoutAlign="end center" style="float: right; position: relative">
                                                    <span [matMenuTriggerFor]="menu"
                                                          style="cursor: pointer;">
                                                        <mat-icon>more_horiz</mat-icon>
                                                    </span>
                                                <mat-menu #menu="matMenu" class="menu-more-global">
                                                    <button mat-menu-item
                                                            (click)="editComment(keyChild, 'listCommentChild')">Chỉnh
                                                        sửa
                                                    </button>
                                                    <button mat-menu-item class="text-danger"
                                                            (click)="removeComment(cardChild.id)">Xoá
                                                    </button>
                                                    <!--                                <button mat-menu-item class="text-danger" *ngIf="item.status === 3" (click)="confirmCancelSend(item.title)">Hủy</button>-->
                                                </mat-menu>
                                            </div>
                                        </div>

                                    </ng-container>

                                    <div class="custom-input-child d-flex" fxLayoutAlign="end center" #commentChild>
                                        <input (keypress)="sentComment(card.id, $event)"
                                               *ngIf="card.isChecked === true"
                                               class="form-control input-field-comment-post" style="width: 90%"
                                               placeholder="Nhập bình luận">
                                        <span class="pointer" (click)="cancelComment(i, 'reply')"
                                              *ngIf="card.isChecked === true"> &nbsp;&nbsp;Hủy</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start none" class="footer-comment-post">
                            <div fxFlex="15%">
                                <div class="img-module ml-2">
                                    <img src="https://cdn.tgdd.vn/Files/2019/01/01/1142002/s8ori_800x600.jpg">
                                </div>
                            </div>
                            <div fxFlex="75%" class="mt-3">
                                <input #commentParent (keypress)="sentComment(null, $event)"
                                       class="form-control input-field-comment-post" placeholder="Nhập bình luận">
                            </div>
                            <div fxFlex="9%" class="mt-3">
                                <img src="assets/images/icons/icon-28.svg" class="btn-submit-comment-post">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="5%"></div>
    </div>
</div>
