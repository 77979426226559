export const lang = {
    title_manager_list: 'Danh sách năm học',
    title_detail: 'Chi tiết năm học',
    total_grade: 'Tổng số khối học',

    tabs: {
        info_school_year: 'Thông tin năm học',
        list_customer: 'Danh sách khách hàng',
    },

    label_manager_list: {
        start_date: 'Ngày bắt đầu',
        end_date: 'Ngày kết thúc',
        status: 'Trạng thái',
        active: 'Kích hoạt',
        pass_school_year: 'Năm học đã qua',
        next_school_year: 'Năm học tới',
        current_school_year: 'Năm học hiện tại',
        customer: 'Khách hàng'
    },

    menu_manager_list: {
        software_module_declaration: 'Khai báo phân hệ phần mềm',
        declare_subject: 'Khai báo môn học',
        declare_grade: 'Khai báo khối',
        declare_school_years: 'Khai báo môn học',
        declare_vertical_units: 'Khai báo đơn vị ngành dọc',
        administrative_unit_declaration: 'Khai báo đơn vị hành chính',
    },

    button: {
        btn_create_school_year: 'Thêm năm học  mới',
        btn_edit_info_school_year: 'Chỉnh sửa thông tin năm học',
        btn_delete_school_year: 'Xóa năm học',
        btn_add: 'Thêm mới',
        btn_edit: 'Chỉnh sửa',
        btn_close: 'Hủy',
        btn_customer: 'Thêm khách hàng',
        btn_edit_info: 'Chỉnh sửa thông tin',
        btn_add_customer: 'Thêm khách hàng',
    },
    message: {
        dialog_delete_school_year: 'Bạn đang thực hiện xóa năm học',
        confirm_delete: 'Xác nhận xóa ?',
        success_delete: 'Xóa thành công',
        error_delete : 'Xóa thất bại',
    },

    form: {
        title_add: 'Thêm năm học',
        title_edit: 'Chỉnh sửa năm học',
        label: {
            title_info_basic: 'Thông tin cơ bản',
            full_name: 'Tên năm học',
            code: 'Mã năm học',
            start_date: 'Ngày bắt đầu',
            end_date: 'Ngày kết thúc',
            status: 'Trạng thái',
            active: 'Kích hoạt',
            status_school_year: 'Trạng thái năm học',
            current: 'Hiện tại',
        },
        placeholder: {
            full_name: 'Nhập tên năm học',
            code: 'Nhập mã năm học',
        }
    },

    validate_form: {
        full_name_required: 'Bạn chưa điền tên năm học',
        full_name_limit_chart: 'Phải ít nhất có 2 ký tự trở lên và không quá 250 ký tự (Không được phép có ký tự đặc biệt)',
        code_required: 'Bàn nhập mã code',
        code_limit_chart: 'Bạn phải nhập ít nhất 2 ký tự trở lên và không vượt quá 50 ký tự (Không có ký tự đặc biệt)'
    },

    tab_list_customer: {
        title: 'Danh sách khách hàng áp dụng',
        stt: 'STT',
        full_name: 'Tên khách hàng',
        code: 'Mã khách hàng',
        domain: 'Tên miền',
        phone: 'Số điện thoại',
        email: 'Email',
        status: 'Trạng thái',
        active: 'kích hoạt',
        tools: 'Công cụ',
    },

    dialog_add_customer: {
        title_add: 'Thêm khách hàng',
        list_customer: 'Danh sách khách hàng',
        choose_all: 'Chọn tất cả',
        btn_add: 'Thêm',
        btn_close: 'Hủy',
        placeholder_search: 'Tim kiếm hoặc mã khách hàng'
    }
}
