<h2 mat-dialog-title class="text-center">Chỉnh sửa mật khẩu quản trị - {{data?.tenant?.fullname}}</h2>

<form [formGroup]="tenantAdminForm" class="form" (ngSubmit)="onClickSubmit();" enctype="multipart/form-data">
    <div class="form-group col-md-12 row row-global">
        <label class="col-md-3">Tài khoản</label>
        <div class="col-md-8 pl-0 pr-0">
            <p>{{data?.tenant?.tenantAdmin}}</p>
        </div>
    </div>
    <div class="form-group col-md-12 row row-global">
        <label class="col-md-3">Mật khẩu mới<span class="text-danger"> (*)</span></label>

        <div class="col-md-8 pl-0 pr-0">
            <input [type]="hidePassword ? 'password' : 'text'" class="form-control input-field-global"
                placeholder="Nhập mật khẩu" formControlName="tenantAdminPassword" id="password-field"
                placeholder="Nhập mật khẩu của bạn">
            <mat-icon aria-hidden="false" class="field-icon toggle-password" aria-label="Example home icon"
                (click)="hidePassword = !hidePassword">
                {{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            <div
                *ngIf="this.tenantAdminForm.get('tenantAdminPassword').invalid && (submitted || this.tenantAdminForm.get('tenantAdminPassword').dirty || this.tenantAdminForm.get('tenantAdminPassword').touched)">
                <mat-error>
                    <p class="error-message" *ngIf="this.tenantAdminForm.get('tenantAdminPassword').errors.required">
                        Mật khẩu là bắt buộc
                    </p>
                </mat-error>
                <mat-error>
                    <p class="error-message"
                        *ngIf="this.tenantAdminForm.get('tenantAdminPassword').errors.minlength || this.tenantAdminForm.get('tenantAdminPassword').errors.maxlength">
                        Mật khẩu từ 6-50 ký tự
                    </p>
                </mat-error>
                <mat-error>
                    <p class="error-message" *ngIf="this.tenantAdminForm.get('tenantAdminPassword').errors.pattern">
                        Mật khẩu chưa đúng định dạng có ít nhất 1 ký tự chữ thường và 1 ký tự số.
                    </p>
                </mat-error>
                
            </div>
        </div>
    </div>
    <div class="form-group col-md-12 row row-global">
        <label class="col-md-3">Nhập lại mật khẩu<span class="text-danger"> (*)</span></label>

        <div class="col-md-8 pl-0 pr-0">
            <input [type]="confirm_hidePassword ? 'password' : 'text'" class="form-control input-field-global"
                placeholder="Nhập mật khẩu" formControlName="confirm_tenantAdminPassword" id="password-field"
                placeholder="Nhập mật khẩu của bạn">
            <mat-icon aria-hidden="false" class="field-icon toggle-password" aria-label="Example home icon"
                (click)="confirm_hidePassword = !confirm_hidePassword">
                {{confirm_hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            <div
                *ngIf="this.tenantAdminForm.get('confirm_tenantAdminPassword').invalid && (submitted || this.tenantAdminForm.get('confirm_tenantAdminPassword').dirty || this.tenantAdminForm.get('confirm_tenantAdminPassword').touched)">
                <mat-error>
                    <p class="error-message"
                        *ngIf="this.tenantAdminForm.get('confirm_tenantAdminPassword').errors.required">
                        Mật khẩu là bắt buộc
                    </p>
                </mat-error>
                <mat-error>
                    <p class="error-message"
                        *ngIf="this.tenantAdminForm.get('confirm_tenantAdminPassword').errors.minlength || this.tenantAdminForm.get('confirm_tenantAdminPassword').errors.maxlength">
                        Mật khẩu từ 6-50 ký tự
                    </p>
                </mat-error>
                <mat-error>
                    <p class="error-message"
                        *ngIf="this.tenantAdminForm.get('confirm_tenantAdminPassword').errors.pattern">
                        Mật khẩu chưa đúng định dạng có ít nhất 1 ký tự chữ thường và 1 ký tự số.
                    </p>
                </mat-error>
                <mat-error>
                    <p class="error-message" *ngIf="this.tenantAdminForm.get('confirm_tenantAdminPassword').errors.mismatch">
                        Mật khẩu nhập lại chưa khớp
                    </p>
                </mat-error>
            </div>
        </div>
    </div>

    <!--Thông tin cơ bản-->

    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button>Huỷ</button>
        <!-- <button mat-button type="submit" mat-raised-button color="primary" *ngIf="hideButtonAdd" [disabled]="!tenantAdminForm.valid">Add</button> -->
        <!-- <button mat-button type="submit" mat-raised-button color="primary" *ngIf="!hideButtonAdd" [disabled]="!tenantAdminForm.valid">Update</button> -->
        <button mat-button mat-raised-button color="primary" type="submit">Chỉnh sửa</button>
    </mat-dialog-actions>
</form>

<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
</div>