<div fxLayout="column" class="bg-content-global" *ngIf="!isLoading">
    <div fxLayout="column" fxFlex class="content-declare">
        <div fxFlex="3rem" fxLayout="row" fxLayoutAlign="start start">
            <div>
                <h4 class="title-page-global">{{ dataDetailEducation3.name }}</h4>
            </div>
        </div>
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div fxFlex fxLayout="column" class="content__education-lv1">
            <div class="info__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <h4 class="title__education-lv1">Thông tin cơ bản</h4>
                    <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">
                    <button class="btn-add-global" mat-raised-button (click)="clickEditEducationLv3(dataDetailEducation3);"><mat-icon>edit</mat-icon>Chỉnh sửa thông tin</button>
                    </ng-template>
                </div>
                <div class="form__info-education-lv1 row">
                    <div fxLayout="row" fxLayoutAlign="center center" class="col-md-2">
                        <div class="img__info-education-lv1">
                            <img src="https://scontent.fhan2-5.fna.fbcdn.net/v/t1.0-9/126490683_1856067191217227_582269962890364399_n.jpg?_nc_cat=109&ccb=2&_nc_sid=8bfeb9&_nc_ohc=ZOwMdTsVYugAX_d-q0M&_nc_oc=AQkiI2TbBfx5lNPwYoeZgtKP8bwTjTpypqsSMi8eeXPy4lQCO8fbY0buFWbbhBix6e0&_nc_ht=scontent.fhan2-5.fna&oh=1a492bf2a04d1d4910f09a6db80a5aef&oe=5FE39391"
                                alt="">
                        </div>
                    </div>
                    <div class="col-md-4 row custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Tên trường</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input [value]="dataDetailEducation3.name" [disabled]="true" type="text" class="form-control input-field-global" placeholder="Nhập tên sở">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trực thuộc sở</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailEducation3.parent_code_lv1" [disabled]="true" class="form-control input-field-global" placeholder="Nhập trực thuộc sở">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Thứ tự sắp xếp</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" [value]="dataDetailEducation3.sort" [disabled]="true" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4 offset-1 custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Mã</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailEducation3.code" [disabled]="true" class="form-control input-field-global" placeholder="Nhập mã">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trực thuộc phòng</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailEducation3.parent_code" [disabled]="true" class="form-control input-field-global" placeholder="Nhập trực thuộc phòng">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trạng thái</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <select class="form-control input-field-global" [disabled]="true">
                                    <option [value]="0" [selected]="dataDetailEducation3.status == 0 ? true : false" class="custom-text-option">Chưa kích hoạt</option>
                                    <option [value]="1" [selected]="dataDetailEducation3.status == 1 ? true : false" class="custom-text-option">Kích hoạt</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>