import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';
import { REGEX_CODE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-form-administrative-lv2',
  templateUrl: './dialog-form-administrative-lv2.component.html',
  styleUrls: ['./dialog-form-administrative-lv2.component.scss']
})
export class DialogFormAdministrativeLv2Component implements OnInit {
  public dataEducation: FormGroup;
  urlImage: SafeResourceUrl;
  selectedFile: File
  checkOption:number = 1;

  public id:any;
  public fullname:number;
  public code:number;
  public order:number;
  public parent:number;
  public status:number;
  public logo:string;

  hideButtonAdd:boolean;

  panelOpenState = true;
  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private dialogref: MatDialogRef<DialogFormAdministrativeLv2Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    private administrativeUnitService:AdministrativeUnitService
  ) { }
  
  ngOnInit(): void {
    this.urlImage = this.data.education ? this.data.education.logo : '/assets/images/png/avatar.png';
    this.data?.status == "update" ? this.hideButtonAdd = false : this.hideButtonAdd = true;
    this.dataEducation = this.fb.group({
      id: [this.data?.education?.id ? this.data.education.id : this.id],
      fullname: [this.data?.education?.fullname ? this.data.education.fullname : this.fullname,[Validators.required]],
      code: [this.data?.education?.code ? this.data.education.code : this.data.code,[Validators.required,Validators.pattern(REGEX_CODE)]],
      order:  [this.data?.education?.indexOrder ? Number(this.data.education.indexOrder) : 0,[Validators.required]],
      parent:  [this.data?.education?.cityId ? this.data.education.cityId : this.data.parent.name,[Validators.required]],
      logo:  [this.data?.education?.logo ? this.data.education.logo : this.logo],
      status: [this.data?.education?.status ? this.data.education.status : 0]
    });
  }

  onClickSubmit(value):void{
    if(this.data.status == "add"){
      let dataInput = {
        fullname: value.fullname,
        code: value.code,
        logo: "string",
        type: 2,
        status: value.status ? Number(1) : Number(0),
        indexOrder: Number(value.order),
        cityId: this.data.parent.code,
        districtId: null
      }
      this.administrativeUnitService.storeLocation(dataInput).subscribe(
        (res)=>{
          this.dialogref.close(true);
        },
        (err)=>{
          this.dialogref.close(false);
        }
      )
    }else{
      let dataInput = {
        id:this.data.education.id,
        fullname: value.fullname,
        code: value.code,
        logo: "string",
        type: 2,
        status: value.status ? Number(1) : Number(0),
        indexOrder: Number(value.order),
        cityId: this.data.education.cityId,
        districtId: null
      }
      this.administrativeUnitService.updateLocation(dataInput).subscribe(
        (res)=>{
          this.dialogref.close(true);
        },
        (err)=>{
          this.dialogref.close(false);
        }
      )
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileChange(event) {

    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result)
      }
    }

  }

  uploadImage() {
    let inputUploadFile = document.getElementById('inputUploadFile') as HTMLElement;
    inputUploadFile.click();
  }

  changeOptionAdd(value){
    this.checkOption = value;
  }

  // validate
  education_validation_messages = {
    'fullname': [
      { type: 'required', message: 'chưa nhập tên education' }
    ],
    'code': [
      { type: 'required', message: 'chưa nhập mã education' },
      { type: 'pattern', message: 'mã education phải từ 5 đến 15 ký tự' }
    ],
    'order': [
      { type: 'required', message: 'chưa nhập order' }
    ],
    'parent': [
      { type: 'required', message: 'chưa nhập parent' }
    ]
  }

}
