import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Grade } from '@app/_models/grade';

@Injectable({
  providedIn: 'root'
})
export class IndustryUnitService {

  constructor(private http: HttpClient) { }

  generateCode(prefix:any){
    let data = {
      "prefix": prefix,
      "lenghtOfAlphabet": 0,
      "lenghtOfUpercase": 0,
      "lenghtOfNumber": 6,
      "lenghtOfSpecial": 0
    };
    return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`,data);
  }

  getListEducationLv1(){
    return this.http.get(`${environment.coreApiUrl}/api/moetunit/get-list-so-gd`);
  }

  getListEducationLv2(idEducationLv1:any){
    return this.http.get(`${environment.coreApiUrl}/api/moetunit/get-list-phong-gd-va-thpt?id=${idEducationLv1}`);
  }

  getListEducationLv3(idEducationLv2:any){
    return this.http.get(`${environment.coreApiUrl}/api/moetunit/get-list-school?id=${idEducationLv2}`);
  }

  storeEducation(data:any){
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/store`,data);
  }

  getDetailEducation(id:any){
    return this.http.get(`${environment.coreApiUrl}/api/moetunit/detail/${id}`);
  }

  updateEducation(data:any){
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/update`,data);
  }

  deleteEducation(id:any){
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/Delete`, {id});
  }

  filterEducation(data){
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/all-moetunit`,data);
  }

  moveEducation(data){
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/update-vertical-unit`,data);
  }

  importBase64MoetUnit(data){
    const headers = new HttpHeaders({'Content-Type':'application/json'});
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/import-base64`,data,{headers});
  }

  importMoetUnit(data){
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/import-moet-unit`,data);
  }

  searchListEducation(keyWord, phongId, soId){
    phongId = phongId === undefined ? null : phongId;
    soId = soId === undefined ? null : soId;
    keyWord = keyWord === '' ? '' : keyWord;
    console.log(keyWord, soId, phongId, 123123123123123);
    return this.http.post(`${environment.coreApiUrl}/api/moetunit/search-moetunit-to-admin`, {
        keyword: keyWord,
        unit: 0,
        soId,
        phongId
  });
}

}
