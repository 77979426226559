import { Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AccountManagerService, ForgotPasswordService} from '@app/_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoginComponent} from '@app/components/login/login.component';

@Component({
  selector: 'app-dialog-reset-passwords',
  templateUrl: './dialog-reset-passwords.component.html',
  styleUrls: ['./dialog-reset-passwords.component.scss']
})
export class DialogResetPasswordsComponent implements OnInit {
  public type = 'password';
  public message: string;
  iconEyeNewPass = false;
  iconEyeRepeatPass = false;
  checkValidate = false;
  code: string;
  value = {
    dataPassword: '',
    dateRePassword: ''
  };
  validate = {
    // 0 hidden, 1 fail, 2 success
    is_repeat_password: 0,
    verify_account: {
      is_min_length: 0,
      is_pattern_number: 0,
      is_pattern_text: 0,
    }
  };

  constructor(
      private fb: FormBuilder,
      private route: Router,
      private accountManagerService: AccountManagerService,
      private _snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DialogResetPasswordsComponent>,
  ) { }

  ngOnInit(): void {
  }

  confirmPassword() {
    if (this.validate.is_repeat_password !== 1 && this.validate.verify_account.is_min_length !== 1 && this.validate.verify_account.is_pattern_number !== 1 && this.validate.verify_account.is_pattern_text !== 1) {
      this.validate.is_repeat_password = 2;
      this.validate.verify_account.is_min_length = 2;
      this.validate.verify_account.is_pattern_number = 2;
      this.validate.verify_account.is_pattern_text = 2;
      return false;
    }
    this.accountManagerService.resetPassword(this.data.infoUser.id, this.value.dataPassword, this.value.dateRePassword).subscribe(
        (ref: any) => {
          // console.log(ref);
          this.dialogRef.close(ref);
        }, error => {
          // console.log(error.status);
        }
        );
  }

  checkNewPassword(password: string) {
    const regPatternText = /^(?=.*[a-z\\A-Z]).{1,}$/g;
    const regPatternNumber = /^(?=.*\d).{1,}$/g;
    if (password.length >= 6) {
      this.validate.verify_account.is_min_length = 1;
    } else {
      this.validate.verify_account.is_min_length = 2;
    }
    // + Mật khẩu phải chứa ít nhất 1 chữ số
    if (regPatternNumber.test(password)) {
      this.validate.verify_account.is_pattern_number = 1;
    } else {
      this.validate.verify_account.is_pattern_number = 2;
    }
    // + Mật khẩu phải chứa ít nhất 1 ký tự chữ (chữ hoa hoặc thường)
    if (regPatternText.test(password)) {
      this.validate.verify_account.is_pattern_text = 1;
    } else {
      this.validate.verify_account.is_pattern_text = 2;
    }
    this.value.dataPassword = password;

    if (password === this.value.dateRePassword && this.value.dateRePassword !== '' && password !== ''){
      this.validate.is_repeat_password = 1;
    }
    else{
      this.validate.is_repeat_password = 2;
    }
  }

  checkRepeatPassword(repeatPassword: string) {
    this.value.dateRePassword = repeatPassword;
    if (repeatPassword === this.value.dataPassword && this.value.dataPassword !== '' && repeatPassword !== ''){
      this.validate.is_repeat_password = 1;
    }
    else{
      this.validate.is_repeat_password = 2;
    }
  }
}
