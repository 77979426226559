<div class="row" *ngIf="checkData">
    <ng-container *ngFor="let element of dataShowPermissions">
        <div class="col-lg-3 mt-2">
            <div class="px-2 pt-2 box-list-module">
                <label class="label-dialog-list-module pb-1">
                    <mat-checkbox [disabled]="true" [checked]="element.is_selected"></mat-checkbox> {{  element.name }} <span class="code-dialog-list-permission">({{  element.code }})</span>
                </label>
                <div class="div-box-module">
                    <div class="mt-2" *ngFor="let item of element.permissons">
                        <mat-checkbox [disabled]="true" [checked]="item.status"></mat-checkbox>
                        <span class="label-dialog-list-permission mt-4"> {{  item.permisionName }} <span class="code-dialog-list-permission">({{  item.permissionCode }})</span></span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
 <div class="spinner" *ngIf="isLoading">
     <mat-spinner></mat-spinner>
 </div>
