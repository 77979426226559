<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between none" >
        <div>
            <p class="title-page-global">Danh sách Môn học</p>
        </div>
        <div fxFlex="70%" fxLayout="row" fxLayoutAlign="end none" >
            <input (keydown)="checkText($event, search.value)" #search type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="text-left btn btn-default button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? 'Tìm kiếm' : ''}}" (blur)="checkText($event, search.value)" >
            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass()">
            <button class="btn btn-default btn-filter">
                <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilter = !showFilter">
            </button>
            <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm2]">
                <button class="btn btn-add-global mat-elevation-z" (click)="openForm('add')" mat-raised-button><mat-icon>add</mat-icon>Bài viết mới</button>
                </ng-template>
            </div>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="end start" class="option-filter" *ngIf="showFilter">
        <select #scope class="btn-filter-option mr-2" (change)="checkFilter(scope.value, sendEmail.value, sendComment.value)">
            <option selected value="">Phạm vi</option>
            <option value="0">Core admin</option>
            <option value="1">Khách hàng</option>
        </select>
        <select #sendEmail class="btn-filter-option mr-2" (change)="checkFilter(scope.value, sendEmail.value, sendComment.value)">
            <option value="" selected>Chọn</option>
            <option value="1">Cho phép gửi mail</option>
            <option value="0">Không cho phép gửi mail </option>
        </select>
        <select #sendComment class="btn-filter-option mr-2" (change)="checkFilter(scope.value, sendEmail.value, sendComment.value)">
            <option value="" selected>Chon</option>
            <option value="1">Cho phép bình luận</option>
            <option value="0">Không cho phép bình luận </option>
        </select>
        <button class="btn btn-filter-search" style="margin-right: 3.1%;margin-left: 0.7%" (click)="filter()">Lọc</button>
    </div>
    <div fxFlex fxLayout="column" class="content-data">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th *matHeaderCellDef mat-header-cell style="width: 5%">STT</th>
                <td mat-cell *matCellDef="let item, let i = index">{{(pageSize * pageIndex) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="post">
                <th *matHeaderCellDef mat-header-cell style="width: 15%">Bài viết</th>
                <td mat-cell *matCellDef="let item">
                    <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm3]">

                    <a class="link_table" [routerLink]="['detail', item.id]" href="javascript:;">{{item.title}}</a>
                    </ng-template>
                    <ng-template [ngxPermissionsExcept]="[POST_PERMISSION.perm3]">
                        <p>{{item.title}}</p>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="limit">
                <th *matHeaderCellDef mat-header-cell style="width: 8%">Phạm vi gửi</th>
                <td mat-cell *matCellDef="let item"><span class="show-more-limit">{{item.sendRange == 1 ? 'Khách hàng' : 'Core admin'}}</span></td>
            </ng-container>
            <ng-container matColumnDef="group">
                <th *matHeaderCellDef mat-header-cell style="width: 8%">Nhóm đối tượng</th>
                <td mat-cell *matCellDef="let item">
                    <span class="show-more-group" *ngIf="item.targetGroup === 0">Tất cả</span>
                    <span class="show-more-group" *ngIf="item.targetGroup === 1">Người dùng</span>
                    <span class="show-more-group" *ngIf="item.targetGroup === 2">Giáo viên</span>
                    <span class="show-more-group" *ngIf="item.targetGroup === 3">Học sinh</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="object">
                <th *matHeaderCellDef mat-header-cell style="width: 8%">Đối tượng</th>
                <td mat-cell *matCellDef="let item"><span class="show-more-object" (click)="showListObject(item.id)">{{item.recipient}}</span></td>
            </ng-container>
            <ng-container matColumnDef="select_email">
                <th *matHeaderCellDef mat-header-cell style="width: 4%">Cho phép gửi mail</th>
                <td mat-cell *matCellDef="let item"><mat-checkbox [checked]="item.allowSendMail === 1"></mat-checkbox></td>
            </ng-container>
            <ng-container matColumnDef="select_comment">
                <th *matHeaderCellDef mat-header-cell style="width: 5%">Cho phép bình luận</th>
                <td mat-cell *matCellDef="let item"><mat-checkbox [checked]="item.allowComment === 1"></mat-checkbox></td>
            </ng-container>
            <ng-container matColumnDef="calendar_publish">
                <th *matHeaderCellDef mat-header-cell style="width: 6%">Lịch gửi</th>
                <td mat-cell *matCellDef="let item">{{item.timePublish}}<br>{{item.datePublish}}</td>
            </ng-container>
            <ng-container matColumnDef="send">
                <th *matHeaderCellDef mat-header-cell style="width: 6%">Đã gửi</th>
                <td mat-cell *matCellDef="let item">{{item.numberReceive}}/{{item.numberSent}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell style="width: 8%">Trạng thái</th>
                <td mat-cell *matCellDef="let item">
                    <span *ngIf="item.sendStatus === 2" class="show-more-limit" style="background: #FF8038">Gửi thành công</span>
                    <span *ngIf="item.sendStatus === 0" class="show-more-limit" style="background: #1355C1">Chưa gửi</span>
                    <span *ngIf="item.sendStatus === 1" class="show-more-limit" style="background: #8950FC">Đang gửi</span>
                    <span *ngIf="item.sendStatus === 3" class="show-more-limit" style="background: #FF4848">Lỗi</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="tool">
                <th *matHeaderCellDef mat-header-cell style="width: 5%">Công cụ</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm4]">
                        
                            <button mat-menu-item (click)="openForm('edit', item.id)" *ngIf="item.sendStatus !== 1">Chỉnh sửa</button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm7]">
                        
                            <button mat-menu-item *ngIf="item.sendStatus === 3" (click)="confirmSendTo(item.title, item.id)">Gửi lại</button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm6]">
                        
                        
                            <button mat-menu-item class="text-danger" *ngIf="item.sendStatus === 2"
                                (click)="confirmHidden(item.title, item.id)">Ẩn</button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm5]">
                        
                            <button mat-menu-item class="text-danger" *ngIf="item.sendStatus === 0 || item.sendStatus === 3"
                                (click)="confirmDelete(item.title, item.id)">Xoá</button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[POST_PERMISSION.perm8]">
                        
                            <button mat-menu-item class="text-danger" *ngIf="item.sendStatus === 1"
                                (click)="confirmCancelSend(item.title, item.id)">Hủy</button>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
            <tr mat-row *matRowDef="let row,columns: dataColumn"></tr>
        </table>
        <mat-paginator showFirstLastButtons appStylePaginator class="table-paginate-global" [length]="totalRecord" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
    </div>
</div>
