import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lang } from './lang-form-subject';
import { FormBuilder, Validators } from '@angular/forms';
import { Grade } from '@app/_models/grade';
import {ShowMessage, GradeManagerService, GeneralService} from '@app/_services';
import { REGEX_FULL_NAME,REGEX_PHONE,REGEX_CODE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss']
})
export class DialogFormComponent implements OnInit {
  panelOpenState = true;
  lang = lang;
  formGrade:any;
  checkValidate = false;
  message_validate = {
    fullname: [
      { type: 'required', message: lang.message_validate.full_name_required },
      { type: 'minlength', message: "Độ dài tên khối từ 2-250 ký tự" },
      { type: 'maxlength', message: "Độ dài tên khối từ 2-250 ký tự" },
      { type: 'pattern' , message: "Tên không hợp lệ" }
    ],
    code: [
      { type: 'required', message: lang.message_validate.code_required },
      { type: 'minlength', message: "Độ dài mã khối từ 2-50 ký tự" },
      { type: 'maxlength', message: "Độ dài mã khối từ 2-50 ký tự" },
      { type: 'pattern' , message: "Mã không hợp lệ" }
    ],
    indexOrder: [
      { type: 'pattern', message: 'Chỉ được nhập số nguyên dương' },
    ],
    description: [
      { type: 'maxlength', message: 'Chỉ được nhập tối đa 500 ký tự' },
    ]
  }
  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public dialog: MatDialogRef<any>,
      private fb: FormBuilder,
      private gradeManagerService:GradeManagerService,
      private showMessage: ShowMessage,
      private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.formGrade = this.fb.group({
      fullname: [this.data.infoGrade ? this.data.infoGrade.fullname: '', [Validators.required, Validators.minLength(2),Validators.maxLength(250),Validators.pattern(REGEX_FULL_NAME)]],
      code: [{value:this.data.infoGrade ? this.data.infoGrade.code: '',disabled: this.data.status == "update" ? true : false}, [Validators.required, Validators.minLength(2),Validators.maxLength(50),Validators.pattern(REGEX_CODE)]],
      trainingLevel: [this.data.infoGrade ? this.data.infoGrade.trainingLevel : 99],
      group: ["Khối MOET"],
      indexOrder: [this.data.infoGrade ? this.data.infoGrade.indexOrder: 0, Validators.pattern("^[0-9]+\d*$")],
      status: [this.data.infoGrade && this.data.infoGrade.status == 1 ? true: false],
      description: [this.data.infoGrade ? this.data.infoGrade.description: '',[Validators.maxLength(500)]],
    });

    if (this.data.status === 'add') this.generateRandomKey();
  }

  generateRandomKey(){
    this.generalService.getCodeRandom('K').subscribe((res: any) => {
      this.formGrade.patchValue({
        code: res.data,
      });
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit(fromData:Grade){
    if(!this.formGrade.valid){
      this.showMessage.error("Bạn chưa nhập đủ thông tin");
      return;
    }
    if (this.data.status == 'add'){
      fromData.status ? fromData.status = 1 : fromData.status = 0;
      fromData.trainingLevel = Number(fromData.trainingLevel);
      fromData.group = 0;
      fromData.indexOrder = Number(fromData.indexOrder);
      this.dialog.close(fromData);
    }else{
      fromData.status ? fromData.status = 1 : fromData.status = 0;
      fromData.trainingLevel = Number(fromData.trainingLevel);
      fromData.group = 0;
      fromData.indexOrder = Number(fromData.indexOrder);
      fromData.id = this.data.infoGrade.id;
      delete fromData.code;
      this.dialog.close(fromData);
    }
  }

  onDismiss(): void {
    this.dialog.close(false);
  }

}
