import {AfterViewInit, Component, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import {AuthenticationService, ShowMessage} from '@app/_services';
import * as $ from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { DialogChangePasswordComponent } from '@app/_shared/dialogs/change-password/dialog-change-password.component';
import { NotificationService } from '@app/_services/notification.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  array:any;
  alertNoti: boolean = false;
  cancel = true;
  user: any;
  config: any;
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private db: AngularFireDatabase,
    private showMessage: ShowMessage
  ) {
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    window.addEventListener('storage', () => {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.config = JSON.parse(localStorage.getItem('config'));
    });
    this.config = JSON.parse(localStorage.getItem('config'));
    this.getDataNotification();
    this.notificationAction('update');
  }

  notificationAction(action?: string){
    this.db.list(`core_admin/user/${action}/${environment.userID}`).valueChanges().subscribe(
        (res) => {
          if (res.length > 0) {
            if (typeof res[0] === 'string') {
              const msg = JSON.parse(res[0]);
              if (msg.status === true){
                this.showMessage.success(msg.message);
              }
              else{
                this.showMessage.error(msg.message);
              }
              this.db.list(`core_admin/user/${action}/${environment.userID}`).remove();
            }
          }
        },
        (err) => {
          this.showMessage.success('');
          console.log(err);
        }
    );
  }
  getDataNotification(){
    this.notificationService.getDataNotification(15,0).subscribe(
      (res:any) => {
        if (res.status === 1){
          this.array = res.data;
        }
      },
      (err) => {
      });
  }

  checkAllNotificationRead(){
    let data = {
      markReadAll: true
    }
    this.notificationService.checkAllNotificationRead(data).subscribe(
      (res: any) => {
      },
      (err) => {

      });
  }

  viewAllNotification(){
    this.router.navigate(['/notification']);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  showNoti(cancel){
    this.alertNoti = true;
    this.cancel = !cancel;
  }
  showFormChangePassword(){
    const dialogForm = this.dialog.open(DialogChangePasswordComponent, {
      height: 'auto',
      width: '40%',
    });
  }

  openProfile(){
    this.router.navigate([`${'profile'}`], { queryParams: {active: 'tab1'}});
  }

}

$(document).click(function() {
  var container = $("#notification-center");
  var content_notification = $("#noti-user");
  if (!container.is(event.target) && !container.has(event.target).length) {
      if(!content_notification.is(event.target) && !content_notification.has(event.target).length){
        $("#noti-user").addClass('hidden');
      }else{
        // $("#noti-user").addClass('hidden');
      }
  }else{
      $("#noti-user").toggleClass('hidden');
  }
});




