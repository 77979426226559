import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { statusUser } from "@app/_services/constants";
import { ShowMessage } from './show-message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private message: ShowMessage) {}

  isAuthorized(allowedRoles: string[] = [], allowedPermission: string[] = []): boolean {
    const currentUserRole = JSON.parse(localStorage.getItem('roles'));
    const currentUserPermisson = JSON.parse(localStorage.getItem('permission'));
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if(allowedRoles.length !== 0) {
      let checkRole = allowedRoles.some(r=> currentUserRole.indexOf(r) >= 0)
      if(!checkRole) {
        this.message.error("Role fail");
        return false;
      }
    } 
    
    if(allowedPermission.length !== 0) {
      let checkPermission = allowedPermission.some(r=> currentUserPermisson.indexOf(r) >= 0)
      if(!checkPermission) {
        this.message.error("Permisson fail");
        return false;
      }
      return true;
    } else {
      this.message.error("Permisson fail");
      return false;
    }
  }

  getListPermission() {
    return ["tenants:view","tenants:create"];
  }
}
