import { Injectable } from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ModuleSoftwareManagerService {

    constructor(private http: HttpClient) { }

    getAllSubSystems(pageSize, pageIndex, keyword: string){
        const linkKeyWord = keyword === '' ? '' : `&keyword=${keyword}`;
        const linkStatus = status === '' ? '' : `&status=${status}`;
        pageIndex = pageIndex + 1;
        return this.http.get(`${environment.coreApiUrl}/api/subsystems?PageSize=${pageSize}&PageIndex=${pageIndex}${linkKeyWord}`);
    }

    generateRandomKey(data: {prefix: string, lenghtOfAlphabet: number, lenghtOfUpercase: number, lenghtOfNumber: number, lenghtOfSpecial: number}){
        return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`, data);
    }

    storeSubSystem(data: {fullname: string, code: string, description: string, indexOrder: number, status: number}){
        return this.http.post(`${environment.coreApiUrl}/api/subsystems`, data);
    }

    /**detail subSystem**/
    getSubSystemById(id){
        return this.http.get(`${environment.coreApiUrl}/api/subsystems/${id}`);
    }

    /**remove SubSystem**/
    removeSubSystem(id){
        return this.http.post(`${environment.coreApiUrl}/api/subsystems/delete`, {id});
    }

    /**update subsystem**/
    updateSubSystem(id, data: {fullname: string, code: string, description: string, indexOrder: number, status: number}){
        return this.http.put(`${environment.coreApiUrl}/api/subsystems/${id}`, data);
    }

    /**Thay doi trang thai phan he**/
    changeStatus(id: string, status: number){
        return this.http.post(`${environment.coreApiUrl}/api/subsystems/change-status`, {id, status});
    }

    /**Lay danh sách tenant cua subsystem **/
    listTenantOfModule(subsystem_id: string, keyword: string, status: number[]){
        return this.http.post(`${environment.coreApiUrl}/api/subsystems/${subsystem_id}/tenants`, {keyword, status});
    }

    /**Lay danh sach tenant khong thuoc subSystem**/
    listTenantNotBelongSubSystem(subsystem_id: string, keyword: string){
        return this.http.get(`${environment.coreApiUrl}/api/subsystems/${subsystem_id}/tenants-not-belong-subsystem?keyword=${keyword}`);
    }

    /**them tenant vao subject**/
    addTenant(subsystemId: string, tenantIds: string[]){
        return this.http.post(`${environment.coreApiUrl}/api/subsystems/add-tenant`, {subsystemId, tenantIds});
    }

    /**Xoa nguoi dung**/
    removeTenant(subsystemId: string, tenantId: string){
        return this.http.post(`${environment.coreApiUrl}/api/subsystems/delete-tenant`, {subsystemId, tenantId});
    }
}
