import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { ListenFirebaseService, TenantManagerService, Base64Service, ShowMessage, GeneralService } from '@app/_services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { statusUser } from "@app/_services/constants";
import { Observable, of, Subscriber } from 'rxjs';
import { REGEX_PHONE, REGEX_FULL_NAME, REGEX_DOMAIN, TIME_OUT_LISTEN_FIREBASE, REGEX_PASSWORD, REGEX_CODE,REGEX_USER_NAME } from '@app/_shared/utils/constant';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

function urlMatchValidator(): ValidatorFn {
  return (control: FormControl) => {
    if (!control || !control.parent) {
      return null;
    }
    return control.parent.get("tenantUrl").value !== control.parent.get("adminUrl").value ? null : { mismatch: true };
  };
}

@Component({
  selector: 'app-tenant-manager-create',
  templateUrl: './dialog-form-tenant-manager.component.html',
  styleUrls: ['./dialog-form-tenant-manager.component.scss']
})
export class DialogFormTenantManagerComponent implements OnInit {
  tenantForm: FormGroup;
  urlImage: SafeResourceUrl;
  selectedFile: File
  isValidate: boolean;
  convertImg = null;
  fileName = '';
  dataTenant = {};
  // selectedCountry: City = new City(2, 'Brazil');
  cities: any[];
  districts: any[];
  wards: any[];
  action: string;
  isLoadingResults = false;

  employeeDeploy = [];
  employeeSale = [];
  listCityObj = {};

  hideButtonAdd: boolean;
  panelOpenState = true;
  hidePassword = true;
  submitted = false;
  randomCode: string = "";
  readonly statusUser = statusUser;
  typeModule = "tenant";
  checkAdminUrl = false;
  checkTenantUrl = false;
  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private tenantManagerService: TenantManagerService,
    private dialogRef: MatDialogRef<DialogFormTenantManagerComponent>,
    private showMessage: ShowMessage,
    private base64: Base64Service,
    private general: GeneralService,
    private listenFirebaseService: ListenFirebaseService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.isLoadingResults = true;
    this.action = this.data.action;
    this.urlImage = this.data.tenant && this.data.tenant.logo !== '' ? this.data.tenant.logo : '/assets/images/png/avatar.png';

    const APIDeploymentStaffs = this.tenantManagerService.getDeploymentStaffs();
    const APIBussinessStaffs = this.tenantManagerService.getBussinessStaffs();
    const APICodeRandom = this.general.getCodeRandom('TE');
    const APIGetCity = this.general.getListCity();
    this.infoTenant()

    if (this.action === "create") {
      forkJoin([APIDeploymentStaffs, APIBussinessStaffs, APICodeRandom, APIGetCity]).subscribe(results => {
        this.listCityObj = results[3];
        this.employeeDeploy = (results[0] as any).data;
        this.employeeSale = (results[1] as any).data;

        this.tenantForm.patchValue({ code: (results[2] as any).data });
        this.tenantForm.patchValue({ adminUrl: `${(results[2] as any).data.toLowerCase()}.admin.elearn.vn` });
        this.tenantForm.patchValue({ tenantUrl: `${(results[2] as any).data.toLowerCase()}.elearn.vn` });
        this.tenantForm.patchValue({ tenantAdmin: `${(results[2] as any).data.toLowerCase()}_admin` });
        this.cities = (results[3] as any).data;
        this.tenantForm.patchValue({ cityId: "" });
        this.tenantForm.patchValue({ districtId: "" });
        this.tenantForm.patchValue({ wardId: "" });
        this.isLoadingResults = false;
      }), err => {
        console.log(err);
        this.isLoadingResults = false;
      };
      this.hideButtonAdd = false
    } else {
      forkJoin([APIDeploymentStaffs, APIBussinessStaffs, APIGetCity]).subscribe(results => {
        this.employeeDeploy = (results[0] as any).data;
        this.employeeSale = (results[1] as any).data;
        this.cities = (results[2] as any).data;

        if(this.data?.tenant?.cityId !== null) {
          let selectedCityId = this.data?.tenant?.cityId;
            this.general.getDetailLocation(selectedCityId).pipe(
              mergeMap(detailCity => this.general.getListDistrict((detailCity as any).data.id))
            ).subscribe((result:any) => {
              this.districts = result?.data;
            });
        } else {
          this.tenantForm.patchValue({ cityId: "" });
          
        }
        if(this.data?.tenant?.districtId !== null) {
          let selectedDistrictId = this.data?.tenant?.districtId;
            this.general.getDetailLocation(selectedDistrictId).pipe(
              mergeMap(detailDistrict => this.general.getListWard((detailDistrict as any).data.id))
            ).subscribe((result:any) => {
              this.wards = result?.data;
            });
        } else {
          this.tenantForm.patchValue({ districtId: "" });
        }

        if(this.data?.tenant?.wardId === null){
          this.tenantForm.patchValue({ wardId: "" });
        }
        this.isLoadingResults = false;
      }), err => {
        console.log(err);
        this.isLoadingResults = false;
      };
      this.tenantForm.get('tenantAdmin').disable();
      this.tenantForm.get('tenantAdminPassword').disable();
      this.hideButtonAdd = true
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSelectCity(selectedCityId) {
      this.general.getDetailLocation(selectedCityId).pipe(
        mergeMap(detailCity => this.general.getListDistrict((detailCity as any).data.id))
      ).subscribe((result:any) => {
        this.districts = result.data;
        this.tenantForm.patchValue({ districtId: "" });
        this.tenantForm.patchValue({ wardId: "" });
        this.wards = [];
      });
  }

  onSelectDistrict(selectedDistrictId) {
        this.general.getDetailLocation(selectedDistrictId).pipe(
          mergeMap(detailDistrict => this.general.getListWard((detailDistrict as any).data.id))
        ).subscribe((result:any) => {
          this.wards = result.data;
          this.tenantForm.patchValue({ wardId: "" });
        });
  }

  valueCodeChange($event) {
    console.log($event.target.value);
    this.tenantForm.patchValue({ adminUrl: `${$event.target.value.toLowerCase()}.admin.elearn.vn` });
    this.tenantForm.patchValue({ tenantUrl: `${$event.target.value.toLowerCase()}.elearn.vn` });
    this.tenantForm.patchValue({ tenantAdmin: `${$event.target.value.toLowerCase()}_admin` });
  }

  infoTenant() {
    this.tenantForm = this.fb.group({
      // id: [this.data?.tenant?.id ? this.data.tenant.id : this.id],
      // Thông tin cơ bản
      fullname: [this.data?.tenant?.fullname ? this.data.tenant.fullname : null, [Validators.required, Validators.minLength(2), Validators.maxLength(250), Validators.pattern(REGEX_FULL_NAME)]],
      hotline: [this.data?.tenant?.hotline ? this.data.tenant.hotline : null, [Validators.required, Validators.pattern(REGEX_PHONE)]],
      email: [this.data?.tenant?.email ? this.data.tenant.email : null, [Validators.required, Validators.email]],
      status: [this.data?.tenant?.status ? this.data.tenant.status : 2], // default status: Đang sử dụng = 2;
      code: [this.data?.tenant?.code ? this.data.tenant.code : null, [Validators.required, Validators.pattern(REGEX_CODE), Validators.minLength(2), Validators.maxLength(50)]],
      // Thông tin nâng cao
      contactFullname: [this.data?.tenant?.contactFullname ? this.data.tenant.contactFullname : null, [Validators.maxLength(250), Validators.pattern(REGEX_FULL_NAME)]],
      contactPhone: [this.data?.tenant?.contactPhone ? this.data.tenant.contactPhone : null, [Validators.pattern(REGEX_PHONE)]],
      cityId: [this.data?.tenant?.cityId ? this.data.tenant.cityId : null],
      wardId: [this.data?.tenant?.wardId ? this.data.tenant.wardId : null],
      contactEmail: [this.data?.tenant?.contactEmail ? this.data.tenant.contactEmail : null, [Validators.email]],
      districtId: [this.data?.tenant?.districtId ? this.data.tenant.districtId : null],
      address: [this.data?.tenant?.address ? this.data.tenant.address : null, [Validators.maxLength(500)]],
      tenantWebsite: [this.data?.tenant?.tenantWebsite ? this.data.tenant.tenantWebsite : null, [Validators.pattern(REGEX_DOMAIN)]],
      indexOrder: [this.data?.tenant?.indexOrder ? this.data.tenant.indexOrder : "0", [Validators.required]],
      // Tên miền
      adminUrl: [this.data?.tenant?.adminUrl ? this.data.tenant.adminUrl : null, [Validators.required, Validators.pattern(REGEX_DOMAIN), urlMatchValidator()]],
      tenantUrl: [this.data?.tenant?.tenantUrl ? this.data.tenant.tenantUrl : null, [Validators.required, Validators.pattern(REGEX_DOMAIN), urlMatchValidator()]],
      // Tài khoản quản trị
      tenantAdmin: [this.data?.tenant?.tenantAdmin ? this.data.tenant.tenantAdmin : null, [Validators.required, Validators.pattern(REGEX_USER_NAME), Validators.minLength(2), Validators.maxLength(50)]],
      tenantAdminPassword: [this.data?.tenant?.tenantAdminPassword ? this.data.tenant.tenantAdminPassword : null, [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(REGEX_PASSWORD)]],
      // Nhân viên chăm sóc
      employeeDeployId: [this.data?.tenant?.employeeDeployId ? this.data.tenant.employeeDeployId : null],
      employeeSaleId: [this.data?.tenant?.employeeSaleId ? this.data.tenant.employeeSaleId : null],
    });
  }
  get f() { return this.tenantForm.controls; }


  getExstendsion(image) {
    if (image.endsWith('jpg') || image.endsWith('jpeg')) {
      return 'jpg';
    }
    if (image.endsWith('png')) {
      return 'png';
    }
  }

  myimage: Observable<any>;
  onFileChange($event) {
    const file = ($event.target as HTMLInputElement).files[0];
    let allowExtentionImage = ['png', 'jpg'];
    if (!allowExtentionImage.includes(this.getExstendsion(file?.type))) {
      this.showMessage.error("Ảnh không đúng định dạng");
      return;
    }
    this.selectedFile = file;
    this.fileName = this.selectedFile.name;

    let reader = new FileReader();
    reader.readAsDataURL(file); // read file as data url
    reader.onload = (event: any) => { // called once readAsDataURL is completed
      this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
    };

    this.myimage = new Observable((subscriber: Subscriber<any>) => {
      this.base64.readFile(file, subscriber);
    });
    this.myimage.subscribe((resultBase64) => {
      this.convertImg = resultBase64;
    });
  }

  uploadImage() {
    document.getElementById('inputUploadFile').click();
  }

  changeCheckAdminUrl (){
    
    this.checkAdminUrl = false;
  }

  changeCheckTenantUrl() {
    
    this.checkTenantUrl = false;
  }

  onClickSubmit() {
    this.submitted = true;
    let data = this.tenantForm.value;
    if (this.tenantForm.invalid) {
      return;
    }
    // check domain exist
    this.isLoadingResults = true;

    const APICheckDomainAdminUrl = this.tenantManagerService.checkExistDomain({"domain": data.adminUrl});
    const APICheckDomainTenantUrl = this.tenantManagerService.checkExistDomain({"domain": data.tenantUrl});
    if(data.adminUrl !== this.data?.tenant?.adminUrl && data.tenantUrl !== this.data?.tenant?.tenantUrl) {
      forkJoin([APICheckDomainAdminUrl, APICheckDomainTenantUrl]).subscribe(results => {
        if((results[0] as any).status === 0 && (results[1] as any).status === 0){
          
          this.checkAdminUrl = true;
          this.checkTenantUrl = true;
          this.isLoadingResults = false;
          return;
        } 
        if((results[0] as any).status === 0){
          this.checkAdminUrl = true;
          this.isLoadingResults = false;
          return;
        } 
        if((results[1] as any).status === 0){
          this.checkTenantUrl = true;
          this.isLoadingResults = false;
          return;
        }
        
      }), err => {
        console.log(err);
        this.isLoadingResults = false;
        return;
      };
    } else if (data.adminUrl === this.data?.tenant?.adminUrl && data.tenantUrl !== this.data?.tenant?.tenantUrl) {
      forkJoin([APICheckDomainTenantUrl]).subscribe(results => {

        if((results[0] as any).status === 0){
          this.checkTenantUrl = true;
          this.isLoadingResults = false;
          return;
        }
        
      }), err => {
        console.log(err);
        this.isLoadingResults = false;
        return;
      };
    } else if  (data.adminUrl !== this.data?.tenant?.adminUrl && data.tenantUrl === this.data?.tenant?.tenantUrl) {
      forkJoin([APICheckDomainAdminUrl]).subscribe(results => {
        if((results[0] as any).status === 0){
          this.checkAdminUrl = true;
          this.isLoadingResults = false;
          return;
        } 
        
      }), err => {
        console.log(err);
        this.isLoadingResults = false;
        return;
      };
    }

    // 
    let logoIsNewFile;
    if (this.convertImg !== null) {
      logoIsNewFile = 1;
    }
    if (this.convertImg === null && this.data?.tenant?.logo !== "") {
      logoIsNewFile = 0;
    }
    let uploadData = {
      // Thông tin cơ bản
      fullname: data.fullname,
      code: data.code,
      logo: this.convertImg === null ? this.data?.tenant?.logo : this.convertImg,
      // fileName: this.fileName === null ? '' : this.fileName,
      hotline: data.hotline,
      email: data.email,
      status: parseInt(data.status),
      // Thông tin nâng cao
      contactFullname: data.contactFullname,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      cityId: data.cityId,
      districtId: data.districtId,
      wardId: data.wardId,
      address: data.address,
      tenantWebsite: data.tenantWebsite,
      indexOrder: data.indexOrder == '' ? data.indexOrder = 1 : parseInt(data.indexOrder),
      // Tên miền
      adminUrl: data.adminUrl,
      tenantUrl: data.tenantUrl,
      // Tài khoản quản trị
      tenantAdmin: data.tenantAdmin,
      tenantAdminPassword: data.tenantAdminPassword,
      // Nhân viên chăm sóc
      employeeDeployId: data.employeeDeployId,
      employeeSaleId: data.employeeSaleId
    };


    // return ;
    if (this.action == 'create') {
      let listenFb = new Observable((subscriber: Subscriber<any>) => {
        this.listenFirebaseService.checkFireBase("create", this.typeModule, subscriber);
      });

      listenFb.subscribe((result) => {
        if (result === true) {
          this.dialogRef.close(result);
          this.isLoadingResults = false;
        } else {
          this.isLoadingResults = false;
        }

      });
      uploadData.code = data.code;
      this.tenantManagerService.createTenant(uploadData).subscribe(
        (res: any) => {
          if (res.status == 0) {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
              this.showMessage.error(res.msg);
            }
          }
        },
        error => {
          this.isLoadingResults = false;
        }
      );
      setTimeout(() => {
        if (this.isLoadingResults) {
          this.isLoadingResults = false;
          this.dialogRef.close();
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    } else {
      let listenFb = new Observable((subscriber: Subscriber<any>) => {
        this.listenFirebaseService.checkFireBase("update", this.typeModule, subscriber);
      });

      listenFb.subscribe((result) => {
        if (result === true) {
          this.dialogRef.close(result);
          this.isLoadingResults = false;
        } else {
          this.isLoadingResults = false;
        }
      });
      let dataUpdate = { ...uploadData, logoIsNewFile };
      this.tenantManagerService.updateTenant(dataUpdate, this.data.tenant.id).subscribe((res: any) => {
        if (res) {
          if (res.status == 0) {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
              this.showMessage.error(res.msg);
            }
          }
        }
      }, error => {
        this.isLoadingResults = false;
      });
      setTimeout(() => {
        if (this.isLoadingResults) {
          this.isLoadingResults = false;
          this.dialogRef.close();
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    }
  }
}
