<div fxLayout="column" class="bg-content-global">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h3 class="title-page-global">Chi tiết khách hàng</h3>
            </div>
            <div>
                <button mat-raised-button color="primary" (click)="openDialogUpdateTenant();" *ngIf="activeLink == 'tab1'"><img class="mr-2 mb-1" src="assets/images/icons/icon-21.svg"> Chỉnh sửa thông tin</button>
            </div>
        </div>
    </div>
    <div fxFlex style="overflow-y: auto;">
        <div fxLayout="row" class="tab-group">
            <div fxFlex="25%" class="tab-left" fxLayout="column">
                <div fxFlex="40%" fxLayout="column" fxLayoutAlign="center center" class="tab-header">
                    <!-- <img src={{tenantInfor.logo !== "" }} alt="tenant logo"> -->
                    <img [src]="tenantInfor?.logo ? tenantInfor?.logo : '/assets/images/png/avatar.png'"
                        class="avatar">
                    <h4>{{tenantInfor?.fullname}}</h4>

                    <p class="tenant-status" [ngStyle]="{'background': tenantInfor?.colorStatus}">{{tenantInfor?.nameStatus}}</p>
                </div>

                <div fxFlex fxLayout="column" fxLayoutAlign="end" class="tab-footer">
                    <div fxLayout="column" fxLayoutAlign="start" class="tab-links">
                        <ng-container *ngFor="let link of links">
                            <ng-template [ngxPermissionsOnly]="link.permission">
                                <div mat-tab-nav-bar>
                                    <a class="ml-5" mat-tab-link (click)="changeRoute(link.route)" [active]="activeLink == link.route"> {{link.name}} </a>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container>
                            <div mat-tab-nav-bar>
                                <a class="ml-5" mat-tab-link (click)="openDialogUpdateTenantAdminPassword()"> Đổi mật khẩu quản trị </a>
                            </div>
                        </ng-container>
                    </div>
                    <h4 style="margin-left: 3rem;">Khởi tạo ngày: {{tenantInfor?.createdAt | parseTime: "DD/MM/YYYY"}}</h4>
                    <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm22]">

                    <div (click)="openDialogDelete();" style="cursor: pointer; margin-left: 3rem;"><span>Xóa khách hàng</span></div>
                    </ng-template>
                </div>
            </div>
            <div fxFlex class="tab-right">
                <ng-container *ngIf="activeLink == 'tab1'">
                    <app-tab-tenant-content-detail [data]="tenantInfor"></app-tab-tenant-content-detail>
                </ng-container>
                <ng-container *ngIf="activeLink == 'tab2'">
                    <app-tab-tenant-config [data]="tenantInfor"></app-tab-tenant-config>
                </ng-container>
                <ng-container *ngIf="activeLink == 'tab3'">
                    
                    <app-tab-tenant-mldlai-config></app-tab-tenant-mldlai-config>

                </ng-container>
                <!-- <ng-container *ngIf="activeLink == 'tab4'">
                    <app-tab-tenant-account-admin [data]="tenantInfor"></app-tab-tenant-account-admin>
                </ng-container> -->
            </div>
        </div>
    </div>
</div>
