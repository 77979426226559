import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogAccountListAppliesComponent } from './dialogs/dialog-account-list-applies/dialog-account-list-applies.component';

import { SharedModule } from '@app/_shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GradesManagerRoutingModule } from './grades-manager-routing.module';
import { DialogFormComponent } from './dialogs/dialog-form/dialog-form.component';

import { ListCustomerComponent } from './components/tabs/list-customer/list-customer.component';
import { DialogAddAccountAppliesComponent } from './dialogs/dialog-add-account-applies/dialog-add-account-applies.component';
import { DialogSubjectAppliesComponent } from './dialogs/dialog-subject-applies/dialog-subject-applies.component';
import { InfoGradeComponent } from './components/tabs/info-grade/info-grade.component';
import { DetailGradeComponent } from './components/detail-grade/detail-grade.component';
import { ListGradeComponent } from './components/list-grade/list-grade.component';
import { GradeDetailTableCustomerComponent } from './components/table-customer/table-customer.component';
import { GradeDetailTableSubjectComponent } from './components/table-subject/table-subject.component';
import { DialogAddSubjectAppliesComponent } from './dialogs/dialog-add-subject-applies/dialog-add-subject-applies.component';
import { NgxPermissionsModule } from 'ngx-permissions';
@NgModule({
    declarations: [
        ListGradeComponent,
        DialogAccountListAppliesComponent,
        DialogFormComponent,
        DetailGradeComponent,
        InfoGradeComponent,
        ListCustomerComponent,
        DialogAddAccountAppliesComponent,
        DialogSubjectAppliesComponent,
        GradeDetailTableCustomerComponent,
        GradeDetailTableSubjectComponent,
        DialogAddSubjectAppliesComponent
    ],
    imports: [
        CommonModule,
        GradesManagerRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forChild()
    ]
})
export class GradesManagerModule { }
