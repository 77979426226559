<div fxLayout="column" class="bg-content-global" *ngIf="dataDetailLocation">
    <div fxLayout="column" fxFlex class="content-declare">
        <div fxFlex="3rem" fxLayout="row" fxLayoutAlign="start start">
            <div>
                <h4 class="title-page-global">{{ dataDetailLocation.fullname }}</h4>
            </div>
        </div>
        <div fxFlex fxLayout="column" class="content__education-lv1">
            <div class="info__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <h4 class="title__education-lv1">Thông tin cơ bản</h4>
                    <div class="btn-action-detail">
                        <ng-template ngxPermissionsOnly="['{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="menu-more-global">
                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                    <button mat-menu-item class="px-5" (click)="clickEditAdminstrativeLv2(dataDetailLocation);">Chỉnh sửa</button>
                                </ng-template>
                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                    <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv2(1);">Chuyển</button>
                                </ng-template>
                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                    <button mat-menu-item class="text-danger px-5" (click)="clickDeleteAdminstrative(dataDetailLocation);">Xóa</button>
                                </ng-template>
                            </mat-menu>
                        </ng-template>
                    </div>
                </div>
                <div class="form__info-education-lv1 row">
                    <div fxLayout="row" fxLayoutAlign="center center" class="col-md-2">
                        <div class="img__info-education-lv1">
                            <img src="https://scontent.fhan2-5.fna.fbcdn.net/v/t1.0-9/126490683_1856067191217227_582269962890364399_n.jpg?_nc_cat=109&ccb=2&_nc_sid=8bfeb9&_nc_ohc=ZOwMdTsVYugAX_d-q0M&_nc_oc=AQkiI2TbBfx5lNPwYoeZgtKP8bwTjTpypqsSMi8eeXPy4lQCO8fbY0buFWbbhBix6e0&_nc_ht=scontent.fhan2-5.fna&oh=1a492bf2a04d1d4910f09a6db80a5aef&oe=5FE39391"
                                alt="">
                        </div>
                    </div>
                    <div class="col-md-4 row custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Tên Quận/Huyện</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailLocation.fullname" [disabled]="true" class="form-control input-field-global" placeholder="Nhập tên quận/huyện">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trực thuộc Tỉnh/TP</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailLocation.cityId" [disabled]="true" class="form-control input-field-global" placeholder="Nhập tỉnh/tp">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trạng thái</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <mat-checkbox [disabled]="true" [checked]="dataDetailLocation.status == 1 ? true : false"></mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4 offset-1 custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Mã</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailLocation.code" [disabled]="true" class="form-control input-field-global" placeholder="Nhập mã">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Thứ tự sắp xếp</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" [value]="dataDetailLocation.indexOrder" [disabled]="true" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3"></label>
                            <div class="col-md-9 pl-0 pr-0">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                <button class="title__education-lv1-custom" mat-raised-button>Đơn vị quản lý</button>
                <div>
                    <input (focusout)="searchFocusOut(search.value,3);" #search type="text" class="text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}" [value]="showSearch == true? dataSearch : ''" [readOnly]="showSearch == true ? false: true"
                        placeholder="{{showSearch == true ? 'Tìm kiếm' : ''}}">
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass(search.value)">
                    <ng-template ngxPermissionsOnly="{{permissionLocation.perm2}}">
                        <button class="btn-add-global" mat-raised-button (click)="clickAddEucationLv3();"><mat-icon>add</mat-icon> Thêm mới</button>
                    </ng-template>
                </div>
            </div>
            <div fxFlex class="content-declare" fxLayout="row" fxLayoutAlign="space-between start">
                <div class="lv-3" style="display: flex;flex-direction: column;width: 100%;height: 100%">
                    <div class="title">Phường/ Xã ({{ countPhuongXa ? countPhuongXa : 0 }})</div>
                    <!-- start phường/xã -->
                    <div class="content-lv-3">
                        <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;height: 50%;">
                            <div *ngFor="let item of dataPhuongXa" class="grade" id="{{ item.code }}">
                                <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                                    <div class="grade-left">
                                        <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                                    </div>
                                    <div class="grade-right">
                                        <ng-template ngxPermissionsOnly="['{{permissionLocation.perm3}}', '{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                <mat-icon>more_horiz</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" class="menu-more-global">
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm3}}">
                                                    <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/administrative-unit/lv3/detail/',item.id]">Chi tiết</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                                    <button mat-menu-item class="px-5" (click)="clickEditEducationLv3(item);">Chỉnh sửa</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                                    <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv3(item);">Chuyển</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                                    <button mat-menu-item class="text-danger px-5" (click)="clickDeleteAdminstrative(item);">Xóa</button>
                                                </ng-template>
                                            </mat-menu>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end phường/xã -->
                </div>
            </div>
        </div>
    </div>
</div>
