import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GeneralConfigService, ListenFirebaseService, ShowMessage, TenantManagerService } from '@app/_services';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { DialogSelectUnitVerticalComponent } from '@app/_shared/dialogs/dialog-select-unit-vertical/dialog-select-unit-vertical.component';
import { MESSAGE_ERROR_CALL_API, TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { Subscriber } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-tab-config-examus',
  templateUrl: './tab-config-examus.component.html',
  styleUrls: ['./tab-config-examus.component.scss']
})
export class TabConfigExamusComponent implements OnInit {
  formSubmit: FormGroup;
  listTenants: any = [];
  isLoading: boolean = false;
  keyword: string = '';
  isCreate: boolean = false;
  listDVGDs: any = [];

  constructor(
    public dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private generalConfigService: GeneralConfigService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.formSubmit = this.fb.group({
      items: this.fb.array([]),
    });
    this.getListTenants();
    this.getListConfigs();

  }

  get items() { return (this.formSubmit.get('items') as FormArray) }

  createDynamicForm(value = null) {
    return this.fb.group({
      id: value ? value.id : null,
      tenantId: [value?.tenantId ? value?.tenantId : '', Validators.required],
      moetId: [value?.organizationId ? value?.organizationId : '', Validators.required],
      moetName: value?.organizationName ? value?.organizationName : 'Chọn ĐVND/ĐVGD',
      status: value?.status ? value?.status ? true : false : true,
      checkValidate: false,
      disableControl: value ? true : false,
    });
  }

  delete(index: number, id) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '35%',
      data: {
        title: 'Bạn đang thực hiện xóa cấu hình',
        message: 'Bạn có chắc muốn xóa cấu hình này không ?',
        btnOkText: 'XÓA',
        btnCancelText: 'HỦY',
        data: {
          name: ``
        },
      }
    })
    dialogRef.afterClosed().subscribe((ref: any) => {
      if (ref) {
        let dataInput = {
          id: id
        }
        this.listenFireBase('delete', index);
        this.setTimOutFireBase();
        this.generalConfigService.deleteIntegratedElsaConfig(dataInput).subscribe((res: any) => {
          if (res.status == 0) {
            this.isLoading = false;
            this.showMessage.error(res.msg);
          }
        }, (err: any) => {
          this.isLoading = false;
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      }
    });

  }

  update(index) {
    this.items.controls[index].get('disableControl').setValue(!this.items.controls[index].get('disableControl').value);
  }

  add() {
    this.items.push(this.createDynamicForm());
    this.isCreate = true;
  }

  submit(index, valueRow) {
    this.isLoading = true;

    if (valueRow.get('tenantId').value == '' || valueRow.get('tenantId').value == null) {
      this.isLoading = false;
      this.showMessage.warring('Bạn chưa chọn khách hàng!');
      return;
    }
    if (valueRow.get('moetId').value == '' || valueRow.get('moetId').value == null) {
      this.isLoading = false;
      this.showMessage.warring('Bạn chưa chọn đơn vị ngành dọc!');
      return;
    }

    if (this.formSubmit.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.isCreate) {
      // create
      let dataInput: any = {
        tenantId: valueRow.get('tenantId').value ? valueRow.get('tenantId').value : valueRow.get('dvgdId').value,
        organizationId: valueRow.get('moetId').value,
        status: valueRow.get('status').value ? 1 : 0,
      }
      // this.listenFireBase('create', index);
      // this.setTimOutFireBase();
      // this.generalConfigService.addIntegratedElsaConfig(dataInput).subscribe((res: any) => {
      //   if (res.status == 0) {
      //     this.isLoading = false;
      //     this.showMessage.error(res.msg);
      //   }
      // }, (err: any) => {
      //   this.isLoading = false;
      //   this.showMessage.error(MESSAGE_ERROR_CALL_API);
      // })
    } else {
      // update
      let dataInput: any = {
        id: valueRow.get('id').value,
        status: valueRow.get('status').value ? 1 : 0,
        apiKey: valueRow.get('api_key').value
      }
      // this.listenFireBase('update', index);
      // this.setTimOutFireBase();
      // this.generalConfigService.editIntegratedElsaConfig(dataInput).subscribe((res: any) => {
      //   if (res.status == 0) {
      //     this.isLoading = false;
      //     this.showMessage.error(res.msg);
      //   }
      // }, (err: any) => {
      //   this.isLoading = false;
      //   this.showMessage.error(MESSAGE_ERROR_CALL_API);
      // })
    }
  }

  // chon dvnd
  selectUnitVertical(index) {
    
    const selectDialog = this.dialog.open(DialogSelectUnitVerticalComponent, {
        height: 'auto',
        width: '80%',
    })
    selectDialog.afterClosed().subscribe((ref: any) => {
        if (ref) {
          this.items.controls[index].get('moetId').setValue(ref.unit.id);
          this.items.controls[index].get('moetName').setValue(ref.unit.name);
        }
    });
  }

  // lấy danh sách khách hàng
  getListTenants() {
    this.tenantManagerService.getDataTenant(99999, 0, '', []).subscribe((res: any) => {
      this.listTenants = res.data.data;
    }, (err: any) => {

    })
  }

  // lay danh sach cau hinh
  getListConfigs() {
    this.generalConfigService.getListIntegratedElsaConfig({ "keyword": this.keyword }).subscribe((res: any) => {
      res.data.forEach(element => {
        this.items.push(this.createDynamicForm(element));
      });
    }, (err: any) => {

    })
  }

  listenFireBase(action, index) {
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(action, 'admin_config', subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref === true) {
        switch (action) {
          case 'create':
            this.isCreate = false;
            this.items.controls[index].get('disableControl').setValue(true);
            break;
          case 'update':
            this.items.controls[index].get('disableControl').setValue(true);
            break;
          case 'delete':
            (this.formSubmit.get('items') as FormArray).removeAt(index);
            break;
        }
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  setTimOutFireBase() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
}
