import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CommentService {

    constructor(private http: HttpClient) { }

    getAllCommentByPostId(PostId){
        return this.http.get(`${environment.coreApiUrl}/api/comments?PostId=${PostId}`);
    }

    storeComment(data: {
        postId: string,
        parentCommentId: string,
        avatar: string,
        fullName: string,
        content: string
    }){
        return this.http.post(`${environment.coreApiUrl}/api/comments`, data);
    }

    updateComment(id: string, data: {
        postId: string,
        parentCommentId: string,
        avatar: string,
        fullName: string,
        content: string
    }){
        return this.http.put(`${environment.coreApiUrl}/api/comments/${id}`, data);
    }

    removeComment(id: string){
        return this.http.post(`${environment.coreApiUrl}/api/comments/delete`, {id});
    }
}
