import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';

import { DialogFormAdministrativeLv1Component } from '../../dialogs/dialog-form-administrative-lv1/dialog-form-administrative-lv1.component';
import { DialogFormAdministrativeLv2Component } from '../../dialogs/dialog-form-administrative-lv2/dialog-form-administrative-lv2.component';
import { DialogFormAdministrativeLv3Component } from '../../dialogs/dialog-form-administrative-lv3/dialog-form-administrative-lv3.component';
import { DialogMoveAdministrativeComponent } from '../../dialogs/move-administrative/dialog-move-administrative.component';
import { ActivatedRoute } from '@angular/router';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ListenFirebaseService} from '@app/_services';
// import leader line
import 'leader-line';
import { forEachChild } from 'typescript';
import { DialogMoveAdministrativeLv3Component } from '../../dialogs/move-administrative-lv3/dialog-move-administrative-lv3.component';
// declare let LeaderLine: any;
declare var LeaderLine: any;
import { LOCATION_PERMISSION } from '@app/_modules/administrative-units/constant';


@Component({
  selector: 'app-detail-administrative-lv1',
  templateUrl: './detail-administrative-lv1.component.html',
  styleUrls: ['./detail-administrative-lv1.component.scss']
})
export class DetailAdministrativeLv1Component implements OnInit{
  dataSource2:any;
  permissionLocation = LOCATION_PERMISSION;
  constructor(
    public dialog: MatDialog,
    private activatedRoute:ActivatedRoute,
    private administrativeUnitService:AdministrativeUnitService,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService
  ){}
  id_active = '';
  id_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  show_level_2 = false;
  show_level_3 = false;
  showSearch: boolean = false;
  dataSearch: string = '';
  idLocationLv1:any;
  dataDetailLocation:any;
  codeLocationLv1:any;
  dataSource:any;
  isLoading:boolean = false;
  resultSearch:any;
  checkResultSearch:boolean = false;
  linkDetailEducation:string;
  // khai báo line
  lineLv2 = [];
  lineLv3 = [];
  checkOpenPGD:boolean = false;
  checkOpenTHPT:boolean = false;
  checkOpenTHCS:boolean = false;
  checkOpenTH:boolean = false;
  checkOpenMG:boolean = false;
  countQuanHuyen:number = 0;
  countPhuongXa:number = 0;
  countTinhTP:number = 0;
  dataQuanHuyen = [];
  dataPhuongXa = [];
  checkClickLv1:boolean = false;
  checkClickLv2:boolean = false;
  parent_lv2:string='';
  parent_lv3:string='';

  ngOnInit(){
    this.getDataDetailLocation();
  }


  typeModule = 'location';

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataDetailLocation();
      }
    });
  }

  getDataDetailLocation(){
    this.isLoading = true;
    this.idLocationLv1 = this.activatedRoute.snapshot.params.id;
    this.administrativeUnitService.getDetailLocation(this.idLocationLv1).subscribe(
      (res:any)=>{
        this.dataDetailLocation = res.data;
        this.codeLocationLv1 = res.data.id;
        this.getLocationLv2(res.data.id);
        this.isLoading = false;
      },
      (err)=>{
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  openLv3(idLevel2){
    if(this.lineLv3){
      this.lineLv3?.forEach(e=>{
        e?.remove();
      })
    }
    this.lineLv3 = [];
    this.checkClickLv2 = false;
    this.countPhuongXa = 0;
    this.administrativeUnitService.getLocationLv3(idLevel2).subscribe(
      (res:any)=>{
        this.id_active_lv_2 = idLevel2;
        if(res.data){
          this.countPhuongXa = res.data.length;
          (res.data).forEach((e,index)=>{
            setTimeout(() => {
              this.lineLv3.push(
                new LeaderLine(
                  document.getElementById(idLevel2),
                  document.getElementById(e.id),
                  {color: 'black',size:1,path:'grid',endPlug:'behind'}
                )
              );
              this.lineLv3[index].position();
            }, 20);
          });
          document.getElementById('content__education-lv1').addEventListener('scroll', ()=>{
            if(this.lineLv3){
              this.lineLv3?.forEach(e=>{
                e?.position();
              })
            }
          });
          this.dataPhuongXa = res.data;
          this.checkClickLv1 = true;
          this.checkClickLv2 = true;
        }
      },
      (err)=>{
      }
    )
  }

  getLocationLv2(id:any){
    this.administrativeUnitService.getLocationLv2(id).subscribe(
      (res:any)=>{
        this.dataQuanHuyen = res.data;
        this.countQuanHuyen = res.data.length;
        this.check_child_lv_1 = this.checkDataLv1(res.data,id);
        this.id_active = id;
        this.close_lv_2 = true;
      },
      (err)=>{

      }
    )
  }

  // click level 1
  activeLv1(code:string){
    
  }
  // click level 2
  activeLv2(idLevel2:string){
    this.administrativeUnitService.getLocationLv3(idLevel2).subscribe(
      (res:any)=>{
        this.dataSource2 = res.data;
        this.check_child_lv_2 = this.checkDataLv2(res.data,idLevel2);
        this.id_active_lv_2 = idLevel2;
        this.closeTree2(idLevel2);
        this.close_lv_3 = true;
      },
      (err)=>{

      }
    )
  }
  // click open close level 2
  closeTree(code:string){
    if(this.id_active == code){
      this.close_lv_2 = !this.close_lv_2;
    }else{
      this.activeLv1(code);
    }
  }
  // click open close level 3
  closeTree2(code:string,code_lv1?:string){
    if(this.id_active_lv_2 == code){
      this.close_lv_3 = !this.close_lv_3;
    }else{
      this.activeLv2(code);
      this.closeTree(code_lv1);
    }
  }

  checkDataLv1(data:any,id:string){
    let check = false;
    data.forEach(element => {
      if(element.cityId == id){
        check = true;
      }
    });
    return check;
  }

  checkDataLv2(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.districtId == code){
        check = true;
      }
    });
    return check;
  }

  toggleClass(value){
    this.dataSearch = '';
    this.showSearch = !this.showSearch;
    this.checkResultSearch = false;
  }

  clickEditEducationLv1(data:any){
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv1Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        administrative:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getDataDetailLocation();
        this.showMessage.success("Cập nhật thành công!");
      }
    });
  }

  clickEditEducationLv2(data:any){
    data.fullname = data.name;
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) this.checkFireBase('update');
    });
  }

  clickEditEducationLv3(data:any){
    data.fullname = data.name;
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getDataDetailLocation();
        this.showMessage.success("Cập nhật thông tin thành công!");
      }
    });
  }

  clickDeleteEducation(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa đơn vị',
        message:'Xác nhận xóa?',
        btnOkText:'Delete',
        btnCancelText:'Cancel',
        data: {
          id:data.id,
          name:data.fullname
        }
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.isLoading = true;

        this.administrativeUnitService.deleteLocation(data.id).subscribe(
          (res:any)=>{
            this.isLoading = false;
            this.checkFireBase('delete')

          },
          (err)=>{
            this.isLoading = false;
          }
        )
      } 
      
    })

  }

  onSearchChange(searchValue: string): void {  
    this.dataSearch = searchValue;
  }

  clickAddEucationLv2(){
    this.administrativeUnitService.generateCode('DVHC').subscribe(
      (res:any)=>{
        const dialogRef = this.dialog.open(DialogFormAdministrativeLv2Component,{
          height:'auto',
          width:'80%',
          data: {
            status:"add",
            code:res.data,
            parent:{
              code:this.dataDetailLocation.code,
              name:this.dataDetailLocation.fullname
            }
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result) this.checkFireBase('create');
        });
      },
      (err)=>{

      }
    )
  }

  clickMoveAdminstrativeLv2(data:any){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeComponent, {
      height:'auto',
      width:'30%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result) this.checkFireBase('update_administrative_units');
      }
    )
  }

  clickMoveAdminstrativeLv3(data){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeLv3Component, {
      height:'auto',
      width:'80%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });
  }

  searchFocusOut(txtSearch:string='',unit: any){
    this.administrativeUnitService.filterLocation(txtSearch,Number(unit),this.dataDetailLocation.code,'').subscribe(
      (res:any)=>{
        this.resultSearch = res.data;
        this.checkResultSearch = true;
        switch (Number(unit)) {
          case 2:
            this.linkDetailEducation = '/administrative-unit/lv2/detail/';
            break;
          default:
            this.linkDetailEducation = '/administrative-unit/lv3/detail/';
            break;
        }
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeLineLeader(){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = [];
    this.lineLv3 = [];
  }

}

