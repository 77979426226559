import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../../_shared/dialogs/delete/dialog-delete.component';
import { DialogTenantEditModuleComponent } from '@app/_modules/tenants-manager/dialog/dialog-tenant-edit-module/dialog-tenant-edit-module.component';
import { TenantManagerService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {TENANT_PERMISSION} from '../../../constants';

@Component({
  selector: 'app-tab-tenant-module-list',
  templateUrl: './tab-tenant-module-list.component.html',
  styleUrls: ['./tab-tenant-module-list.component.scss']
})
export class TabTenantModuleListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'tenant_name_module', 'tenant_code_module', 'role_apply', 'action'];

  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  dataSource: any;
  // check pagination
  public isPagination = false;
  public isTable = false;
  keyword: string = '';
  typeModule = 'tenant_module';
  isLoadingResults = false
  TENANT_PERMISSION = TENANT_PERMISSION;

  constructor(
    public dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private activatedRoute: ActivatedRoute,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService
  ) { }
  tenantId = '';

  ngOnInit(): void {
    this.listModules();
  }

  listModules(search?: string, filter?: []) {
    this.isLoadingResults = true;

    this.activatedRoute.paramMap.subscribe((res: any) => {
      this.tenantId = res.params.id;
      search = search ? search : '';
      filter = filter ? filter : [];
      this.tenantManagerService.getAllListModule(this.pageSize, this.pageIndex, search, filter, this.tenantId).subscribe((ref: any) => {
        this.dataSource = ref.data.data;
        this.totalRecord = ref.data.totalItems;
        this.isLoadingResults = false;

      });
    });
  }

  onChangePaginate(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.listModules();
  }

  openDialogDelete(value) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xóa module',
        message: 'Xác nhận xóa?',
        btnOkText: 'Delete',
        btnCancelText: 'Cancel',
        data: {
          id: value.id,
          name: value.tenant_name_module
        }
      }
    });
  }

  showFormEditModule() {
    const dialogRef = this.dialog.open(DialogTenantEditModuleComponent, {
      height: 'auto',
      width: '70%',
      panelClass: 'custom-modalbox',
      data: {
        tenantId: this.tenantId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.listModules();
    });
  }

  confirmDelete(item: any) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện gỡ module ra khỏi danh sách áp dụng',
        message: 'Bạn có chắc muốn gỡ module này không ?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'HỦY',
        data: {
          id: item.id,
          name: item.fullname
        },
      }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm === true) {
        this.isLoadingResults = true;
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
        });

        listenFb.subscribe((result) => {
          if (result === true) {
            this.listModules();
          }
          this.isLoadingResults = false;
        });

        this.tenantManagerService.deleteModuleTenant(this.tenantId, item.id).subscribe((res: any) => {
          if (res.status == 0) {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
              this.showMessage.error(res.msg);
            }
          }
        }, error => {
          this.isLoadingResults = false;
        });
        setTimeout(() => {
          if (this.isLoadingResults) {
            this.isLoadingResults = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }


    });
  }

  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.pageIndex = 0;
      this.listModules(this.keyword);
    }
  }

  onBlur(event) {
    event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
    if (event.type === 'blur') {
      this.pageIndex = 0;
      this.listModules(this.keyword);
    }
  }

}
