import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-renameDialog',
  templateUrl: './renameDialog.component.html',
  styleUrls: ['./renameDialog.component.css']
})
export class RenameDialogComponent implements OnInit {
  folderName: string;

  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.folderName = data?.name;
    }


  ngOnInit() {}
}
