import {Component, OnInit} from '@angular/core';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogPostManagerListUserComponent} from '@app/_modules/post-manager/dialogs/dialog-post-manager-list-user/dialog-post-manager-list-user.component';
import {PostManagerService} from '@app/_services/post-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommentService} from '@app/_services';
import {AccountManagerService} from '@app/_services';
import {ShowMessage} from "@app/_services";
import {PersonalService} from '@app/_services';

import * as _ from 'lodash';
import * as $ from 'jquery';
import * as moment from 'moment';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {POST_PERMISSION} from "../../constants";

@Component({
    selector: 'app-post-manager-detail',
    templateUrl: './post-manager-detail.component.html',
    styleUrls: ['./post-manager-detail.component.scss']
})
export class PostManagerDetailComponent implements OnInit {
    hidden: false;
    postId: string;
    dataInfoPost;
    isLoading = false;
    infoUser;
    listCommentParent = [];
    listCommentChild = [];
    POST_PERMISSION = POST_PERMISSION;
    dataStatusSent = [
        {fullName: 'Mới tạo', color: '#1355C1'},
        {fullName: 'Đang gửi', color: '#8950FC'},
        {fullName: 'Gửi thành công', color: '#FF8038'},
        {fullName: 'Gửi lỗi', color: '#FF4848'},
    ];

    objects = ['Tất cả', 'Người dùng', 'Khách hàng',];
    senderRanges = ['Tất cả', 'Người dùng', 'Giáo viên', 'Giáo viên', 'Học sinh']

    constructor(
        private dialog: MatDialog,
        private postManagerService: PostManagerService,
        private activeRoute: ActivatedRoute,
        private commentService: CommentService,
        private accountManagerService: AccountManagerService,
        private showMessage: ShowMessage,
        private personalService: PersonalService,
        private db: AngularFireDatabase,
        private route: Router,
    ) {
    }

    ngOnInit(): void {
        this.activeRoute.paramMap.subscribe((paramMap: any) => {
            this.postId = paramMap.params.id;
            this.getInfoPost();
            this.getLisCommentByPostId();
        });
        this.getInfoUser();
    }

    checkFireBase(param, nameModule = null){
        nameModule === null ? nameModule = 'comment' : nameModule;
        return this.db.list(`core_admin/${nameModule}/${param}/${environment.userID}`).valueChanges().subscribe((res: any) => {
            if (res.length > 0) {
                let parseRes = JSON.parse(res[0]);
                if (parseRes.status === true) {
                    this.showMessage.success(parseRes.message);
                    if (nameModule === 'post') this.route.navigate(['post-manager']);

                    if (nameModule === 'comment') this.getLisCommentByPostId();
                    return this.db.list(`core_admin/${nameModule}/${param}/${environment.userID}`).remove();
                }

                return this.showMessage.error(parseRes.message);
            }
        });
    }

    getInfoPost() {
        this.postManagerService.getPostById(this.postId).subscribe((res: any) => {
            this.dataInfoPost = res.data;
            return this.isLoading = false;
        });

    }

    getInfoUser(){
        let token = JSON.parse(localStorage.getItem('currentUser'));
        this.personalService.getInfoUserByAccessToken(token).subscribe((res: any) => {
            if (res.status === 1){
                this.infoUser = res.data;
                return this.getInfoPost();
            }

            return this.showMessage.error(res.msg);
        }, err => {
            return this.showMessage.error(err);
        });
    }

    updateComment(infoComment: any, event){
        if (event.code === 'Enter'){
            let data = {
                postId: this.postId,
                parentCommentId: infoComment.parentCommentId,
                avatar: this.infoUser.avatar,
                fullName: this.infoUser.fullname,
                content: event.target.value,
            }

            this.commentService.updateComment(infoComment.id, data).subscribe((res: any) => {
                this.checkFireBase('update');
            });
        }
    }

    parseMinute(param){
        let totalTime: number;
        let yearNow = moment().utc().format('YYYY');
        let mouthNow = moment().utc().format('MM');
        let dayNow = moment().utc().format('DD');
        let hoursNow = moment().utc().format('hh');
        let minuteNow = moment().utc().format('mm');
        let secondsNow = moment().utc().format('ss');

        let yearCreateAt = moment(param*1000).utc().format('YYYY');
        let mountCreateAt = moment(param*1000).utc().format('MM');
        let dayCreateAt = moment(param*1000).utc().format('DD');
        let hoursCreateAt = moment(param*1000).utc().format('hh');
        let minuteCreateAt = moment(param*1000).utc().format('mm');
        let secondsCreateAt = moment(param*1000).utc().format('ss');
        
        if(yearNow > yearCreateAt){
            return parseInt(yearNow) - parseInt(yearCreateAt) +' '+ 'năm trước';
        }
        
        if (yearNow === yearCreateAt){
            if (mouthNow > mountCreateAt) {
                return parseInt(mouthNow) - parseInt(mountCreateAt) +' '+ 'tháng trước';
            }
            
            if(mountCreateAt === mouthNow){
                if (dayNow > dayCreateAt){
                    return parseInt(dayNow) - parseInt(dayCreateAt) +' '+ 'ngày trước';
                }

                if (dayCreateAt === dayNow){
                    if (hoursNow > hoursCreateAt) return parseInt(hoursNow) - parseInt(hoursCreateAt) + ' ' + 'giờ trước';
                    if (hoursNow === hoursCreateAt) {
                        if (minuteNow > minuteCreateAt) return parseInt(minuteNow) - parseInt(minuteCreateAt) + ' ' + 'phút trước';

                        return parseInt(secondsNow) - parseInt(secondsCreateAt) + ' ' + 'giây trước';
                    }
                }
            }
        }
    }

    removeComment(id: string){
        const dialogConfirmDelete = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '30%',
            data: {
                title: 'Bạn đang thực hiện xóa bài viết',
                message: 'Bạn có chắc muốn xóa bài viết này không ?',
                btnOkText: 'XÓA',
                btnCancelText: 'HỦY',
                data: {
                    name: '',
                }
            }
        });

        dialogConfirmDelete.afterClosed().subscribe((confirm: any) => {
            if (confirm){
                this.commentService.removeComment(id).subscribe((res: any) => {
                    if (res.status === 0) return this.showMessage.error(res.msg);
                    return this.checkFireBase('delete');
                });
            }
        });
    }

    cancelComment(keyComment, nameComment){
        if (nameComment === 'reply') return this.listCommentParent[keyComment].isChecked = false;

        if (nameComment === 'listCommentChild') return this.listCommentChild[keyComment].isEditable = false;

        return  this.listCommentParent[keyComment].isEditable = false;
    }

    editComment(keyComment, nameComment){
        if (nameComment === 'listCommentParent'){
            this.listCommentParent[keyComment].isEditable = true;
        }else {
            this.listCommentChild[keyComment].isEditable = true;
        }
    }

    sentComment(parentCommentId: string | null, event) {
        if (event.code === 'Enter') {
            let data = {
                postId: this.postId,
                parentCommentId: parentCommentId,
                avatar: 'string',
                fullName: this.infoUser.fullName,
                content: event.target.value,
            }

            this.commentService.storeComment(data).subscribe((res: any) => {
                this.checkFireBase('create');
            });
        }
    }

    getLisCommentByPostId() {
      let dataParent = [];
      let dataChild = [];
        this.commentService.getAllCommentByPostId(this.postId).subscribe((res: any) => {
            res.data.forEach(item => {
                if (item.parentCommentId === item.id || item.parentCommentId === null){
                    item.isChecked = false;
                    item.isEditable = false;
                    dataParent.push(item);
                }else {
                    dataChild.push(item)
                    item.isEditable = false;
                }
            });

            this.listCommentParent = dataParent;
            this.listCommentChild = dataChild;
        });
    }

    reply(keyComment: number) {
        this.listCommentParent[keyComment].isChecked = true;
    }

    confirmHidden(itemName: string) {
        const dialogConfirmHidden = this.dialog.open(DialogChangeActiveComponent, {
            height: 'auto',
            width: '30%',
            data: {
                title: 'Bạn đang thực hiện ẩn bài viết',
                content: 'Bạn có chắc muốn ẩn bài viết này không ?',
                status: false,
                btnCancelText: 'HỦY',
                btnOkText: 'XÁC NHẬN',
                data: {
                    name: itemName,
                }
            }
        });
    }

    confirmDelete(infoPost) {
        const dialogConfirmDelete = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '30%',
            data: {
                title: 'Bạn đang thực hiện xóa bài viết',
                message: 'Bạn có chắc muốn xóa bài viết này không ?',
                btnOkText: 'XÓA',
                btnCancelText: 'HỦY',
                data: {
                    name: infoPost.title,
                }
            }
        });

        dialogConfirmDelete.afterClosed().subscribe((ref: any) => {
            if (ref === true){
                this.postManagerService.deletePostManager(infoPost.id).subscribe((res: any) => {
                    this.checkFireBase('delete', 'post');
                });
            }
        });
    }

    confirmSendTo(itemName: string) {
        const dialogConfirmSend = this.dialog.open(DialogChangeActiveComponent, {
            height: 'auto',
            width: '30%',
            data: {
                title: 'Bạn đang thực hiện gửi lại bài viết',
                content: 'Bạn có chắc muốn gửi lại bài viết này không ?',
                status: true,
                btnCancelText: 'HỦY',
                btnOkText: 'XÁC NHẬN',
                data: {
                    name: itemName,
                }
            }
        });
    }

    confirmCancelSend(itemName: string) {
        const dialogConfirmCancelSend = this.dialog.open(DialogChangeActiveComponent, {
            height: 'auto',
            width: '30%',
            data: {
                title: 'Bạn đang thực hiện huỷ gửi bài viết',
                content: 'Bạn có chắc muốn huỷ gửi bài viết này không ?',
                status: false,
                btnCancelText: 'HỦY',
                btnOkText: 'XÁC NHẬN',
                data: {
                    name: itemName,
                }
            }
        });
    }

    showListUser() {
        const showList = this.dialog.open(DialogPostManagerListUserComponent, {
            height: 'auto',
            width: '60%',
            data: {
                postId: this.postId
            }
        });
    }
}
