<div fxLayout="column" class="bg-content-global">
    <div fxFlex="6rem" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span class="title-page-global">Danh sách khách hàng</span>
            </div>
            <div class="example-loading-shade" *ngIf="isLoadingResults">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            </div>
            <div>
                <input [(ngModel)]="keyword" #search id="search-value" (keypress)="onKeyPressSearch($event)"
                    [style.color]="showSearch ? '#A1A1A1' : '#FFFFFF' " (blur)="onBlur($event)" type="text"
                    class="text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}"
                    [readOnly]="showSearch == true ? false: true"
                    placeholder="{{showSearch == true ? 'Tìm kiếm theo Tên khách hàng, Mã khách hàng, Tên miền, Số điện thoại hoặc Email' : ''}}">
                <img src="assets/images/svg/search.svg" alt=""
                    class="icon-search {{ showSearch == true ? 'active_filter' : '' }}"
                    (click)="toggleClass(search.value)">

                <button class="btn btn-default btn-filter">
                    <img src="assets/images/svg/filter_img.svg" alt=""
                        class="{{ showFilter == true ? 'active_filter' : '' }}" (click)="showFilterClick()">
                </button>
                <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm2]">
                    <button class="btn btn-add-global" mat-raised-button (click)="openDialogCreateTenant()"><img
                            src="assets/images/svg/add.svg"> &nbsp;Thêm khách hàng</button>
                </ng-template>

            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-end center" class="mt-2 filter-section" style="padding-right: 40px;"
            *ngIf="showFilter">
            <button *ngFor="let item of statusUser"
                [ngStyle]="searchStatus.includes(item.id) && {'background': item.color}"
                class="btn btn-default mr-2 btn-filter-option"
                (click)="itemOptionClick($event, item.id)">{{item.name}}</button>
            <!-- this.searchStatus -->
            <button class="btn btn-default mr-2 btn-filter-option" mat-raised-button color="primary"
                (click)="filterSubmit()">Lọc</button>
        </div>
    </div>
    <div fxFlex class="content-data">
        <table mat-table [dataSource]="data_tenant" matSort class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;">STT</th>
                <td mat-cell *matCellDef="let item;let i = index">{{(pageSize * (pageIndex)) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Tên khách hàng</th>
                <td mat-cell *matCellDef="let item">
                    <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm4]">
                        <a class="link_table" href="javascript:;" (click)="opendetaiTenant(item.id,'tab1');">
                            <p class="ellipsis-text">{{ item.fullname }}</p>
                        </a>
                    </ng-template>
                    <ng-template [ngxPermissionsExcept]="[TENANT_PERMISSION.perm4]">
                        <p class="ellipsis-text">{{ item.fullname }}</p>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Mã khách hàng</th>
                <td mat-cell *matCellDef="let item">{{ item.code }}</td>
            </ng-container>
            <ng-container matColumnDef="tenantUrl">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Tên miền</th>
                <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;"
                        (click)="goToUrl(item.tenantUrl)">{{ item.tenantUrl }}</a></td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 14%;">Số điện thoại</th>
                <td mat-cell *matCellDef="let item">{{ item.hotline }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 14%;">Email</th>
                <td mat-cell *matCellDef="let item">{{ item.email }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Trạng thái</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                        <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm3]">
                            <!-- <select class="form-control col-md-8 btn-status" #status
                                (change)="dialogOpenChangeStatus(element, status, $event)"
                                [ngStyle]="{'background': statusUser[element.statusIndex].color}">
                                <ng-container *ngFor="let item of statusUser">
                                    <option class="font-option" style="background-color: #33CC33" [value]="item.id"
                                        [selected]="item.id == element.status">{{item.name}}</option>
                                </ng-container>
                            </select> -->
                            <mat-select class="form-control col-md-8 btn-status" #status [value]="element.status" (selectionChange)="dialogOpenChangeStatus(element, $event)" [ngStyle]="{'background': statusUser[element.statusIndex].color}">
                                <ng-container *ngFor="let item of statusUser">
                                    <mat-option class="font-option" [value]="item.id" >{{item.name}}</mat-option>
                                </ng-container>
                            </mat-select>

                        </ng-template>
                        <ng-template [ngxPermissionsExcept]="[TENANT_PERMISSION.perm3]">
                            <!-- <select disabled class="form-control col-md-8 btn-status" #status
                                (change)="dialogOpenChangeStatus(element, status, $event)"
                                [ngStyle]="{'background': statusUser[element.statusIndex].color}">
                                <ng-container *ngFor="let item of statusUser">
                                    <option class="font-option" style="background-color: #33CC33" [value]="item.id"
                                        [selected]="item.id == element.status">{{item.name}}</option>
                                </ng-container>
                            </select> -->
                            <mat-select disabled class="form-control col-md-8 btn-status" #status [value]="element.status" (selectionChange)="dialogOpenChangeStatus(element, $event)" [ngStyle]="{'background': statusUser[element.statusIndex].color}">
                                <ng-container *ngFor="let item of statusUser">
                                    <mat-option class="font-option" [value]="item.id" >{{item.name}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </ng-template>

                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action_table" style="width: 8%;">Công cụ</th>
                <td mat-cell *matCellDef="let item" class="action_table_td">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm3]">
                            <button mat-menu-item class="px-5" (click)="openDialogUpdateTenant(item);">Chỉnh
                                sửa</button>
                        </ng-template>
                        <ng-template>
                            <button mat-menu-item (click)="opendetaiTenant(item.id,'tab2');" class="px-5">Cấu
                                hình</button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm21]">
                            <button mat-menu-item (click)="opendetaiTenant(item.id,'tab3');" class="px-5">Gói áp
                                dụng</button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm22]">
                            <button mat-menu-item class="text-danger px-5"
                                (click)="openDialogDelete(item);">Xóa</button>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
        <ng-container *ngIf="totalRecord > pageSize">

        <mat-paginator  showFirstLastButtons appStylePaginator class="table-paginate-global" [length]="totalRecord" [pageIndex]="pageIndex"
            [pageSize]="pageSize" (page)="onChangePaginate($event)"></mat-paginator>
        </ng-container>
        </div>
</div>