import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Grade } from '@app/_models/grade';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).access_token: null}`,
  })
};

@Injectable({
  providedIn: 'root'
})
export class GradeManagerService {

  constructor(private http: HttpClient) { }

  indexGrade(data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/index`,data);
  }

  storeGrade(data: Grade){
    return this.http.post(`${environment.coreApiUrl}/api/grade/store`, data, httpOptions);
  }

  updateGrade(data: any){
    return this.http.post(`${environment.coreApiUrl}/api/grade/update`, data, httpOptions);
  }

  deleteGrade(idGrade: string){
    return this.http.post(`${environment.coreApiUrl}/api/grade/delete`,{id: idGrade}, httpOptions);
  }

  getTenantByGradeId(idGrade: string){
    return this.http.post(`${environment.coreApiUrl}/api/grade/tenants/${idGrade}`, httpOptions);
  }

  detailGrade(idGrade: string){
    return this.http.get(`${environment.coreApiUrl}/api/grade/detail/${idGrade}`, httpOptions);
  }

  getTenantNotAssign(idGrade: string,data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/not-assigned-tenants/${idGrade}`,data, httpOptions);
  }

  filterGrade(dataFilter: any){
    return this.http.post(`${environment.coreApiUrl}/api/grade/index`, dataFilter, httpOptions);
  }

  getSubjectsByGradeId(idGrade: string){
    return this.http.post(`${environment.coreApiUrl}/api/grade/subjects/${idGrade}`, httpOptions);
  }

  getSubjectNotAssign(idGrade:string,data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/not-assigned-subjects/${idGrade}`,data, httpOptions);
  }

  addTenantToGrade(data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/assign-tenant`,data, httpOptions);
  }

  addSubjectToGrade(data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/assign-subject`,data, httpOptions);
  }

  removeTenantFromGrade(data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/remove-tenant`,data, httpOptions);
  }

  removeSubjectFromGrade(data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/remove-subject`,data, httpOptions);
  }

  filterTenantOfGrade(idGrade,data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/tenants/${idGrade}`,data, httpOptions);
  }

  filterSubjectOfGrade(idGrade,data){
    return this.http.post(`${environment.coreApiUrl}/api/grade/subjects/${idGrade}`,data, httpOptions);
  }
}
