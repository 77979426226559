<div fxLayout="column" class="background-content" style="height: calc(100vh - 100px); overflow-y: auto">
    <div fxLayout="row" fxLayoutAlign="space-between start " class="custom-header">
        <div class="custom-title">
            <p class="title-page-global title-custom">Năm học - {{infoSchoolYear?.fullname}}</p>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div class="title-info">Thông tin cơ bản</div>
        <div>
            <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm4}}">
                <button class="btn btn-add-edit" (click)="openDialogUpdateSchoolYear()">
                    <img src="assets/images/svg/edit-icon.svg">
                    Chỉnh sửa thông tin
                </button>
            </ng-template>
        </div>
    </div>
    <br>
    <div style="width: 100%; border-bottom: 1px solid #F2F3F8">
        <div class="col-md-12">
            <div class="row">
                <div class="form-group col-md-4 row row-global">
                    <label class="col-md-3 lb-global">Tên năm học</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div
                               class="form-control input-field-global" [innerHTML]="infoSchoolYear?.fullname">
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Mã năm học</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div
                              class="form-control input-field-global" [innerHTML]="infoSchoolYear?.code">
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Trạng thái kích hoạt</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <mat-checkbox class="custom-checkbox" disabled
                                      [checked]="infoSchoolYear?.isActive == 1? true: false">
                            Kích hoạt
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4 row row-global">
                    <label class="col-md-3 lb-global">Ngày bắt đầu</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div
                                class="form-control input-field-global" [innerHTML]="infoSchoolYear?.startDate | parseTime: 'DD/MM/YYYY'">
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Ngày kết thúc</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div class="form-control input-field-global" [innerHTML]="infoSchoolYear?.endDate | parseTime: 'DD/MM/YYYY'">
                        </div>

                    </div>
                </div>

                <div class="form-group col-md-4 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Trạng thái năm học</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <mat-checkbox class="custom-checkbox" disabled
                                      [checked]="infoSchoolYear?.status == 1 ? true: false">
                            Hiện tại
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm6}}">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div style="position: relative; right: 20px">
                <button class="btn btn-custom tab-active tab-for-permission">
                    Khách hàng
                </button>
            </div>
            <div>
            <span class="{{showSearch == true ? 'transition-search-show': ''}}">
                <input (keydown)="keyPressSearch($event)" #search type="text"
                       class="text-left btn btn-default button-search"
                       [value]="showSearch == true? dataSearch : ''"
                       [readOnly]="showSearch == true ? false: true"
                       placeholder="{{showSearch == true ? 'Tên, mã khách hàng, tên miền, SĐT hoặc email' : ''}}">
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search" (click)="toggleClass(search.value)">
                </span>
                <button class="{{ showFilter == true ? 'change-color-icon' : '' }} btn custom-filter"
                        (click)="showFilterClick()">
                    <img class="icon-filter" src="assets/images/svg/filter_img.svg" alt="">
                </button>
                <button class="btn btn-add-edit" (click)="openDialogAddCustomers()">
                    <img src="assets/images/svg/add.svg">
                    Thêm khách hàng
                </button>
            </div>
        </div>
        <div style="position: relative; right: 78px" fxLayout="row" fxLayoutAlign="flex-end center"
             *ngIf="showFilter">
            <ng-container *ngFor="let item of statusUser, let keyStatus = index">
                <button class="btn btn-default mr-2  btn-item-filter {{item.checked ? 'active-font' : ''}}" [style]="{background: item.checked ? item.color : '#F2F3F8'}" (click)="itemOptionClick(keyStatus)">
                    {{item.name}}
                </button>
            </ng-container>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button class="btn btn-filter-option custom-btn-filter" (click)="clickFilterStatus()">Lọc</button>
        </div>
        <div class="dialog-body">
            <div class="custom-scroll">
                <table mat-table [dataSource]="dataSource" class="table-el" *ngIf="!isLoading">
                    <ng-container matColumnDef="stt">
                        <th mat-header-cell *matHeaderCellDef> STT</th>
                        <td class="border-first-5" mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="fullname">
                        <th mat-header-cell *matHeaderCellDef> Tên khách hàng</th>
                        <td mat-cell *matCellDef="let element">
                            <a href="tenants-manager/detail/{{element.id}}?active=tab1" target="_blank">
                                {{element?.fullname}}
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef> Mã</th>
                        <td mat-cell *matCellDef="let element"> {{element?.code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="domain">
                        <th mat-header-cell *matHeaderCellDef> Tên miền</th>
                        <td mat-cell *matCellDef="let element"><a target="_blank" href="{{element?.tenantUrl}}">
                            {{element?.tenantUrl | substring: 20}}</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef>SĐT</th>
                        <td mat-cell *matCellDef="let element"> {{element?.hotline}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element">
                            {{element?.email}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start_date">
                        <th mat-header-cell *matHeaderCellDef>Ngày bắt đầu</th>
                        <td mat-cell *matCellDef="let element">
                            {{element?.startDate | parseTime : 'DD/MM/YYYY'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="to_date">
                        <th mat-header-cell *matHeaderCellDef>Ngày kết thúc</th>
                        <td mat-cell *matCellDef="let element">
                            {{element?.endDate | parseTime : 'DD/MM/YYYY'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%"> Trạng thái</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex">
                                <select disabled class="form-control col-md-8 btn-status" #status
                                        [ngStyle]="{'background': findStatusUser(element?.status).color}">
                                        <option class="font-option" selected>
                                            {{findStatusUser(element?.status).name}}
                                        </option>
                                </select>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tools">
                        <th class="text-right" mat-header-cell *matHeaderCellDef> Công cụ</th>
                        <td class="border-last-5" mat-cell *matCellDef="let element">
                            <button class="btn btn-block" style="width: 50px; float: right"
                                    (click)="openDialogDeleteTenant(element)">
                                <img class="icon-remove" src="assets/images/svg/delete.svg" alt="">
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <ng-container *ngIf="dataSource.length == 0">
                    <span>Chưa có dữ liệu</span>
                </ng-container>

            </div>
        </div>
    </ng-template>
</div>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
