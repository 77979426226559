// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './_shared/shared.module';
import { ErrorInterceptor } from './_helpers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DefaultLayoutComponent } from './_layouts/default-layout/default-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ResetPasswordComponent} from './components/forgot-password/reset-password/reset-password.component';
import {VerifyAccountComponent} from './components/forgot-password/verify-account/verify-account.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password/forgot-password.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import {TokenInterceptor} from './_helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {DetailAccountComponent} from './components/profile/info/components/detail-account/detail-account.component';
import {TabInfoUserComponent} from './components/profile/info/tabs/tab-info-user/tab-info-user.component';
import {TabManipulationHistoryComponent} from './components/profile/info/tabs/tab-manipulation-history/tab-manipulation-history.component';
import { DialogFormProfileComponent } from './components/profile/info/dialogs/dialog-form-profile/dialog-form-profile.component';
import { PostPersonalListComponent } from './components/profile/post-personal/components/post-personal-list/post-personal-list.component';
import { PostPersonalDetailComponent } from './components/profile/post-personal/components/post-personal-detail/post-personal-detail.component';
import { NotificationListComponent } from './components/profile/notification/components/notification-list/notification-list.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ShowMessageComponent } from './_shared/show-message/show-message.component';
// import firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment.firebase';
// end import firebase
import { FileService } from '@app/_services';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeComponent,
    NotFoundComponent,
    LoginComponent,
    AccessDeniedComponent,
    DefaultLayoutComponent,
    ResetPasswordComponent,
    VerifyAccountComponent,
    ForgotPasswordComponent,
    DetailAccountComponent,
    TabInfoUserComponent,
    TabManipulationHistoryComponent,
    DialogFormProfileComponent,
    PostPersonalListComponent,
    PostPersonalDetailComponent,
    NotificationListComponent,
    ShowMessageComponent,
    
  ],
  imports: [
    // BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    CKEditorModule,
    NgxPermissionsModule.forRoot(),
    NgbModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    FileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule{}
