import { Component, OnInit, Inject} from '@angular/core';
import {SchoolYearsManagerService} from '@app/_services';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-list-customers',
  templateUrl: './dialog-list-customers.component.html',
  styleUrls: ['./dialog-list-customers.component.scss']
})
export class DialogListCustomersComponent implements OnInit {
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'time'];
  dataSource = [];
  keyword = '';

  constructor(
      private schoolYearsManagerService: SchoolYearsManagerService,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems() {
    let data = {keyword: this.keyword, status: null};
    this.schoolYearsManagerService.getTenantOfSchoolYear(this.data.infoSchoolYear.id, data).subscribe((res: any) => {
      this.dataSource = res.data;
    });
  }

  keyPressSearch(event){
    if (event.code === 'Enter' || event.key==='Tab'){
      this.keyword = event.target.value;
      this.loadDataItems();
    }
  }
}
