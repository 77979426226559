import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';
import { DialogAddAccountAppliesComponent } from '../../dialogs/dialog-add-account-applies/dialog-add-account-applies.component';
import { DialogAddSubjectAppliesComponent } from '../../dialogs/dialog-add-subject-applies/dialog-add-subject-applies.component';
import { DialogFormComponent } from '../../dialogs/dialog-form/dialog-form.component';
import { GradeManagerService } from '@app/_services/grade.service';
import { ActivatedRoute } from '@angular/router';
import { ShowMessage } from '@app/_services';
import { MatTableDataSource } from '@angular/material/table';
import { ListenFirebaseService } from '@app/_services';
import {Observable, Subscriber} from 'rxjs';
import { DialogChangeActiveComponent } from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-detail-grade',
  templateUrl: './detail-grade.component.html',
  styleUrls: ['./detail-grade.component.scss']
})
export class DetailGradeComponent implements OnInit {

  showSearch:boolean = false;
  showFilter:boolean = false;
  dataSearch:string = '';
  active_tab:boolean = true;
  dataGrade:any;
  idGrade:string;
  trainingLevel:string;
  isMoet:string;
  isLoading:boolean = false;
  listTenant:any;
  lengthTenant:number;
  listSubject:any;
  lengthSubject:number;
  statusSearchTenant=[];
  statusSearchSubject=[];
  checkActiveStatusSubject:boolean;
  txtSearchTenant:string;
  txtSearchSubject:string;
  activeTab1:boolean = true;
  activeTab2:boolean = false;

  constructor(
    public dialog: MatDialog,
    private gradeManagerService:GradeManagerService,
    private route: ActivatedRoute,
    private showMessage:ShowMessage,
    private listenFirebaseService:ListenFirebaseService
  ) { }

  ngOnInit(){
    this.idGrade = this.route.snapshot.paramMap.get('id');
    this.getListTenantOfGrade();
    this.loadDataItems(this.idGrade);
  }

  loadDataItems(idGrade){
    this.isLoading = true;
    // lay chi tiet thong tin khoi
    this.gradeManagerService
        .detailGrade(idGrade)
        .subscribe((data:any) => {
          this.dataGrade = data.data;
          switch (this.dataGrade.trainingLevel) {
            case 1:
              this.trainingLevel = 'Mầm non';
              break;
            case 2:
              this.trainingLevel = 'Tiểu học';
              break;
            case 3:
              this.trainingLevel = 'Trung học cơ sở';
              break;
            case 4:
              this.trainingLevel = 'Trung học phổ thông';
              break;
            default:
              this.trainingLevel = 'Khác';
              break;
          }

          switch (this.dataGrade.group) {
            case 0:
              this.isMoet = 'Khối MOET';
              break;
            default:
              this.getListSubjectOfGrade();
              this.activeTab2 = true;
              this.isMoet = 'Khối riêng khách hàng';
              break;
          }
          this.isLoading = false;
        },
        (error => {
          this.isLoading = false;
        })
      );
  }

  toggleClass(dataSearch){
    this.showSearch = !this.showSearch;
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
  }

  getListTenantOfGrade(){
    this.isLoading = true;
    this.gradeManagerService
        .getTenantByGradeId(this.idGrade)
        .subscribe((data:any) => {
          this.lengthTenant = data.data.length;
          this.listTenant = new MatTableDataSource(data.data);
          this.isLoading = false;
        },
        (error => {
          this.isLoading = false;
        })
      );
  }

  getListSubjectOfGrade(){
    this.isLoading = true;
    this.gradeManagerService
        .getSubjectsByGradeId(this.idGrade)
        .subscribe((data:any) => {
          this.lengthSubject = data.data.length;
          this.listSubject = new MatTableDataSource(data.data);
          this.isLoading = false;
        },
        (error => {
          this.isLoading = false;
        })
      );
  }

  clickTab1(){
    this.isLoading = true;
    this.active_tab = !this.active_tab;
    this.activeTab1 = true;
    this.activeTab2 = false;
    this.gradeManagerService
        .getTenantByGradeId(this.idGrade)
        .subscribe((data:any) => {
          this.lengthTenant = data.data.length;
          this.listTenant = new MatTableDataSource(data.data);
          this.isLoading = false;
        },
        (error => {
          this.isLoading = false;
        })
      );
  }

  clickTab2(){
    this.isLoading = true;
    this.active_tab = !this.active_tab;
    this.activeTab1 = false;
    this.activeTab2 = true;
    this.gradeManagerService
        .getSubjectsByGradeId(this.idGrade)
        .subscribe((data:any) => {
          this.lengthSubject = data.data.length;
          this.listSubject = new MatTableDataSource(data.data);
          this.isLoading = false;
        },
        (error => {
          this.isLoading = false;
        })
      );
  }

  itemOptionClickTenant(event,status){
    if(event.srcElement.classList.contains("active")){
      const index = this.statusSearchTenant.indexOf(status);
      if (index > -1) {
        this.statusSearchTenant.splice(index, 1);
      }
      event.srcElement.classList.remove("active");
    }else{
      this.statusSearchTenant.push(status);
      event.srcElement.classList.add("active");
    }
  }

  itemOptionClickSubjectActive(event,level){
    if(event.srcElement.classList.contains("active")){
      event.srcElement.classList.remove("active");
      const index = this.statusSearchSubject.indexOf(level);
      if (index > -1) {
        this.statusSearchSubject.splice(index, 1);
      }
    }else{
      this.checkActiveStatusSubject = true;
      this.statusSearchSubject.push(level);
      event.srcElement.classList.add("active");
    }
  }

  itemOptionClickSubjectNotActive(event,status){
    if(event.srcElement.classList.contains("active")){
      event.srcElement.classList.remove("active");
      this.statusSearchSubject = [];
    }else{
      this.checkActiveStatusSubject = false;
      this.statusSearchSubject = [];
      this.statusSearchSubject.push(status);
      event.srcElement.classList.add("active");
    }
  }

  clickDialogAddCustomer(idGrade:string){
    const dialogRefxxxx = this.dialog.open(DialogAddAccountAppliesComponent, {
          width: '60%',
          height: 'auto',
          data: {
            status: 'add',
            idGrade: idGrade
          },
          autoFocus:false
        });

        dialogRefxxxx.afterClosed().subscribe((res:any) => {
          if(res == false || typeof res === 'undefined'){
            return;
          }else{
            const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
              height: 'auto',
              width: '40%',
              data: {
                btnCancelText: 'Hủy',
                btnOkText: 'Xác nhận',
                status: false,
                title: 'Bạn đang thực hiện thêm khách hàng áp dụng khối',
                content:'Bạn có chắc muốn thực hiện thay đổi này không?',
                data: {
                  name: this.dataGrade.fullname,
                }
              }
            });
  
            confirmActive.afterClosed().subscribe((dialogResult: any) => {
              if(dialogResult){
                if(res == false) {
                  this.isLoading = false;
                }else{
                  const listenFb = new Observable((subscriber: Subscriber<any>) => {
                    this.listenFirebaseService.checkFireBase('create', 'grade_tenant', subscriber);
                  });
                  listenFb.subscribe((ref) => {
                    this.isLoading = false;
                      if (ref === true) {
                        this.getListTenantOfGrade();
                      }
                  });
                  this.isLoading = true;
                  this.gradeManagerService.addTenantToGrade(res).subscribe((res:any)=>{
                    if(res.status == 0){
                      this.isLoading = false;
                      this.showMessage.error(res.msg);
                    }
                  },
                  (err)=>{
                    this.isLoading = false;
                  });
                  setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                  }, TIME_OUT_LISTEN_FIREBASE);
                }
              }else{
                
              }
            });
          }
        }, err => {
          
        });
  }

  clickDialogAddSubject(idGrade:string){
    const dialogRef = this.dialog.open(DialogAddSubjectAppliesComponent, {
      width: '60%',
      height: 'auto',
      data: {
        status: 'add',
        idGrade: idGrade
      },
      autoFocus:false
    });
    dialogRef.afterClosed().subscribe((res:any) => {
      if(res == false || typeof res === 'undefined'){
        return;
      }else{
        const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
          height: 'auto',
          width: '40%',
          data: {
            btnCancelText: 'Hủy',
            btnOkText: 'Xác nhận',
            status: false,
            title: 'Bạn đang thực hiện thêm môn học trong khối',
            content:'Bạn có chắc muốn thực hiện thay đổi này không?',
            data: {
              name: this.dataGrade.fullname,
            }
          }
        });
  
        confirmActive.afterClosed().subscribe((dialogResult: any) => {
          if(dialogResult){
            if(res == false) {
              this.isLoading = false;
            }else{
              const listenFb = new Observable((subscriber: Subscriber<any>) => {
                this.listenFirebaseService.checkFireBase('create', 'grade_subject', subscriber);
              });
              listenFb.subscribe((ref) => {
                this.isLoading = false;
                  if (ref === true) {
                    this.getListSubjectOfGrade();
                  }
              });
              this.isLoading = true;
              this.gradeManagerService.addSubjectToGrade(res).subscribe((res:any)=>{
                if(res.status == 0){
                  this.isLoading = false;
                  this.showMessage.error(res.msg);
                }
              },
              (err)=>{
                this.isLoading = false;
              });
              setTimeout(() => {
                if(this.isLoading){
                  this.isLoading = false;
                }
              }, TIME_OUT_LISTEN_FIREBASE);
            }
          }else{
            
          }
        });
      }
    }, err => {
      
    });
  }

  openDialogChangeUpdateGrade(infoGrade){
    const dialogRef = this.dialog.open(DialogFormComponent, {
      width: '60%',
      height: 'auto',
      data: {
        status: 'update',
        infoGrade:infoGrade
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.isLoading = true;
      if(dialogResult){
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase('update', 'grade', subscriber);
        });
        listenFb.subscribe((ref) => {
          this.isLoading = false;
            if (ref === true) {
              this.loadDataItems(infoGrade.id);
            }
        });
        this.isLoading = true;
          this.gradeManagerService.updateGrade(dialogResult).subscribe((res:any)=>{
            if(res.status == 0){
              this.isLoading = false;
              this.showMessage.error(res.msg);
            }
          },
          (err)=>{
            this.isLoading = false;
          });
          setTimeout(() => {
            if(this.isLoading){
              this.isLoading = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
      }else{
        this.isLoading = false;
      }
    });
  }

  checkRemoveTenant(event){
    if(event !== false){
      const listenFb = new Observable((subscriber: Subscriber<any>) => {
        this.listenFirebaseService.checkFireBase('delete', 'grade_tenant', subscriber);
      });
      listenFb.subscribe((ref) => {
        this.isLoading = false;
          if (ref === true) {
            this.getListTenantOfGrade();
          }
      });
      this.isLoading = true;
      this.gradeManagerService.removeTenantFromGrade(event).subscribe((res: any)=>{
        if(res.status == 0){
          this.isLoading = false;
          this.showMessage.error(res.msg);
        }
      },
      (err) => {
        this.isLoading = false;
      });
      setTimeout(() => {
        if(this.isLoading){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    }
  }

  checkRemoveSubject(event){
    if(event !== false) {
      const listenFb = new Observable((subscriber: Subscriber<any>) => {
        this.listenFirebaseService.checkFireBase('delete', 'grade_subject', subscriber);
      });
      listenFb.subscribe((ref) => {
        this.isLoading = false;
        if (ref === true) {
          this.getListSubjectOfGrade();
        }
      });
      this.isLoading = true;
      this.gradeManagerService.removeSubjectFromGrade(event).subscribe((res: any) => {
        if (res.status === 0) {
          this.isLoading = false;
          this.showMessage.error(res.msg);
        }},
          (err) => {
              this.isLoading = false;
            });
      setTimeout(() => {
        if (this.isLoading) {
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
      }
  }

  clickFilterTenant(){
    this.isLoading = true;
      let data = {
        "keyword": this.txtSearchTenant,
        "status": this.statusSearchTenant.length !== 0 ? this.statusSearchTenant : null
      };
      setTimeout(() => {
        this.gradeManagerService.filterTenantOfGrade(this.idGrade,data).subscribe(
          (res:any)=>{
            this.lengthTenant = res.data.length;
            this.listTenant = new MatTableDataSource(res.data);
          },
          (err)=>{
          }
        );
        this.isLoading = false;
      }, 1000);
  }

  clickFilterSubject(){
    this.isLoading = true;
    let data = {
      "keyword": this.txtSearchSubject,
      "trainingLevel": this.statusSearchSubject.length !== 0 ? this.statusSearchSubject : null
    };
    setTimeout(() => {
      this.gradeManagerService.filterSubjectOfGrade(this.idGrade,data).subscribe(
        (res:any)=>{
          this.lengthSubject = res.data.length;
          this.listSubject = new MatTableDataSource(res.data);
        },
        (err)=>{
        }
      );
      this.isLoading = false;
    }, 1000);
  }

  onKeySearchTenant(event){
    this.txtSearchTenant = event.target.value;
    if(event.key == 'Enter' || event.key == 'Tab'){
        this.clickFilterTenant();
    }
  }

  onKeySearchSubject(event){
    this.txtSearchSubject = event.target.value;
    if(event.key == 'Enter' || event.key == 'Tab'){
      this.clickFilterSubject();
    }
  }
}

