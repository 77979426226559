<script src="module-detail.component.spec.ts"></script>
<div fxLayout="column" class="background-content" style="height: calc(100vh - 100px); overflow-y: auto">
    <div fxLayout="row" fxLayoutAlign="space-between start " class="custom-header">
        <div class="custom-title">
            <p class="title-page-global title-custom">Module - {{ infoModule?.fullname }}</p>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <p class="title-info">Thông tin cơ bản</p>
        <div>
            <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm4]">

            <button class="btn btn-add-edit" style="width: 201px" (click)="openDialogUpdateModule()">
                <img src="assets/images/svg/edit_module_icon.svg" alt="">
                Chỉnh sửa thông tin
            </button>
            </ng-template>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div style="width: 100%; border-bottom: 1px solid #F2F3F8;padding: 1rem 0;">
        <div class="row">
            <div class="col-md-3" fxLayout="row" fxLayoutAlign="center start">
                <img id="avatar" [src]="infoModule?.image != '' ? infoModule?.image : 'assets/images/png/avatar.png'" alt="">
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global">Tên module</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <span class="form-control input-field-global">{{ infoModule?.fullname }}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-6 ml-auto row row-global p-0">
                        <label class="col-md-3 offset-1 lb-global">Mã</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <span class="form-control input-field-global">{{ infoModule?.code }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global">Thứ tự sắp xếp</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <span class="form-control input-field-global">{{ infoModule?.indexOrder }}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-6 ml-auto row row-global p-0">
                        <label class="col-md-3 offset-1 lb-global">Nhóm module</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <span class="form-control input-field-global">{{ infoModule?.isApplyCore === 0 ? 'Khách hàng' : 'Core admin' }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global">Trạng thái</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <mat-checkbox disabled [checked]="infoModule?.isActive === 1">Kích hoạt</mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="row" fxLayout="row">
                    <div class="col-md-12 d-flex p-0">
                        <label style="width: 13%;" class="lb-global">Mô tả</label>
                        <div style="width: 90%;">
                            <span style="height: 80px;" class="form-control input-field-global">{{infoModule?.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div style="position: relative; right: 20px">
            <button (click)="changeTab('tab-permission')" class="mr-3 btn btn-custom {{activeTab === 'tab-permission' ? 'tab-active' : 'tab-default'}} tab-for-permission">Quyền
            </button>
            <button *ngIf="isCheckModule == true" (click)="changeTab('tab-customer')" class="btn btn-custom {{activeTab === 'tab-customer' ? 'tab-active' : 'tab-default'}} tab-for-customer">Khách hàng
            </button>
        </div>
        <div>
            <span class="{{!showSearch == true ? 'transition-search-show': ''}} {{ activeTab === 'tab-permission' ? 'custom-icon-tab1' : 'custom-icon-tab2' }}" (keydown)="checkText($event, search.value)">
                 <input #search type="text" id="search-value"  [readOnly]="!showSearch == true ? false: true" class="text-left btn btn-default button-search
                    {{!showSearch == true ? 'transition-search-show': ''}}" placeholder="{{activeTab === 'tab-permission' ? 'Tên hoặc mã quyền' : 'Tên hoặc mã khách hàng'}}">
                <img src="assets/images/svg/search.svg" alt="" class="icon-search active_filter" (click)="toggleClass()">
            </span>
            <button style="margin-right: -10px;" *ngIf="activeTab === 'tab-customer'" class="{{ showFilter == true ? 'change-color-icon' : '' }} btn custom-filter" (click)="showFilterClick()">
                <img class="icon-filter" src="assets/images/svg/filter_img.svg" alt="">
            </button>
            <button class="btn btn-add-edit" style="width: 200px" (click)="openDialogAdd()">
                <img src="assets/images/svg/add.svg" class="mr-2">{{activeTab === 'tab-permission' ? 'Thêm quyền' : 'Thêm khách hàng'}}
            </button>
        </div>
    </div>
    <div class="{{ activeTab === 'tab-customer' ? '' : 'hidden-icon' }}" style="position: relative; right: 78px;margin-top: 5px;" fxLayout="row" fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter">
        <ng-container *ngFor="let item of dataStatus, let i = index">
            <button *ngIf="i < dataStatus.length - 1" class="btn btn-default mr-2 btn-filter-option btn-item-filter {{item.checked === true ? 'tab-active' : ''}}" (click)="itemOptionClick($event, i)">{{item.name}}</button>
            <ng-container *ngIf="i === dataStatus.length - 1">
                <button class="btn btn-filter-option custom-btn-filter {{item.checked === true ? 'tab-active' : ''}}" (click)="filter()">Lọc</button>
            </ng-container>
        </ng-container>
    </div>

    <div>
        <app-tab-permission [search]="dataSearch" [moduleName]="infoModule?.fullname" [moduleId]="moduleId" [openDialogAdd]="openDialog" *ngIf="activeTab === 'tab-permission'"></app-tab-permission>
        <app-tab-list-customer-of-detail-module [moduleName]="infoModule?.fullname" [isChange]="isChange" [dataFilter]="dataFilter" [search]="dataSearch" [moduleId]="moduleId" [openDialogAdd]="openDialog" *ngIf="activeTab === 'tab-customer'" [moduleId]="moduleId"></app-tab-list-customer-of-detail-module>
    </div>
</div>