import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectsRoutingModule } from './subjects-routing.module';
import { SubjectListComponent } from './components/subject-list/subject-list.component';
import { SharedModule } from '../../_shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/_shared/material.module';
import { SubjectsDetailComponent } from './components/subjects-detail/subjects-detail.component';
import { SubjectsListTenantComponent } from './components/tabs/subjects-list-tenant/subjects-list-tenant.component';
import { SubjectsListCustomerComponent } from './components/tabs/subjects-list-customer/subjects-list-customer.component';
import { SubjectsListMoetComponent } from './components/tabs/subjects-list-moet/subjects-list-moet.component';
import { SubjectsListAllComponent } from './components/tabs/subjects-list-all/subjects-list-all.component';
import { DialogFormSubjectsManagerComponent } from './dialogs/dialog-form-subjects-manager/dialog-form-subjects-manager.component';
import { DialogSubjectListCustomersComponent } from './dialogs/dialog-subject-list-customers/dialog-subject-list-customers.component';
import { SubjectListGradeComponent } from './components/tabs/subject-list-grade/subject-list-grade.component';
import { DialogSubjectAddCustomersComponent } from './dialogs/dialog-subject-add-customers/dialog-subject-add-customers.component';
import { DialogSubjectAddGradesComponent } from './dialogs/dialog-subject-add-grades/dialog-subject-add-grades.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogSubjectListGradesComponent } from './dialogs/dialog-subject-list-grades/dialog-subject-list-grades.component';
@NgModule({
  declarations: [
      SubjectListComponent,
      SubjectsDetailComponent,
      SubjectsListTenantComponent,
      SubjectsListCustomerComponent,
      SubjectsListMoetComponent,
      SubjectsListAllComponent,
      DialogFormSubjectsManagerComponent,
      DialogSubjectListCustomersComponent,
      SubjectListGradeComponent,
      DialogSubjectAddCustomersComponent,
      DialogSubjectAddGradesComponent,
      DialogSubjectListGradesComponent
  ],
    imports: [
        CommonModule,
        SubjectsRoutingModule,
        FlexLayoutModule,
        SharedModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forChild()
    ]
})
export class SubjectsModule { }
