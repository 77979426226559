<div class="wrapper">
  <div class="title">
    <h3>Cấu hình private key upload file</h3>
    <div>
      <button (click)="generatePrivateKey()" class="btn mr-2">Tạo mã mới</button>
      <button (click)="add()" class="btn">Lưu</button>
    </div>
  </div>
  <!--  danh sach  -->
  <input [value]="privateKey ? privateKey : ''" type="text" placeholder="Nhập private key" class="form-control">
</div>

<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>