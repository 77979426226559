 <table mat-table [dataSource]="dataSource"  class="table-el">
     <ng-container matColumnDef="stt">
         <th mat-header-cell *matHeaderCellDef  style="width: 3%;">STT</th>
         <td mat-cell *matCellDef="let item, let stt = index">{{ stt + 1}}</td>
     </ng-container>
     <ng-container matColumnDef="fullname">
         <th mat-header-cell *matHeaderCellDef  style="width: 18%;">Tên người dùng</th>
         <td mat-cell *matCellDef="let item">{{ item?.fullName }}</td>
     </ng-container>
     <ng-container matColumnDef="username">
         <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Tài khoản</th>
         <td mat-cell *matCellDef="let item">{{ item?.userCode }}</td>
     </ng-container>
     <ng-container matColumnDef="phone">
         <th mat-header-cell *matHeaderCellDef  style="width: 10%;">SĐT</th>
         <td mat-cell *matCellDef="let item">{{ item?.phone }}</td>
     </ng-container>
     <ng-container matColumnDef="email">
         <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Email</th>
         <td mat-cell *matCellDef="let item">{{ item?.email }}</td>
     </ng-container>
     <ng-container matColumnDef="tool">
         <th mat-header-cell *matHeaderCellDef style="width: 3%;" class="text-right">Công cụ</th>
         <td mat-cell *matCellDef="let element" class="text-center" >
             <a href="javascript:;" (click)="confirmDelete(element)">
                 <img src="assets/images/icons/icon-22.svg">
             </a>
         </td>
     </ng-container>
     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
 </table>
 <p *ngIf="dataSource.length === 0">Không có dữ liệu</p>
 <div class="spinner" *ngIf="isLoading">
     <mat-spinner></mat-spinner>
 </div>
