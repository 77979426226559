<div fxLayout="column" style="height: 100%">
    <div fxFlex="85px" fxLayout="row" fxLayoutAlign="space-between start">
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm6, TENANT_PERMISSION.perm7, TENANT_PERMISSION.perm8]">

            <button class="mat-elevation-z"
                [class]="activeTab === 'subject' ? 'btn-tenant-title-focus' : 'btn-tenant-title'" mat-raised-button
                (click)="moveTab('subject')">Môn học</button>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm9, TENANT_PERMISSION.perm10, TENANT_PERMISSION.perm11]">

            <button class="mat-elevation-z"
                [class]="activeTab === 'grade' ? 'btn-tenant-title-focus' : 'btn-tenant-title'" mat-raised-button
                (click)="moveTab('grade')">Khối</button>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm12, TENANT_PERMISSION.perm13, TENANT_PERMISSION.perm14]">

            <button class="mat-elevation-z"
                [class]="activeTab === 'schoolYear' ? 'btn-tenant-title-focus' : 'btn-tenant-title'" mat-raised-button
                (click)="moveTab('schoolYear')">Năm học</button>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm15, TENANT_PERMISSION.perm16, TENANT_PERMISSION.perm17]">

            <button class="mat-elevation-z"
                [class]="activeTab === 'subsystems' ? 'btn-tenant-title-focus' : 'btn-tenant-title'" mat-raised-button
                (click)="moveTab('subsystems')">Phân hệ</button>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm18, TENANT_PERMISSION.perm19, TENANT_PERMISSION.perm20]">

            <button class="mat-elevation-z"
                [class]="activeTab === 'module' ? 'btn-tenant-title-focus' : 'btn-tenant-title'" mat-raised-button
                (click)="moveTab('module')">Module</button>
        </ng-template>
    </div>
    <div fxFlex>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm6]">
            <app-tab-tenant-subject-list *ngIf="activeTab === 'subject'"></app-tab-tenant-subject-list>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm9]">
            <app-tab-tenant-grade-list *ngIf="activeTab === 'grade'"></app-tab-tenant-grade-list>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm12]">
            <app-tab-tenant-school-year-list *ngIf="activeTab === 'schoolYear'"></app-tab-tenant-school-year-list>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm15]">
            <app-tab-tenant-role-list *ngIf="activeTab === 'subsystems'"></app-tab-tenant-role-list>
        </ng-template>
        <ng-template
            [ngxPermissionsOnly]="[TENANT_PERMISSION.perm18]">
            <app-tab-tenant-module-list *ngIf="activeTab === 'module'"></app-tab-tenant-module-list>
        </ng-template>
    </div>
</div>