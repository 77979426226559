import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../../../_services';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tab-tenant-mldlai-config',
  templateUrl: './tab-tenant-mldlai-config.component.html',
  styleUrls: ['./tab-tenant-mldlai-config.component.scss']
})
export class TabTenantMldlaiConfigComponent implements OnInit, AfterViewInit {

  constructor(private httpClient: HttpClient, private authSer: AuthenticationService
    ,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar

  ) {

  }

  getjwtsession() {
    // var jwt=localStorage.getItem('currentUser');
    // if(jwt==null|| jwt == undefined){
    //   localStorage.setItem("currentUser","M10MP32T9zf6XEu2+6cTo9Ea+ujlRkj+j1sJNJ6CxuXJdgv7Z/jyHuvWYRJng9BBz5769CmEfucTvOF3IKA9fKs8vpAaTnUyJ4CVpeoKOWI=");
    // }
    //return "M10MP32T9zf6XEu2+6cTo9Ea+ujlRkj+j1sJNJ6CxuXJdgv7Z/jyHuvWYRJng9BBz5769CmEfucTvOF3IKA9fKs8vpAaTnUyJ4CVpeoKOWI=";
    // "todo: get saved token after login succes from localstorage";

    return this.authSer.currentUserValue;
  }

  ktaMarkingEssayApiUrl = environment.coreApiUrl;// "http://127.0.0.1:5141";

  GptOpenAiApiKey: string = "";
  GptOpenAiChatApiUrl: string = "https://api.openai.com/v1/chat/completions";
  GptOpenAiModelName: string = "gpt-3.5-turbo";
  StringJsonConfig: string = "";
  GptOpenAiPricePerToken: string = "";

  isEnable: boolean = false;
  tenantId: any;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((res: any) => {

      this.tenantId = res.params.id;
    });
  }

  @Output('OnSaveChange') onSaveChange = new EventEmitter<any>();
  @Output('OnGetConfig') onGetConfig = new EventEmitter<any>();

  _buildHttpHeader(){
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', "Bearer " + this.getjwtsession());
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return headers;
  }

  ngAfterViewInit(): void {
  
    this.httpClient
      .get(this.ktaMarkingEssayApiUrl + "/api/mldlai/getgptconfig?tenantid=" + this.tenantId,
        { headers: this._buildHttpHeader(), observe: undefined }).subscribe((r: any) => {
          // console.log(r);
          this.GptOpenAiApiKey = r.data.gptOpenAiApiKey;
          this.GptOpenAiChatApiUrl = r.data.gptOpenAiChatApiUrl;
          this.GptOpenAiModelName = r.data.gptOpenAiModelName;
          this.isEnable = r.data && r.data.isDeleted == 0 ? true : false;
          this.GptOpenAiPricePerToken = r.data.gptPricePerToken;

          this.onGetConfig.emit(r);

        });
  }

  doSave() {

    this.httpClient
      .post(this.ktaMarkingEssayApiUrl + "/api/mldlai/savegptconfig", {
        chatApiUrl: this.GptOpenAiChatApiUrl,
        apiKey: this.GptOpenAiApiKey,
        modelName: this.GptOpenAiModelName,
        isEnable: this.isEnable,
        tenantId: this.tenantId,
        gptPricePerToken: parseFloat(this.GptOpenAiPricePerToken),
      }, { headers: this._buildHttpHeader(), observe: undefined }).subscribe((r: any) => {

        this.onSaveChange.emit(r);

        this.snackBar.open('Save success ' + this.GptOpenAiPricePerToken, 'Close', {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

      });
  }

  doEnable() {
    this.isEnable = true;
    this.snackBar.open('Enabled', 'Close', {
      duration: 500, // Duration in milliseconds
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

  }
  doDisable() {
    this.isEnable = false;
    this.snackBar.open('Disabled!', 'Close', {
      duration: 500, // Duration in milliseconds
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


}
