<div fxLayout="column" class="bg-content-global" *ngIf="dataDetailLocation">
    <div fxLayout="column" fxFlex class="content-declare">
        <div fxFlex="3rem" fxLayout="row" fxLayoutAlign="start start">
            <div>
                <h4 class="title-page-global">{{ dataDetailLocation.fullname }}</h4>
            </div>
        </div>
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div fxFlex fxLayout="column" class="content__education-lv1">
            <div class="info__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <h4 class="title__education-lv1">Thông tin cơ bản</h4>
                    <div class="btn-action-detail">
                        <ng-template ngxPermissionsOnly="['{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="menu-more-global">
                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                    <button mat-menu-item class="px-5" (click)="clickEditEducationLv3(dataDetailLocation);">Chỉnh sửa</button>
                                </ng-template>
                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                    <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv3(1);">Chuyển</button>
                                </ng-template>
                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                    <button mat-menu-item class="text-danger px-5" (click)="clickDeleteAdminstrative(dataDetailLocation);">Xóa</button>
                                </ng-template>
                            </mat-menu>
                        </ng-template>
                    </div>
                </div>
                <div class="form__info-education-lv1 row">
                    <div fxLayout="row" fxLayoutAlign="center center" class="col-md-2">
                        <div class="img__info-education-lv1">
                            <img [src]="dataDetailLocation.logo" alt="">
                        </div>
                    </div>
                    <div class="col-md-4 row custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Tên Phường/Xã</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [disabled]="true" [value]="dataDetailLocation.fullname" class="form-control input-field-global" placeholder="Nhập tên phường/xã">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trực thuộc Tỉnh/TP</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [disabled]="true" [value]="dataDetailLocation.cityId" class="form-control input-field-global" placeholder="Nhập tỉnh/tp">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Thứ tự sắp xếp</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" [disabled]="true" [value]="dataDetailLocation.indexOrder" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4 offset-1 custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Mã</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [disabled]="true" [value]="dataDetailLocation.code" class="form-control input-field-global" placeholder="Nhập mã">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trực Quận/Huyện</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [disabled]="true" [value]="dataDetailLocation.districtId" class="form-control input-field-global" placeholder="Nhập quận/huyện">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trạng thái</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <mat-checkbox [disabled]="true" [checked]="dataDetailLocation.status == 1 ? true : false"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
