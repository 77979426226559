<div fxLayout="column" class="bg-content-global">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <p class="title-page-global">Chi tiết người dùng</p>
            </div>
            <div>
                <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm4]">
                    <button mat-raised-button class="btn btn-add-edit" (click)="openDialogUpdateAccount(infoUser)" *ngIf="activeLink == 'info-account'"><img src="assets/images/svg/edit-icon.svg"> Chỉnh sửa thông tin</button>
                </ng-template>
                <div *ngIf="activeLink == 'decentralization-applies'">
                    <input #searchRole (keydown)="search($event);" type="text" class="text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}"  placeholder="{{showSearch == true ? 'Tên phân quyền và mã phân quyền' : ''}}" [readOnly]="showSearch == true ? false: true" >
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass(searchRole)">
                    <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm14]">
                        <button mat-raised-button class="btn btn-add-edit" (click)="openDialogAddDecentralized()"><mat-icon>add</mat-icon> Thêm phân quyền</button>
                    </ng-template>
                </div>

                <div *ngIf="activeLink == 'action-history'" fxLayout="row" fxLayoutAlign="end center"  >
                    <div>
                        <input #historySearch (keydown)="search($event);" type="text"
                               class="text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}"
                               [readOnly]="showSearch == true ? false: true"
                               placeholder="{{showSearch == true ? 'Tìm kiếm' : ''}}">
                        <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch == true ? 'active_filter' : '' }}"
                             (click)="toggleClass(historySearch)">
                    </div>
                    &nbsp;&nbsp;
                    <button  class="btn btn-default btn-filter">
                        <img src="assets/images/svg/filter_img.svg" alt=""
                             class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilterClick()">
                    </button>
                </div>

                <div *ngIf="activeLink == 'tenants'">
                    <input #searchTenant (keydown)="search($event);" type="text" class="text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}"  [readOnly]="showSearch == true ? false: true" placeholder="{{showSearch == true ? ' Tên, mã, SĐT, email người dùng,... ' : ''}}">
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass(searchTenant)">
                    <button class="btn btn-add-edit" mat-raised-button (click)="openDialogAddTenant()"><mat-icon>add</mat-icon> Thêm khách hàng</button>
                </div>

            </div>
        </div>
        <div *ngIf="showFilter && activeLink == 'action-history'" class="d-flex "  fxLayoutAlign="end center">
            <ng-container *ngIf="typeTime != 3 && typeTime !== ''">
                <div class="btn custom-filter-time mr-2" fxLayoutAlign="start center">
                   {{this.startDate | parseTime: 'hh:ss DD/MM/YYYY'}}
                </div>
                <div class="btn custom-filter-time mr-2" fxLayoutAlign="start center">
                    {{this.endDate | parseTime: 'hh:ss DD/MM/YYYY'}}
                </div>
            </ng-container>
            <ng-container *ngIf="typeTime == 3 && typeTime !== ''">
                &nbsp;
                <app-datetime-picker-toggle class="mr-2" [background]="'#FF8038'" [color]="'#FFFFFF'"></app-datetime-picker-toggle>
                -
                <app-datetime-picker-toggle class="ml-2" [background]="'#FF8038'" [color]="'#FFFFFF'"></app-datetime-picker-toggle>
                &nbsp;
            </ng-container>
            <select class="form-control col-md-2" id="exampleFormControlSelect1" (change)="changeTime($event)">
                <option value="">Lọc theo</option>
                <option value="0">Hôm nay</option>
                <option value="1">Tuần này</option>
                <option value="2">Tháng này</option>
                <option value="3">Khác</option>
            </select>
            &nbsp;
            &nbsp;
            <button class="btn btn-add-edit" mat-raised-button (click)="filter()">Lọc</button>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex style="overflow: auto;" *ngIf="!isLoading" class="{{showFilter && activeLink == 'action-history' ? 'mt-5' : ''}}">
        <div fxLayout="row" class="tab-group">
            <div fxFlex="25%" class="tab-left" fxLayout="column">
                <div fxFlex="40%" fxLayout="column" fxLayoutAlign="center center" class="tab-header">
                    <img id="custom-avatar-user" [src]="infoUser?.logo" alt="">
                    <h4>{{infoUser?.fullName}}</h4>
                    <button fxLayoutAlign="center center" class="btn custom-status" [ngStyle]="{background: statusUser[this.infoUser?.userStatus]?.color}">{{statusUser[this.infoUser?.userStatus]?.name}}</button>
                </div>

                <div fxFlex fxLayout="column" fxLayoutAlign="end" class="tab-footer">
                    <div fxLayout="column" fxLayoutAlign="start" class="tab-links">
                        <ng-container *ngFor="let link of links" >
                            <ng-template [ngxPermissionsOnly]="link.permission">
                                <div class="custom-link">
                                    <span (click)="changeTab(link.route)" class="{{activeLink == link.route ? 'tab-link-active' : ''}} custom-active-link"> {{link.name}} </span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                    <div class="custom-hr"></div>
                    <p class="hover-custom">Khởi tạo ngày: {{infoUser?.createdAtEpochTime | parseTime: 'HH:mm:ss DD/MM/YYYY'}}</p>
                    <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm5]">

                    <div (click)="openDialogDelete()" style="cursor: pointer; ">Xóa tài khoản</div>
                    </ng-template>
                </div>
            </div>
<!--            {{dataRoles | json}}-->
            <div fxFlex class="tab-right" *ngIf="!isLoading">
                <ng-container *ngIf="activeLink == 'info-account' && isLoading == false">
                    <app-account-info [dataLocation]="dataLocation" [infoUser]="infoUser"></app-account-info>
                </ng-container>

                <ng-container *ngIf="activeLink == 'decentralization-applies'">
                    <app-decentralization-applies [dataRoles]="dataRoles" [userId]="userId"  (pageIndex)="pageIndex" (checkRemoveRole)="checkRemoveRole($event);" (roleIds)="getInfoRoles($event)"></app-decentralization-applies>
                    <mat-paginator appStylePaginator *ngIf="dataRoles.totalItems > pageSize" class="table-paginate-global" showFirstLastButtons [length]="dataRoles.totalItems" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
                </ng-container>

                <ng-container *ngIf="activeLink == 'tenants'">
                    <app-accounts-tenant-list [dataTenant]="dataTenant " [userId]="userId"  (pageIndex)="pageIndex" (checkRemove)="checkRemove($event);"></app-accounts-tenant-list>
                    <mat-paginator appStylePaginator *ngIf="dataTenant.totalItems > pageSize" class="table-paginate-global" showFirstLastButtons [length]="dataTenant.totalItems" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
                </ng-container>
                <ng-container *ngIf="activeLink == 'action-history'  ">
                    <app-accounts-action-history [dataHistories]="dataHistories"  (pageIndex)="pageIndex" ></app-accounts-action-history>
                    <mat-paginator appStylePaginator *ngIf="dataHistories.totalItems > pageSize" class="table-paginate-global" showFirstLastButtons [length]="dataHistories.totalItems" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!--<div class="show-loading"-->
<!--     *ngIf="isLoading">-->
<!--    <mat-spinner></mat-spinner>-->
<!--</div>-->
