import { Injectable } from '@angular/core';
import { MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {ShowMessageComponent} from '@app/_shared/show-message/show-message.component';

@Injectable({
  providedIn: 'root'
})
export class ShowMessage {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private snackBar: MatSnackBar) { }

  success(message, paramDuration = null) {
    if(paramDuration === null)  paramDuration = 2000;

    this.snackBar.openFromComponent(ShowMessageComponent, {
      data: {
        status: 'success',
        message
      },
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['success-snackbar-global'],
      duration: paramDuration
    });
  }

  error(message, paramDuration = null) {
    if(paramDuration === null)  paramDuration = 2000;
    this.snackBar.openFromComponent(ShowMessageComponent, {
      data: {
        status: 'error',
        message
      },
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['error-snackbar-global'],
      duration: paramDuration
    });
  }

  warring(message, paramDuration = null) {
    if(paramDuration === null)  paramDuration = 2000;
    this.snackBar.openFromComponent(ShowMessageComponent, {
      data: {
        status: 'warring',
        message
      },
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: ['warring-snackbar-global'],
      duration: paramDuration
    });
  }

}
