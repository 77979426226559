import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from '../_services';
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authenticationService: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/login') || request.url.includes('/get-code') || request.url.includes('/confirm-code') || request.url.includes('/check-reset-code') || request.url.includes('/check-change-password')) {
        return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${this.authenticationService.currentUserValue}`,
        // Authorization: `Bearer ${this.authenticationService.currentUserValue}`
      }
    });
    return next.handle(request);
  }
}
