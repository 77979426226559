<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <p class="title-page-global">Khối</p>
            </div>
            <div *ngIf="activeTab === 'tab-for-customer'">
                <input id="search-value" (keydown)="checkText($event)" #search type="text" class="text-left btn btn-default button-search {{!showSearch == true ? 'transition-search-show': 'text-white'}}" [value]="!showSearch == true? keyword : ''" [readOnly]="!showSearch == true ? false: true"
                    placeholder="{{!showSearch == true ? 'Tên hoặc mã khối' : ''}}">
                <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ !showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass()">
                <button class="btn btn-default btn-filter">
                    <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter == true ? 'active_filter' : '' }}" (click)="showFilterClick()">
                </button>
                <button class="btn custom-btn-add-grade" mat-raised-button (click)="openDialogCreateGrade()"><mat-icon>add</mat-icon> Thêm khối mới</button>
            </div>
        </div>
    </div>
    <div fxFlex="50px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <button (click)="changeTab('tab-for-customer')" class="btn btn-default {{activeTab === 'tab-for-customer' ? 'tab-active' : 'tab-default'}} tab-for-customer">Khối học MOET</button>
                <button (click)="changeTab('tab-for-moet')" class="btn btn-default {{activeTab === 'tab-for-moet' ? 'tab-active' : 'tab-default'}} tab-for-moet">Khối học do khách hàng tạo</button>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-end center" style="padding-right: 100px;" *ngIf="showFilter">
                <ng-container>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,1)">Mầm non</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,2)">Tiểu học</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,3)">Trung học cơ sở</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,4)">Trung học phổ thông</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,99)">Khác</button>
                </ng-container>
                <button class="btn btn-add-global filter" (click)="filterDataGrade()">Lọc</button>
            </div>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex style="background-color: #FFFFFF;" class="content-data">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 5%;">STT</th>
                <td mat-cell *matCellDef="let item; let i = index">{{(pageSize * (pageIndex - 1)) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 25%;">Tên khối</th>
                <td mat-cell *matCellDef="let item">
                    <a class="text-link" [routerLink]="['/grades-manager/detail',item.id]">{{ item.fullname }}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;">Mã</th>
                <td mat-cell *matCellDef="let item">{{ item.code }}</td>
            </ng-container>
            <ng-container matColumnDef="training_level">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;">Cấp đào tạo</th>
                <td mat-cell *matCellDef="let item">
                    {{ item.trainingLevel == 1 ? 'Mầm non' : '' }} {{ item.trainingLevel == 2 ? 'Tiểu học' : '' }} {{ item.trainingLevel == 3 ? 'Trung học cơ sở' : '' }} {{ item.trainingLevel == 4 ? 'Trung học phổ thông' : '' }} {{ item.trainingLevel == 99 ? 'Khác' : ''
                    }}
                </td>
            </ng-container>
            <ng-container matColumnDef="total_customer">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;" class="{{ activeTab === 'tab-for-moet' ? 'd-none' : '' }}">Khách hàng</th>
                <td mat-cell *matCellDef="let item" class="{{ activeTab === 'tab-for-moet' ? 'd-none' : '' }}">
                    <button class="btn btn-total-apples" (click)="openDialogListCustomerApplies(item.id)" *ngIf="item.group == 0">
                        {{ item.tenantNumber }}
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="total_subject">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;">Môn học</th>
                <td mat-cell *matCellDef="let item">
                    <button class="btn btn-total-apples" (click)="openDialogSubjectpplies(item.id)">
                        {{ item.subjectNumber }}
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;">Trạng thái</th>
                <td mat-cell *matCellDef="let item">
                    <ng-template [ngxPermissionsExcept]="[permissionGrade.perm4]">
                        <mat-checkbox [disabled]="item.group != 0 ? true : false || item.canEdit == 1 ? false : true" [checked]="item.status == 1 ? true : false" (change)="clickChangeStatusGrade($event,item);">
                            {{lang.label.activated}}
                        </mat-checkbox>
                    </ng-template>
                    <ng-template [ngxPermissionsExcept]="[permissionGrade.perm4]" >
                        <mat-checkbox  [checked]="item.status == 1 ? true : false" disabled>
                            {{lang.label.activated}}
                        </mat-checkbox>
                    </ng-template>

                </td>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef class="action_table {{ activeTab === 'tab-for-moet' ? 'd-none' : '' }}" style="width: 10%;">Công cụ</th>
                <td mat-cell *matCellDef="let item" class="action_table_td {{ activeTab === 'tab-for-moet' ? 'd-none' : '' }}">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="item.group == 0">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <button *ngIf="item.canEdit == 1" mat-menu-item (click)="openDialogUpdateGrade(item)">
                            <span>Chỉnh sửa</span>
                        </button>
                        <button *ngIf="item.canDelete == 1" mat-menu-item class="text-danger" (click)="openDialogDelete(item)">
                            <span>Xóa</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
        <mat-paginator *ngIf="length > pageSize" appStylePaginator class="table-paginate-global" showFirstLastButtons [length]="length" (page)="handlePage($event)" [pageIndex]="pageIndex - 1" [pageSize]="pageSize">
        </mat-paginator>
    </div>
</div>
