import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';

import { DialogFormAdministrativeLv3Component } from '../../dialogs/dialog-form-administrative-lv3/dialog-form-administrative-lv3.component';
import { DialogMoveAdministrativeComponent } from '../../dialogs/move-administrative/dialog-move-administrative.component';
import { ActivatedRoute } from '@angular/router';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ListenFirebaseService} from '@app/_services';
import {LOCATION_PERMISSION} from '@app/_modules/administrative-units/constant';

@Component({
  selector: 'app-detail-administrative-lv3',
  templateUrl: './detail-administrative-lv3.component.html',
  styleUrls: ['./detail-administrative-lv3.component.scss']
})
export class DetailAdministrativeLv3Component implements OnInit{
  constructor(
    public dialog: MatDialog,
    private activatedRoute:ActivatedRoute,
    private administrativeUnitService:AdministrativeUnitService,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService
  ){
  }
  permissionLocation = LOCATION_PERMISSION;
  showSearch: boolean = false;
  dataSearch: string = '';
  idLocationLv3:any;
  isLoading:boolean = false;
  dataDetailLocation:any;
  
  ngOnInit(){
    this.getDataDetailLocation();
  }

  typeModule = 'location';
  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataDetailLocation();
      }
    });
  }

  getDataDetailLocation(){
    this.isLoading = true;
    this.idLocationLv3 = this.activatedRoute.snapshot.params.id;
    this.administrativeUnitService.getDetailLocation(this.idLocationLv3).subscribe(
      (res:any)=>{
        this.dataDetailLocation = res.data;
        this.isLoading = false;
      },
      (err)=>{
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  toggleClass(value){
    this.dataSearch = value;
    this.showSearch = !this.showSearch;
  }

  clickEditEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) this.checkFireBase('update');
    });
  }

  clickDeleteAdminstrative(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa đơn vị',
        message:'Xác nhận xóa?',
        btnOkText:'Delete',
        btnCancelText:'Cancel',
        data: {
          id:data.id,
          name:data.fullname
        }
      }
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.isLoading = true;

        this.administrativeUnitService.deleteLocation(data.id).subscribe(
          (res:any)=>{
            this.isLoading = false;
            this.checkFireBase('delete')

          },
          (err)=>{
            this.isLoading = false;
          }
        )
      } 
      
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clickMoveAdminstrativeLv3(id){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeComponent, {
      height:'auto',
      width:'30%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: {
            id:id,
            name:"Quận Đống Đa"
        }
      }
    });
  }
}

