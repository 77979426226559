import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tab-tenant-account-admin',
  templateUrl: './tab-tenant-account-admin.component.html',
  styleUrls: ['./tab-tenant-account-admin.component.scss']
})
export class TabTenantAccountAdminComponent implements OnInit {

  panelOpenState = true;
  @Input() data: any;
  constructor(
  ) { }


  ngOnInit(): void {

  }
}
