export const lang = {
    title: 'Cấu hình chung hệ thống',
    label: {
        info_basic: {
            title: 'Thông tin cơ bản',
            code_config: 'Mã cấu hình',
            config_value: 'Giá trị cấu hình',
        },

        info_system: {
            title: 'Thông tin hệ thống',
            name_system: 'Tên hệ thống',
            phone: 'Điện thoại',
            email: 'Email',
            hotline: 'Hotline',
            address: 'Địa chỉ',
            language: 'Ngôn ngữ',
            image: 'Ảnh',
            logo: 'Logo',
            logo_app: 'Ảnh nền',
            avatar_login: 'Ảnh đăng nhập'
        },

        security_info: {
            title: 'Thông tin bảo mật',

            error_log_max: {
                label: 'Đăng nhập lỗi tối đa',
                label_after: 'lần, hệ thống sẽ khóa đăng nhập trong vòng 30 phút'
            },
            password: {
                title: 'Mật khẩu',
                from: 'Từ',
                to: 'Đến',
                char: 'ký tự',
            },

            password_includes: {
                label: 'Mật khẩu bao gồm',
                lowercase_letters: 'Chữ cái thường',
                capital_letters: 'Chữ cái in hoa',
                special_characters: 'Ký tự đặc biệt',
            }
        },

        email_service: {
            title: 'Email server',
            url: 'Đường dẫn',
            user_name: 'Tên đăng nhập',
            code: 'Mã',
            password: 'Mật khẩu',
            active_confirm: {
                label: 'Xác nhận kích hoạt',
                confirm: 'Xác nhận',
                close: 'Không',
            },
        },

        system_intro: 'Giới thiệu hệ thống',
    },

    button: {
        btn_edit_info: 'Chỉnh sửa thông tin',
    }
};