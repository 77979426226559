<div style="background-color: #fff; height: 100%;">
    <p class="div__title" mat-dialog-title>Chọn đơn vị
        <span class="icon-close" [mat-dialog-close]="null"><img src="assets/images/icons/Vector-24.png"></span>
    </p>
    <hr>
    <mat-dialog-content class="div__content">
        <div class="row mr-0 ml-0"> 
          <label class="col-md-4 lb-global">Chọn đơn vị <span class="text-danger">(*)</span></label>
          <div class="col-md-8 pl-0 pr-0">
            <select class="form-control" (change)="selectOption($event)" [(ngModel)]="isChoose">
              <option value="null">Chọn đơn vị</option>
              <option value="1">Đơn vị ngành dọc</option>
              <option value="2">Đơn vị giáo dục</option>
            </select>
          </div>
        </div>
    </mat-dialog-content>
</div>