<div id="content">
    <div>
        <h5 class="title-custom">{{lang.label.title_info}}</h5>
        <div class="row">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 lb-global">{{lang.label.name_grade}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input type="text" class="form-control input-field-global" value="Khối 5"
                           disabled>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global ml-auto">
                <label class="col-md-3 offset-1 lb-global">{{lang.label.code}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input type="text" class="form-control input-field-global" value="OMT 123"
                           disabled>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 lb-global">{{lang.label.grade_training}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input type="text" class="form-control input-field-global" value="cấp 1"
                           disabled>
                </div>
            </div>
            <div class="form-group col-md-6 ml-auto row row-global">
                <label class="col-md-3 offset-1 lb-global">{{lang.label.training_system}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input type="text" class="form-control input-field-global" value="MOET"
                           disabled>

                </div>
            </div>
        </div>
    </div>

    <div class="margin-title">
        <h5 class="title-global">{{lang.label.describe}}</h5>
        <div class="row">
            <div class="form-group col-md-12 row row-global">
                <div class="col-md-12 pl-0 pr-0">
                    <textarea type="text" class="form-control input-field-global" rows="4"
                             disabled>ABC</textarea>
                </div>
            </div>

        </div>
    </div>

</div>
