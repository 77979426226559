import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostManagerListComponent } from '@app/_modules/post-manager/components/post-manager-list/post-manager-list.component';
import { PostManagerDetailComponent } from '@app/_modules/post-manager/components/post-manager-detail/post-manager-detail.component';
import { PostManagerFormComponent } from '@app/_modules/post-manager/components/post-manager-form/post-manager-form.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { POST_PERMISSION } from './constants';
const routes: Routes = [
  {
    path: '',
    component: PostManagerListComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [POST_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'detail/:id',
    component: PostManagerDetailComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [POST_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'form/:id',
    component: PostManagerFormComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [POST_PERMISSION.perm2],
        redirectTo: '/accessdenied'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostManagerRoutingModule { }
