import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogFormTenantManagerComponent } from '../../dialog/dialog-form-tenant-manager/dialog-form-tenant-manager.component';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';
import { DialogFormTenantConfigComponent } from '../../dialog/dialog-form-tenant-config/dialog-form-tenant-config.component';
import { DialogFormTenantRoleComponent } from '../../dialog/dialog-form-tenant-role/dialog-form-tenant-role.component';
import { DialogFormTenantModuleComponent } from '../../dialog/dialog-form-tenant-module/dialog-form-tenant-module.component';
import { Router,ActivatedRoute } from '@angular/router';
import { TenantManagerService, ListenFirebaseService, ShowMessage } from '@app/_services';
import {statusUser} from "@app/_services/constants";
import { Tenant } from '@app/_models';
import { Observable, Subscriber } from 'rxjs';
import { DialogFormTenantAdminChangePasswordComponent } from '../../dialog/dialog-form-tenant-admin-change-password/dialog-form-tenant-admin-change-password.component';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {TENANT_PERMISSION} from '../../constants';

@Component({
  selector: 'app-tenant-manager-detail',
  templateUrl: './tenant-manager-detail.component.html',
  styleUrls: ['./tenant-manager-detail.component.scss']
})
export class TenantManagerDetailComponent implements OnInit {
  links = [
    {
      permission: 'core_admin_tenant_detail',
      route: 'tab1',
      name: 'Thông tin khách hàng',
    },
    {
      permission: '',
      route: 'tab2',
      name: 'Thiết lập'
    },
    {
      permission: '',
      route: 'tab3',
      name: 'A.I Chatgpt'
    }
  ];
  activeLink = "";
  TENANT_PERMISSION = TENANT_PERMISSION;
  queryParams: any;
  tenantId: string;
  isLoading = true;
  tenantInfor:any;
  readonly statusUser = statusUser;
  typeModule = 'tenant';
  isLoadingResults = false;
  constructor(
    public dialog: MatDialog, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tenantManagerService: TenantManagerService,
    private listenFirebaseService: ListenFirebaseService,
    private showMessage: ShowMessage,
    ) { }

  ngOnInit(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.activeLink = this.queryParams.active;
    this.getInfoTenant();
  }

  changeRoute(route){
    this.activeLink = route;
    this.router.navigate([], {queryParams: {active: route}});
  }

  getInfoTenant(){
    this.isLoading = true;
    this.activatedRoute.paramMap.subscribe((res: any) => {
      // get info user
        this.tenantId = res.params.id;
        this.tenantManagerService.getDataTenantById(this.tenantId).subscribe((res: any) => {
          // res.data.map(x => {
          //   x.statusIndex = this.statusUser.findIndex(userEle => userEle.id === x.status);
          // });
          this.tenantInfor = res.data;
           let statusObj = this.statusUser.find(x => x.id === this.tenantInfor.status);
            this.tenantInfor.colorStatus = statusObj?.color;
            this.tenantInfor.nameStatus = statusObj?.name;
          this.isLoading = false;          
        });
    })
  }
  
  openDialogUpdateTenant(){
    const dialogRef = this.dialog.open(DialogFormTenantManagerComponent, {
      height:'auto',
      width:'80%',
      data: {
        tenant: this.tenantInfor,
        status:"update"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getInfoTenant();
      }
    });
  }

  openDialogUpdateTenantConfig(){
    const dialogRef = this.dialog.open(DialogFormTenantConfigComponent, {
      height:'auto',
      width:'80%',
      data: {
        tenant: this.tenantInfor,
        status:"update"
      }
    });
  }

  openDialogUpdateTenantRole(){
    const dialogRef = this.dialog.open(DialogFormTenantRoleComponent, {
      height:'auto',
      width:'60%',
      data: {
        data: [
          {'id':1,'name':'phân hệ 1','code':'PH1'},
          {'id':2,'name':'phân hệ 2','code':'PH2'},
          {'id':3,'name':'phân hệ 3','code':'PH3'},
          {'id':4,'name':'phân hệ 4','code':'PH4'},
          {'id':5,'name':'phân hệ 5','code':'PH5'},
          {'id':6,'name':'phân hệ 6','code':'PH6'},
          {'id':7,'name':'phân hệ 7','code':'PH7'},
          {'id':8,'name':'phân hệ 8','code':'PH8'},
          {'id':9,'name':'phân hệ 9','code':'PH9'},
          {'id':11,'name':'phân hệ 10','code':'PH10'}
        ],
        status:"create"
      }
    });
  }

  openDialogAddModule(){
    const dialogRef = this.dialog.open(DialogFormTenantModuleComponent, {
      height:'auto',
      width:'50%',
      data: {
        data: [
          {'id':1,'name':'phân hệ 1','code':'PH1'},
          {'id':2,'name':'phân hệ 2','code':'PH2'},
          {'id':3,'name':'phân hệ 3','code':'PH3'},
          {'id':4,'name':'phân hệ 4','code':'PH4'},
          {'id':5,'name':'phân hệ 5','code':'PH5'},
          {'id':6,'name':'phân hệ 6','code':'PH6'},
          {'id':7,'name':'phân hệ 7','code':'PH7'},
          {'id':8,'name':'phân hệ 8','code':'PH8'},
          {'id':9,'name':'phân hệ 9','code':'PH9'},
          {'id':11,'name':'phân hệ 10','code':'PH10'}
        ],
        status:"create"
      }
    });
  }

  openDialogDelete() {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xóa khách hàng',
        message: 'Bạn có chắc chắn muốn xóa dữ liệu này không?',
        btnOkText: 'Xoá',
        btnCancelText: 'Huỷ',
        data: {
          id: this.tenantInfor.id,
          name: this.tenantInfor.fullname
        },
        html: `<div class="img-module"><img src="${(this.tenantInfor.logo == null || this.tenantInfor.logo == "") ? 'assets/images/png/avatar.png' : this.tenantInfor.logo}" alt="Ảnh đại diện"></div>`
      }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm === true) {
        this.isLoadingResults = true;

        let listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
        });

        listenFb.subscribe((result) => {
          if (result === true) {
              this.router.navigate(['tenants-manager']);
          }
          this.isLoadingResults = false;
        });

        this.tenantManagerService.deleteTenant(this.tenantInfor.id).subscribe((res: any) => {
          if(res.status === 0) {
            this.isLoadingResults = false;
            this.showMessage.error(res.msg);
          } 
        });
        setTimeout(() => {
          if (this.isLoadingResults) {
            this.isLoadingResults = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }
    });
  }

  openDialogUpdateTenantAdminPassword() {
    const dialogRef = this.dialog.open(DialogFormTenantAdminChangePasswordComponent, {
      height:'auto',
      width:'60%',
      data: {
        tenant: this.tenantInfor,
        status:"update"
      }
    });
  }

}

