export const TENANT_PERMISSION = {
    perm1: "core_admin_tenants",
    perm2: "core_admin_tenant_add",
    perm3: "core_admin_tenant_edit",
    perm4: "core_admin_tenant_detail",
    perm5: "core_admin_tenant_change_admin_password",

    perm6: "core_admin_tenant_subject",
    perm7: "core_admin_tenant_subject_update",
    perm8: "core_admin_tenant_subject_delete",

    perm9: "core_admin_tenant_grades",
    perm10: "core_admin_tenant_grade_update",
    perm11: "core_admin_tenant_grade_delete",

    perm12: "core_admin_tenant_school_years",
    perm13: "core_admin_tenant_school_year_update",
    perm14: "core_admin_tenant_school_year_delete",

    perm15: "core_admin_tenant_subsystems",
    perm16: "core_admin_tenant_subsystem_update",
    perm17: "core_admin_tenant_subsystem_delete",
    
    perm18: "core_admin_tenant_modules",
    perm19: "core_admin_tenant_module_update",
    perm20: "core_admin_tenant_module_delete",
    
    perm21: "core_admin_tenant_service",
    perm22: "core_admin_tenant_delete",
}
