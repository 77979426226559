import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {RoleManagerService, ShowMessage} from '@app/_services';
import { Role } from '@app/_models';
import * as _ from 'lodash';
import {REGEX_CODE, REGEX_FULL_NAME} from '@app/_shared/utils/constant';

const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value !== null){
    let out = control.value.replace(/\s/g, '');
    if (out === '') {
      return {
        'required': true
      };
    }
    return null;
  }
};

const REGEX_CODE_NEXT = /^[\w\-]{2,}$/;

@Component({
  selector: 'app-dialog-role-form-add-edit',
  templateUrl: './dialog-role-form-add-edit.component.html',
  styleUrls: ['./dialog-role-form-add-edit.component.scss']
})
export class DialogRoleFormAddEditComponent implements OnInit {

  roleForm: FormGroup;
  role: any;
  data: string[] = [];
  checkValidate: boolean = false;
  checkCode: string = '';
  action: string;
  isLoading: boolean = false;
  constructor(
      private fb: FormBuilder,
      private dialogRes: MatDialogRef<DialogRoleFormAddEditComponent>,
      @Inject(MAT_DIALOG_DATA) private value,
      private rolesService: RoleManagerService,
      private showMessage: ShowMessage) {}
  ngOnInit(): void {
    const dataFake = {
      name: '',
      code: '',
      description: '',
      displayNumber: 1,
      status: 0,
    };
    this.action = this.value.action;
    this.role = this.value.item ? this.value.item : dataFake;
    // this.codeRandom = this.action === 'add' ? this.value.code : this.role.roleCode;
    this.roleForm = this.fb.group({
      name: [this.role.roleName, [Validators.required, Validators.minLength(2), Validators.maxLength(250), Validators.pattern(REGEX_FULL_NAME), trimValidator]],
      code: [this.role.roleCode, [Validators.required, Validators.minLength(2), Validators.maxLength(250), Validators.pattern(REGEX_CODE_NEXT), trimValidator]],
      description: this.role.description,
      displayNumber: this.role.displayNumber,
      status: this.role.status === 1 ? true : false,
    });
  }

  submit(value, validate){
    this.isLoading = true;
    if (!validate){
      this.isLoading = false;
      this.showMessage.warring('Vui lòng điền đầy đủ thông tin bắt buộc');
      return false;
    }
    value.status = value.status ? 1 : 0;
    value.displayNumber = value.displayNumber ? value.displayNumber : 0;
    if (this.value.action === 'add'){
      this.rolesService.addRole(value).subscribe((ref: any) => {
        if (ref.status === 1){
          this.isLoading = false;
          this.dialogRes.close(ref);
        }
        else{
          this.isLoading = false;
          this.checkCode = ref.msg;
        }
      });
    }
    else{
      this.rolesService.editRole(value, this.role.id).subscribe((ref: any) => {
        this.dialogRes.close(ref);
          },
      );
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeTextTest(value){
    if (value === ''){
      this.checkCode = '';
    }
  }
}
