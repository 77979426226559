export class SchoolYear {
    id: string;
    fullname: string;
    code: string;
    startDate: number;
    endDate: number;
    isActive: number;
    status: number;
    tenantNumber: number
}
