import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ShowMessage } from '@app/_services';
import { IndustryUnitService } from '@app/_services/industry-unit.service';

@Component({
  selector: 'app-import-industry',
  templateUrl: './import-industry.component.html',
  styleUrls: ['./import-industry.component.scss']
})
export class ImportIndustryComponent implements OnInit {
  displayedColumns = ['error', 'name1', 'code1', 'name2', 'code2', 'name3', 'code3', 'level'];
  @Input() dataSource;
  @Input() fileName;
  @Output() isImport = new EventEmitter<boolean>();
  constructor(
      private message: ShowMessage,
      private industryUnitService:IndustryUnitService
  ) { }
  ngOnInit(): void {
  }

  cancel(){
    this.isImport.emit(false);
    this.message.error('Đã hủy')
  }

  addDataImport(){
    this.isImport.emit(false);
  }

}
