import {Component, Renderer2, ElementRef, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import { TenantManagerService } from '@app/_services';
import { ShowMessage } from '@app/_services/show-message.service';
import { Router } from '@angular/router';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Tenant } from '@app/_models';
import { DialogShowDataActionHistoryComponent } from '../../../dialogs/dialog-show-data-action-history/dialog-show-data-action-history.component';

@Component({
    selector: 'app-accounts-action-history',
    templateUrl: './account-action-history.component.html',
    styleUrls: ['./account-action-history.component.scss']
})
export class AccountActionHistoryComponent implements OnInit {
    displayedColumns: string[] = ['stt', 'action_time', 'action_content', 'action_ip', 'action_url', 'user_agent', 'data'];
    @Input() dataHistories: any;
    @Output() pageIndex = new EventEmitter<any>();
    public pageSize: number = 15;
    dataSource: any;
    totalRecord = 0;
    public order = [];
    // check pagination
    public isPagination = false;
    public isTable = false;
    showSearch: boolean = false;
    showFilter: boolean = false;
    dataSearch: string = '';

    constructor(public dialog: MatDialog,
                private tenantManagerService: TenantManagerService,
                private showMessage: ShowMessage,
                private router: Router) {}

    ngOnInit(): void {
        this.dataSource = this.dataHistories.data;
        this.totalRecord = this.dataHistories.totalItems;
    }
    onChangePaginate(event){
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
    }

      openDialogShowDataActionHistory(data){
        const dialogDelete = this.dialog.open(DialogShowDataActionHistoryComponent, {
          height: 'auto',
          width: '40%',
          data: data
        });
      }
}
