import { Component, OnInit } from '@angular/core';
import {lang} from '../../../general-configuration.lang';

@Component({
  selector: 'app-system-information',
  templateUrl: './system-information.component.html',
  styleUrls: ['./system-information.component.scss']
})
export class SystemInformationComponent implements OnInit {
  lang = lang;
  panelOpenState = true;
  isLoading = false;
  constructor() { }

  ngOnInit(): void {
  }

}
