import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RoleManagerListComponent} from '@app/_modules/roles-manager/components/role-manager-list/role-manager-list.component';
import {RoleDetailComponent} from '@app/_modules/roles-manager/components/role-detail/role-detail.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import { ROLES_PERMISSION } from '@app/_modules/roles-manager/constant';

const routes: Routes = [
  {
    path: '',
    component: RoleManagerListComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [ROLES_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'detail/:id',
    component: RoleDetailComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [ROLES_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesManagerRoutingModule { }
