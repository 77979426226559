<h2 mat-dialog-title class="title-page-global text-center">{{this.data.roleName}}- Quyền</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row input-search-dialog-list">
            <div class="col-lg-12">
                <input id="search-value-dialog" (keydown)="checkText($event, searchDialogList.value)" #searchDialogList type="text" class="form-control input-field-global" placeholder="Nhập tên, mã module hoặc tên, mã quyền">
                <img src="assets/images/icons/icon-23.svg">
            </div>
        </div>
        <div class="row mt-1" *ngIf="checkData">
            <div class="col-lg-4 mt-3" *ngFor="let element of dataShowPermissions">
                <div class="px-2 pt-2 box-list-module">
                    <label class="label-dialog-list-module pb-1">
                        <mat-checkbox [disabled]="true" [checked]="element.is_selected"></mat-checkbox> {{  element.name }} <span class="code-dialog-list-permission">({{  element.code }})</span>
                    </label>
                    <div class="div-box-module">
                        <div class="mt-2" *ngFor="let item of element.permissons">
                            <mat-checkbox [disabled]="true" [checked]="item.status"></mat-checkbox>
                            <span class="label-dialog-list-permission mt-4"> {{  item.permisionName }} <span class="code-dialog-list-permission">({{  item.permissionCode }})</span></span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn-close-dialog-global mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
</mat-dialog-actions>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
