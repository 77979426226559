import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {ListenFirebaseService, TenantManagerService} from '@app/_services';
import {ShowMessage} from '@app/_services/show-message.service';
import {Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Tenant} from '@app/_models';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {AccountManagerService} from '@app/_services/account-manager.service';
import {Observable, Subscriber} from 'rxjs';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';
import { USER_PERMISSION } from "../../../constants";
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-accounts-tenant-list',
    templateUrl: './account-tenant-list.component.html',
    styleUrls: ['./account-tenant-list.component.scss']
})
export class AccountTenantListComponent implements OnInit, OnChanges {
    @Input() dataTenant;
    @Input() userId;
    @Output() checkRemove = new EventEmitter<boolean>();
    @Output() pageIndex = new EventEmitter<any>();
    dataSource: any;
    totalRecord = 0;
    public pageSize: number = 15;

    displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'status'];
    // public pageIndex: number = 0;
    public order = [];
    // check pagination
    public isPagination = false;
    public isTable = false;
    showSearch: boolean = false;
    showFilter: boolean = false;
    dataSearch: string = '';
    typeModule = 'user';
    isLoading = false;
    USER_PERMISSION = USER_PERMISSION;

    statusCustomer = [
        {id: 0, name: 'Chưa kích hoạt', color: '#77B6D1'},
        {id: 1, name: 'Dùng thử', color: '#1355C1'},
        {id: 2, name: 'Đang sử dụng', color: '#FF8038'},
        {id: 3, name: 'Hết hạn dịch vụ', color: '#CC4D4D'},
        {id: 4, name: 'Hết hạn sử dụng', color: '#6B6B6B'},
        {id: 99, name: 'Khác', color: '#8950FC'},
    ];

    constructor(
        public dialog: MatDialog,
        private tenantManagerService: TenantManagerService,
        private showMessage: ShowMessage,
        private router: Router,
        private accountManagerService: AccountManagerService,
        private listenFirebaseService: ListenFirebaseService,
        private permissionsService : NgxPermissionsService
    ) {
    }

    ngOnInit(): void {
        if (this.permissionsService.getPermission(this.USER_PERMISSION.perm12)
        ){
          this.displayedColumns.push('action');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.dataSource = this.dataTenant.data;
        this.totalRecord = this.dataTenant.totalItems;
    }

    onChangePaginate(event) {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
    }

    getStatusCustomer(id) {
        let index = this.statusCustomer.map(item => item.id).indexOf(id);
        return this.statusCustomer[index];
    }

    confirmDelete(data) {
        const dialogDelete = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: 'Bạn đang thực hiện xoá khách hàng ra khỏi danh sách quản lý',
                message: 'Bạn có chắc muốn xoá khách hàng này không ?',
                btnOkText: 'XÁC NHẬN',
                btnCancelText: 'HỦY',
                data: {
                    name: data.tenantName,
                },
            }
        });

        dialogDelete.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                this.isLoading = true;
                this.accountManagerService.removeTenantFromUser(this.userId, data.tenantId).subscribe(
                    (res: any) => {
                        if (res.status === 0) {
                            this.isLoading = false;
                            return this.showMessage.error(res.msg);
                        }

                    }, error => this.isLoading = false);

                setTimeout(() => {
                    if (this.isLoading) {
                        this.isLoading = false;
                        this.checkRemove.emit(true);
                    }
                }, TIME_OUT_LISTEN_FIREBASE);

                const listenFb = new Observable((subscriber: Subscriber<any>) => {
                    this.listenFirebaseService.checkFireBase('remove_tenant_from_user', this.typeModule, subscriber);
                });

                listenFb.subscribe((refg) => {
                    this.isLoading = false;
                    if (refg) {
                        this.checkRemove.emit(true);
                    }else this.isLoading = false;
                });
            }else this.isLoading = false;
        });
    }
}
