<div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<form [formGroup]="infoForm" style="width: 100%" *ngIf="isLoading === false" (ngSubmit)="onSubmit(infoForm.value)">
    <!-- email server -->
    <div class="panel-content-global">
        <div class="panel-header col-log-6"
                                    style="margin-left: 0; padding-left: 0">

            <div class="panel-title">{{lang.label.email_service.title}}</div>
    </div>
        <div class="row col-md-11 pl-0 ml-0">
            <div class="form-group col-md-6 row ">
                <label class="col-md-3 lb-global">SMTP url</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input placeholder="Đường dẫn smtp của email" type="email" formControlName="smtp_url" class="form-control input-field-global">
                    <mat-error *ngIf="infoForm.get('smtp_url').hasError('maxlength') &&
                        (infoForm.get('smtp_url').dirty || infoForm.get('smtp_url').touched || isValidate == true)">
                        Bạn không được nhập vượt quá 50 ký tự
                    </mat-error>
                    <mat-error *ngIf="infoForm.get('smtp_url').hasError('email') &&
                        (infoForm.get('smtp_url').dirty || infoForm.get('smtp_url').touched || isValidate == true)">
                        Email không đúng định dạng
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-md-6 ml-auto row ">
                <label class="col-md-3 offset-1 lb-global">SMTP port</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input placeholder="SMTP port" formControlName="smtp_port" type="text" class="form-control input-field-global">
                    <mat-error *ngIf="infoForm.get('smtp_port').hasError('maxlength') &&
                        (infoForm.get('smtp_port').dirty || infoForm.get('smtp_port').touched || isValidate == true)">
                        Bạn không được nhập vượt quá 10 ký tự
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row col-md-11 pl-0 ml-0">
            <div class="form-group col-md-6 row">
                <label class="col-md-3 lb-global">Tên đăng nhập</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input placeholder="Tên đăng nhập email server" type="text" formControlName="smtp_user" class="form-control input-field-global">
                    <mat-error *ngIf="infoForm.get('smtp_user').hasError('maxlength') &&
                        (infoForm.get('smtp_user').dirty || infoForm.get('smtp_user').touched || isValidate == true)">
                        Tên đăng nhập không được vượt quá 50 ký tự
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-md-6 ml-auto row">
                <label class="col-md-3 offset-1 lb-global">Mật khẩu</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input placeholder="Mật khẩu đăng nhập email server" type="text" formControlName="smtp_password" class="form-control  input-field-global">
                    <mat-error *ngIf="infoForm.get('smtp_password').hasError('maxlength') &&
                        (infoForm.get('smtp_password').dirty || infoForm.get('smtp_password').touched || isValidate == true)">
                        Mật khẩu không được vượt quá 50 ký tự
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row col-md-11 pl-0 ml-0">
            <div class="form-group col-md-6 row">
                <label class="col-md-3 lb-global">Sử dụng TLS</label>
                <div class="col-md-8 pl-0 pr-0">
                    <mat-checkbox class="example-margin" style="position: relative; top: 5px" formControlName="smtp_tls">
                    </mat-checkbox>
                </div>
            </div>

        </div>
    </div>
    <br>
    <!-- email server -->
    <div fxLayout="row" fxLayoutAlign="center end" >
        <a class="btn custom-btn custom-btn-close" (click)="cancel()">HỦY</a>&nbsp;&nbsp;
        <button class="btn custom-btn custom-btn-add">LƯU</button>
    </div>

</form>
