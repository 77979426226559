import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ListenFirebaseService, ModulesManagerService, ShowMessage} from '@app/_services';
import {DialogFormModuleComponent} from '@app/_modules/modules-manager/dialogs/dialog-form-module/dialog-form-module.component';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {DialogListCustomerComponent} from '@app/_modules/modules-manager/dialogs/dialog-list-customers/dialog-list-customer.component';
import {DialogAddCustomerComponent} from '@app/_modules/modules-manager/dialogs/dialog-add-customer/dialog-add-customer.component';
import {DialogListPermissionComponent} from '../../../dialogs/dialog-list-permission/dialog-list-permission.component';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {Modules} from '@app/_models/modules';
import * as _ from 'lodash';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { NgxPermissionsService } from 'ngx-permissions';
import {MODULE_PERMISSION} from '../../../constants';

@Component({
    selector: 'app-tab-customers',
    templateUrl: './tab-customers.component.html',
    styleUrls: ['./tab-customers.component.scss']
})
export class TabCustomersComponent implements OnChanges {
    displayedColumns: string[] = ['stt', 'fullname', 'code', 'permission', 'customer', 'status'];
    dataSource: any;
    pageSize = 15;
    pageIndex = 0;
    totalRecord = 0;
    permissions = [];
    showSearch = false;
    showFilter = false;
    isLoading = false;
    typeModule = "module";
    @Input() isActive;
    @Input() dataSearch;
    @Input() openDialog;
    MODULE_PERMISSION = MODULE_PERMISSION;
    constructor(
        public dialog: MatDialog,
        private modulesManagerService: ModulesManagerService,
        private showMessage: ShowMessage,
        private db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService,
        private permissionsService: NgxPermissionsService
    ) {}

    ngOnChanges(): void {
        this.loadDataItems();
        if (this.permissionsService.getPermission(this.MODULE_PERMISSION.perm4) 
        || this.permissionsService.getPermission(this.MODULE_PERMISSION.perm5)
        || this.permissionsService.getPermission(this.MODULE_PERMISSION.perm6)
        || this.permissionsService.getPermission(this.MODULE_PERMISSION.perm9)
        ){
            const index = this.displayedColumns.indexOf('tools');
            if (index == -1) {
                this.displayedColumns.push('tools');
            }
        }
    }

    listenFirebase(){
        this.checkFireBase("change_status");
        this.checkFireBase("delete");
        this.checkFireBase("update");
    }

    checkFireBase(param){
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
        });
        listenFb.subscribe((res) => {
            this.isLoading = false;
            if(res == true) {
                this.loadDataItems();
            }
        });
    }

    loadDataItems() {
        if(this.isActive != 3){
            this.isLoading = true;
            this.modulesManagerService.getDataModule(this.pageSize, this.pageIndex+1,this.dataSearch,this.isActive == null ? '' : this.isActive,0).subscribe(
                (res: any) => {
                    this.dataSource = res.data.data;
                    this.totalRecord = res.data.totalItems;
                    this.isLoading = false;
                },
                error => {
                    this.isLoading = false;
                }
            );
        }
    }

    onChangePaginate(event){
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.loadDataItems();
    }

    changeStatusRole(test: any, infoCoreAdmin: any) {
        const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
            height: 'auto',
            width: '40%',
            data: {
                btnCancelText: 'Hủy',
                btnOkText: 'Xác nhận',
                status: test.checked,
                title: test.checked ? 'Bạn đang thực hiện kích hoạt module' : 'Bạn đang thực hiện bỏ kích hoạt module',
                content: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
                data: {
                    name: infoCoreAdmin.fullname,
                }
            }
        });
        confirmActive.afterClosed().subscribe((res: any) => {
            test.source._checked = res ? test.checked : !test.checked;
            if (res) {
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.changeStatus({
                    moduleId: infoCoreAdmin.id,
                    isActive: test.checked === true ? 1 : 0
                }).subscribe((res: any) => {
                    if(res.status == 0){
                      this.showMessage.error(res.msg);
                      this.isLoading = false;
                    }
                  },
                  (err)=>{
                      this.isLoading = false;
                  });
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }

    openDialogDelete(infoUser: any) {
        const dialogRef = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: 'Bạn đang thực hiện xóa module',
                message: 'Bạn có chắc muốn xóa dữ liệu này không?',
                btnOkText: 'XÓA',
                btnCancelText: 'HỦY',
                data: {
                    name: infoUser.fullname
                },
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: string) => {
            if (confirmed) {
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.deleteModule(infoUser.id).subscribe(
                    (res: any) => {
                        if(res.status == 0){
                            this.isLoading = false;
                            this.showMessage.error(res.msg);
                        }
                      },
                      (err)=>{
                        this.isLoading = false;
                    }
                );
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }

    openDialogUpdateModule(data: any) {
        const dialogRef = this.dialog.open(DialogFormModuleComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'update',
                infoModule: data,
                permissions: this.permissions,
                groupModule: 'Customer',
            }
        });

        dialogRef.afterClosed().subscribe((dataInput: any) => {
            if(typeof dataInput !== 'undefined' && dataInput != false){
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.updateModule(dataInput.id, dataInput.data).subscribe((res: any) => {
                    if(res.status == 0){
                        this.isLoading = false;
                        this.showMessage.error(res.msg);
                    }
                  },
                  (err)=>{
                    this.isLoading = false;
                });
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }

    openDialogListPermission(infoModule: string) {
        this.dialog.open(DialogListPermissionComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'list-customer',
                infoModule
            }
        });
    }

    openDialogListCustomer(infoModule) {
        this.dialog.open(DialogListCustomerComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'list-customer',
                infoModule
            }
        });
    }

}
