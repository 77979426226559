import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/_shared/shared.module';
import { MaterialModule } from '@app/_shared/material.module';
import { ModuleSoftwareManagerRoutingModule } from './module-software-manager-routing.module';
import { SoftwareListComponent } from './components/software-list/software-list.component';
import { SoftwareDetailComponent } from './components/software-detail/software-detail.component';
import { DialogFormAddEditSoftwareComponent } from './dialogs/dialog-form-add-edit-software/dialog-form-add-edit-software.component';
import { DialogListUserSoftwareComponent } from './dialogs/dialog-list-user-software/dialog-list-user-software.component';
import { DialogChangeStatusComponent } from './dialogs/dialog-change-status/dialog-change-status.component';
import { DialogAddCustomerComponent } from './dialogs/dialog-add-customer/dialog-add-customer.component';
import {ReactiveFormsModule} from '@angular/forms';
import { DialogDeleteSofwareComponent } from './dialogs/dialog-delete-sofware.component/dialog-delete-sofware.component';
import { NgxPermissionsModule } from 'ngx-permissions';
@NgModule({
  declarations: [
      SoftwareListComponent,
      SoftwareDetailComponent,
      DialogFormAddEditSoftwareComponent,
      DialogListUserSoftwareComponent,
      DialogAddCustomerComponent,
      DialogChangeStatusComponent,
      DialogDeleteSofwareComponent
  ],
  imports: [
    CommonModule,
    ModuleSoftwareManagerRoutingModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild()
  ]
})
export class ModuleSoftwareManagerModule { }
