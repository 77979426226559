<div class="mldlai-config">
    <table>
        <tr>
            <td>
                <h3>Chatgpt configs</h3>
            </td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>GptOpenAiChatApiUrl</td>
            <td>
                <input [value]="GptOpenAiChatApiUrl" class="form-control input-field-global"
                    placeholder="GptOpenAiChatApiUrl">
            </td>
            <td></td>
        </tr>
        <tr>
            <td>GptOpenAiApiKey</td>
            <td>
                <input [(ngModel)]="GptOpenAiApiKey" class="form-control input-field-global" placeholder="GptOpenAiApiKey">
            </td>
            <td></td>
        </tr>
        <tr>
            <td>GptOpenAiModelName</td>
            <td>
                <input [(ngModel)]="GptOpenAiModelName" class="form-control input-field-global"
                    placeholder="GptOpenAiModelName">
            </td>
            <td></td>
        </tr>
        <tr>
            <td>GptOpenAiPricePerToken</td>
            <td>
                <input [(ngModel)]="GptOpenAiPricePerToken" class="form-control input-field-global"
                    placeholder="GptOpenAiPricePerToken"> USD
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                Enable
            </td>
            <td>
                {{isEnable}}
            </td>
            <td>
                <button *ngIf="!isEnable" class="btn btn-primary mr-2" (click)="doEnable()">
                    Enable</button>
                <button *ngIf="isEnable" class="btn btn-primary mr-2" (click)="doDisable()">
                    Disable</button>
            </td>

        </tr>
        <tr>
            <td>

            </td>
            <td>
                <button class="btn btn-primary mr-2" (click)="doSave()">
                    Save change</button>
            </td>
            <td>

            </td>
        </tr>
    </table>
</div>