<div class="wrap" id="tab-content-curriculum-component">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree" class="col-11">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding class="node-child">
      <ng-container> 
          <div class="ml-0 mr-0 box mt-2 mb-2" style="display: flex; position: relative;">
            <div class="box__left pl-0 pr-0">
              <div class="row ml-0 mr-0 box__content">
                <button *ngIf="!node.expandable" mat-icon-button disabled></button>
                <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.filename">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <div [appMath]="node.content" class="content-node content-node-div" *ngIf="!node.isCheckEdit"></div>
                <div *ngIf="node.isCheckEdit" class="content-node">
                  <ckeditor style="width: 100%" [editor]="Editor" [(ngModel)]="node.content">
                  </ckeditor>
                </div>
              </div>
              <!-- mat menu: thao tác thêm/sửa/xóa/gán/xem -->
              <mat-icon mat-icon-button [matMenuTriggerFor]="menu" class="mat-icon-menu" 
                (click)="$event.stopPropagation();" *ngIf="!node.isCheckEdit">more_horiz
              </mat-icon>
              <mat-menu #menu="matMenu" class="menu-more-global custom-menu">
                <button class="px-5" mat-menu-item (click)="addNewItem($event, node)" [disabled]="!canAddNewNode">
                  <img src="assets/images/svg/add-orange.svg" alt="" class="mr-2">
                  Thêm đơn mục con
                </button>
                <button class="px-5" mat-menu-item (click)="clickBtnEdit(node)" [disabled]="!canEditNode">
                  <img src="assets/images/svg/edit-orange.svg" alt="" class="mr-2">
                  Cập nhật nội dung
                </button>
                <button class="px-5" mat-menu-item *ngIf="type == 1" (click)="assignContent(node, true)">
                  <img src="assets/images/svg/assign-orange.svg" alt="" class="mr-2">
                  Gán yêu cầu cần đạt cho mục
                </button>
                <button class="px-5 mat-menu-bottom" mat-menu-item *ngIf="type == 1" (click)="openChooseContent(node, false)">
                  <img src="assets/images/svg/view-orange.svg" alt="" class="mr-2">
                  Xem yêu cầu cần đạt đã gán
                </button>
                <button class="px-5 text-danger" mat-menu-item (click)="deleleNode(node)">
                  <img src="assets/images/svg/delete-orange.svg" alt="" class="mr-2">
                  Xóa mục
                </button>
              </mat-menu>
              <!-- end mat menu -->
              <ng-container *ngIf="node.isCheckEdit">
                <button class="btn btn-icon btn--icon--not-oke ml-2" *ngIf="type == 1" title="Gán"
                (click)="openChooseContent(node, node.isCheckEdit)">
                  <img src="assets/images/svg/assign-orange.svg" alt="">
                </button> 
                <button class="btn btn-icon btn-icon--oke ml-2" title="Cập nhật"
                (click)="clickDoneEdit(node, node.content)">
                  <img src="assets/images/svg/icon-tich.svg" alt="">
                </button>
                <button class="btn btn-icon btn--icon--not-oke ml-2" title="Hủy"
                (click)="clickClearTextEdit(node)">
                  <img src="assets/images/svg/icon-close-round.svg" alt="">
                </button>  
              </ng-container>
            </div>
          </div>               
      </ng-container>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent; let i=index" matTreeNodePadding class="no-content">
      <div style="width: 100%; display: flex;">
        <div style="width: 100%; ">
        <div class="row ml-0 mr-0 box mt-2 mb-2" style="width: 100%;">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 box__left pl-0 pr-0">
              <div class="box__content mr-2">
                <ckeditor style="width: 100% " [editor]="Editor" [(ngModel)]="valueAddNewNode">
                </ckeditor>
              </div>  
              <ng-container>
                <button class="btn btn-icon btn--icon--not-oke ml-2" *ngIf="type == 1" (click)="openChooseContent(node,true)">
                  <img src="assets/images/svg/assign-orange.svg" alt="">
                </button> 
                <button class="btn btn-icon btn-icon--oke ml-2" (click)="clickDoneCreate(node, valueAddNewNode)">
                  <img src="assets/images/svg/icon-tich.svg" alt="">
                </button>
                <button class="btn btn-icon btn--icon--not-oke ml-2" id= {{i}} (click)="clickClearTextCreate(node)">
                  <img src="assets/images/svg/icon-close-round.svg" alt="">
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="node-parent">
      <ng-container>
        <div class=" ml-0 mr-0 box mt-2 mb-2" style="display: flex; position: relative;"> 
          <div class=" box__left pl-0 pr-0">
            <div class="row ml-0 mr-0 box__content">
              <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <div [appMath]="node.content" class="content-node content-node-div" *ngIf="!node.isCheckEdit"></div>
              <div class="content-node" *ngIf="node.isCheckEdit">
                <ckeditor style="width: 100% " [editor]="Editor" [(ngModel)]="node.content">
                </ckeditor>
              </div>
            </div>
            <ng-container *ngIf="node.isCheckEdit">
              <button class="btn btn-icon btn--icon--not-oke ml-2" *ngIf="type == 1" (click)="openChooseContent(node, node.isCheckEdit)"
              title="Gán">
                <img src="assets/images/svg/assign-orange.svg" alt="">
              </button> 
              <button class="btn btn-icon btn-icon--oke ml-2" (click)="clickDoneEdit(node, node.content)" title="Cập nhật">
                <img src="assets/images/svg/icon-tich.svg" alt=""></button>
              <button class="btn btn-icon btn--icon--not-oke ml-2" (click)="clickClearTextEdit(node)" title="Hủy">
                <img src="assets/images/svg/icon-close-round.svg" alt="">
              </button>  
            </ng-container>           
          </div>

          <!-- mat menu: thao tác thêm/sửa/xóa/gán/xem -->
          <mat-icon [matMenuTriggerFor]="menu" class="mat-icon-menu" 
          (click)="$event.stopPropagation();"
          *ngIf="!node.isCheckEdit">more_horiz
          </mat-icon>
          <mat-menu #menu="matMenu" class="menu-more-global custom-menu">
            <button class="px-5 custom-mat-item" mat-menu-item (click)="addNewItem($event, node)" [disabled]="!canAddNewNode">
              <img src="assets/images/svg/add-orange.svg" alt="" class="mr-2">
              Thêm đơn mục con
            </button>
            <button class="px-5" mat-menu-item (click)="clickBtnEdit(node)" [disabled]="!canEditNode">
              <img src="assets/images/svg/edit-orange.svg" alt="" class="mr-2">
              Cập nhật nội dung
            </button>
            <button class="px-5 mat-menu-bottom"mat-menu-item *ngIf="type == 1" (click)="assignContent(node, true)">
              <img src="assets/images/svg/assign-orange.svg" alt="" class="mr-2">
              Gán yêu cầu cần đạt cho mục
            </button>
            <button class="px-5" mat-menu-item *ngIf="type == 1" (click)="openChooseContent(node, false)">
              <img src="assets/images/svg/view-orange.svg" alt="" class="mr-2">
              Xem yêu cầu cần đạt đã gán
            </button>
            <button class="px-5 text-danger" mat-menu-item (click)="deleleNode(node)">
              <img src="assets/images/svg/delete-orange.svg" alt="" class="mr-2">
              Xóa mục
            </button>
          </mat-menu>
          <!-- end mat menu -->
        </div>
      </ng-container>
    </mat-tree-node>
  </mat-tree>

  <!-- ô nhập dữ liệu nút gốc -->
  <div class="row ml-0 mr-0 box mt-2 mb-2 box--root" style="width: 100%;" *ngIf="this.showInputRoot !=0">
    <div class="col-sm-12 col-md-11 col-lg-11 col-xl-11 box__left pl-0">
      <div class="box__content mr-2">
        <ckeditor style="width: 100% " [editor]="Editor" [(ngModel)]="valueNodeRoot">
        </ckeditor>
      </div>
      
      <ng-container>
        <button class="btn btn-icon btn--icon--not-oke ml-2" *ngIf="type == 1" title="Gán"
          (click)="openChooseContent({id:null, content:'', level:0, expandable:false, isCheckEdit: false, suggestTieuChiDanhGiaIdsNode: []}, true)">
            <img src="assets/images/svg/assign-orange.svg" alt="">
        </button> 
        <button class="btn btn-icon btn-icon--oke ml-2" title="Thêm"
        (click)="clickDoneCreate({id: null, content:'', subTieuChiDanhGias: null, isCheckEdit: false, isCheckClear: false, childs: [], suggestTieuChiDanhGiaIdsNode: listCheckBox}, valueNodeRoot)">
          <img src="assets/images/svg/icon-tich.svg" alt="">
        </button>
        <button class="btn btn-icon btn--icon--not-oke ml-2" title="Hủy"
        (click)="clickClearTextCreate(valueNodeRoot)">
          <img src="assets/images/svg/icon-close-round.svg" alt="">
        </button>  
      </ng-container>
    </div>   
  </div>

  <!--  btn thêm dữ liệu nút gốc -->
  <button *ngIf="showBtnAddRoot !=0" class="btn-action btn-add ml-3" style="margin-bottom: 30px; margin-top: 10px;" 
          (click)="addRootItem({id:null, content:'', level:0, expandable:false, isCheckEdit: false, 
          suggestTieuChiDanhGiaIds: [], childs: [],
          suggestTieuChiDanhGiaIdsNode: [], isDisableAdd: false})"
          [disabled]="!canAddNewNode">
      <img src="assets/images/svg/add.svg" alt="">
      {{type == 1 ? 'Thêm nội dung' : 'Thêm yêu cầu đạt được'}}
  </button>
  
</div>
<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>