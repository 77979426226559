<div fxLayout="column" class="bg-content-global">
    <div fxFlex fxLayout="column" class="role-page-detail" >
        <p fxFlex="50px" class="title-page-global">Phân quyền - {{infoRole?.roleName}}</p>
        <div fxFlex fxLayout="column" class="content-role-page-detail" >
            <div fxFlex="245px" fxLayout="column">
                <div fxFlex="55px" fxLayout="row" fxLayoutAlign="space-between start">
                    <p class="title-profile-role" >Thông tin cơ bản</p>
                    <div>
                        <ng-template ngxPermissionsOnly="{{permissionRole.perm3}}">
                            <button class="btn-role-global mat-elevation-z" mat-raised-button (click)="showFormRole()"><img class="mr-2 mb-1" src="assets/images/icons/icon-21.svg"> Chỉnh sửa thông tin </button>
                        </ng-template>
                    </div>
                </div>
                <div fxFlex="190px" style="border-bottom: 1px solid black">
                    <div class="row">
                        <div class="col-lg-4 form-group row row-global" >
                            <label class="col-sm-3 lb-global ">Tên phân quyền</label>
                            <div class="col-sm-8 pl-0 pr-0">
                                <input disabled [value]="infoRole?.roleName" type="text" class="form-control input-field-global" placeholder="Nhập tên phân quyền">
                            </div>
                        </div>
                        <div class="col-lg-4 form-group row row-global" >
                            <label class="offset-1 col-sm-3 lb-global">Mã phân quyền</label>
                            <div class="col-sm-8 pl-0 pr-0 ">
                                <input disabled [value]="infoRole?.roleCode" type="text"  class="form-control input-field-global" placeholder="Nhập mã phân quyền">
                            </div>
                        </div>
                        <div class="col-lg-4 form-group row row-global" >
                            <label class="offset-1 col-sm-3 lb-global">Thứ tự sắp xếp</label>
                            <div class="col-sm-8 pl-0 pr-0 ">
                                <input disabled [value]="infoRole?.displayNumber" onlyNumber (keypress)="numberOnly($event)" type="number" min="1"  class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 form-group row row-global">
                            <label class="col-sm-3 lb-global">Trạng thái</label>
                            <div class="col-sm-8 pl-0 pr-0 lb-global">
                                <mat-checkbox disabled [checked]="infoRole?.status == 1 ? true: false" class="checkbox-form-role">Kích hoạt</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <label class="lb-global" fxFlex="8%">Mô tả</label>
                        <div fxFlex class="form-group pr-0 row-global">
                            <textarea disabled [value]="infoRole?.description" class="form-control input-field-global col-sm-12"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="52px" fxLayout="row" fxLayoutAlign="space-between start" class="div-btn-tab mt-2 bg-white">
                <div fxFlex="30%">
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm9}}">
                        <button class="btn mr-2  mat-elevation-z" [class]="tab === 'tab1' ? 'btn-filter-subject-select' : 'btn-filter-subject'" mat-raised-button (click)="changeTab('tab1')" >Quyền</button>
                    </ng-template>
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm6}}">
                        <button class="btn mr-2  mat-elevation-z" [class]="tab === 'tab2' ? 'btn-filter-subject-select' : 'btn-filter-subject'" mat-raised-button (click)="changeTab('tab2')" >Người dùng</button>
                    </ng-template>
                </div>
                <div fxFlex="70%" fxLayout="row" fxLayoutAlign="end none" >
                    <input (keydown)="checkText($event, search.value)" #search type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="text-left btn button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch ? tab === 'tab1' ? 'Nhập tên, mã module hoặc tên, mã quyền' : 'Nhập tên tài khoản hoặc mã, số điện thoại, email' : ''}}" >
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass()">
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm9}}">
                        <button class="btn-role-global mat-elevation-z" *ngIf="tab === 'tab1'" mat-raised-button (click)="showAddModuleRole()">
                            <span *ngIf="checkTotalPermission === false"><img class="mr-2 mb-1" src="assets/images/icons/icon-40.svg">Thêm quyền</span>
                            <span *ngIf="checkTotalPermission === true"><img class="mr-2 mb-1" src="assets/images/icons/icon-21.svg">Chỉnh sửa quyền</span>
                        </button>
                    </ng-template>
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm6}}">
                        <button class="btn-role-global mat-elevation-z" *ngIf="tab === 'tab2'" mat-raised-button (click)="showAddUserRole()"><img class="mr-2 mb-1" src="assets/images/svg/add.svg">Thêm tài khoản</button>
                    </ng-template>
                </div>
            </div>
            <div fxFlex class="detail-content">
                <ng-template ngxPermissionsOnly="{{permissionRole.perm9}}">
                    <app-role-list-module-permission *ngIf="tab === 'tab1'" [roleId]="roleId" [dataSearch]="dataSearch" [change]="change" (outCheckRemoveSubject)='checkRemoveSubject($event)'></app-role-list-module-permission>
                </ng-template>
                <ng-template ngxPermissionsOnly="{{permissionRole.perm6}}">
                    <app-role-list-users *ngIf="tab === 'tab2'" [roleId]="roleId" [dataSearch]="dataSearch" [change]="change" [reloadTabUser]="reloadTabUser" ></app-role-list-users>
                </ng-template>
            </div>
        </div>
    </div>
</div>
