import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from './user.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})

export class PostManagerService {

    constructor(private http: HttpClient) { }
    getDataAllPostManager(pageSize, pageIndex, keyWord = '', scope = '', comment= '', mail = ''){
        const linkKeySearch = keyWord === '' ? '' : `&KeySearch=${keyWord}`;
        const linkScope = scope === '' ? '' : `&SendRange=${scope}`;
        const linkComment = comment === '' ? '' : `&AllowComment=${comment}`;
        const linkMail = mail === '' ? '' : `&AllowSendMail=${mail}`;
        return this.http.get(`${environment.coreApiUrl}/api/posts?PageSize=${pageSize}&PageIndex=${pageIndex}${linkKeySearch}${linkScope}${linkComment}${linkMail}`);
    }

    storePostManager(value){
        return this.http.post(`${environment.coreApiUrl}/api/posts`, value);
    }

    getPostById(id){
        return this.http.get(`${environment.coreApiUrl}/api/posts/${id}`);
    }

    editPostManager(value){
        return this.http.put(`${environment.coreApiUrl}/api/posts/${value.id}`, value);
    }

    deletePostManager(id){
        return this.http.post(`${environment.coreApiUrl}/api/posts/delete`, {id});
    }

    cancelPostManager(itemId: string){
        return this.http.post(`${environment.coreApiUrl}/api/posts/cancel`, {
            id: itemId
        });
    }

    hidePostManager(itemId: string){
        return this.http.post(`${environment.coreApiUrl}/api/posts/hide`, {
            id: itemId
        });
    }

    sendToPostManager(itemId: string){
        return this.http.post(`${environment.coreApiUrl}/api/posts/resend-post`, {
            id: itemId
        });
    }

    getRecipientPostManager(itemId: string, keyWord: string){
        let link = keyWord === undefined ? '' : `?KeySearch=${keyWord}`;
        return this.http.get(`${environment.coreApiUrl}/api/posts/${itemId}/get-recipient${link}`);
    }

    /**Lay danh sach nguoi dung o chi tiet bai viet**/
    getUsersReceivedPost(postId: string, KeySearch: string, IsSend: number, IsReceive: number, IsRead: number){
        return this.http.get(`${environment.coreApiUrl}/api/posts/${postId}/get-users-received-post?KeySearch=${KeySearch}&IsSend=${IsSend}&IsReceive=${IsReceive}&IsRead=${IsRead}`);
    }

}

