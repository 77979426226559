import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from "@app/_shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { CurriculumDashbroadComponent } from './components/curriculum-dashbroad/curriculum-dashbroad.component';
import { CurriculumRoutingModule } from './curriculum-routing.module';
import { CurriculumListComponent } from './components/curriculum-list/curriculum-list.component';
import { CurriculumFormComponent} from './components/curriculum-form/curriculum-form.component';
import { CurriculumDetailComponent } from './components/curriculum-detail/curriculum-detail.component';
import { TypeOfSubjectComponent } from './components/type-of-subject/type-of-subject.component';
import { DialogCurriculumComponent } from './dialogs/dialog-curriculum/dialog-curriculum.component';
import { MatTreeSelectOptionComponent } from '@app/_modules/curriculum/components/mat-tree-select-option/mat-tree-select-option.component';
import { DialogAssginSchoolYearCurriculumComponent } from './dialogs/dialog-assgin-shool-year-curriculum/dialog-assgin-shool-year-curriculum.component';
import { TabContentCurriculumComponent } from './tabs/tab-content-curriculum/tab-content-curriculum.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
@NgModule({
  declarations: [
    CurriculumDashbroadComponent,
    CurriculumListComponent,
    CurriculumFormComponent,
    CurriculumDetailComponent,
    TypeOfSubjectComponent,
    DialogCurriculumComponent,
    MatTreeSelectOptionComponent,
    DialogAssginSchoolYearCurriculumComponent,
    TabContentCurriculumComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    CurriculumRoutingModule,
    //IntegralUIModule,
    CKEditorModule
  ],
  exports: [
    TypeOfSubjectComponent
  ]
})
export class CurriculumModule { }
