import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ModuleSoftwareManagerService} from "@app/_services";
import {ShowMessage} from "@app/_services";
import {REGEX_FULL_NAME} from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-form-add-edit-software',
  templateUrl: './dialog-form-add-edit-software.component.html',
  styleUrls: ['./dialog-form-add-edit-software.component.scss']
})
export class DialogFormAddEditSoftwareComponent implements OnInit {
  infoForm;
  checkValidate = false;
  isLoading = false;
  messageValidate = {
    fullname: [
      {type: 'required', message: 'Bạn không được để trống'},
      {type: 'minlength', message: 'Tên phân hệ phải có tối thiểu 2 ký tự'},
      {type: 'maxlength', message: 'Tên phân hệ không được vượt quá 250 ký tự'},
      {type: 'pattern', message: 'Tên phân hệ chỉ chấp nhận các ký tự đặc biệt: _ - ( ) [ ]'},
    ],
    code: [
      {type: 'pattern', message: 'Bạn không được nhập ký tự đặc biệt ngoài 2 ký tự "-" và "_"'},
      {type: 'required', message: 'Bạn không được để trống'},
      {type: 'minlength', message: 'Không ít hơn 2 ký tự'},
      {type: 'maxlength', message: 'Không được vượt quá 50 ký tự'},
    ],
    indexOrder: [
      {type: 'required', message: 'Bạn không được để trống'},
      {type: 'pattern', message: 'Bạn chỉ được nhập ký tự số'},
    ],
    description: [
      {type: 'maxlength', message: 'Bạn kkhông được vượt quá 500 ký tự'},
    ],
  };

  constructor(
      private dialogRef: MatDialogRef<DialogFormAddEditSoftwareComponent>,
      private fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public infoSoftwareModule,
      private moduleSoftwareManagerService: ModuleSoftwareManagerService,
      private showMessage: ShowMessage,
      ) { }

  ngOnInit(): void {
    this.infoForm = this.fb.group({
      fullname: [this.infoSoftwareModule.data ? this.infoSoftwareModule.data.fullname : '', [Validators.required, Validators.minLength(2), Validators.maxLength(250), Validators.pattern(REGEX_FULL_NAME)]],
      code: [this.infoSoftwareModule.data ? this.infoSoftwareModule.data.code : '', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[-\w]{0,}$/)]],
      description: [this.infoSoftwareModule.data ? this.infoSoftwareModule.data.description : '', Validators.maxLength(500)],
      indexOrder: [this.infoSoftwareModule.data ? this.infoSoftwareModule.data.indexOrder : 0, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      status: [this.infoSoftwareModule.data ? this.infoSoftwareModule.data.status === 1 ? true : false : false],
    });
    if (this.infoSoftwareModule.status === 'add'){
      this.generateRandomKey();
    }
  }

  generateRandomKey(){
    let data = {prefix: "PH", lenghtOfAlphabet: 0, lenghtOfUpercase: 0, lenghtOfNumber: 6, lenghtOfSpecial: 0}
    this.moduleSoftwareManagerService.generateRandomKey(data).subscribe((res: any) => {
      this.infoForm.patchValue({
        code: res.data,
      });
      this.infoForm.get('code').updateValueAndValidity();
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit(data){
    this.isLoading = true;
    if(this.infoForm.invalid) 
    {
      this.isLoading = false;
      return; 
    }
    //this.checkValidate = true;
    let formData = {
      fullname: data.fullname,
      code: data.code,
      description: data.description,
      indexOrder: parseInt(data.indexOrder),
      status: data.status === true ? 1 : 0
    };

    if (this.infoSoftwareModule.status === 'add'){
      this.moduleSoftwareManagerService.storeSubSystem(formData).subscribe((res: any) => {
        this.isLoading = false;
        if (res.status === 0) {
          this.isLoading = false;
          this.showMessage.warring(res.msg);
        }
        this.dialogRef.close(res);
      },(err:any) => {
        this.isLoading = false;
        this.showMessage.error(err.msg);
      });
      
    }else {
      this.moduleSoftwareManagerService.updateSubSystem(this.infoSoftwareModule.data.id, formData).subscribe((res: any) => {
        this.isLoading = false;
        if (res.status === 0)return this.showMessage.warring(res.msg);

        this.dialogRef.close(res);
      });
    }
  }
  checkInputName() {

  }

}
