<h4 mat-dialog-title class="title-dialog title-delete custom-title-move">{{ data_move_education.title }}</h4>
<p class="name-custom">{{ data_move_education.data.name }}</p>
<mat-dialog-content class="mat-typography mat-dialog-content-global">
    <div class="mat-dialog-content-global" [innerHTML]="data.html ? data.html : ''"></div>
    <div class="content-move-adminstrative">
        <span><input type="text" class="form-control input-field-global" [value]="data_move_education.data.nameSoGD" placeholder="Nhập tên tỉnh/thành phố" [disabled]="true"></span>
        <img class="img-change-status" src="assets/images/icons/next_icon.png" alt="">
        <span>
            <select class="form-control input-field-global" *ngIf="listEducationLv1" (change)="selectOption($event.target.value)">
                <option *ngFor="let item of listEducationLv1;let i = index" [value]="item.code" [selected]="i == 0 ? true : false">{{ item.name }}</option>
            </select>
        </span>
    </div>
    <!-- <p class="message-delete">{{ data_move_education.message }}</p> -->
</mat-dialog-content>
<mat-dialog-actions align="center" class="mt-3 mb-1">
    <button mat-button class="btn-custom btn-cancel-confirm" mat-dialog-close mat-raised-button>{{ data_move_education.btnCancelText }}</button>
    <button mat-button class="btn-custom btn-accept-confirm" [mat-dialog-close]="true" cdkFocusInitial mat-raised-button (click)="confirm()">{{ data_move_education.btnOkText }}</button>
</mat-dialog-actions>
