import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeneralService, ListenFirebaseService, SubjectService} from '@app/_services';
import {FormBuilder, FormGroup, Validators, ValidatorFn, FormControl} from '@angular/forms';
import {subjectRequest} from './subject-request';
import {ShowMessage} from '@app/_services';
import * as $ from 'jquery';
import {REGEX_FULL_NAME} from '@app/_shared/utils/constant';
import {Observable, Subscriber} from 'rxjs';


const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value !== null){
        let out = control.value.replace(/\s/g, '');
        if (out === '') {
            return {
                'trimError': { value: 'control has leading whitespace' }
            };
        }
        return null;
    }
};
const REGEX_CODE_NEXT = /^[\w\-]{2,}$/ ;
@Component({
    selector: 'app-dialog-form-subjects-manager',
    templateUrl: './dialog-form-subjects-manager.component.html',
    styleUrls: ['./dialog-form-subjects-manager.component.scss']
})
export class DialogFormSubjectsManagerComponent implements OnInit {
    infoForm: FormGroup;
    action: string;
    isLoading: boolean = false;
    selectGrade = [
        {id: 1, fullName: 'Mầm Non'},
        {id: 2, fullName: 'Tiểu học'},
        {id: 3, fullName: 'Trung học cơ sở'},
        {id: 4, fullName: 'Trung học phổ thông'},
        {id: 99, fullName: 'Khác'},
    ];

    errGrade = '';
    msgValidate = subjectRequest;
    isValidate = false;
    dataGrade = [];
    gradeIds = [];
    trainingLevel: number;
    checkCode = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private subjectService: SubjectService,
        private fb: FormBuilder,
        private showMessage: ShowMessage,
        private matDialogRef: MatDialogRef<DialogFormSubjectsManagerComponent>,
        private generalService: GeneralService,
        private listenFirebaseService: ListenFirebaseService
    ) {}

    ngOnInit(): void {
        this.action = this.data.action;
        this.trainingLevel = this.action === 'add' ? 1 : this.data.infoSubject?.grades[0].trainingLevel;
        this.getGrade(this.trainingLevel);
        this.getGradeChecked();
        this.infoForm = this.fb.group({
            code: [null, [Validators.required, trimValidator, Validators.pattern(REGEX_CODE_NEXT)]],
            fullname: [this.data.infoSubject !== null ? this.data.infoSubject.fullname : '', [Validators.required, Validators.pattern(REGEX_FULL_NAME), trimValidator]],
            description: [this.data.infoSubject !== null ? this.data.infoSubject.grades[0].description : ''],
            trainingLevel: [this.data.infoSubject !== null ? this.data.infoSubject?.grades[0].trainingLevel : 1],
            isMoetSubject: 'MOET',
            indexOrder: [this.data.infoSubject !== null ? this.data.infoSubject.indexOrder : 0, Validators.pattern(/^[0-9]{1,1000}$/)],
            status: [this.data.infoSubject !== null ? this.data.infoSubject.status === 1 ? true : false : false]
        });
        if (this.action === 'add'){
            this.getFormCode();
        }
        else{
            this.infoForm.patchValue({
                code: this.data.infoSubject.code
            });
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
      }

    getFormCode() {
        this.isLoading = true;
        this.generalService.getCodeRandom('SUBJECT').subscribe((ref: any) => {
            this.infoForm.patchValue({
                code: ref.data
            });
            this.isLoading = false;
        });
    }

    getGrade(param) {
        this.subjectService.getListByTrainingLevel(param).subscribe((res: any) => {
            return this.dataGrade = res.data;
        });
    }

    chooseTrainingLever() {
        this.getGradeChecked();
        this.errGrade = '';
        this.trainingLevel = this.infoForm.get('trainingLevel').value;
        return this.getGrade(this.infoForm.get('trainingLevel').value);
    }

    getGradeChecked(){
        if (this.action !== 'add'){
            this.data.infoSubject.grades.forEach((item, index) => {
                this.gradeIds.push(item.id);
            });
        }
    }

    chooseChecked(gradeId){
        let key = this.gradeIds.indexOf(gradeId);
        if (key === -1){
            this.gradeIds.push(gradeId);
        }else{
            this.gradeIds.splice(key, 1);
        }
        return this.getGrade(this.trainingLevel);
    }

    onSubmit(data) {
        this.isLoading = true;
        const gradeIds = [];
        $.each($('#check-box-grade-ids').find('.mat-checkbox-checked'), (index, element) => {
            // console.log($(element).find('input')[0].attributes[4].value);
            gradeIds.push($(element).find('input')[0].attributes[4].value);
        });

        if (this.infoForm.invalid) {
            this.isLoading = false;
            this.showMessage.warring('Vui lòng điền đầy đủ thông tin bắt buộc');
            return false;
        }

        if (gradeIds.length === 0) {
            this.isLoading = false;
            return this.errGrade = 'Bạn phải chọn khối';
        }

        const dataUpload = {
            fullname: data.fullname,
            gradeIds: gradeIds,
            code: data.code,
            description: data.description,
            trainingLevel: Number(data.trainingLevel),
            isMoetSubject: 1,
            indexOrder: Number(data.indexOrder),
            status: data.status === true ? 1 : 0,
        };
        // console.log(JSON.stringify(dataUpload));
        // return false;
        if (this.action === 'add'){
            this.subjectService.storeSubject(dataUpload).subscribe((res: any) => {
                if (res.status === 1){
                    this.isLoading = false;
                    return this.matDialogRef.close(true);
                }
                else{
                    this.isLoading = false;
                    this.checkCode = res.msg;
                }
            },
                err => {
                    this.isLoading = false;
                    return this.matDialogRef.close(true);
                });
            setTimeout(() => {
                if (this.isLoading){
                    this.isLoading = false;
                    return this.matDialogRef.close(true);
                }
            }, 10000);
        }
        else {
            this.subjectService.updateSubject(this.data.infoSubject.id, dataUpload).subscribe((res: any) => {
                if (res.status === 1){
                    this.isLoading = false;
                    return this.matDialogRef.close(true);
                }
                else{
                    this.isLoading = false;
                    this.checkCode = res.msg;
                }
            }, err => {
                this.isLoading = false;
                // ;
                return this.matDialogRef.close(true);
            });
            setTimeout(() => {
                if (this.isLoading){
                    this.isLoading = false;
                    return this.matDialogRef.close(true);
                }
            }, 10000);
        }
    }

}
