export class Tenant {
    id: string;
    code: string;
    email: string;
    fullname: string;
    hotline: string;
    status: number;
    created_at: string;
    updated_at: string;
    address: string;
    adminUrl: string;
    cityId: string;
    contactEmail: string;
    contactFullname: string;
    contactPhone: string;
    districtId: string;
    employeeDeployId: string;
    employeeSaleId: string;
    indexOrder: number;
    logo: string;
    tenantAdmin: string;
    tenantAdminPassword: string;
    tenantUrl: string;
    tenantWebsite: string;
    wardId: string;
    statusIndex: number;
    colorStatus: string;
    nameStatus: string;
    createdAt: string;
}