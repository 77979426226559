<form *ngIf="!isLoading" style="background-color: #fff; height: 100%;">
    <p class="form__title" mat-dialog-title>{{dataDialog.nameForm == 'assign' ? 'Gán năm học': 'Gỡ năm học'}}
        <span class="icon-close" mat-dialog-close><img src="assets/images/icons/Vector-24.png"></span>
    </p>
    <hr>
    <mat-dialog-content class="form__content">
        <div class="row"> 
            <div class="form-group col-md-6 row row-global group-field">
                <label class="col-md-3 lb-global lb-global-form">Mã chương trình </label>
                <div class="col-md-8 pl-0 pr-0">
                    <span class="form-control input-field-global custom-background-input">{{dataDialog.data?.code}}</span>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global group-field">
                <div class="col-md-1"></div>
                <label class="col-md-2 lb-global lb-global-form">Môn </label>
                <div class="col-md-9 pl-0 pr-0">
                    <span class="form-control input-field-global custom-background-input">{{dataDialog.data?.subjectName}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 row row-global group-field">
                <label class="col-md-3 lb-global lb-global-form">Tên chương trình </label>
                <div class="col-md-8 pl-0 pr-0">
                    <span class="form-control input-field-global custom-background-input">{{dataDialog.data?.fullName}}</span>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global group-field" fxLayout="row">
                <div class="col-md-1"></div>
                <label class="col-md-2 lb-global lb-global-form">Khối </label>
                <div class="col-md-9 pl-0 pr-0">
                    <span class="form-control input-field-global custom-background-input">{{dataDialog.data?.gradeName}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 row row-global group-field">
                <label class="col-md-3 lb-global lb-global-form">Năm học<span class="text-danger">(*)</span></label>
                <div class="col-md-8 pl-0 pr-0">
                    <select class="form-control input-field-global custom-background-input" (change)="chooseOption($event)">
                        <option value="null"> -- Chọn năm học -- </option>
                        <ng-container *ngFor="let item of listSchoolYear">
                            <option [value]="item.id">{{item.name}}</option> 
                        </ng-container>                           
                    </select>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <hr>
    <div mat-dialog-action style="display: flex; justify-content: flex-end;" class="form__footer">
        <button class="btn btn-custom-cancel-accept btn-cancel" mat-dialog-close>Huỷ</button>
        <button class="btn btn-custom-cancel-accept btn-accept ml-2" (click)="onSubmit()">Lưu</button> <!--curriculumForm.valid-->
    </div>
</form>