<div fxLayout="column" class="bg-content-detail-global">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h4 class="title-header">{{lang.tab_list_customer.title}}</h4>
            </div>
        </div>
    </div>
    <div fxFlex>
        <table mat-table [dataSource]="data" matSort class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    style="width: 5%;">{{lang.tab_list_customer.stt}}</th>
                <td mat-cell *matCellDef="let item; let i = index">{{ i + 1 }}</td>
            </ng-container>
            <ng-container matColumnDef="fullname" >
                <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header>{{lang.tab_list_customer.full_name}}</th>
                <td mat-cell *matCellDef="let item">{{ item.fullname }}</td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell style="width: 15%;" *matHeaderCellDef mat-sort-header>{{lang.tab_list_customer.code}}</th>
                <td mat-cell *matCellDef="let item">{{ item.code }}</td>
            </ng-container>
            <ng-container matColumnDef="domain">
                <th mat-header-cell style="width: 15%;" *matHeaderCellDef mat-sort-header>{{lang.tab_list_customer.domain}}</th>
                <td mat-cell *matCellDef="let item">{{ item.domain }}</td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell style="width: 15%;" *matHeaderCellDef mat-sort-header>{{lang.tab_list_customer.phone}}</th>
                <td mat-cell *matCellDef="let item">{{ item.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell style="width: 15%;" *matHeaderCellDef mat-sort-header>{{lang.tab_list_customer.email}}</th>
                <td mat-cell *matCellDef="let item">{{ item.email }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.tab_list_customer.status}}</th>
                <td mat-cell *matCellDef="let item">
                    <mat-checkbox [checked]="item.status == 1? true: false">
                        {{lang.tab_list_customer.active}}
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên module</th>
                <td mat-cell *matCellDef="let item">
                    <button class="btn btn-block" (click)="openDialogDelete(item)">
                        <img src="assets/images/svg/delete.svg" alt="">
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
        <mat-paginator appStylePaginator showFirstLastButtons [length]="length" [pageIndex]="0" [pageSize]="5"
                       class="table-paginate-tab-global"></mat-paginator>
    </div>
</div>
