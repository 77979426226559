import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, PermissonGuard } from './_helpers';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ExamsModule } from './_modules/exams/exams.module';
import { BooksModule } from './_modules/books/books.module';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { DefaultLayoutComponent } from './_layouts/default-layout/default-layout.component';
import { RolesManagerModule } from './_modules/roles-manager/roles-manager.module';
import { AccountsManagerModule } from './_modules/accounts-manager/accounts-manager.module';
import { TenantsManagerModule } from './_modules/tenants-manager/tenants-manager.module';
import { ModuleManagerModule } from './_modules/modules-manager/module-manager.module';
import { SchoolYearsManagerModule } from './_modules/school-years-manager/school-years-manager.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password/forgot-password.component';
import { VerifyAccountComponent } from './components/forgot-password/verify-account/verify-account.component';
import { ResetPasswordComponent } from './components/forgot-password/reset-password/reset-password.component';
import { PermissionManagerModule } from '@app/_modules/permission-manager/permission-manager.module';
import { ModuleSoftwareManagerModule } from '@app/_modules/module-software-manager/module-software-manager.module';
import { SubjectsModule } from '@app/_modules/subjects/subjects.module';
import { IndustryUnitModule } from '@app/_modules/industry-unit/industry-unit.module';
import { AdministrativeUnitModule } from '@app/_modules/administrative-units/administrative-units.module';
import { GradesManagerModule } from '@app/_modules/grades-manager/grades-manager.module';
import { DetailAccountComponent } from './components/profile/info/components/detail-account/detail-account.component';
import { PostPersonalListComponent } from '@app/components/profile/post-personal/components/post-personal-list/post-personal-list.component';
import { PostPersonalDetailComponent } from '@app/components/profile/post-personal/components/post-personal-detail/post-personal-detail.component';
import { NotificationListComponent } from '@app/components/profile/notification/components/notification-list/notification-list.component';
import { GeneralConfigurationsModule } from './_modules/general-configurations/general-configurations.module';
import { PostManagerModule } from '@app/_modules/post-manager/post-manager.module';
import { DashboardModule } from './_modules/dashboard/dashboard.module';
import { CurriculumModule } from '@app/_modules/curriculum/curriculum.module';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'verify-account',
    component: VerifyAccountComponent,
  },
  {
    path: 'setup-new-password/:id',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'examsystem',
        loadChildren: () => ExamsModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'bookssystem',
        loadChildren: () => BooksModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'roles-manager',
        loadChildren: () => RolesManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'permission-manager',
        loadChildren: () => PermissionManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'module-software-manager',
        canActivate: [AuthGuard],
        loadChildren: () => ModuleSoftwareManagerModule,
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'accounts-manager',
        loadChildren: () => AccountsManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'tenants-manager',
        loadChildren: () => TenantsManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'modules-manager',
        loadChildren: () => ModuleManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'school-years-manager',
        loadChildren: () => SchoolYearsManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'grades-manager',
        loadChildren: () => GradesManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'subjects-manager',
        loadChildren: () => SubjectsModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'post-manager',
        loadChildren: () => PostManagerModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'profile',
        component: DetailAccountComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: [],
        },
      },
      {
        path: 'notification',
        component: NotificationListComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: [],
        },
      },
      {
        path: 'post-personal',
        component: PostPersonalListComponent,
        canActivate: [AuthGuard],
        // component: PostPersonalDetailComponent,
        data: {
          allowedRoles: [],
        },
      },
      {
        path: 'post-personal/detail/:postId',
        component: PostPersonalDetailComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: [],
        },
      },
      {
        path: 'industry-unit',
        loadChildren: () => IndustryUnitModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'administrative-unit',
        loadChildren: () => AdministrativeUnitModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: 'general-configuration',
        loadChildren: () => GeneralConfigurationsModule,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: []
        }
      },
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'accessdenied',
        component: AccessDeniedComponent,
        canActivate: [AuthGuard],
        data: {}
      },
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule,
        data: {}
      },
      {
        path: 'curriculum',
        loadChildren: () => CurriculumModule,
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
