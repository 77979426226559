<div fxLayout="column" class="bg-content-global" *ngIf="dataDetailEducation1">
    <div fxLayout="column" fxFlex class="content-declare">
        <div fxFlex="3rem" fxLayout="row" fxLayoutAlign="start start">
            <div>
                <h4 class="title-page-global">{{ dataDetailEducation1.name }}</h4>
            </div>
        </div>
        <div fxFlex fxLayout="column" class="content__education-lv1" id="content__education-lv1">
            <div class="info__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <h4 class="title__education-lv1">Thông tin cơ bản</h4>
                    <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">
                        <button class="btn-add-global" mat-raised-button (click)="clickEditEducationLv1(dataDetailEducation1);">
                            <mat-icon>edit</mat-icon>Chỉnh sửa thông tin
                        </button>
                    </ng-template>
                </div>
                <div class="form__info-education-lv1 row">
                    <div fxLayout="row" fxLayoutAlign="center center" class="col-md-2">
                        <div class="img__info-education-lv1">
                            <img [src]="dataDetailEducation1.logo" alt="">
                        </div>
                    </div>
                    <div class="col-md-4 row custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Tên sở</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailEducation1.name" [disabled]="true" class="form-control input-field-global" placeholder="Nhập tên sở">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Thứ tự sắp xếp</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" [value]="dataDetailEducation1.sort" [disabled]="true" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                            </div>
                        </div>
                    </div>
                    <div class="row col-md-4 offset-1 custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Mã</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [value]="dataDetailEducation1.code" [disabled]="true" class="form-control input-field-global" placeholder="Nhập mã">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trạng thái</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <select class="form-control input-field-global" [disabled]="true">
                                    <option [value]="0" [selected]="dataDetailEducation1.status == 0 ? true : false"
                                        class="custom-text-option">Chưa kích hoạt</option>
                                    <option [value]="1" [selected]="dataDetailEducation1.status == 1 ? true : false"
                                        class="custom-text-option">Kích hoạt</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="tree__education-lv1" id="tree__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <button class="title__education-lv1-custom" mat-raised-button>Đơn vị quản lý</button>
                    <div style="display: flex;">
                        <select class="input-field-global search-industry-custom" #option_education>
                            <option [value]="2" class="custom-text-option">Phòng giáo dục</option>
                            <option [value]="3" class="custom-text-option">Trường trung học phổ thông</option>
                            <option [value]="4" class="custom-text-option">Trường trung học cơ sở</option>
                            <option [value]="5" class="custom-text-option">Trường tiểu học</option>
                            <option [value]="6" class="custom-text-option">Trường mầm non</option>
                        </select>
                        <div style="position: relative;">
                            <input (focusout)="searchFocusOut(search.value,option_education.value);" #search type="text" class="text-left btn btn-default button-search {{showSearch ? 'transition-search-show': ''}}" [value]="dataSearch" (input)="onSearchChange($event.target.value)"
                                [readOnly]="showSearch ? false: true" placeholder="{{showSearch ? 'Tìm kiếm' : ''}}">
                            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass(search.value)">
                            <div class="result-search {{ !checkResultSearch ? '' : 'active' }}">
                                <div class="item" *ngFor="let item of resultSearch" [routerLink]="[linkDetailEducation,item.id]">
                                    <h4>{{ item.name }}</h4>
                                    <p>{{ item.code }}</p>
                                </div>
                            </div>
                        </div>
                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm7]">
                            <button class="btn-file-detail-global mr-2" mat-raised-button>
                                <mat-icon>note_add</mat-icon> Import
                            </button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm2]">

                            <button class="btn-add-global" mat-raised-button (click)="clickAddEucationLv2(dataDetailEducation1);">
                                <mat-icon>add</mat-icon> Thêm mới
                            </button>
                        </ng-template>

                    </div>
                </div>

                <div class="items">
                    <!-- tree lv2, lv3 -->
                    <div fxFlex class="content-declare" fxLayout="row" fxLayoutAlign="space-between start" style="height: 100%;overflow-y: auto;">
                        <div class="lv-2" style="display: flex;flex-direction: column;width: 45%;height: 100%;">
                            <div class="title">Phòng GD, Trường <i>[{{ dataDetailEducation1.name }}]</i></div>
                            <!-- start PGD -->
                            <div class="content-lv-2">
                                <div class="header-lv-2" (click)="schowPGD(dataDetailEducation1.code);" id="content-pgd">
                                    <div class="grades">
                                        <div class="grade">
                                            <div class="content-grade">
                                                <div class="grade-left">
                                                    <h4><img [src]="checkOpenPGD ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countPGD }} Phòng giáo dục</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height: 30vh;overflow-y: auto;" *ngIf="checkOpenPGD">
                                    <div *ngFor="let item of dataPGD" class="grade" id="{{ item.code }}" (click)="openLv3(item.code,item.name)">
                                        <div class="content-grade {{ code_active_lv_2 == item.code ? 'active' : '' }}">
                                            <div class="grade-left">
                                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code
                                                        }})</span></h4>
                                                <p>
                                                    <span>Trường mầm non:{{ item.countMamNon }}</span>
                                                    <span>Trường tiểu học:{{ item.countTH }}</span>
                                                    <span>Trường THCS:{{ item.countTHCS }}</span>
                                                </p>
                                            </div>
                                            <div class="grade-right">
                                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_horiz</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">
                                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv2/detail/',item.id]">Chi
                                                                tiết</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">
                                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv2(item);">Chỉnh sửa</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv2(item);">Chuyển</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">
                                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                        </ng-template>
                                                    </mat-menu>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end PGD -->
                            <!-- start THPT -->
                            <div class="content-lv-2" [style]="checkClickLv1 ? 'visibility: visible;' : 'visibility: hidden;'" *ngIf="checkClickLv1">
                                <div class="header-lv-2" (click)="schowTHPT(dataDetailEducation1.code);" id="content-thpt">
                                    <div class="grades">
                                        <div class="grade">
                                            <div class="content-grade">
                                                <div class="grade-left">
                                                    <h4><img [src]="checkOpenTHPT ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countTHPT }} Trường</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height: 30vh;overflow-y: auto;" *ngIf="checkOpenTHPT">
                                    <div *ngFor="let item of dataTHPT" class="grade" id="{{ item.code }}">
                                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                                            <div class="grade-left">
                                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code
                                                        }})</span></h4>
                                            </div>
                                            <div class="grade-right">
                                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_horiz</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv2/detail/',item.id]">Chi
                                                                tiết</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv2(item);">Chỉnh sửa</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv2(item);">Chuyển</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                        </ng-template>
                                                    </mat-menu>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end THPT -->
                        </div>
                        <div class="lv-3" style="display: flex;flex-direction: column;width: 45%;height: 100%">
                            <div class="title">Trường <i>{{ nameLevel2 }}</i></div>
                            <!-- start THCS -->
                            <div class="content-lv-3" style="max-height: 33%;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                                <div class="header-lv-3" (click)="schowTHCS(parent_lv3);" id="content-thcs">
                                    <div class="grades">
                                        <div class="grade">
                                            <div class="content-grade">
                                                <div class="grade-left">
                                                    <h4><img [src]="checkOpenTHCS ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countTHCS }} Trường THCS</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;height: 83%;overflow-y: auto;" *ngIf="checkOpenTHCS">
                                    <div *ngFor="let item of dataTHCS" class="grade" id="{{ item.code }}">
                                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                                            <div class="grade-left">
                                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code
                                                        }})</span></h4>
                                            </div>
                                            <div class="grade-right">
                                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_horiz</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv3/detail/',item.id]">Chi
                                                                tiết</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3(item);">Chỉnh sửa</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv3(item);">Chuyển</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                        </ng-template>
                                                    </mat-menu>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end THCS -->
                            <!-- start TH -->
                            <div class="content-lv-3" style="max-height: 33%;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                                <div class="header-lv-3" (click)="schowTH(parent_lv3);" id="content-th">
                                    <div class="grades">
                                        <div class="grade">
                                            <div class="content-grade">
                                                <div class="grade-left">
                                                    <h4><img [src]="checkOpenTH ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countTH }} Trường tiểu học</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;height: 83%;overflow-y: auto;" *ngIf="checkOpenTH">
                                    <div *ngFor="let item of dataTH" class="grade" id="{{ item.code }}">
                                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                                            <div class="grade-left">
                                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code
                                                        }})</span></h4>
                                            </div>
                                            <div class="grade-right">
                                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_horiz</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">
                                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv3/detail/',item.id]">Chi
                                                                tiết</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">
                                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3(item);">Chỉnh sửa</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv3(item);">Chuyển</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">
                                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                        </ng-template>
                                                    </mat-menu>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end TH -->
                            <!-- start MG -->
                            <div class="content-lv-3" style="max-height: 33%;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                                <div class="header-lv-3" (click)="schowMG(parent_lv3);" id="content-mg">
                                    <div class="grades">
                                        <div class="grade">
                                            <div class="content-grade">
                                                <div class="grade-left">
                                                    <h4><img [src]="checkOpenMG ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countMG }} Trường mẫu giáo</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;height: 83%;overflow-y: auto;" *ngIf="checkOpenMG">
                                    <div *ngFor="let item of dataMG" class="grade" id="{{ item.code }}">
                                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                                            <div class="grade-left">
                                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code
                                                        }})</span></h4>
                                            </div>
                                            <div class="grade-right">
                                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_horiz</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">
                                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv3/detail/',item.id]">Chi
                                                                tiết</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3(item);">Chỉnh sửa</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv3(item);">Chuyển</button>
                                                        </ng-template>
                                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                        </ng-template>
                                                    </mat-menu>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end MG -->
                        </div>
                    </div>
                    <!-- end tree lv2, lv3 -->
                </div>

            </div>
        </div>
    </div>
</div>