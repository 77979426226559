import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {ConfirmComponent} from '@app/_shared/dialogs/confirm/confirm.component';
import {Observable, Subscriber} from 'rxjs';
import * as _lodash from 'lodash';
@Component({
  selector: 'app-dialog-subject-add-grades',
  templateUrl: './dialog-subject-add-grades.component.html',
  styleUrls: ['./dialog-subject-add-grades.component.scss']
})
export class DialogSubjectAddGradesComponent implements OnInit {
  dataColumn = ['stt', 'grade', 'code', 'level'];
  selectGrade = [
    {id: 1, fullName: 'Mầm Non'},
    {id: 2, fullName: 'Tiểu học'},
    {id: 3, fullName: 'Trung học cơ sở'},
    {id: 4, fullName: 'Trung học phổ thông'},
    {id: 99, fullName: 'Khác'},
  ];
  dataSource: any;
  allComplete = false;
  data: any;
  length: number;
  isLoading = false;
  dataFlag = [];
  constructor(
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public value,
      private subjectService: SubjectService,
      private dialogRef: MatDialogRef<DialogSubjectAddGradesComponent>,
      private listenFirebaseService: ListenFirebaseService,
      private showMessage: ShowMessage
  ) { }
  ngOnInit(): void {
    this.listGradeNot();
  }
  listGradeNot(keyWord?: string){
    this.subjectService.getListGradeNotSubject(this.value.id, keyWord).subscribe((ref: any) => {
      this.data = ref.data;
      // this.dataSource.forEach(t => t.checked = 0);
      this.data.forEach(item => {
        if ( _lodash.indexOf(this.dataFlag, item.id) !== -1){
          item.checked = 1;
        }
        else{
          item.checked = 0;
        }
        item.trainingLevel = this.selectGrade.find(a => a.id === item.trainingLevel).fullName;
      });
      this.allComplete = this.data != null && this.data.length > 0 && this.data.every(t => t.checked === 1);
      this.length = this.data.filter(x => x.checked === 1).length;
      this.isLoading = false;
    });
  }
  checkText(event, value) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.isLoading = true;
      this.listGradeNot(value);
    }
  }
  checkAllModule(event){
    if (this.data == null) {
      return;
    }
    this.allComplete = event.checked;
    if (event.checked){
      this.data.forEach(t => {
        t.checked = 1;
        this.dataFlag.push(t.id);
      });
    }
    else{
      this.data.forEach(t => {
        t.checked = 0;
        let i = _lodash.indexOf(this.dataFlag, t.id);
        this.dataFlag.splice(i, 1);
      });
    }
    this.length = this.data.filter(x => x.checked === 1).length;
  }
  updateAllComplete(event, itemId: string) {
    if (event.checked){
      this.data.find(x => x.id === itemId).checked = 1;
      this.dataFlag.push(itemId);
    }
    else{
      this.data.find(x => x.id === itemId).checked = 0;
      let i = _lodash.indexOf(this.dataFlag, itemId);
      this.dataFlag.splice(i, 1);
    }
    this.allComplete = this.data != null && this.data.length > 0 && this.data.every(t => t.checked === 1);
    this.length = this.data.filter(x => x.checked === 1).length;
  }
  addGradeBySubject(){
    if ((this.data.filter(x => x.checked === 1)).length === 0){
      this.dialogRef.close();
      return;
    }
    const dialogDelete = this.dialog.open(ConfirmComponent, {
      height: 'auto',
      width: '36%',
      data: {
        title: 'Bạn đã thực hiện thêm khối áp dụng môn học',
        name: this.value.name,
        message: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
        btnCancel: 'HỦY',
        btnConfirm: 'XÁC NHẬN',
        color: 'red'
      },
    });
    dialogDelete.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isLoading = true;
        let gradeArray = [];
        this.data.filter(x => x.checked === 1).forEach(item => gradeArray.push(item.id));
        this.subjectService.addGradeBySubject(this.value.id, gradeArray).subscribe((ref: any) => {
          if (ref.status === 1){
          }
          else{
            this.showMessage.error(ref.msg);
            this.isLoading = false;
            return this.dialogRef.close(true);
          }
        });
        setTimeout(() => {
          if (this.isLoading){
            this.isLoading = false;
            this.dialogRef.close(true);
          }
        }, 10000);
        this.checkFireBaseModule();
      }
    });
  }

  checkFireBaseModule(){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('add_grade_to_subject', 'subject_grade', subscriber);
    });
    listenFb.subscribe((refg) => {
      if (refg === true) {
        this.isLoading = false;
        return this.dialogRef.close(true);
      }
    });
  }
}
