import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PostManagerService} from '@app/_services';
import {$e} from 'codelyzer/angular/styles/chars';

@Component({
  selector: 'app-dialog-post-manager-list-user',
  templateUrl: './dialog-post-manager-list-user.component.html',
  styleUrls: ['./dialog-post-manager-list-user.component.scss']
})
export class DialogPostManagerListUserComponent implements OnInit {
  dataColumn = ['stt', 'fullName', 'code', 'phone', 'email'];
  dataSource = [];
  showSearch: boolean = false;
  showFilter: boolean = false;
  check = false;
  KeySearch = '';
  IsSend = 0;
  IsReceive = 0;
  IsRead = 0;
  dataStatus = [
    {id: 0, fullName: 'Đã gửi', isChecked: false},
    {id: 1, fullName: 'Đã nhận', isChecked: false},
    {id: 2, fullName: 'Đã đọc', isChecked: false},
  ];
  constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private dialogRes: MatDialogRef<DialogPostManagerListUserComponent>,
      private postManagerService: PostManagerService,
      @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
   this.loadDataItems();
  }

  loadDataItems(){
    this.postManagerService.getUsersReceivedPost(this.data.postId, this.KeySearch, this.IsSend, this.IsReceive, this.IsRead).subscribe((res: any) => {
      this.dataSource = res.data;
    });
  }

  clickSearch(value){
    this.KeySearch = value;
    this.loadDataItems();
  }

  keyPressSearch(event){
    if (event.code === 'Enter'){
      this.KeySearch = event.target.value;
      this.loadDataItems();
    }
  }

  toggleClass(value, check){
    if (!check){
      this.showSearch = !this.showSearch;
      (document.getElementById('search-value') as HTMLInputElement).value = '';
    }
    else{
      this.check = false;
      this.KeySearch = value;
    }
  }

  checkText(){
    // let demo = document.getElementById('search-value').value;
    let demo = (document.getElementById('search-value') as HTMLInputElement).value;
    if (demo.length > 0){
      this.check = true;
    }
    else {
      this.check = false;
    }
  }

  itemOptionClick(keyDataStatus: number){
    this.dataStatus[keyDataStatus].isChecked = !this.dataStatus[keyDataStatus].isChecked;
    this.IsSend = this.dataStatus[0].isChecked === true ? 1 : 0;
    this.IsReceive = this.dataStatus[1].isChecked === true ? 1 : 0;
    this.IsRead = this.dataStatus[2].isChecked === true ? 1 : 0;

    this.loadDataItems();
  }
}
