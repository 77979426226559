import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ModulesManagerService {

    constructor(private http: HttpClient) {
    }

    getDataModule(pageSize, pageIndex,keyword,isActive,isApplyCore) {
        return this.http.get(`${environment.coreApiUrl}/api/module?PageSize=${pageSize}&PageIndex=${pageIndex}&keyword=${keyword}&isActive=${isActive}&isApplyCore=${isApplyCore}`);
    }

    generateRandomKey(data: {prefix: string, lenghtOfAlphabet: number, lenghtOfUpercase: number, lenghtOfNumber: number, lenghtOfSpecial: number}){
        return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`, data);
    }

    storeModule(data) {
        return this.http.post(`${environment.coreApiUrl}/api/module`, data);
    }

    getModuleById(id: string) {
        return this.http.get(`${environment.coreApiUrl}/api/module/${id}`);
    }

    updateModule(id, data) {
        return this.http.put(`${environment.coreApiUrl}/api/module/${id}`, data);
    }

    deleteModule(moduleId: string) {
        return this.http.post(`${environment.coreApiUrl}/api/module/delete`, {moduleId});
    }

    getListPermissionByModule(moduleId='', keyword='') {
        return this.http.post(`${environment.coreApiUrl}/api/module/get-permission`, {moduleId, keyword});
    }

    storePermissionByModule(data: any) {
        return this.http.post(`${environment.coreApiUrl}/api/module/create-permission`, data);
    }

    updatePermissionByModule(data: any) {
        return this.http.put(`${environment.coreApiUrl}/api/module/update-permission`, data);
    }

    deletePermissionByModule(itemId: string) {
        return this.http.post(`${environment.coreApiUrl}/api/module/delete-permission`, {
            id: itemId
        });
    }

    /** add tenant vao module **/
    storeTenantOfModule(data: {moduleId: string, tenantIds: string[]}) {
        return this.http.post(`${environment.coreApiUrl}/api/module/add-tenant`, data);
    }

    /**Xoa tenant**/
    removeTenantById(data: {moduleId: string, tenantId: string}){
        return this.http.post(`${environment.coreApiUrl}/api/module/delete-tenant`, data);
    }

    /**Danh sach tenant trong chi tiet modules**/
    listTenantOfModule(data: {moduleId: string, keyword: string, status: number[]}) {
        return this.http.post(`${environment.coreApiUrl}/api/module/${data.moduleId}/tenants`, data);
    }

    /**Lay danh sach tenant ma module do chua tich**/
    lisTenantNotBelongModule(module_id: string, keyword: string){
        return this.http.post(`${environment.coreApiUrl}/api/module/${module_id}/tenants-not-belong-module`, {keyword});
    }

    changeStatus(data: { moduleId: string, isActive: number }) {
        return this.http.post(`${environment.coreApiUrl}/api/module/change-status`, data);
    }

    getCodeRandom(code: string){
        return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`, {
            prefix: code,
            lenghtOfAlphabet: 0,
            lenghtOfUpercase: 0,
            lenghtOfNumber: 6,
            lenghtOfSpecial: 0
        });
    }

}
