import { Injectable } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import { environment } from '@environments/environment';
import { Subscriber } from 'rxjs';
import { ShowMessage } from './show-message.service';

@Injectable({
    providedIn: 'root'
})
export class ListenFirebaseService {
    constructor(
        private db: AngularFireDatabase,
        private message: ShowMessage
        ) { }

    checkFireBase(param, typeModule, subscriber: Subscriber<any>){
        this.db.list(`core_admin/${typeModule}/${param}/${environment.userID}`).valueChanges().subscribe((res: any) => {
            if (res.length > 0) {
                let parseRes = JSON.parse(res[0]);
                if (parseRes.status === true) {
                    this.db.list(`core_admin/${typeModule}/${param}/${environment.userID}`).remove();
                    this.message.success(parseRes.message);
                    subscriber.next(true);
                } else {
                    this.db.list(`core_admin/${typeModule}/${param}/${environment.userID}`).remove();
                    this.message.error(parseRes.message);
                    subscriber.next(false);
                }
                subscriber.complete();
            }
        });
    }

    checkFireBaseSoftBook(param, typeModule, subscriber: Subscriber<any>) {
        this.db.list(`soft_book/${typeModule}/${param}/${environment.userID}`).valueChanges().subscribe((res: any) => {
            if (res.length > 0) {
              let parseRes = JSON.parse(res[0]);
              if (parseRes.status === true) {
                this.db.list(`soft_book/${typeModule}/${param}/${environment.userID}`).remove();
                this.message.success(parseRes.message);
                subscriber.next(true);
              } else {
                this.db.list(`soft_book/${typeModule}/${param}/${environment.userID}`).remove();
                this.message.error(parseRes.message);
                subscriber.next(false);
              }
              subscriber.complete();
            }
        });
    }

}
