import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/_shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IndustryUnitRoutingModule } from './industry-unit-routing.module';
import { EducationLv1Component } from './components/education-lv1/education-lv1.component';
import { DetailEducationLv1Component } from "./components/detail-education-lv1/detail-education-lv1.component";
import { DetailEducationLv2Component } from "./components/detail-education-lv2/detail-education-lv2.component";
import { DetailEducationLv3Component } from "./components/detail-education-lv3/detail-education-lv3.component";
import { ImportIndustryComponent } from "./components/import-industry/import-industry.component";
import { DialogFormEducationLv1Component } from "./dialog/dialog-form-education-lv1/dialog-form-education-lv1.component";
import { DialogFormEducationLv2Component } from "./dialog/dialog-form-education-lv2/dialog-form-education-lv2.component";
import { DialogFormEducationLv3Component } from "./dialog/dialog-form-education-lv3/dialog-form-education-lv3.component";
import { DialogImportIndustryComponent } from "./dialog/dialog-import-industry/dialog-import-industry.component";
import { DialogMoveEducationComponent } from "./dialog/move-education/dialog-move-education.component";
import { DialogMoveEducationLv2Component } from "./dialog/move-education-lv2/dialog-move-education-lv2.component";
import { NgxPermissionsModule } from 'ngx-permissions';


@NgModule({
  declarations: [
    EducationLv1Component,
    DetailEducationLv1Component,
    DetailEducationLv2Component,
    DetailEducationLv3Component,
    ImportIndustryComponent,
    DialogFormEducationLv1Component,
    DialogFormEducationLv2Component,
    DialogFormEducationLv3Component,
    DialogImportIndustryComponent,
    DialogMoveEducationComponent,
    DialogMoveEducationLv2Component
  ],
  imports: [
    CommonModule,
    IndustryUnitRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild()
  ]
})
export class IndustryUnitModule { }
