import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralConfigService {

    constructor(private http: HttpClient) { }

    getDomainNameInUrl() {

        var domainName = `${window.location.hostname}:${window.location.port}`;
        domainName = `${window.location.hostname}`;
        var port = `${window.location.port}`;
        if (port == '80' || port == '443') {
            domainName = `${window.location.hostname}`;
        }
        return domainName;
    }

    /**lay thong tin he thong**/
    getInfoSystemConfig() {
        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-system-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**Chinh sua thong tin he thong**/
    updateSystemConfig(data: {
        tenantId: string,
        fullname: string,
        phone: string,
        hotline: string,
        email: string,
        language: string,
        address: string,
        logo: string,
        logo_filename: string,
        login_welcome: string,
        login_welcome_filename: string,
        login_logo: string,
        login_logo_filename: string,
        login_background: string,
        login_background_filename: string
    }) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/system-config`, data);
    }

    /**chinh sua cau hinh bao mat**/
    updateSecurityConfig(data: {
        max_login_failed: string,
        password_min_length: string,
        password_max_length: string,
        password_format: number[],
        resend_code_max_time: string
    }) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/security-config`, data);
    }

    /**thong tin cau hinh**/
    infoSecurityConfig() {
        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-security-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**Chinh sua email server**/
    updateEmailServerConfig(data: {
        smtp_url: string,
        smtp_port: string,
        smtp_user: string,
        smtp_password: string,
        smtp_tls: string
    }) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/email-server-config`, data);
    }

    /**Lay thong tin email server**/
    getInfoEmailServer() {

        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-email-server-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**thong tin gioi thieu he thong**/
    getIntroConfig() {
        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-intro-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**cap nhat gioi thieu he thong**/
    updateIntroConfig(about_intro: string) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/intro-config`, { about_intro });
    }

    /**Chinh sach bao mat**/
    getAboutPolicyConfig() {
        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-about-policy-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**cap nhat chinh sach bao mat**/
    updateAboutPolicyConfig(about_private_policy: string) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/about-policy-config`, { about_private_policy });
    }

    /**Dieu khoan dich vu**/
    getAboutTermOfServiceConfig() {
        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-about-term-of-service-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**cap nhat dieu khaon dich vu**/
    updateAboutTermOfServiceConfig(about_terms_of_service: string) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/about-term-of-service-config`, { about_terms_of_service });
    }


    /**Tich hop elsa**/
    getIntegratedElsaConfig() {
        // var domainName = `${window.location.hostname}:${window.location.port}`;
        // domainName = `${window.location.hostname}`;
        // var port = `${window.location.port}`;
        // if (port == '80' || port == '443') {
        //     domainName = `${window.location.hostname}`;
        // }
        // let domain = domainName;
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-elsa-tenant-config`, {
            domain:this.getDomainNameInUrl()
        });
    }

    /**cap nhat tich hop elsa**/
    updateIntegratedElsaConfig(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/elsa-tenant-config`, data);
    }

    // *** tich hop cau hinh elsa ***
    // danh sach
    getListIntegratedElsaConfig(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-elsa-config`, data);
    }
    // them
    addIntegratedElsaConfig(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/create-elsa-config`, data);
    }
    // sua
    editIntegratedElsaConfig(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/update-elsa-config`, data);
    }
    // xoa
    deleteIntegratedElsaConfig(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/delete-elsa-config`, data);
    }

    // lấy danh sách đơn vị giáo dục
    getListDVGD() {
        return this.http.get(`${environment.authApiUrl}/api/admin/education-unit/simple`);
    }

    // ** cấu hình sms khi quên mật khẩu ***
    getListConfigSMS() {
        return this.http.get(`${environment.authApiUrl}/api/admin-config/get-send-sms-forgot-password-config`);
    }

    changeActiveSMS(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/update-send-sms-forgot-password-config`, data);
    }

    // *** cấu hình thông báo hệ thống ***
    getListConfigNoti() {
        return this.http.get(`${environment.authApiUrl}/api/admin-config/system-alert/index`);
    }

    addConfigNoti(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/system-alert/store`, data);
    }

    changeActiveConfigNoti(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/system-alert/update`, data);
    }

    deleteConfigNoti(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/system-alert/delete`, data);
    }

    // lay private key upload file
    getPrivateKeyUploadFile(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/get-private-key-upload`, data);
    }
    // update private key uplaod file
    updatePrivateKeyUploadFile(data) {
        return this.http.post(`${environment.authApiUrl}/api/admin-config/private-key-upload`, data);
    }
}
