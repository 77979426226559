import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';

import { DialogFormAdministrativeLv2Component } from '../../dialogs/dialog-form-administrative-lv2/dialog-form-administrative-lv2.component';
import { DialogFormAdministrativeLv3Component } from '../../dialogs/dialog-form-administrative-lv3/dialog-form-administrative-lv3.component';
import { DialogMoveAdministrativeComponent } from '../../dialogs/move-administrative/dialog-move-administrative.component';
import { ActivatedRoute } from '@angular/router';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {ListenFirebaseService} from '@app/_services';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
// import leader line
import 'leader-line';
import { forEachChild } from 'typescript';
import { DialogMoveAdministrativeLv3Component } from '../../dialogs/move-administrative-lv3/dialog-move-administrative-lv3.component';
import {LOCATION_PERMISSION} from '@app/_modules/administrative-units/constant';
// declare let LeaderLine: any;
declare var LeaderLine: any;

@Component({
  selector: 'app-detail-administrative-lv2',
  templateUrl: './detail-administrative-lv2.component.html',
  styleUrls: ['./detail-administrative-lv2.component.scss']
})
export class DetailAdministrativeLv2Component implements OnInit {
  dataSource2:any;
  permissionLocation = LOCATION_PERMISSION;
  constructor(
    public dialog: MatDialog,
    private activatedRoute:ActivatedRoute,
    private administrativeUnitService:AdministrativeUnitService,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService
  ){}
  code_active = '';
  code_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  show_level_2 = false;
  show_level_3 = false;
  showSearch: boolean = false;
  dataSearch: string = '';
  idLocationLv2:any;
  dataDetailLocation:any;
  codeLocationLv2:any;
  dataSource:any;
  isLoading:boolean = false;
  resultSearch:any;
  checkResultSearch:boolean = false;
  linkDetailEducation:string;

  // khai báo line
  lineLv2 = [];
  lineLv3 = [];
  checkOpenPGD:boolean = false;
  checkOpenTHPT:boolean = false;
  checkOpenTHCS:boolean = false;
  checkOpenTH:boolean = false;
  checkOpenMG:boolean = false;
  countQuanHuyen:number = 0;
  countPhuongXa:number = 0;
  countTinhTP:number = 0;
  dataQuanHuyen = [];
  dataPhuongXa = [];
  checkClickLv1:boolean = false;
  checkClickLv2:boolean = false;
  parent_lv2:string='';
  parent_lv3:string='';

  ngOnInit(){
    this.getDataDetailLocation();
  }

  typeModule = 'location';

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataDetailLocation();
      }
    });
  }


  getDataDetailLocation(){
    this.isLoading = true;
    this.idLocationLv2 = this.activatedRoute.snapshot.params.id;
    this.administrativeUnitService.getDetailLocation(this.idLocationLv2).subscribe(
      (res:any)=>{
        this.dataDetailLocation = res.data;
        this.codeLocationLv2 = res.data.id;
        this.getLocationLv3(res.data.id);
        this.isLoading = false;
      },
      (err)=>{
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  getLocationLv3(id:any){
    this.administrativeUnitService.getLocationLv3(id).subscribe(
      (res:any)=>{
        this.dataPhuongXa = res.data;
        this.countPhuongXa = res.data.length;
        this.close_lv_2 = true;
      },
      (err)=>{

      }
    )
  }

  toggleClass(value){
    this.dataSearch = value;
    this.showSearch = !this.showSearch;
    this.checkResultSearch = false;
  }

  clickEditAdminstrativeLv2(data:any){
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) this.checkFireBase('update');
    });
  }

  clickEditEducationLv3(data:any){
    data.fullname = data.name;
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) this.checkFireBase('update');
    });
  }

  clickDeleteAdminstrative(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa đơn vị',
        message:'Xác nhận xóa?',
        btnOkText:'Delete',
        btnCancelText:'Cancel',
        data: {
          id:data.id,
          name:data.fullname
        }
      }
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.isLoading = true;

        this.administrativeUnitService.deleteLocation(data.id).subscribe(
          (res:any)=>{
            this.isLoading = false;
            this.checkFireBase('delete')

          },
          (err)=>{
            this.isLoading = false;
          }
        )
      } 
      
    })
  }

  clickAddEucationLv3(){
    this.administrativeUnitService.generateCode('DVHC').subscribe(
      (res:any)=>{
        const dialogRef = this.dialog.open(DialogFormAdministrativeLv3Component,{
          height:'auto',
          width:'80%',
          data: {
            status:"add",
            code:res.data,
            parent:{
              code:this.dataDetailLocation.code,
              name:this.dataDetailLocation.fullname
            },
            parent_lv1:{
              code:this.dataDetailLocation.cityId,
              name:this.dataDetailLocation.cityName ? this.dataDetailLocation.cityName : "Tên thành phố demo"
            }
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result) this.checkFireBase('create');
        });
      },
      (err)=>{

      }
    )
  }

  clickMoveAdminstrativeLv2(id){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeComponent, {
      height:'auto',
      width:'30%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: {
            id:id,
            name:"Quận Đống Đa"
        }
      }
    });
  }

  clickMoveAdminstrativeLv3(data){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeLv3Component, {
      height:'auto',
      width:'80%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });
  }

  searchFocusOut(txtSearch:string='',unit:number){
    this.isLoading = true;
    this.administrativeUnitService.filterLocation(txtSearch,Number(unit),this.dataDetailLocation.cityId,this.dataDetailLocation.code).subscribe(
      (res:any)=>{
        this.dataSource2 = res.data;
        this.checkResultSearch = true;
        this.linkDetailEducation = '/administrative-unit/lv3/detail/';
        this.isLoading = false;
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeLineLeader(){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = [];
    this.lineLv3 = [];
  }

}

