import { Component, OnInit } from '@angular/core';
import { lang } from '../../../general-configuration.lang';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { ListenFirebaseService, ShowMessage } from '@app/_services';
import { GeneralConfigService } from "@app/_services";
import { validateSystem } from './validateSystem';
import { AngularFireDatabase } from '@angular/fire/database';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { Base64Service } from '@app/_services/base-64.service';
import { Observable, Subscriber } from 'rxjs';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {
  lang = lang;
  panelOpenState = true;
  urlImage: SafeResourceUrl = 'assets/images/png/avatar.png';
  infoForm;
  isLoading = false;
  isValidate = false;
  messageError = validateSystem;
  fileImage = {
    logo: {
      logo_filename: '',
      url: '',
      file: ''
    },
    login_logo: {
      login_logo_filename: '',
      url: '',
      file: ''
    },
    login_background: {
      login_background_filename: '',
      url: '',
      file: ''
    },
  }
  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private showMessage: ShowMessage,
    private generalConfigService: GeneralConfigService,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService,
    private base64: Base64Service,
    private titleService:Title
  ) { }

  typeModule = 'admin_config';

  checkFireBase() {
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('update', this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.isLoading = false;
        this.getInfoSystem();
        this.getConfig();
      } else {
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.getInfoSystem();
  }

  getInfoSystem() {
    this.isLoading = true;
    var domainName = `${window.location.hostname}:${window.location.port}`;
    domainName=`${window.location.hostname}`;
    var port=`${window.location.port}`;
    if( port=='80'|| port=='443'){
      domainName=`${window.location.hostname}`;
    }
    let domain =domainName;
    // let dataInput = {
    //     domain: domain
    // }
    this.generalConfigService.getInfoSystemConfig().subscribe((res: any) => {
      this.infoForm = this.fb.group({
        fullname: [res.data.fullname, [Validators.required, Validators.maxLength(250)]],
        phone: [res.data.phone, [Validators.pattern(/^(0|0084|\+84)?(3[2|3|4|5|6|7|8|9]|5[6|8|9]|7[0|6|7|8|9]|8[1|2|3|4|5|6|8|9]|9[0|1|3|4|6|7|8|9])+([0-9]{7})$/)]],
        email: [res.data.email, [Validators.required, Validators.email, Validators.maxLength(250)]],
        hotline: [res.data.hotline, [Validators.pattern(/^(0|0084|\+84)?(3[2|3|4|5|6|7|8|9]|5[6|8|9]|7[0|6|7|8|9]|8[1|2|3|4|5|6|8|9]|9[0|1|3|4|6|7|8|9])+([0-9]{7})$/)]],
        language: [res.data.language],
        address: [res.data.address, Validators.maxLength(150)],
        login_welcome: [res.data.login_welcome],
      });
      this.loadImage(res);
    });
  }

  loadImage(res) {
    this.fileImage.logo.url = res.data.logo ? res.data.logo : this.urlImage;
    this.fileImage.logo.file = res.data.logo ? res.data.logo : this.urlImage;

    this.fileImage.login_logo.url = res.data.login_logo ? res.data.login_logo : this.urlImage;
    this.fileImage.login_logo.file = res.data.login_logo ? res.data.login_logo : this.urlImage;

    this.fileImage.login_background.url = res.data.login_background ? res.data.login_background : this.urlImage;
    this.fileImage.login_background.file = res.data.login_background ? res.data.login_background : this.urlImage;
    this.isLoading = false;
  }

  getDataFile(name, nameFile, urlImage) {
    if (name == 'logo') {
      this.fileImage.logo.file = urlImage;
      this.fileImage.logo.logo_filename = nameFile;
      // @ts-ignore
      return this.fileImage.logo.url = urlImage;
    }
    if (name == 'login-logo') {
      this.fileImage.login_logo.file = urlImage;
      this.fileImage.login_logo.login_logo_filename = nameFile;
      // @ts-ignore
      return this.fileImage.login_logo.url = urlImage;
    }
    if (name == 'login-background') {
      this.fileImage.login_background.file = urlImage;
      this.fileImage.login_background.login_background_filename = nameFile;
      // @ts-ignore
      return this.fileImage.login_background.url = urlImage;
    }
  }

  myimage: Observable<any>;
  onFileChange(event, name) {
    if (event.target.files.length > 0) {

      const file = (event.target as HTMLInputElement).files[0];
      let nameFile = file.name;
      let myimage = new Observable((subscriber: Subscriber<any>) => {
        this.base64.readFile(file, subscriber);
      });
      myimage.subscribe((resultBase64) => {
        this.getDataFile(name, nameFile, resultBase64);
      });
    }
  }

  chooseFile(element) {
    element.click();
  }

  onSubmit(data) {
    this.isLoading = true;
    let uploadData: any = data;
    uploadData.fullname = data.fullname.trim();
    uploadData.address = data.address.trim();
    uploadData.login_welcome = data.login_welcome.trim();
    uploadData.logo = this.fileImage.logo.file;
    uploadData.login_logo = this.fileImage.login_logo.file;
    uploadData.login_background = this.fileImage.login_background.file;

    uploadData.logo_filename = this.fileImage.logo.logo_filename;
    uploadData.login_logo_filename = this.fileImage.login_logo.login_logo_filename;
    uploadData.login_background_filename = this.fileImage.login_background.login_background_filename;

    if (this.infoForm.invalid) {
      this.isLoading = false;
      this.showMessage.error('Bạn cần kiểm tra lại các ô nhập', 2000);
      return this.isValidate = true;
    }
    this.checkFireBase();
    this.generalConfigService.updateSystemConfig(uploadData).subscribe((res: any) => {
      this.isLoading = false;
      if (res.status === 0) {
        this.showMessage.error(res.msg);
        this.isLoading = false;
      }
    }, (err: any) => {
      this.isLoading = false;
    });
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
        this.getConfig();
      }
    }, TIME_OUT_LISTEN_FIREBASE);

  }

  getConfig(){
   
    var domainName = `${window.location.hostname}:${window.location.port}`;
    domainName=`${window.location.hostname}`;
    var port=`${window.location.port}`;
    if( port=='80'|| port=='443'){
      domainName=`${window.location.hostname}`;
    }
    let domain =domainName;
    this.generalConfigService.getInfoSystemConfig().subscribe((ref: any) => {
      let configCore = ref.data;
      document.getElementById("title-page-config").textContent = ref.data.fullname;
      this.titleService.setTitle(ref.data.fullname);
      localStorage.setItem('config', JSON.stringify(configCore));
    });
  }

  cancel() {
    this.fileImage.logo.logo_filename = '';
    this.fileImage.logo.url = '';
    this.fileImage.logo.file = '';

    this.fileImage.login_background.login_background_filename = '';
    this.fileImage.login_background.url = '';
    this.fileImage.login_background.file = '';

    this.fileImage.login_logo.login_logo_filename = '';
    this.fileImage.login_logo.url = '';
    this.fileImage.login_logo.file = '';
    this.getInfoSystem();
  }
}
