import { Component, OnInit } from '@angular/core';
import {lang} from '../../general-configuration.lang';
import * as $ from 'jquery';
import { GENERAL_CONFIGURATIONS_PERMISSION } from '../../constants';
@Component({
  selector: 'app-general-configuration-system',
  templateUrl: './general-configuration-system.component.html',
  styleUrls: ['./general-configuration-system.component.scss']
})
export class GeneralConfigurationSystemComponent implements OnInit {
  panelOpenState = true;
  lang = lang;
  GENERAL_CONFIGURATIONS_PERMISSION = GENERAL_CONFIGURATIONS_PERMISSION;
  links = [
    {
      route: 'tab1',
      name: 'Hệ thống',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm1
    },
    {
      route: 'tab2',
      name: 'Bảo mật',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm2

    },
    {
      route: 'tab3',
      name: 'Email server',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm3
    },
    {
      route: 'tab4',
      name: 'Giới thiệu',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm4
    },
    {
      route: 'tab5',
      name: 'Chính sách bảo mật',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm5
    },
    {
      route: 'tab6',
      name: 'Điều khoản dịch vụ',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm6
    },
    {
      route: 'tab7',
      name: 'Tích hợp Elsa',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm7
    },
    {
      route: 'tab8',
      name: 'Cấu hình SMS',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm8
    },
    // {
    //   route: 'tab9',
    //   name: 'Cấu hình examus',
    // },
    {
      route: 'tab10',
      name: 'Thông báo bảo trì hệ thống',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm10
    },
    {
      route: 'tab11',
      name: 'Cấu hình private key upload File',
      permission: GENERAL_CONFIGURATIONS_PERMISSION.perm10
    },
  ];
  activeLink = "tab1";

  constructor() { }

  ngOnInit(): void {
    $("#leader-line-defs").remove();
  }
}
