import { Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModulesManagerService} from '@app/_services';

@Component({
  selector: 'app-dialog-list-permission',
  templateUrl: './dialog-list-permission.component.html',
  styleUrls: ['./dialog-list-permission.component.scss']
})
export class DialogListPermissionComponent implements OnInit {
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'description', 'decentralization'];
  dataSource = [];
  keyword = '';
  constructor(
      private fb: MatDialogRef<DialogListPermissionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private moduleManagerModule: ModulesManagerService,
  ) { }

  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(){
    this.moduleManagerModule.getListPermissionByModule(this.data.infoModule.id, this.keyword).subscribe((res: any) => {
      this.dataSource = res.data;
    });
  }

  blurSearch(value){
    this.keyword = value;
    this.loadDataItems();
  }

  keyPressSearch(event,keyword){
    if (event.code == 'Enter' || event.code == "Tab"){
      this.keyword = keyword;
      this.loadDataItems();
    }
  }


}
