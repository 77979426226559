import {Component, OnInit, OnChanges, SimpleChanges, Input, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ModulesManagerService, ShowMessage} from '@app/_services';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {DialogFormModuleComponent} from '@app/_modules/modules-manager/dialogs/dialog-form-module/dialog-form-module.component';
import {DialogAddCustomerComponent} from '@app/_modules/modules-manager/dialogs/dialog-add-customer/dialog-add-customer.component';
import {DialogListPermissionComponent} from '@app/_modules/modules-manager/dialogs/dialog-list-permission/dialog-list-permission.component';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import * as _ from 'lodash';
import {DialogListCustomerComponent} from '@app/_modules/modules-manager/dialogs/dialog-list-customers/dialog-list-customer.component';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ListenFirebaseService } from '@app/_services';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { NgxPermissionsService } from 'ngx-permissions';
import {MODULE_PERMISSION} from '../../../constants';

@Component({
    selector: 'app-tab-core-admin',
    templateUrl: './tab-core-admin.component.html',
    styleUrls: ['./tab-core-admin.component.scss']
})
export class TabCoreAdminComponent implements OnChanges {
    displayedColumns: string[] = ['stt', 'fullname', 'code', 'permission', 'status'];
    dataSource: any;
    pageSize = 15;
    pageIndex = 0;
    totalRecord = 0;
    permissions = [];
    showSearch = false;
    showFilter = false;
    isLoading = false;
    @Input() openDialog;
    @Input() dataSearch;
    @Input() isActive;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        public dialog: MatDialog,
        private modulesManagerService: ModulesManagerService,
        private showMessage: ShowMessage,
        private db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService,
        private permissionsService: NgxPermissionsService
    ) {
    }
    MODULE_PERMISSION = MODULE_PERMISSION;
    typeModule = "module";

    ngOnChanges(): void {
        this.loadDataItems();
        if (this.permissionsService.getPermission(this.MODULE_PERMISSION.perm4) 
        ){
            const index = this.displayedColumns.indexOf('tools');
            if (index == -1) {
                this.displayedColumns.push('tools');
            }
        }
    }

    loadDataItems() {
        if(this.isActive != 3){
            this.isLoading = true;
            this.modulesManagerService.getDataModule(this.pageSize, this.pageIndex+1,this.dataSearch,this.isActive == null ? '' : this.isActive,1).subscribe(
                (res: any) => {
                    this.dataSource = res.data.data;
                    this.totalRecord = res.data.totalItems;
                    this.isLoading = false;
                },
                error => {
                    this.isLoading = false;
                }
            );
        }
    }

    listenFirebase(){
        this.checkFireBase("create");
        this.checkFireBase("update");
        this.checkFireBase("change_status");
    }

    checkFireBase(param){
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
        });
        listenFb.subscribe((res) => {
            if(res === true) {
                this.isLoading = false;
                this.loadDataItems();
            }else{
                this.isLoading = false;
            }
        });
    }

    onChangePaginate(event){
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.loadDataItems();
    }

    openDialogAddModule() {
        const dialogRef = this.dialog.open(DialogFormModuleComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'add',
                infoModule: null,
                permissions: this.permissions,
                groupModule: 'Core admin',
            }
        });

        dialogRef.afterClosed().subscribe((ref: any) => {
            this.listenFirebase();
        });
    }

    changeStatusRole(element: any, infoCoreAdmin: any) {
        const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
            height: 'auto',
            width: '40%',
            data: {
                btnCancelText: 'Hủy',
                btnOkText: 'Xác nhận',
                status: element.checked,
                title: element.checked ? 'Bạn đang thực hiện kích hoạt module' : 'Bạn đang thực hiện bỏ kích hoạt module',
                content: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
                data: {
                    name: infoCoreAdmin.fullname,
                }
            }
        });
        confirmActive.afterClosed().subscribe((res: any) => {
            element.source._checked = res ? element.checked : !element.checked;
            if (res) {
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.changeStatus({
                    moduleId: infoCoreAdmin.id,
                    isActive: element.checked === true ? 1 : 0
                }).subscribe((res:any)=>{
                    if(res.status == 0){
                      this.isLoading = false;
                      this.showMessage.error(res.msg);
                    }
                  },
                  (err)=>{
                    this.isLoading = false;
                });
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }


    openDialogUpdateModule(data: any) {
        const dialogRef = this.dialog.open(DialogFormModuleComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'update',
                infoModule: data,
                permissions: this.permissions,
                groupModule: 'Core admin',
            }
        });

        dialogRef.afterClosed().subscribe((dataInput: any) => {
            if(typeof dataInput !== 'undefined' && dataInput != false){
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.updateModule(dataInput.id, dataInput.data).subscribe((res:any)=>{
                    if(res.status == 0){
                      this.isLoading = false;
                      this.showMessage.error(res.msg);
                    }
                  },
                  (err)=>{
                    this.isLoading = false;
                });
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }

    openDialogListCustomer(infoModule){
        this.dialog.open(DialogListPermissionComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'list-customer',
                infoModule
            }
        });
    }

}
