export class Role {
    id: string;
    roleName: string;
    roleCode: string;
    displayNumber: number;
    status: number;
    description: string;
    numberPermission: number;
    numberUser: number;
    users: any;
    permissions: any;
}
