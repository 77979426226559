import { Injectable } from "@angular/core";
import { Subject, ReplaySubject, Observable,Observer } from "rxjs";
import * as $ from 'jquery';
'use strict';

declare global {
  interface Window {
    hubReady: Observer<boolean>;
    MathJax: {
      typesetPromise: () => void;
      startup: {
        promise: Promise<any>;
      };
    };
  }
}

declare var MathJax:any;

export interface MathService1 {
  ready(): Observable<boolean>;

  render(element: HTMLElement, math: string): void;
}

@Injectable({
  providedIn: "root"
})

export class MathService implements MathService1{
  private readonly notifier: ReplaySubject<boolean>;

  constructor() {
    this.notifier = new ReplaySubject<boolean>();
    window.hubReady = this.notifier; // as said, bind to window object
  }

  ready(): Observable<boolean> {
    return this.notifier;
  }

  render(element: HTMLElement, math:string): void {
    setTimeout(() => {
      element.innerHTML = math;
      setTimeout(() => {
        let length1 = document.getElementsByClassName("re-answer").length;
        if (length1 > 0) {
        for (let i = 0; i < length1; i++) {
          let id_replace_target = document.getElementsByClassName("re-answer")[i]?.getAttribute("id");
          let id_replace_source = "re-"+id_replace_target;
          $("#"+id_replace_source).appendTo("#"+id_replace_target);
        }
        }
        let length2 = document.getElementsByClassName("re-answer-dialog").length;
        if (length2 > 0) {
        for (let i = 0; i < length2; i++) {
          let id_replace_target = document.getElementsByClassName("re-answer-dialog")[i]?.getAttribute("id");
          let id_replace_source = "re-"+id_replace_target;
          $("#"+id_replace_source).appendTo("#"+id_replace_target);
        }
        }

      }, 300)

      MathJax.Hub.Queue(['Typeset', MathJax.Hub, element]);
    }, 150);
  }
}
