import { Component, OnInit, Inject } from '@angular/core';
import {lang} from '../dialog-account-list-applies/lang-account-list-applies';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as $ from 'jquery';
import { GradeManagerService } from '@app/_services/grade.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-add-subject-applies',
  templateUrl: './dialog-add-subject-applies.component.html',
  styleUrls: ['./dialog-add-subject-applies.component.scss']
})
export class DialogAddSubjectAppliesComponent implements OnInit {
  formAddSubjectToGrade:any;
  dataSubjectNotAssign:any;
  pageIndex = 0;
  pageSize = 15;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'is_moet', 'status'];
  lengthSubject:number;
  isChecked:boolean = false;
  countCheck:number = 0;
  arraySubject = [];
  arraySelectAll = [];
  dataTemp:any;
  constructor(
      private dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data,
      private gradeManagerService:GradeManagerService,
      private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    // lay danh sach item chua assign
    this.loadDataItems();
    // khai bao form them
    this.formAddSubjectToGrade = this.fb.group({
      ids: [[], [Validators.required]]
    });
  }

  loadDataItems(keyword = ''){
    // lay danh sach tenant chua assign
    let dataFilter = {
      keyword: keyword,
    }
    this.gradeManagerService
      .getSubjectNotAssign(this.data.idGrade,dataFilter)
      .subscribe((data:any) => {
        this.dataTemp = [];
        this.lengthSubject = data.data.length;
        this.dataSubjectNotAssign = new MatTableDataSource(data.data);
        this.dataTemp = data.data;
      },
      (error => {
      })
    );
  }

  clickSelectAll(event){
    this.isChecked = event.checked;
    if(event.checked){
      this.dataTemp.forEach(element => {
        if(this.arraySubject.indexOf(element.id) == -1){
          this.arraySubject.push(element.id);
        }
      });
      this.countCheck = this.arraySubject.length;
    }else{
      this.arraySubject = [];
      this.countCheck = 0;
    }
  }

  clickSelectItem(event,idSubject){
    if(event.checked){
      this.arraySubject.push(idSubject);
      this.countCheck++;
    }else{
      this.isChecked = false;
      const index = this.arraySubject.indexOf(idSubject);
      if (index > -1) {
        this.arraySubject.splice(index, 1);
      }
      this.countCheck--;
    }
  }

  clickAddSubject(){
    let dataAddSubject = {
      "subjectIds": this.arraySubject,
      "gradeId": this.data.idGrade
    }
    this.dialogRef.close(dataAddSubject);
  }

  closeAddSubject(){
    this.dialogRef.close(false);
  }

  searchListSubject(event,keyword){
    if(event.key == "Enter"){
      this.loadDataItems(keyword);
    }
  }

}
