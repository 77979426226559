<section class="dashboard">
    <div class="dashboard__top">
        <div class="chart">
            <app-chart></app-chart>
        </div>
        <div class="notification">
            <app-notification></app-notification>
        </div>       
    </div>
    <div class="dashboard__bottom">
        <app-new-customer-list></app-new-customer-list>
    </div>
</section>