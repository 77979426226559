import {Component, OnInit, ViewChild, ChangeDetectorRef, Inject, HostListener, ElementRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RoleManagerService} from '@app/_services';
import {User} from '@app/_models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ShowMessage} from "@app/_services";
import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
    selector: 'app-dialog-role-add-user',
    templateUrl: './dialog-role-add-user.component.html',
    styleUrls: ['./dialog-role-add-user.component.scss']
})
export class DialogRoleAddUserComponent implements OnInit {
    dataColumn = ['stt', 'fullName', 'code', 'phone', 'email'];
    dataSource = [];
    checkAll: boolean;
    checkStatus: boolean;
    keyword:any;
    isLoading:boolean = false;
    dataChecked = [];
    flag = true;
    length = 0;
    constructor(
        private eRef: ElementRef,
        private dialogRes: MatDialogRef<DialogRoleAddUserComponent>,
        private roleManagerService: RoleManagerService,
        @Inject(MAT_DIALOG_DATA) private data,
        private fb: FormBuilder,
        private showMessage: ShowMessage) {
    }

    ngOnInit(): void {
        this.loadDataItems();
    }

    loadDataItems(keyWordSearch?: string){
        this.isLoading = true;
        this.roleManagerService.getUserNotBelongRole(this.data.roleId, keyWordSearch).subscribe((res: any) => {
            res.data.forEach(item => {
               item.status = false;
            });
            this.dataSource = res.data;
            this.isLoading = false;
        });
    }

    checkCheckBox(event, itemId) {
        this.dataSource.find(item => item.id === itemId ).status = event.checked;
        this.length = this.dataSource.filter(x => x.status === true).length;
        this.checkAll = this.dataSource.length > 0 && this.dataSource.every(t => t.status);
    }
    checkAllCheckBox(event) {
        this.dataSource.forEach(item => {
            item.status = event.checked;
        });
        this.length = this.dataSource.filter(x => x.status === true).length;
        this.checkAll = event.checked;
    }

    onSubmit() {
        let tenantIds = [];
        this.isLoading = true;
        this.dataSource.filter(x => x.status === true).forEach(item => {
            tenantIds.push(item.id);
        });
        if (tenantIds.length === 0) return;
        this.roleManagerService.assignUser(this.data.roleId, tenantIds).subscribe((res: any) => {
            this.dialogRes.close(res);
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.eRef.nativeElement.contains(event.target)) {
            this.flag = true;
        } else {
            this.flag = false;
        }
    }

    checkText(event, value) {
        setTimeout(() => {
            if (this.flag === true){
                if (event.keyCode === 13 || event.keyCode === 9) {
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isLoading = true;
                        this.loadDataItems(value);
                    }, 1000);
                }
            }
        }, 500);
    }
}
