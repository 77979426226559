<div class="dialog-body">
    <div class="row">
        <p class="col-md-12 text-center title">
            {{this.data.infoUser.fullName | substring: 25}} - Danh sách phân quyền
        </p>
    </div>
    <div class="container-fluid">
        <form style="width: 100%; margin: auto" #element >
            <div class="scroll-custom row">
                <ng-container *ngFor="let item of roles">
                    <div class="col-md-6 row-element-custom" style="margin-bottom: 20px" >
                        <div class="d-flex align-items-center background">
                            <div class="col-md-1 text-right">
                                <mat-checkbox disabled class="example-margin" [checked]="true"></mat-checkbox>
                            </div>
                            <div class="col-md-8">
                                <span class="custom-title-role">{{item?.name}}</span><br>
                                <span class="custom-label-code">{{item?.code}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-12 text-center">
                <button mat-button mat-dialog-close class="btn btn-close">Đóng
                </button>
            </div>
        </form>
    </div>

</div>
