import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {ListenFirebaseService, SubjectService} from '@app/_services';
import {ShowMessage} from '@app/_services/index';
import {Router} from '@angular/router';
import {Observable, Subscriber} from 'rxjs';
import {SUBJECT_PERMISSION} from '@app/_modules/subjects/constant';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-subjects-list-tenant',
  templateUrl: './subjects-list-tenant.component.html',
  styleUrls: ['./subjects-list-tenant.component.scss']
})
export class SubjectsListTenantComponent implements OnInit, OnChanges {
  @Input() itemId;
  @Input() itemName;
  @Input() keyword;
  @Input() dataFilter;
  @Input() onChange;
  dataColumn = ['stt', 'customer', 'code', 'link', 'phone', 'email', 'status'];
  dataSource: any;
  length: number;
  isLoading = false;
  permissionSubject = SUBJECT_PERMISSION;
  constructor(
      private dialog: MatDialog,
      private subjectService: SubjectService,
      private showMessage: ShowMessage,
      private router: Router,
      private listenFirebaseService: ListenFirebaseService,
      private permissionsService: NgxPermissionsService) { }

  ngOnInit(): void {
    if (this.permissionsService.getPermission(this.permissionSubject.perm9)){
      this.dataColumn.push('tool');
    }
    this.getListCustomerBySubject();
    this.checkFireBaseModule();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.onChange){
      // this.isLoading = true;
      // setTimeout(() => {
          this.getListCustomerBySubject(this.keyword, this.dataFilter);
      // }, 2000);
    }
  }

  opendetaiTenant(id, tab) {
    this.router.navigate([`${'tenants-manager/detail'}/${id}`], { queryParams: { active: tab } });
  }


  getListCustomerBySubject(search?: string, filter?: any){
    search = search ? search : '';
    filter = filter ? filter : [];
    this.subjectService.getListCustomerBySubject(this.itemId, search, filter).subscribe((ref: any) => {
      this.dataSource = new MatTableDataSource<any>(ref.data);
      this.length = ref.data.length;
      this.isLoading = false;
    });
  }

  confirmDelete(itemName: string, itemId: string){
    const confirmDelete = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '36%',
      data: {
        title: 'Bạn đang thực hiện xoá khách hàng áp dụng môn học',
        message: 'Bạn có chắc muốn xoá khách hàng này không ?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'Hủy',
        data: {
          name: itemName + ' - ' + this.itemName,
          id: itemId
        },
      }
    });
    confirmDelete.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isLoading = true;
        this.subjectService.removeCustomer(this.itemId, itemId).subscribe((res: any) => {
          if (res.status === 1){
          }
          else if (res.status === 0){
            this.isLoading = false;
            this.showMessage.success(res.msg);
          }
        }, error => {
          this.isLoading = false;
          this.showMessage.success(error);
        });
        setTimeout(() => {
          if (this.isLoading){
            this.getListCustomerBySubject();
          }
        }, 10000);
        this.checkFireBaseModule();
      }
    });
  }

  checkFireBaseModule(){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('delete', 'subject_tenant', subscriber);
    });
    listenFb.subscribe((refg) => {
      if (refg === true) {
          this.getListCustomerBySubject(this.keyword, this.dataFilter);
      }
    });
  }


}
