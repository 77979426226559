import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { ListenFirebaseService, TenantManagerService, ShowMessage} from '@app/_services';
import { Observable, Subscriber } from 'rxjs';
import { REGEX_PASSWORD, TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

function passwordMatchValidator(password: string): ValidatorFn {
  return (control: FormControl) => {
    if (!control || !control.parent) {
      return null;
    }
    return control.parent.get(password).value === control.value ? null : { mismatch: true };
  };
}

@Component({
  selector: 'app-dialog-form-tenant-admin-change-password',
  templateUrl: './dialog-form-tenant-admin-change-password.component.html',
  styleUrls: ['./dialog-form-tenant-admin-change-password.component.scss']
})
export class DialogFormTenantAdminChangePasswordComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private tenantManagerService: TenantManagerService,
    @Inject(MAT_DIALOG_DATA) public data,
    private listenFirebaseService: ListenFirebaseService,
    private showMessage: ShowMessage,
    private dialogRef: MatDialogRef<DialogFormTenantAdminChangePasswordComponent>,


  ) { }
  tenantAdminForm: FormGroup;
  hidePassword = true;
  confirm_hidePassword = true;

  panelOpenState = true;
  submitted = false;
  typeModule="tenant";  
  isLoadingResults = false;
  ngOnInit(): void {
    this.tenantAdminForm = this.fb.group({
      tenantAdminPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(REGEX_PASSWORD)]],
      confirm_tenantAdminPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(REGEX_PASSWORD), passwordMatchValidator('tenantAdminPassword')
    ]],
    })
  }
  onClickSubmit() {
    this.submitted = true;
    let value = this.tenantAdminForm.value;
    if (this.tenantAdminForm.invalid) {
      return;
    }
    console.log(value);
    let param = {
      "tenantId": this.data?.tenant?.id,
      "tenantAdmin": this.data?.tenant?.tenantAdmin,
      "tenantAdminPassword": value.tenantAdminPassword,
      "confirmTenantAdminPassword": value.confirm_tenantAdminPassword,
    }
    this.isLoadingResults = true;
 
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase("change_password", this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.dialogRef.close(res);
      }
      this.isLoadingResults = false;
    });

    this.tenantManagerService.changePasswordTenantAdmin(param).subscribe((ref: any) => {
      if (ref.status == 0) {
        if (this.isLoadingResults) {
          this.isLoadingResults = false;
          this.showMessage.error(ref.msg);
        }
      }
    });
    setTimeout(() => {
      if (this.isLoadingResults) {
        this.isLoadingResults = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
}
