<div fxLayout="column" class="bg-content-detail-global">
    <div fxFlex="50px">
        <div class="title-content-page-tab">Danh sách khách hàng quản lý</div>
    </div>
    <div fxFlex class="content-data">
        <table mat-table [dataSource]="dataSource" matSort class="table-el">
            <ng-container matColumnDef="stt" >
                <th mat-header-cell *matHeaderCellDef  style="width: 5%;">STT</th>
                <td mat-cell *matCellDef="let item;let i = index">{{ dataTenant.size*(dataTenant.page - 1) + i + 1 }}</td>
            </ng-container>
            <ng-container matColumnDef="fullname" >
                <th mat-header-cell *matHeaderCellDef  style="width: 12%;">Tên Tenant</th>
                <td mat-cell *matCellDef="let item">
                    <a class="link_table" target="_blank" href="tenants-manager/detail/{{item?.id}}?active=tab1">
                    {{ item.tenantName | substring: 20}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Mã</th>
                <td mat-cell *matCellDef="let item">{{ item.tenantCode }}</td>
            </ng-container>
            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef  style="width: 14%;">Tên miền</th>
                <td mat-cell *matCellDef="let item"><a class="link_table" href="{{item.tenantDomain}}" target="_blank">{{ item.tenantDomain | substring: 20}}</a></td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef  style="width: 12%;">Số điện thoại</th>
                <td mat-cell *matCellDef="let item">{{ item.tenantPhone }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef  style="width: 15%;">Email</th>
                <td mat-cell *matCellDef="let item">{{ item.tenantEmail | substring: 25}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef  style="width: 12%;">Trạng thái</th>
                <td mat-cell *matCellDef="let item" [ngSwitch]="item.tenantStatus">
                    <div fxLayoutAlign="center center" [style]="{background: getStatusCustomer(item.tenantStatus).color}" class="status-customer">
                        {{getStatusCustomer(item.tenantStatus).name}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action_table" style="width: 8%;">Công cụ</th>
                <td mat-cell *matCellDef="let item" class="action_table_td">
                    <img src="assets/images/icons/icon-22.svg" style="cursor: pointer" (click)="confirmDelete(item)">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
    </div>
</div>

<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
