<div fxLayout="column" class="bg-content-global">
    <div fxFlex="60px">
        <div fxLayout="row result-custom" fxLayoutAlign="space-between end">
            <div>
                <p class="title-page-global title-custom">Import đơn vị hành chính</p>
                <p>File: {{ fileName }}</p>
            </div>
            <!-- <button class="btn btn-download" style="position: relative; bottom: 30px"><img src="assets/images/svg/download.svg" alt=""> Tải xuống dữ liệu lỗi</button> -->
        </div>
    </div>

    <div fxFlex class="custom-table">
        <table mat-table [dataSource]="dataSource" class="table-el">

            <!-- <ng-container matColumnDef="error">
                <th mat-header-cell *matHeaderCellDef class="text-danger" style="width: 10%"> * Lỗi</th>
                <td class="border-first-5 text-danger" mat-cell *matCellDef="let element"> {{element.error}} </td>
            </ng-container> -->

            <!-- Name Column -->
            <ng-container matColumnDef="name1" style="width: 20%">
                <th mat-header-cell *matHeaderCellDef> Tỉnh/Thành phố</th>
                <td mat-cell *matCellDef="let element"> {{element.cityName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="code1">
                <th mat-header-cell *matHeaderCellDef style="width: 15%"> Mã Tỉnh/Thành phố</th>
                <td mat-cell *matCellDef="let element"> {{element.cityCode}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name2">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Quận/Huyện</th>
                <td mat-cell *matCellDef="let element"> {{element.districtName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="code2">
                <th mat-header-cell *matHeaderCellDef style="width: 15%">Mã Quận/Huyện</th>
                <td mat-cell *matCellDef="let element"> {{element.districtCode}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="name3">
                <th mat-header-cell *matHeaderCellDef style="width: 15%">Phường/Xã</th>
                <td mat-cell *matCellDef="let element"> {{element.wardName}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="code3">
                <th mat-header-cell *matHeaderCellDef style="width: 15%">Mã Phường/Xã</th>
                <td mat-cell *matCellDef="let element"> {{element.wardCode}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <br>
    <p class="text-center">
        <button class="btn btn-cancel" (click)="cancel()">Hủy</button> &nbsp;&nbsp;
        <button class="btn btn-add" (click)="addDataImport()">Lưu</button>
    </p>
</div>