import { Component, OnInit, Inject } from '@angular/core';
import {AccountManagerService} from '@app/_services/index';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as $ from 'jquery';

@Component({
  selector: 'app-dialog-list-roles',
  templateUrl: './dialog-list-roles.component.html',
  styleUrls: ['./dialog-list-roles.component.scss']
})
export class DialogListRolesComponent implements OnInit {
  checkStatus = true;
  roles: any;
  keyWord = '';
  constructor(
      private accountManagerService: AccountManagerService,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.getAllRoles();
  }

  getAllRoles(){
    this.accountManagerService.getRoleByUser(1000, 0, this.data.infoUser.id, this.keyWord).subscribe((res: any) => {
      this.roles = res.data.data;
    });
  }
}
