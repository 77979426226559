import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';

import { DialogFormEducationLv1Component } from '../../dialog/dialog-form-education-lv1/dialog-form-education-lv1.component';
import { DialogFormEducationLv2Component } from '../../dialog/dialog-form-education-lv2/dialog-form-education-lv2.component';
import { DialogFormEducationLv3Component } from '../../dialog/dialog-form-education-lv3/dialog-form-education-lv3.component';
import { IndustryUnitService } from '@app/_services/industry-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ListenFirebaseService} from '@app/_services';
import {ADMIN_MOET_UNIT_PERMISSION} from '../../constants';

// import leader line
import 'leader-line';
import { forEachChild } from 'typescript';
import { DialogMoveEducationComponent } from '../../dialog/move-education/dialog-move-education.component';
import { DialogMoveEducationLv2Component } from '../../dialog/move-education-lv2/dialog-move-education-lv2.component';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

// declare let LeaderLine: any;
declare var LeaderLine: any;

interface FoodNode {
  name: string;
  code?: string;
  parent_code?: string;
  children?: FoodNode[];
}
interface FoodNode1 {
  name: string;
  parent_code_lv1?: string;
  parent_code?: string;
  children?: FoodNode[];
}

@Component({
  selector: 'app-detail-education-lv1',
  templateUrl: './detail-education-lv1.component.html',
  styleUrls: ['./detail-education-lv1.component.scss']
})
export class DetailEducationLv1Component implements OnInit{
  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  treeControl2 = new NestedTreeControl<FoodNode1>(node2 => node2.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  dataSource2 = new MatTreeNestedDataSource<FoodNode1>();

  constructor(
    public dialog: MatDialog,
    private industryUnitService:IndustryUnitService,
    private activatedRoute: ActivatedRoute,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService,
    @Inject(DOCUMENT) private document
  ){}
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
  hasChild2 = (_: number, node2: FoodNode1) => !!node2.children && node2.children.length > 0;
  code_active = '';
  code_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  show_level_2 = false;
  show_level_3 = false;
  showSearch: boolean = false;
  dataSearch: string = '';
  idEducation1:any;
  dataDetailEducation1:any;
  isLoading:boolean = false;

  resultSearch:any;
  checkResultSearch:boolean = false;
  linkDetailEducation:string;
  // khai báo phần leaderline
  lineLv2 = [];
  lineLv3 = [];
  checkOpenPGD:boolean = false;
  checkOpenTHPT:boolean = false;
  checkOpenTHCS:boolean = false;
  checkOpenTH:boolean = false;
  checkOpenMG:boolean = false;
  countPGD:number = 0;
  countTHPT:number = 0;
  countTHCS:number = 0;
  countTH:number = 0;
  countMG:number = 0;
  dataPGD = [];
  dataTHPT = [];
  dataTHCS = [];
  dataTH = [];
  dataMG = [];
  checkClickLv1:boolean = false;
  checkClickLv2:boolean = false;
  parent_lv2:string='';
  parent_lv3:string='';
  nameLevel2 = ''; 

  typeModule = 'moet_unit';
  ADMIN_MOET_UNIT_PERMISSION=ADMIN_MOET_UNIT_PERMISSION;

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataDeatilEducation();
        this.isLoading = false;
      }
      this.isLoading = false;
    });
  }

  ngOnInit(){
    this.getDataDeatilEducation();
  }

  getDataDeatilEducation(){
    this.isLoading = true;
    //get param
    this.idEducation1 = this.activatedRoute.snapshot.params.id;
    this.industryUnitService.getDetailEducation(this.idEducation1).subscribe(
      (res:any)=>{
        this.isLoading = false;
        this.dataDetailEducation1 = res.data;
        this.dataDetailEducation1.status = Number(this.dataDetailEducation1.status);
        this.openLv2(res.data.code);
      },
      (err)=>{
        this.isLoading = false;
      }
    );
  }

  openLv2(code){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = this.lineLv3 = [];
    this.code_active = code;
    this.checkClickLv1 = true;
    this.checkClickLv2 = false;
    this.checkOpenPGD = false;
    this.checkOpenTHPT = false;
    this.parent_lv2 = code;
    
    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res:any)=>{
        let count_phong_gd:number = 0;
        let count_thpt:number = 0;
        let code_parent:string;
        this.dataPGD = [];
        this.dataTHPT = [];
        (res.data).forEach((e:any)=>{
          code_parent = e.soGD;
          if(e.unit == 2){
            count_phong_gd++;
            this.dataPGD.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else{
            count_thpt++;
            this.dataTHPT.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }
        });
        this.countPGD = count_phong_gd;
        this.countTHPT = count_thpt;
      },
      (err)=>{
      }
    )
  }

  getEducationLv2(code:any){
    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res:any)=>{
        let count_phong_gd:number = 0;
        let count_thpt:number = 0;
        let code_parent:string;
        let childs_phong_gd = [];
        let childs_thpt = [];
        (res.data).forEach((e:any)=>{
          code_parent = e.soGD;
          if(e.unit == 2){
            count_phong_gd++;
            childs_phong_gd.push(e);
          }else{
            count_thpt++;
            childs_thpt.push(e);
          }
        });
        let resultEducationLv2 = [
          {
            name: `${count_phong_gd} Phòng giáo dục`,
            parent_code: `${code_parent}`,
            children: childs_phong_gd
          }, 
          {
            name: `${count_thpt} Trường THPT`,
            parent_code: `${code_parent}`,
            children: childs_thpt
          }
        ];

        this.dataSource.data = resultEducationLv2;
        
        this.check_child_lv_1 = this.checkDataLv1(resultEducationLv2,code);
        this.code_active = code;
        this.closeTree(code);
        this.close_lv_2 = true;
      },
      (err)=>{
        console.log(err);
      }
    )
  }

  // click level 1
  activeLv1(code:string){
    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res:any)=>{
        let count_phong_gd:number = 0;
        let count_thpt:number = 0;
        let code_parent:string;
        let childs_phong_gd = [];
        let childs_thpt = [];
        (res.data).forEach((e:any)=>{
          code_parent = e.soGD;
          if(e.unit == 2){
            count_phong_gd++;
            childs_phong_gd.push({id:e.id,name: e.name,code:e.code});
          }else{
            count_thpt++;
            childs_thpt.push({id:e.id,name: e.name,code:e.code});
          }
        });
        let resultEducationLv2 = [
          {
            name: `${count_phong_gd} Phòng giáo dục`,
            parent_code: `${code_parent}`,
            children: childs_phong_gd
          }, 
          {
            name: `${count_thpt} Trường THPT`,
            parent_code: `${code_parent}`,
            children: childs_thpt
          }
        ];
        this.dataSource.data = resultEducationLv2;
        
        this.check_child_lv_1 = this.checkDataLv1(resultEducationLv2,code);
        this.code_active = code;
        this.closeTree(code);
        this.close_lv_2 = true;
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  // click level 2
  activeLv2(code2:string){
    this.industryUnitService.getListEducationLv3(code2).subscribe(
      (res:any)=>{
        let count_thcs:number = 0;
        let count_tieu_hoc:number = 0;
        let count_mau_giao:number = 0;
        let code_parent:string;
        let code_parent_lv1:string;
        let childs_thcs = [];
        let childs_tieu_hoc = [];
        let childs_mau_giao = [];
        (res.data).forEach((e:any)=>{
          code_parent = e.phongGD;
          code_parent_lv1 = e.soGD;
          if(e.unit == 4){
            count_thcs++;
            childs_thcs.push({id:e.id,name: e.name});
          }else if(e.unit == 5){
            count_tieu_hoc++;
            childs_tieu_hoc.push({id:e.id,name: e.name});
          }else{
            count_mau_giao++;
            childs_mau_giao.push({id:e.id,name: e.name})
          }
        });
        let resultEducationLv3 = [
          {
            name: `${count_thcs} Trường THCS`,
            parent_code_lv1:`${code_parent_lv1}`,
            parent_code: `${code_parent}`,
            children: childs_thcs
          }, 
          {
            name: `${count_tieu_hoc} Trường tiểu học`,
            parent_code_lv1:`${code_parent_lv1}`,
            parent_code: `${code_parent}`,
            children: childs_tieu_hoc
          },
          {
            name: `${count_mau_giao} Trường mầm non`,
            parent_code_lv1:`${code_parent_lv1}`,
            parent_code: `${code_parent}`,
            children: childs_mau_giao
          },
        ];
        this.dataSource2.data = resultEducationLv3;
        this.check_child_lv_2 = this.checkDataLv2(resultEducationLv3,code2);
        this.code_active_lv_2 = code2;
        this.closeTree2(code2);
        this.close_lv_3 = true;
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  // click open close level 2
  closeTree(code:string){
    if(this.code_active == code){
      this.close_lv_2 = !this.close_lv_2;
    }else{
      this.activeLv1(code);
    }
  }
  // click open close level 3
  closeTree2(code:string,code_lv1?:string){
    if(this.code_active_lv_2 == code){
      this.close_lv_3 = !this.close_lv_3;
    }else{
      this.activeLv2(code);
      this.closeTree(code_lv1);
    }
  }

  checkDataLv1(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.parent_code == code){
        check = true;
      }
    });
    return check;
  }

  checkDataLv2(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.parent_code == code){
        check = true;
      }
    });
    return check;
  }

  toggleClass(value){
    this.dataSearch = '';
    this.showSearch = !this.showSearch;
    this.checkResultSearch = false;
  }

  clickEditEducationLv1(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv1Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickEditEducationLv2(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickEditEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickDeleteEducation(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa khách hàng',
        message:'Xác nhận xóa?',
        btnOkText:'Xóa',
        btnCancelText:'Hủy',
        data: {
          id:data.id,
          name:data.name
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.industryUnitService.deleteEducation(data.id).subscribe(
          (res)=>{
            this.checkFireBase('delete');
            if (this.isLoading) this.activeLv2(this.code_active_lv_2);
          },
          (err)=>{
            this.getDataDeatilEducation();
            this.showMessage.success("Xóa nghành dọc không thành công");
          }
        )
      }
    });
  }

  onSearchChange(searchValue: string): void {  
    this.dataSearch = searchValue;
  }

  clickAddEucationLv2(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"add",
        infoParent:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('create');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  searchFocusOut(txtSearch:string='',unit: any){
    let dataInput = {
      keyword: txtSearch ? txtSearch : null,
      unit: Number(unit),
      soId: this.dataDetailEducation1.code,
      phongId: null
    }
    this.industryUnitService.filterEducation(dataInput).subscribe(
      (res:any)=>{
        this.resultSearch = res.data;
        this.checkResultSearch = true;
        switch (Number(unit)) {
          case 1:
            this.linkDetailEducation = '/industry-unit/education-lv1/detail/';
            break;
          case 2:
            this.linkDetailEducation = '/industry-unit/education-lv2/detail/';
            break;
          case 3:
            this.linkDetailEducation = '/industry-unit/education-lv2/detail/';
            break;
          default:
            this.linkDetailEducation = '/industry-unit/education-lv3/detail/';
            break;
        }
      },
      (err)=>{
        console.log(err);
      }
    )
  }

  schowPGD(parent_code){
    this.checkOpenPGD = !this.checkOpenPGD;
  }

  schowTHPT(parent_code){
    this.checkOpenTHPT = !this.checkOpenTHPT;
  }

  removeLineLeader(){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = this.lineLv3 = [];
  }

  clickMoveEducationLv2(data:any){
    const dialogRef = this.dialog.open(DialogMoveEducationComponent, {
      height:'auto',
      width:'30%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.checkFireBase('update_vertical_unit');
          if (this.isLoading) this.activeLv1(data.parent_code_lv1);
          // this.getDataEducation1();
          //
          // this.showMessage.success('Chuyển đổi đơn vị nghành dọc thành công');
        }
      }
    )

  }

  schowTHCS(parent_code){
    this.checkOpenTHCS = !this.checkOpenTHCS;
    // if(this.lineLv3){
    //   this.lineLv3.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ];
    //   if(this.lineLv3){
    //     this.lineLv3?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
  }

  schowTH(parent_code){
    this.checkOpenTH = !this.checkOpenTH;
    // if(this.lineLv3){
    //   this.lineLv3.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ];
    //   if(this.lineLv3){
    //     this.lineLv3?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
  }

  schowMG(parent_code){
    this.checkOpenMG = !this.checkOpenMG;
    // if(this.lineLv3){
    //   this.lineLv3.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ];
    //   if(this.lineLv3){
    //     this.lineLv3?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
  }


  openLv3(code2,name2){
    this.nameLevel2 = `[${name2}]`;
    // if(this.lineLv3){
    //   this.lineLv3?.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(code2),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(code2),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(code2),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ];
    //   if(this.lineLv3){
    //     this.lineLv3?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
    document.getElementById('content__education-lv1').addEventListener('scroll', ()=>{
      if(this.lineLv3){
        this.lineLv3?.forEach(e=>{
          e?.position();
        })
      }
    });
    this.industryUnitService.getListEducationLv3(code2).subscribe(
      (res:any)=>{
        this.code_active_lv_2 = code2;
        let count_thcs:number = 0;
        let count_tieu_hoc:number = 0;
        let count_mau_giao:number = 0;
        let code_parent:string;
        let code_parent_lv1:string;
        this.checkClickLv2 = true;
        this.dataTHCS = [];
        this.dataTH = [];
        this.dataMG = [];
        this.parent_lv3 = code2;
        (res.data).forEach((e:any)=>{
          code_parent = e.phongGD;
          code_parent_lv1 = e.soGD;
          if(e.unit == 4){
            count_thcs++;
            this.dataTHCS.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else if(e.unit == 5){
            count_tieu_hoc++;
            this.dataTH.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else{
            count_mau_giao++;
            this.dataMG.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            })
          }
        });
        this.countTHCS = count_thcs;
        this.countTH = count_tieu_hoc;
        this.countMG = count_mau_giao;
      },
      (err)=>{
      }
    )
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  clickMoveEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogMoveEducationLv2Component, {
      height:'auto',
      width:'80%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.checkFireBase('update_vertical_unit');
          if (this.isLoading)  {
            this.activeLv1(data.parent_code_lv1);
            this.activeLv2(data.parent_code);
          }
        }
      }
    )
  }


}

