import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IndustryUnitService } from '@app/_services/industry-unit.service';
import { REGEX_CODE } from '@app/_shared/utils/constant';
import { ShowMessage } from '@app/_services/show-message.service';

@Component({
  selector: 'app-dialog-form-education-lv2',
  templateUrl: './dialog-form-education-lv2.component.html',
  styleUrls: ['./dialog-form-education-lv2.component.scss']
})
export class DialogFormEducationLv2Component implements OnInit {
  public dataEducation: FormGroup;
  urlImage: SafeResourceUrl;
  selectedFile: File
  checkOption:number = 2;

  public id:any;
  public fullname:number;
  public code:number;
  public order:number;
  public parent:number;
  public status:number;
  public logo:string;
  isLoading = false;
  hideButtonAdd:boolean;

  panelOpenState = true;
  checkShowForm:boolean = false;
  constructor(
    private sanitizer: DomSanitizer,private fb: FormBuilder,
    private dialogref: MatDialogRef<DialogFormEducationLv2Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    private industryUnitService:IndustryUnitService,
    private showMessage:ShowMessage
  ) { }
  
  ngOnInit(): void {
    this.getDataForm();
  }

  getDataForm(){
    this.isLoading = true;
    let prefix = 'PGD';
    this.checkOption == 2 ? prefix = 'PGD' : prefix = 'THPT';
    this.industryUnitService.generateCode(prefix).subscribe(
      (res:any)=>{
        this.urlImage = this.data.education ? this.data.education.logo : '/assets/images/png/avatar.png';
        this.data?.status == "update" ? this.hideButtonAdd = false : this.hideButtonAdd = true;
        this.dataEducation = this.fb.group({
          id: [this.data?.education?.id ? this.data.education.id : this.id],
          unit: [this.data?.education?.unit ? this.data.education.unit : this.checkOption],
          fullname: [this.data?.education?.name ? this.data.education.name : this.fullname,[Validators.required]],
          code: [this.data?.education?.code ? this.data.education.code : res.data,[Validators.required,Validators.pattern(REGEX_CODE)]],
          order:  [this.data?.education?.sort ? Number(this.data.education.sort) : 0,[Validators.required]],
          parent:  [this.data?.education?.nameSoGD ? this.data.education.nameSoGD : this.data.infoParent.name,[Validators.required]],
          logo:  [this.data?.education?.logo ? this.data.education.logo : this.logo],
          status: [this.data?.education?.status ? Number(this.data.education.status) : 0]
        });
        this.checkShowForm = true;
        this.isLoading = false;
      },
      (err)=>{

      }
    );
    setTimeout(() => {
      if(this.isLoading){
        this.isLoading = !this.isLoading;
      }
    }, 5000);
  }

  onClickSubmit(value:any):void{
    this.isLoading = true;
    if(this.data.status == "add"){
      let dataInput = {
        logo: value.logo,
        fullName: value.fullname,
        sort: Number(value.order),
        code: value.code,
        status: Number(value.status),
        unit: Number(value.unit),
        soId: this.data.infoParent.code,
        phongId: null
      };
      this.industryUnitService.storeEducation(dataInput).subscribe(
        (res:any)=>{
          if(res.status == 0){
            this.showMessage.error(res.msg);
            this.isLoading = false;
          }else{
            this.dialogref.close(true);
            this.isLoading = false;
          }
        },
        (err)=>{
          this.dialogref.close(false);
          this.isLoading = false;
        }
      );
    }else{
      let dataInput = {
        id: value.id,
        logo: "string",
        fullName: value.fullname,
        sort: Number(value.order),
        code: value.code,
        status: Number(value.status),
        unit: Number(value.unit),
        soId: value.parent,
        phongId: null
      };
      this.industryUnitService.updateEducation(dataInput).subscribe(
        (res:any)=>{
          if(res.status == 0){
            this.showMessage.error(res.msg);
            this.isLoading = false;
          }else{
            this.dialogref.close(true);
            this.isLoading = false;
          }
        },
        (err)=>{
          this.dialogref.close(false);
          this.isLoading = false;
        }
      );
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  onFileChange(event) {

    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result)
      }
    }

  }

  uploadImage() {
    let inputUploadFile = document.getElementById('inputUploadFile') as HTMLElement;
    inputUploadFile.click();
  }

  changeOptionAdd(value){
    this.checkOption = Number(value);
    this.getDataForm();
  }

  // validate
  education_validation_messages = {
    'fullname': [
      { type: 'required', message: 'Chưa nhập tên' }
    ],
    'code': [
      { type: 'required', message: 'Chưa nhập mã' },
      { type: 'pattern', message: 'Mã phải từ 5 đến 15 ký tự' }
    ],
    'order': [
      { type: 'required', message: 'chưa nhập số thứ tự' }
    ],
    'parent': [
      { type: 'required', message: 'chưa nhập đơn vị cha' }
    ]
  }

}
