import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from './user.service';
import {retry} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class AccountManagerService {

  constructor(private http: HttpClient) { }

  generateRandomKey(prefix: string){
    let data = {prefix, lenghtOfAlphabet: 0, lenghtOfUpercase: 0, lenghtOfNumber: 0, lenghtOfSpecial: 0}
    return this.http.post(`${environment.authApiUrl}/api/Common/generate-random-key`, data);
  }

  getAllDataUser(size, page, search, filterRoles, filterStatus){
    page = Number(page + 1);
    return this.http.post(`${environment.coreApiUrl}/api/users/get-all`, {
      pageSize: size,
      pageIndex: page,
      keySearch: search,
      roles: filterRoles,
      userStatuses: filterStatus
    }
    );
  }

  changeStatusUser(id: string, status: number){
    status = Number(status);
    return this.http.post(`${environment.coreApiUrl}/api/users/change-status`, {
      userId: id,
      userStatus: status
    });
  }

  deleteUser(userId: string){
    return this.http.post(`${environment.coreApiUrl}/api/users/delete`, {
      id: userId
    });
  }

  getIdByUser(userId: string){
    return this.http.get(`${environment.coreApiUrl}/api/users/${userId}`);
  }

  createUser(data: any){
    return this.http.post(`${environment.coreApiUrl}/api/users`, data);
  }

  updateUser(data: any, userId: string){
    return this.http.put(`${environment.coreApiUrl}/api/users/${userId}`, data);
  }

  resetPassword(userId: string, password: string, rePassword: string){
    return this.http.post(`${environment.coreApiUrl}/api/users/reset-password`, {
      id: userId,
      newPassword: password,
      reNewPassword: rePassword
    });
  }

  getRoleByUser(pageSize, pageIndex, userId: string, KeySearch: string){
    pageIndex = pageIndex + 1;
    return this.http.get(`${environment.coreApiUrl}/api/users/${userId}/get-roles-user?PageSize=${pageSize}&PageIndex=${pageIndex}&KeySearch=${KeySearch}`);
  }

  filterRoleByUser(pageSize, pageIndex, userId: string, keyWord: string){
    pageIndex = pageIndex + 1;
    return this.http.get(`${environment.coreApiUrl}/api/users/${userId}/get-roles-user?KeySearch=${keyWord}&PageSize=${pageSize}&PageIndex=${pageIndex}`);
  }

  getRoleNotAssignByUserId(userId: string, KeySearch){
    return this.http.get(`${environment.coreApiUrl}/api/users/${userId}/get-roles?KeySearch=${KeySearch}`);
  }
  getCustomerByUser(pageSize: number, pageIndex: number, userId: string, keyWord: string){
    pageIndex = pageIndex + 1;
    return this.http.get(`${environment.coreApiUrl}/api/users/${userId}/get-tenants-user?KeySearch=${keyWord}&PageSize=${pageSize}&PageIndex=${pageIndex}`);
  }

  infoUser(userId: string){
    return this.http.get(`${environment.coreApiUrl}/api/users/${userId}`);
  }

  getTenantNotAssign(data: {
    id: string,
    KeySearch: string,
    PageSize: number,
    PageIndex: number,
  }){
    return this.http.get(`${environment.coreApiUrl}/api/users/${data.id}/get-tenants?KeySearch=${data.KeySearch}&PageSize=${data.PageSize}&PageIndex=${data.PageIndex}`);
  }

  assignTenantsToUser(id, data){
    return this.http.post(`${environment.coreApiUrl}/api/users/assign-tenants`, {
      userId: id,
      tenantIds: data
    });
  }

  removeTenantFromUser(id, tenantId){
    return this.http.post(`${environment.coreApiUrl}/api/users/remove-tenant`, {
      userId: id,
      tenantId
    });
  }

  assignRolesToUser(id, data){
    return this.http.post(`${environment.coreApiUrl}/api/users/assign-roles`, {
      userId: id,
      roleIds: data
    });
  }

  removeRoleFromUser(userId, roleId){
    return this.http.post(`${environment.coreApiUrl}/api/users/remove-role`, {userId, roleId});
  }

  getHistoryByUserId(data: {UserId: string, KeyWord: string, StartDateTime: number, EndDateTime: number, PageSize: number, PageIndex: number}){
    return this.http.get(`${environment.coreApiUrl}/api/users/user-histories?UserId=${data.UserId}&KeyWord=${data.KeyWord}&StartDateTime=${data.StartDateTime}&EndDateTime=${data.EndDateTime}&PageSize=${data.PageSize}&PageIndex=${data.PageIndex+1}`);
  }

  getCodeRandom(code: string){
    return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`, {
      prefix: code,
      lenghtOfAlphabet: 0,
      lenghtOfUpercase: 0,
      lenghtOfNumber: 6,
      lenghtOfSpecial: 0
    });
  }

  getAllRole(){
    return this.http.get(`${environment.coreApiUrl}/api/roles?PageSize=99999&PageIndex=1`);
  }

  importCustomer(userId: string, file: any){
    return this.http.post(`${environment.coreApiUrl}/api/users/${userId}/import-base64`, JSON.stringify(file));
  }

  createUserImport(id: string, data){
    return this.http.post(`${environment.coreApiUrl}/api/users/create-users`, {
      userId: id,
      createUsers: data
    });
  }
}
