<div class="toolbar-header">
  <mat-icon *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">arrow_back</mat-icon>
  <span style="margin-left: 8px">{{path || 'Files'}}</span>
  <span class="spacer"></span>
  <button  (click)="uploadImage()" title="Tải lên" class="toolbar-button file">Tải lên</button>
  <input type="file" class="avatar-file" name="avatar" style="display: none;" id="inputUploadFile"
    (change)="onFileChange($event)">
  
    <button (click)="openNewFolderDialog()" title="Tạo thư mục con" class="toolbar-button folder">Tạo thư mục con</button>
</div>

<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-between stretch">
  <div class="content" fxFlex fxLayout="row">
    <mat-grid-list cols="4" rowHeight="100px" fxFlex>
      <mat-grid-tile *ngFor="let element of fileElements" class=file-or-folder>
        <div *ngIf="element.isFolder">
          <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}" #menuTrigger="matMenuTrigger"></span>
          <div fxLayout="column" fxLayoutAlign="space-between center" (click)="navigate(element)" (contextmenu)="openMenu($event,element, menuTrigger)">
            <!-- <mat-ic  on color="primary" class="file-or-folder-icon pointer" *ngIf="element.isFolder">folder</mat-icon> -->
            <img style="width: 45px" src="assets/images/svg/folder.svg" class="folder-icon pointer" *ngIf="element.isFolder"/>
            <!-- <mat-icon color="primary" class="file-or-folder-icon pointer" *ngIf="!element.isFolder">insert_drive_file</mat-icon> -->
            <span>{{element.name}}</span>
          </div>
        </div>
        <div *ngIf="!element.isFolder">
          <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}" #menuTrigger="matMenuTrigger"></span>
          <li (click)="navigate(element)" (contextmenu)="openMenu($event,element, menuTrigger)" id="c156" class="ckf-file-item ui-li-has-thumb" style="width:150px;height:150px;" data-icon="false"
          role="presentation">
          <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
          #menuTrigger="matMenuTrigger"></span>
      
          <a href="javascript:void(0)" class="ckf-files-inner ui-btn" tabindex="-1" draggable="true"
            role="listitem" aria-label="a.png" aria-describedby="ckf-file-desc-c156"
            data-ckf-drag-preview="ckf-drag-prev-c156" data-ckf-view="c156"><img id="ckf-drag-prev-c156" class="ui-li-thumb"
              alt=""
              src={{element.url}}
              draggable="true" data-ckf-drag-preview="ckf-drag-prev-c156">
            <div class="ckf-file-desc ui-bar-a" draggable="true">
              <h2 title="a.png" dir="auto">{{element.name}}</h2>
            </div>
          </a></li>        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <!-- <button mat-menu-item [matMenuTriggerFor]="moveToMenu" [matMenuTriggerData]="{self: element}">
      <mat-icon>open_with</mat-icon>
      <span>Move To</span>
    </button> -->
    <button mat-menu-item (click)="openRenameDialog(element)">
      <mat-icon>edit</mat-icon>
      <span>Rename</span>
    </button>
    <button mat-menu-item (click)="deleteElement(element)">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #moveToMenu="matMenu">
  <ng-template matMenuContent let-self="self">
    <ng-container *ngFor="let element of fileElements">
      <button *ngIf="element.isFolder && element.id !== self.id" mat-menu-item
        (click)="moveElement(self, element)">{{element.name}}</button>
    </ng-container>
  </ng-template>
</mat-menu>

<!-- <ul data-role="listview" tabindex="30" role="list"
  class="ckf-files-view ckf-files-view-borders ui-body-inherit ckf-files-thumbs ui-listview" aria-label="Khung tập tin"
  style="min-height: 458px;">
  <li id="c156" *ngFor="let element of fileElements" class="ckf-file-item ui-li-has-thumb" style="width:150px;height:150px;" data-icon="false"
    role="presentation">
    <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
    #menuTrigger="matMenuTrigger"></span>

    <a href="javascript:void(0)" class="ckf-files-inner ui-btn" tabindex="-1" draggable="true"
      role="listitem" aria-label="a.png" aria-describedby="ckf-file-desc-c156"
      data-ckf-drag-preview="ckf-drag-prev-c156" data-ckf-view="c156"><img id="ckf-drag-prev-c156" class="ui-li-thumb"
        alt=""
        src={{element.url}}
        draggable="true" data-ckf-drag-preview="ckf-drag-prev-c156">
      <div class="ckf-file-desc ui-bar-a" draggable="true">
        <h2 title="a.png" dir="auto">{{element.name}}</h2>
        <p draggable="true" id="ckf-file-desc-c156" data-ckf-drag-preview="ckf-drag-prev-c156">30/1/2021 8:59
          SA<br>304.0 KB</p>
      </div>
    </a></li>
</ul> -->