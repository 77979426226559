import { Component, OnInit} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogFormTenantManagerComponent } from '../../dialog/dialog-form-tenant-manager/dialog-form-tenant-manager.component';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';
import { DialogChangeStatusComponent } from '../../dialog/dialog-change-status/dialog-change-status.component';
import * as _ from 'lodash';
import { TenantManagerService, ListenFirebaseService, ShowMessage, GeneralService } from '@app/_services';
import { Tenant } from '@app/_models';
import { Router } from '@angular/router';
import * as Editor from '../../../../../assets/js/ckeditor/build/ckeditor';
import * as $ from 'jquery';
import { statusUser } from '@app/_services/constants';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { NgxPermissionsService } from 'ngx-permissions';
import {TENANT_PERMISSION} from '../../constants';

@Component({
  selector: 'app-tenant-manager-list',
  templateUrl: './tenant-manager-list.component.html',
  styleUrls: ['./tenant-manager-list.component.scss']
})
export class TenantManagerListComponent implements OnInit {
  data_tenant: any;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'tenantUrl', 'phone', 'email', 'status'];
  public Editor = Editor;
  TENANT_PERMISSION = TENANT_PERMISSION;
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  public order = [];
  public isPagination = false;
  public isTable = false;
  showSearch: boolean = true;
  showFilter: boolean = false;
  keyword: string = '';
  typeModule = 'tenant';
  isLoadingResults=false;
  constructor(
    public dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private router: Router,
    private listenFirebaseService: ListenFirebaseService,
    private showMessage: ShowMessage,
    private permissionsService: NgxPermissionsService,
    private general: GeneralService
  ) { }
  readonly statusUser = statusUser;
  searchStatus = [];

  ngOnInit(): void {
    this.getListTenant(this.keyword, this.searchStatus);
    $("#leader-line-defs").remove();

    if (this.permissionsService.getPermission(this.TENANT_PERMISSION.perm4) 
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm5)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm6)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm7)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm8)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm9)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm10)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm11)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm12)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm13)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm14)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm15)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm16)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm17)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm18)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm19)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm20)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm21)
    || this.permissionsService.getPermission(this.TENANT_PERMISSION.perm22)
    ){
      this.displayedColumns.push('action');
    }
  }

  getListTenant(keyword, status) {
    this.tenantManagerService.getDataTenant(this.pageSize, this.pageIndex, keyword, status).subscribe((res: any) => {
      res.data.data.map(x => {
        x.statusIndex = this.statusUser.findIndex(userEle => userEle.id === x.status);
      });

      this.data_tenant = res.data.data;
      this.totalRecord = res.data.totalItems;
    });
  }

  onChangePaginate(event: any) {
    if (typeof event === "object") {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      this.getListTenant(this.keyword, this.searchStatus);
    }
  }

  openDialogCreateTenant() {
    // this.general.getCodeRandom('TE').subscribe((codeRef: any) => {
      const dialogRef = this.dialog.open(DialogFormTenantManagerComponent, {
        width: '80%',
        height: 'auto',
        panelClass: 'custom-modalbox',
        data: {
          action: 'create',
          randomCode: ''
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.getListTenant(this.keyword, this.searchStatus);
        }
      });
  }

  openDialogUpdateTenant(tenant) {
    this.tenantManagerService.getDataTenantById(tenant.id).subscribe((ref: any) => {
          if (ref.status === 1) {
            const dialogRef = this.dialog.open(DialogFormTenantManagerComponent, {
              width: '80%',
              height: 'auto',
              panelClass: 'custom-modalbox',
              data: {
                action: "update",
                tenant: ref.data,
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log(result);
              if(result) {
                this.getListTenant(this.keyword, this.searchStatus);
              }
            });
          }
        },
        (error: any) => {
          this.showMessage.error(error.message);
        });
  }

  openDialogDelete(tenant: any) {
    tenant.logo = null;
    this.tenantManagerService.getDataTenantById(tenant.id).subscribe((res: any) => {
      if(res.status === 1) {
        tenant.logo = res.data.logo
      }
      const dialogRef = this.dialog.open(DialogDeleteComponent, {
        height: 'auto',
        width: '40%',
        data: {
          title: 'Bạn đang thực hiện xóa khách hàng',
          message: 'Bạn có chắc chắn muốn xóa dữ liệu này không?',
          btnOkText: 'Xoá',
          btnCancelText: 'Huỷ',
          data: {
            id: tenant.id,
            name: tenant.fullname
          },
          html: `<img src="${(tenant.logo == null || tenant.logo == "") ? 'assets/images/png/avatar.png' : tenant.logo}" alt="Ảnh đại diện">`
        }
      });
      dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm === true) {
          this.isLoadingResults = true;
          this.tenantManagerService.deleteTenant(tenant.id).subscribe((res: any) => {
            if(res.status === 1) {
              let listenFb = new Observable((subscriber: Subscriber<any>) => {
                this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
              });
              listenFb.subscribe((res) => {
                if (res === true) {
                  this.pageIndex = 0;
                  this.getListTenant(this.keyword, this.searchStatus);
                }
              });
            } else {
              this.showMessage.error(res.msg);
            }
            this.isLoadingResults = false;
          });
        }
      });
    });

  }

  toggleClass(value) {
    this.keyword = value;
    this.showSearch = !this.showSearch;
  }

  showFilterClick() {
    this.showFilter = !this.showFilter;
    if (this.showFilter === false) {
      this.searchStatus = [];
      this.getListTenant(this.keyword, this.searchStatus);
    }
  }

  filterSubmit() {
    this.pageIndex = 0;
    this.getListTenant(this.keyword, this.searchStatus);
  }

  itemOptionClick(event, status) {
    if (event.srcElement.classList.contains("active")) {
      event.srcElement.classList.remove("active");
      this.searchStatus = this.searchStatus.filter(word => word !== status);

    } else {
      this.searchStatus.push(status);
      event.srcElement.classList.add("active");
    }
  }

  dialogOpenChangeStatus(infoTenant, event) {
    
    let $prevSelection = $("#mat-select-0:selected");

    // $('#id_language_code').on('change', function() {
    //   $('#language_change_modal').modal('show'); // user confirm language change.
    // });
  
    // $('#yesButton').on('click', function() {
    //   $prevSelection = $("#id_language_code option:selected");
    //   console.log("Yes was clicked. Allowing selection change and saving new selection");
    // });
  
    // $('#cancelButton').on('click', function() {
    //   console.log("No was clicked. Resetting select to previous value");
    //   $prevSelection.prop("selected", true);
    // });


    const keyStatus = event.value;
    let newStatusObj = this.statusUser.find(userEle => userEle.id === parseInt(keyStatus));
    let index = this.statusUser.findIndex(userEle => userEle.id === parseInt(keyStatus));

    this.tenantManagerService.getDataTenantById(infoTenant.id).subscribe((ref: any) => {
          if (ref.status === 1) {
            const dialogRef = this.dialog.open(DialogChangeStatusComponent, {
              width: '40%',
              height: 'auto',
              data:
                  {
                    user: ref.data,
                    newStatus: newStatusObj
                  }
            });

            dialogRef.afterClosed().subscribe((confirm: any) => {
              if (confirm === true) {
                // $(element).css('background-color', this.statusUser[index].color);
                this.tenantManagerService.changeStatusTenant(infoTenant.id, parseInt(keyStatus)).subscribe((res: any) => {
                  let listenFb = new Observable((subscriber: Subscriber<any>) => {
                    this.listenFirebaseService.checkFireBase("change_status", this.typeModule, subscriber);
                  });
                  listenFb.subscribe((res) => {
                    if (res === true) {
                      this.pageIndex = 0;
                      this.getListTenant(this.keyword, this.searchStatus);
                    }
                  });
                });
              }
              else {
                // event.target.options.selectedIndex = infoTenant.userCode;
                $prevSelection.prop("selected", true);
              }
            });
          }
        },
        (error: any) => {
          this.showMessage.error(error.message);
        });
  }

  opendetaiTenant(id, tab) {
    this.router.navigate([`${'tenants-manager/detail'}/${id}`], { queryParams: { active: tab } });
  }

  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;

    if (code === 13 || code === 9) {
      this.pageIndex = 0;
      this.getListTenant(this.keyword, this.searchStatus);
    }
  }


  onBlur(event) {
    if (this.showSearch === true) {
      event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
      if (event.type === 'blur') {
        this.pageIndex = 0;
        this.getListTenant(this.keyword, this.searchStatus);
      }
    }
  }

  goToUrl(domain) {
    let url = 'http://' + domain;
    window.open(url, '_blank');
  }
}

