import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-delete-sofware',
  templateUrl: './dialog-delete-sofware.component.html',
  styleUrls: ['./dialog-delete-sofware.component.scss']
})
export class DialogDeleteSofwareComponent implements OnInit {
  data_delete:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<DialogDeleteSofwareComponent>) { }

  ngOnInit(): void {
    this.data_delete = this.data;
  }
  confirm(){
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
