import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShowMessage } from '@app/_services';
import { GradeManagerService } from '@app/_services/grade.service';

@Component({
  selector: 'app-grade-detail-table-subject',
  templateUrl: './table-subject.component.html',
  styleUrls: ['./table-subject.component.scss']
})
export class GradeDetailTableSubjectComponent implements OnInit {
  @Input() dataSubject:any;
  @Input() idGradeSubject:any;
  @Output() outCheckRemoveSubject = new EventEmitter<any>();
  pageIndex = 0;
  pageSize = 15;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'is_moet', 'status','action'];

  constructor(
      public dialog: MatDialog,
      private gradeManagerService:GradeManagerService
  ) { }

  ngOnInit(): void {
  }

  clickDeleteSubject(item,idGrade){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
        data: {
          title: 'Bạn đang thực hiện gỡ môn học ra khỏi khối',
          message: 'Bạn có chắc muốn gỡ môn học này không?',
          btnOkText: 'XÁC NHẬN',
          btnCancelText: 'HỦY',
          data: {
            name: item.fullname,
          },
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.outCheckRemoveSubject.emit({
          "subjectId": item.id,
          "gradeId": idGrade
        });
      }else{
        this.outCheckRemoveSubject.emit(false);
      }
    });
  }
}
