<h2 mat-dialog-title class="title-page-global text-center">Chỉnh sửa thông tin cá nhân</h2>
<form [formGroup]="infoUser" (ngSubmit)="onSubmit(infoUser.value, infoUser.valid)">
    <mat-dialog-content class="mat-typography">
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="img-module" (click)="uploadImage()">
                <img [src]="dataBase64Image ? dataBase64Image : 'https://increasify.com.au/wp-content/uploads/2016/08/default-image.png'" class="avatar">
                <div class="box-image"><label><mat-icon >photo library</mat-icon><span>Thay đổi ảnh </span></label></div>
                <input type="file" class="avatar-file" name="avatar" hidden id="create-post-avatar" (change)="onFileChange($event)" accept="image/png, image/jpeg">
            </div>
        </div>
        <mat-expansion-panel [expanded]="true"  class="panel-content-global mat-elevation-z">
            <mat-expansion-panel-header class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">
                <mat-panel-title class="panel-title">Thông tin cơ bản</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tên người dùng</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="fullName" type="text" class="form-control input-field-global" placeholder="Nhập tên người dùng">
                        <div *ngIf="this.infoUser.get('fullName').invalid && (this.infoUser.get('fullName').dirty || this.infoUser.get('fullName').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('fullName').errors.required || this.infoUser.get('fullName').errors.trimError || isValidate">
                                    Tên người dùng là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('fullName').errors.minlength">
                                    Tên người dùng cần nhập 2-250 ký tự
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('fullName').errors.maxlength">
                                    Tên người dùng cần nhập 2-250 ký tự
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('fullName').errors.pattern">
                                    Tên người dùng chỉ chấp nhận các ký tự đặc biệt: _ - ( ) [ ]
                                </p>
                            </mat-error>
<!--                            <mat-error>-->
<!--                                <p class="error-message" *ngIf="">-->
<!--                                    Tên người dùng là bắt buộc.-->
<!--                                </p>-->
<!--                            </mat-error>-->
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global" style="opacity: 50%">
                    <label class="col-md-3 offset-1 lb-global">Mã tài khoản</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="userCode" type="text" class="form-control input-field-global" placeholder="Nhập mã tài khoản" readOnly style="background-color: #F2F3F8">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Ngày sinh</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="birthday" readonly type="text" class="form-control input-field-global" [max]="maxDate" placeholder="Nhập mã tenant" [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker" style="position: relative; float: right; bottom: 40px;"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Giới tính</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="gender">
                            <option value="1" selected>Nam</option>
                            <option value="2">Nữ</option>
                        </select>
                        <div *ngIf="this.infoUser.get('gender').invalid && (this.infoUser.get('gender').dirty || this.infoUser.get('gender').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('gender').errors.required">
                                    Bạn chọn giới tính
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Email</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="email" type="email" class="form-control input-field-global" placeholder="Nhập email">
                        <div *ngIf="this.infoUser.get('phone').invalid && this.infoUser.get('email').invalid && (this.infoUser.get('email').dirty || this.infoUser.get('email').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('email').errors.required">
                                    Bạn chưa nhập email
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('email').errors.email">
                                    Email chưa đúng định dạng
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global" style="opacity: 50%">
                    <label class="col-md-3 offset-1 lb-global">Tài khoản</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="userName" type="text" readOnly class="form-control input-field-global" placeholder="Nhập mã tài khoản">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Số điện thoại</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="phone" type="text" class="form-control input-field-global" placeholder="Nhập số điện thoại">
                        <div *ngIf="this.infoUser.get('email').invalid && this.infoUser.get('phone').invalid && (this.infoUser.get('phone').dirty || this.infoUser.get('phone').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('phone').errors.required">
                                    Bạn chưa nhập số điện thoại
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('phone').errors.pattern">
                                    Không đúng số điện thoại
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tỉnh/thành phố</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select #valueCity class="form-control input-field-global" formControlName="cityId" (change)="changeDistrictByCity(valueCity.value, 1)">
                            <option value="">Chọn tỉnh/thành phố </option>
                            <option *ngFor="let c of city" value="{{c.id}}" >{{c.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Quận/huyện</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select #valueDistrict class="form-control input-field-global" formControlName="district" (change)="changeWardByDistrict(valueDistrict.value, 1)">
                            <option value="" selected>Chọn quận/huyện </option>
                            <option *ngFor="let d of district" value="{{d.id}}" >{{d.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Phường/xã</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="ward">
                            <option value="" selected>Chọn phường/xã</option>
                            <option *ngFor="let w of ward" value="{{w.id}}" >{{w.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12 row row-global"  fxLayout="row">
                    <label class="lb-global" fxFlex="12%">Địa chỉ</label>
                    <div fxFlex class="form-group pr-0">
                        <textarea class="form-control input-field-global" formControlName="address" style="height: 120px"></textarea>
                        <div *ngIf="this.infoUser.get('address').invalid && (this.infoUser.get('address').dirty || this.infoUser.get('address').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.infoUser.get('address').errors.maxlength">
                                    Địa chỉ không được nhập quá 500 kí tự.
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close class="btn-cancel-profile">HỦY</button>
        <button [disabled]="isLoading" mat-button type="submit" class="btn btn-submit-profile"> <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span> LƯU</button>
    </mat-dialog-actions>
</form>
