import { Component, OnInit } from '@angular/core';
import {lang} from '../../../general-configuration.lang';
import {ListenFirebaseService, ShowMessage} from '@app/_services';
import {GeneralConfigService} from '@app/_services';
import {FormBuilder, Validators} from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
@Component({
  selector: 'app-security-information',
  templateUrl: './security-information.component.html',
  styleUrls: ['./security-information.component.scss']
})
export class SecurityInformationComponent implements OnInit {
  lang = lang;
  panelOpenState = true;
  infoForm;
  isLoading = false;
  isCheckAll = false;
  isValidate = false;
  dataChecked = [
    {id: 1, name: 'lowercase', checked: false},
    {id: 2, name: 'capital', checked: false},
    {id: 3, name: 'number', checked: false},
    {id: 4, name: 'special letters', checked: false},
  ];
  constructor(
      private showMessage: ShowMessage,
      private generalConfigService: GeneralConfigService,
      private fb: FormBuilder,
      private db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService
  ) { }

  typeModule = 'admin_config';

  checkFireBase(){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('update', this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.isLoading = false;
        this.getInfoSecurityConfig();
      } else{
        this.isLoading = false;
     }
    });
  }

  ngOnInit(): void {
    this.getInfoSecurityConfig();
    this.infoForm  = this.fb.group({
      max_login_failed: [0, [Validators.required, Validators.pattern(/^[0-9]{0,12}$/)]],
      password_min_length: [6, [Validators.min(6),Validators.pattern(/^[0-9]{0,12}$/)]],
      password_max_length: [50, [Validators.max(50),Validators.pattern(/^[0-9]{0,12}$/)]],
      resend_code_max_time: [0, Validators.pattern(/^[0-9]{0,12}$/)]
    });  
  }

  checkAll(){
    this.isCheckAll = !this.isCheckAll;
    this.dataChecked.forEach(item => {
      item.checked = this.isCheckAll;
    });
  }

  testChecked(){
    let check = this.dataChecked.map(e => {
      return e.checked;
    }).indexOf(false);

    if (check === -1){
      this.isCheckAll = true;
    }else{
      this.isCheckAll = false;
    }
  }

  itemCheck(key){
    this.dataChecked[key].checked = !this.dataChecked[key].checked;
    this.testChecked();
  }

  getInfoSecurityConfig(){
    this.isLoading = true;
    this.generalConfigService.infoSecurityConfig().subscribe((res: any) => {
      console.log(res.data);
      this.infoForm.patchValue({
        max_login_failed: res.data.max_login_failed !== '' ? res.data.max_login_failed : 0,
        password_min_length: res.data.password_min_length !== '' ? res.data.password_min_length : 0,
        password_max_length: res.data.password_max_length !== '' ? res.data.password_max_length : 0,
        resend_code_max_time: res.data.resend_code_max_time !== '' ? res.data.resend_code_max_time : 0
      });
      if (res.data.password_format !== null){
        res.data.password_format.forEach(item => {
          let key = this.dataChecked.map(e => {
            return e.id;
          }).indexOf(item);
          this.dataChecked[key].checked = true;
        });
        this.testChecked();
      }
      this.isLoading = false
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit(data){
  
    this.isLoading = true;
    if (this.infoForm.invalid){
      this.isValidate = true;
      this.isLoading = false;

      return;
    }
    if(data.password_min_length !== 0 && data.password_max_length !== 0 && Number(data.password_min_length) >= Number(data.password_max_length)) {
      this.isValidate = true;
      this.isLoading = false;
      return;
    }
    let passwordFormat = [];
    this.dataChecked.forEach(item => {
      if (item.checked === true){
        passwordFormat.push(item.id);
      }
    });

    let uploadData = {
      max_login_failed: `${data.max_login_failed}`,
      password_min_length: `${data.password_min_length}`,
      password_max_length: `${data.password_max_length}`,
      password_format: passwordFormat,
      resend_code_max_time: `${data.resend_code_max_time}`
    };
    console.log(uploadData);
    //debugger
    this.checkFireBase();
    this.generalConfigService.updateSecurityConfig(uploadData).subscribe((res: any) => {
      this.isLoading = false;
      if (res.status === 0) {
        this.showMessage.error(res.msg); 
        this.isLoading = false;
      }
    }, (err: any) => {
      this.isLoading = false;
    });
    setTimeout(() => {
      if(this.isLoading){
          this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }

  cancel(){
    this.getInfoSecurityConfig();
  }

}
