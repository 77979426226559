import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TenantsManagerRoutingModule } from './tenants-manager-routing.module';
import { TenantManagerListComponent } from './components/tenant-manager-list/tenant-manager-list.component';
import { TenantManagerDetailComponent } from './components/tenant-manager-detail/tenant-manager-detail.component';
import { TabTenantContentDetailComponent } from './components/tabs/tab-tenant-content-detail/tab-tenant-content-detail.component';
import { TabTenantConfigComponent } from './components/tabs/tab-tenant-config/tab-tenant-config.component';
import { TabTenantRoleListComponent } from './components/tabs/tab-tenant-role-list/tab-tenant-role-list.component';
import { TabTenantModuleListComponent } from './components/tabs/tab-tenant-module-list/tab-tenant-module-list.component';
import { DialogFormTenantManagerComponent } from "./dialog/dialog-form-tenant-manager/dialog-form-tenant-manager.component";
import { DialogFormTenantConfigComponent } from "./dialog/dialog-form-tenant-config/dialog-form-tenant-config.component";
import { DialogFormTenantRoleComponent } from "./dialog/dialog-form-tenant-role/dialog-form-tenant-role.component";
import { DialogFormTenantModuleComponent } from "./dialog/dialog-form-tenant-module/dialog-form-tenant-module.component";
import { SharedModule } from '../../_shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabTenantSchoolYearListComponent } from './components/tabs/tab-tenant-school-year-list/tab-tenant-school-year-list.component';
import { TabTenantGradeListComponent } from './components/tabs/tab-tenant-grade-list/tab-tenant-grade-list.component';
import { TabTenantSubjectListComponent } from './components/tabs/tab-tenant-subject-list/tab-tenant-subject-list.component';
import { DialogTenantEditSubjectComponent } from './dialog/dialog-tenant-edit-subject/dialog-tenant-edit-subject.component';
import { DialogTenantEditGradeComponent } from './dialog/dialog-tenant-edit-grade/dialog-tenant-edit-grade.component';
import { DialogTenantEditSchoolYearComponent } from './dialog/dialog-tenant-edit-school-year/dialog-tenant-edit-school-year.component';
import { DialogTenantEditRoleComponent } from './dialog/dialog-tenant-edit-role/dialog-tenant-edit-role.component';
import { DialogTenantEditModuleComponent } from './dialog/dialog-tenant-edit-module/dialog-tenant-edit-module.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DialogChangeStatusComponent } from './dialog/dialog-change-status/dialog-change-status.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TabTenantAccountAdminComponent } from './components/tabs/tab-tenant-account-admin/tab-tenant-account-admin.component';
import { DialogFormTenantAdminChangePasswordComponent } from './dialog/dialog-form-tenant-admin-change-password/dialog-form-tenant-admin-change-password.component';
import { TabTenantMldlaiConfigComponent } from './components/tabs/tab-tenant-mldlai-config/tab-tenant-mldlai-config.component';

@NgModule({
  declarations: [
    TenantManagerListComponent,
    TenantManagerDetailComponent,
    DialogFormTenantManagerComponent,
    DialogFormTenantConfigComponent,
    DialogFormTenantRoleComponent,
    DialogFormTenantModuleComponent,
    TabTenantContentDetailComponent,
    TabTenantConfigComponent,
    TabTenantRoleListComponent,
    TabTenantModuleListComponent,
    TabTenantSchoolYearListComponent,
    TabTenantGradeListComponent,
    TabTenantSubjectListComponent,
    DialogTenantEditSubjectComponent,
    DialogTenantEditGradeComponent,
    DialogTenantEditSchoolYearComponent,
    DialogTenantEditRoleComponent,
    DialogTenantEditModuleComponent,
    DialogChangeStatusComponent,
    TabTenantAccountAdminComponent,
    DialogFormTenantAdminChangePasswordComponent,
    TabTenantMldlaiConfigComponent
  ],
  imports: [
    CommonModule,
    TenantsManagerRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgxPermissionsModule.forChild()
  ]
})
export class TenantsManagerModule { }
