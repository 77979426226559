import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PermissionManagerService} from '@app/_services';

@Component({
  selector: 'app-dialog-delete-permission',
  templateUrl: './dialog-delete-permission.component.html',
  styleUrls: ['./dialog-delete-permission.component.scss']
})
export class DialogDeletePermissionComponent implements OnInit {

  isLoading = false;

  constructor(private dialogRef: MatDialogRef<DialogDeletePermissionComponent>,
              @Inject(MAT_DIALOG_DATA) public data, public permissionsService: PermissionManagerService) { }

  ngOnInit(): void {
  }
  closeDialog(){
    this.dialogRef.close();
  }
  deletePermission(){
      this.isLoading = true;
      this.permissionsService.deletePermission(this.data.id).subscribe((res: any) => {
       this.dialogRef.close(res);
     });

  }
}
