import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { City, District, Ward } from "../_models";
@Injectable({
  providedIn: 'root'
})
export class TenantManagerService {
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) { }
  // getDataTenant(page: number, getTotalPage: boolean, limit: number){
  //     page = page + 1;
  //     return this.http.get(`${environment.coreApiUrl}/api/core/v1/tenants?page=${page}&limit=${limit}&getTotalPage=${getTotalPage}&paginate=false`);
  // }

  getCities() {
    return [
      new City("3fa85f64-5717-4562-b3fc-2c963f66afa1", 'Hải Phòng'),
      new City("3fa85f64-5717-4562-b3fc-2c963f66afa2", 'Hà Nội'),
    ];
  }

  getDistrictes() {
    return [
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa3", "3fa85f64-5717-4562-b3fc-2c963f66afa1", 'Hồng Bàng'),
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa4", "3fa85f64-5717-4562-b3fc-2c963f66afa1", 'Lê Chân'),
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa5", "3fa85f64-5717-4562-b3fc-2c963f66afa1", 'Ngô Quyền'),
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa6", "3fa85f64-5717-4562-b3fc-2c963f66afa1", 'Kiến An'),
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa7", "3fa85f64-5717-4562-b3fc-2c963f66afa1", 'Cầu Giấy'),
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa8", "3fa85f64-5717-4562-b3fc-2c963f66afa2", 'Nam Từ Liêm'),
      new District("3fa85f64-5717-4562-b3fc-2c963f66afa9", "3fa85f64-5717-4562-b3fc-2c963f66afa2", 'Bắc Từ Liêm')
    ];
  }

  getWardes() {
    return [
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb1", "3fa85f64-5717-4562-b3fc-2c963f66afa3", 'Sở Dầu'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb2", "3fa85f64-5717-4562-b3fc-2c963f66afa3", 'Hạ Lý'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb3", "3fa85f64-5717-4562-b3fc-2c963f66afa5", 'Phan Bội Châu'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb4", "3fa85f64-5717-4562-b3fc-2c963f66afa6", 'Hawaii'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb5", "3fa85f64-5717-4562-b3fc-2c963f66afa7", 'Sao Paulo'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb6", "3fa85f64-5717-4562-b3fc-2c963f66afa8", 'Rio de Janeiro'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb7", "3fa85f64-5717-4562-b3fc-2c963f66afa8", 'Phường A ,Nam Từ Liêm'),
      new Ward("3fa85f64-5717-4562-b3fc-2c963f66afb8", "3fa85f64-5717-4562-b3fc-2c963f66afa9", 'Phường B ,Bắc Từ Liêm')
    ];
  }

  getDataTenant(pageSize, pageIndex, keyword?: string, status?: number[]) {
    pageIndex = pageIndex + 1 ;
    if (status?.length === 0) {
      return this.http.post(`${environment.authApiUrl}/api/tenant/index`, { "pageSize": pageSize, "pageIndex": pageIndex, "keyword": keyword, "status": null });
    } else {
      return this.http.post(`${environment.authApiUrl}/api/tenant/index`, { "pageSize": pageSize, "pageIndex": pageIndex, "keyword": keyword, "status": status });
    }
  }

  deleteTenant(tenantId: string) {
    const data = {
      id: tenantId
    }
    return this.http.post(`${environment.coreApiUrl}/api/tenant/delete`, data);
  }
  getDataTenantById(tenantId: string) {
    return this.http.get(`${environment.coreApiUrl}/api/tenant/detail/${tenantId}`);
  }

  createTenant(data: any) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/store`, data);
  }

  updateTenant(data: any, tenantId: string) {
    data.id = tenantId
    return this.http.post(`${environment.coreApiUrl}/api/tenant/update`, data);
  }

  changeStatusTenant(id: string, status: number) {
    status = Number(status);
    return this.http.post(`${environment.coreApiUrl}/api/tenant/change-status`, {
      id: id,
      status: status
    });
  }

  getSubjectByTenant(size, index, search: string, filter, tenantId) {
    index = index + 1;
    return this.http.post(`${environment.coreApiUrl}/api/tenant/subjects/${tenantId}`, {
      pageSize: size,
      pageIndex: index,
      keyword: search,
      status: 0,
      isMoetSubject: 0
    });
  }

  getAllSubjects(search: string, tenantId, status = 0, isMoetSubject = 0) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/all-subjects/${tenantId}`, {
      keyword: search,
      status: status,
      isMoetSubject: isMoetSubject
    });
  }

  updateAllSubject(tenantId: string, subjectIds: string[]) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/update-subjects`, {
      "tenantId": tenantId,
      "subjectIds": subjectIds
    });
  }

  getAllGrades(search: string, tenantId, status = 0, group = 0) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/all-grades/${tenantId}`, {
      keyword: search,
      status: status,
      group: group
    });
  }

  updateAllGrade(tenantId: string, gradeIds: string[]) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/update-grades`, {
      "tenantId": tenantId,
      "gradeIds": gradeIds
    });
  }

  // /api/tenant/school-years/{id}

  getAllSchoolYears(search: string, tenantId) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/all-school-years/${tenantId}`, {
      keyword: search
    });
  }

  // /api/tenant/update-school-years
  updateAllSchoolYear(tenantId: string, schoolYearIds: string[]) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/update-school-years`, {
      "tenantId": tenantId,
      "schoolYearIds": schoolYearIds
    });
  }


  // /api/tenant/all-modules/{id}
  getAllModules(search: string, tenantId) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/all-modules/${tenantId}`, {
      keyword: search
    });
  }
  // /api/tenant/update-modules
  updateAllModule(tenantId: string, moduleIds: string[]) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/update-modules`, {
      "tenantId": tenantId,
      "moduleIds": moduleIds
    });
  }

  // /api/tenant/all-subsystems/{id}
  getAllSubsystems(search: string, tenantId) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/all-subsystems/${tenantId}`, {
      keyword: search
    });
  }
  // /api/tenant/update-modules
  updateAllSubsystem(tenantId: string, subSystemIds: string[]) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/update-subsystems`, {
      "tenantId": tenantId,
      "subSystemIds": subSystemIds
    });
  }



  deleteSubjectTenant(tenantId: string, subjectId: string) {
    const data = {
      tenantId: tenantId,
      subjectId: subjectId
    }
    return this.http.post(`${environment.coreApiUrl}/api/tenant/remove-subject`, data);
  }

  deleteGradeTenant(tenantId: string, gradeId: string) {
    const data = {
      tenantId: tenantId,
      gradeId: gradeId
    }
    return this.http.post(`${environment.coreApiUrl}/api/tenant/remove-grade`, data);
  }

  deleteSchoolYearTenant(tenantId: string, schoolYearId: string) {
    const data = {
      tenantId: tenantId,
      schoolYearId: schoolYearId
    }
    return this.http.post(`${environment.coreApiUrl}/api/tenant/remove-school-year`, data);
  }

  deleteModuleTenant(tenantId: string, moduleId: string) {
    const data = {
      tenantId: tenantId,
      moduleId: moduleId
    };
    return this.http.post(`${environment.coreApiUrl}/api/tenant/remove-module`, data);
  }

  deleteSubsystemTenant(tenantId: string, subSystemId: string) {
    const data = {
      tenantId: tenantId,
      subSystemId: subSystemId
    };
    return this.http.post(`${environment.coreApiUrl}/api/tenant/remove-subsystem`, data);
  }

  getAllListGrade(size, index, search: string, filter, tenantId) {
    index = index + 1;
    return this.http.post(`${environment.coreApiUrl}/api/tenant/grades/${tenantId}`, {
      pageSize: size,
      pageIndex: index,
      keyword: search,
      status: 0,
      group: 0
    });
  }

  getAllListSchoolYear(search: string, filter, tenantId) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/school-years/${tenantId}`, {
      keyword: search
    });
  }

  getAllListSubsystem(size, index, search: string, filter, tenantId) {
    index = index + 1;
    return this.http.post(`${environment.coreApiUrl}/api/tenant/subsystems/${tenantId}`, {
      pageSize: size,
      pageIndex: index,
      keyword: search
    });
  }

  getAllListModule(size, index, search: string, filter, tenantId) {
    index = index + 1;
    return this.http.post(`${environment.coreApiUrl}/api/tenant/modules/${tenantId}`, {
      pageSize: size,
      pageIndex: index,
      keyword: search
    });
  }

  getBussinessStaffs() {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/business-staffs`, {});
  }
  
  getDeploymentStaffs() {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/deployment-staffs`, {});
  }

  changePasswordTenantAdmin(data) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/change-admin-password`, data);
  }

  checkExistDomain(domain) {
    return this.http.post(`${environment.coreApiUrl}/api/tenant/check-domain-exist`, domain);
  }
}
