import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ARR_TYPE_CONFIG_NOTI_SYSTEM } from '@app/_modules/general-configurations/constants';
import { DialogFormConfigNotiComponent } from '@app/_modules/general-configurations/dialogs/dialog-form-cofig-noti/dialog-form-cofig-noti.component';
import { GeneralConfigService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { MESSAGE_ERROR_CALL_API, TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { Subscriber } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-tab-config-noti-system-maintenance',
  templateUrl: './tab-config-noti-system-maintenance.component.html',
  styleUrls: ['./tab-config-noti-system-maintenance.component.scss']
})
export class TabConfigNotiSystemMaintenanceComponent implements OnInit {

  isLoading: boolean = false;
  listNoti: Array<any> = [];
  arrType: Array<object> = ARR_TYPE_CONFIG_NOTI_SYSTEM;

  constructor(
    public dialog: MatDialog,
    private generalConfigService: GeneralConfigService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,
  ) { }

  ngOnInit(): void {
    this.getListNoti();
  }

  getListNoti() {
    this.isLoading = true;
    this.generalConfigService.getListConfigNoti().subscribe((res: any) => {
      if (res.status == 0) {
        this.isLoading = false;
        this.showMessage.error(res.msg);
      } else {
        this.listNoti = res.data;
        this.listNoti.forEach(item => {
          let index = this.arrType.findIndex((el: any) => el.value == item.type);
          if (index != -1) {
            item['color'] = this.arrType[index]['color'];
            item['borderColor'] = this.arrType[index]['borderColor'];
            item['background'] = this.arrType[index]['background'];
            item['nameType'] = this.arrType[index]['type'];
            item['isDisplayActive'] = item.isActive == 1 ? true : false;
          }
        });
        this.isLoading = false;
      }
    }, (err: any) => {
      this.isLoading = false;
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  add() {
    const dialogRef = this.dialog.open(DialogFormConfigNotiComponent, {
      height: 'auto',
      width: '60%',
      autoFocus: false,
      data: {
      }
    })
    dialogRef.afterClosed().subscribe((ref: any) => {
      if (ref && ref != undefined) {
        this.getListNoti();
      }
    });
  }

  delete(valueDel) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '35%',
      data: {
        title: 'Xóa cấu hình thông báo',
        message: 'Bạn có chắc muốn xóa cấu hình thông báo này không ?',
        btnOkText: 'TIẾP TỤC',
        btnCancelText: 'HỦY',
      }
    })
    dialogRef.afterClosed().subscribe((ref: any) => {
      if (ref && ref != undefined) {
        let dataInput = {
          id: valueDel.id
        }
        this.listenFireBase('delete_system_alert', null);
        this.setTimOutFireBase();
        this.generalConfigService.deleteConfigNoti(dataInput).subscribe((res: any) => {
          if (res.status == 0) {
            this.showMessage.error(res.msg);
          }
        }, (err: any) => {
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      }
    });
  }

  changeActive(value) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '35%',
      data: {
        title: 'Thay đổi trạng thái cấu hình thông báo',
        message: value.isActive == 0 || !value.isActive ? 'Bạn có chắc muốn kích hoạt cấu hình thông báo này không ?' : 'Bạn có chắc muốn hủy kích hoạt cấu hình thông báo này không ?',
        btnOkText: 'TIẾP TỤC',
        btnCancelText: 'HỦY',
      }
    })
    dialogRef.afterClosed().subscribe((ref: any) => {
      if (ref && ref != undefined) {
        let dataInput = {
          id: value.id,
          isActive: Number(!value.isActive),
        }

        this.listenFireBase('update_system_alert', value);
        this.setTimOutFireBase();
        this.generalConfigService.changeActiveConfigNoti(dataInput).subscribe((res: any) => {
          if (res.status == 0) {
            this.showMessage.error(res.msg);
          }
        }, (err: any) => {
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      } else {
        let index = this.listNoti.findIndex(item => item.id == value.id);
        if (index != -1) {
          this.listNoti[index].isDisplayActive = !!this.listNoti[index].isActive;
        }
      }
    });
  }

  listenFireBase(action, value) {
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(action, 'admin_config', subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref === true) {
        this.isLoading = false;
        this.getListNoti();
        // if (action == 'delete_system_alert') {
        //   this.getListNoti();
        // } else {
        //   let index = this.listNoti.findIndex(item => item.id == value.id);
        //   if (index != -1) {
        //     this.listNoti[index].isActive = Number(this.listNoti[index].isDisplayActive);
        //     this.listNoti.forEach((item, i) => {
        //       if(index != i) {
        //         item.isActive = 0;
        //         item.isDisplayActive = item.isActive;
        //       }
        //     })
        //   }
        // }
      } else {
        this.isLoading = false;
      }
    });
  }

  setTimOutFireBase() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
}
