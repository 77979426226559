<div class="dialog-body">
    <div class="row">
        <div class="col-md-12 text-center title">
            Năm học {{data.infoSchoolYear.fullname}} - Khách hàng
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                        <span class="font">
                                    Tổng số: {{dataSource.length}} khách hàng
                               </span>
                </div>

                <div class="col-md-12 d-flex justify-content-center">
                    <input (keydown)="keyPressSearch($event)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp"
                           placeholder="Tên, mã khách hàng, tên miền, SĐT hoặc email">
                </div>
                <img style="position: relative; left: 30px; bottom: 30px; cursor: pointer" src="assets/images/svg/search.svg" alt="">
            </div>
        </div>
    </div>

    <div class="container-fluid custom-scroll">
        <table mat-table [dataSource]="dataSource" class="table-el">

            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 5%"> STT</th>
                <td class="border-first-5" mat-cell *matCellDef="let element, let i = index"> {{i + 1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 12%">Khách hàng</th>
                <td mat-cell *matCellDef="let element">
                    <a href="tenants-manager/detail/{{element.id}}?active=tab1" target="_blank">
                        {{element.fullname | substring: 20}}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Mã</th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef style="width: 12%"> Tên miền</th>
                <td mat-cell *matCellDef="let element">
                    <a href="{{element.tenantUrl}}" target="_blank">
                        {{element.tenantUrl | substring: 20}}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> SĐT</th>
                <td mat-cell *matCellDef="let element">
                    {{element.hotline}}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 12%">Email</th>
                <td mat-cell *matCellDef="let element">
                    {{element.email | substring: 20}}
                </td>
            </ng-container>

            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef style="width: 13%">Thời gian</th>
                <td mat-cell *matCellDef="let element">
                  <p>Ngày: {{element.startDate | parseTime: 'DD/MM/YYYY'}}</p>
                  <p>Đến ngày: {{element.endDate | parseTime: 'DD/MM/YYYY'}}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
<br>
<p class="text-center">
    <button mat-dialog-close class="btn btn-close">Đóng</button>
</p>
