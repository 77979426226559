import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ShowMessage} from '@app/_services';
import {AccountManagerService} from '../../../../_services/account-manager.service';
import {PersonalService} from '@app/_services';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.scss']
})
export class ImportUsersComponent implements OnInit {
  displayedColumns = [];
  @Input() dataSource;
  @Output() isImport = new EventEmitter<boolean>();
  isChecked = false;
  userId: string;
  dataExport: AOA = [['error', 'fullname', 'code', 'account', 'password', 'email', 'phone', 'birth', 'gender', 'address', 'ward']];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'File_fail.xlsx';

  constructor(
      private message: ShowMessage,
      private accountManagerService: AccountManagerService,
      private personalService: PersonalService,
  ) { }
  ngOnInit(): void {
    this.getInfoUserByUsername();
    let isValid = this.dataSource.map(e => {
      return e.isValid;
    }).indexOf(false);

    if (isValid < -1){
      this.displayedColumns = ['fullname', 'code', 'account', 'password', 'email', 'phone', 'birth', 'gender', 'address', 'ward'];
    }else{
      this.isChecked = true;
      this.displayedColumns = ['error', 'fullname', 'code', 'account', 'password', 'email', 'phone', 'birth', 'gender', 'address', 'ward'];
    }

    this.dataExportServer();
  }

  dataExportServer(){
    this.dataSource.forEach(item => {
      let data = [];
      data.push(item.errors);
      data.push(item.fullName);
      data.push(item.userCode);
      data.push(item.userName);
      data.push(item.password);
      data.push(item.email);
      data.push(item.phone);
      data.push(item.birthday);
      data.push(item.gender);
      data.push(item.address);
      data.push(item.ward);
      this.dataExport.push(data);
    });
  }

  getInfoUserByUsername(){
    let token = JSON.parse(localStorage.getItem('currentUser'));
    return this.personalService.getInfoUserByAccessToken(token).subscribe((res: any) => {
      if (res.status === 1) return this.userId = res.data.id;

      return this.message.error(res.msg);
    });
  }

  exportExcel(){
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExport);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  cancel(){
    this.isImport.emit(false);
    this.message.error('Đã hủy')
  }

  addDataImport(){
    this.accountManagerService.createUserImport(this.userId, this.dataSource).subscribe((res: any) => {
      if (res.status === 1){
        this.message.success(res.msg);
        return this.isImport.emit(false);
      }

      if (res.status === 0) this.message.error(res.msg);
    });
  }


}
