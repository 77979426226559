import { Component, OnInit,Input,Output,EventEmitter  } from '@angular/core';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShowMessage } from '@app/_services';
import { GradeManagerService } from '@app/_services/grade.service';

@Component({
  selector: 'app-grade-detail-table-customer',
  templateUrl: './table-customer.component.html',
  styleUrls: ['./table-customer.component.scss']
})
export class GradeDetailTableCustomerComponent implements OnInit {
  @Input() dataTenant:any;
  @Input() idGradeCustomer:any;
  @Output() outCheckRemoveTenant = new EventEmitter<any>();
  pageIndex = 0;
  pageSize = 15;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'status','action'];
  length;

  constructor(
      public dialog: MatDialog,
      private gradeManagerService:GradeManagerService
  ) { }

  ngOnInit(): void {
  }

  clickDeleteCustomer(item,idGrade){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
        data: {
          title: 'Bạn đang thực hiện gỡ khách hàng ra khỏi khối',
          message: 'Bạn có chắc muốn gỡ khách hàng này không?',
          btnOkText: 'XÁC NHẬN',
          btnCancelText: 'HỦY',
          data: {
            name: item.tenantFullname,
          },
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.outCheckRemoveTenant.emit({
          "tenantId": item.tenantId,
          "gradeId": idGrade
        });
      }else{
        this.outCheckRemoveTenant.emit(false);
      }
    });

  }

}
