<div fxLayout="row">
    <div fxFlex="25%"></div>
    <div fxFlex="50%">
        <h4 class="title-page-global text-center" mat-dialog-title>Thêm phân quyền</h4>
    </div>
    <div fxFlex="25%"></div>
</div>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<mat-dialog-content>
    <div class="container-fluid dialog-content" fxLayout="row">
        <div fxFlex="1%"></div>
        <div fxFlex="98%">
            <div fxLayout="row" fxLayoutAlign="space-between none" class="mt-4">
                <input class="form-control input-field-dialog" placeholder="Nhập tên, mã phân quyền" (keydown)="Search($event)" id="search-value-dialog">
            </div>
            <div fxLayout="row" fxLayoutAlign="start none" class="mt-4">
                <mat-checkbox [checked]="checkAll" (change)="selectAllItem($event);"></mat-checkbox> <span class="ml-1">Chọn tất cả</span><span class="ml-5">Đã chọn: {{ count_selected }} phân quyền mới</span>
            </div>
            <div class="mt-1 mb-2 row">
                <div class=" mt-2 col-lg-3" *ngFor="let item of dataRolesNotAssign">
                    <div class="item-subject" fxLayout="row" fxLayoutAlign="start center" >
                        <mat-checkbox [checked]="checkAll" class="ml-3" [value]="item.roleId" (change)="selectItem($event);"></mat-checkbox>
                        <label class="ml-3 mt-2" fxLayout="column" fxLayoutAlign="start start">
                            <span class="title-subject"> {{ item.roleName }}</span>
                            <span class="subtitle-subject">{{ item.roleCode }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="1%"></div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mt-3">
    <button mat-button mat-dialog-close mat-raised-button class="mat-elevation-z btn-cancel px-5">Hủy</button>
    <button type="submit" mat-raised-button class="mat-elevation-z btn-save px-5" (click)="clickAddRolesToUser();">Lưu</button>
</mat-dialog-actions>


