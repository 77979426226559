<div fxLayout="column" class="bg-content-detail-global">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h4 class="title-custom">{{lang.title_list_customer}}</h4>
                <p class="result-global">{{lang.total}}: {{ data.length }}</p>
            </div>
        </div>
    </div>
    <div fxFlex>
        <table mat-table [dataSource]="data" matSort class="table-el-tab">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;">{{lang.lable.stt}}</th>
                <td mat-cell *matCellDef="let item; let i = index">{{ i+1 }}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">{{lang.lable.fullname}}</th>
                <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;" (click)="opendetaiTenant(item.id,'tab1');">{{ item.fullname }}</a></td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">{{lang.lable.code}}</th>
                <td mat-cell *matCellDef="let item">{{ item.code }}</td>
            </ng-container>
            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">{{lang.lable.domain}}</th>
                <td mat-cell *matCellDef="let item">{{ item.domain }}</td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">{{lang.lable.phone}}</th>
                <td mat-cell *matCellDef="let item">{{ item.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">{{lang.lable.email}}</th>
                <td mat-cell *matCellDef="let item">{{ item.email }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.lable.status}}</th>
                <td mat-cell *matCellDef="let item">
                    <mat-checkbox [checked]="item.status == 1? true: false">
                        {{lang.lable.active}}
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên module</th>
                <td mat-cell *matCellDef="let item">
                    <button class="btn btn-block" (click)="openDialogDelete(item)">
                        <img src="assets/images/svg/delete.svg" alt="">
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
        <mat-paginator appStylePaginator showFirstLastButtons [length]="length" [pageIndex]="0" [pageSize]="5" class="table-paginate-tab-global"></mat-paginator>
    </div>
</div>