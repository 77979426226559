<span mat-dialog-title class="title-delete">
    <p class="title-text-danger">{{ data_delete.title }}</p>
    <p class="title-name ellipsis-text" title="data_delete.data.name">{{ data_delete.data?.name  | substring: 40}}</p>
</span>
<mat-dialog-content class="mat-typography">
    <div class="mat-dialog-content-delete" [innerHTML]="data.html ? data.html : ''"></div>
    <p class="message-delete" [style]="data.html ? 'margin: 20px 0 20px 0' : ''">{{ data_delete.message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn  dialog-active-btn-cancel mat-elevation-z" (click)="onDismiss()">{{ data_delete.btnCancelText }}</button>
    <button mat-button type="submit" class="btn  dialog-active-btn-submit mat-elevation-z" (click)="confirm()"> {{ data_delete.btnOkText }}</button>
</mat-dialog-actions>
