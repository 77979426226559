import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {DialogFormSubjectsManagerComponent} from '@app/_modules/subjects/dialogs/dialog-form-subjects-manager/dialog-form-subjects-manager.component';
import {DialogNotificationComponent} from '@app/_shared/dialogs/notification/notification.component';
import {DialogSubjectListCustomersComponent} from '@app/_modules/subjects/dialogs/dialog-subject-list-customers/dialog-subject-list-customers.component';
import {Observable, Subscriber} from 'rxjs';
import {SUBJECT_PERMISSION} from '@app/_modules/subjects/constant';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-subjects-list-customer',
  templateUrl: './subjects-list-customer.component.html',
  styleUrls: ['./subjects-list-customer.component.scss']
})
export class SubjectsListCustomerComponent implements OnInit {

  dataColumn = ['stt', 'subject', 'code', 'grade', 'tenant', 'is_active'];
  @Input() dataSource: any;
  @Input() countData: number;
  @Input() pageIndex = 0;
  @Output() reloadDataSource = new EventEmitter<boolean>();
  @Output() reloadDataSourcePaginate = new EventEmitter<number>();
  typeModule = 'subject';
  permissionSubject = SUBJECT_PERMISSION;
  constructor(
      private dialog: MatDialog,
      private router: Router,
      private  showMessage: ShowMessage,
      ) { }

  ngOnInit(): void {
  }

  openDetailSubject(id, tab) {
    this.router.navigate([`${'subjects-manager/detail'}/${id}`], {queryParams: {active: tab}});
  }
  onChangePaginate(event){
    this.pageIndex = event.pageIndex;
    this.reloadDataSourcePaginate.emit(this.pageIndex);
  }

  openTenant(id) {
    this.router.navigate([`${'tenants-manager/detail'}/${id}`], {queryParams: {active: 'tab1'}});
  }

}
