<div fxLayout="column" class="bg-content-global" *ngIf="isImportIndustry == false">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h4 class="title-page-global">Đơn vị hành chính</h4>
            </div>
            <div style="display: flex;">
                <select class="input-field-global search-industry-custom" #option_education>
                    <option [value]="1" class="custom-text-option">Tỉnh/TP</option>
                    <option [value]="2" class="custom-text-option">Quận/Huyện</option>
                    <option [value]="3" class="custom-text-option">Phường/Xã</option>
                </select>
                <div style="position: relative;">
                    <input [style.color]="showSearch ? '#A1A1A1' : '#FFFFFF' " (focusout)="searchFocusOut(search.value,option_education.value);" #search type="text" class="p-0 text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}"
                        [value]="dataSearch" (input)="onSearchChange($event.target.value)" [readOnly]="showSearch ? false: true" placeholder="{{showSearch ? 'Tìm kiếm' : ''}}">
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass(search.value)">
                    <div class="result-search {{ !checkResultSearch ? '' : 'active' }}">
                        <div class="item" *ngFor="let item of resultSearch" [routerLink]="[linkDetailEducation,item.id]">
                            <h4>{{ item.fullname }}</h4>
                            <p *ngIf="item.type == 2">{{ item.nameCity }}</p>
                            <p *ngIf="item.type == 3">{{ item.nameCity }} > {{ item.nameDistrict }}</p>
                        </div>
                    </div>
                </div>
                <ng-template ngxPermissionsOnly="{{permissionLocation.perm7}}">
                    <button class="btn-file-global mr-2" mat-raised-button (click)="openDialogImportIndustry();"><mat-icon>note_add</mat-icon> Import</button>
                </ng-template>
                <ng-template ngxPermissionsOnly="{{permissionLocation.perm2}}">
                    <button class="btn-add-global" mat-raised-button (click)="openDialogCreate();"><mat-icon>add</mat-icon> Thêm mới</button>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex class="content-declare" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="lv-1" style="display: flex;flex-direction: column;width: 28%;height: 100%">
            <div class="title">Tỉnh/ Thành phố ({{ countTinhTP }})</div>
            <div class="grades" style="display: flex;flex-direction: column;width: 100%;height: 100%">
                <div *ngFor="let item of dataLocationLv1" class="grade" id="{{ item.id }}" (click)="openLv2(item.id)">
                    <div class="content-grade {{ code_active == item.id ? 'active' : '' }}">
                        <div class="grade-left">
                            <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            <p>
                                <span>Quận/huyện:{{ item.countDistrict }}</span>
                                <span>Phường/xã:{{ item.countWard }}</span>
                            </p>
                        </div>
                        <div class="grade-right">
                            <ng-template ngxPermissionsOnly="['{{permissionLocation.perm3}}', '{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}']">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="menu-more-global">
                                    <ng-template ngxPermissionsOnly="{{permissionLocation.perm3}}">
                                        <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/administrative-unit/lv1/detail/',item.id]">Chi tiết</button>
                                    </ng-template>
                                    <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                        <button mat-menu-item class="px-5" (click)="clickEditEducationLv1(item);">Chỉnh sửa</button>
                                    </ng-template>
                                    <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                        <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                    </ng-template>
                                </mat-menu>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lv-2" style="display: flex;flex-direction: column;width: 28%;height: 100%;">
            <div class="title">Quận/ Huyện ({{ countQuanHuyen ? countQuanHuyen : 0 }})</div>
            <!-- start quận huyện -->
            <div class="content-lv-2" style="max-height: 50%;" [style]="checkClickLv1 ? 'visibility: visible;' : 'visibility: hidden;'" *ngIf="checkClickLv1">
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height: 100%;overflow-y: auto;">
                    <div *ngFor="let item of dataQuanHuyen" class="grade" id="{{ item.id }}" (click)="openLv3(item.id)">
                        <div class="content-grade {{ code_active_lv_2 == item.id ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                                <p>
                                    <span>Phường/xã:{{ item.countWard }}</span>
                                </p>
                            </div>
                            <div class="grade-right">
                                <ng-template ngxPermissionsOnly="['{{permissionLocation.perm3}}', '{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm3}}">
                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/administrative-unit/lv2/detail/',item.id]">Chi tiết</button>
                                        </ng-template>
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv2();">Chỉnh sửa</button>
                                        </ng-template>
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                            <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv2(item);">Chuyển</button>-->
                                        </ng-template>
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end quận huyện -->
        </div>
        <div class="lv-3" style="display: flex;flex-direction: column;width: 28%;height: 100%">
            <div class="title">Phường/ Xã ({{ countPhuongXa ? countPhuongXa : 0 }})</div>
            <!-- start phường/xã -->
            <div class="content-lv-3" style="max-height: 33%;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;height: 50%;">
                    <div *ngFor="let item of dataPhuongXa" class="grade" id="{{ item.id }}">
                        <div class="content-grade {{ code_active == item.id ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            </div>
                            <div class="grade-right">
                                <ng-template ngxPermissionsOnly="['{{permissionLocation.perm3}}', '{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm3}}">
                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/administrative-unit/lv3/detail/',item.id]">Chi tiết</button>
                                        </ng-template>
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3();">Chỉnh sửa</button>
                                        </ng-template>
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                            <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv3(item);">Chuyển</button>
                                        </ng-template>
                                        <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end phường/xã -->
        </div>
    </div>
    <!--  -->
</div>
<app-import-administrative (isImport)="getCloseImport($event)" *ngIf="isImportIndustry == true" [dataSource]="dataImport" [fileName]="fileName"></app-import-administrative>