<div class="dialog-body">
    <div class="row">
        <div class="col-md-12 text-center title">
            {{data.infoModule.fullname}} - Quyền
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <span class="font">Tổng số: {{dataSource.length}} quyền</span>
                </div>

                <div class="col-md-12 d-flex justify-content-center">
                    <input (keydown)="keyPressSearch($event,input_search.value)" #input_search type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Nhập tên hoặc mã quyền">
                </div>
                <img (click)="blurSearch(input_search.value)" style="position: relative; left: 30px; bottom: 30px; cursor: pointer" src="assets/images/svg/search.svg" alt="">
            </div>
        </div>
    </div>

    <div class="container-fluid custom-scroll">
        <table mat-table [dataSource]="dataSource" class="table-el">

            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 8%;"> STT</th>
                <td class="border-first-5" mat-cell *matCellDef="let element, let i = index">
                    {{i + 1}}
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fullname" style="width: 23%;">
                <th mat-header-cell *matHeaderCellDef> Tên quyền</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fullname}}
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 25%;"> Mã</th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef style="width: 25%;"> Mô tả</th>
                <td mat-cell *matCellDef="let element">
                    {{element.description}}
                </td>
            </ng-container>

            <ng-container matColumnDef="decentralization">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Phân quyền</th>
                <td mat-cell *matCellDef="let element">
                    {{element.decentralization}}
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="order_index">
                <th mat-header-cell *matHeaderCellDef>Thứ tự hiển thị</th>
                <td mat-cell *matCellDef="let element">
                    {{element.indexOrder}}
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <p *ngIf="dataSource.length === 0">Không có dữ liệu</p>
    </div>
</div>
<br>
<p class="text-center">
    <button mat-dialog-close class="btn btn-close">Đóng</button>
</p>