import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogSubjectAddCustomersComponent} from '@app/_modules/subjects/dialogs/dialog-subject-add-customers/dialog-subject-add-customers.component';
import {DialogSubjectAddGradesComponent} from '@app/_modules/subjects/dialogs/dialog-subject-add-grades/dialog-subject-add-grades.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {DialogFormSubjectsManagerComponent} from '@app/_modules/subjects/dialogs/dialog-form-subjects-manager/dialog-form-subjects-manager.component';
import {Observable, Subscriber} from 'rxjs';
import { SUBJECT_PERMISSION } from '@app/_modules/subjects/constant';

@Component({
  selector: 'app-subjects-detail',
  templateUrl: './subjects-detail.component.html',
  styleUrls: ['./subjects-detail.component.scss']
})
export class SubjectsDetailComponent implements OnInit {
  selectGrade = [
    {id: 1, fullName: 'Mầm Non'},
    {id: 2, fullName: 'Tiểu học'},
    {id: 3, fullName: 'Trung học cơ sở'},
    {id: 4, fullName: 'Trung học phổ thông'},
    {id: 99, fullName: 'Khác'},
  ];
  permissionSubject = SUBJECT_PERMISSION;
  showSearch: boolean = true;
  showFilter: boolean = false;
  dataSearchCustomer: string = '';
  dataSearchGrade: string = '';
  tab: string;
  id: string;
  item: any;
  dataFilter = [];
  dataFilterApp = [];
  change = 0;
  constructor(
      private dialogRef: MatDialog,
      private router: Router,
      private route: ActivatedRoute,
      private subjectService: SubjectService,
      private showMessage: ShowMessage,
      private listenFirebaseService: ListenFirebaseService) { }

  ngOnInit(): void {
    this.tab = this.route.snapshot.queryParams.active;
    this.id = this.route.snapshot.paramMap.get('id');
    this.detailSubject();
    this.checkFireBaseModule();
  }


  detailSubject(){
    this.subjectService.getSubjectById(this.id).subscribe((ref: any) => {
      this.item = ref.data;
      this.item.trainingLevel = this.selectGrade.find(i => i.id === this.item.trainingLevel).fullName;
      this.tab = this.item.isMoetSubject === 0 ? 'tab2' : this.tab;
    });
  }

  toggleClass(){
    this.showSearch = !this.showSearch;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
    if (this.change !== 0){
      this.change ++;
    }
    if (this.showSearch === false){
      this.dataSearchCustomer = '';
      this.dataSearchGrade = '';
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkText(event, value){
    if (this.showSearch === true) {
      if (event.keyCode === 13  || event.keyCode === 9 ){
        if (this.tab === 'tab1') {
          this.dataSearchCustomer = value;
          this.change ++;
        }
        else if (this.tab === 'tab2'){
          this.dataSearchGrade = value;
          this.change ++;
        }
      }
    }
  }

  changeTab(tab){
    this.tab = tab;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
    this.showSearch = true;
    this.dataSearchGrade = '';
    this.dataSearchCustomer = '';
    this.dataFilter = [];
    this.dataFilterApp = [];
    if (this.change !== 0){
      this.change ++;
    }
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
    this.dataFilterApp = [];
    if (this.change !== 0){
      this.change ++;
    }
  }

  itemOptionClick(event, value){
    if(event.srcElement.classList.contains("active")){
      event.srcElement.classList.remove("active");
      let stt = this.dataFilter.indexOf(value);
      this.dataFilter.splice(stt, 1);
    }else{
      event.srcElement.classList.add("active");
      this.dataFilter.push(value);
    }
  }

  filterStatus(){
    this.dataFilterApp = this.dataFilter;
    this.change ++;
  }

  showAddCustomer(){
    const dialogAdd = this.dialogRef.open(DialogSubjectAddCustomersComponent, {
      height: 'auto',
      width: '60%',
      data: {id: this.id, name: this.item.fullname}
    });
    dialogAdd.afterClosed().subscribe((ref: any) => {
      if (ref){
        this.change++;
      }
    });
  }

  showAddGrade(){
    const dialogAdd = this.dialogRef.open(DialogSubjectAddGradesComponent, {
      height: 'auto',
      width: '60%',
      data: {id: this.id, name: this.item.fullname}
    });
    dialogAdd.afterClosed().subscribe((ref: any) => {
      if (ref){
        this.change++;
      }
    });
  }

  openDialogUpdate(infoSubject) {
    const dialogRef = this.dialogRef.open(DialogFormSubjectsManagerComponent, {
      height: 'auto',
      width: '70%',
      data: {
        action: 'update',
        infoSubject
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res){
        this.checkFireBaseModule();
      }
    });
  }

  checkFireBaseModule(){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('update', 'subject', subscriber);
    });
    listenFb.subscribe((refg) => {
      if (refg === true) {
        this.detailSubject();
        this.change++;
      }
    });
  }
}
