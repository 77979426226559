import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SubjectListComponent} from '@app/_modules/subjects/components/subject-list/subject-list.component';
import {SubjectsDetailComponent} from '@app/_modules/subjects/components/subjects-detail/subjects-detail.component';
import {SoftwareListComponent} from '@app/_modules/module-software-manager/components/software-list/software-list.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {SoftwareDetailComponent} from '@app/_modules/module-software-manager/components/software-detail/software-detail.component';
import { SUBJECT_PERMISSION } from '@app/_modules/subjects/constant';

const routes: Routes = [
  {
    path: '',
    component: SubjectListComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUBJECT_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'detail/:id',
    component: SubjectsDetailComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [SUBJECT_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubjectsRoutingModule { }
