import { Component, OnInit, Inject } from '@angular/core';
import { TenantManagerService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { Tenant } from '@app/_models';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-tenant-edit-grade',
  templateUrl: './dialog-tenant-edit-grade.component.html',
  styleUrls: ['./dialog-tenant-edit-grade.component.scss']
})
export class DialogTenantEditGradeComponent implements OnInit {

  constructor(
    private tenantManagerService: TenantManagerService,
    @Inject(MAT_DIALOG_DATA) public dataInput,
    private showMessage: ShowMessage,
    private dialogRef: MatDialogRef<DialogTenantEditGradeComponent>,
    private listenFirebaseService: ListenFirebaseService
  ) { }
  keyword = "";
  status = 0;
  group = 0;
  tenantId: string;
  queryParams: any;
  tenantInfor: Tenant;
  typeModule = 'tenant_grade';
  isLoadingResults = false;

  ngOnInit(): void {
    this.tenantId = this.dataInput.tenantId;
    this.getInfoTenant();
    this.getDataAllGrades();
  }
  data = [];
  getInfoTenant() {
    this.tenantManagerService.getDataTenantById(this.tenantId).subscribe((res: any) => {
      this.tenantInfor = res.data;
    });
  }

  getDataAllGrades() {
    this.isLoadingResults = true;

    return this.tenantManagerService.getAllGrades(this.keyword, this.tenantId, this.status, this.group).subscribe((res: any) => {
      let data3 = _.groupBy(res.data, 'trainingLevel');
      let trainingLevelArr = Object.keys(data3);

      let temp = [];
      trainingLevelArr.forEach(trainingLevelEle => {
        let is_selected = true;
        let obj = {
          "trainingLevel": trainingLevelEle,
          "subjectArray": []
        }
        data3[trainingLevelEle].forEach(element => {
          if (element.isAssigned === 0) {
            is_selected = false;
            element.is_selected = false;
          } else {
            element.is_selected = true;
          }
          obj["subjectArray"].push(element);
        });
        obj["is_selected"] = is_selected;
        temp.push(obj)
        this.data = temp;
      });
      this.isLoadingResults = false;

    });
  }

  submitUpdate() {
    // get ids grades have is_selected: true
    this.isLoadingResults = true;

    let selectIdArr = [];
    this.data.forEach(element => {
      element.subjectArray.forEach(subject => {
        if (subject.is_selected === true) {
          selectIdArr.push(subject.id);
        }
      });
    });

    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase("update", this.typeModule, subscriber);
    });
    listenFb.subscribe((result) => {
      if (result === true) {
        this.dialogRef.close(result);
      }
      this.isLoadingResults = false;

    });

    this.tenantManagerService.updateAllGrade(this.tenantId, selectIdArr).subscribe((res: any) => {
      if (res.status == 0) {
        if (this.isLoadingResults) {
          this.isLoadingResults = false;
          this.showMessage.error(res.msg);
        }
      }
    }, error => {
    });
    setTimeout(() => {
      if (this.isLoadingResults) {
        this.isLoadingResults = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }

  checkStatus: boolean = false;

  checkAll(event) {
    this.data.forEach(function (t) {
      t.is_selected = event.checked;
      t.subjectArray.forEach(a => a.is_selected = event.checked);
    });
  }

  checkAllModule(event, itemId: number) {
    this.data.find(x => x.trainingLevel === itemId).subjectArray.forEach(t => t.is_selected = event.checked);
    if (event.checked === true) {
      this.data.find(x => x.trainingLevel === itemId).is_selected = true;
    } else {
      this.data.find(x => x.trainingLevel === itemId).is_selected = false;
    }
    this.checkStatus = this.data.length > 0 && this.data.every(t => t.is_selected);
  }

  updateAllComplete(itemId) {
    this.data.find(x => x.trainingLevel === itemId).is_selected = this.data.find(x => x.trainingLevel === itemId).subjectArray != null && this.data.find(x => x.trainingLevel === itemId).subjectArray.every(t => t.is_selected);
    this.checkStatus = this.data.length > 0 && this.data.every(t => t.is_selected);
  }

  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.keyword = (event.target as HTMLInputElement).value;
      this.getDataAllGrades();
    }
  }

  onBlur(event) {
      if (event.type === 'blur') {
        this.keyword = (event.target as HTMLInputElement).value;
        this.getDataAllGrades();
      }
    }
}
