
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModulesManagerService } from '@app/_services';
import { ShowMessage } from "@app/_services";
import { DialogFormModuleComponent } from '@app/_modules/modules-manager/dialogs/dialog-form-module/dialog-form-module.component';
import { ListenFirebaseService } from '@app/_services/listen-firebase.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import * as $ from 'jquery';
import {MODULE_PERMISSION} from '../../constants';

@Component({
    selector: 'app-list-module',
    templateUrl: './module-list.component.html',
    styleUrls: ['./module-list.component.scss']
})
export class ModuleListComponent implements OnInit {
    activeTab = 'tab-customer';
    showSearch = false;
    showFilter = false;
    openDialog = 0;
    filterActive = [
        {name: 'Kích hoạt', isActive: false},
        {name: 'Chưa kích hoạt', isActive: false},
    ];
    keyWord = '';
    isActive = null;
    tabActive = 1;
    countIsActive1 = 0;
    countIsActive2 = 0;
    permissions = [];
    isLoading:boolean = false;
    MODULE_PERMISSION=MODULE_PERMISSION;
    groupModule = 0;
    constructor(
        public dialog: MatDialog,
        private moduleManagerModule: ModulesManagerService,
        private showMessage: ShowMessage,
        private listenFirebaseService:ListenFirebaseService,
        ) {
    }

    ngOnInit(): void {
        // this.listenFibase();
        $("#leader-line-defs").remove();
    }

    onKeyPressSearch(event: any, value){
        if (event.code === 'Enter' || event.code === 'Tab'){
            this.keyWord = value;
        }
    }

    listenFibase(){
        // action add module
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase("create", "module", subscriber);
        });
        listenFb.subscribe((res) => {
            if(res === true) {
                this.isActive = null;
                this.isLoading = false;
            }else{
                this.isLoading = false;
            }
        });
    }

    showFilterClick() {
        this.showFilter = !this.showFilter;
        if(!this.showFilter){
            this.isActive = null;
        }
    }

    openDialogAddModule() {
        const dialogRef = this.dialog.open(DialogFormModuleComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'add',
                infoModule: null,
                permissions: this.permissions,
                groupModule: this.groupModule,
            }
        });

        dialogRef.afterClosed().subscribe((dataInput: any) => {
            if(typeof dataInput !== 'undefined' && dataInput != false){
                this.listenFibase();
                this.isActive = 3;
                this.isLoading = true;
                this.moduleManagerModule.storeModule(dataInput).subscribe(
                    (res:any)=>{
                        if(res.status == 0){
                          this.isLoading = false;
                          this.showMessage.error(res.msg);
                        }
                      },
                      (err)=>{
                        this.isLoading = false;
                    }
                );
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }

    itemOptionClick1(type) {
        this.countIsActive1++;
        if(this.countIsActive1 == 1){
            this.countIsActive2 = 0;
            this.isActive = type;
        }else{
            this.isActive = null;
            this.countIsActive1 = 0;
        }
    }

    itemOptionClick2(type) {
        this.countIsActive2++;
        if(this.countIsActive2 == 1){
            this.countIsActive1 = 0;
            this.isActive = type;
        }else{
            this.isActive = null;
            this.countIsActive2 = 0;
        }
    }


    changeTab(_nameTab) {
        this.openDialog = 0;
        this.activeTab = _nameTab;
        if(_nameTab == "tab-customer"){
            this.tabActive = 1;
            this.groupModule = 0;
        }else{
            this.tabActive = 0;
            this.groupModule = 1;
        }
    }

    toggleClass(value) {
        this.keyWord = value;
        this.showSearch = !this.showSearch;
    }
}
