<div class="wrapper">
  <div class="title">
    <h3>Danh sách cấu hình SMS</h3>
  </div>
  <!--  danh sach  -->
  <table class="table table-bordered table-el">
    <thead style="background: #EEEEEE;">
      <tr>
        <th scope="col" class="text-center text-table" style="width: 7%;">STT</th>
        <th scope="col" class="text-table" style="width: 78%;">Tên khách hàng</th>
        <th scope="col" class="text-table text-center" style="width: 15%;">Trạng thái</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of listConfig; let i=index">
        <tr>
          <!-- stt -->
          <td scope="row" class="text-center stt">{{i+1}}</td>

          <!-- ten khach hang -->
          <td>
            {{item.tenantName}}
          </td>

          <!-- trang thai -->
          <td class="text-center">
            <label class="switch">
              <input
                type="checkbox" [(ngModel)]="item.isDisplayActive"
                [checked]="item.isDisplayActive ? true : null"
                (change)="changeActive(item)"
              />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="listConfig.length == 0" class="text-no-data">
        <td colspan="3">Không có dữ liệu</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>