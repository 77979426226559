import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-dialog-change-status',
  templateUrl: './dialog-change-status.component.html',
  styleUrls: ['./dialog-change-status.component.scss']
})
export class DialogChangeStatusComponent implements OnInit {
  data_change_status:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRes: MatDialogRef<DialogChangeStatusComponent>) { }

  ngOnInit(): void {
    this.data_change_status = this.data;
  }
  confirm(){
    this.dialogRes.close('ok');
  }
}
