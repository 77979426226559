<div fxLayout="column" style="overflow-x: hidden">

    <div class="div-panel-title-dialog" fxFlex="40px">Thông tin cơ bản</div>
    <br>
    <div class="row custom-space-right">
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3">Tên người dùng</label>
            <div class="col-md-8 pl-0 pr-0">
                <textarea readonly class="form-control input-field-global">{{infoUser?.fullName}}</textarea>
<!--                <input disabled [value]="infoUser?.fullName" type="text"   >-->
            </div>
        </div>
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3 offset-1">Mã</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.code" type="text" class="form-control input-field-global"  >
            </div>
        </div>
    </div>

    <div class="row custom-space-right">
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3">Ngày sinh</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="parseTime(infoUser?.birthday)" type="text" class="form-control input-field-global"  >
            </div>
        </div>
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3 offset-1">Giới tính</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.gender === 1 ? 'Nam' : 'Nữ'" type="text" class="form-control input-field-global"  >
            </div>
        </div>
    </div>

    <div class="row custom-space-right">
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3">Email</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.email" type="text" class="form-control input-field-global"  >
            </div>
        </div>
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3 offset-1">Tài khoản</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.userName" type="text" class="form-control input-field-global"  >
            </div>
        </div>
    </div>

    <div class="row custom-space-right">
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3">Điện thoại liên hệ</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.phone" type="text" class="form-control input-field-global"  >
            </div>
        </div>
    </div>

    <div class="row custom-space-right">
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3">Tỉnh/thành phố</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.nameCity" type="text" class="form-control input-field-global"  >
            </div>
        </div>
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3 offset-1 ">Quận/huyện</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.nameDistrict"  type="text" class="form-control input-field-global"  >
            </div>
        </div>
    </div>

    <div class="row custom-space-right">
        <div class="form-group col-md-6 row row-global">
            <label class="col-md-3 ">Phường xã</label>
            <div class="col-md-8 pl-0 pr-0">
                <input disabled [value]="infoUser?.nameWard"  type="text" class="form-control input-field-global"  >
            </div>
        </div>
    </div>

    <div class="div-address-custom custom-space-right">
        <label class="label-address-custom">Địa chỉ</label>
        <div class="input-address-custom">
            <textarea readonly style="height: 120px" class="form-control input-field-global">{{infoUser?.address}}</textarea>
        </div>
    </div>
</div>
