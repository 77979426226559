import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AccountManagerService, ShowMessage} from '@app/_services';


@Component({
  selector: 'app-dialog-list-customers',
  templateUrl: './dialog-list-customers.component.html',
  styleUrls: ['./dialog-list-customers.component.scss']
})
export class DialogListCustomersComponent implements OnInit {
  dataCustomer: any;
  length: number;
  pageSize = 15;
  pageIndex = 0;
  keyWord = '';
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email'];
  isLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountManagerService: AccountManagerService,
    private showMessage: ShowMessage
  ) { }

  ngOnInit(): void {
    this.getAllUserByTenant();
  }

  getAllUserByTenant(){
    this.isLoading = true;
    this.accountManagerService.getCustomerByUser(this.pageSize, this.pageIndex, this.data.infoUser.id, this.keyWord).subscribe((res: any) => {
      this.isLoading = false;
      this.dataCustomer = res.data.data;
      this.length = res.data.totalItems;
    });
  }

  onChangePaginate(event){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getAllUserByTenant();
  }

  toggleClass(){
    (document.getElementById('search-value') as HTMLInputElement).value = '';
  }

  checkText(event, value){
    if (event.key === 'Enter' ||event.key === 'Tab'){
      this.keyWord = value.trim();
      this.getAllUserByTenant();
    }
  }


}
