export const SUBJECT_PERMISSION = {
    perm1: 'core_admin_subjects',
    perm2: 'core_admin_subject_add',
    perm3: 'core_admin_subject_detail',
    perm4: 'core_admin_subject_edit',
    perm5: 'core_admin_subject_delete',
    perm6: 'core_admin_subject_import',
    perm7: 'core_admin_subject_tenants',
    perm8: 'core_admin_subject_tenant_add',
    perm9: 'core_admin_subject_tenant_delete',
    perm10: 'core_admin_subject_grades',
    perm11: 'core_admin_subject_grade_add',
    perm12: 'core_admin_subject_grade_delete',
};
