<div fxLayout="column" class="bg-content-global">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h4 class="title-page-global">Cấu hình chung</h4>
            </div>
        </div>
    </div>
    <div fxFlex style="overflow-y: auto;">
        <div fxLayout="row" class="tab-group">
            <div fxFlex="22%" class="tab-left" fxLayout="column">
                <div fxFlex="40%" fxLayout="column">
                    <div fxLayout="column" fxLayoutAlign="start" class="tab-links">
                        <ng-container *ngFor="let link of links">
                            <ng-template [ngxPermissionsOnly]="link.permission">
                                <div mat-tab-nav-bar>
                                    <a class="ml-5" mat-tab-link (click)="activeLink = link.route"
                                    [active]="activeLink == link.route"> {{link.name}} </a>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>

            </div>
            <div fxFlex class="tab-right">
                <app-system *ngIf="activeLink === 'tab1'"></app-system>
                <app-security-information *ngIf="activeLink === 'tab2'"></app-security-information>
                <app-email-sever *ngIf="activeLink === 'tab3'"></app-email-sever>
                <app-system-introduction *ngIf="activeLink === 'tab4'"></app-system-introduction>
                <app-tab-about-policy-config *ngIf="activeLink === 'tab5'"></app-tab-about-policy-config>
                <app-tab-about-term-of-service *ngIf="activeLink === 'tab6'"></app-tab-about-term-of-service>
                <app-tab-integrated-elsa *ngIf="activeLink === 'tab7'"></app-tab-integrated-elsa>
                <app-tab-config-sms *ngIf="activeLink === 'tab8'"></app-tab-config-sms>
                <!-- <app-tab-config-examus *ngIf="activeLink === 'tab9'"></app-tab-config-examus> -->
                <app-tab-config-noti-system-maintenance *ngIf="activeLink === 'tab10'"></app-tab-config-noti-system-maintenance>
                <app-tab-config-private-key-upload-file *ngIf="activeLink === 'tab11'"></app-tab-config-private-key-upload-file>
            </div>
        </div>
    </div>
</div>
