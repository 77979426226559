import { Component, OnInit } from '@angular/core';
import {ListenFirebaseService, ShowMessage} from '@app/_services';
import {ActivatedRoute} from '@angular/router';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {lang} from '../../school-years.lang';
import {DialogFormSchoolYearsComponent} from '../../dialogs/dialog-form-school-years/dialog-form-school-years.component';
import {DialogAddCustomerComponent} from '../../dialogs/dialog-add-customer/dialog-add-customer.component';
import {DialogChangeStatusComponent} from '../../dialogs/dialog-change-status/dialog-change-status.component';
import {SchoolYearsManagerService} from '@app/_services';
import {SchoolYear} from '@app/_models';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, Subscriber} from 'rxjs';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';
import {SCHOOL_YEAR_PERMISSION} from '@app/_modules/school-years-manager/constant';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-school-year-details',
  templateUrl: './school-year-details.component.html',
  styleUrls: ['./school-year-details.component.scss']
})
export class SchoolYearDetailsComponent implements OnInit {
  dataSearch = '';
  lang = lang;
  schoolYearId: string;
  infoSchoolYear: SchoolYear;
  showFilter = false;
  showSearch = true;
  isLoadingResults = false;
  oldValueStatus;
  keyword = '';
  dataStatus = [];
  dataSource = [];
  permissionSchoolYear = SCHOOL_YEAR_PERMISSION;
  statusUser = [
    {id: 0, name: 'Chưa kích hoạt', color: '#77B6D1', checked: false},
    {id: 1, name: 'Dùng thử', color: '#1355C1', checked: false},
    {id: 2, name: 'Đang sử dụng', color: '#FF8038', checked: false},
    {id: 3, name: 'Hết hạn sử dụng', color: '#CC4D4D', checked: false},
    {id: 4, name: 'Hết hạn dùng thử', color: '#6B6B6B', checked: false},
    {id: 99, name: 'Khác', color: '#8950FC', checked: false},
  ];

  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'start_date', 'to_date', 'status'];
  typeModule = 'school_year';
  isLoading = false;
  constructor(
      private dialog: MatDialog,
      private activeRoute: ActivatedRoute,
      private _snackBar: MatSnackBar,
      private showMessage: ShowMessage,
      private route: Router,
      private schoolYearsManagerService: SchoolYearsManagerService,
      private db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService,
      private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit(): void {
    if (this.permissionsService.getPermission(this.permissionSchoolYear.perm8)){
      this.displayedColumns.push('tools');
    }
    this.activeRoute.paramMap.subscribe((param: any) => {
      this.schoolYearId = param.params.id;
      this.getInfoSchoolYear();
      this.loadDataItems();
    });
  }

  listenFireBase(action, type){
    this.isLoading = true;
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(action, type, subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref) {
        if (action === 'update'){
          return this.getInfoSchoolYear();
        }
        this.loadDataItems();
      }
    });
  }

  findStatusUser(status){
    return this.statusUser.find(item => item.id === status);
  }


  showFilterClick(){
    this.showFilter = !this.showFilter;
    if (!this.showFilter){
      this.statusUser.forEach((item, index) => {
        item.checked = false;
      });
      if (this.dataStatus.length > 0)
      {
        this.dataStatus = [];
        this.loadDataItems();
      }
    }
  }

  getInfoSchoolYear(){
    this.schoolYearsManagerService.getInfoSchoolYearById(this.schoolYearId).subscribe((res: any) => {
      if (res.status === 0){
        return this.route.navigate(['school-years-manager']);
      }

      if (res.status === 1){
        this.infoSchoolYear = res.data;
      }
      this.isLoading = false;
    });
  }

  loadDataItems(){
    this.isLoading = true;
    let data = {keyword: this.keyword, status: this.dataStatus.length > 0 ? this.dataStatus : null};
    this.schoolYearsManagerService.getTenantOfSchoolYear(this.schoolYearId, data).subscribe((res: any) => {
      this.dataSource = res.data;
      this.isLoading = false;
    });
  }

  keyPressSearch(event){
    if (event.key === 'Enter' || event.key === 'Tab'){
      this.keyword = event.target.value;
      this.loadDataItems();
    }
  }

  clickFilterStatus(){
    this.loadDataItems();
  }

  itemOptionClick(keyStatus){
    let dataStatus = [];
      this.statusUser[keyStatus].checked = !this.statusUser[keyStatus].checked;
      this.statusUser.forEach(item => {
        if (item.checked === true){
          dataStatus.push(item.id);
        }
      });
      return this.dataStatus = dataStatus;
  }

  openDialogDeleteTenant(infoTenant){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện gỡ khách hàng ra khỏi năm học',
        message: 'Bạn có chắc muốn gỡ khách hàng này không ?',
        btnOkText: 'XÓA',
        btnCancelText: 'HỦY',
        data: {
          name: infoTenant.fullname,
        },
      }
    });

    const keyDataSource = this.dataSource.map(e => {
      return e.id;
    }).indexOf(infoTenant?.id);

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm){
        this.listenFireBase('delete', 'school_year_tenant');
        setTimeout(() => {
          if (this.isLoading) this.isLoading = false;
        }, TIME_OUT_LISTEN_FIREBASE);

        this.schoolYearsManagerService.removeTenantById(this.schoolYearId, infoTenant.id).subscribe((res: any) =>{
          if (res.status === 0) {
            this.isLoading = false
            return this.showMessage.error(res.msg);
          }
        }, err => this.isLoading = false);
      }
    });
  }

  openDialogAddCustomers(){
    const dialogRef = this.dialog.open(DialogAddCustomerComponent, {
      width: '70%',
      height: 'auto',
      data: {
        status: 'add',
        infoSchoolYear : this.infoSchoolYear
      },
      autoFocus: false
    });

    // this.listenFireBase('create', 'school_year_tenant');
    dialogRef.afterClosed().subscribe(res => {
      if (res)  this.loadDataItems();
      this.isLoading = false;
    })

  }

  openDialogUpdateSchoolYear(){
    const dialogRef = this.dialog.open(DialogFormSchoolYearsComponent, {
      width: '80%',
      height: 'auto',
      data: {
        status: 'update',
        data: this.infoSchoolYear
      }
    });

    this.listenFireBase('update', 'school_year');
    dialogRef.afterClosed().subscribe(res => {
      if (!res) this.isLoading = false;
    })
  }

  toggleClass(value){
    this.dataSearch = value;
    this.showSearch = !this.showSearch;
  }

}
