import { Directive, OnChanges, OnInit, Input, ElementRef, OnDestroy, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { MathService } from "./math.service";
import { take, takeUntil } from "rxjs/operators";

@Directive({
  selector: '[appMath]'
})
export class MathDirective implements OnInit, OnChanges, OnDestroy {
  @Input() private appMath: any;
  private alive$ = new Subject<boolean>();
  private readonly el: HTMLElement;

  constructor(private mathService: MathService, private elementRef: ElementRef) {
    this.el = elementRef.nativeElement as HTMLElement;
  }

  async ngOnInit() {
    let wrapper = document.createElement('div');
    wrapper.classList.add("ck-content");
    wrapper.innerHTML = this.appMath;
    let wrapper2  = await this.loadAudio(wrapper) as HTMLDivElement;
    let wrapper3  = await this.loadImage(wrapper2) as HTMLDivElement;
    this.appMath = wrapper3.outerHTML;
    this.appMath = this.appMath.replaceAll('&lt;', '<');
    this.appMath = this.appMath.replaceAll('&gt;', '>');
    // this.appMath = this.appMath.replaceAll('&nbsp;', ' ');
    this.appMath = this.appMath.replaceAll('&amp;', '&');
    await this.render();
  }

  loadImage(wrapper : any) {
    return new Promise(function(resolve, reject) {
      let length = wrapper.getElementsByTagName("img").length;
      if(length > 0){
      for (let i = 0; i < length; i++) {
        wrapper.getElementsByTagName("img")[i]?.classList.add("img-cover");
      }
      }
      resolve(wrapper);  
    });
  }

  loadAudio(wrapper : any) {
    return new Promise(function(resolve, reject) {
      let length = wrapper.getElementsByTagName("a").length;
      if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (wrapper.getElementsByTagName("a")[0]?.getAttribute("title") === "sound") {
          let url = wrapper.getElementsByTagName("a")[0].getAttribute("href");
          wrapper.getElementsByTagName("a")[0].replaceWith('<audio controls controlsList="nodownload" preload="none"><source src="' + url + '" type="audio/mpeg"></audio>');
        }
       }
      }
      resolve(wrapper);  
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes && changes['appMath'] && changes['appMath'].currentValue) {
    //   this.render();
    // }
  }

  private render() {
    this.mathService.ready().pipe(
      take(1),
      takeUntil(this.alive$)
    ).subscribe(() => this.mathService.render(this.el, this.appMath));
  }

  ngOnDestroy() {
    this.alive$.next(false);
  }

}
