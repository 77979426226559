<h2 mat-dialog-title class="title-page-global text-center">{{data.name}} - Khách hàng</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <span>Tổng số: {{length}} khách hàng</span>
            </div>
        </div>
        <div class="row input-search-dialog-list mt-2">
            <div class="col-sm-12" >
                <input #search (keydown)="checkText($event, search.value)" type="text" id="search-value-dialog"  class="form-control input-field-global" placeholder="Nhập tên hoặc mã, số điện thoại, email">
                <img src="assets/images/icons/icon-23.svg">
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-sm-12">
                <table mat-table [dataSource]="dataSource" class="table-el">
                    <ng-container matColumnDef="stt">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                        <td mat-cell *matCellDef="let item, let i = index">{{i + 1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%">Khách hàng</th>
                        <td mat-cell *matCellDef="let item" >{{item.fullname}}</td>
                    </ng-container>
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                        <td mat-cell *matCellDef="let item">{{item.code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="link">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
                        <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;">{{item.tenantUrl}}</a></td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                        <td mat-cell *matCellDef="let item">{{item.hotline}}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                        <td mat-cell *matCellDef="let item">{{item.email}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="dialog-btn-close mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
</mat-dialog-actions>
<div class="spinner-global" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
