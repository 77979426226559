<div class="dialog-body">
    <div class="row">
        <div class="col-md-12 text-center title">
            {{data.infoModule.fullname}} - Khách hàng
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <span class="font">Tổng số: {{dataSource.length}} khách hàng</span>
                </div>

                <div class="col-md-12 d-flex justify-content-center">
                    <input [autofocus]="true" #input_search (keydown)="keyPressSearch($event,input_search.value)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Nhập tên tenant hoặc mã tenant">
                </div>
                <img (click)="blurSearch(input_search.value)" style="position: relative; left: 30px; bottom: 30px; cursor: pointer" src="assets/images/svg/search.svg" alt="">

            </div>
        </div>
    </div>

    <div class="container-fluid custom-scroll">
        <table mat-table [dataSource]="dataSource" class="table-el">

            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 8%;"> STT</th>
                <td class="border-first-5" mat-cell *matCellDef="let element, let i = index"> {{i + 1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 22%;"> Khách hàng</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fullname}}
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Mã</th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Tên miền</th>
                <td mat-cell *matCellDef="let element">
                    {{element.tenantUrl}}
                </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;"> SĐT</th>
                <td mat-cell *matCellDef="let element">
                    {{element.hotline}}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;">Email</th>
                <td mat-cell *matCellDef="let element">
                    {{element.email}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
<br>
<p class="text-center">
    <button mat-dialog-close class="btn btn-close">Đóng</button>
</p>