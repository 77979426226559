<h2 mat-dialog-title class="title-page-global text-center">Thêm khách hàng</h2>
<div class="container-fluid">
    <div class="row input-search-dialog-list">
        <div class="col-sm-12">
            <input type="text"  class="form-control input-field-global" placeholder="Tên khách hàng, Mã khách hàng, Tên miền, SĐT hoặc Email" id="search-value-dialog" (keydown)="checkText($event, searchDialogList.value)" #searchDialogList>
            <img src="assets/images/icons/icon-23.svg">
        </div>
        <div class="col-sm-12"  fxLayout="row">
            <div><mat-checkbox [checked]="allComplete"  (change)="checkAllModule($event)"></mat-checkbox> Chọn tất cả</div>
            <div class="ml-5">Đã chọn: {{length}} khách hàng mới</div>
        </div>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row mt-1">
            <div class="col-sm-12">
                <p *ngIf="data && data.length === 0" style="font-size: 18px; font-weight: 500">Không có dữ liệu </p>
                <table mat-table [dataSource]="data" class="table-el" *ngIf="data && data.length > 0">
                    <ng-container matColumnDef="stt">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Chọn</th>
                        <td mat-cell *matCellDef="let item"><mat-checkbox [ngModel]="item.checked === 1" [value]="item.id" (change)="updateAllComplete($event, item.id)"></mat-checkbox></td>
                    </ng-container>
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%">Khách hàng</th>
                        <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullname}}</td>
                    </ng-container>
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                        <td mat-cell *matCellDef="let item">{{item.code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="link">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
                        <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;">{{item.tenantUrl}}</a></td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                        <td mat-cell *matCellDef="let item">{{item.hotline}}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                        <td mat-cell *matCellDef="let item">{{item.email}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn-cancel mat-elevation-z" mat-raised-button mat-dialog-close>HỦY</button>
    <button class="btn-save-data mat-elevation-z" mat-raised-button (click)="addCustomerBySubject()" [disabled]="isLoading" [style]="isLoading ? 'background: #1355C1;color: white;opacity: 0.5;' : ''">THÊM</button>
</mat-dialog-actions>
<div class="spinner-global" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
