import { Component, OnInit } from '@angular/core';
import { lang } from './lang';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { DialogChangeStatusComponent } from '@app/_shared/dialogs/change-status/dialog-change-status.component';
import { DialogChangeActiveComponent } from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogAccountListAppliesComponent } from '../../dialogs/dialog-account-list-applies/dialog-account-list-applies.component';
import { DialogSubjectAppliesComponent } from '../../dialogs/dialog-subject-applies/dialog-subject-applies.component';
import { DialogFormComponent } from '../../dialogs/dialog-form/dialog-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShowMessage } from '@app/_services';
import { Grade } from '@app/_models/grade';
import { GradeManagerService } from '@app/_services/grade.service';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFireDatabase } from '@angular/fire/database';
import { ListenFirebaseService } from '@app/_services';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {NgxPermissionsService} from 'ngx-permissions';
import { GRADE_PERMISSION } from '@app/_modules/grades-manager/constant';

@Component({
  selector: 'app-list-grade',
  templateUrl: './list-grade.component.html',
  styleUrls: ['./list-grade.component.scss']
})
export class ListGradeComponent implements OnInit {
  constructor(
      public dialog: MatDialog,
      private _snackBar: MatSnackBar,
      private message: ShowMessage,
      private gradeManagerService: GradeManagerService,
      public db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService,
      private showMessage: ShowMessage,
      private permissionsService: NgxPermissionsService
  ) { }

  dataSource:any;
  lang = lang;
  pageIndex = 1;
  pageSize = 15;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'training_level', 'total_customer', 'total_subject', 'status'];
  length = 0;
  activeTab = 'tab-for-customer';
  showSearch: boolean = false;
  showFilter: boolean = false;
  isLoading:boolean = false;
  statusSearch:number;
  isSearch:boolean = false;
  resultSearch:any;
  dataFirebase:any;
  isMoet:any = 0;
  keyword = '';
  status = null;
  countItem: number;
  statusSearchSubject = [];
  checkActiveStatusSubject:boolean;
  permissionGrade = GRADE_PERMISSION;
  dataStatus = [
      {id: 0, fullName: 'Chưa kích hoạt', isCheck: false},
      {id: 1, fullName: 'Kích hoạt', isCheck: false},
  ];

  ngOnInit(): void {
    if (this.permissionsService.getPermission(this.permissionGrade.perm4) || this.permissionsService.getPermission(this.permissionGrade.perm5)){
      this.displayedColumns.push('tools');
    }
    this.getListGrade();
  }

  getListGrade(){
    this.isLoading = true;
    let param = {
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      trainingLevel:this.statusSearchSubject.length == 0 ? null : this.statusSearchSubject,
      group: this.isMoet,
      keyword: this.keyword
    };
    this.gradeManagerService
      .indexGrade(param)
      .subscribe((data: any) => {
        this.dataSource = data.data.data;
        this.countItem = data.data.countItems;
        this.length = data.data.totalItems;
        this.isLoading = false;
      },
      (error => {
        this.isLoading = false;
      })
    );
  }

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, "grade", subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.isLoading = false;
        this.pageIndex = 1;
        this.getListGrade();
      }else{
        this.isLoading = false;
      }
    });
  }
  
  handlePage(event){
    this.pageIndex = event.pageIndex+1;
    this.getListGrade();
  }

  openDialogDelete(item){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
        data: {
          title: 'Bạn đang thực hiện xóa khối',
          message: 'Bạn có chắc muốn xóa dữ liệu này không?',
          btnOkText: 'Xóa',
          btnCancelText: 'Hủy',
          data: {
            id:item.id,
            name: item.fullname,
          },
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.checkFireBase("delete");
        this.isLoading = true;
        this.gradeManagerService.deleteGrade(item.id).subscribe((res:any)=>{
          if(res.status == 0){
            this.isLoading = false;
            this.showMessage.error(res.msg);
          }
        },
        (err)=>{
          this.isLoading = false;
        });
        setTimeout(() => {
          if(this.isLoading){
            this.isLoading = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }else{
        this.isLoading = false;
      }
    });
  }

  openDialogListCustomerApplies(id){
    this.dialog.open(DialogAccountListAppliesComponent, {
      width: '80%',
      data: {
        id:id
      },
    });
  }

  openDialogSubjectpplies(id){
    this.dialog.open(DialogSubjectAppliesComponent, {
      width: '60%',
      data: {id},
    });
  }

  openDialogCreateGrade(){
    if(this.activeTab == "tab-for-customer"){
      this.isMoet = 0;
    }
    const dialogRef = this.dialog.open(DialogFormComponent, {
      width: '60%',
      height: 'auto',
      data: {
        status: 'add',
        isMoet:this.isMoet,
        infoGrade: null
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.isMoet = 0;
      this.checkFireBase("create");
      this.isLoading = true;
      if(dialogResult){
        this.isLoading = true;
          this.gradeManagerService.storeGrade(dialogResult).subscribe((res:any)=>{
            if(res.status == 0){
              this.isLoading = false;
              this.showMessage.error(res.msg);
            }
          },
          (err)=>{
            this.isLoading = false;
          });
          setTimeout(() => {
            if(this.isLoading){
              this.isLoading = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
      }else{
        this.isLoading = false;
      }
      if(this.activeTab == "tab-for-customer"){
        this.isMoet = 0;
      }
    });
  }

  openDialogUpdateGrade(infoGrade){
    const dialogRef = this.dialog.open(DialogFormComponent, {
      width: '60%',
      height: 'auto',
      data: {
        status: 'update',
        infoGrade
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.checkFireBase("update");
      this.isLoading = true;
      if(dialogResult){
        this.isLoading = true;
          this.gradeManagerService.updateGrade(dialogResult).subscribe((res:any)=>{
            if(res.status == 0){
              this.isLoading = false;
              this.showMessage.error(res.msg);
            }
          },
          (err)=>{
            this.isLoading = false;
          });
          setTimeout(() => {
            if(this.isLoading){
              this.isLoading = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
      }else{
        this.isLoading = false;
      }
    }) ;
  }

  changeTab(tab_name){
    this.activeTab = tab_name;
    this.pageIndex = 1;
    if(tab_name == "tab-for-customer") this.isMoet = 0;
    else if(tab_name == "tab-for-customer") this.isMoet = 0;
    else this.isMoet = 1;
    this.getListGrade();
  }

  toggleClass(){
    // this.keyword = '';
    this.showSearch = !this.showSearch;
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
    if (!this.showFilter) {
      this.isMoet = null;
      this.statusSearchSubject = [];
      this.getListGrade();
      this.keyword = null;
    }
  }


  checkText(event){
    if (event.code === 'Enter' || event.code === 'Tab') {
      this.pageIndex = 1;
      this.keyword = event.target.value;
      this.getListGrade();
    }
  }


  changeStatus(key){
    this.dataStatus.forEach(item => {
      if(item.id !== this.dataStatus[key].id) item.isCheck = false;
    });
    this.dataStatus[key].isCheck = !this.dataStatus[key].isCheck;
    this.dataStatus.forEach(item => {
      if (item.isCheck === true) this.status = item.id;
    });

    if (this.dataStatus.map(item => item.isCheck).indexOf(true) === -1) this.status = null;
  }

  filterDataGrade(){
    this.pageIndex = 1;
    this.getListGrade();
  }

  clickChangeStatusGrade(event,item){
    const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '40%',
      data: {
        btnCancelText: 'Hủy',
        btnOkText: 'Xác nhận',
        status: event.checked,
        title: event.checked ? 'Bạn đang thực hiện kích hoạt khối' : 'Bạn đang thực hiện bỏ kích hoạt khối',
        content:'Bạn có chắc muốn thực hiện thay đổi này không?',
        data: {
          name: item.fullname,
        }
      }
    });
    confirmActive.afterClosed().subscribe((dialogResult: any) => {
      if(dialogResult){
        this.checkFireBase("update");
        this.isLoading = true;
        event.checked ? item.status = 1 : item.status = 0;
        item.trainingLevel = Number(item.trainingLevel);
        item.group = Number(item.group);
        delete item.code;
        this.gradeManagerService.updateGrade(item).subscribe((res:any)=>{
          if(res.status == 0){
            this.isLoading = false;
            this.showMessage.error(res.msg);
          }
        },
        (err)=>{
          this.isLoading = false;
        });
        setTimeout(() => {
          if(this.isLoading){
            this.isLoading = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }else{
        this.isLoading = false;
        this.getListGrade();
      }
    });
  }

  itemOptionClickSubjectActive(event,level){
    if(event.srcElement.classList.contains("active")){
      event.srcElement.classList.remove("active");
      const index = this.statusSearchSubject.indexOf(level);
      if (index > -1) {
        this.statusSearchSubject.splice(index, 1);
      }
    }else{
      this.checkActiveStatusSubject = true;
      this.statusSearchSubject.push(level);
      event.srcElement.classList.add("active");
    }
  }

}
