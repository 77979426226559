import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, Inject, Injectable, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { BehaviorSubject, Observable, of as ofObservable } from "rxjs";
import { DATA_TREE_FAKE } from '../../constant';
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
export class TodoItemNode {
  id: string;
  content: string;
  isUsed: boolean;
  childs: TodoItemNode[];
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id: string;
  content: string;
  level: number;
  isUsed: boolean;
  expandable: boolean;
}

@Injectable()
export class ChecklistDatabase {
  dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): any[] {
    return this.dataChange.value; 
  }

  constructor() {
    this.dataChange.next([]);
  }
}

@Component({
  selector: "mat-tree-select-option",
  templateUrl: "./mat-tree-select-option.component.html",
  styleUrls: ["./mat-tree-select-option.component.scss"],
  providers: [ChecklistDatabase],
})
export class MatTreeSelectOptionComponent implements OnInit {
  dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  // get data(): any[] {
  //   return this.dataChange.value;
  // }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName: string = "";

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  // isLoading = false;
  keyword = "";
  countItem = 0;
  dataDialogInput:any;
  arrData:any = DATA_TREE_FAKE;
  pageSize: number = 9999;
  pageIndex: number = 1;
  isLoading:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRef: MatDialogRef<MatTreeSelectOptionComponent>,
    private curriculumManagerService: CurriculumManagerService,

  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    this.getAllData();
  }
  
  getAllData() {
    this.isLoading = true;
    let dataInput = {
      khungChuongTrinhId: this.data.id,
      type: this.data.type
    }
    this.curriculumManagerService.getListContentCurriculum(dataInput).subscribe((res:any)=> {
      this.isLoading = false;
      if(res.status == 1) {
        this.dataSource.data = res.data;
        this.countItem = this.treeControl.dataNodes.filter(item => item.isUsed).length;
      }
    }, (err:any)=> {
      this.isLoading = false;
    })
  }

  convertDataTree() {
      this.treeControl.dataNodes.forEach(item => {
        if (this.data.listCheckBox.find((element) => element === item.id)) {
          item.isUsed = true;
    }});
  }

  search(event, keyword) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.keyword = keyword.trim();
      this.convertDataTree();
    }
      
  }

  save() {
    this.data.listCheckBox = this.treeControl.dataNodes.filter(item => item.isUsed).map(item => item.id);
    this.dialogRef.close(this.data.listCheckBox);
  }

  changeCheckBoxItem(node) {  
    node.isUsed = !node.isUsed;
    this.countItem = this.treeControl.dataNodes.filter(item => item.isUsed).length;
  }

  toggleAll() {
    if(this.treeControl.dataNodes) {
      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        this.treeControl.dataNodes[i].isUsed = false;
      }
    }
    this.countItem = 0; 
  }
  // tree
  getLevel = (node: TodoItemFlatNode) => {
    return node.level;
  };

  isExpandable = (node: TodoItemFlatNode) => {
    return node.expandable;
  };

  getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
    return ofObservable(node.childs);
  };

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.expandable;
  };

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    let flatNode =
      this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.content === node.content
        ? this.nestedNodeMap.get(node)!
        : new TodoItemFlatNode();
    flatNode.id = node.id;
    flatNode.content = node.content;
    flatNode.isUsed = this.data.listCheckBox.findIndex(item => item == node.id) > -1 ? true : false;
    flatNode.level = level;
    flatNode.expandable = node.childs?.length > 0 ? true : false;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /* Get the parent node of a node */
  public getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}
