import {Component, OnInit} from '@angular/core';
import {lang} from '../../school-years.lang';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {ListenFirebaseService, ShowMessage} from '@app/_services';
import {DialogFormSchoolYearsComponent} from '../../dialogs/dialog-form-school-years/dialog-form-school-years.component';
import {DialogListCustomersComponent} from '../../dialogs/dialog-list-customers/dialog-list-customers.component';
import {SchoolYearsManagerService} from '@app/_services/index';
import {DialogChangeStatusComponent} from '../../dialogs/dialog-change-status/dialog-change-status.component';
import * as moment from 'moment';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, Subscriber} from 'rxjs';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';
import { SCHOOL_YEAR_PERMISSION } from '@app/_modules/school-years-manager/constant';

@Component({
    selector: 'app-school-years-list',
    templateUrl: './school-years-list.component.html',
    styleUrls: ['./school-years-list.component.scss']
})
export class SchoolYearsListComponent implements OnInit {
    showSearch = true;
    stringSearch = '';
    lang = lang;
    permissionSchoolYear = SCHOOL_YEAR_PERMISSION;
    dataSource = [];
    typeModule = 'school_year';
    isLoading = false;
    statusSchoolYear = [
        {id: 0, name: 'Năm học đã qua', color: '#1355C1'},
        {id: 1, name: 'Năm học hiện tại', color: '#FF8038'},
        {id: 2, name: 'Năm học sắp tới', color: '#000000'},
    ];

    constructor(
        private dialog: MatDialog,
        private showMessage: ShowMessage,
        private schoolYearsManagerService: SchoolYearsManagerService,
        public db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService
    ) {
    }

    ngOnInit(): void {

        this.getDataSchoolYear('');
    }

    listenFireBase(action) {
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(action, this.typeModule, subscriber);
        });
        listenFb.subscribe((ref) => {
            if(ref === true) {
                this.isLoading = false;
                this.getDataSchoolYear(this.stringSearch);
            }else{
                this.isLoading = false;
            }
        });
    }


    getDataSchoolYear(keyword: string) {
        this.isLoading = true;
        this.schoolYearsManagerService.getDataSchoolYears(keyword).subscribe((res: any) => {
            this.dataSource = res.data;
            this.isLoading = false;
        });
    }

    openDialogListCustomer(infoSchoolYear) {
        this.dialog.open(DialogListCustomersComponent, {
            width: '60%',
            height: 'auto',
            data: {
                infoSchoolYear
            },
            autoFocus: false,
        });
    }


    keyPressSearch(event, value) {
        if (event.key === 'Enter' || event.key  === 'Tab') {
            this.stringSearch = value;
            this.getDataSchoolYear(value);
        }
    }

    openDialogDelete(infoSchoolYear) {
        const dialogRef = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: 'Bạn đang thực hiện xóa năm học',
                message: 'Bạn có chắc muốn xóa dữ liệu này không ?',
                btnOkText: 'Xóa',
                btnCancelText: 'Hủy',
                data: {
                    name: infoSchoolYear.fullname,
                },
            }
        });

        this.listenFireBase('delete');
        dialogRef.afterClosed().subscribe(confirm => {
            this.isLoading = true;
            if (confirm) {
                this.schoolYearsManagerService.removeSchoolYear(infoSchoolYear.id).subscribe((res: any) => {
                    this.isLoading = false;
                    if (res.status === 0)
                        this.showMessage.error(res.msg);
                }, err => this.isLoading = false);
            }
            else this.isLoading = false;
        });
        setTimeout(() => {
            if (this.isLoading) this.isLoading = false;
        }, TIME_OUT_LISTEN_FIREBASE);

    }

    openDialogCreateSchoolYear() {
        const dialogRef = this.dialog.open(DialogFormSchoolYearsComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'add',
                data: null,
            }
        });
      this.listenFireBase('create');
        setTimeout(() => {
            if (this.isLoading) this.isLoading = false;
        }, TIME_OUT_LISTEN_FIREBASE);

      dialogRef.afterClosed().subscribe(res => {
          if (!res){
            this.isLoading = false;
          }
        });
    }

    openDialogUpdateSchoolYear(data) {
        const dialogRef = this.dialog.open(DialogFormSchoolYearsComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'update',
                data,
            }
        });
        this.listenFireBase('update');
        setTimeout(() => {
            if (this.isLoading) this.isLoading = false;
        }, TIME_OUT_LISTEN_FIREBASE);

        dialogRef.afterClosed().subscribe(res => {
          if (!res){
            this.isLoading = false
          }
        });
    }

    changeStatusSchoolYear(event, infoSchoolYear: any) {
        const confirmActive = this.dialog.open(DialogChangeStatusComponent, {
            height: 'auto',
            width: '40%',
            data: {
                status: event.checked,
                infoSchoolYear
            }
        });
        confirmActive.afterClosed().subscribe((confirm: boolean) => {
            if (confirm) {
                this.isLoading = true;
                this.schoolYearsManagerService.changeStatus(infoSchoolYear.id, event.checked === true ? 1 : 0).subscribe((res: any) => {
                  if (res.status === 0) this.showMessage.error(res.msg);
                });
                this.listenFireBase('change_active_status');
                setTimeout(() => {
                    if (this.isLoading) this.isLoading = false;
                }, TIME_OUT_LISTEN_FIREBASE);
            } else {
                event.checked = !event.checked;
            }
        });
    }

    toggleClass(event) {
        this.showSearch = !this.showSearch;
        event.value = '';
        if (!this.showSearch && this.stringSearch !== '') {
            this.stringSearch = '';
            this.getDataSchoolYear(this.stringSearch);
        }
    }

}
