<div class="dialog-body">
    <div class="row">
        <div class="col-md-12 text-center title mb-3">
            Thêm khách hàng
        </div>
        <div style="width: 100%">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <div fxFlex="100" style="margin-left: 1%">
                        <input [autofocus]="true" #input_search (keydown)="keyPressSearch($event,input_search.value)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Tìm kiếm theo Tên khách hàng, Mã khách hàng, Tên miền, Số điện thoại hoặc Email ">
                    </div>
                </div>
                <img style="position: relative; left: 25px; bottom: 30px; cursor: pointer" src="assets/images/svg/search.svg" alt="">
                <div class="col-md-12">
                    <span class="font">
                                      <mat-checkbox (change)="checkAll()" [checked]="isCheckAll"> Chọn tất cả</mat-checkbox>
                               </span>
                    <span class="ml-3">
                        Đã chọn: {{countCustom}} khách hàng mới
                    </span>
                </div>
                <div class="custom-scroll col-md-12">
                    <table mat-table [dataSource]="dataSource" class="table-el">

                        <ng-container matColumnDef="stt">
                            <th style="width: 5%" mat-header-cell *matHeaderCellDef>
                                Chọn
                            </th>
                            <td mat-cell *matCellDef="let element, let i = index">
                                <mat-checkbox [checked]="element.isChecked" (change)="chooseInfo(i)"></mat-checkbox>
                            </td>

                        </ng-container>
                        <ng-container matColumnDef="fullName">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%">Khách hàng</th>
                            <td mat-cell *matCellDef="let item" class="text-important-table-global">
                                <a href="tenants-manager/detail/{{item?.id}}?active=tab1" target="_blank">
                                    {{item.fullname | substring: 30}}
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                            <td mat-cell *matCellDef="let item">{{item.code}}</td>
                        </ng-container>
                        <ng-container matColumnDef="domain">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
                            <td mat-cell *matCellDef="let item">
                                <a href="{{item.tenantUrl}}" target="_blank">
                                    {{item.tenantUrl | substring: 15}}
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                            <td mat-cell *matCellDef="let item">{{item.phone}}</td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                            <td mat-cell *matCellDef="let item">{{item.email}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                        <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
                    </table>
                    <p *ngIf="dataSource.length === 0">Không có dữ liệu</p>
                </div>
            </div>

            <p style="margin-top: 10px" fxLayout="row" fxLayoutAlign="center center">
                <button mat-dialog-close class="btn custom-btn-close">HỦY</button>&nbsp;&nbsp;
                <button class="btn custom-btn-add" (click)="onSubmit()">THÊM</button>
            </p>
        </div>

    </div>
</div>
<br>
<div class="show-loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>