import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FileElement } from './model/element';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
// import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NewFolderDialogComponent } from './modals/newFolderDialog/newFolderDialog.component';
import { RenameDialogComponent } from './modals/renameDialog/renameDialog.component';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Base64Service } from '@app/_services';
import { Observable, of, Subscriber } from 'rxjs';

@Component({
  selector: 'file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnChanges {
  constructor(
    public dialog: MatDialog,
    private base64: Base64Service,
    private sanitizer: DomSanitizer
    ) {}

  @Input() fileElements: FileElement[];
  @Input() canNavigateUp: string;
  @Input() path: string;

  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedUp = new EventEmitter();
  @Output() fileAdded = new EventEmitter<{ fileName: string, base64: string }>();
  @Output() fileChoose = new EventEmitter<FileElement>();

  urlImage: SafeResourceUrl;
  selectedFile: File
  fileName = '';
  convertImg = null;

  ngOnChanges(changes: SimpleChanges): void {}

  deleteElement(element: FileElement) {
    this.elementRemoved.emit(element);
  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    } else {
      this.fileChoose.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  moveElement(element: FileElement, moveTo: FileElement) {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  }

  openNewFolderDialog() {
    let dialogRef = this.dialog.open(NewFolderDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    });
  }

  openNewFileDialog() {
    let dialogRef = this.dialog.open(NewFolderDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    });
  }

  uploadImage() {
    document.getElementById('inputUploadFile').click();
  }

  myimage: Observable<any>;
  onFileChange($event) {
    const file = ($event.target as HTMLInputElement).files[0];
    this.selectedFile = file;
    this.fileName = this.selectedFile.name;

    let allowExtentionImage = ['png', 'jpg'];
    if (!allowExtentionImage.includes(this.getExstendsion(file.type))) {
      alert("Extension image wrong");
      return;
    }


    let reader = new FileReader();
    reader.readAsDataURL(file); // read file as data url
    reader.onload = (event: any) => { // called once readAsDataURL is completed
      this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
    };

    this.myimage = new Observable((subscriber: Subscriber<any>) => {
      this.base64.readFile(file, subscriber);
    });
    this.myimage.subscribe((resultBase64) => {
      this.convertImg = resultBase64;
      this.fileAdded.emit({ fileName: this.fileName, base64: this.convertImg });

    });
  }
  getExstendsion(image) {
    if (image.endsWith('jpg') || image.endsWith('jpeg')) {
      return 'jpg';
    }
    if (image.endsWith('png')) {
      return 'png';
    }
  }

  openRenameDialog(element: FileElement) {
    let dialogRef = this.dialog.open(RenameDialogComponent,{
      width: '250px',
      data: {name: element.name}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        element.name = res;
        this.elementRenamed.emit(element);
      }
    });
  }

  openMenu(event: MouseEvent, element: FileElement, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }
}
