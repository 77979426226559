import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UserPersonalService {
    constructor(private http: HttpClient) { }

    getPersonalUser(token: string) {
        return this.http.post(`${environment.coreApiUrl}/api/personal/detail`, {
            accessToken: token
        });
    }

    getAllManipulationHistory(size, index, itemId, search, startTime, endTime) {
        const token = JSON.parse(localStorage.getItem('currentUser'));
        index  = index + 1;
        return this.http.post(`${environment.coreApiUrl}/api/personal/all_manipulation_history`, {
            accessToken: token,
            startDateTime: startTime,
            endDateTime: endTime,
            keyword: search,
            pageIndex: index,
            pageSize: size
        });
    }

    checkPasswordOld(token: string, oldPassword: string) {
        return this.http.post(`${environment.coreApiUrl}/api/personal/check-password`, {
            accessToken: token,
            password: oldPassword
        });
    }

    changePassword(token: string, newPassword: string, rePassword: string) {
        return this.http.post(`${environment.coreApiUrl}/api/personal/change-password`, {
            accessToken: token,
            password: newPassword,
            confirmedPassword: rePassword
        });
    }

    updateProfileUser(data){
        return this.http.put(`${environment.coreApiUrl}/api/personal/update-personal`, data);
    }
}
