import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {GeneralConfigurationSystemComponent} from './components/general-configuration-system/general-configuration-system.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import {GENERAL_CONFIGURATIONS_PERMISSION} from './constants';
const route: Routes = [
    {
        path: '', 
        component: GeneralConfigurationSystemComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
        permissions: {
            only: [
                GENERAL_CONFIGURATIONS_PERMISSION.perm1, 
                GENERAL_CONFIGURATIONS_PERMISSION.perm2, 
                GENERAL_CONFIGURATIONS_PERMISSION.perm3,
                GENERAL_CONFIGURATIONS_PERMISSION.perm4,
                GENERAL_CONFIGURATIONS_PERMISSION.perm5,
                GENERAL_CONFIGURATIONS_PERMISSION.perm6,
                GENERAL_CONFIGURATIONS_PERMISSION.perm7,
                GENERAL_CONFIGURATIONS_PERMISSION.perm8,
                GENERAL_CONFIGURATIONS_PERMISSION.perm10
            ],
            redirectTo: '/accessdenied'
        }
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(route)],
    exports: [RouterModule],
})

export class GeneralConfigurationsRoutingModule {

}
