import { NavItem } from './../../../../_models/nav-item';
import { map } from 'rxjs/operators';
import { REGEX_CODE } from './../../../../_shared/utils/constant';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ShowMessage } from "@app/_services";
import { TIME_OUT_LISTEN_FIREBASE, MESSAGE_ERROR_CALL_API } from "@app/_shared/utils/constant";
import { FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
import { GeneralService } from '@app/_services/general.service';
import { Observable, Subscriber } from "rxjs";
import { ListenFirebaseService } from "@app/_services/listen-firebase.service";
@Component({
  selector: 'app-dialog-assgin-shool-year-curriculum',
  templateUrl: './dialog-assgin-shool-year-curriculum.component.html',
  styleUrls: ['./dialog-assgin-shool-year-curriculum.component.scss']
})
export class DialogAssginSchoolYearCurriculumComponent implements OnInit {
  isLoading: boolean = false;
  listSchoolYear = [];
  shoolYearId: string = ''
  infoCurriculum:any;
  
  constructor(
      private activatedRoute: ActivatedRoute,
      public dialogRef: MatDialogRef<DialogAssginSchoolYearCurriculumComponent>,
      private router: Router,
      private showMessage: ShowMessage,
      private curriculumManagerService: CurriculumManagerService,
      private listenFirebaseService: ListenFirebaseService,
      @Inject(MAT_DIALOG_DATA) public dataDialog,
    
  ) { }

  ngOnInit(): void { 
    if(this.dataDialog.nameForm == 'assign') {
      this.getListSchoolYear(); 
    } else {
      this.getListSchoolYearAssigned();
    }
  }

  chooseOption(event) {
    this.shoolYearId = event.target.value;
  }

  getListSchoolYear() {
    this.curriculumManagerService.getListShoolYearNotAssign(this.dataDialog.data.id).subscribe((res:any) => {
      if(res.status == 1) {
        this.listSchoolYear = res.data;
      }else {
        this.showMessage.error(res.msg);
      } 
    }, (err:any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  getListSchoolYearAssigned() {
    this.curriculumManagerService.getListShoolYearAssigned(this.dataDialog.data.id).subscribe((res:any) => {
      if(res.status == 1) {
        this.listSchoolYear = res.data;
        let index = this.listSchoolYear.findIndex(item => item.status == 1);
        this.listSchoolYear.splice(index, 1);
        // this.listSchoolYear = res.data;
      }else {
        this.showMessage.error(res.msg);
      } 
    }, (err:any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  onSubmit() {
      if(this.shoolYearId == 'null' || this.shoolYearId == '' || this.shoolYearId == null) {
        this.showMessage.warring('Vui lòng chọn năm học!');
        return;
      }
      let dataInput = {
        khungChuongTrinhId: this.dataDialog.data.id,
        selectedSchoolYearId: this.shoolYearId,
      };
      if(this.dataDialog.nameForm == 'assign') {
        this.checkFireBase('update');
        this.setTimeOutFireBase();
        this.curriculumManagerService.assginShoolYear(dataInput).subscribe((res: any) => {
          this.isLoading = false;
          if(res.status == 0) {
            this.dialogRef.close(false);
            this.showMessage.error(res.msg);
          }
        }, (err:any) => {
          this.isLoading = false;
          this.dialogRef.close(false);
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      } else {
        this.checkFireBase('update');
        this.setTimeOutFireBase();
        this.curriculumManagerService.removeSchoolYear(dataInput).subscribe((res: any) => {
          this.isLoading = false;
          if(res.status == 0) {
            this.dialogRef.close(false);
            this.showMessage.error(res.msg);
          }
        }, (err:any) => {
          this.isLoading = false;
          this.dialogRef.close(false);
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      }
      
    }

    checkFireBase(param){
      let listenFb = new Observable((subscriber: Subscriber<any>) => {
        this.listenFirebaseService.checkFireBase(param, 'khung_chuong_trinh', subscriber);
      });
      listenFb.subscribe((res) => {
        if (res === true) {
          this.isLoading = false;
          // this.showMessage.success(res.msg);
          this.dialogRef.close(true);  
        }
      });
    }
    setTimeOutFireBase() {
      setTimeout(() => {
        if(this.isLoading) {
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    }
}
