import { Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogFormAddEditSoftwareComponent} from '@app/_modules/module-software-manager/dialogs/dialog-form-add-edit-software/dialog-form-add-edit-software.component';
import {DialogAddCustomerComponent} from '../../dialogs/dialog-add-customer/dialog-add-customer.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ListenFirebaseService, ModuleSoftwareManagerService} from '@app/_services';
import {ShowMessage} from '@app/_services';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { MODULE_PERMISSION } from '@app/_modules/module-software-manager/constant';
import {NgxPermissionsService} from 'ngx-permissions';
@Component({
  selector: 'app-software-detail',
  templateUrl: './software-detail.component.html',
  styleUrls: ['./software-detail.component.scss']
})
export class SoftwareDetailComponent implements OnInit {
  showSearch = false;
  dataSearch = '';
  showFilter = false;
  dataSource = [];
  subSystemId: string;
  infoSubSystem: any;
  keyword = '';
  dataStatus = [];
  typeModule = "subsystem";
  isLoading = false;
  length;
  permissionModules = MODULE_PERMISSION;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'status'];
  statusUser = [
    {id: 0, name: 'Chưa kích hoạt', color: '#77B6D1', checked: false},
    {id: 1, name: 'Dùng thử', color: '#1355C1', checked: false},
    {id: 2, name: 'Đang sử dụng', color: '#FF8038', checked: false},
    {id: 3, name: 'Hết hạn sử dụng', color: '#CC4D4D', checked: false},
    {id: 4, name: 'Hết hạn dùng thử', color: '#6B6B6B', checked: false},
    {id: 99, name: 'Khác', color: '#8950FC', checked: false},
  ];

  constructor(
      private dialog: MatDialog,
      private activeRoute: ActivatedRoute,
      private moduleSoftwareManagerService: ModuleSoftwareManagerService,
      private router: Router,
      private showMessage: ShowMessage,
      private db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService,
      private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit(): void {
    if (this.permissionsService.getPermission(this.permissionModules.perm8)){
      this.displayedColumns.push('tools');
    }
    this.activeRoute.paramMap.subscribe((paramMap: any) => {
      this.subSystemId = paramMap.params.id;
      this.getInfoSubSystem();
    });
    this.loadDataItems();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  checkFireBase(param){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, 'subsystem', subscriber);
    });
    listenFb.subscribe((ref) => {
        if (ref === true) {
          this.isLoading = false;
          //this.getInfoSubSystem();
          this.loadDataItems();
        }
    });
  }
  listenFirebase(){
    this.checkFireBase('delete_tenants_to_subsystem');
    //this.checkFireBase('change_status');
    this.checkFireBase('update');
    this.checkFireBase('insert_tenants_to_subsystem');
}

  getInfoSubSystem(){
    this.moduleSoftwareManagerService.getSubSystemById(this.subSystemId).subscribe((res: any) => {
      if (res.status === 1) return  this.infoSubSystem = res.data;
      this.showMessage.error(res.msg);
      return this.router.navigate(['module-software-manager']);
    });
  }

  openDialogDelete(infoTenant) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xóa khách hàng ra khỏi phân phân hệ',
        message: 'Bạn có chắc muốn gỡ khách hàng này không ?',
        btnOkText: 'Xác nhận',
        btnCancelText: 'Hủy',
        data: {
          name: infoTenant.fullname +' - '+this.infoSubSystem.fullname,
        },
      },
    });
    
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm){
        this.isLoading = true;
        this.moduleSoftwareManagerService.removeTenant(this.subSystemId, infoTenant.id).subscribe((res: any) => {
          if(res.status == 0) {
            this.isLoading = false;
            this.showMessage.warring(res.msg);
          }}, (err:any) => {
            this.isLoading = false;
            this.showMessage.error(err.msg);
          });
          this.checkFireBase('delete_tenants_to_subsystem');
          setTimeout(() => {
            if(this.isLoading){
                this.isLoading = false;
            }
        }, TIME_OUT_LISTEN_FIREBASE);
        this.listenFirebase();
      }
    })
  }

  itemOptionClick(keyStatus){
    let dataStatus = [];
    this.statusUser[keyStatus].checked = !this.statusUser[keyStatus].checked;
    this.statusUser.forEach(item => {
      if (item.checked === true){
        dataStatus.push(item.id);
      }
    });

    return this.dataStatus = dataStatus;
  }

  getColorStatus(statusId){
    let key = this.statusUser.map(item => item.id).indexOf(statusId);
    return this.statusUser[key].color;
  }

  loadDataItems(){
    this.isLoading = true;
    let status = this.dataStatus.length === 0 ? null : this.dataStatus;
    this.moduleSoftwareManagerService.listTenantOfModule(this.subSystemId, this.dataSearch, status).subscribe((res: any) => {
      this.dataSource = res.data;
      this.isLoading = false;
    });
  }


  showFormEdit = (data: any) => {
     const dialogRef = this.dialog.open(DialogFormAddEditSoftwareComponent, {
      height: 'auto',
      width: '60%',
      data: {
        data,
        action: 'edit'
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      console.log(res)
      this.getInfoSubSystem();
      //if (res.status === 0) return this.showMessage.error(res.msg); 
    });
    this.checkFireBase('update');
    setTimeout(() => {
        if(this.isLoading){
            this.isLoading = false;
        }
    }, TIME_OUT_LISTEN_FIREBASE);  
    this.listenFirebase();
    }

  showFilterClick(){
    this.showFilter = !this.showFilter;
    if (!this.showFilter && this.dataStatus.length !== 0){
      this.statusUser.forEach(item => {
        item.checked = false;
      });
      this.dataStatus = [];
      this.loadDataItems();
    }
  }

  blurSearch(event){
    this.keyword = event.target.value.trim();
    this.loadDataItems();
  }

  keyPressSearch(event, value){
    if (event.keyCode  === 13 || event.keyCode  === 9){
        this.dataSearch = value.trim();
        this.loadDataItems();
    }
}

  clickFilterStatus(){
    this.loadDataItems();
  }

  dialogAddTenant(){
    const dialogRef = this.dialog.open(DialogAddCustomerComponent, {
      height: 'auto',
      width: '70%',
      data: {
        subSystemId: this.subSystemId,
        subSystemName: this.infoSubSystem.fullname
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.checkFireBase('insert_tenants_to_subsystem');
      setTimeout(() => {
        if(this.isLoading){
            this.isLoading = false;
        }
    }, TIME_OUT_LISTEN_FIREBASE);  
    this.listenFirebase();
    });
  }

  toggleClass() {
    (document.getElementById('search__input') as HTMLInputElement).value = "";
    this.showSearch = !this.showSearch;
}
}

