export const lang = {
    title_add: 'Thêm khối học',
    title_edit: 'Chỉnh sửa khối học',
    label: {
        title_info: 'Thông tin cơ bản',
        name_grade: 'Tên môn hoc',
        grade_training: 'Cấp đào tạo',
        index_order: 'Thứ tự sắp xếp',
        code: 'Mã khối học',
        training_system: 'Hệ đào tạo',
        describe: 'Mô tả',
        status: 'Trạng thái',
        active: 'Kích hoạt'
    },
    button: {
        button_add: 'Thêm',
        button_edit: 'Lưu',
        button_close: 'Hủy',
    },

    message_validate: {
        full_name_required: 'Bạn chưa nhập tên khối',
        full_name_limit_chart: 'Phải ít nhất là 2 ký tự và không vượt quá 250 ký tự (Không có ký tự đặc biệt)',
        code_required: 'Bạn chưa nhập mã khối',
        code_limit_chart: 'Phải ít nhất 2 ký tự và không vượt quá 50 ký tự',
    }
}
