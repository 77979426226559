import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {element} from 'protractor';
import {log} from 'util';
import {Observable, Subscriber} from 'rxjs';
import {ConfirmComponent} from '@app/_shared/dialogs/confirm/confirm.component';
import * as _lodash from 'lodash';
@Component({
  selector: 'app-dialog-subject-add-customers',
  templateUrl: './dialog-subject-add-customers.component.html',
  styleUrls: ['./dialog-subject-add-customers.component.scss']
})
export class DialogSubjectAddCustomersComponent implements OnInit {
  dataColumn = ['stt', 'customer', 'code', 'link', 'phone', 'email'];
  // dataSource: any;
  allComplete: boolean = false;
  checkStatus: boolean;
  length: any = 0;
  data: any;
  isLoading = false;
  dataFlag = [];

  constructor(
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public value,
      private subjectService: SubjectService,
      private dialogRef: MatDialogRef<DialogSubjectAddCustomersComponent>,
      private listenFirebaseService: ListenFirebaseService,
      private showMessage: ShowMessage) { }

  ngOnInit(): void {
    this.listCustomerNot();
  }
  listCustomerNot(keyWord?: string){
    this.subjectService.getListCustomerNotSubject(this.value.id, keyWord).subscribe((ref: any) => {
      console.log(11111);
      this.data = ref.data;
      this.data.forEach(item => {
        if ( _lodash.indexOf(this.dataFlag, item.id) !== -1){
          item.checked = 1;
        }
        else{
          item.checked = 0;
        }
      });
      this.allComplete = this.data != null && this.data.length > 0 && this.data.every(t => t.checked === 1);
      this.length = this.data.filter(x => x.checked === 1).length;
      console.log(this.allComplete, this.length);
      this.isLoading = false;
    });
  }
  checkText(event, value) {
    // event.keyCode === 13 ? (document.getElementById('search-value-dialog') as HTMLInputElement).blur() : '';
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.isLoading = true;
      this.listCustomerNot(value);
    }
  }
  checkAllModule(event){
    if (this.data == null) {
      return;
    }
    this.allComplete = event.checked;
    if (event.checked){
      this.data.forEach(t => {
        t.checked = 1;
        this.dataFlag.push(t.id);
      });
    }
    else{
      this.data.forEach(t => {
        t.checked = 0;
        let i = _lodash.indexOf(this.dataFlag, t.id);
        this.dataFlag.splice(i, 1);
      });
    }
    this.length = this.data.filter(x => x.checked === 1).length;
  }
  updateAllComplete(event, itemId: string) {
    if (event.checked){
      this.data.find(x => x.id === itemId).checked = 1;
      this.dataFlag.push(itemId);
    }
    else{
      this.data.find(x => x.id === itemId).checked = 0;
      let i = _lodash.indexOf(this.dataFlag, itemId);
      this.dataFlag.splice(i, 1);
    }
    this.allComplete = this.data != null && this.data.length > 0 && this.data.every(t => t.checked === 1);
    this.length = this.data.filter(x => x.checked === 1).length;
  }

  addCustomerBySubject() {
    if ((this.data.filter(x => x.checked === 1)).length === 0){
      this.dialogRef.close(false);
      return;
    }
    else{
      const dialogDelete = this.dialog.open(ConfirmComponent, {
        height: 'auto',
        width: '36%',
        data: {
          title: 'Bạn đã thực hiện thêm khách hàng áp dụng môn học',
          name: this.value.name,
          message: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
          btnCancel: 'HỦY',
          btnConfirm: 'XÁC NHẬN',
          color: 'red'
        },
      });
      dialogDelete.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.isLoading = true;
          let tenantArray = [];
          this.data.filter(x => x.checked === 1).forEach(item => tenantArray.push(item.id));
          this.subjectService.addCustomerBySubject(this.value.id, tenantArray).subscribe((ref: any) => {
            if (ref.status === 1){
            }
            else{
              this.showMessage.error(ref.msg);
              this.isLoading = false;
              return this.dialogRef.close(true);
            }
          });
          setTimeout(() => {
            if (this.isLoading){
              this.isLoading = false;
              this.dialogRef.close(true);
            }
          }, 10000);
          this.checkFireBaseModule();
        }
      });
    }
    }
  checkFireBaseModule(){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('create', 'subject_tenant', subscriber);
    });
    listenFb.subscribe((refg) => {
      if (refg === true) {
        this.isLoading = false;
        return this.dialogRef.close(true);
      }
    });
  }
}
