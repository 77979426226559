export const SCHOOL_YEAR_PERMISSION = {
    perm1: 'core_admin_school_years',
    perm2: 'core_admin_school_year_add',
    perm3: 'core_admin_school_year_detail',
    perm4: 'core_admin_school_year_edit',
    perm5: 'core_admin_school_year_delete',
    perm6: 'core_admin_school_year_tenants',
    perm7: 'core_admin_school_year_tenant_add',
    perm8: 'core_admin_school_year_tenant_delete',
};
