import {Component, Renderer2, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogFormAccountComponent} from '../../dialogs/dialog-from-account/dialog-form-account.component';
import {AccountManagerService} from '../../../../_services/account-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {ListenFirebaseService, ShowMessage} from '@app/_services';
import {DialogListRolesComponent} from '../../dialogs/dialog-list-roles/dialog-list-roles.component';
import {DialogListCustomersComponent} from '../../dialogs/dialog-list-customers/dialog-list-customers.component';
import {DialogResetPasswordsComponent} from '../../dialogs/dialog-reset-passwords/dialog-reset-passwords.component';
import {DialogImportUsersComponent} from '../../dialogs/dialog-import-users/dialog-import-users.component';
import {DialogChangeStatusComponent} from '../../dialogs/dialog-change-status/dialog-change-status.component';
import * as $ from 'jquery';
import * as _moment from 'moment';
import {FormControl} from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, Subscriber} from 'rxjs';
import * as XLSX from 'xlsx';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';
import { USER_PERMISSION } from "../../constants";
import { NgxPermissionsService } from 'ngx-permissions';

type AOA = any[][];

const moment = _moment;

@Component({
    selector: 'app-accounts-manager-list',
    templateUrl: './accounts-manager-list.component.html',
    styleUrls: ['./accounts-manager-list.component.scss']
})
export class AccountsManagerListComponent implements OnInit {
    displayedColumns: string[] = ['stt', 'fullname', 'account', 'phone', 'email', 'decentralization', 'customer', 'status', 'log_data'];
    dataSource: any;
    currentPage: number;
    length: number;
    showSearch: boolean = true;
    dataSearch: string = '';
    showFilter: boolean = false;
    roles: any;
    isImportUser = false;
    dataImport = [];
    selectable = true;
    button = {
        is_active_import_user: false,
        is_active_export_user: false,
    };
    isLoading = false;
    pageSize = 15;
    pageIndex = 0;
    totalRecord = 0;
    dataFilterStatus = [];
    dataFilterRoles = [];
    statusUser = [
        {id: 0, name: 'Chưa kích hoạt', color: '#FF8038'},
        {id: 1, name: 'Kích hoạt', color: '#1355C1'},
        {id: 2, name: 'Bị khóa', color: '#283C63'},
        {id: 3, name: 'Bị xóa', color: '#C4C4C4'},
    ]
    typeModule = 'user';
    dataExport: AOA;
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    fileName: string = 'Danh-sach-nguoi-dung.xlsx';
    USER_PERMISSION = USER_PERMISSION;

    statusUserControls = new FormControl();
    roleUserControls = new FormControl();

    constructor(
        public dialog: MatDialog,
        private accountManagerService: AccountManagerService,
        private _snackBar: MatSnackBar,
        private showMessage: ShowMessage,
        private renderer: Renderer2,
        public db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService,
        private permissionsService: NgxPermissionsService
    ) {
    }

    ngOnInit(): void {
        this.loadDataItems();
        this.getAllRoles();
        $("#leader-line-defs").remove();

        if (this.permissionsService.getPermission(this.USER_PERMISSION.perm4) 
        || this.permissionsService.getPermission(this.USER_PERMISSION.perm9)
        || this.permissionsService.getPermission(this.USER_PERMISSION.perm13)
        || this.permissionsService.getPermission(this.USER_PERMISSION.perm10)
        || this.permissionsService.getPermission(this.USER_PERMISSION.perm8)
        || this.permissionsService.getPermission(this.USER_PERMISSION.perm5)
        ){
          this.displayedColumns.push('tools');
        }
    }

    loadDataItems() {
        this.isLoading = true;
        return this.accountManagerService.getAllDataUser(this.pageSize, this.pageIndex, this.dataSearch, this.dataFilterRoles, this.dataFilterStatus).subscribe((ref: any) => {
            ref.data.data.forEach(t => {
                t.lastLogedin = moment(t.lastLogedin).format('h:mm A - DD/MM/YYYY');
                t.statusIndex = this.statusUser.findIndex(userEle => userEle.id === t.userStatus);
            });
            this.dataSource = ref.data.data;
            this.totalRecord = ref.data.totalItems;
            this.exportDataTable();
            this.isLoading = false
        });
    }

    listenFireBase(action){
        this.setTimOutFireBase();
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(action, this.typeModule, subscriber);
        });
        listenFb.subscribe((ref) => {
            if (ref === true) {
                this.isLoading = false;
                this.loadDataItems();
            }else{
                this.isLoading = false;
            }
        });
    }

    setTimOutFireBase(){
        setTimeout(() => {
            if (this.isLoading) this.isLoading = false;
        }, TIME_OUT_LISTEN_FIREBASE);
    }

    onChangePaginate(event){
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.loadDataItems();
    }

    getDataStatus(){
        this.dataFilterStatus = this.statusUserControls.value;
        this.dataFilterRoles = this.roleUserControls.value;
    }

    showFilterClick(){
        this.showFilter = !this.showFilter;
        if (!this.showFilter){
            this.statusUserControls.setValue([]);
            this.roleUserControls.setValue([]);
            if ((this.dataFilterStatus.length > 0 || this.dataFilterRoles.length > 0)){
                this.dataFilterStatus = [];
                this.dataFilterRoles = [];
                this.loadDataItems();
            }
        }
    }

    toggleClass(){
        this.showSearch = !this.showSearch;
        if(!this.showSearch) {
            (document.getElementById('search-value') as HTMLInputElement).value = '';
            if(this.dataSearch !== ''){
                this.dataSearch = '';
                this.loadDataItems();
            }
        }
    }

    checkText(event, value){
        if (event.key === 'Enter' || event.key === 'Tab') {
            this.dataSearch = value
            this.getDataStatus();
            this.pageIndex = 0
            this.loadDataItems();
        }
    }
    getAllRoles(){
        this.accountManagerService.getAllRole().subscribe((ref: any) => {
            this.roles = ref.data.data;
        });
    }
    removeUserFilter(topping, key) {
        const toppings = this.statusUserControls.value as string[];
        const index = toppings.indexOf(topping);
        if (index !== -1) {
            toppings.splice(index, 1);
        }
        let data = this.statusUserControls.value // To trigger change detection
        data.slice(key, 1);
        console.log(this.statusUserControls.setValue(data));
    }

    removeRoleFilter(topping: string) {
        const toppings = this.roleUserControls.value as string[];
        const index = toppings.indexOf(topping);
        if (index !== -1) {
            toppings.splice(index, 1);
        }
        this.roleUserControls.setValue(toppings); // To trigger change detection
    }

    filter(keyword){
        this.getDataStatus();
        this.pageIndex = 0;
        this.dataSearch = keyword.value;
        this.loadDataItems();
    }

    exportDataTable(){
        this.dataExport = [['STT', 'Tên người dùng', 'Tài khoản', 'SĐT', 'Email', 'Phân quyền', 'Khách hàng', 'Trang thái', 'Dữ liệu đăng nhập']];
        this.dataSource.forEach((item, index) => {
            let data = [];
            data.push(index+1);
            data.push(item.fullName);
            data.push(item.userName);
            data.push(item.phone);
            data.push(item.email);
            data.push(item.numberRole);
            data.push(item.numberTenant);
            data.push(this.statusUser[item.status]?.name)
            data.push(item.loginData);
            this.dataExport.push(data);
        });

    }

    exportExcel(){
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExport);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, this.fileName);
    }

    openDialogImportUser(){
        this.button.is_active_import_user = true;
        const dialogRef = this.dialog.open(DialogImportUsersComponent, {
            height: 'auto',
            width: '40%',
        });

        this.listenFireBase('import');
        dialogRef.afterClosed().subscribe(res => {
            if (res){
                this.dataImport = res;
                this.isImportUser = true;
            }
            this.button.is_active_import_user = false;
        });
    }

    openDialogDelete(infoUser: any) {
        const dialogRef = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: 'Bạn đang thực hiện xóa người dùng',
                message: 'Bạn có chắc muốn xóa người dùng này không ?',
                btnOkText: 'XÓA',
                btnCancelText: 'HỦY',
                data: {
                    name: `${infoUser.userName} (${infoUser.userCode})`
                },
                html: `<img src="${infoUser.avatar}" alt="Ảnh đại diện">`
            }
        });

        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.listenFireBase('delete');
                this.accountManagerService.deleteUser(infoUser.id).subscribe((res: any) => {
                    if (res.status === 0) return this.showMessage.error(res.msg);
                }, err => this.isLoading = false);
                this.pageIndex = 0;
            }
        });
    }

    getCloseImport(confirm){
        this.button.is_active_export_user = false;
        this.button.is_active_import_user = false;
        this.isImportUser = confirm;
        this.loadDataItems();
    }

    openDialogResetPassword(user){
        this.accountManagerService.getIdByUser(user.id).subscribe((ref: any) => {
            if (ref.status === 1) {
                const dialogRef = this.dialog.open(DialogResetPasswordsComponent, {
                    width: '40%',
                    height: 'auto',
                    data: {
                        infoUser: ref.data
                    },
                });


                this.pageIndex = 0;
                this.listenFireBase('reset_password');
                dialogRef.afterClosed().subscribe(res => {
                    if (!res) this.isLoading = false;
                })

            }
        });
    }

    openDialogAddAccount() {
        this.listenFireBase('create');
        const dialogRef = this.dialog.open(DialogFormAccountComponent, {
            data: {
                status: 'add',
                infoUser: null,
            },
            width: '70%',
            height: 'auto',
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(res => {
            this.isLoading = false;
        })
    }

    openDialogUpdateAccount(data: any) {
         this.accountManagerService.getIdByUser(data.id).subscribe((ref: any) => {
            if (ref.status === 1){
                this.listenFireBase('update');
                const dialogRef = this.dialog.open(DialogFormAccountComponent, {
                    width: '70%',
                    height: 'auto',
                    data: {
                        status: 'update',
                        infoUser: ref.data,
                        code: ''
                    },
                    autoFocus: false
                });

                this.setTimOutFireBase();
                dialogRef.afterClosed().subscribe(res => {
                    this.isLoading = false;
                });
            }

        });
    }

    dialogOpenListRole(infoUser){
        return this.dialog.open(DialogListRolesComponent, {
            width: '50%',
            height: 'auto',
            data: {
                infoUser: infoUser
            },
            autoFocus: false
        });
    }

    dialogOpenListCustomer(infoUser){
        this.accountManagerService.getIdByUser(infoUser.id).subscribe((ref: any) => {
            if (ref.status === 1) {
                return this.dialog.open(DialogListCustomersComponent, {
                    width: '70%',
                    height: 'auto',
                   data: {
                       infoUser,
                   },
                    autoFocus: false
                });
            }
        });
    }

    dialogOpenChangeStatus(infoUser, element, event){
        const keyStatus = element.value;
        const dialogRef = this.dialog.open(DialogChangeStatusComponent, {
            width: '40%',
            height: 'auto',
            data:
                {
                    user: infoUser,
                    newStatus : this.statusUser[keyStatus]
                }
        });
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm){
                this.isLoading = true;
                this.listenFireBase("change_status");
                $(element).css('background-color', this.statusUser[keyStatus].color);
                this.accountManagerService.changeStatusUser(infoUser.id, keyStatus).subscribe((res: any) => {
                    if (res.status === 0){
                        this.showMessage.error(res.msg);
                        this.isLoading = false;
                    }
                }, err => this.isLoading = false);
            }
            else{
                event.target.options.selectedIndex = infoUser.status;
            }
        });
    }

}
