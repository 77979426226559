import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndustryUnitService } from '@app/_services/industry-unit.service';

@Component({
  selector: 'app-dialog-move-education',
  templateUrl: './dialog-move-education.component.html',
  styleUrls: ['./dialog-move-education.component.scss']
})
export class DialogMoveEducationComponent implements OnInit {
  data_move_education:any;
  listEducationLv1:any;
  moveCode:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRes: MatDialogRef<DialogMoveEducationComponent>,
    private industryUnitService:IndustryUnitService
  ) { }

  ngOnInit(): void {
    this.getDataEducationLv1();
    this.data_move_education = this.data;
  }

  selectOption(value:any){
    this.moveCode = value;
  }

  confirm(){
    let data = {
      id: this.data_move_education.data.id,
      soId: this.moveCode,
      phongId: null,
      unit: this.data_move_education.data.unit
    }
    this.industryUnitService.moveEducation(data).subscribe(
      (res:any)=>{
        if(res.status == 1){
          this.dialogRes.close(true);
        }else{
          this.dialogRes.close(false);
        }
      },
      (err)=>{
        this.dialogRes.close(false);
      }
    )
  }

  getDataEducationLv1(){
    this.industryUnitService.getListEducationLv1().subscribe(
      (res:any)=>{
        this.listEducationLv1 = res.data;
        this.moveCode = res.data[0].code
      },
      (err)=>{

      }
    )
  }

}
