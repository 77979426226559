import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-dialog-change-active',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {title:string, name: string, message: string, btnCancel: string, btnConfirm: string, color?: string},
              private dialogRef: MatDialogRef<ConfirmComponent>
  ) { }

  ngOnInit(): void {
  }
  confirm(){
    this.dialogRef.close(true);
  }

}
