<div class="custom-scroll">
    <table mat-table [dataSource]="dataTenant" class="table-el">
        <ng-container matColumnDef="stt">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;">STT</th>
            <td mat-cell *matCellDef="let item; let i = index">{{ i+1 }}</td>
        </ng-container>
        <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef style="width: 25%;">Tên khách hàng</th>
            <td mat-cell *matCellDef="let item">
                <a class="text-link" [routerLink]="['/tenants-manager/detail/',item.id]" [queryParams]="{ active: 'tab1'}">{{ item.tenantFullname }}</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Mã</th>
            <td mat-cell *matCellDef="let item">{{ item.tenantCode }}</td>
        </ng-container>
        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef style="width: 15%;">Tên miền</th>
            <td mat-cell *matCellDef="let item">
                <a class="text-link" href="{{ item.tenantUrl }}" target="_blank" rel="noopener noreferrer">{{ item.tenantUrl }}</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;">SĐT</th>
            <td mat-cell *matCellDef="let item">{{ item.tenantHotline }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef style="width: 15%;">Email</th>
            <td mat-cell *matCellDef="let item">{{ item.tenantEmail }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Trạng thái</th>
            <td mat-cell *matCellDef="let item">
                <button [disabled]="true" class="form-control input-field-global option-{{item.tenantStatus}}">
                {{ item.tenantStatus == 0 ? 'Chưa kích hoạt' : ''}}
                {{ item.tenantStatus == 1 ? 'Dùng thử' : ''}}
                {{ item.tenantStatus == 2 ? 'Đang sử dụng' : ''}}
                {{ item.tenantStatus == 3 ? 'Hết hạn sử dụng' : ''}}
                {{ item.tenantStatus == 4 ? 'Hết hạn dùng thử' : ''}}
                {{ item.tenantStatus == 99 ? 'Khác' : ''}}
            </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="action_table" style="width: 10%;">Công cụ</th>
            <td mat-cell *matCellDef="let item" class="action_table_td">
                <button mat-icon-button (click)="clickDeleteCustomer(item,idGradeCustomer);">
                <img src="assets/images/svg/remove-icon-line.svg" alt="">
            </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
    </table>
</div>