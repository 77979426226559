import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertNameTrainingLevelPipe'
})
export class ConvertNameTrainingLevelPipe implements PipeTransform {
  transform(level: any): string {
        if(level == 1){
            return "Mầm non";
        }else if(level == 2){
            return "Tiểu học";
        }else if(level == 3){
            return "Trung học cơ sở";
        }else if(level == 4){
            return "Trung học phổ thông";
        }else{
            return "Khác";
        }
  }
}

