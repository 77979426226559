import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';

import { DialogFormEducationLv1Component } from '../../dialog/dialog-form-education-lv1/dialog-form-education-lv1.component';
import { DialogFormEducationLv2Component } from '../../dialog/dialog-form-education-lv2/dialog-form-education-lv2.component';
import { DialogFormEducationLv3Component } from '../../dialog/dialog-form-education-lv3/dialog-form-education-lv3.component';
import { DialogImportIndustryComponent } from '../../dialog/dialog-import-industry/dialog-import-industry.component';

import { IndustryUnitService } from '@app/_services/industry-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';

import { DialogMoveEducationComponent } from '../../dialog/move-education/dialog-move-education.component';
import { DialogMoveEducationLv2Component } from '../../dialog/move-education-lv2/dialog-move-education-lv2.component';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {ListenFirebaseService} from '@app/_services';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ADMIN_MOET_UNIT_PERMISSION} from '../../constants';

// import leader line
import 'leader-line';
import { forEachChild } from 'typescript';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
// declare let LeaderLine: any;
declare var LeaderLine: any;

interface FoodNode {
  name: string;
  code?: string;
  parent_code?: string;
  children?: FoodNode[];
}
interface FoodNode1 {
  name: string;
  parent_code_lv1?: string;
  parent_code?: string;
  children?: FoodNode[];
}


@Component({
  selector: 'app-education-lv1',
  templateUrl: './education-lv1.component.html',
  styleUrls: ['./education-lv1.component.scss']
})
export class EducationLv1Component implements OnInit {
  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  treeControl2 = new NestedTreeControl<FoodNode1>(node2 => node2.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  dataSource2 = new MatTreeNestedDataSource<FoodNode1>();
  ADMIN_MOET_UNIT_PERMISSION= ADMIN_MOET_UNIT_PERMISSION;
  constructor(
    public dialog: MatDialog,
    private industryUnitService:IndustryUnitService,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService,
    @Inject(DOCUMENT) private document
  ){}
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
  hasChild2 = (_: number, node2: FoodNode1) => !!node2.children && node2.children.length > 0;
  code_active = '';
  code_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  show_level_2 = false;
  show_level_3 = false;
  showSearch: boolean = false;
  dataSearch: string = '';
  isLoading = false;
  fileName:string;

  button = {
    is_active_import_user: false,
    is_active_export_user: false,
  }
  dataImport = [];
  isImportIndustry = false;
  currentPage: number;
  isLoadDataResult = false;
  dataEducation1:any;
  resultSearch:any;
  checkResultSearch:boolean = false;
  linkDetailEducation:string;
  lineLv2 = [];
  lineLv3 = [];
  checkOpenPGD:boolean = false;
  checkOpenTHPT:boolean = false;
  checkOpenTHCS:boolean = false;
  checkOpenTH:boolean = false;
  checkOpenMG:boolean = false;
  countPGD:number = 0;
  countTHPT:number = 0;
  countTHCS:number = 0;
  countTH:number = 0;
  countMG:number = 0;
  dataPGD = [];
  dataTHPT = [];
  dataTHCS = [];
  dataTH = [];
  dataMG = [];
  checkClickLv1:boolean = false;
  checkClickLv2:boolean = false;
  parent_lv2:string='';
  parent_lv3:string='';
  nameLv1 = '';
  nameLv2 = '';

  ngOnInit(){
    this.getDataEducation1();
  }

  openLv2(code,name){
    this.nameLv1 = `[${name}]`;
    this.nameLv2 = '';
    this.isLoading = true;
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = this.lineLv3 = [];
    this.code_active = code;
    this.checkClickLv1 = true;
    this.checkClickLv2 = false;
    this.checkOpenPGD = false;
    this.checkOpenTHPT = false;
    this.parent_lv2 = code;
    
    // setTimeout(() => {
    //   this.lineLv2 = [
    //     new LeaderLine(
    //       document.getElementById(code),
    //       document.getElementById('content-pgd'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(code),
    //       document.getElementById('content-thpt'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ]
    //   if(this.lineLv2){
    //     this.lineLv2?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res:any)=>{
        let count_phong_gd:number = 0;
        let count_thpt:number = 0;
        let code_parent:string;
        this.dataPGD = [];
        this.dataTHPT = [];
        (res.data).forEach((e:any)=>{
          code_parent = e.soGD;
          if(e.unit == 2){
            count_phong_gd++;
            this.dataPGD.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else{
            count_thpt++;
            this.dataTHPT.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }
        });
        this.countPGD = count_phong_gd;
        this.countTHPT = count_thpt;
        this.isLoading = false;
      },
      (err)=>{
        this.isLoading = false;
      }
    )
  }

  openLv3(code2,name){
    this.isLoading = true;
    this.nameLv2 = `[${name}]`;
    // if(this.lineLv3){
    //   this.lineLv3?.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(code2),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(code2),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(code2),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ]
    // }, 50);
    this.industryUnitService.getListEducationLv3(code2).subscribe(
      (res:any)=>{
        this.code_active_lv_2 = code2;
        let count_thcs:number = 0;
        let count_tieu_hoc:number = 0;
        let count_mau_giao:number = 0;
        let code_parent:string;
        let code_parent_lv1:string;
        this.checkClickLv2 = true;
        this.dataTHCS = [];
        this.dataTH = [];
        this.dataMG = [];
        this.parent_lv3 = code2;
        (res.data).forEach((e:any)=>{
          code_parent = e.phongGD;
          code_parent_lv1 = e.soGD;
          if(e.unit == 4){
            count_thcs++;
            this.dataTHCS.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else if(e.unit == 5){
            count_tieu_hoc++;
            this.dataTH.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else{
            count_mau_giao++;
            this.dataMG.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            })
          }
          this.isLoading = false;
        });
        this.countTHCS = count_thcs;
        this.countTH = count_tieu_hoc;
        this.countMG = count_mau_giao;
      },
      (err)=>{
        this.isLoading = false;
      }
    )
  }

  schowPGD(parent_code){
    this.removeLineLeader();
    this.checkClickLv2 = false;
    this.checkOpenPGD = !this.checkOpenPGD;
    // if(this.lineLv2){
    //   this.lineLv2.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv2 = [];
    // setTimeout(() => {
    //   this.lineLv2 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-pgd'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thpt'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ]
    //   if(this.lineLv2){
    //     this.lineLv2?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
  }

  schowTHPT(parent_code){
    this.checkOpenTHPT = !this.checkOpenTHPT;
    // if(this.lineLv2){
    //   this.lineLv2.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv2 = [];
    // setTimeout(() => {
    //   this.lineLv2 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-pgd'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thpt'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ]
    //   if(this.lineLv2){
    //     this.lineLv2?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
  }

  schowTHCS(parent_code){
    this.checkOpenTHCS = !this.checkOpenTHCS;
    // if(this.lineLv3){
    //   this.lineLv3.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ];
    //   if(this.lineLv3){
    //     this.lineLv3?.forEach(e=>{
    //       e?.position();
    //     })
    //   }
    // }, 50);
  }

  schowTH(parent_code){
    this.checkOpenTH = !this.checkOpenTH;
    // if(this.lineLv3){
    //   this.lineLv3.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ]
    // }, 50);
  }

  schowMG(parent_code){
    this.checkOpenMG = !this.checkOpenMG;
    // if(this.lineLv3){
    //   this.lineLv3.forEach(e=>{
    //     e?.remove();
    //   })
    // }
    // this.lineLv3 = [];
    // setTimeout(() => {
    //   this.lineLv3 = [
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-thcs'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-th'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     ),
    //     new LeaderLine(
    //       document.getElementById(parent_code),
    //       document.getElementById('content-mg'),
    //       {color: 'black',size:1,path:'grid',endPlug:'behind'}
    //     )
    //   ]
    // }, 50);
  }

  removeLineLeader(){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = this.lineLv3 = [];
  }

  typeModule = 'moet_unit';

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataEducation1();
        if (param === 'delete') {
          this.activeLv1(this.code_active);
          this.activeLv2(this.code_active_lv_2);
        }
        this.isLoading = false;
      }
      this.isLoading = false;
    });
  }

  getDataEducation1(){
    this.isLoading = true;
    this.industryUnitService.getListEducationLv1().subscribe(
      (res:any)=>{
        this.isLoading = false;
        this.dataEducation1 = res.data;
      },
      (err)=>{
        this.isLoading = false;
      }
    )
  }

  // click level 1
  activeLv1(code:string){
    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res:any)=>{
        let count_phong_gd:number = 0;
        let count_thpt:number = 0;
        let code_parent:string;
        let childs_phong_gd = [];
        let childs_thpt = [];
        (res.data).forEach((e:any)=>{
          code_parent = e.soGD;
          if(e.unit == 2){
            count_phong_gd++;
            childs_phong_gd.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else{
            count_thpt++;
            childs_thpt.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }
        });
        let resultEducationLv2 = [
          {
            name: `${count_phong_gd} Phòng giáo dục`,
            parent_code: `${code_parent}`,
            children: childs_phong_gd
          }, 
          {
            name: `${count_thpt} Trường THPT`,
            parent_code: `${code_parent}`,
            children: childs_thpt
          }
        ];
        this.dataSource.data = resultEducationLv2;
        
        this.check_child_lv_1 = this.checkDataLv1(resultEducationLv2,code);
        this.code_active = code;
        this.closeTree(code);
        this.close_lv_2 = true;
      },
      (err)=>{
      }
    )

    // setTimeout(() => {
    //   new LeaderLine(
    //     document.getElementById("11111"),
    //     document.getElementById("22222"),
    //     {
    //       color: 'black',
    //       path:'grid',
    //       size:1
    //     }
    //   );
    //   new LeaderLine(
    //     document.getElementById("11111"),
    //     document.getElementById("222223"),
    //     {
    //       color: 'black',
    //       path:'grid',
    //       size:1
    //     }
    //   );
    // }, 50);
  }
  // click level 2
  activeLv2(code2:string){
    
  }
  // click open close level 2
  closeTree(code:string){
    if(this.code_active == code){
      this.close_lv_2 = !this.close_lv_2;
    }else{
      this.activeLv1(code);
    }
  }
  // click open close level 3
  closeTree2(code:string,code_lv1?:string){
    if(this.code_active_lv_2 == code){
      this.close_lv_3 = !this.close_lv_3;
    }else{
      this.activeLv2(code);
      this.closeTree(code_lv1);
    }
  }

  checkDataLv1(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.parent_code == code){
        check = true;
      }
    });
    return check;
  }

  checkDataLv2(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.parent_code == code){
        check = true;
      }
    });
    return check;
  }

  toggleClass(value){
    this.dataSearch = '';
    this.showSearch = !this.showSearch;
    this.checkResultSearch = false;
  }

  clickCreateEducationLv1(){
    const dialogRef = this.dialog.open(DialogFormEducationLv1Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"add"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('create');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickEditEducationLv1(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv1Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickEditEducationLv2(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickEditEducationLv3(){
    const dialogRef = this.dialog.open(DialogFormEducationLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }
  clickDeleteEducation(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa khách hàng',
        message:'Xác nhận xóa?',
        btnOkText:'Xóa',
        btnCancelText:'Hủy',
        data: {
          id:data.id,
          name:data.name
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.industryUnitService.deleteEducation(data.id).subscribe(
          (res)=>{
            this.checkFireBase('delete');

          },
          (err)=>{
            this.getDataEducation1();
            this.showMessage.success("Xóa nghành dọc không thành công");
          }
        )
      }
    });
  }

  onSearchChange(searchValue: string): void {  
    this.dataSearch = searchValue;
  }

  openDialogImportIndustry(){
    this.button.is_active_import_user = true;
        const dialogRef = this.dialog.open(DialogImportIndustryComponent, {
            height: 'auto',
            width: '40%',
        });

        dialogRef.afterClosed().subscribe(result => {
          this.isLoading = true;
          this.industryUnitService.importBase64MoetUnit(result.dataInput).subscribe(
            (res:any)=>{
                if (res != '' && res.status == 1){
                  localStorage.setItem('sessionFile',res.data.data[0].sessionFile);
                  this.dataImport = res.data;
                  this.fileName = result.fileName;
                  this.isImportIndustry = true;
                }else{
                  this.showMessage.error(res.msg);
                }
                this.button.is_active_import_user = false;
                this.isLoading = false;
            },
            (err)=>{
              this.isLoading = false;
            }
          )
        });
  }

  getCloseImport(confirm){
    this.isLoading = true;
    let dataInput = {
      // pageSize: 0,
      // pageIndex: 0,
      // keyWord: "string",
      sessionFile: localStorage.getItem('sessionFile')
    }
    this.industryUnitService.importMoetUnit(dataInput).subscribe(
      (res:any)=>{
        this.isLoading = false;
        if(res.status == 1){
          this.showMessage.success(res.msg);
          this.getDataEducation1();
          localStorage.removeItem("sessionFile");
        }else{
          this.showMessage.error(res.msg);
        }
      },
      (err)=>{
        this.isLoading = false;
      }
    );
    this.button.is_active_export_user = false;
    this.button.is_active_import_user = false;
    this.isImportIndustry = confirm;
  }

  searchFocusOut(txtSearch:string='',unit: any){
    let dataInput = {
      keyword: txtSearch ? txtSearch : null,
      unit: Number(unit),
      soId: null,
      phongId: null
    }
    this.industryUnitService.filterEducation(dataInput).subscribe(
      (res:any)=>{
        this.resultSearch = res.data;
        this.checkResultSearch = true;
        switch (Number(unit)) {
          case 1:
            this.linkDetailEducation = '/industry-unit/education-lv1/detail/';
            break;
          case 2:
            this.linkDetailEducation = '/industry-unit/education-lv2/detail/';
            break;
          case 3:
            this.linkDetailEducation = '/industry-unit/education-lv2/detail/';
            break;
          default:
            this.linkDetailEducation = '/industry-unit/education-lv3/detail/';
            break;
        }
      },
      (err)=>{
      }
    )
  }

  clickMoveEducationLv2(data:any){
    const dialogRef = this.dialog.open(DialogMoveEducationComponent, {
      height:'auto',
      width:'30%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.checkFireBase('update_vertical_unit');
          if (this.isLoading) this.activeLv1(data.parent_code_lv1);
          // this.getDataEducation1();
          //
          // this.showMessage.success('Chuyển đổi đơn vị nghành dọc thành công');
        }
      }
    )

  }

  clickMoveEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogMoveEducationLv2Component, {
      height:'auto',
      width:'80%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.checkFireBase('update_vertical_unit');
          if (this.isLoading)  {
            this.activeLv1(data.parent_code_lv1);
            this.activeLv2(data.parent_code);
          }
          // this.getDataEducation1();
          // this.activeLv1(data.parent_code_lv1);
          // this.activeLv2(data.parent_code);
          // this.showMessage.success('Chuyển đổi đơn vị hành chính thành công');
        }
      }
    )
  }
}

