import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import { AuthorizationService } from '../_services';
import { Observable } from 'rxjs';
import { allowedNodeEnvironmentFlags } from 'process';

@Injectable({ providedIn: 'root' })
export class PermissonGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot
        ): Observable<boolean> | Promise<boolean> | boolean  {

        const allowedPermissonChildRoute = route.data.permissons;

        const userPermission = JSON.parse(localStorage.getItem('permission'));
        let check = allowedPermissonChildRoute.some(r=> userPermission.indexOf(r) >= 0)

        if(userPermission.length > 0 && check){
            return true;
        }
        // return true;
        this.router.navigate(['/accessdenied']);
        // not logged in so redirect to login page with the return url
        return false;
    }
}
