<table mat-table [dataSource]="dataSource" class="table-el">
    <ng-container matColumnDef="stt">
        <th mat-header-cell *matHeaderCellDef style="width: 3%">STT</th>
        <td mat-cell *matCellDef="let item, let i = index">{{(pageIndex * 15) + i + 1}}</td>
    </ng-container>
    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef style="width: 15%">Tên môn học</th>
        <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;" (click)="openDetailSubject(item.id,'tab1');">{{item.fullname}}</a></td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef style="width: 8%">Mã môn học</th>
        <td mat-cell *matCellDef="let item">{{item.code}}</td>
    </ng-container>
     <ng-container matColumnDef="grade">
        <th mat-header-cell *matHeaderCellDef style="width: 12%">Khối </th>
        <td mat-cell *matCellDef="let item"><span *ngFor="let grade of item.grades">{{grade.fullname}}, </span></td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef style="width: 8%">MOET</th>
        <td mat-cell *matCellDef="let item"><mat-checkbox [checked]="item.isMoetSubject === 1" disabled></mat-checkbox></td>
    </ng-container>
    <ng-container matColumnDef="count_user">
        <th mat-header-cell *matHeaderCellDef style="width: 8%">Khách hàng</th>
        <td mat-cell *matCellDef="let item"><span *ngIf="item.isMoetSubject === 1" class="show-more" (click)="showListCustomer(item)">{{item.tenantCount}}</span></td>
    </ng-container>
    <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef style="width: 5%">Trạng thái</th>
        <td mat-cell *matCellDef="let item"><mat-checkbox [checked]="item.status === 1" (change)="changeStatusSubject($event, item.id, item.fullname)">Kích hoạt</mat-checkbox></td>
    </ng-container>
    <ng-container matColumnDef="tool">
        <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-right">Công cụ</th>
        <td mat-cell *matCellDef="let item" class="text-right">
            <button *ngIf="item.isMoetSubject === 1" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="menu-more-global" >
                <button mat-menu-item (click)="openDialogUpdate(item)">Chỉnh sửa</button>
                <button mat-menu-item class="text-danger" (click)="confirmDelete(item.id, item.fullname)">Xóa</button>
            </mat-menu>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
    <tr mat-row *matRowDef="let row, columns: dataColumn"></tr>
</table>
<mat-paginator *ngIf="countData > 15" appStylePaginator class="table-paginate-global" [length]="countData" [pageIndex]="pageIndex" [pageSize]="15" (page)="onChangePaginate($event)" ></mat-paginator>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
