<!--<mat-toolbar class="header-bar">-->
<!--    <mat-toolbar-row>-->
<div class="header-bar">
    <div fxLayout="row" fxLayoutAlign="space-between end" style="width: 100%;">
        <div class="items-title">
            <a routerLink="/" class="title-el"><img [src]="config?.logo ? config?.logo : '/assets/images/logo/logo.png'" alt=""></a>
            <div class="ml-3">
                <p class="title-menu" id="title-page-config">{{config?.fullname}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
            <div style="padding: 10px;">
                <label class="total-noti-user" [hidden]="alertNoti">
                    <span>02</span>
                </label>
                <button class="button-noti-user" mat-icon-button (click)="showNoti(cancel)" id="notification-center">
                    <img [src]="cancel ? 'assets/images/icons/icon-20.svg' : 'assets/images/icons/icon-19.svg' " width="20px">
                </button>
                <div fxLayout="column" class="noti-user hidden" id="noti-user">
                    <div fxFlex fxLayout="column">
                        <div fxFlex="30px">
                            <p class="title-noti-user mt-3 ml-2">Thông báo</p>
                        </div>
                        <div fxFlex="30px" class="mt-4" fxLayout="row" fxLayoutAlign="space-between none">
                            <p class="ml-2">Hôm nay</p>
                            <span class="check-all-notification-read" (click)="checkAllNotificationRead();">Đánh dấu tất cả là đã đọc</span>
                        </div>
                        <div fxFlex style="overflow-y: auto; overflow-x: hidden" *ngIf="array">
                            <div *ngFor="let card of array" fxLayout="row" fxLayoutAlign="start none" [ngClass]="card.status == 0 ? 'not-read' : ''" class="item-noti-user">
                                <div fxFlex="15%">
                                    <img [src]="card.featuredImage" class="mt-3 ml-2">
                                </div>
                                <div fxFlex="84%" class="mt-2">
                                    <span class="item-noti-user-title">{{card.postTitle}}</span>
                                    <p class="item-noti-user-content" style="text-overflow: ellipsis;"> {{card.shortDescription}}</p>
                                    <span class="item-noti-user-profile" fxLayout="row" fxLayoutAlign="space-between none">
                                            <span>Từ: {{card.fullName}} ({{ card.userName }})</span>
                                    <span>Đã nhận: {{card.receivedAt | date : "dd/MM/yyyy"}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="40px" class="footer-noti-user" fxLayout="row" fxLayoutAlign="center center">
                        <span class="mt-1" (click)="viewAllNotification();">Xem tất cả thông báo</span>
                    </div>
                </div>
            </div>
            <div class="info-user ml-2" [matMenuTriggerFor]="menuUser" #t="matMenuTrigger" fxLayout="row" fxLayoutAlign=" center">
                <img mat-card-avatar class="example-header-image mr-1" src="{{user?.avatar}}">
                <span class="name-user mr-1">{{user?.fullname}}</span>
                <img [src]="t.menuOpen ? 'assets/images/icons/icon-32.svg' : 'assets/images/icons/icon-18.svg'" width="14px">
                <mat-menu #menuUser="matMenu" class="menu-more-global">
                    <button mat-menu-item (click)="openProfile()">Thông tin tài khoản</button>
                    <button mat-menu-item routerLink="post-personal">Bài viết</button>
                    <button mat-menu-item (click)="showFormChangePassword()">Đổi mật khẩu</button>
                    <button mat-menu-item (click)="logout()">Đăng xuất</button>
                </mat-menu>
            </div>
            <div class="ml-3 mr-5 div-label-language">
                <label class="label-language" mat-icon-button [matMenuTriggerFor]="language" #x="matMenuTrigger">
                    <div>
                        <img src="https://cdn.webshopapp.com/shops/94414/files/54968390/vietnam-flag-icon-free-download.jpg" style="height: 20px; width: 30px">
                        <span>Tiếng Việt</span>
                    </div>
                    <img [src]="x.menuOpen ? 'assets/images/icons/icon-32.svg' : 'assets/images/icons/icon-18.svg'" width="14px">
                </label>
                <mat-menu #language="matMenu" class="select-language">
                    <button mat-menu-item><img src="https://cdn.webshopapp.com/shops/94414/files/54968390/vietnam-flag-icon-free-download.jpg" style="height: 20px; width: 30px"> Tiếng Việt </button>
                    <button mat-menu-item><img src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg" style="height: 20px; width: 30px"> Cambodia</button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>
<!--    </mat-toolbar-row>-->
<!--</mat-toolbar>-->