import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {DialogDeletePermissionComponent} from '@app/_modules/permission-manager/dialogs/dialog-delete-permission/dialog-delete-permission.component';
import {DialogFormAddEditPermissionComponent} from '@app/_modules/permission-manager/dialogs/dialog-form-add-edit-permission/dialog-form-add-edit-permission.component';
import { PermissionManagerService } from '@app/_services/permission-manager.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Permission } from '@app/_models/permission';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent implements OnInit {
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'module', 'is_active', 'tool'];
  total: number = 0;
  data: any;
  dataPermission: any;
  total_page: number;
  page: number;
  limit: number;
  isLoading: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private dialogRes: MatDialog, private permissionsService: PermissionManagerService) {}

  ngOnInit(): void {
    this.listDataPermission();
    $("#leader-line-defs").remove();
  }
  listDataPermission(){
    this.limit = 10;
    this.page = 0;
    this.dataPermission = this.permissionsService.getDataPermission(this.page, true, this.limit).subscribe((res: any) => {
        this.total_page = res.data.total_page;
        this.dataPermission = new MatTableDataSource<Permission>(res.data.permissions);
        this.total = res.data.total_count;
        this.dataPermission.paginate = this.paginator;
    });
  }

  onChangePaginate(event){
    this.isLoading = true;
    this.page = event.pageIndex;
    this.limit =  event.pageSize;
    this.dataPermission = this.permissionsService.getDataPermission(this.page, false, this.limit).subscribe((res: any) => {
      this.dataPermission = new MatTableDataSource<Permission>(res.data.permissions);
      this.dataPermission.paginate = this.paginator;
    });
    this.isLoading = false;
  }

  confirmDelete(name: string, id: string) {
    const confirmDelete = this.dialogRes.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện quyền',
        message: 'Xác nhận xóa?',
        btnOkText: 'Delete',
        btnCancelText: 'Cancel',
        data: {
          name: name
        }
      }
    });
    confirmDelete.afterClosed().subscribe((data: any) => {
      if (data === 'ok'){
        this.isLoading = true;
        this.permissionsService.deletePermission(id).subscribe(
            (ref: any) => {
              alert(ref.message);
              this.listDataPermission();
            },
            (error: any) => {
                    alert(error.message);
                    this.listDataPermission();
                  }
        );
      }
    });
  }


  showFormPermission(action: string, item?: any){
    if (action === 'add'){
      const dialog = this.dialogRes.open(DialogFormAddEditPermissionComponent, {
        width: '60%',
        panelClass: 'custom-modalbox',
        data: {
          action: action,
        }
      });
      dialog.afterClosed().subscribe(res => {
        if (res && res.http_status_code === 200){
          alert(res.message);
          this.listDataPermission();
        }
      });
    }
    else{
      this.permissionsService.getPermissionById(item.id).subscribe((ref: any) => {
        if (ref.http_status_code === 200){
          item.module_id = item.module.id;
          const dialog = this.dialogRes.open(DialogFormAddEditPermissionComponent, {
            width: '60%',
            panelClass: 'custom-modalbox',
            data: {
              action: action,
              item: item,
            }
          });
          dialog.afterClosed().subscribe(res => {
            if (res && res.http_status_code === 200){
              alert(res.message);
              this.listDataPermission();
            }
          });
        }
      },
      (error: any) => {
        alert(error.message);
      });
    }
  }
}
