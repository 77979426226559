<section class="container-fluid main-wrapper" fxLayout="column">
    <div fxFlex="10%" fxLayout="row" fxLayoutAlign="end start">
    </div>
    <div fxFlex="80%" class="row mb-5">
        <div class="col-12 col-sm-12 col-lg-2"></div>
        <div class="col-12 col-lg-4 col-sm-12 login-image">
            <div class="custom-space-logo " fxLayout="column">

                <div class="custom-logo">
                    <img src="assets/images/svg/elearn.svg" class="logo pl-0">
                    <p class="desc-tenant mt-4">Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.
                        Tincidunt id condimentum praesent nam.
                        Mi nisi cras neque molestie. Lobortis commodo, volutpat elementum ultricies. Sit a nisl diam mi.
                        Vestibulum eu consequat orci aliquam massa.</p>
                </div>
            </div>
            <div class="mt-5 mr-4 custom-banner">
                <img src="assets/images/svg/introduce.svg">
            </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-1"></div>
        <div class="col-12 col-lg-3 bg-white col-lg-3 col-sm-12" style="border-radius: 10px">
            <div class="container" style="height: 100%">
                <button style="width: auto; position: relative; right: 18px" class="btn"
                        routerLink="../forgot-password">
                    <img src="assets/images/svg/back-icon.svg">
                </button>
                <br>
                <label class="dn-login"> Xác nhận tài khoản của bạn</label>
                <form [formGroup]="verifyAccount" (ngSubmit)="confirmCode(verifyAccount.value)">
                    <div class="form-group">
                                <div *ngIf="verifyAccount.get('code').invalid && (verifyAccount.get('code').dirty || verifyAccount.get('code').touched) || isSpace"
                                      class="text-danger">
                                    *Bạn cần phải nhập mã</div>
                        <div class="text-danger"
                              *ngIf="message && this.verifyAccount.get('code').invalid == false">*{{message}}
                                </div>
                        <label class="title-input-form">Vui lòng nhập mã tài khoản</label>
                        <input (keyup)="turnOffMessage(code.value)" #code type="text" formControlName="code"
                               class="form-control"
                               id="verify-account">
                        <p>
                            <a *ngIf="isOverTime === false && username !== null" style="cursor: pointer"
                               (click)="sentCode()" class="custom-font-code">Bạn không nhận được mã?</a>
                            <span class="text-danger" *ngIf="isOverTime == true">
                                    *Gửi lại mã {{overTime}}
                                </span>
                        </p>

                    </div>
                    <button type="submit" class="btn btn-primary sent-code "
                            [disabled]="verifyAccount.invalid || isSpace">
                        Xác nhận
                    </button>
                </form>
                <!--                        <button *ngIf="isOverTime == true" type="button" class="btn btn-primary sent-code "> Gửi mã-->
                <!--                        </button>-->
            </div>
        </div>
        <div class="col-2">
            <div class="text-center ">

            </div>
        </div>
    </div>
    <div fxFlex="10%"></div>
</section>

