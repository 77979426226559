import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IndustryUnitService } from '@app/_services/industry-unit.service';
import { REGEX_CODE } from '@app/_shared/utils/constant';
import { ShowMessage } from '@app/_services/show-message.service';

@Component({
  selector: 'app-dialog-form-education-lv1',
  templateUrl: './dialog-form-education-lv1.component.html',
  styleUrls: ['./dialog-form-education-lv1.component.scss']
})
export class DialogFormEducationLv1Component implements OnInit {
  public dataEducation: FormGroup;
  urlImage: SafeResourceUrl;
  selectedFile: File;
  isLoading = false;
  public id:any;
  public fullname:number;
  public code:number;
  public order:number;
  public status:number;
  public logo:string;

  hideButtonAdd:boolean;

  panelOpenState = true;
  checkShowForm:boolean = false;
  constructor(
    private sanitizer: DomSanitizer,private fb: FormBuilder,
    private dialogref: MatDialogRef<DialogFormEducationLv1Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    private industryUnitService:IndustryUnitService,
    private showMessage:ShowMessage
  ) { }
  
  ngOnInit(): void {
    this.isLoading = true;
    this.industryUnitService.generateCode("SGD").subscribe(
      (res:any)=>{
        this.urlImage = this.data.education ? this.data.education.logo : '/assets/images/png/avatar.png';
        this.data?.status == "update" ? this.hideButtonAdd = false : this.hideButtonAdd = true;
        this.dataEducation = this.fb.group({
          id: [this.data?.education?.id ? this.data.education.id : this.id],
          fullname: [this.data?.education?.name ? this.data.education.name : '',[Validators.required]],
          code: [this.data?.education?.code ? this.data.education.code : res.data,[Validators.required,Validators.pattern(REGEX_CODE)]],
          order:  [this.data?.education?.sort ? this.data.education.sort : 0,[Validators.required]],
          logo:  [this.data?.education?.logo ? this.data.education.logo : ''],
          status: [this.data?.education?.status == 0 ? 0 : 1]
        });
        this.checkShowForm = true;
        this.isLoading = false;
      },
      (err)=>{

      }
    )
    setTimeout(() => {
      if(this.isLoading){
        this.isLoading = !this.isLoading;
      }
    }, 5000);
  }

  onClickSubmit(value:any):void{
    this.isLoading = true;
    if(this.data.status == "add"){
      let dataInput = {
        logo: value.logo,
        fullName: value.fullname,
        sort: Number(value.order),
        code: value.code,
        status: Number(value.status),
        unit: 1,
        soId: null,
        phongId: null
      };
      this.industryUnitService.storeEducation(dataInput).subscribe(
        (res:any)=>{
          if(res.status == 0){
            this.showMessage.error(res.msg);
            this.isLoading = false;
          }else{
            this.dialogref.close(true);
            this.isLoading = false;
          }
        },
        (err)=>{
          this.dialogref.close(false);
          this.isLoading = false;
        }
      );
    }else{
      let dataInput = {
        id: value.id,
        logo: "string",
        fullName: value.fullname,
        sort: Number(value.order),
        code: value.code,
        status: Number(value.status),
        unit: 1,
        soId: null,
        phongId: null
      };
      this.industryUnitService.updateEducation(dataInput).subscribe(
        (res:any)=>{
          if(res.status == 0){
            this.showMessage.error(res.msg);
            this.isLoading = false;
          }else{
            this.dialogref.close(true);
            this.isLoading = false;
          }
        },
        (err)=>{
          this.dialogref.close(false);
          this.isLoading = false;
        }
      );
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileChange(event) {

    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result)
      }
    }

  }

  uploadImage() {
    let inputUploadFile = document.getElementById('inputUploadFile') as HTMLElement;
    inputUploadFile.click();
  }

  // validate
  education_validation_messages = {
    'fullname': [
      { type: 'required', message: 'chưa nhập tên sở giáo dục' }
    ],
    'code': [
      { type: 'required', message: 'chưa nhập mã sở giáo dục' },
      { type: 'pattern', message: 'mã sở giáo dục phải từ 5 đến 15 ký tự' }
    ],
    'order': [
      { type: 'required', message: 'chưa nhập order' }
    ]
  }

}
