<h2 mat-dialog-title class="title-page-global text-center">Thêm môn học</h2>
<mat-dialog-content class="mat-typography">
    <div class="dialog-body">
        <div class="row" style="padding-left: 15px;">
            <div class="col-md-12 row d-flex justify-content-center" style="padding-left: 30px;">
                <div class="col-md-12 pr-0 content-search">
                    <div style="cursor: pointer;">
                        <img src="assets/images/svg/search.svg" alt="">
                    </div>
                    <input #searchSubjectOfGrade (focusout)="loadDataItems(searchSubjectOfGrade.value)" (keydown)="searchListSubject($event,searchSubjectOfGrade.value)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Nhập tên, mã môn học">
                </div>
            </div>
            <p class="col-md-12 text-left m-0 mt-2" style="font-size: 16px;">
                <span>
                    <mat-checkbox class="mr-5" (change)="clickSelectAll($event);">Chọn tất cả</mat-checkbox>
                </span>
                <span>Đã chọn: {{ countCheck }} môn học mới</span>
            </p>
        </div>
        <div class="container-fluid">
            <table mat-table [dataSource]="dataSubjectNotAssign" class="table-el">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Chọn</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-checkbox [checked]="arraySubject.indexOf(item.id) != -1" (change)="clickSelectItem($event,item.id);"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fullname">
                    <th mat-header-cell *matHeaderCellDef style="width: 35%;">Tên môn học</th>
                    <td mat-cell *matCellDef="let item">
                        <a class="text-link" [routerLink]="['/grades-manager/detail/1']">{{ item.fullname }}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%;">Mã</th>
                    <td mat-cell *matCellDef="let item">{{ item.code }}</td>
                </ng-container>
                <ng-container matColumnDef="is_moet">
                    <th mat-header-cell *matHeaderCellDef style="width: 15%;">Môn học MOET</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-checkbox [checked]="item.isMoetSubject == 1 ? true : false" [disabled]="true"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Trạng thái</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-checkbox [checked]="item.status == 1 ? true : false" [disabled]="true">
                            {{ item.status == 1 ? "Kích hoạt" : "Chưa kích hoạt" }}
                        </mat-checkbox>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn-close custom-outline-button btn-cancel-global" (click)="closeAddSubject();">HỦY</button>
    <button mat-button mat-dialog-close mat-raised-button class="btn-close custom-outline-button btn-add-global" (click)="clickAddSubject();">THÊM</button>
</mat-dialog-actions>