import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EducationLv1Component } from "./components/education-lv1/education-lv1.component";
import { DetailEducationLv1Component } from "./components/detail-education-lv1/detail-education-lv1.component";
import { DetailEducationLv2Component } from "./components/detail-education-lv2/detail-education-lv2.component";
import { DetailEducationLv3Component } from "./components/detail-education-lv3/detail-education-lv3.component";
import { NgxPermissionsGuard } from 'ngx-permissions';
import {ADMIN_MOET_UNIT_PERMISSION} from './constants';
const routes: Routes = [
  {
    path: 'education-lv1',
    component: EducationLv1Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [ADMIN_MOET_UNIT_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'education-lv1/detail/:id',
    component: DetailEducationLv1Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [ADMIN_MOET_UNIT_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'education-lv2/detail/:id',
    component: DetailEducationLv2Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [ADMIN_MOET_UNIT_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'education-lv3/detail/:id',
    component: DetailEducationLv3Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [ADMIN_MOET_UNIT_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndustryUnitRoutingModule { }
