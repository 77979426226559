import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-dialog-tenant-role',
  templateUrl: './dialog-form-tenant-role.component.html',
  styleUrls: ['./dialog-form-tenant-role.component.scss']
})
export class DialogFormTenantRoleComponent implements OnInit {
  public addRoleTenant: FormGroup;
  select_all = false;
  public id:any;
  ids = [];
  data_role:any;
  constructor(private fb: FormBuilder,
    private dialogref: MatDialogRef<DialogFormTenantRoleComponent>, @Inject(MAT_DIALOG_DATA) public data) { }
  ngOnInit(): void {
    this.data_role = this.data.data;
    this.addRoleTenant = this.fb.group({
      id: [this.data?.tenant?.id ? this.data.tenant.id : this.id],
      ids: [this.data?.tenant?.ids ? this.data.tenant.ids : this.ids,[Validators.required]]
    });
  }

  onClickSubmit(value):void{

  }

  onSelectAll(e: any): void {
    for (let i = 0; i < this.data_role.length; i++) {
      const item = this.data_role[i];
      item.is_selected = e.checked;
    }
  }

}
