<div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<!-- thông tin hệ thống -->
<mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false" class="panel-content-global">
    <mat-expansion-panel-header class="panel-header col-log-6"
                                style="margin-left: 0; padding-left: 0">

        <mat-panel-title class="panel-title">{{lang.label.info_system.title}}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row col-md-11 pl-0">
        <div class="form-group col-md-6 row">
            <label class="col-md-3 lb-global">{{lang.label.info_system.name_system}}</label>
            <div class="col-md-8 pl-0 pr-0">
                <input [value]="" type="text" disabled class="form-control input-field-global">

            </div>
        </div>
        <div class="form-group col-md-6 ml-auto row row-global">
            <label class="col-md-3 offset-1 lb-global">{{lang.label.info_system.phone}}</label>
            <div class="col-md-8 pl-0 pr-0">
                <input [value]="" disabled type="text" class="form-control input-field-global" value="0349557845">
            </div>
        </div>
    </div>
    <div class="row col-md-11 pl=0">
        <div class="form-group col-md-6 row">
            <label class="col-md-3 lb-global">{{lang.label.info_system.email}}</label>
            <div class="col-md-8 pl-0 pr-0">
                <input [value]="" type="text" disabled class="form-control input-field-global" value="abc@gmail.com">

            </div>
        </div>
        <div class="form-group col-md-6 ml-auto row row-global">
            <label class="col-md-3 offset-1 lb-global ">{{lang.label.info_system.hotline}}</label>
            <div class="col-md-8 pl-0 pr-0">
                <input [value]="" disabled type="text" class="form-control input-field-global">
            </div>
        </div>
    </div>

    <div class="div-address-custom col-md-11" style=" padding-left: 0">
        <label class="label-address-custom">{{lang.label.info_system.address}}</label>
        <div class="pl-0 pr-0 input-address-custom">
            <input [value]="" disabled type="text" class="form-control input-field-global" value="Thôn GT">
        </div>
    </div>

    <div class="row col-md-11 pl-0" style="margin-top: 15px;">
        <div class="form-group col-md-6 row">
            <label class="col-md-3 lb-global">{{lang.label.info_system.language}}</label>
            <div class="col-md-8 pl-0 pr-0">
                <select class="form-control input-field-global">
                    <option value="vi">Tiếng Việt</option>
                    <option value="en">Tiếng Anh</option>
                </select>
            </div>
        </div>
    </div>

    <div class="div-address-custom col-md-11 pl-0">
        <label class="lb-global label-address-custom">{{lang.label.info_system.image}}</label>
        <div class="pl-0 pr-0 input-address-custom">
            <div class="row">
                <div class="col-md-3 logo-image">
                    <img src="https://pdsohio.com/wp-content/uploads/2017/04/default-image.jpg" class="logo">
                    <div class="custom-title-image">
                        {{lang.label.info_system.logo}}
                    </div>
                </div>
                <div class="col-md-3 offset-1 logo-image">
                    <img src="https://pdsohio.com/wp-content/uploads/2017/04/default-image.jpg" class="logo">
                    <div class="custom-title-image">
                        {{lang.label.info_system.logo_app}}
                    </div>
                </div>
                <div class="col-md-4 offset-1 logo-image">
                    <img src="https://pdsohio.com/wp-content/uploads/2017/04/default-image.jpg" alt="" class="image">
                    <div class="custom-title-image">
                        {{lang.label.info_system.avatar_login}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>
<br>
<!-- thông tin hệ thống -->
<div fxLayout="row" fxLayoutAlign="center end" >
    <button class="btn custom-btn custom-btn-close">HỦY</button>&nbsp;&nbsp;
    <button class="btn custom-btn custom-btn-add">LƯU</button>
</div>
