export const environment = {
    firebaseConfig: {
        apiKey: "AIzaSyAVLmwoqOnMJufeQFpIb62a-7IkG3H8ty4",
        authDomain: "elearn-7c15d.firebaseapp.com",
        databaseURL: "https://elearn-7c15d-default-rtdb.firebaseio.com",
        projectId: "elearn-7c15d",
        storageBucket: "elearn-7c15d.appspot.com",
        messagingSenderId: "1057110185279",
        appId: "1:1057110185279:web:66081d15abb0ff17ab6f8a",
        measurementId: "G-09DKYWTMPS"
    }
};