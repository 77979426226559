import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    const seconds = parseInt(moment(value).utc().format('X'));
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return 'about ' + interval + ' years';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return 'about ' + interval + ' months';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return 'about ' + interval + ' days';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return 'about ' + interval + ' hours';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return 'about ' + interval + ' minutes';
    }
    return 'about ' + Math.floor(seconds) + ' seconds';
  }

}
