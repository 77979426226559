import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogTenantEditSubjectComponent} from '@app/_modules/tenants-manager/dialog/dialog-tenant-edit-subject/dialog-tenant-edit-subject.component';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import { TenantManagerService, ListenFirebaseService } from '@app/_services';
import {ActivatedRoute} from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {TENANT_PERMISSION} from '../../../constants';

@Component({
  selector: 'app-tab-tenant-subject-list',
  templateUrl: './tab-tenant-subject-list.component.html',
  styleUrls: ['./tab-tenant-subject-list.component.scss']
})
export class TabTenantSubjectListComponent implements OnInit {
  dataColumn = ['stt', 'nameSubject', 'codeSubject', 'typeSubject', 'tool'];
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  dataSource: any;
  tenantId = "";
  keyword: string = '';
  typeModule = 'tenant_subject';
  isLoadingResults = false;
  TENANT_PERMISSION = TENANT_PERMISSION;
  
  @Input() data: any;
  constructor(
    private dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private activatedRoute: ActivatedRoute,
    private listenFirebaseService: ListenFirebaseService
    ) { }

  ngOnInit(): void {
    this.listSubject();
  }

  listSubject(search?: string, filter?: []) {
    this.isLoadingResults = true;

    this.activatedRoute.paramMap.subscribe((res: any) => {
      this.tenantId = res.params.id;
      search = search ? search : '';
      filter = filter ? filter : [];
      this.tenantManagerService.getSubjectByTenant(this.pageSize, this.pageIndex, search, filter, this.tenantId).subscribe((ref: any) => {
        this.dataSource = ref.data.data;
        this.totalRecord = ref.data.totalItems;
        this.isLoadingResults = false;

      });
    });
  }

  onChangePaginate(event){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.listSubject();
  }

  showFormEditSubject(){
      const dialogRef = this.dialog.open(DialogTenantEditSubjectComponent, {
        width: '70%',
        height: 'auto',
        panelClass: 'custom-modalbox',
        data: {
          tenantId: this.tenantId
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.listSubject();
        }
      });
  }


  confirmDelete(item: any) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
        height: 'auto',
        width: '40%',
        data: {
          title:'Bạn đang thực hiện gỡ môn học ra khỏi danh sách áp dụng',
          message:'Bạn có chắc muốn gỡ môn học này không ?',
          btnOkText:'XÁC NHẬN',
          btnCancelText:'HỦY',
            data: {
              id:item.id,
              name:item.fullname
            },
        }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm === true) {
          this.isLoadingResults = true;
          let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
          });
  
          listenFb.subscribe((result) => {
            if (result === true) {
              this.listSubject();
            }
            this.isLoadingResults = false;
          });
  
          this.tenantManagerService.deleteSubjectTenant(this.tenantId, item.id).subscribe((res: any) => {
            if (res.status == 0) {
              if (this.isLoadingResults) {
                this.isLoadingResults = false;
              }
            }
          }, error => {
            this.isLoadingResults = false;
          });
          setTimeout(() => {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
        }

    });
  }

  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.pageIndex = 0;
      this.listSubject(this.keyword);
    }
  }
  
  onBlur(event) {
    event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
    if (event.type === 'blur') {
      this.pageIndex = 0;
      this.listSubject(this.keyword);
    }
  }
}
