import { Component, OnInit, Inject } from '@angular/core';
import { TenantManagerService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { Tenant } from '@app/_models';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-tenant-edit-module',
  templateUrl: './dialog-tenant-edit-module.component.html',
  styleUrls: ['./dialog-tenant-edit-module.component.scss']
})
export class DialogTenantEditModuleComponent implements OnInit {

  constructor(
    private tenantManagerService: TenantManagerService,
    @Inject(MAT_DIALOG_DATA) public dataInput,
    private dialogRef: MatDialogRef<DialogTenantEditModuleComponent>,
    private listenFirebaseService: ListenFirebaseService,
    private showMessage: ShowMessage    
  ) { }

  keyword = "";
  tenantId: string;
  queryParams: any;
  tenantInfor: Tenant;
  checkStatus: boolean = false;
  typeModule = 'tenant_module';
  isLoadingResults = false;

  ngOnInit(): void {
    this.tenantId = this.dataInput.tenantId;
    this.getInfoTenant();
    this.getDataAllModules();
  }
  data = [];
  getInfoTenant() {
    this.tenantManagerService.getDataTenantById(this.tenantId).subscribe((res: any) => {
      this.tenantInfor = res.data;
    });
  }

  getDataAllModules() {
    this.isLoadingResults = true;

    return this.tenantManagerService.getAllModules(this.keyword, this.tenantId).subscribe((res: any) => {
      this.data = res.data;
      this.data.forEach(element => {
        if(element.isAssigned === 1) {
          element.is_selected = true;
        } else {
          element.is_selected = false;
        }
      });    
      this.isLoadingResults = false;

    });
  }

  submitUpdate() {
    this.isLoadingResults = true;

    let selectIdArr = [];
    this.data.forEach(element => {
        if (element.is_selected === true) {
          selectIdArr.push(element.id);
        }
    });

    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase("update", this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.dialogRef.close(res);
      }
      this.isLoadingResults = false;

    });

    this.tenantManagerService.updateAllModule(this.tenantId, selectIdArr).subscribe((res: any) => {
        if (res.status == 0) {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
              this.showMessage.error(res.msg);
            }
          }
    }, error => {
      console.log(error);
    });
    setTimeout(() => {
      if (this.isLoadingResults) {
        this.isLoadingResults = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }

  checkAll(event) {
    this.data.forEach(function (t) {
      t.is_selected = event.checked;
      // t.subjectArray.forEach(a => a.is_selected = event.checked);
    });
  }
  updateAllComplete() {
    this.checkStatus = this.data.length > 0 && this.data.every(t => t.is_selected);
  }
  
  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.keyword = (event.target as HTMLInputElement).value;
      this.getDataAllModules();
    }
  }

  onBlur(event) {
      if (event.type === 'blur') {
        this.keyword = (event.target as HTMLInputElement).value;
        this.getDataAllModules();
      }
    }

}
