<div fxLayout="column" class="bg-content-detail-global">
    <div fxFlex="50px">
        <div class="title-content-page-tab">Lịch sử thao tác</div>
    </div>
    <div fxFlex class="content-data">
        <table mat-table [dataSource]="dataSource" matSort class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;">STT</th>
                <td mat-cell *matCellDef="let item;let i = index">{{ dataHistories.size*(dataHistories.page - 1) + i + 1 }}</td>
            </ng-container>
            <ng-container matColumnDef="action_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 18%;">Thời gian</th>
                <td mat-cell *matCellDef="let item">{{ item.dateTime | date:"h:mm dd/MM/yyyy" }}</td>
            </ng-container>

            <ng-container matColumnDef="action_content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Hành động</th>
                <td mat-cell *matCellDef="let item">{{ item.action }}</td>
            </ng-container>
            <ng-container matColumnDef="action_ip">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 18%;">IP</th>
                <td mat-cell *matCellDef="let item">{{ item.ip }}</td>
            </ng-container>

            <ng-container matColumnDef="action_url">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">URL</th>
                <td mat-cell *matCellDef="let item">{{ item.url }}</td>
            </ng-container>

            <ng-container matColumnDef="user_agent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 14%;">User-Agent</th>
                <td mat-cell *matCellDef="let item">{{ item.userAgent }}</td>
            </ng-container>

            <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef class="action_table" style="width: 8%;">Dữ liệu</th>
                <td mat-cell *matCellDef="let item" class="action_table_td">
                    <img src="assets/images/icons/icon-detail-history.svg" style="cursor: pointer" (click)="openDialogShowDataActionHistory(item)">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
    </div>
</div>
