<div>
  <div class="title">Chọn yêu cầu cần đạt
    <span class="icon-close" [mat-dialog-close]='null'><img src="assets/images/icons/Vector-24.png"></span>
  </div>
  <hr>
  <div class="header-dialog mt-2" fxLayout="column">
    <div class="block-search">
      <input #searchInput type="text" id="search-value" class="form-control" placeholder="Nhập nội dung cần tìm kiếm"
        (keydown)="search($event, searchInput.value)" />
      <div>
        <img src="assets/images/svg/search.svg" alt="" class="icon-search" />
      </div>
    </div>
    <span class="count-check">Đã chọn
      <span class="count">{{ countItem }} yêu cầu</span>
    </span>
  </div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mat-tree-exam-list tree mt-2">
    <mat-tree-node *matTreeNodeDef="let node; let i = index" matTreeNodeToggle matTreeNodePadding class="node-child border-custom mb-3">
      <div class="example-tree-container">
        <div class="mat-tree-node box node-child" fxLayout="row">
          <button *ngIf="!node.expandable" mat-icon-button disabled></button>
          <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
            </mat-icon>
          </button>
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="none" style="align-items: center">
              <mat-checkbox [disabled]="!data.canCheck" class="text-bold" class="checklist-leaf-node"
                [checked]="node.isUsed == 1 ? true : false" (change)="changeCheckBoxItem(node)">
                <div class="box-title-name" id="custom-app-math-css" [appMath]="node.content"></div>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; let i = index; when: hasChild" matTreeNodePadding class="node-parent border-custom mb-3">
      <div class="example-tree-container">
        <div class="mat-tree-node box node-parent" fxLayout="row">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
            </mat-icon>
          </button>
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="none" style="align-items: center">
              <mat-checkbox class="text-bold checklist-leaf-node" [checked]="node.isUsed == 1 ? true : false"
                (change)="changeCheckBoxItem(node)" [disabled]="!data.canCheck">
                <div class="box-title-name" id="custom-app-math-css" [appMath]="node.content"></div>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
<hr>
<div class="container-button" fxLayoutAlign="end center" *ngIf="data.canCheck">
  <button class="btn btn-cancel mr-2" (click)="toggleAll()">
    Bỏ chọn
  </button>
  <button class="btn btn-active" (click)="save()">
    Tiếp tục
  </button>
</div>
<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>