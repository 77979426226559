export const MODULE_PERMISSION = {
    perm1: 'core_admin_subsystems',
    perm2: 'core_admin_subsystem_add',
    perm3: 'core_admin_subsystem_detail',
    perm4: 'core_admin_subsystem_edit',
    perm5: 'core_admin_subsystem_delete',
    perm6: 'core_admin_subsystem_tenants',
    perm7: 'core_admin_subsystem_tenant_add',
    perm8: 'core_admin_subsystem_tenant_delete',
};
