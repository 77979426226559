import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { GeneralConfigService, ShowMessage } from '@app/_services/index';


@Component({
  selector: 'app-dialog-add-unit',
  templateUrl: './dialog-add-unit.component.html',
  styleUrls: ['./dialog-add-unit.component.scss']
})
export class DialogAddUnitComponent implements OnInit {

  isLoading = false;
  listUnits = [];
  keyword: string = '';
  dataResult: any = {
    id: null,
    name: ''
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private generalConfigService: GeneralConfigService,
    public dialogRef: MatDialogRef<DialogAddUnitComponent>,
  ) { }

  ngOnInit(): void {
    this.getListUnits();
  }

  getListUnits() {
    this.isLoading = true;
    this.generalConfigService.getListDVGD().subscribe((res: any) => {
      this.listUnits= res.data;
      this.isLoading = false;
    });
  }

  search(event){
    if (event.key === 'Enter' || event.key === 'Tab'){
      this.keyword = event.target.value;
      this.getListUnits();
    }
  }

  chooseUnit(event) {
    console.log(event);
    this.dataResult.id = event.value.id;
    this.dataResult.name = event.value.name;
  }
  add(){
    if(this.dataResult.id == null) {
      
    } else {
      this.dialogRef.close(this.dataResult);
    }
  }

}
