<p class="text-center title-page-global">{{data.infoUser.fullName | substring: 30}} - Khách hàng</p>
<div class="row">
    <div class="col-md-12 d-flex justify-content-center">
        <div class="col-md-12">
            <p class="col-md-5 d-flex justify-content-start"
                 style="padding-left: 0">
                <span class="font">
                    Tổng số: {{length}} khách hàng
                </span>
            </p>

        </div>
    </div>

    <div class="col-md-12 d-flex justify-content-center">
        <div class="col-sm-12" (keydown)="checkText($event, search.value)">
            <input #search type="text" id="inputSearch"  class="form-control input-field-global "  placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email" (blur)="checkText($event, search.value)">
            <img class="icon-search-dialog" src="assets/images/icons/icon-23.svg">
        </div>

    </div>
</div>
<div id="custom-scroll">
    <table mat-table [dataSource]="dataCustomer" class="table-el">

        <ng-container matColumnDef="stt">
            <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-left"> STT</th>
            <td class="border-first-5" mat-cell *matCellDef="let element, let stt = index"> {{stt + 1}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">Khách hàng</th>
            <td mat-cell *matCellDef="let element"> {{element.tenantName | substring: 20}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
            <td mat-cell *matCellDef="let element"> {{element.tenantCode}} </td>
        </ng-container>

        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
            <td mat-cell *matCellDef="let element">
                <a target="_blank" href="{{element.tenantDomain}}">
                    {{element.tenantDomain | substring: 25}}
                </a>
            </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef style="width: 10%"> SĐT</th>
            <td mat-cell *matCellDef="let element"> {{element.tenantPhone}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef style="width: 10%"> Email</th>
            <td mat-cell *matCellDef="let element"> {{element.tenantEmail}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<mat-paginator *ngIf="length > pageSize" showFirstLastButtons appStylePaginator class="table-paginate-global" [length]="length"
               [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)"></mat-paginator>
<p class="text-center" style="margin-top: 10px; position: relative; top: 20px">
    <button mat-button mat-dialog-close class="btn btn-close">Đóng
    </button>
</p>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

