import {Inject, Component, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {lang} from '../../school-years.lang';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ShowMessage} from '@app/_services';
import {FormBuilder, Validators} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {SchoolYearsManagerService} from '@app/_services';
import * as _moment from 'moment';
const moment = _moment;
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-dialog-form-school-years',
  templateUrl: './dialog-form-school-years.component.html',
  styleUrls: ['./dialog-form-school-years.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})

export class DialogFormSchoolYearsComponent implements OnInit, OnChanges {
  lang = lang;
  infoForm;
  isValidate = false;
  changeStatus: boolean;
  startDate;
  endDate;
  startMonth;
  endMonth;
  startDay;
  endDay;
  currentSchoolYear;
  isLoading = false;
  messageValidate = {
    fullname: [
      {type: 'required', message: lang.validate_form.full_name_required},
      {type: 'pattern', message: lang.validate_form.full_name_required},
    ],
    code: [
      {type: 'required', message: lang.validate_form.code_required},
      {type: 'pattern', message: lang.validate_form.code_limit_chart},
    ],
  };

  constructor(
      private dialogRef: MatDialogRef<any>,
      private message: ShowMessage,
      @Inject(MAT_DIALOG_DATA) public infoSchoolYear,
      private fb: FormBuilder,
      private schoolYearsManagerService: SchoolYearsManagerService,
  ) { }

  ngOnInit(): void {
    let endDate = _moment().add(8, 'months').endOf("month");
    this.infoForm = this.fb.group({
      fullname: [this.infoSchoolYear.data ? this.infoSchoolYear.data.fullname : `${moment().format('YYYY')}-${moment().format('YYYY')}`, [Validators.required, Validators.pattern(/^[a-zA-Z\d\W\s]{2,250}$/)]],
      code: [this.infoSchoolYear.data ? this.infoSchoolYear.data.code : `${moment(this.startDate).format('YY')}-${moment(this.endDate).format('YY')}`, [Validators.pattern(/^[\w\-]{2,50}$/), Validators.required]],
      startDate: [this.infoSchoolYear.data ? moment((this.infoSchoolYear.data.startDate*1000)) : moment()],
      endDate: [this.infoSchoolYear.data ? moment((this.infoSchoolYear.data.endDate*1000)) : endDate],
      isCurrent: [this.infoSchoolYear.data && this.infoSchoolYear.data.status === 1 ? true : false],
      isActive: [this.infoSchoolYear.data && this.infoSchoolYear.data.isActive === 1 ? true : false]
    });

    this.startDate = this.infoForm.get('startDate').value.format('YYYY');
    this.endDate = this.infoForm.get('endDate').value.format('YYYY');
    this.startMonth = this.infoForm.get('startDate').value.format('MM');
    this.endMonth = this.infoForm.get('endDate').value.format('MM');
    this.startDay = this.infoForm.get('startDate').value.format('DD');
    this.endDay = this.infoForm.get('endDate').value.format('DD');
    this.changeStatus = this.infoSchoolYear.data && this.infoSchoolYear.data.isActive === 1 ? true : false;
    this.getSchoolYearCurrent();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getSchoolYearCurrent(){
    let startDate = moment(this.infoForm.get('startDate').value).format("X");
    let data = {
      "isCurrent": 1,
      "startDate": parseInt(startDate),
    }
    this.schoolYearsManagerService.getCurrentSchoolYear(data).subscribe((res: any) => this.currentSchoolYear = res.data);
  }

  chooseDateTime(formControlName, dateTime: any){
    let tempStartDate = dateTime.value.clone();
    if (formControlName === 'startDate') {
      this.startDate = dateTime.value;
      this.startDay = dateTime.value.format('DD');
      this.startMonth = dateTime.value.format('MM');
      this.infoForm.patchValue({
        endDate: tempStartDate.add(8, 'months').endOf("month"),
      });
    }
    else {

      this.endDate = dateTime.value;
      this.endDay = dateTime.value.format('DD');
      this.endMonth = dateTime.value.format('MM');
    }
    this.infoForm.patchValue({
      fullname: `${this.infoForm.get('startDate').value.format('YYYY')} - ${this.infoForm.get('endDate').value.format('YYYY')}`,
      code: `${this.infoForm.get('startDate').value.format('YYYY').slice(-2)}-${this.infoForm.get('endDate').value.format('YYYY').slice(-2)}`,
    });
    this.infoForm.get('fullname').updateValueAndValidity();
    this.infoForm.get('code').updateValueAndValidity();
  }

  toggleStatus(checked){
    this.changeStatus = checked;
    if (checked) this.getSchoolYearCurrent();
  }

  onSubmit(data){
    if (_moment(data.startDate).format('X') >= data.endDate.format('X')){
      return this.message.warring('Ngày kết thúc phải lớn hơn ngày bắt đầu !');
    }

    if (this.infoForm.invalid){
      return this.isValidate = true;
    }

    this.isLoading = true;
    if (this.infoSchoolYear.status === 'add'){
      let formData = {
        fullname: data.fullname,
        code: data.code,
        startDate: Date.parse(data.startDate)/1000,
        endDate: Date.parse(data.endDate)/1000,
        isActive: data.isActive ? 1 : 0,
        isCurrent: data.isCurrent? 1 : 0,
      }

      this.schoolYearsManagerService.storeSchoolYear(formData).subscribe((res: any) => {
        this.isLoading = false

        if (res.status === 0){
          return this.message.warring(res.msg);
        }
        this.dialogRef.close(res);
      }, err => this.isLoading = false);

    }else {
      let formData = {
        id: this.infoSchoolYear.data.id,
        fullname: data.fullname,
        startDate: Date.parse(data.startDate)/1000,
        endDate: Date.parse(data.endDate)/1000,
        isActive: data.isActive ? 1 : 0,
        isCurrent: data.isCurrent? 1 : 0,
      }

      this.schoolYearsManagerService.updateSchoolYear(formData).subscribe((res: any) => {
        this.isLoading = false;
        if (res.status === 0){
          return this.message.warring(res.msg);
        }
        this.dialogRef.close(res);
      }, error => this.isLoading = false);

      setTimeout(() => {
        if (this.isLoading) this.isLoading = false;
      }, TIME_OUT_LISTEN_FIREBASE);
    }
  }
}
