import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ShowMessage } from "@app/_services";
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
import { MESSAGE_ERROR_CALL_API } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-curriculum-dashbroad',
  templateUrl: './curriculum-dashbroad.component.html',
  styleUrls: ['./curriculum-dashbroad.component.scss']
})
export class CurriculumDashbroadComponent implements OnInit {
  isLoading: boolean = false;
  dataGrade = [];
  gradeId:string;
  subjectId:string;
  oldGradeId:string;
  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      public dialog: MatDialog,
      private showMessage: ShowMessage,
      private curriculumManagerService: CurriculumManagerService,
    ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.subjectId = params['subjectId'];
      this.getListGrades();
    });
    this.activatedRoute.queryParams.subscribe(res=> {
      this.gradeId = res?.gradeId;
    })
  }

  getListGrades() {
    let dataInput = {
      keyword: "",
      trainingLevel: null
    };
    this.curriculumManagerService.getGradesBySubject(this.subjectId,dataInput).subscribe((res: any) => {
      if(res.status == 1) {
        this.dataGrade = res.data;
      } else {
        this.showMessage.error(res.msg);
      }
      
    }, (err:any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  chooseGrade(gradeId, gradeName) {
    this.oldGradeId = this.activatedRoute.snapshot.queryParams.gradeId;
    this.gradeId = gradeId;
    // console.log(this.gradeId, 'xxxxxx', this.oldGradeId);
    
    let subjectName = this.activatedRoute.snapshot.queryParamMap.get('subjectName');
    if(this.gradeId == this.oldGradeId) {
      gradeId = 'null';
      // console.log('nulll');
      
    } 
    this.router.navigate(
      [`curriculum/list/${this.subjectId}`], 
      {
        // relativeTo: this.activatedRoute,
        queryParams: {gradeId: gradeId, subjectName: subjectName},  
        queryParamsHandling: 'merge'
    });
    
    
    
  }
}
