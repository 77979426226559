import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailAccountComponent } from './components/detail-account/detail-account.component';
import { AccountsManagerListComponent } from './components/accounts-manager-list/accounts-manager-list.component';
import {ImportUsersComponent} from './components/import-users/import-users.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import {USER_PERMISSION} from './constants';

const routes: Routes = [
  {
    path: '',
    component: AccountsManagerListComponent,
    canActivate: [NgxPermissionsGuard],
        data: {
        permissions: {
            only: [
              USER_PERMISSION.perm1
            ],
            redirectTo: '/accessdenied'
        }
        }
  },
  {
    path: 'info-account/:id',
    component: DetailAccountComponent,
    canActivate: [NgxPermissionsGuard],
        data: {
        permissions: {
            only: [
              USER_PERMISSION.perm3
            ],
            redirectTo: '/accessdenied'
        }
        }
  },
  {
    path: 'import-user',
    component: ImportUsersComponent,
    canActivate: [NgxPermissionsGuard],
        data: {
        permissions: {
            only: [
              USER_PERMISSION.perm6
            ],
            redirectTo: '/accessdenied'
        }
        }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsManagerRoutingModule { }
