import { Component, OnInit } from '@angular/core';
import {lang} from '../../../general-configuration.lang';
import {GeneralConfigService, ListenFirebaseService} from '@app/_services';
import {ShowMessage} from '@app/_services';
import {FormBuilder, Validators} from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
@Component({
  selector: 'app-email-sever',
  templateUrl: './email-sever.component.html',
  styleUrls: ['./email-sever.component.scss']
})
export class EmailSeverComponent implements OnInit {
  panelOpenState = true;
  lang = lang;
  infoForm;
  isLoading = false;
  isValidate = false;
  constructor(
      private showMessage: ShowMessage,
      private generalConfigService: GeneralConfigService,
      private fb: FormBuilder,
      private db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService
  ) { }

  typeModule = 'admin_config';

  checkFireBase(){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('update', this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.isLoading = false;
        this.getInfoEmailServerConfig();
      } else{
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.getInfoEmailServerConfig();
  }

  getInfoEmailServerConfig(){
    this.isLoading = true;
    this.generalConfigService.getInfoEmailServer().subscribe((res: any) => {
      this.infoForm = this.fb.group({
        smtp_url: [res.data.smtp_url, [Validators.maxLength(50), Validators.email]],
        smtp_port: [res.data.smtp_port, Validators.maxLength(10)],
        smtp_user: [res.data.smtp_user, Validators.maxLength(50)],
        smtp_password: [res.data.smtp_password, Validators.maxLength(50)],
        smtp_tls: [res.data.smtp_tls === '1' ? true: false]
      });
      this.isLoading = false;
    });
  }

  onSubmit(data){
    this.isLoading = true;
    if (this.infoForm.invalid){
      this.isValidate = true;
    }

    let uploadData = data;
    uploadData.smtp_tls = data.smtp_tls === true ? '1' : '0';
    this.checkFireBase();
    this.generalConfigService.updateEmailServerConfig(uploadData).subscribe((res: any) => {
      this.isLoading = false;
      if (res.status === 0) {
        this.showMessage.error(res.msg); 
        this.isLoading = false;
      } 
    }, (err: any) => {
      this.isLoading = false;
    });
    setTimeout(() => {
      if(this.isLoading){
          this.isLoading = false;
      }
  }, TIME_OUT_LISTEN_FIREBASE);

  }

  cancel(){
    this.getInfoEmailServerConfig();
  }
}

