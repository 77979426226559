import { Component, OnInit, Inject, NgModule} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {ModuleSoftwareManagerService} from '@app/_services';
import {MatTableDataSource} from '@angular/material/table';
import * as $ from 'jquery';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {ConfirmComponent} from '@app/_shared/dialogs/confirm/confirm.component';
import {ShowMessage} from "@app/_services";
import * as _ from 'lodash';

@Component({
  selector: 'app-dialog-add-customer',
  templateUrl: './dialog-add-customer.component.html',
  styleUrls: ['./dialog-add-customer.component.scss']
})
export class DialogAddCustomerComponent implements OnInit {
  dataColumn = ['stt', 'fullName', 'code', 'domain', 'phone', 'email'];
  dataSource = [];
  isCheckAll = false;
  checkStatus: boolean;
  keyword = '';
  isLoading = false;
  countCustom = 0;
  constructor(private dialogRes: MatDialogRef<DialogAddCustomerComponent>,
              private moduleSoftwareManagerService: ModuleSoftwareManagerService,
              @Inject(MAT_DIALOG_DATA) public data,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private showMessage: ShowMessage) {
  }


  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(){
    this.moduleSoftwareManagerService.listTenantNotBelongSubSystem(this.data.subSystemId, this.keyword).subscribe((res: any) => {
      this.dataSource = res.data;
      return this.dataSource.forEach(item => {
        item.isChecked = false;
      });

    })
  }

  checkAll(){
    this.isCheckAll = !this.isCheckAll;

    this.dataSource.forEach(item => item.isChecked = this.isCheckAll);

    this.countCustom = this.dataSource.filter(item => item.isChecked === true).length;
  }

  chooseInfo(keyDataSource: number){
    this.dataSource[keyDataSource].isChecked = !this.dataSource[keyDataSource].isChecked;
    let check = this.dataSource.map(e => e.isChecked).indexOf(false);

    if (check === -1)this.isCheckAll = true;
    else this.isCheckAll = false;

    this.countCustom = this.dataSource.filter(item => item.isChecked === true).length;
  }

  blurSearch(value){
    this.keyword = value.trim();
    this.loadDataItems();
  }

  keyPressSearch(event,value){
    if (event.code === 'Enter' || event.code == "Tab"){
      this.keyword = value;
      this.loadDataItems();
    }
  }

  checkAllCheckBox(event) {
    $.each(this.dataSource, (index, item) => {
      item.is_active = event.checked;
    });
    this.checkAll = event.checked;
    this.checkStatus = event.checked;
  }

  onSubmit() {
    this.isLoading = true;
    let ids = [];
    this.dataSource.forEach(item => {
      if (item.isChecked) ids.push(item.id);
    });
    if (ids.length === 0) {
      this.isLoading = false;
      return this.showMessage.warring('Bạn chưa chọn khách hàng nào !!');
    }
    const dialogRef =  this.dialog.open(ConfirmComponent, {
      width: '50%',
      height: 'auto',
      data: {
        title: 'Bạn đã thực hiện thêm khách hàng áp dụng phân hệ',
        name: this.data.subSystemName,
        message: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
        btnCancel: 'HỦY',
        btnConfirm: 'XÁC NHẬN',
        color: '#FF4848'
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm)
        return this.moduleSoftwareManagerService.addTenant(this.data.subSystemId, ids).subscribe((res: any) => {
          this.isLoading = false;
          if (res.status === 0) return this.showMessage.error(res.msg);
          this.dialogRes.close(res);
        });

      this.isLoading = false;
    })

  }

}
