<h2 mat-dialog-title class="title-dialog text-center mb-3">{{data.status == 'add' ? 'Thêm module' : 'Chỉnh sửa thông tin module'}}</h2>
<div class="container-fluid" fxLayout="column">
    <div class="spinner" *ngIf="isLoadingResults">
        <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="infoModel" (ngSubmit)="onSubmit(infoModel.value)">
        <mat-dialog-content class="mat-typography">
            <div class="col-md-12 d-flex align-items-center flex-column">
                <div class="img-module" (click)="uploadImage()">
                    <img [src]="urlImage ? urlImage : 'https://increasify.com.au/wp-content/uploads/2016/08/default-image.png'" class="avatar">
                    <div class="box-image"><label><mat-icon >photo library</mat-icon><span>Thay đổi ảnh </span></label></div>
                    <input type="file" class="avatar-file" name="avatar" hidden id="inputUploadFile" (change)="onFileChange($event)">
                </div>
            </div>
            <div class="panel-title">Thông tin module</div>
            <!-- Thông tin cơ bản -->
            <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content-global">
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global"> Tên module <span class="text-danger"> (*)</span></label>
                        <div class="col-md-8 pl-0 pr-0">
                            <input formControlName="fullname" type="text" class="form-control input-field-global" placeholder="Nhập tên module">
                            <mat-error *ngFor="let validation of messageValidate.fullname">
                                <p class="error-message" *ngIf="infoModel.get('fullname').hasError(validation.type) && (infoModel.get('fullname').dirty || infoModel.get('fullname').touched || checkValidate == true)">
                                    {{validation.message}}
                                </p>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group col-md-6 ml-auto row row-global p-0">
                        <label class="col-md-3 offset-1 lb-global"> Mã module <span class="text-danger"> (*)</span></label>
                        <div class="col-md-8 pl-0 pr-0">
                            <input (click)="formatErr()" [readOnly]="data.status !== 'add' ? true : false" formControlName="code" type="text" class="{{ data.status !== 'add' ? 'custom-disable' : '' }} form-control input-field-global" placeholder="Nhập mã module">
                            <span class="text-danger">{{resErrorCode}}</span>
                            <mat-error *ngFor="let validation of messageValidate.code">
                                <p class="error-message" *ngIf="infoModel.get('code').hasError(validation.type) && (infoModel.get('code').dirty || infoModel.get('code').touched || checkValidate == true)">
                                    {{validation.message}}
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global">Thứ tự sắp xếp</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <input formControlName="indexOrder" onlyNumber (keypress)="numberOnly($event)" type="number" min="0" class="form-control input-field-global" placeholder="Thứ tự sắp xếp">
                            <ng-container *ngIf="infoModel.get('indexOrder')?.invalid && infoModel.get('indexOrder').dirty">
                                <span *ngIf="infoModel.get('indexOrder').errors.pattern" class="text-danger pt-2">
                                    {{messageValidate.indexOrder}}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="form-group col-md-6 ml-auto row row-global p-0">
                        <label class="col-md-3 offset-1 lb-global">Nhóm module</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <select formControlName="group_module" class="form-control input-field-global">
                                <option value="0">Khách hàng</option>
                                <option value="1">Core admin</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0 ">
                        <label class="col-md-3 lb-global">Trạng thái</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <mat-checkbox formControlName="isActive">
                                Kích hoạt
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="row" fxLayout="row">
                    <div class="col-md-12 d-flex p-0">
                        <label style="width: 13%;">Mô tả</label>
                        <div style="width: 90%;">
                            <textarea formControlName="description" type="text" class="form-control input-field-global" rows="4" placeholder="Nhập mô tả"></textarea>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <!-- Thông tin cơ bản -->

        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <a mat-dialog-close class="custom-btn-add-module btn btn-close" (click)="onDissmiss()">Hủy</a>
            <button type="submit" class="custom-btn-add-module btn btn-add-edit ml-2 px-4">
                {{data.status === 'add' ? 'Thêm' : 'Lưu'}}
            </button>
        </mat-dialog-actions>

    </form>
</div>