import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/_services/notification.service';
import * as moment from 'moment';
import * as $ from 'jquery';
let NOTI = [
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
  {title: 'Thông báo: điều chỉnh năm học', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus', createdBy: 'huy', expired: '30 phút'},
];

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  array:any;
  showSearch: boolean = false;
  showFilter: boolean = false;
  dataSearch: string = '';
  check = false;
  date: any;
  filter: number = 1;
  fromDate:number;
  toDate:number;
  customFromDate:any;
  customToDate:any;
  isLoading:boolean = false;
  constructor(private notificationService:NotificationService) { }

  ngOnInit(): void {
    this.getDataNotification();
    $("#leader-line-defs").remove();
  }

  getDataNotification(){
    this.isLoading = true;
    this.notificationService.getDataNotification(15,0).subscribe(
      (res:any)=>{
        if(res.status == 1){
          this.array = res.data;
        }
        this.isLoading = false;
      },
      (err)=>{
        this.isLoading = false;
      }
    )
  }

  filterNotification(value:any){
    this.clickBtnFilterNotification(value);
  }

  changeFilter(event){
    this.filter = Number(event.target.value);
  }

  toggleClass(value, check){
    if (!check){
      this.showSearch = !this.showSearch;
      ( document.getElementById('search-value') as HTMLInputElement).value = '';
    }
    else{
      this.check = false;
      this.dataSearch = value;
    }
  }

  clickBtnFilterNotification(value:any){
    this.isLoading = true;
    if(this.filter == 1){
      this.fromDate = Number(moment().format('YYYYMMDD'));
      this.toDate = Number(moment().format('YYYYMMDD'));
    }else if(this.filter == 2){
      var now = moment();
      this.fromDate = Number(moment(now.clone().weekday(1)).format('YYYYMMDD'));
      this.toDate = Number(moment(now.clone().weekday(7)).format('YYYYMMDD'));
    }else if(this.filter == 3){
      this.fromDate = Number(moment().clone().startOf('month').format('YYYYMMDD'));
      this.toDate = Number(moment().clone().endOf('month').format('YYYYMMDD'));
    }else{
      this.fromDate = Number(moment(this.customFromDate).format('YYYYMMDD'));
      this.toDate = Number(moment(this.customToDate).format('YYYYMMDD'));
    }
    this.notificationService.filterNotification(15,0,value,this.fromDate,this.toDate).subscribe(
      (res:any)=>{
        this.array = res.data;
        this.isLoading = false;
      },
      (err)=>{
        this.isLoading = false;
      }
    )
  }

  checkText(){
    let demo = ( document.getElementById('search-value') as HTMLInputElement).value;

    if (demo.length > 0){
      this.check = true;
    }
    else {
      this.check = false;
    }
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
  }

}
