import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogSelectOptionComponent } from '@app/_modules/general-configurations/dialogs/dialog-select-option/dialog-select-option.component';
import { GeneralConfigService, ListenFirebaseService, ShowMessage, TenantManagerService } from '@app/_services';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { MESSAGE_ERROR_CALL_API, TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { Subscriber } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-tab-config-private-key-upload-file',
  templateUrl: './tab-config-private-key-upload-file.component.html',
  styleUrls: ['./tab-config-private-key-upload-file.component.scss']
})
export class TabConfigPrivateKeyUploadFileComponent implements OnInit {
  formSubmit: FormGroup;
  listTenants: any = [];
  isLoading: boolean = false;
  keyword: string = '';
  isCreate: boolean = false;
  listDVGDs: any = [];
  privateKey:any;

  constructor(
    public dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private generalConfigService: GeneralConfigService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getPrivateKey();
  }

  generatePrivateKey(){
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < 50; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.privateKey = result;
  }

  getPrivateKey(){
    this.generalConfigService.getPrivateKeyUploadFile({}).subscribe((res:any)=>{
      if(res.status == 1){
        this.privateKey = res.data.privateKey;
      }
    })
  }

  add() {
    let dataInput = {
      privateKey: this.privateKey
    }
    this.isLoading = true;
    this.listenFireBase("update");
    this.setTimOutFireBase();
    this.generalConfigService.updatePrivateKeyUploadFile(dataInput).subscribe((res:any)=>{
      if(res.staus == 0){
        this.showMessage.error(res.msg);
        this.isLoading = false;
      }
    })
  }

  listenFireBase(action) {
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(action, 'admin_config', subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref === true) {
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  setTimOutFireBase() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
}
