import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject, HostListener, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoleManagerService } from '@app/_services';
import { Role } from '@app/_models/role';

@Component({
  selector: 'app-dialog-role-list-users',
  templateUrl: './dialog-role-list-users.component.html',
  styleUrls: ['./dialog-role-list-users.component.scss']
})
export class DialogRoleListUsersComponent implements OnInit {
    dataColumn = ['stt', 'fullName', 'code', 'phone', 'email'];
    dataSource: any;
    length: number;
    flag = true;
    isLoading = false;

    constructor(
        private eRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogRes: MatDialogRef<DialogRoleListUsersComponent>,
        private roleManagerService: RoleManagerService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit(): void {
        this.getAllUserByRole();
    }

    getAllUserByRole(keyWord?: string) {
        this.roleManagerService.getUsersOfRole(this.data.roleId, keyWord, 0, 999999, '').subscribe(
            (data: any) => {
                this.dataSource = new MatTableDataSource(data.data);
                this.length = data.data.length;
                this.isLoading = false;
            },
            (error => {
            })
        );
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.eRef.nativeElement.contains(event.target)) {
            this.flag = true;
        } else {
            this.flag = false;
        }
    }

    checkText(event, value) {
        setTimeout(() => {
            if (this.flag === true){
                if (event.keyCode === 13 || event.keyCode === 9) {
                    this.isLoading = true;
                    setTimeout(() => {
                        this.getAllUserByRole(value);
                    }, 1000);
                }
            }
        }, 500);
    }
}
