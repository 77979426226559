import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionListComponent } from '@app/_modules/permission-manager/components/permission-list/permission-list.component';
import { PermissionDetailComponent } from '@app/_modules/permission-manager/components/permission-detail/permission-detail.component';

const routes: Routes = [
  {
    path: '',
    component: PermissionListComponent,
  },
  {
    path: 'detail-permission/:id',
    component: PermissionDetailComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PermissionManagerRoutingModule { }
