<div fxLayout="column" class="bg-content-global">
    <div fxFlex fxLayout="column" class="role-page-detail" >
        <p fxFlex="50px" class="title-page-global">Môn học - {{item?.fullname}}</p>
        <div fxFlex fxLayout="column" class="content-role-page-detail" >
            <div fxFlex="265px" fxLayout="column">
                <div fxFlex="55px" fxLayout="row" fxLayoutAlign="space-between start">
                    <p class="title-profile-role" >Thông tin cơ bản</p>
                    <div>
                        <ng-template ngxPermissionsOnly="{{permissionSubject.perm4}}">
                            <button class="btn-subject-manager-global mat-elevation-z" *ngIf="item?.isMoetSubject === 1" mat-raised-button (click)="openDialogUpdate(item)"><img class="mr-2 mb-1" src="assets/images/icons/icon-21.svg"> Chỉnh sửa thông tin </button>
                        </ng-template>
                    </div>
                </div>
                <div fxFlex="210px" style="border-bottom: 1px solid black">
                    <div class="row row-global">
                        <div class="col-lg-4 form-group row col-global row-global" >
                            <label class="col-sm-3 lb-global ">Tên môn học</label>
                            <div class="col-sm-8 pl-0 pr-0">
                                <input type="text" class="form-control input-field-global" disabled value="{{item?.fullname}}">
                            </div>
                        </div>
                        <div class="col-lg-4 form-group row col-global row-global" >
                            <label class="offset-1 col-sm-3 lb-global">Mã môn học</label>
                            <div class="col-sm-8 pl-0 pr-0 ">
                                <input type="text"  class="form-control input-field-global" disabled value="{{item?.code}}">
                            </div>
                        </div>
                        <div class="col-lg-4 form-group row col-global row-global" >
                            <label class="offset-1 col-sm-3 lb-global">Nhóm môn học</label>
                            <div class="col-sm-8 pl-0 pr-0 ">
                                <input type="text"  class="form-control input-field-global" disabled [value]="item?.isMoetSubject === 1? 'MOET' : 'Môn học thường'">
                            </div>
                        </div>
                    </div>
                    <div class="row row-global">
                        <div class="col-lg-4 form-group row col-global row-global" >
                            <label class="col-sm-3 lb-global">Cấp</label>
                            <div class="col-sm-8 pl-0 pr-0">
                                <input type="text"  class="form-control input-field-global" disabled value="{{item?.trainingLevel}}">
                            </div>
                        </div>
                        <div class="col-lg-4 form-group row col-global row-global" >
                            <label class="offset-1 col-sm-3 lb-global">Thứ tự sắp xếp</label>
                            <div class="col-sm-8 pl-0 pr-0 ">
                                <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1"  class="form-control input-field-global" disabled value="{{item?.indexOrder}}">
                            </div>
                        </div>
                        <div class="col-lg-4 form-group row col-global row-global">
                            <label class="offset-1 col-sm-3 lb-global">Trạng thái</label>
                            <div class="col-sm-8 pl-0 pr-0 lb-global">
                                <mat-checkbox disabled readonly class="checkbox-form-status" [checked]="item?.status === 1">Kích hoạt</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" class="row row-global">
                        <label class="lb-global" fxFlex="8.5%">Dữ liệu</label>
                        <div fxFlex class="form-group pr-0 col-global row-global">
                            <textarea class="form-control input-field-global col-sm-12" readonly style="background-color: #F2F3F8">{{item?.description}}</textarea>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template [ngxPermissionsOnly]="[permissionSubject.perm10, permissionSubject.perm7]">
                <div fxFlex="98px" fxLayout="column" class="div-btn-tab" >
                    <div  fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between start" class=" mt-2 bg-white">
                        <div fxFlex="30%">
                            <ng-template ngxPermissionsOnly="{{permissionSubject.perm7}}">
                                <button *ngIf="item?.isMoetSubject !== 0" class="btn mr-2  mat-elevation-z text-center" [class]="tab === 'tab1' ? 'btn-filter-subject-select' : 'btn-filter-subject'" mat-raised-button (click)="changeTab('tab1')" >Khách hàng</button>
                            </ng-template>
                            <ng-template ngxPermissionsOnly="{{permissionSubject.perm10}}">
                                <button class="btn mr-2  mat-elevation-z text-center" [class]="tab === 'tab2' ? 'btn-filter-subject-select' : 'btn-filter-subject'" mat-raised-button (click)="changeTab('tab2')" >Khối</button>
                            </ng-template>
                        </div>
                        <div fxFlex="70%" fxLayout="row" fxLayoutAlign="end none" >
                            <input (keydown)="checkText($event, search.value)" #search type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="text-left btn button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? tab === 'tab1' ? 'Tên hoặc mã khách hàng' : 'Tên hoặc mã khối' : ''}}" (blur)="checkText($event, search.value)" >
                            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass()">
                            <button class="btn btn-default btn-filter ml-3" *ngIf="tab === 'tab1' && item?.isMoetSubject !== 0">
                                <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilterClick()">
                            </button>
                            <ng-template ngxPermissionsOnly="{{permissionSubject.perm8}}">
                                <button class="btn btn-add-global mat-elevation-z" *ngIf="tab === 'tab1' && item?.isMoetSubject !== 0 "  mat-raised-button (click)="showAddCustomer()"><mat-icon>add</mat-icon>Thêm khách hàng </button>
                            </ng-template>
                            <ng-template ngxPermissionsOnly="{{permissionSubject.perm11}}">
                                <button class="btn btn-add-global mat-elevation-z" *ngIf="tab === 'tab2' && item?.isMoetSubject !== 0"  mat-raised-button (click)="showAddGrade()"><mat-icon>add</mat-icon>Thêm khối</button>
                            </ng-template>
                        </div>
                    </div>
                    <div fxFlex="40px" *ngIf="showFilter && tab === 'tab1'" style="width: 70%"  fxLayout="row" fxLayoutAlign="flex-end center" style="padding-right: 2rem;">
                        <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 0)">Chưa kích hoạt</button>
                        <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 1)">Dùng thử</button>
                        <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 2)">Đang sử dụng</button>
                        <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 3)">Hết hạn sử dụng</button>
                        <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 4)">Hết hạn dùng thử</button>
                        <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 99)">Khác</button>
                        <button class="btn btn-default btn-filter-option-action" (click)="filterStatus()">Lọc</button>
                    </div>
                </div>
            </ng-template>
            <div fxFlex class="detail-content">
                <ng-template ngxPermissionsOnly="{{permissionSubject.perm7}}">
                    <app-subjects-list-tenant *ngIf="tab === 'tab1'"[onChange]="change" [itemId]="id" [itemName]="item?.fullname" [keyword]="dataSearchCustomer" [dataFilter] = "dataFilterApp" ></app-subjects-list-tenant>
                </ng-template>
                <ng-template ngxPermissionsOnly="{{permissionSubject.perm10}}">
                    <app-subject-list-grade *ngIf="tab === 'tab2'" [itemId]="id" [onChange]="change" [itemName]="item?.fullname" [keyword]="dataSearchGrade"></app-subject-list-grade>
                </ng-template>
            </div>
        </div>
    </div>
</div>
