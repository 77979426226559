import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertStringListRolePipe'
})
export class ConvertStringPipe implements PipeTransform {
  transform(items: any[], field: string): string {
    // [fullname1, fullname2, fullname2]    
    const temp = items.map(x => {
            return x[field];
        }); 

        // "fullname1, fullname2, fullname3"
        let convertString =  temp.join(",");
        if(convertString.length > 10){
          convertString = convertString.substring(0, 30)+'...'
        }
        return convertString;
  }
}

