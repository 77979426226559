import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-dialog-form-tenant-module',
  templateUrl: './dialog-form-tenant-module.component.html',
  styleUrls: ['./dialog-form-tenant-module.component.scss']
})
export class DialogFormTenantModuleComponent implements OnInit {
  public formTenantModule: FormGroup;

  public id:any;
  public module_id:number;
  public status:number;

  hideButtonAdd:boolean;

  panelOpenState = true;
  constructor(private fb: FormBuilder,
    private dialogref: MatDialogRef<DialogFormTenantModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }
  
  ngOnInit(): void {
    this.data?.status == "update" ? this.hideButtonAdd = false : this.hideButtonAdd = true;
    this.formTenantModule = this.fb.group({
      id: [this.data?.tenant?.id ? this.data.tenant.id : this.id],
      module_id: [this.data?.tenant?.module_id ? this.data.tenant.module_id : this.module_id,[Validators.required]],
      status:  [this.data?.tenant?.status ? this.data.tenant.status : this.status]
    });
  }

  onClickSubmit(value):void{

  }

  // validate
  tenant_validation_messages = {
    'module_id': [
      { type: 'required', message: 'chưa chọn module' }
    ]
  }

}
