import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
import { DialogCurriculumComponent } from '../../dialogs/dialog-curriculum/dialog-curriculum.component';

@Component({
  selector: 'app-curriculum-form',
  templateUrl: './curriculum-form.component.html',
  styleUrls: ['./curriculum-form.component.scss']
})
export class CurriculumFormComponent implements OnInit {
  isLoading: boolean = false;
  nameForm ='create';
  active_tab = 'content';
  messageValidate = {
    name: [
      { type: 'required', message: 'Bạn chưa nhập tên khung chương trình' },
      { type: 'pattern', message: 'Tên khung chương trình phải từ 2 - 250 ký tự' },
    ],
    code: [
      { type: 'required', message: 'Bạn chưa nhập mã khung chương trình' },
      { type: 'pattern', message: 'Mã khung chương trình chỉ chấp nhận  a-z, A-Z, 2-50, _, - ' }
    ],
  }
  infoCurriculum:any;
  subjectId:string = '';
  gradeId:string = '';
  curriculumId:string = '';
  constructor(
      private activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      private curriculumManagerService: CurriculumManagerService,
      private router: Router
  ) {
    
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
      this.curriculumId = res.idCurriculum;
      this.getDetailCurriculum();
    })
    this.activatedRoute.queryParams.subscribe(res => {
      this.nameForm = res.nameForm;
      this.active_tab = res.tab;
    })
  }

  clickTab(tab?: string){
    this.active_tab = tab;
    this.router.navigate([`curriculum/form/${this.curriculumId}`], {queryParams: { nameForm: this.nameForm, tab: this.active_tab}});
  }

  getDetailCurriculum() {
    this.isLoading = true;
    this.curriculumManagerService.getDetailCurriculum(this.curriculumId).subscribe((res:any) => {
      this.isLoading = false;
      this.infoCurriculum = res.data;
    }, (err:any) => {
      this.isLoading = false;
    })
  }
  
  openCurriculum() {
    const dialogRef = this.dialog.open(DialogCurriculumComponent, {
      width: '60%',
      data: {
        nameForm: 'update',
        data: this.infoCurriculum 
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getDetailCurriculum();
      }
    });
  }
}
