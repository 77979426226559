import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';
import { ActivatedRoute } from '@angular/router';

import { DialogFormEducationLv2Component } from '../../dialog/dialog-form-education-lv2/dialog-form-education-lv2.component';
import { DialogFormEducationLv3Component } from '../../dialog/dialog-form-education-lv3/dialog-form-education-lv3.component';
import { IndustryUnitService } from '@app/_services/industry-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ListenFirebaseService} from '@app/_services';
// import leader line
import 'leader-line';
import { forEachChild } from 'typescript';
import { DialogMoveEducationComponent } from '../../dialog/move-education/dialog-move-education.component';
import { DialogMoveEducationLv2Component } from '../../dialog/move-education-lv2/dialog-move-education-lv2.component';
declare var LeaderLine: any;
import {ADMIN_MOET_UNIT_PERMISSION} from '../../constants';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

interface FoodNode {
  name: string;
  code?: string;
  parent_code?: string;
  children?: FoodNode[];
}
interface FoodNode1 {
  name: string;
  parent_code_lv1?: string;
  parent_code?: string;
  children?: FoodNode[];
}


@Component({
  selector: 'app-detail-education-lv2',
  templateUrl: './detail-education-lv2.component.html',
  styleUrls: ['./detail-education-lv2.component.scss']
})
export class DetailEducationLv2Component implements OnInit {
  treeControl2 = new NestedTreeControl<FoodNode1>(node2 => node2.children);
  dataSource2 = new MatTreeNestedDataSource<FoodNode1>();
  constructor(
    public dialog: MatDialog,
    private activatedRoute:ActivatedRoute,
    private industryUnitService:IndustryUnitService,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService
  ){
    // this.dataSource2.data = TREE_DATA_2;
  }
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
  hasChild2 = (_: number, node2: FoodNode1) => !!node2.children && node2.children.length > 0;
  code_active = '';
  code_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  show_level_2 = false;
  show_level_3 = false;
  showSearch: boolean = false;
  dataSearch: string = '';
  isLoading:boolean = false;
  idEducation2:any;
  dataDetailEducation2:any;
  nameEducationLv1:any;
  // khai báo phần leaderline
  lineLv2 = [];
  lineLv3 = [];
  checkOpenPGD:boolean = false;
  checkOpenTHPT:boolean = false;
  checkOpenTHCS:boolean = false;
  checkOpenTH:boolean = false;
  checkOpenMG:boolean = false;
  countPGD:number = 0;
  countTHPT:number = 0;
  countTHCS:number = 0;
  countTH:number = 0;
  countMG:number = 0;
  dataPGD = [];
  dataTHPT = [];
  dataTHCS = [];
  dataTH = [];
  dataMG = [];
  checkClickLv1:boolean = false;
  checkClickLv2:boolean = false;
  parent_lv2:string='';
  parent_lv3:string='';
  checkUnit = true;

  typeModule = 'moet_unit';
  ADMIN_MOET_UNIT_PERMISSION= ADMIN_MOET_UNIT_PERMISSION;

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataDeatilEducation();
        this.isLoading = false;
      }
      this.isLoading = false;
    });
  }
  ngOnInit(){
    this.getDataDeatilEducation();
  }

  getDataDeatilEducation(){
    this.isLoading = true;
    //get param
    this.idEducation2 = this.activatedRoute.snapshot.params.id;
    this.industryUnitService.getDetailEducation(this.idEducation2).subscribe(
      (res:any)=>{
        if(res.data.unit == 3){
          this.checkUnit = false;
        }
        this.dataDetailEducation2 = res.data;
        this.dataDetailEducation2.status = Number(this.dataDetailEducation2.status);
        this.openLv3(res.data.code);
        this.isLoading = false;
      },
      (err)=>{
        this.isLoading = false;
      }
    );
  }

  openLv3(code2){
    this.industryUnitService.getListEducationLv3(code2).subscribe(
      (res:any)=>{
        this.code_active_lv_2 = code2;
        let count_thcs:number = 0;
        let count_tieu_hoc:number = 0;
        let count_mau_giao:number = 0;
        let code_parent:string;
        let code_parent_lv1:string;
        this.checkClickLv2 = true;
        this.dataTHCS = [];
        this.dataTH = [];
        this.dataMG = [];
        this.parent_lv3 = code2;
        (res.data).forEach((e:any)=>{
          code_parent = e.phongGD;
          code_parent_lv1 = e.soGD;
          if(e.unit == 4){
            count_thcs++;
            this.dataTHCS.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else if(e.unit == 5){
            count_tieu_hoc++;
            this.dataTH.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            });
          }else{
            count_mau_giao++;
            this.dataMG.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon:e.countMamNon,
              countTH:e.countTH,
              countTHCS:e.countTHCS,
              nameSoGD:e.nameSoGD,
              namePhongGD:e.namePhongGD
            })
          }
        });
        this.countTHCS = count_thcs;
        this.countTH = count_tieu_hoc;
        this.countMG = count_mau_giao;
      },
      (err)=>{
      }
    )
  }

  closeTree2(code:string,code_lv1?:string){
    if(this.code_active_lv_2 == code){
      this.close_lv_3 = !this.close_lv_3;
    }
  }

  checkDataLv1(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.parent_code == code){
        check = true;
      }
    });
    return check;
  }

  checkDataLv2(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.parent_code == code){
        check = true;
      }
    });
    return check;
  }

  toggleClass(value){
    this.dataSearch = value;
    this.showSearch = !this.showSearch;
  }

  clickEditEducationLv2(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      }
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickEditEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  clickDeleteEducation(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa khách hàng',
        message:'Xác nhận xóa?',
        btnOkText:'Xóa',
        btnCancelText:'Hủy',
        data: {
          id:data.id,
          name:data.name
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.industryUnitService.deleteEducation(data.id).subscribe(
          (res)=>{
            this.checkFireBase('delete');
          },
          (err)=>{
            this.getDataDeatilEducation();
            this.showMessage.success("Xóa nghành dọc không thành công");
          }
        )
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clickAddEucationLv3(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"add",
        infoParent:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('create');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }

  schowTHCS(parent_code){
    this.checkOpenTHCS = !this.checkOpenTHCS;
  }

  schowTH(parent_code){
    this.checkOpenTH = !this.checkOpenTH;
  }

  schowMG(parent_code){
    this.checkOpenMG = !this.checkOpenMG;
  }

  removeLineLeader(){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = this.lineLv3 = [];
  }

  clickMoveEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogMoveEducationLv2Component, {
      height:'auto',
      width:'80%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.checkFireBase('update_vertical_unit');
          if (this.isLoading)  {
          }
        }
      }
    )
  }


}

