<p style="width: 100%; font-size: 18px;text-align: center; margin-top: 20px" *ngIf="length === 0">Không có khối áp dụng</p>
<table mat-table [dataSource]="dataSource" class="table-el" *ngIf="length > 0">
    <ng-container matColumnDef="stt">
        <th mat-header-cell *matHeaderCellDef style="width: 5%">STT</th>
        <td mat-cell *matCellDef="let item, let i = index">{{i + 1}}</td>
    </ng-container>
    <ng-container matColumnDef="grade">
        <th mat-header-cell *matHeaderCellDef style="width: 20%">Khối</th>
        <td mat-cell *matCellDef="let item" ><a class="link_table" href="javascript:;">{{item.fullname}}</a></td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
        <td mat-cell *matCellDef="let item">{{item.code}}</td>
    </ng-container>
    <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Cấp đào tạo</th>
        <td mat-cell *matCellDef="let item">{{item?.trainingLevel}}</td>
    </ng-container>
    <ng-container matColumnDef="tool">
        <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-center">Công cụ</th>
        <td mat-cell *matCellDef="let item" class="text-center"><img src="assets/images/icons/icon-22.svg" style="cursor: pointer" (click)="confirmDelete(item.fullname, item.id)"></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
    <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
</table>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
