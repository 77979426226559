import {Component, OnInit, Inject, HostListener, ElementRef} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { RoleManagerService } from '@app/_services';
import { ShowMessage } from '@app/_services/show-message.service';
import * as _lodash from 'lodash';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
@Component({
  selector: 'app-dialog-role-add-module',
  templateUrl: './dialog-role-add-module.component.html',
  styleUrls: ['./dialog-role-add-module.component.scss']
})
export class DialogRoleAddModuleComponent implements OnInit {
  checkStatus: boolean = false;
  dataPermissions: any;
  dataPermissionAll: any;
  dataShowPermissions = [];
  arrayModuleIds = [];
  // arrayResultPermissions = [];
  checkData: boolean = false;
  isSelectAll: boolean = false;
  // isSelectAllWithModule:boolean = false;
  arrayPermissionsAddToRole = [];
  arrayPermissionsRemoveToRole = [];
  flagCheckAll:number = 0;
  isLoading = false;
  nameRole: any;
  flag = true;
  constructor(
      private eRef: ElementRef,
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) private data,
      private roleManagerService: RoleManagerService,
      private showMessage: ShowMessage,
      private dialogRef: MatDialogRef<DialogChangeActiveComponent>
    ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getAllDataPermissions();
  }
  getAllDataPermissions(keyWord?: string){
    this.roleManagerService.getPermissionsOfRole(this.data.roleId, keyWord, 0, 999999, '').subscribe((data: any) => {
        this.dataPermissions = data.data.permissionCheck;
        this.dataPermissionAll = data.data.permissionAll;
      this.dataPermissionAll.forEach((element) => {

        this.arrayModuleIds.push(element.moduleId);
      });
        this.dataPermissionAll.forEach((element) => {
          this.arrayModuleIds.push(element.moduleId);
        });
        this.arrayModuleIds = this.unique(this.arrayModuleIds);

        // lay danh sach tat ca permisson thuoc cac module id
        this.arrayModuleIds.forEach((element) => {
          let arrayPermissionsTemp = [];
          let moduleId = '';
          let moduleName = '';
          let moduleCode = '';
          let checkIsPerrmissonActive = false;
          this.dataPermissionAll.forEach((item) => {
            if(element == item.moduleId){
              moduleName = item.moduleName;
              moduleCode = item.moduleCode;
              moduleId = item.moduleId;
              var checkActivePermission = this.dataPermissions.filter(element => element.permissionId === item.permissionId);
              if (checkActivePermission.length !== 0){
                item.status = true;
                this.arrayPermissionsAddToRole.push(item.permissionId);
              }else{
                item.status = false;
              }
              arrayPermissionsTemp.push({id: item.permissionId, permission: item.permisionName, code: item.permissionCode, is_selected: item.status});
            }
          });
          this.dataShowPermissions.push({
            id: moduleId,
            stt: moduleId,
            module: moduleName,
            code: moduleCode,
            is_selected: checkIsPerrmissonActive,
            permissionsArray: arrayPermissionsTemp,
          });
          this.dataShowPermissions.find(x => x.stt  === element).is_selected = this.dataShowPermissions.find(x => x.stt  === element).permissionsArray != null && this.dataShowPermissions.find(x => x.stt  === element).permissionsArray.every(t => t.is_selected);
        });
        this.checkData = true;
        this.nameRole = this.data.roleName;
        this.isLoading = false;
    });
  }

  // select all item
  checkSelectAllItem(event){
    this.flagCheckAll = 1;
    this.isSelectAll = event.checked;
    if(event.checked){
      this.arrayPermissionsAddToRole = [];
      this.arrayPermissionsRemoveToRole = [];
      this.dataPermissionAll.forEach((element) => {
        this.arrayPermissionsAddToRole.push(element.permissionId);
      });
    }else{
      this.arrayPermissionsAddToRole = [];
      this.arrayPermissionsRemoveToRole = [];
      this.dataPermissionAll.forEach((element) => {
        this.arrayPermissionsRemoveToRole.push(element.permissionId);
      });
    }
  }

  // select all item of module
  checkSelectAllWithModule(event, moduleId){
    this.checkItemChecked(event, moduleId);
    // console.log(event.source.value);
  }

  // select item
  checkItemChecked(event, moduleId){
    if (event.checked){
      // them o mang them
      this.arrayPermissionsAddToRole.push(event.source.value);

      // xoa o mang xoa
      const index = this.arrayPermissionsRemoveToRole.indexOf(event.source.value);
      if (index > -1) {
        this.arrayPermissionsRemoveToRole.splice(index, 1);
      }
    }else{
      // xoa o mang them
      const index = this.arrayPermissionsAddToRole.indexOf(event.source.value);
      if (index > -1) {
        this.arrayPermissionsAddToRole.splice(index, 1);
      }

      // them o mang xoa
      this.arrayPermissionsRemoveToRole.push(event.source.value);
    }
  }

  addPermissionsToRole(){
    if (this.arrayPermissionsAddToRole.length === 0){
      this.showMessage.warring('Bạn chưa chọn module quyền nào');
      return false;
    }
    const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '36%',
      data: {
        btnCancelText: 'HỦY',
        btnOkText: 'XÁC NHẬN',
        status: false,
        content: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
        title: 'Bạn đang thực hiện thay đổi quyền áp dụng của phân quyền',
        data: {
          name: this.data.roleName,
        }
      }
    });
    confirmActive.afterClosed().subscribe((res: any) => {
      if (res){
        this.isLoading = true;
        this.roleManagerService.assignPermissionsToRole(this.data.roleId, this.arrayPermissionsAddToRole).subscribe(
            (res: any) => {
              if (this.arrayPermissionsRemoveToRole.length > 0){
                this.roleManagerService.removeMultiplePermissionsByRoleId(this.data.roleId, this.arrayPermissionsRemoveToRole).subscribe(
                    (res1: any) => {
                      this.dialogRef.close(true);
                      this.isLoading = false;
                      // this.showMessage.success('Chỉnh sửa quyền thành công!');
                    },
                    (err1: any ) => {
                      this.dialogRef.close(true);
                      this.isLoading = false;
                      // this.showMessage.error('Chỉnh sửa quyền không thành công!');
                    }
                );
              }else{
                this.dialogRef.close(true);
                this.isLoading = false;
                // this.showMessage.success('Chỉnh sửa quyền thành công!');
              }
            },
            (err: any) => {
              this.dialogRef.close(true);
              this.isLoading = false;
              // this.showMessage.error('Chỉnh sửa quyền không thành công!');
            }
        );
      }

    });
  }

  checkAll(event){
    this.checkSelectAllItem(event);
    this.dataShowPermissions.forEach( function (t){
      t.is_selected = event.checked;
      t.permissionsArray.forEach(a => a.is_selected = event.checked);
    });
  }

  checkAllModule(event, itemId: number){
    this.dataShowPermissions.find(x => x.stt  === itemId).permissionsArray.forEach(t => {
      t.is_selected = event.checked;
      if(event.checked){
        if (this.arrayPermissionsAddToRole.indexOf(t.id) < 0){
          this.arrayPermissionsAddToRole.push(t.id);
        }
        const index = this.arrayPermissionsRemoveToRole.indexOf(t.id);
        if (index > -1) {
          this.arrayPermissionsRemoveToRole.splice(index, 1);
        }
      }else{
        // xoa o mang them
        const index = this.arrayPermissionsAddToRole.indexOf(t.id);
        if (index > -1) {
          this.arrayPermissionsAddToRole.splice(index, 1);
        }
        // them o mang xoa
        this.arrayPermissionsRemoveToRole.push(t.id);
      }
    });
    if (event.checked === true) {
      this.dataShowPermissions.find(x => x.stt  === itemId).is_selected = true;
    } else {
      this.dataShowPermissions.find(x => x.stt  === itemId).is_selected = false;
    }
    this.checkStatus =  this.dataShowPermissions.length > 0 && this.dataShowPermissions.every(t => t.is_selected);
  }

  updateAllComplete(itemId) {
    this.dataShowPermissions.find(x => x.stt  === itemId).is_selected = this.dataShowPermissions.find(x => x.stt  === itemId).permissionsArray != null && this.dataShowPermissions.find(x => x.stt  === itemId).permissionsArray.every(t => t.is_selected);
    this.checkStatus =  this.dataShowPermissions.length > 0 && this.dataShowPermissions.every(t => t.is_selected);
  }

  unique(arr) {
    var obj = {}
    var newArr = []
    for (let i = 0; i < arr.length; i++) {
      if (!obj[arr[i]]) {
        obj[arr[i]] = 1
        newArr.push(arr[i])
      }
    }
    return newArr;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  checkText(event, value) {
    setTimeout(() => {
      if (this.flag === true){
        if (event.keyCode === 13 || event.keyCode === 9) {
          this.isLoading = true;
          setTimeout(() => {
            this.dataShowPermissions = [];
            this.arrayModuleIds = [];
            this.arrayPermissionsAddToRole = [];
            this.getAllDataPermissions(value);
          }, 1000);
        }
      }
    }, 500);
  }
}
