export class Post {
    title: string;
    summaryContent: string;
    content: string;
    avatar: string;
    sendRange: number;
    targetGroup: number;
    recipient: number;
    allowSendMail: number;
    allowComment: number;
    sendNotice: number;
    sendSchedule: any;
    sendStatus: number;
    displayStatus: number;
    numberReceive: number;
    numberSent: number;
}

// "content": "Post1",
// "avatar": "Post1",
// "sendRange": 1,
// "targetGroup": 1,
// "recipient": 1,
// "allowSendMail": 1,
// "allowComment": 1,
// "sendNotice": 1,
// "sendSchedule": "2020-12-17T09:29:50.364Z",
// "sendStatus": 0,
// "displayStatus": 1,
// "numberReceive": 0,
// "numberSent": 0,
// "parentId": "00000000-0000-0000-0000-000000000000",
// "tenantId": "00000000-0000-0000-0000-000000000000",
// "isDeleted": 0,
// "createdBy": "00000000-0000-0000-0000-000000000000",
// "createdAt": "2020-12-17T09:45:05.094Z",
// "updatedBy": "00000000-0000-0000-0000-000000000000",
// "updatedAt": "2020-12-17T09:45:05.094Z",
// "createdAtEpochTime": 1608198305,
// "updatedAtEpochTime": 1608198305,
