import { Component, OnInit, Inject } from '@angular/core';
import { TenantManagerService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { Tenant } from '@app/_models';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-tenant-edit-school-year',
  templateUrl: './dialog-tenant-edit-school-year.component.html',
  styleUrls: ['./dialog-tenant-edit-school-year.component.scss']
})
export class DialogTenantEditSchoolYearComponent implements OnInit {

  
  constructor(
    private tenantManagerService: TenantManagerService,
    @Inject(MAT_DIALOG_DATA) public dataInput,
    private dialogRef: MatDialogRef<DialogTenantEditSchoolYearComponent>,
    private listenFirebaseService: ListenFirebaseService,
    private showMessage: ShowMessage,

  ) { }
  keyword = "";
  tenantId: string;
  queryParams: any;
  tenantInfor: Tenant;
  typeModule: "tenant_school_year";
  isLoadingResults = false;

  ngOnInit(): void {
    this.tenantId = this.dataInput.tenantId;
    this.getInfoTenant();
    this.getDataAllSchoolYears();
  }
  data = [];
  getInfoTenant() {
    this.tenantManagerService.getDataTenantById(this.tenantId).subscribe((res: any) => {
      this.tenantInfor = res.data;
    });
  }

  getDataAllSchoolYears() {
    this.isLoadingResults = true;

    return this.tenantManagerService.getAllSchoolYears(this.keyword, this.tenantId).subscribe((res: any) => {
      this.data = res.data;
      this.data.forEach(element => {
        if(element.isAssigned === 1) {
          element.is_selected = true;
        } else {
          element.is_selected = false;
        }
      });    
      this.isLoadingResults = false;  
    });
  }

  submitUpdate() {
    // get ids grades have is_selected: true
    this.isLoadingResults = true;

    let selectIdArr = [];
    this.data.forEach(element => {
        if (element.is_selected === true) {
          selectIdArr.push(element.id);
        }
    });

    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase("update", this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.dialogRef.close(res);
      }
      this.isLoadingResults = false;

    });

    this.tenantManagerService.updateAllSchoolYear(this.tenantId, selectIdArr).subscribe((res: any) => {
      if (res.status == 0) {
        if (this.isLoadingResults) {
          this.isLoadingResults = false;
          this.showMessage.error(res.msg);
        }
      }
    }, error => {
      console.log(error);
    });
    setTimeout(() => {
      if (this.isLoadingResults) {
        this.isLoadingResults = false;
        this.dialogRef.close(true);
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }

  parseTime(param){
    return moment((param*1000)).format('DD/MM/YYYY');
  }
}
