import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListGradeComponent } from './components/list-grade/list-grade.component';
import { DetailGradeComponent } from './components/detail-grade/detail-grade.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import { GRADE_PERMISSION } from '@app/_modules/grades-manager/constant';

const routes: Routes = [
    {
        path: '',
        component: ListGradeComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [GRADE_PERMISSION.perm1],
                redirectTo: '/accessdenied'
            }
        }
    },
    {
        path: 'detail/:id',
        component: DetailGradeComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [GRADE_PERMISSION.perm3],
                redirectTo: '/accessdenied'
            }
        }
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GradesManagerRoutingModule { }
