import { Component, OnInit } from '@angular/core';
import {lang} from '../../../general-configuration.lang';
import {ListenFirebaseService, ShowMessage} from '@app/_services';
import {GeneralConfigService} from '@app/_services';
import * as Editor from 'src/assets/js/ckeditor/build/ckeditor';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
@Component({
  selector: 'app-system-introduction',
  templateUrl: './system-introduction.component.html',
  styleUrls: ['./system-introduction.component.scss']
})
export class SystemIntroductionComponent implements OnInit {
  panelOpenState = true;
  lang = lang;
  public Editor = Editor;
  content;
  isLoading = false;
  constructor(
      private showMessage: ShowMessage,
      private generalConfigService: GeneralConfigService,
      private db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService
  ) { }

  typeModule = 'admin_config';

  checkFireBase(){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('update', this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.isLoading = false;
        this.getIntoConfig();
      } else{
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.getIntoConfig();
  }

  getIntoConfig(){
    this.isLoading = true
    this.generalConfigService.getIntroConfig().subscribe((res: any) => {
      this.content = res.data.about_intro;
      this.isLoading = false;
    });
  }

  onSubmit(){
    this.checkFireBase();
    this.isLoading = true;
    this.generalConfigService.updateIntroConfig(this.content).subscribe((res: any) => {
      if (res.status === 0 ) {
        this.showMessage.error(res.msg);
        this.isLoading = false;
      }   
    }, (err: any) => {
      this.isLoading = false;
    });
    setTimeout(() => {
      if(this.isLoading){
          this.isLoading = false;
      }
  }, TIME_OUT_LISTEN_FIREBASE);
  }

  cancel(){
    this.getIntoConfig();
  }
}

