import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../_shared/dialogs/delete/dialog-delete.component';

import { DialogFormAdministrativeLv1Component } from '../../dialogs/dialog-form-administrative-lv1/dialog-form-administrative-lv1.component';
import { DialogFormAdministrativeLv2Component } from '../../dialogs/dialog-form-administrative-lv2/dialog-form-administrative-lv2.component';
import { DialogFormAdministrativeLv3Component } from '../../dialogs/dialog-form-administrative-lv3/dialog-form-administrative-lv3.component';
import { DialogImportAdministrativeComponent } from '../../dialogs/dialog-import-administrative/dialog-import-administrative.component';
import { DialogMoveAdministrativeComponent } from '../../dialogs/move-administrative/dialog-move-administrative.component';
import { DialogMoveAdministrativeLv3Component } from '../../dialogs/move-administrative-lv3/dialog-move-administrative-lv3.component';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {ListenFirebaseService} from '@app/_services';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
// import leader line
import 'leader-line';
import { forEachChild } from 'typescript';
// declare let LeaderLine: any;
declare var LeaderLine: any;
import { LOCATION_PERMISSION } from '@app/_modules/administrative-units/constant';


@Component({
  selector: 'app-administrative-lv1',
  templateUrl: './administrative-lv1.component.html',
  styleUrls: ['./administrative-lv1.component.scss']
})
export class AdministrativeLv1Component implements OnInit {
  dataSource:any;
  dataSource2:any;
  permissionLocation = LOCATION_PERMISSION;
  constructor(
    public dialog: MatDialog,
    private administrativeUnitService: AdministrativeUnitService,
    private showMessage: ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService
  ){}
  code_active = '';
  code_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  show_level_2 = false;
  show_level_3 = false;
  showSearch: boolean = false;
  dataSearch: string = '';
  resultSearch:any;
  checkResultSearch:boolean = false;
  linkDetailEducation:string;
  // khai báo line
  lineLv2 = [];
  lineLv3 = [];
  checkOpenPGD:boolean = false;
  checkOpenTHPT:boolean = false;
  checkOpenTHCS:boolean = false;
  checkOpenTH:boolean = false;
  checkOpenMG:boolean = false;
  countQuanHuyen:number = 0;
  countPhuongXa:number = 0;
  countTinhTP:number = 0;
  dataQuanHuyen = [];
  dataPhuongXa = [];
  checkClickLv1:boolean = false;
  checkClickLv2:boolean = false;
  parent_lv2:string='';
  parent_lv3:string='';
  fileName:string;


  button = {
    is_active_import_user: false,
    is_active_export_user: false,
  }
  dataImport = [];
  isImportIndustry = false;
  currentPage: number;
  isLoadDataResult = false;
  dataLocationLv1:any;
  dataLocationLv2:any;
  dataLocationLv3:any;
  isLoading:boolean = false;

  ngOnInit(){
    this.getDataLocationLv1();
  }

  typeModule = 'location';

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataLocationLv1();
      }
    });
  }

  getDataLocationLv1(){
    this.isLoading = true;
    this.administrativeUnitService.getLocationLv1().subscribe(
      (res:any)=>{
        this.countTinhTP = res.data.length;
        this.dataLocationLv1 = res.data;
        this.isLoading = false;
      },
      (err)=>{
        this.isLoading = false;
      }
    )
  }

  openLv2(idLevel1){
    this.countQuanHuyen = 0;
    if(this.lineLv2.length > 0){
      for (let index = 0; index < this.lineLv2.length; index++) {
        this.lineLv2[index]?.remove();
      }
    }
    if(this.lineLv3.length > 0){
      for (let index = 0; index < this.lineLv3.length; index++) {
        this.lineLv3[index]?.remove();
      }
    }
    
    this.lineLv2 = [];
    this.lineLv3 = [];
    this.code_active = idLevel1;
    this.parent_lv2 = idLevel1;
    this.administrativeUnitService.getLocationLv2(idLevel1).subscribe(
      (res:any)=>{
        this.countQuanHuyen = res.data.length;
        (res.data).forEach(e=>{
          setTimeout(() => {
            this.lineLv2.push(
              new LeaderLine(
                document.getElementById(idLevel1),
                document.getElementById(e.id),
                {color: 'black',size:1,path:'grid',endPlug:'behind'}
              )
            );
          }, 20);
        });
        this.dataQuanHuyen = res.data;
        this.checkClickLv1 = true;
        this.checkClickLv2 = false;
      },
      (err)=>{
      }
    )
  }

  openLv3(idLevel2){
    if(this.lineLv3){
      this.lineLv3?.forEach(e=>{
        e?.remove();
      })
    }
    this.lineLv3 = [];
    this.checkClickLv2 = false;
    this.countPhuongXa = 0;
    this.administrativeUnitService.getLocationLv3(idLevel2).subscribe(
      (res:any)=>{
        this.code_active_lv_2 = idLevel2;
        if(res.data){
          this.countPhuongXa = res.data.length;
          (res.data).forEach(e=>{
            setTimeout(() => {
              this.lineLv3.push(
                new LeaderLine(
                  document.getElementById(idLevel2),
                  document.getElementById(e.id),
                  {color: 'black',size:1,path:'grid',endPlug:'behind'}
                )
              );
            }, 20);
          });
          this.dataPhuongXa = res.data;
          this.checkClickLv1 = true;
          this.checkClickLv2 = true;
        }
      },
      (err)=>{
      }
    )
  }


  checkDataLv1(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.cityId == code){
        check = true;
      }
    });
    return check;
  }

  checkDataLv2(data:any,code:string){
    let check = false;
    data.forEach(element => {
      if(element.districtId == code){
        check = true;
      }
    });
    return check;
  }

  toggleClass(value){
    this.dataSearch = '';
    this.showSearch = !this.showSearch;
    this.checkResultSearch = false;
  }

  clickEditEducationLv1(data:any){
    data.fullname = data.name;
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv1Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        administrative:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getDataLocationLv1();
        this.showMessage.success("Cập nhật thành công thành công!");
      }
    });
  }

  clickEditEducationLv2(){
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv2Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  clickEditEducationLv3(){
    const dialogRef = this.dialog.open(DialogFormAdministrativeLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
  clickDeleteEducation(data:any){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa đơn vị',
        message:'Xác nhận xóa?',
        btnOkText:'Delete',
        btnCancelText:'Cancel',
        data: {
          id:data.id,
          name:data.name
        }
      }
    });
    

    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.isLoading = true;

        this.administrativeUnitService.deleteLocation(data.id).subscribe(
          (res:any)=>{
            this.isLoading = false;
            this.checkFireBase('delete')
          },
          (err)=>{
            this.isLoading = false;
          }
        )
      } 
      
    })
  }

  onSearchChange(searchValue: string): void {  
    this.dataSearch = searchValue;
  }

  openDialogImportIndustry(){
    this.button.is_active_import_user = true;
        const dialogRef = this.dialog.open(DialogImportAdministrativeComponent, {
            height: 'auto',
            width: '40%',
        });

        dialogRef.afterClosed().subscribe(result => {
          this.isLoading = true;
          this.administrativeUnitService.importBase64Location(result.dataInput).subscribe(
            (res:any)=>{
                if (res != '' && res.status == 1){
                  localStorage.setItem('sessionFile',res.data.data[0].sessionFile);
                  this.dataImport = res.data.data;
                  this.fileName = result.fileName;
                  this.isImportIndustry = true;
                }else{
                  this.showMessage.error(res.msg);
                }
                this.button.is_active_import_user = false;
                this.isLoading = false;
            },
            (err)=>{
              this.isLoading = false;
            }
          )
        });
  }

  getCloseImport(confirm){
    this.isLoading = true;
    let dataInput = {
      // pageSize: 0,
      // pageIndex: 0,
      // keyWord: "string",
      sessionFile: localStorage.getItem('sessionFile')
    }
    this.administrativeUnitService.importLocation(dataInput).subscribe(
      (res:any)=>{
        this.isLoading = false;
        if(res.status == 1){
          this.showMessage.success(res.msg);
          this.getDataLocationLv1();
          localStorage.removeItem("sessionFile");
        }else{
          this.showMessage.error(res.msg);
        }
      },
      (err)=>{
        this.isLoading = false;
      }
    );
    this.button.is_active_export_user = false;
    this.button.is_active_import_user = false;
    this.isImportIndustry = confirm;
  }

  openDialogCreate(){
    this.administrativeUnitService.generateCode('DVHC').subscribe(
      (res:any)=>{
        const dialogRef = this.dialog.open(DialogFormAdministrativeLv1Component,{
          height:'auto',
          width:'80%',
          data: {
            status:"add",
            code:res.data
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result) this.checkFireBase('create');
        });
      },
      (err)=>{

      }
    )
  }

  clickMoveAdminstrativeLv2(data:any){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeComponent, {
      height:'auto',
      width:'30%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.getDataLocationLv1();
          this.showMessage.success('Chuyển đổi đơn vị hành chính thành công');
        }
      }
    )
  }

  clickMoveAdminstrativeLv3(data:any){
    const dialogRef = this.dialog.open(DialogMoveAdministrativeLv3Component, {
      height:'auto',
      width:'80%',
      data: {
        title:'Bạn đang thực hiện chuyển đơn vị',
        message:'Bạn có chắc muốn cập nhật dữ liệu này không?',
        btnOkText:'XÁC NHẬN',
        btnCancelText:'HỦY',
        data: data
      }
    });

    dialogRef.afterClosed().subscribe(
      result =>{
        if(result){
          this.getDataLocationLv1();
          this.showMessage.success('Chuyển đổi đơn vị hành chính thành công');
        }
      }
    )
  }

  searchFocusOut(txtSearch:string='', unit: any){
    this.administrativeUnitService.filterLocation(txtSearch,Number(unit),'','').subscribe(
      (res:any)=>{
        this.resultSearch = res.data;
        this.checkResultSearch = true;
        switch (Number(unit)) {
          case 1:
            this.linkDetailEducation = '/administrative-unit/lv1/detail/';
            break;
          case 2:
            this.linkDetailEducation = '/administrative-unit/lv2/detail/';
            break;
          default:
            this.linkDetailEducation = '/administrative-unit/lv3/detail/';
            break;
        }
      },
      (err)=>{
        console.log(err);
      }
    )
  }

  removeLineLeader(){
    for (let index = 0; index < this.lineLv2.length; index++) {
      this.lineLv2[index]?.remove();
    }
    for (let index = 0; index < this.lineLv3.length; index++) {
      this.lineLv3[index]?.remove();
    }
    this.lineLv2 = this.lineLv3 = [];
  }


}

