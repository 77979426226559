import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModuleSoftwareManagerService} from '@app/_services';

@Component({
  selector: 'app-dialog-list-user-software',
  templateUrl: './dialog-list-user-software.component.html',
  styleUrls: ['./dialog-list-user-software.component.scss']
})
export class DialogListUserSoftwareComponent implements OnInit {
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email'];
  dataSource = [];
  keyword = '';
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private moduleSoftwareManagerService: ModuleSoftwareManagerService
  ) { }

  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(){
    this.moduleSoftwareManagerService.listTenantOfModule(this.data.infoSubSystem.id, this.keyword, null).subscribe((res: any) => {
      return this.dataSource = res.data;
    });
  }

  blurSearch(event){
    this.keyword = event.target.value.trim();
    this.loadDataItems();
  }

  keyPressSearch(event){
    if (event.code == 'Enter' || event.code == 'Tab'){
      this.keyword = event.target.value.trim();
      this.loadDataItems();
    }
  }
}
