import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TenantManagerListComponent } from "./components/tenant-manager-list/tenant-manager-list.component";
import { TenantManagerDetailComponent } from "./components/tenant-manager-detail/tenant-manager-detail.component";
// import { PermissonGuard } from '../../_helpers';
import { NgxPermissionsGuard } from 'ngx-permissions';
import {TENANT_PERMISSION} from './constants';
const routes: Routes = [
  {
    path: '',
    component: TenantManagerListComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [TENANT_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'detail/:id',
    component: TenantManagerDetailComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [TENANT_PERMISSION.perm3, TENANT_PERMISSION.perm4, 
          TENANT_PERMISSION.perm5, TENANT_PERMISSION.perm6, 
          TENANT_PERMISSION.perm7, TENANT_PERMISSION.perm8, 
          TENANT_PERMISSION.perm9, TENANT_PERMISSION.perm10, 
          TENANT_PERMISSION.perm11, TENANT_PERMISSION.perm12, 
          TENANT_PERMISSION.perm13, TENANT_PERMISSION.perm14, 
          TENANT_PERMISSION.perm15, TENANT_PERMISSION.perm16, 
          TENANT_PERMISSION.perm17, TENANT_PERMISSION.perm18, 
          TENANT_PERMISSION.perm19, TENANT_PERMISSION.perm20,
          TENANT_PERMISSION.perm21, TENANT_PERMISSION.perm22],
        redirectTo: '/accessdenied'
      }
    }  
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TenantsManagerRoutingModule { }
