<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px">
        <p class="title-page-global">Danh sách Môn học</p>
    </div>
    <div fxFlex fxLayout="row" class="form-page">
        <div fxFlex="15%"></div>
            <div fxFlex fxLayout="column" *ngIf="postForm">
                <form fxFlex fxLayout="column" [formGroup]="postForm">
                    <div fxFlex="30px" class="row">
                        <p class="label-form" >Thông tin cơ bản</p>
                    </div>
                    <div fxFlex="235px" class="row pl-0">
                        <div class="col-xl-3 col-lg-4 pl-0 col-md-5 col-sm-12 col-12">
                            <div class="img-module" (click)="uploadImage()">
                                <img [src]="urlImage ? urlImage : 'assets/images/logo/avatar-post.png'" class="avatar-img">
                                <div class="box-image" fxLayout="row" fxLayoutAlign="center center" >
                                    <label>
                                        <img src="assets/images/icons/icon-29.svg" class="icon-image mr-2"><span>Thay đổi ảnh </span>
                                    </label>
                                </div>
                                <input type="file" class="avatar-file" name="avatar" hidden id="create-post-avatar" (change)="onFileChange($event)">
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12 col-12">
                            <div class="form-group row row-global mt-4">
                                <label class="col-sm-1 title-field">Tiêu đề (<span class="text-danger">*</span>)</label>
                                <div class="col-sm-11 pl-0 pr-0">
                                    <input type="text" class="form-control input-field-global" formControlName="title" placeholder="Nhập tên phân quyền">
                                    <mat-error *ngFor="let validate of postValidate.title">
                                        <span class="error-message" *ngIf="postForm.get('title').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                                    </mat-error>
                                </div>
                            </div>
                            <div class="form-group row row-global">
                                <label class="col-sm-1 title-field ">Tóm tắt (<span class="text-danger">*</span>)</label>
                                <div class="col-sm-11 pl-0 pr-0">
                                    <textarea class="form-control input-field-global" formControlName="summaryContent" rows="4" cols="50"></textarea>
                                    <mat-error *ngFor="let validate of postValidate.summaryContent">
                                        <span class="error-message" *ngIf="postForm.get('summaryContent').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="30px" class="row">
                        <p class="label-form" >Nội dung</p>
                    </div>
                    <div fxFlex="600px" class="row">
                        <div class="col-lg-12 row-global">
                            <div class="form-group row row-global content-post">
                                <ckeditor [editor]="Editor" formControlName="content" class="input-field-global" id="editor1" style="width: 100%"></ckeditor>
                                <mat-error *ngFor="let validate of postValidate.content">
                                    <span class="error-message" *ngIf="postForm.get('content').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="30px" class="row">
                        <p class="label-form" >Thiết lập bài viết</p>
                    </div>
                    <div fxFlex class="row">
                        <div class="col-lg-12">
                            <div class=" form-group row row-global">
                                <label class="col-sm-1 title-field">Phạm vi </label>
                                <div class="col-sm-2 pl-0 pr-0">
                                    <select #scope class="form-control input-field-global" formControlName="sendRange" (change)="changeScope(scope.value)">
                                        <option value="0">Tất cả</option>
                                        <option value="1">Khách hàng</option>
                                    </select>
                                </div>
                            </div>
                            <div class=" form-group row row-global">
                                <label class="col-sm-1 title-field">Nhóm đối tượng</label>
                                <div class="col-sm-2 pl-0 pr-0">
                                    <select #limit class="form-control input-field-global" formControlName="targetGroup" (change)="changeLimit(limit.value)">
                                        <option value="0">Tất cả</option>
                                        <option value="1" *ngIf="scopePost == 0">người dùng</option>
                                        <option value="2" *ngIf="scopePost == 1">Giáo viên</option>
                                        <option value="3" *ngIf="scopePost == 1">Học sinh</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row row-global limit-form-post" *ngIf="limitPost != 0">
                                <label class="col-sm-1 title-field">Đối tượng</label>
                                <div class="col-sm-11 pl-0 pr-0">
                                    <div class="input-field-global py-3 pl-2">
                                        <mat-chip-list #chipList >
                                            <mat-chip *ngFor="let tenant of dataSelectTenants" [selectable]="selectable" [removable]="removable" (removed)="remove(tenant)">
                                                {{tenant.fullname}}
                                                <mat-icon style="color: white" matChipRemove *ngIf="removable">clearn</mat-icon>
                                            </mat-chip>
                                            <input style="color: #F2F3F8"
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputAddOnBlur]="addOnBlur"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matAutocomplete]="auto">
                                        </mat-chip-list>
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                            <mat-option *ngFor="let tenant of tenants" [value]="tenant">
                                                {{tenant.fullname}}
                                            </mat-option>
                                        </mat-autocomplete>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group row row-global">
                                <mat-checkbox formControlName="allowSendMail"><span class="title-field">Cho phép gửi mail</span></mat-checkbox>
                            </div>
                            <div class="form-group row row-global">
                                <mat-checkbox formControlName="allowComment"><span class="title-field">Cho phép bình luận</span></mat-checkbox>
                            </div>
                            <div class="form-group row row-global">
                                <mat-checkbox (change)="checkPublishAfter($event)" formControlName="sendNotice"><span class="title-field">Gửi thông báo sau</span></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="form-group row row-global date-time-range" *ngIf="checkPublish">
                    <span class="title-field ml-4">Thông báo sẽ được gửi vào </span>&nbsp;
                    <input readonly type="text" class="mr-2 input-field-global" value="{{time.hour | json}} : {{time.minute | json}}" style="padding-left: 10px">
                    <button mat-icon-button class="btn-time-post" (click)="hiddenTime = !hiddenTime">
                        <img src="assets/images/icons/icon-30.svg" height="20px">
                    </button>
                    <span class="title-field">Ngày </span>&nbsp;
                    <input readonly type="text" class="mr-2 input-field-global" [matDatepicker]="pickerTo" [(ngModel)]="dateOfBirth">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo" ></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo ></mat-datepicker>
                </div>
                <div class="form-group row row-global date-time-range" *ngIf="checkPublish">
                    <mat-error>
                        <span class="error-message" *ngIf="dateOfBirth == '' && checkValidate">{{postValidate.timeDatePublish}}</span>
                    </mat-error>
                </div>
                <div *ngIf="hiddenTime" class="time-post hidden-time-post">
                    <ngb-timepicker [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
                </div>
                <div fxFlex="50px"  fxLayout="row" fxLayoutAlign="center none" >
                    <button mat-button mat-dialog-close mat-raised-button class="btn-cancel mat-elevation-z">Hủy</button>
                    <button mat-button type="submit" class="btn btn-add-edit ml-2 px-4" (click)="submitPost(postForm.valid, postForm.value)">Thêm</button>
                </div>
            </div>
        <div fxFlex="15%"></div>
    </div>
</div>

