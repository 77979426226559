import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {statusUser} from "@app/_services/constants";
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-change-status',
  templateUrl: './dialog-change-status.component.html',
  styleUrls: ['./dialog-change-status.component.scss']
})
export class DialogChangeStatusComponent implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DialogChangeStatusComponent>
  ) { }
  readonly statusUser = statusUser;
  urlImage: SafeResourceUrl;

  ngOnInit(): void {
      this.urlImage = this.data.user && this.data.user.logo !== '' ? this.data.user.logo : '/assets/images/png/avatar.png';
  }

  confirmStatus(){
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
