import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogRoleFormAddEditComponent} from '@app/_modules/roles-manager/dialogs/dialog-role-form-add-edit/dialog-role-form-add-edit.component';
import {DialogRoleAddUserComponent} from '@app/_modules/roles-manager/dialogs/dialog-role-add-user/dialog-role-add-user.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ListenFirebaseService, RoleManagerService, ShowMessage} from '@app/_services';
import {Role} from '@app/_models';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {DialogRoleAddModuleComponent} from '@app/_modules/roles-manager/dialogs/dialog-role-add-module/dialog-role-add-module.component';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ROLES_PERMISSION} from '@app/_modules/roles-manager/constant';

@Component({
    selector: 'app-role-detail',
    templateUrl: './role-detail.component.html',
    styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit {
    permissionRole = ROLES_PERMISSION;
    data: Role;
    id: string;
    tab = '';
    queryParams: any;
    infoRole: any;
    roleId: string;
    changeTabText = false;
    checkEditRolePermission:boolean = false;
    reloadTabUser = false;
    checkTotalPermission: boolean = false;
    showSearch: boolean = true;
    change: number = 0;
    dataSearch: string = '';
    constructor(
        private dialogRes: MatDialog,
        private rolesService: RoleManagerService,
        private router: Router,
        private route: ActivatedRoute,
        private db: AngularFireDatabase,
        private showMessage: ShowMessage,
        private listenFirebaseService: ListenFirebaseService
    ) {
    }

    ngOnInit(): void {
        this.queryParams = this.route.snapshot.queryParams;
        this.tab = this.queryParams.active;
        this.getRoleById();
    }

    typeModule = 'role';

    checkFireBase(param){
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
        });
        listenFb.subscribe((res) => {
            if (res === true) {
                if (param === 'update'){
                    this.getRoleById();
                }
            }
        });
    }

    getRoleById() {
        this.queryParams = this.route.paramMap.subscribe((roleId: any) => {
            this.roleId = roleId.params.id;
            this.rolesService.getRoleById(roleId.params.id).subscribe((res: any) => {
                return this.infoRole = res.data;
            });
        });
    }

    checkRemoveSubject(event) {
        this.checkTotalPermission = event;
    }

    showAddUserRole() {
        const dialogRef = this.dialogRes.open(DialogRoleAddUserComponent, {
            height: 'auto',
            width: '60%',
            autoFocus: false,
            data: {
                roleId: this.roleId,
                roleName: this.infoRole.roleName
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res){
                this.change = this.change + 1;
                return this.checkFireBase('assign_users_to_role');
            }
        });
    }

    showAddModuleRole() {
        const dialogRef = this.dialogRes.open(DialogRoleAddModuleComponent, {
            height: 'auto',
            width: '80%',
            autoFocus: false,
            data: {
                roleId: this.roleId,
                roleName: this.infoRole.roleName
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res){
                this.change = this.change + 1;
                return this.checkFireBase('assign_permission_to_role');
            }
        });
    }

    showFormRole() {
        const dialog = this.dialogRes.open(DialogRoleFormAddEditComponent, {
            width: '65%',
            height: 'auto',
            panelClass: 'custom-modalbox',
            autoFocus: false,
            data: {
                action: 'edit',
                item: this.infoRole,
                code: ''
            }
        });
        dialog.afterClosed().subscribe(res => {
            this.checkFireBase('update');
        });
    }

    changeTab(tab){
        this.tab = tab;
        this.change = 0;
        this.dataSearch = '';
        this.showSearch = true;
        (document.getElementById('search-value') as HTMLInputElement).value = '';
        this.router.navigate([`${'roles-manager/detail'}/${this.roleId}`], { queryParams: {active: tab}});
    }

    toggleClass(){
        this.showSearch = !this.showSearch;
        (document.getElementById('search-value') as HTMLInputElement).value = '';
        if (this.showSearch === false){
            this.dataSearch = '';
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
      }
    

    checkText(event, value){
        if (this.showSearch === true) {
            if (event.keyCode === 13 || event.keyCode === 9){
                this.dataSearch = value;
                this.change ++;
            }
        }
    }

}
