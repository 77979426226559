<div (click)="toggleMenu($event)" class="form-control date-picker" fxLayoutAlign="space-between center" [ngStyle]="{background: background, color: color}"  fxLayout="row">
    <div>{{time?.hour === null ? 'H:s' : (time.hour < 10 ? '0'+time.hour : time.hour) + ':' + (time.minute < 10 ? '0'+time.minute : time.minute) + ' '}}</div>&nbsp;
    <div>{{model?.year === null ? 'dd/mm/YY' : model?.day+'/'+model?.month+'/'+model?.year}}</div>&nbsp;&nbsp;&nbsp;
    <button class="btn" [ngStyle]="{filter: filter}" style="width: 20px" #toggleButton>
        <img src="../../../../../assets/images/svg/calendar-icon-white.svg" alt="">
    </button>
</div>

<div class="d-flex" (clickedOutside)="close()" appOutside id="datetime-picker" *ngIf="isMenuOpen">
    <ngb-timepicker [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
    &nbsp;&nbsp;
    <ngb-datepicker #dp [(ngModel)]="model"></ngb-datepicker>
</div>
