<h2 mat-dialog-title class="title-page-global text-center">Phân quyền 1 - Người dùng</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row input-search-dialog-list">
            <div style="width: 100%">
                <input #search (keypress)="keyPressSearch($event)" type="text" style="padding-left: 40px" id="search-value" class="text-left btn btn-default button-search transition-search-show"
                       (keydown)="checkText()" >
                <button class="btn btn-default btn-filter" style="position: relative; left: 20px">
                    <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilter = !showFilter">
                </button>
            </div>
            <div style="position: relative; top: -30px; left: 10px" (click)="clickSearch(search.value)">
                <img src="assets/images/svg/search.svg" alt="" class="custom-icon-search">
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="end start" class="option-filter row" *ngIf="showFilter">
            <ng-container *ngFor="let item of dataStatus, let i = index">
                <button class="btn mr-2 btn-filter-option {{item.isChecked === true ? 'tab-active' : 'btn-default'}}" (click)="itemOptionClick(i)">{{item.fullName}}</button>
            </ng-container>
        </div>
        <div class="row mt-1">
            <table mat-table [dataSource]="dataSource" class="table-el">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                    <td mat-cell *matCellDef="let item, let i = index">{{i + 1}}</td>
                </ng-container>
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">Người dùng</th>
                    <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullName}}</td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                    <td mat-cell *matCellDef="let item">{{item.userCode}}</td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                    <td mat-cell *matCellDef="let item">{{item.phone}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                    <td mat-cell *matCellDef="let item">{{item.email}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn-add-global mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
</mat-dialog-actions>
