<h2 mat-dialog-title
    class="title-page-global text-center">{{infoSchoolYear.status == 'add' ? lang.form.title_add : lang.form.title_edit}}</h2>
<div class="container-fluid" fxLayout="column">
    <form [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm.value)">
        <div class="row">
            <div class="col-md-6 row row-global" style="padding-bottom: 0">
                <label class="col-md-3 lb-global">{{lang.form.label.start_date}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input (dateChange)="chooseDateTime('startDate', $event)" readonly [matDatepicker]="picker_start_date" formControlName="startDate" type="text"
                           class="form-control input-field-global">
                    <div style="position: relative; float: right; bottom: 40px">
                        <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
                        <mat-datepicker #picker_start_date></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-6 ml-auto row row-global" >
                <label class="col-md-3 offset-1 lb-global">{{lang.form.label.end_date}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input (dateChange)="chooseDateTime ('endDate', $event)" readonly [matDatepicker]="picker_end_date" formControlName="endDate" type="text"
                           class="form-control input-field-global">
                    <div style="position: relative; float: right; bottom: 40px;">
                        <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
                        <mat-datepicker #picker_end_date ></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 lb-global">{{lang.form.label.full_name}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input readonly formControlName="fullname" type="text" class="form-control input-field-global"
                           placeholder="{{lang.form.placeholder.full_name}}">
                    <mat-error *ngFor="let validation of messageValidate.fullname">
                        <p class="error-message" *ngIf="infoForm.get('fullname').hasError(validation.type) &&
                        (infoForm.get('fullname').dirty || infoForm.get('fullname').touched || isValidate == true)">{{validation.message}}</p>
                    </mat-error>

                </div>
            </div>
            <!--                <div class="col-md-2"></div>-->
            <div class="form-group col-md-6 ml-auto row row-global">
                <label class="col-md-3 offset-1 lb-global">{{lang.form.label.code}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input  readonly formControlName="code" type="text" class="form-control input-field-global"
                           placeholder="{{lang.form.placeholder.code}}">
                    <mat-error *ngFor="let validation of messageValidate.code">
                        <p class="error-message" *ngIf="infoForm.get('code').hasError(validation.type) &&
                        (infoForm.get('code').dirty || infoForm.get('code').touched || isValidate == true)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 lb-global">
                    {{lang.form.label.status}}
                </label>

                <div class="col-md-8 pl-0 pr-0">
                    <mat-checkbox class="custom-checkbox" formControlName="isActive" >
                        {{lang.form.label.active}}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group col-md-6 ml-auto row row-global">
                <label class="col-md-3 offset-1 lb-global"> {{lang.form.label.status_school_year}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <mat-checkbox  class="custom-checkbox" (change)="toggleStatus(checkbox.checked)" #checkbox formControlName="isCurrent">
                        {{lang.form.label.current}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <!-- Thống báo khi bấm vao checbox năm hiện tại-->
        <div *ngIf="infoForm.get('isCurrent').value" class="row">
            <div class="form-group col-md-12 row row-global">
<!--                <p class="text-danger col-md-12 lb-global">-->
<!--                    *Dữ liệu trạng thái năm học mới thêm đã tồn tại trong hệ thống.-->
<!--                </p>-->

                <p class="font-normal">
                    Hệ thống sẽ tự động chuyển đổi trạng thái của năm học
                </p>

                <div class="col-md-12 pl-0 pr-0 custom-message-school-year" *ngIf="currentSchoolYear !== null">
                    <br>
                    <p class="custom-title-dialog text-center">{{currentSchoolYear?.fullname}} ({{currentSchoolYear?.code}})</p>
                    <div fxLayout="row" fxLayoutAlign="center center" >
                        <div>
                            <label class="font-normal">Trạng thái hiện tại</label>
                            <div class="school-year-current" fxLayout="row" fxLayoutAlign="center center">
                                Năm học hiện tại
                            </div>
                        </div>
                        <div style="position: relative; top: 15px">
                            &nbsp; &nbsp;<img src="assets/images/svg/right.svg" alt=""> &nbsp; &nbsp;
                        </div>
                        <div>
                            <label class="font-normal">Trạng thái năm học mới</label>
                            <div class="school-year-old" fxLayout="row" fxLayoutAlign="center center">
                                {{currentSchoolYear?.newStatus}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Thống báo khi bấm vao checbox năm hiện tại-->

        <mat-dialog-actions align="center" class="d-flex justify-content-center">
            <button type="button" mat-button mat-dialog-close
                    class="btn btn-close">{{lang.button.btn_close}}</button>
            <button style="width: 120px;" mat-button type="submit"
                    class="btn btn-add-edit ml-2 px-4">{{infoSchoolYear.status === 'add' ? "Thêm" : 'Lưu'}}
            </button>
        </mat-dialog-actions>
        <br>
    </form>

</div>

<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>

