<div class="tab-content">
  
    <!-- Tên miền-->

    <!--Tài khoản quản trị-->
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title custom-space-left">
                Tài khoản quản trị
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Tài khoản quản trị</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p>{{data?.tenantAdmin}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Mật khẩu</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p>{{data?.tenantAdminPassword}}</p>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <!--Tài khoản quản trị -->
</div>