<h2 mat-dialog-title class="title-page-global text-center">Phân quyền 1 - Người dùng</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <span class="col-sm-12">Đối tượng nhận bài viết</span>
        </div>
        <div class="row input-search-dialog-list">
            <div class="col-sm-12">
                <input (keydown)="checkText($event, search.value)" #search type="text" class="form-control input-field-global" placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email" (blur)="checkText($event, search.value)">
                <img src="assets/images/icons/icon-23.svg">
            </div>
        </div>
        <div class="row mt-1 row-global">
            <div class="col-sm-6 mt-2" *ngFor="let item  of recipients, let i = index">
                <div class="box-dialog-list-object" fxLayout="row" fxLayoutAlign="start center" >
                    <span class="stt-user">{{i + 1}}</span><span class="name-user">{{item.fullName}}</span><span class="code-user">({{item.userCode}})</span>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn-add-global mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
</mat-dialog-actions>
