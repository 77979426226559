<div class="text-center">
    <span class="title-page-global custom-title-password">Đặt lại mật khẩu</span>
</div>
<p class="text-center custom-label">Người dùng: {{data.infoUser.fullName}} ({{data.infoUser.userCode}})</p>
<br>
<div fxLayout="row" fxLayoutAlign="center center">
    <form id="custom-form" (ngSubmit)="confirmPassword()">
        <div class="form-group">
            <p class="custom-label">Nhập mật khẩu mới</p>
            <input placeholder="Nhập mật khẩu mới" [type]="iconEyeNewPass ? 'text' : 'password'" (click)="checkNewPassword(newPassword.value)"
                   #newPassword (keyup)="checkNewPassword(newPassword.value)" class="form-control custom-input" id="verify-account">
            <div class="position-show-password">
                <img (click)="iconEyeNewPass = !iconEyeNewPass" class="icon-eye" [src]="iconEyeNewPass ? '../../../../assets/images/svg/eye-show.svg' : '../../../../assets/images/svg/eye-hide.svg'" alt="">
            </div>
           <div style="margin-top: 5px">
               <p *ngIf="(validate.verify_account.is_min_length !== 0)" [class]="validate.verify_account.is_min_length === 2 ? 'text-danger' : 'text-success'">
                   <img [src]="validate.verify_account.is_min_length === 2 ? 'assets/images/svg/icon-error-validate.svg' : 'assets/images/svg/success_validate.svg'">
                   Mật khẩu phải ít nhất 6 ký tự
               </p>
               <p *ngIf="(validate.verify_account.is_pattern_number !== 0)" [class]="validate.verify_account.is_pattern_number === 2 ? 'text-danger' : 'text-success'">
                   <img [src]="validate.verify_account.is_pattern_number === 2 ? 'assets/images/svg/icon-error-validate.svg' : 'assets/images/svg/success_validate.svg'">
                   Mật khẩu phải chứa ít nhất 1 chữ số
               </p>
               <p *ngIf="(validate.verify_account.is_pattern_text !== 0)" [class]="validate.verify_account.is_pattern_text === 2 ? 'text-danger' : 'text-success'">
                   <img [src]="validate.verify_account.is_pattern_text === 2 ? 'assets/images/svg/icon-error-validate.svg' : 'assets/images/svg/success_validate.svg'">
                   Mật khẩu phải chứa ít nhất 1 chữ
               </p>
           </div>
        </div>
        <br>
        <div class="form-group">
            <label class="card-title custom-label">Nhập lại mật khẩu mới</label>
            <input placeholder="Nhập lại mật khẩu" [type] = "iconEyeRepeatPass ? 'text' : 'password'" #repeatPassword
                   (keyup)="checkRepeatPassword(repeatPassword.value)"
                   class="form-control custom-input">
            <div class="position-show-password">
                <img (click)="iconEyeRepeatPass = !iconEyeRepeatPass" class="icon-eye" [src]="iconEyeRepeatPass ? '../../../../assets/images/svg/eye-show.svg' : '../../../../assets/images/svg/eye-hide.svg' " alt="">
            </div>
            <div style="margin-top: 5px">
                <p *ngIf="(validate.is_repeat_password !== 0)" [class]="validate.is_repeat_password === 2 ? 'text-danger' : 'text-success'">
                    <img [src]="validate.is_repeat_password === 2 ? 'assets/images/svg/icon-error-validate.svg' : 'assets/images/svg/success_validate.svg'">
                    <span *ngIf="(validate.is_repeat_password == 2)">Mật khẩu không khớp</span>
                    <span *ngIf="(validate.is_repeat_password == 1)">Đã khớp mật khẩu</span>
                </p>
            </div>
        </div>
        <p class="text-center">
            <button mat-button mat-dialog-close class="btn btn-close"
                    style="background: #D7D7D7; border-radius: 5px;">Hủy
            </button>
            <button type="submit" [disabled]="validate.is_repeat_password == 2 || validate.is_repeat_password == 0" class="btn btn-update">
                Xác nhận
            </button>
        </p>
    </form>
</div>



