import { Component, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../../../../../_shared/dialogs/delete/dialog-delete.component';
import {DialogTenantEditRoleComponent} from '@app/_modules/tenants-manager/dialog/dialog-tenant-edit-role/dialog-tenant-edit-role.component';
import { TenantManagerService,  ListenFirebaseService, ShowMessage } from '@app/_services';
import {ActivatedRoute} from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {TENANT_PERMISSION} from '../../../constants';

@Component({
  selector: 'app-tab-tenant-role-list',
  templateUrl: './tab-tenant-role-list.component.html',
  styleUrls: ['./tab-tenant-role-list.component.scss']
})
export class TabTenantRoleListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'tenant_name_role', 'tenant_code_role', 'desc', 'action'];
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  dataSource: any;
  keyword: string = '';
  typeModule = 'tenant_subsystem';
  public isTable = false;
  isLoadingResults = false
  tenantId = "";
  TENANT_PERMISSION = TENANT_PERMISSION;

  constructor(
    public dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private activatedRoute: ActivatedRoute,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService
    ) { }

  ngOnInit(): void {
    this.listSubsystem();
  }

  listSubsystem(search?: string, filter?: []) {
    this.isLoadingResults = true;

    this.activatedRoute.paramMap.subscribe((res: any) => {
      this.tenantId = res.params.id;
      search = search ? search : '';
      filter = filter ? filter : [];
      this.tenantManagerService.getAllListSubsystem(this.pageSize, this.pageIndex, search, filter, this.tenantId).subscribe((ref: any) => {
        this.dataSource = ref.data.data;
        this.totalRecord = ref.data.totalItems;
        this.isLoadingResults = false;

      });
    });
  }

    onChangePaginate(event){
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;
      this.listSubsystem();
    }

  openDialogDeleteRole(value){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xóa phân hệ',
        message: 'Xác nhận xóa?',
        btnOkText: 'Delete',
        btnCancelText: 'Cancel',
        data: {
          id: value.id,
          name: value.tenant_name_role
        }
      }
    });
  }

  showFormEditRole(){
    const dialogRef = this.dialog.open(DialogTenantEditRoleComponent, {
      height: 'auto',
      width: '70%',
      panelClass: 'custom-modalbox',
      data: {
        tenantId: this.tenantId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.listSubsystem();
    });
  }

  confirmDelete(item: any) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
        height: 'auto',
        width: '40%',
        data: {
          title: 'Bạn đang thực hiện gỡ phân hệ ra khỏi danh sách áp dụng',
          message: 'Bạn có chắc muốn gỡ phân hệ này không ?',
          btnOkText: 'XÁC NHẬN',
          btnCancelText: 'HỦY',
            data: {
              id: item.id,
              name: item.fullname
            },
        }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm === true) {
          this.isLoadingResults = true;
          let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
          });
  
          listenFb.subscribe((result) => {
            if (result === true) {
              this.listSubsystem();
            }
            this.isLoadingResults = false;
          });
  
          this.tenantManagerService.deleteSubsystemTenant(this.tenantId, item.id).subscribe((res: any) => {
            if (res.status == 0) {
              if (this.isLoadingResults) {
                this.isLoadingResults = false;
                this.showMessage.error(res.msg);
              }
            }
          }, error => {
            this.isLoadingResults = false;
          });
          setTimeout(() => {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
        }
    });
  }

  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.pageIndex = 0;
      this.listSubsystem(this.keyword);
    }
  }
  
  onBlur(event) {
    event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
    if (event.type === 'blur') {
      this.pageIndex = 0;
      this.listSubsystem(this.keyword);
    }
  }
}
