import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PermissionManagerRoutingModule} from '@app/_modules/permission-manager/permission-manager-routing.module';
import {SharedModule} from '@app/_shared/shared.module';
import { DialogDeletePermissionComponent } from './dialogs/dialog-delete-permission/dialog-delete-permission.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { DialogFormAddEditPermissionComponent } from './dialogs/dialog-form-add-edit-permission/dialog-form-add-edit-permission.component';
import {ReactiveFormsModule} from '@angular/forms';
import { PermissionListComponent } from './components/permission-list/permission-list.component';
import { PermissionDetailComponent } from './components/permission-detail/permission-detail.component';


@NgModule({
  declarations: [
      DialogDeletePermissionComponent,
      DialogFormAddEditPermissionComponent,
      PermissionListComponent,
      PermissionDetailComponent],
    imports: [
        CommonModule,
        PermissionManagerRoutingModule,
        SharedModule,
        FlexLayoutModule,
        ReactiveFormsModule,
    ]
})
export class PermissionManagerModule { }
