import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ShowMessage} from '@app/_services';

import { AuthenticationService } from '@app/_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private showMessage: ShowMessage, private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                // alert("Authentication error");
                this.authenticationService.logout();
                // location.reload(true);
            }
            if (err.status === 403) {
                // auto if 403 response returned from api
                this.showMessage.error('Authorzation error');
                // redirect 403
            }

            if (err.status === 400) {
                this.showMessage.error('Vui lòng kiểm tra lại thông tin!');
            }
            if (err.status === 500) this.showMessage.error('Server error!');
            if  (err.status === 404) {
                this.showMessage.error('Page not found!');
                // redirect 404
            }
            const error = err.error.message || err.statusText;
            return throwError(err.error);
        }));
    }
}
