import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoleManagerService {

  constructor(private http: HttpClient) { }

  getDataRole(pageSize, pageIndex, keyWord = '', status = ''){
    pageIndex = pageIndex + 1;
    const linkKeyWord = keyWord === '' ? '' : `&keyword=${keyWord}`;
    const linkStatus = status === '' ? '' : `&Status=${status}`;
    return this.http.get(`${environment.coreApiUrl}/api/roles?PageSize=${pageSize}&PageIndex=${pageIndex}${linkKeyWord}${linkStatus}`);
  }

  deleteRole(id: string){
    return this.http.post(`${environment.coreApiUrl}/api/roles/delete`, {id});
  }

  getRoleById(id: string){
    return this.http.get(`${environment.coreApiUrl}/api/roles/${id}`);
  }

  addRole(data: any){
    data.displayNumber = Number(data.displayNumber);
    return this.http.post(`${environment.coreApiUrl}/api/roles`, data);
  }

  editRole(data: any, id){
    return this.http.put(`${environment.coreApiUrl}/api/roles/${id}`, data);
  }

  changeStatusRole(roleId: string, status: number){
    return this.http.post(`${environment.coreApiUrl}/api/roles/change-status`, {
      id: roleId,
      status: status});
  }

  getModulesOfRole(roleId: string){
    return this.http.get(`${environment.coreApiUrl}/api/roles/${roleId}/get-modules`);
  }

  getUsersOfRole(roleId: string, keyword: string = '', pageIndex, pageSize, gender = ''){
    let linkKeyWord = keyword === '' ? '' : `&KeySearch=${keyword}`;
    let linkGender = gender === '' ? '' : `&gender=${gender}`;
    pageIndex = Number(pageIndex) + 1;
    return this.http.get(`${environment.coreApiUrl}/api/roles/${roleId}/get-users-role?PageSize=${pageSize}&PageIndex=${pageIndex}${linkKeyWord}${linkGender}`);
  }

  /**xoa nguoi dung khoi role**/
  removeUser(idRole: string, idUser: string){
    let data = {
      idRole: idRole,
      idUser: idUser
    };
    return this.http.post(`${environment.coreApiUrl}/api/roles/remove-user`, data);
  }

  assignUser(idRole, userIds: any){
    return this.http.post(`${environment.coreApiUrl}/api/roles/assign-users`, {idRole, userIds});
  }

  getPermissionsOfRole(roleId: string, keyword: string = '', pageIndex, pageSize, gender = ''){
    let linkKeyWord = keyword === '' ? '' : `&KeySearch=${keyword}`;
    let linkGender = gender === '' ? '' : `&gender=${gender}`;
    pageIndex = Number(pageIndex) + 1;
    return this.http.get(`${environment.coreApiUrl}/api/roles/${roleId}/get-permissions?PageSize=${pageSize}&PageIndex=${pageIndex}${linkKeyWord}${linkGender}`);
  }

  assignPermissionsToRole(roleId: string, data: any){
    const item = {
      idRole: roleId,
      permissionIds: data
    };
    return this.http.post(`${environment.coreApiUrl}/api/roles/assign-permissions`, item);
  }

  filterRole(keyword, status){
    return this.http.get(`${environment.coreApiUrl}/api/roles?KeyWord=${keyword}&&Status=${status}`);
  } 

  removeMultiplePermissionsByRoleId(roleId, data){
    const item = {
      idRole: roleId,
      permissionIds: data
    };
    return this.http.post(`${environment.coreApiUrl}/api/roles/remove-permissions`, item);
  }

  /**Lay tenant khong thuoc roles**/
  getUserNotBelongRole(roleId: string, keyword = ''){
    let linkKeyWord = keyword === '' ? '' : `?KeySearch=${keyword}`;
    return this.http.get(`${environment.coreApiUrl}/api/roles/${roleId}/get-users${linkKeyWord}`);
  }

  getCodeRandom(code: string){
    return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`, {
      prefix: code,
      lenghtOfAlphabet: 0,
      lenghtOfUpercase: 0,
      lenghtOfNumber: 6,
      lenghtOfSpecial: 0
    });
  }

}


