<h2 mat-dialog-title class="title-page-global text-center"> Thêm khách hàng</h2>
<mat-dialog-content class="mat-typography">
    <div class="dialog-body">
        <div class="row" style="padding-left: 15px;">
            <div class="col-md-12 row d-flex justify-content-center" style="padding-left: 30px;">
                <div class="col-md-12 pr-0 content-search">
                    <div style="cursor: pointer;">
                        <img src="assets/images/svg/search.svg" alt="">
                    </div>
                    <input #secrchTenant (keydown)="searchTenantNotAssign($event,secrchTenant.value)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Tìm tên, mã, tên miền, số điện thoại, email">
                </div>
            </div>
            <p class="col-md-12 text-left m-0 mt-2" style="font-size: 16px;">
                <span>
                    <mat-checkbox class="mr-5" [checked]="isChecked" (change)="clickSelectAll($event);">Chọn tất cả</mat-checkbox>
                </span>
                <span>Đã chọn: {{ countCheck }} khách hàng mới</span>
            </p>
        </div>
        <div class="container-fluid">
            <table mat-table [dataSource]="dataTenantNotAssign" matSort class="table-el mt-0">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Chọn</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [checked]="arrayTenant.indexOf(element.id) != -1" (change)="clickSelectItem($event,element.id);"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fullname">
                    <th mat-header-cell *matHeaderCellDef style="width: 25%;">Khách hàng</th>
                    <td mat-cell *matCellDef="let element" class="text-important-table">{{ element.fullname }}</td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Mã</th>
                    <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>
                <ng-container matColumnDef="domain">
                    <th mat-header-cell *matHeaderCellDef style="width: 15%;">Tên miền</th>
                    <td mat-cell *matCellDef="let element"><a [href]="element.tenantUrl" target="_blank" rel="noopener noreferrer">{{ element.tenantUrl }}</a></td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">SĐT</th>
                    <td mat-cell *matCellDef="let element">{{ element.hotline }}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%;">Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" style="line-height: 10px"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedColumns" style="background: white"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn-close custom-outline-button btn-cancel-global" (click)="closeAddTenant()">HỦY</button>
    <button type="submit" mat-button mat-dialog-close mat-raised-button class="btn-close custom-outline-button btn-add-global" (click)="clickAddTenant();">THÊM</button>
</mat-dialog-actions>