<h2 mat-dialog-title class="title-page-global text-center mb-5">{{data.status == 'add' ? 'Thêm khối' : 'Chỉnh sửa khối'}}</h2>
<div class="container-fluid" fxLayout="column">
    <form [formGroup]="formGrade" (ngSubmit)="onSubmit(formGrade.value)">
        <mat-dialog-content class="mat-typography">
            <div class="row ">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tên khối <span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="fullname" type="text" class="form-control input-field-global" placeholder="Nhập tên khối">
                        <mat-error *ngFor="let validation of message_validate.fullname">
                            <p class="error-message" *ngIf="formGrade.get('fullname').hasError(validation.type) && (formGrade.get('fullname').dirty || formGrade.get('fullname').touched || checkValidate == true)">
                                {{validation.message}}
                            </p>
                        </mat-error>

                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 lb-global">Mã khối <span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="code" type="text" class="form-control input-field-global" placeholder="Nhập mã khối">
                        <mat-error *ngFor="let validation of message_validate.code">
                            <p class="error-message" *ngIf="formGrade.get('code').hasError(validation.type) && (formGrade.get('code').dirty || formGrade.get('code').touched || checkValidate == true)">
                                {{validation.message}}
                            </p>
                        </mat-error>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Cấp đào tạo <span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select formControlName="trainingLevel" type="text" class="form-control input-field-global">
                            <option value="1">Mầm non</option>
                            <option value="2">Tiểu học</option>
                            <option value="3">Trung học cơ sở</option>
                            <option value="4">Trung học phổ thông</option>
                            <option value="99">Khác</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 lb-global">Nhóm khối</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input type="text" formControlName="group" class="form-control input-field-global" readonly>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Thứ tự sắp xếp</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="indexOrder" (keypress)="numberOnly($event)" type="number" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                        <mat-error *ngFor="let validation of message_validate.indexOrder">
                            <p class="error-message" *ngIf="formGrade.get('indexOrder').hasError(validation.type) && (formGrade.get('indexOrder').dirty || formGrade.get('indexOrder').touched || checkValidate == true)">
                                {{validation.message}}
                            </p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 lb-global">Trạng thái</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <mat-checkbox formControlName="status" class="col-md-9 p-0">
                            {{lang.label.active}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <label style="width: 11%" class=" lb-global">Mô tả</label>
                <div style="padding-left: 1%; width: 90%">
                    <textarea formControlName="description" type="text" class="form-control input-field-global" placeholder="Nhập mô tả" rows="4"></textarea>
                    <mat-error *ngFor="let validation of message_validate.description">
                        <p class="error-message" *ngIf="formGrade.get('description').hasError(validation.type) && (formGrade.get('description').dirty || formGrade.get('description').touched || checkValidate == true)">
                            {{validation.message}}
                        </p>
                    </mat-error>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center" class="mt-3">
            <button mat-button mat-dialog-close mat-raised-button class="btn-close custom-outline-button" (click)="onDismiss()">{{lang.button.button_close}}</button>
            <button mat-button type="submit" class="btn btn-add-edit custom-outline-button ml-2 px-4">{{data.status === 'add' ? lang.button.button_add : lang.button.button_edit}}</button>
        </mat-dialog-actions>
    </form>
</div>