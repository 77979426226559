<div fxLayout="column" class="custom-bg dashboard">
    <div fxFlex fxLayout="row" class="dashboard-content" >
        <div  fxFlex="15%"  class="dashboard-content__left">
            <ng-container *ngFor="let item of dataGrade">
                <div class="box {{item.id == this.gradeId ? 'active' : ''}}" (click)="chooseGrade(item.id, item.fullname)">
                    <p class="box__name">{{item.fullname}}</p>
                </div>
            </ng-container>
        </div>
        <div fxFlex class="dashboard-content__right">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>


