import {Component, OnInit, ViewChild, OnChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogFormSubjectsManagerComponent} from '@app/_modules/subjects/dialogs/dialog-form-subjects-manager/dialog-form-subjects-manager.component';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, Subscriber} from 'rxjs';
import * as $ from 'jquery';
import { SUBJECT_PERMISSION } from '@app/_modules/subjects/constant';
import {DialogSubjectListGradesComponent} from '@app/_modules/subjects/dialogs/dialog-subject-list-grades/dialog-subject-list-grades.component';

@Component({
  selector: 'app-subject-list',
  templateUrl: './subject-list.component.html',
  styleUrls: ['./subject-list.component.scss']
})
export class SubjectListComponent implements OnInit {
  data: any;
  showSearch: boolean = true;
  showFilter: boolean = false;
  dataSearch: string = '';
  dataFilter: any = [];
  check = false;
  tab = 'moet';
  length: number;
  typeModule = 'subject';
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  isLoading = false;
  permissionSubject = SUBJECT_PERMISSION;
  constructor(
      private dialogRef: MatDialog,
      private subjectService: SubjectService,
      private db: AngularFireDatabase,
      private showMessage: ShowMessage,
      private listenFirebaseService: ListenFirebaseService) { }

  ngOnInit(): void {
    this.listSubject(1);
    $("#leader-line-defs").remove();
  }

  listSubject(type: number, search?: string, filter?: []){
    search = search ? search : '';
    filter = filter ? filter : [];
    this.subjectService.getAllListSubject(this.pageSize, this.pageIndex, type, search, filter).subscribe((ref: any) => {
      this.data = ref.data.data;
      this.totalRecord = ref.data.totalItems;
      this.isLoading = false;
    });
  }

  reloadData(confirm){
    if (confirm === true){
      if (this.tab === ' other'){
        this.listSubject(2, this.dataSearch, this.dataFilter);
      }
      else if (this.tab === 'moet'){
        this.listSubject(1, this.dataSearch, this.dataFilter);
      }
      // else if (this.tab === 'all'){
      //   this.listSubject(0, this.dataSearch, this.dataFilter);
      // }
    }
  }

  changePaginate(event){
    this.isLoading = true;
    this.pageIndex = event;
    if (this.tab === 'other'){
      setTimeout(() => {
        this.listSubject(2, this.dataSearch, this.dataFilter);
      }, 2000);
    }
    else if (this.tab === 'moet'){
      setTimeout(() => {
        this.listSubject(1, this.dataSearch, this.dataFilter);
      }, 2000);
    }
  }

  toggleClass(){
    this.showSearch = !this.showSearch;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
  }

  checkText(event, value){
    if (this.showSearch === true) {
      if (event.keyCode === 13 || event.keyCode === 9){
        this.isLoading = true;
        this.pageIndex = 0;
        this.dataSearch = value;
        if (this.tab === 'other'){
          setTimeout(() => {
            this.listSubject(2, this.dataSearch, this.dataFilter);
          }, 1000);
        }
        else if (this.tab === 'moet'){
          setTimeout(() => {
            this.listSubject(1, this.dataSearch, this.dataFilter);
          }, 1000);
        }
      }
    }
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
    this.dataFilter = [];
  }

  filter(){
    this.pageIndex = 0;
    this.pageSize = 15;
    this.isLoading = true;
    if (this.tab === 'other'){
      setTimeout(() => {
        this.listSubject(2, this.dataSearch, this.dataFilter);
      }, 2000);
    }
    else if (this.tab === 'moet'){
      setTimeout(() => {
        this.listSubject(1, this.dataSearch, this.dataFilter);
      }, 2000);
    }
  }

  itemOptionClick(event, value){
    if (event.srcElement.classList.contains("active")){
      event.srcElement.classList.remove("active");
      // @ts-ignore
      const stt = this.dataFilter.indexOf(value);
      this.dataFilter.splice(stt, 1);
    }else{
      event.srcElement.classList.add("active");
      // @ts-ignore
      this.dataFilter.push(value);
    }
  }

  showFormModuleSubject(status: string) {
    const dialogAdd = this.dialogRef.open(DialogFormSubjectsManagerComponent, {
      height: 'auto',
      width: '70%',
      data: {
        action: status,
        infoSubject: null
      }
    });
    dialogAdd.afterClosed().subscribe((ref: any) => {
      if (ref) {
        console.log(ref);
        this.checkFireBaseModule('create');
      }
    });
  }

  listenFireBase(){
      // create
      this.checkFireBaseModule('create');
      // update
      // this.checkFireBaseModule('update');
  }

  checkFireBaseModule(param){
      const listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase(param, 'subject', subscriber);
      });
      listenFb.subscribe((refg) => {
          if (refg === true) {
              this.isLoading = false;
              this.listSubject(1);
          }
      });
  }

  onChangeTab(tab: string, type: number){
    this.isLoading = true;
    this.tab = tab;
    this.showFilter = false;
    this.dataSearch = '';
    this.dataFilter = [];
    this.showSearch = true;
    this.pageIndex = 0;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
    this.listSubject(type);
  }
}
