import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SchoolYearsManagerService {

    constructor(private http: HttpClient) { }

    getDataSchoolYears(keyword: string){
        return this.http.post(`${environment.authApiUrl}/api/school-year/index`, {
            "keyword": keyword
        });
    }

    storeSchoolYear(data: {fullname: string, code: string, startDate: number, endDate: number, isActive: number, isCurrent: number}){
        return this.http.post(`${environment.authApiUrl}/api/school-year/store`, data);
    }

    /**Sua school year**/
    updateSchoolYear(data: {
        id: string,
        fullname: string,
        startDate: number,
        endDate: number,
        isActive: number,
        isCurrent: number
    }){
        return this.http.post(`${environment.authApiUrl}/api/school-year/update`, data);
    }

    removeSchoolYear(id: string){
        return this.http.post(`${environment.authApiUrl}/api/school-year/delete`, {id});
    }

    /** Thay doi trang thai status cho school year**/
    changeStatus(id: string, isActive){
        return this.http.post(`${environment.authApiUrl}/api/school-year/change-active-status`, {id, isActive});
    }

    /**Lay thong tin chi tiet nam hoc**/
    getInfoSchoolYearById(id: string){
        return this.http.get(`${environment.authApiUrl}/api/school-year/detail/${id}`);
    }

    /**Lay danh sach tenant cua nam hoc**/
    getTenantOfSchoolYear(id: string, data: {keyword: string, status: any}){
        return this.http.post(`${environment.authApiUrl}/api/school-year/tenants/${id}`, data);
    }

    /**Lay danh sach tenant chua co add vao school year**/
    getTenantNotAssignedTenants(schoolYearId: string, keyword: string){
        return this.http.post(`${environment.authApiUrl}/api/school-year/not-assigned-tenants/${schoolYearId}`, {keyword});
    }

    /**them tentant vao school year**/
    assignTenant(data: {schoolYearId: string, tenantIds: string[]}){
        return this.http.post(`${environment.authApiUrl}/api/school-year/assign-tenant`, data);
    }

    removeTenantById(schoolYearId: string, tenantId: string){
        return this.http.post(`${environment.authApiUrl}/api/school-year/remove-tenant`, {schoolYearId, tenantId});
    }

    /**lay nam hoc hien tai**/
    getCurrentSchoolYear(data){
        return this.http.post(`${environment.authApiUrl}/api/school-year/check-current`, data);
    }

}


