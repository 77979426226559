<section class="container-fluid main-wrapper" fxLayout="column">
    <div fxFlex="10%" fxLayout="row" fxLayoutAlign="end start">
    </div>
    <div fxFlex="80%" class="row mb-5">
        <div class="col-12 col-sm-12 col-lg-2"></div>
        <div class="col-12 col-lg-4 col-sm-12 login-image">
            <div class="custom-space-logo " fxLayout="column">

                <div class="custom-logo">
                    <img src="assets/images/svg/elearn.svg" class="logo pl-0">
                    <p class="desc-tenant mt-4">Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.
                        Tincidunt id condimentum praesent nam.
                        Mi nisi cras neque molestie. Lobortis commodo, volutpat elementum ultricies. Sit a nisl diam mi.
                        Vestibulum eu consequat orci aliquam massa.</p>
                </div>
            </div>
            <div class="mt-5 mr-4 custom-banner">
                <img src="assets/images/svg/introduce.svg">
            </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-1"></div>
        <div class="col-12 col-lg-3 bg-white col-lg-3 col-sm-12" style="border-radius: 10px">
            <div class="container" style="height: 100%">
                <label class="dn-login"> Thiết lập mật khẩu mới</label>
                <form [formGroup]="verifyAccount" (ngSubmit)="confirmPassword(verifyAccount.value)">
                    <div class="form-group pb-0 mb-0">

                        <p class="title-input-form">Nhập mật khẩu mới</p>
                        <input placeholder="Nhập mật khẩu mới" type="password" #newPassword
                               (keyup)="checkNewPassword(newPassword.value)" formControlName="verify_account"
                               class="form-control"
                               id="verify-account">
                        <div class="position-show-password"
                             (click)="showPassword('verify_account', newPassword)">
                            <img *ngIf="!iconEyeNewPass" class="icon-eye"
                                 src="../../../../assets/images/svg/eye-show.svg" alt="">
                            <img *ngIf="iconEyeNewPass" class="icon-eye"
                                 src="../../../../assets/images/svg/eye-hide.svg" alt="">
                        </div>
                        <!--Mật khẩu không để trống-->
                        <span class="text-danger"
                              *ngIf="(validate.verify_account.is_required == 2|| verifyAccount.get('verify_account').hasError('required') &&
                        (this.verifyAccount.get('verify_account').touched || this.verifyAccount.get('verify_account').dirty))">
                                    <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu không được để trống
                                    <br>
                                </span>

                        <ng-container *ngIf="validate.verify_account.is_required == 1">
                            <!--Mật khẩu phải ít nhất 6 ký tự-->
                            <span class="text-danger"
                                  *ngIf="(validate.verify_account.is_min_length == 2) || verifyAccount.get('verify_account').hasError('required') && (verifyAccount.get('verify_account').touched || verifyAccount.get('verify_account').dirty)">
                                    <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu phải ít nhất 6 ký tự
                                    <br>
                                </span>
                            <span class="text-success"
                                  *ngIf="(validate.verify_account.is_min_length == 1)">
                                    <img src="assets/images/svg/success_validate.svg" alt=""> Mật khẩu phải ít nhất 6 ký tự
                                    <br>
                                </span>
                            <!--Mật khẩu phải ít nhất 6 ký tự-->

                            <!--1 ký tự số-->
                            <span class="text-danger"
                                  *ngIf="(validate.verify_account.is_number == 2)">
                                    <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự số
                                    <br>
                                </span>
                            <span class="text-success"
                                  *ngIf="(validate.verify_account.is_number == 1)">
                                    <img src="assets/images/svg/success_validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự số
                                    <br>
                                </span>
                            <!--1 ký tự số-->

                            <!--1 ký tự thường hoặc 1 ký tự hoa-->
                            <span class="text-danger"
                                  *ngIf="(validate.verify_account.is_chart == 2)">
                                    <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự chữ (chữ hoa hoặc thường)
                                    <br>
                                </span>
                            <span class="text-success"
                                  *ngIf="(validate.verify_account.is_chart == 1)">
                                    <img src="assets/images/svg/success_validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự chữ (chữ hoa hoặc thường)
                                    <br>
                                </span>
                            <!--1 ký tự số-->

                            <!--Qua ky tu cho phep-->
                            <span class="text-danger"
                                  *ngIf="(validate.verify_account.is_max_length == true)">
                                    <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu vượt quá 50 ký tự
                                    <br>
                                </span>
                            <!--Qua ky tu cho phep-->
                        </ng-container>
                        <br>
                    </div>
                    <div class="form-group pb-0">

                        <label class="title-input-form">Nhập lại mật khẩu mới</label>
                        <input placeholder="Nhập lại mật khẩu" type="password" #repeatPassword
                               (keyup)="checkRepeatPassword(repeatPassword.value)"
                               formControlName="repeat_password" class="form-control">
                        <div (click)="showPassword('repeat_password', repeatPassword)"
                             class="position-show-password">
                            <img *ngIf="!iconEyeRepeatPass" class="icon-eye"
                                 src="../../../../assets/images/svg/eye-show.svg" alt="">
                            <img *ngIf="iconEyeRepeatPass" class="icon-eye"
                                 src="../../../../assets/images/svg/eye-hide.svg" alt="">
                        </div>
                        <ng-container *ngIf="verifyAccount.get('repeat_password').hasError('required') &&
                                (this.verifyAccount.get('repeat_password').touched || this.verifyAccount.get('repeat_password').dirty) || validate.is_repeat_password_required === 1">
                                    <span class="text-danger"><img
                                            src="assets/images/svg/icon-error-validate.svg" alt=""> Nhập lại mật khẩu không được để trống <br></span>
                        </ng-container>
                        <ng-container *ngIf="validate.is_repeat_password_required === 0">
                                    <span class="text-danger"
                                          *ngIf="(validate.is_repeat_password == 1)">
                                      <img src="assets/images/svg/icon-error-validate.svg"> Mật khẩu không khớp
                                </span>
                            <span class="text-success"
                                  *ngIf="(validate.is_repeat_password == 2)">
                                    <img src="assets/images/svg/success_validate.svg"> Đã khớp mật khẩu
                                </span>
                        </ng-container>

                    </div>
                    <button type="submit" class="btn btn-primary sent-code font-helvetica-neue"
                            [disabled]="verifyAccount.invalid">
                        Xác nhận
                    </button>
                </form>
                <!--                        <button *ngIf="isOverTime == true" type="button" class="btn btn-primary sent-code "> Gửi mã-->
                <!--                        </button>-->
            </div>
        </div>
        <div class="col-2">
            <div class="text-center ">

            </div>
        </div>
    </div>
    <div fxFlex="10%"></div>
</section>
