import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UserPersonalService} from '@app/_services/user-personal.service';
import {AuthenticationService, ShowMessage} from '@app/_services';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {FormBuilder, Validators} from '@angular/forms';
import {LoginComponent} from '@app/components/login/login.component';

@Component({
  selector: 'app-shared-dialog-change-password',
  templateUrl: './dialog-change-password.component.html',
  styleUrls: ['./dialog-change-password.component.scss']
})
export class DialogChangePasswordComponent implements OnInit {
  public type = 'password';
  public message: string;
  iconEyeNewPass = false;
  iconEyeRepeatPass = false;
  iconEyeOldPass = false;
  code: string;
  value = {
    dataPassword: '',
    dataRePassword: '',
    dataOldPassword: ''
  };
  checkValidate = false;
  validate = {
    // 0 hidden, 1 fail, 2 success
    checkPasswordOld: 0,
    is_repeat_password: 0,
    is_repeat_password_required: 0,
    verify_account: {
      is_required: 0,
      is_min_length: 0,
      is_max_length: false,
      is_chart: 0,
      is_number: 0,
    }
  };
  isRepeatPass = 0;
  public verifyAccount = this.fb.group({
    verify_account: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(/^(?=.*\d)(?=.*[a-zA-Z]).{6,50}$/)]],
    repeat_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(/^(?=.*\d)(?=.*[a-zA-Z]).{6,50}$/)]]
  });

  constructor(
      private fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DialogChangePasswordComponent>,
      private userPersonalService: UserPersonalService,
      private authenticationService: AuthenticationService,
      private router: Router,
      private showMessage: ShowMessage,
  ) { }

  ngOnInit(): void {
  }


  checkOldPassword(password: string){
    const token = JSON.parse(localStorage.getItem('currentUser'));
    if (password === ''){
      this.validate.checkPasswordOld = 3;
    }
    else{
      this.userPersonalService.checkPasswordOld(token, password).subscribe((ref: any) => {
        if (ref.status === 1){
          this.validate.checkPasswordOld = 1;
          this.value.dataOldPassword = password;
        }
        else{
          this.validate.checkPasswordOld = 2;
        }
      });
    }
  }

  confirmPassword(data) {
    if (this.verifyAccount.invalid) {
      return this.checkValidate = true;
    }
    const token = JSON.parse(localStorage.getItem('currentUser'));
    this.userPersonalService.changePassword(token, data.verify_account, data.repeat_password).subscribe((ref: any) => {
      const userName = JSON.parse(localStorage.getItem('userName'));
      this.dialogRef.close();
      this.showMessage.success('Đổi mật khẩu thành công');
      this.authenticationService.login(userName, data.verify_account).pipe(first()).subscribe(
          (res: any) => {
            if (res.status === 1 ){
              this.router.navigate(['/']);
            }
            else{
              this.showMessage = res.msg;
            }
            return false;
          });
    });
  }


  checkRepeatPassword(repeatPassword: string) {
    if (this.validate.checkPasswordOld === 1){
      if (repeatPassword === '' || repeatPassword.trim() === '') {
        this.validate.is_repeat_password = 0;
        this.validate.is_repeat_password_required = 1;
      }
      else{
        this.validate.is_repeat_password_required = 0;
      }

      if (repeatPassword === this.verifyAccount.value.verify_account) {
        return this.validate.is_repeat_password = 2;
      }

      return this.validate.is_repeat_password = 1;
    }
  }

  checkNewPassword(password: string) {
    if (this.validate.checkPasswordOld === 1) {
      const regPatternNumber = password.toLowerCase().match(/(\d)/g);
      const regPatternChart = password.toLowerCase().match(/([a-z])/g);

      if (password.length === 0 || password.trim() === '') {
        this.validate.verify_account.is_required = 2;
      } else {
        this.validate.verify_account.is_required = 1;
      }

      if (password.length >= 6) {
        this.validate.verify_account.is_min_length = 1;
      } else {
        this.validate.verify_account.is_min_length = 2;
      }

      if (password.length > 50) {
        this.validate.verify_account.is_max_length = true;
      } else {
        this.validate.verify_account.is_max_length = false;
      }


      if (regPatternChart !== null && regPatternChart.length > 0) {
        this.validate.verify_account.is_chart = 1;
      } else {
        this.validate.verify_account.is_chart = 2;
      }

      if (regPatternNumber !== null && regPatternNumber.length > 0) {
        this.validate.verify_account.is_number = 1;
      } else {
        this.validate.verify_account.is_number = 2;
      }

      if (password === this.verifyAccount.value.repeat_password && this.verifyAccount.value.repeat_password !== '') {
        this.validate.is_repeat_password = 2;
      }

      if (password !== this.verifyAccount.value.repeat_password && this.verifyAccount.value.repeat_password !== '') {
        this.validate.is_repeat_password = 1;
      }

    }
  }
}
