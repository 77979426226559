export const subjectRequest = {
    fullname: [
        {type: 'minLength', message: 'Tên môn học nhập 2 - 250 ký tự'},
        {type: 'maxLength', message: 'Tên môn học nhập 2 - 250 ký tự'},
        {type: 'maxLength', message: 'Tên môn học nhập 2 - 250 ký tự'},
        {type: 'pattern', message: 'Tên môn học chỉ chấp nhận các ký tự đặc biệt: _ - ( ) [ ] '},
        {type: 'trimError', message: 'Bạn cần nhập tên môn học'},
    ],
    code: [
        {type: 'trimError', message: 'Bạn cần nhập mã môn học'},
    ],
};
