<h4 mat-dialog-title class="text-center">
    <p class="dialog-active-title" [style]="{color: data.color}">{{data.title}}</p>
    <b class="dialog-active-name">{{ data.name }}</b>
</h4>
<mat-dialog-content class="mat-typography mat-dialog-content-global">
    <p class="dialog-active-content">{{data.message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn dialog-active-btn-cancel mat-elevation-z">{{ data.btnCancel }}</button>
    <button mat-button type="submit" class="btn dialog-active-btn-submit mat-elevation-z" (click)="confirm()"> {{ data.btnConfirm }}</button>
</mat-dialog-actions>