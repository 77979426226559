<div fxLayout="column" fxLayoutAlign="space-between none" style="height: 100%">
    <div>
        <mat-expansion-panel [expanded]="true"  class="panel-content-global">
            <mat-expansion-panel-header class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">
                <mat-panel-title class="panel-title">Thông tin cơ bản</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tên người dùng</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.fullName" type="text" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6  row row-global">
                    <label class="col-md-3 offset-1 lb-global">Mã </label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.userCode" type="text" class="form-control input-field-global" >
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Ngày sinh</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.birthday == null || infoUser?.birthday == 0 ? '' : infoUser?.birthday | parseTime: 'DD/MM/YYYY'" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 lb-global">Giới tính</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.gender == 1 ? 'Nam' : 'Nữ'" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Email</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.email" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 lb-global">Tài khoản</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.userName" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Số điện thoại</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser?.phone" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tỉnh/thành phố</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser.cityName" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6  row row-global">
                    <label class="col-md-3 offset-1 lb-global">Quận/huyện</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser.districtName" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Phường/xã</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div [innerHTML]="infoUser.wardName" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row">
                <div class="form-group col-md-12 pl-0 d-flex pr-0">
                    <label class="lb-global " style="width: 12% ">Địa chỉ</label>
                    <div class="pl-0 pr-0" style="!important; width: 88%">
                        <div [innerHTML]="infoUser.address" class="form-control input-field-global" >
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div style="border-top: solid 1px #dddddd ">
        <label class="lb-global">Đăng nhập gần nhất lúc 00:00 12/12/2020 từ IP 10.10.10.10</label>
    </div>
</div>



