import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministrativeLv1Component } from "./components/administrative-lv1/administrative-lv1.component";
import { DetailAdministrativeLv1Component } from "./components/detail-administrative-lv1/detail-administrative-lv1.component";
import { DetailAdministrativeLv2Component } from "./components/detail-administrative-lv2/detail-administrative-lv2.component";
import { DetailAdministrativeLv3Component } from "./components/detail-administrative-lv3/detail-administrative-lv3.component";
import {NgxPermissionsGuard} from 'ngx-permissions';
import { LOCATION_PERMISSION } from '@app/_modules/administrative-units/constant';

const routes: Routes = [
  {
    path: 'lv1',
    component: AdministrativeLv1Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [LOCATION_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'lv1/detail/:id',
    component: DetailAdministrativeLv1Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [LOCATION_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'lv2/detail/:id',
    component: DetailAdministrativeLv2Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [LOCATION_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'lv3/detail/:id',
    component: DetailAdministrativeLv3Component,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [LOCATION_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrativeUnitRoutingModule { }
