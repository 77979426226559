<h2 mat-dialog-title class="title-page-global text-center">{{nameRole}} - Quyền</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row input-search-dialog-list">
            <div class="col-lg-12">
                <input type="text" class="form-control input-field-global"
                       placeholder="Nhập tên, mã module hoặc tên, mã quyền" id="search-value-dialog"
                       (keydown)="checkText($event, searchDialogList.value)" #searchDialogList >
                <img src="assets/images/icons/icon-23.svg">
            </div>
            <div class="col-sm-12" fxLayout="row">
                <div class="text-select-all">
                    <mat-checkbox [(ngModel)]="checkStatus" (change)="checkAll($event)"></mat-checkbox> Chọn tất cả</div>
                <div class="ml-5 text-select-all">Đã chọn: {{arrayPermissionsAddToRole.length}} quyền mới</div>
            </div>
        </div>
        <div class="row mt-1" *ngIf="checkData">
            <div class="col-lg-4 mt-2" *ngFor="let element of dataShowPermissions">
                <div class="px-2 pt-2 box-list-module">
                    <label class="label-dialog-list-module pb-1">
                        <mat-checkbox [checked]="element.is_selected" [value]="element.id" (change)="checkAllModule($event,element.stt);"></mat-checkbox> {{ element.module }} <span class="code-dialog-list-permission">({{element.code}})</span>
                    </label>
                    <div class="div-box-module">
                        <div class="mt-2" *ngFor="let item of element.permissionsArray">
                            <mat-checkbox [(ngModel)]="item.is_selected" (ngModelChange)="updateAllComplete(element.stt)" [value]="item.id" (change)="checkItemChecked($event,element.id);"></mat-checkbox>
                            <span class="label-dialog-list-permission mt-4"> {{ item.permission }} <span class="code-dialog-list-permission">({{ item.code }})</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn-cancel mat-elevation-z">HỦY</button>
    <button type="submit" class="btn btn-add-edit ml-2 px-4 mat-elevation-z" mat-raised-button (click)="addPermissionsToRole();">LƯU</button>
</mat-dialog-actions>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
