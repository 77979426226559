import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/_shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdministrativeUnitRoutingModule } from './administrative-units-routing.module';
import { AdministrativeLv1Component } from './components/administrative-lv1/administrative-lv1.component';
import { DetailAdministrativeLv1Component } from "./components/detail-administrative-lv1/detail-administrative-lv1.component";
import { DetailAdministrativeLv2Component } from "./components/detail-administrative-lv2/detail-administrative-lv2.component";
import { DetailAdministrativeLv3Component } from "./components/detail-administrative-lv3/detail-administrative-lv3.component";
import { ImportAdministrativeComponent } from "./components/import-administrative/import-administrative.component";
import { DialogFormAdministrativeLv1Component } from "./dialogs/dialog-form-administrative-lv1/dialog-form-administrative-lv1.component";
import { DialogFormAdministrativeLv2Component } from "./dialogs/dialog-form-administrative-lv2/dialog-form-administrative-lv2.component";
import { DialogFormAdministrativeLv3Component } from "./dialogs/dialog-form-administrative-lv3/dialog-form-administrative-lv3.component";
import { DialogImportAdministrativeComponent } from "./dialogs/dialog-import-administrative/dialog-import-administrative.component";
import { DialogMoveAdministrativeComponent } from "./dialogs/move-administrative/dialog-move-administrative.component";
import { DialogMoveAdministrativeLv3Component } from "./dialogs/move-administrative-lv3/dialog-move-administrative-lv3.component";
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    AdministrativeLv1Component,
    DetailAdministrativeLv1Component,
    DetailAdministrativeLv2Component,
    DetailAdministrativeLv3Component,
    ImportAdministrativeComponent,
    DialogFormAdministrativeLv1Component,
    DialogFormAdministrativeLv2Component,
    DialogFormAdministrativeLv3Component,
    DialogImportAdministrativeComponent,
    DialogMoveAdministrativeComponent,
    DialogMoveAdministrativeLv3Component
  ],
  imports: [
    CommonModule,
    AdministrativeUnitRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild()
  ]
})
export class AdministrativeUnitModule { }
