export const GRADE_PERMISSION = {
    perm1: 'core_admin_grades',
    perm2: 'core_admin_grade_add',
    perm3: 'core_admin_grade_detail',
    perm4: 'core_admin_grade_edit',
    perm5: 'core_admin_grade_delete',
    perm6: 'core_admin_grade_tenants',
    perm7: 'core_admin_grade_tenant_add',
    perm8: 'core_admin_grade_tenant_delete',
    perm9: 'core_admin_grade_subjects',
    perm10: 'core_admin_grade_subject_add',
    perm11: 'core_admin_grade_subject_delete',
}
