import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { CurriculumDashbroadComponent } from './components/curriculum-dashbroad/curriculum-dashbroad.component';
import { CurriculumListComponent } from './components/curriculum-list/curriculum-list.component';
import { CurriculumFormComponent } from './components/curriculum-form/curriculum-form.component';
import { CurriculumDetailComponent } from './components/curriculum-detail/curriculum-detail.component';
const routes: Routes = [
  {
    path: 'list/:subjectId',
    component: CurriculumDashbroadComponent,
    children: [ 
      {
        path: '',
        component: CurriculumListComponent,
      },
      {
        path: ':idCurriculum',
        component: CurriculumDetailComponent,
      },
    ]
  },
  {
    path: 'form/:idCurriculum',
    component: CurriculumFormComponent,
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CurriculumRoutingModule { }
