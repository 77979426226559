export const lang = {
    title_list_customer: 'Danh sách khách hàng áp dụng',
    total: 'Tổng số',
    lable: {
        stt: 'STT',
        fullname: 'Tên khách hàng',
        code: 'Mã khách hàng',
        domain: 'Tên miền',
        phone : 'Số điện thoại',
        email: 'Email',
        status: 'Trạng thái',
        active: 'Kích hoạt',
        tools: 'Công cụ'
    },
}
