<div id="dialog-content-global" >
    <h2 mat-dialog-title class="title-page-global text-center">{{this.data.roleName}} - Người dùng</h2>
    <mat-dialog-content>
        <div class="container-fluid">
            <div class="row">
                <span>Tổng số: {{ length }} người dùng</span>
            </div>
            <div class="row input-search-dialog-list">
                <input id="search-value-dialog" (keydown)="checkText($event, searchDialogList.value)" #searchDialogList type="text" class="form-control input-field-global" placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email">
                <img src="assets/images/icons/icon-23.svg">
            </div>
            <div class="row mt-1">
                <table mat-table [dataSource]="dataSource" class="table-el">
                    <ng-container matColumnDef="stt">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                        <td mat-cell *matCellDef="let item; let i = index">{{i+1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="fullName">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%">Người dùng</th>
                        <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                        <td mat-cell *matCellDef="let item">{{item.userCode}}</td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                        <td mat-cell *matCellDef="let item">{{item.phone}}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                        <td mat-cell *matCellDef="let item">{{item.email}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
                </table>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button class="btn-close-dialog-global mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
    </mat-dialog-actions>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
</div>
