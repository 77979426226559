import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import {AccountManagerService, ListenFirebaseService, ShowMessage} from '@app/_services/index';
import {Observable, Subscriber} from 'rxjs';


@Component({
  selector: 'app-dialog-add-tenant',
  templateUrl: './dialog-add-tenant.component.html',
  styleUrls: ['./dialog-add-tenant.component.scss']
})
export class DialogAddTenantComponent implements OnInit {
  dataCustomer:any;
  displayedColumns: string[] = ['id', 'fullname', 'code', 'domain', 'phone', 'email'];
  select_all = false;
  count_selected:number = 0;
  arrayTenant = [];
  dataFilter = {
    id: '',
    KeySearch: '',
    PageSize: 99999,
    PageIndex: 1,
  };
  isLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountManagerService:AccountManagerService,
    private matDialogRef:MatDialogRef<DialogAddTenantComponent>,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService
  ) { }

  ngOnInit(): void {
    this.getDataTenant();
  }

  getDataTenant(){
    this.count_selected = 0;
    this.isLoading = true;
    // data tenant not assign
    this.dataFilter.id = this.data.userId;
    this.accountManagerService.getTenantNotAssign(this.dataFilter).subscribe((res: any) => {
      this.dataCustomer = res.data;
      this.isLoading = false;
    });
  }

  search(event){
    if (event.key === 'Enter' || event.key === 'Tab'){
      this.dataFilter.KeySearch = event.target.value;
      this.getDataTenant();
    }
  }

  onSelectAll(e: any): void {
    for (let i = 0; i < this.dataCustomer.length; i++) {
      const item = this.dataCustomer[i];
      item.is_selected = e.checked;
    }
    if(e.checked){
      this.count_selected = this.dataCustomer.length;
      this.dataCustomer.forEach((element)=>{
        this.arrayTenant.push(element.tenantId);
      });
    }else{
      this.arrayTenant = [];
      this.count_selected = 0;
    }
  }

  countSelected(e){
    if(e.checked){
      this.arrayTenant.push(e.source.value);
      this.count_selected++;
    }else{
      const index = this.arrayTenant.indexOf(e.source.value);
      if (index > -1) {
        this.arrayTenant.splice(index, 1);
      }
      this.count_selected--;
    }
  }

  clickAddTenant(){
    this.isLoading = true;
    this.accountManagerService.assignTenantsToUser(this.data.userId,this.arrayTenant).subscribe(
      (res: any) => {
        this.isLoading = false
        if (res.status === 0) return this.showMessage.error(res.msg);
        this.matDialogRef.close(true);

      }, error => this.isLoading = false
    );

  }

}
