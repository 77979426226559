export const USER_PERMISSION = {
    perm1: "core_admin_users",
    perm2: "core_admin_user_add",
    perm3: "core_admin_user_detail",
    perm4: "core_admin_user_edit",
    perm5: "core_admin_user_delete",
    perm6: "core_admin_user_import",
    perm7: "core_admin_user_export",
    perm8: "core_admin_user_log_histories",
    perm9: "core_admin_user_reset_password",
    perm10: "core_admin_user_tenants",
    perm11: "core_admin_user_tenant_add",
    perm12: "core_admin_user_tenant_delete",
    perm13: "core_admin_user_roles",
    perm14: "core_admin_user_role_add",
    perm15: "core_admin_user_role_delete"
}














