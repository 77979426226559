import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {MatDialog} from '@angular/material/dialog';
import {RoleManagerService} from '@app/_services';

@Component({
  selector: 'app-role-list-module-permission',
  templateUrl: './role-list-module-permission.component.html',
  styleUrls: ['./role-list-module-permission.component.scss']
})
export class RoleListModulePermissionComponent implements OnInit, OnChanges {
  @Input() roleId;
  @Input() dataSearch;
  @Input() change;
  @Output() outCheckRemoveSubject = new EventEmitter<boolean>();
  dataPermissions:any;
  dataPermissionAll:any;
  dataShowPermissions = [];
  arrayModuleIds = [];
  arrayResultPermissions = [];
  checkData:boolean = false;
  isLoading:boolean = false;

  constructor(
      private dialogRes: MatDialog,
      private roleManagerService: RoleManagerService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.dataShowPermissions = [];
      this.arrayModuleIds = [];
      this.arrayResultPermissions = [];
      this.loadDataItems(this.dataSearch);
    }, 1500);
  }

  ngOnChanges() {
    if (this.change !== 0) {
      this.isLoading = true;
      setTimeout(() => {
        this.dataShowPermissions = [];
        this.arrayModuleIds = [];
        this.arrayResultPermissions = [];
        this.loadDataItems(this.dataSearch);
      }, 1500);
    }
  }

  loadDataItems(keyWord?: string){
    this.roleManagerService.getPermissionsOfRole(this.roleId, keyWord, 0, 999999, '').subscribe((data: any) => {
      this.dataPermissions = data.data.permissionCheck;
      this.dataPermissionAll = data.data.permissionAll;
      if (this.dataPermissions.length !== 0){
        this.outCheckRemoveSubject.emit(true);
      }
      else{
        this.outCheckRemoveSubject.emit(false);
      }
        // lay ra nhung module id da duoc dung
        this.dataPermissions.forEach((element) => {
          this.arrayModuleIds.push(element.moduleId);
        });
        this.arrayModuleIds = this.unique(this.arrayModuleIds);
        // lay danh sach tat ca permisson thuoc cac module id
        this.arrayModuleIds.forEach((element) => {
          let arrayPermissionsTemp = [];
          let moduleId = '';
          let moduleName = '';
          let moduleCode = '';
          let checkIsPerrmissonActive = false;
          this.dataPermissionAll.forEach((item) => {
            if(element === item.moduleId){
              moduleName = item.moduleName;
              moduleCode = item.moduleCode;
              moduleId = item.moduleId;
              var checkActivePermission = this.dataPermissions.filter(element => element.permissionId == item.permissionId);
              if(checkActivePermission.length !== 0){
                item.status = true;
              }else{
                item.status = false;
              }
              arrayPermissionsTemp.push(item);
            }
          });
          this.dataShowPermissions.push({
            id: moduleId,
            name: moduleName,
            code: moduleCode,
            is_selected: checkIsPerrmissonActive,
            permissons: arrayPermissionsTemp,
          });
          this.dataShowPermissions.find(x => x.id  === moduleId).is_selected = this.dataShowPermissions.find(x => x.id  === moduleId).permissons != null && this.dataShowPermissions.find(x => x.id  === moduleId).permissons.every(t => t.status);
        });
        this.checkData = true;
        this.isLoading = false;
    });
  }

  unique(arr) {
    var obj = {};
    var newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (!obj[arr[i]]) {
        obj[arr[i]] = 1;
        newArr.push(arr[i]);
      }
    }
    return newArr;
  }
}
