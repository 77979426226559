import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PostManagerService} from '@app/_services/post-manager.service';

@Component({
  selector: 'app-dialog-post-manager-list-object',
  templateUrl: './dialog-post-manager-list-object.component.html',
  styleUrls: ['./dialog-post-manager-list-object.component.scss']
})
export class DialogPostManagerListObjectComponent implements OnInit {
  recipients: any;

  constructor(private changeDetectorRef: ChangeDetectorRef, private dialogRes: MatDialogRef<DialogPostManagerListObjectComponent>,
              private postManagerService: PostManagerService, @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit(): void {
    this.getRecipientByPost(this.data.id);
  }

  getRecipientByPost(itemId, keyWord?: string) {
    this.postManagerService.getRecipientPostManager(itemId, keyWord).subscribe((ref: any) => {
      this.recipients = ref.data;
    });
  }

  checkText(event, value) {
    event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
    if (event.type === 'blur') {
      this.getRecipientByPost(this.data.id, value);
    }
  }
}
