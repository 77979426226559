<section class="container-fluid main-wrapper" fxLayout="column">
    <div fxFlex="10%" fxLayout="row" fxLayoutAlign="end start">
    </div>
    <div fxFlex="80%" class="row mb-5">
        <div class="col-12 col-sm-12 col-lg-2"></div>
        <div class="col-12 col-lg-4 col-sm-12 login-image">
            <div class="custom-space-logo " fxLayout="column">

                <div class="custom-logo">
                    <img src="assets/images/svg/elearn.svg" class="logo pl-0">
                    <p class="desc-tenant mt-4">Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.
                        Tincidunt id condimentum praesent nam.
                        Mi nisi cras neque molestie. Lobortis commodo, volutpat elementum ultricies. Sit a nisl diam mi.
                        Vestibulum eu consequat orci aliquam massa.</p>
                </div>
            </div>
            <div class="mt-5 mr-4 custom-banner">
                <img src="assets/images/svg/introduce.svg">
            </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-1"></div>
        <div class="col-12 col-lg-3 bg-white col-lg-3 col-sm-12" style="border-radius: 10px">
            <div class="container" style="height: 100%">
                <button style="width: auto; position: relative; right: 18px" class="btn" routerLink="../login">
                    <img src="assets/images/svg/back-icon.svg">
                </button>
                <br>
                <label class="dn-login"> Xác nhận tài khoản của bạn</label>
                <p class="card-text" id="describe"> Chúng tôi sẽ gửi một mã xác nhận qua Email hoặc số điện
                    thoại của
                    bạn để thực hiện thiết lập lại mật khẩu.</p>
                <form [formGroup]="verifyAccount" (ngSubmit)="sentCode(verifyAccount.value)">
                    <div class="form-group">
                        <span class="text-danger" *ngIf="this.verifyAccount.get('username').invalid && (this.verifyAccount.get('username').dirty || this.verifyAccount.get('username').touched) || isSpace">*Bạn cần phải nhập tài khoản <br></span>

                        <span class="text-danger pb-2" *ngIf="message"> {{'*'+message}}
                            <br>
                                </span>
                        <label class="title-input-form">Tài khoản</label>

                        <input type="text" formControlName="username"
                               (keyup)="checkValidateAccount(username.value)" #username class="form-control"
                               id="username">
                    </div>
                    <p *ngIf="isOverTime" class="text-danger">
                        *Gửi lại mã {{timeOut}}
                    </p>
                    <div class="d-flex">
                        <button [disabled]="verifyAccount.invalid || isSpace" type="submit" class="btn btn-primary sent-code "
                        > Gửi mã
                        </button>
                    </div>
                </form>
                <div class="mt-2 pb-2">
                    <a href="javascript:;" routerLink="../verify-account" [queryParams]="{username: ''}">Bạn đã có mã xác nhận ?</a>
                </div>
                <!--                        <button *ngIf="isOverTime == true" type="button" class="btn btn-primary sent-code "> Gửi mã-->
                <!--                        </button>-->
            </div>
        </div>
        <div class="col-2">
            <div class="text-center ">

            </div>
        </div>
    </div>
    <div fxFlex="10%"></div>
</section>
