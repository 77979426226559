import { Component, OnInit, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogFormModuleComponent} from '../../dialogs/dialog-form-module/dialog-form-module.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ListenFirebaseService, ModulesManagerService} from '@app/_services';
import {ShowMessage} from '@app/_services';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {MODULE_PERMISSION} from '../../constants';

@Component({
  selector: 'app-detail-module',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./module-detail.component.scss']
})
export class ModuleDetailComponent implements OnInit {
  public infoModule: any;
  activeTab = '';
  showSearch = false;
  showFilter = false;
  dataSearch = '';
  check = false;
  openDialog = 0;
  isCheckModule: boolean;
  moduleId: string;
  dataFilter = [];
  memoryDataFilter = [];
  isChange = false;
  isLoading:boolean = false;
  dataStatus = [
    {id: 0, name: 'Chưa kích hoạt', checked: false},
    {id: 1, name: 'Dùng thử', checked: false},
    {id: 2, name: 'Đang sử dụng', checked: false},
    {id: 3, name: 'Hết hạn sử dụng', checked: false},
    {id: 4, name: 'Hết hạn dùng thử', checked: false},
    {id: 5, name: 'Khác', checked: false},
  ];
  MODULE_PERMISSION = MODULE_PERMISSION;
  typeModule = "module";

  constructor(
      public dialog: MatDialog,
      public route: Router,
      public activatedRoute: ActivatedRoute,
      private moduleManagerModule: ModulesManagerService,
      private showMessage: ShowMessage,
      private db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService

  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(param => {
      const arr = param.type.split("-");
      this.isCheckModule = arr[0] === 'customer' ? true : false;
      this.activeTab = `${arr[1]}-${arr[2]}`;
    });
    this.loadDataItems();
  }


  listtenFirebase(){
    this.checkFireBase("update");
  }

  toggleClass(){
      this.openDialog = 0;
      this.showSearch = !this.showSearch;
      (document.getElementById('search-value') as HTMLInputElement).value = '';
      this.dataSearch = '';
      this.openDialog = 0;
  }

  checkText(event, value){
    this.openDialog = 0;
    if(event.code == "Enter" || event.code == "Tab"){
      this.dataSearch = value;
    }
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
  }

  itemOptionClick(event, index){
    let result = [];
    this.openDialog = 0;
    this.dataStatus[index].checked = !this.dataStatus[index].checked;
    this.dataStatus.forEach((item, index) => {
      if (item.checked === true) result.push(item.id);
    });
    this.memoryDataFilter = result;
  }

  filter(){
    this.isChange = !this.isChange;
    this.dataFilter = this.memoryDataFilter;
  }

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.isLoading = false;
        this.loadDataItems();
      }else{
        this.isLoading = false;
      }
    });
  }

  loadDataItems(){
    this.isLoading  = false;
    this.activatedRoute.paramMap.subscribe((param: any) => {
      this.moduleId = param.params.id;
      this.moduleManagerModule.getModuleById(this.moduleId).subscribe((res: any) => {
        if (res.data === null){
          return this.route.navigate(['modules-manager']);
        }

        return this.infoModule = res.data;
      }, (error: any) => {
                console.log(error);
      });
    });
  }

  openDialogUpdateModule() {
    const dialogRef = this.dialog.open(DialogFormModuleComponent, {
      height: 'auto',
      width: '80%',
      data: {
        status: 'update',
        infoModule: this.infoModule,
      },
    });

    dialogRef.afterClosed().subscribe((dataInput: any) => {
      if(typeof dataInput !== 'undefined' && dataInput != false){
        this.listtenFirebase();
        this.isLoading = true;
        this.moduleManagerModule.updateModule(dataInput.id, dataInput.data).subscribe(
          (res:any)=>{
            if(res.status == 0){
              this.isLoading = false;
              this.showMessage.error(res.msg);
            }
          },
          (err)=>{
            this.isLoading = false;
          }
        );
        setTimeout(() => {
          if(this.isLoading){
            this.isLoading = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }
    });
  }

  openDialogAdd(){
    return this.openDialog += 1;
  }

  changeTab(nameTab){
    this.openDialog = 0;
    this.activeTab = nameTab;
  }
}
