import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {ListenFirebaseService, ModulesManagerService} from '@app/_services';
import {DialogChangeStatusComponent} from '../../../dialogs/dialog-change-status/dialog-change-status.component';
import * as $ from 'jquery';
import {ShowMessage} from '@app/_services';
import {DialogAddCustomerComponent} from '../../../dialogs/dialog-add-customer/dialog-add-customer.component';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { NgxPermissionsService } from 'ngx-permissions';
import {MODULE_PERMISSION} from '../../../constants';
@Component({
    selector: 'app-tab-list-customer-of-detail-module',
    templateUrl: './tab-list-customer-of-detail-module.component.html',
    styleUrls: ['./tab-list-customer-of-detail-module.component.scss']
})
export class TabListCustomerOfDetailModuleComponent implements OnInit, OnChanges {
    @Input() moduleId;
    displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'status'];
    dataSource = [];
    @Input() openDialogAdd;
    @Input() search;
    @Input() dataFilter;
    @Input() isChange;
    @Input() moduleName;
    isLoading:boolean = false;
    typeModule = "module";

    statusUser = [
        {name: 'Chưa kích hoạt', color: '#77B6D1'},
        {name: 'Dùng thử', color: '#1355C1'},
        {name: 'Đang sử dụng', color: '#FF8038'},
        {name: 'Hết hạn sử dụng', color: '#CC4D4D'},
        {name: 'Khác', color: '#8950FC'},
    ];

    length: number;
    currentPage: number;
    MODULE_PERMISSION = MODULE_PERMISSION;
    constructor(
        public dialog: MatDialog,
        private modulesManagerService: ModulesManagerService,
        private showMessage: ShowMessage,
        private db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService,
        private permissionsService: NgxPermissionsService
    ) {
    }

    ngOnInit(): void {
        if (this.permissionsService.getPermission(this.MODULE_PERMISSION.perm8) 
        ){
            const index = this.displayedColumns.indexOf('tools');
            if (index == -1) {
              this.displayedColumns.push('tools');
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.openDialogAdd === 0) {
            this.loadDataItems(this.search, this.dataFilter);
        }

        if (this.openDialogAdd > 0) {
            this.openDialogAddCustomer();
        }
    }

    checkFireBase(param){
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
        });
        listenFb.subscribe((res) => {
            if(res === true) {
                this.isLoading = false;
                this.loadDataItems();
            }else{
                this.isLoading = false;
            }
        });
    }

    listenFirebase(){
        this.checkFireBase('insert_tenants_to_module');
        this.checkFireBase('delete_tenant_in_module');
    }

    loadDataItems(keyword = null, status = null) {
        this.isLoading = true;
        const data = {moduleId: this.moduleId, keyword, status};
        this.modulesManagerService.listTenantOfModule(data).subscribe((res: any) => {
            this.dataSource = res.data;
            this.isLoading = false;
        });
    }

    openDialogAddCustomer() {
        const dialogRef = this.dialog.open(DialogAddCustomerComponent, {
            width: '80%',
            height: 'auto',
            data: {
                status: 'add',
                moduleId: this.moduleId,
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if(res !== false || typeof res !== 'undefined'){
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.storeTenantOfModule(res).subscribe((res: any) => {
                    if(res.status == 0){
                      this.showMessage.error(res.msg);
                      this.isLoading = false;
                    }
                  },
                  (err)=>{
                      this.isLoading = false;
                  });
                setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }

    handlePage(event) {
        // return this.loadDataItems(event.pageIndex, event.pageSize);
    }

    subString(value) {
        if (value !== null && value.length > 20) {
            return value.substr(1, 20) + '...';
        }
        return value;
    }

    openDialogDelete(item: any) {
        const dialogRef = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: `Bạn đang thực hiện gỡ khách hàng ra khỏi module`,
                message: 'Bạn có chắc muốn gỡ khách hàng này không?',
                btnOkText: 'XÁC NHẬN',
                btnCancelText: 'HỦY',
                data: {
                    name: `${item.fullname} - ${this.moduleName}`
                }
            }
        });
        dialogRef.afterClosed().subscribe(confirm => {
            if (confirm) {
                this.listenFirebase();
                this.isLoading = true;
                this.modulesManagerService.removeTenantById({moduleId: this.moduleId, tenantId: item.id}).subscribe((res: any) => {
                    if(res.status == 0){
                      this.showMessage.error(res.msg);
                      this.isLoading = false;
                    }
                  },
                  (err)=>{
                      this.isLoading = false;
                  });
                  setTimeout(() => {
                    if(this.isLoading){
                      this.isLoading = false;
                    }
                }, TIME_OUT_LISTEN_FIREBASE);
            }
        });
    }
}
