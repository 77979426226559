import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {TENANT_PERMISSION} from '../../../constants';
@Component({
  selector: 'app-tab-tenant-config',
  templateUrl: './tab-tenant-config.component.html',
  styleUrls: ['./tab-tenant-config.component.scss']
})
export class TabTenantConfigComponent implements OnInit {
  panelOpenState = true;
  activeTab: any;
  @Input() data:any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }
  TENANT_PERMISSION = TENANT_PERMISSION;
  queryParams: any;

  ngOnInit(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.activeTab = this.queryParams.activeTab;
    if(this.activeTab === undefined) {
      this.activeTab = 'subject';
    }
  }

  moveTab(tab: string){
    this.router.navigate([], {queryParams: {activeTab: tab}});
    this.activeTab = tab;
  }

}
