<div fxLayout="column" class="bg-content-global">
    <div fxFlex="20">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h4 class="title-page-global ">Phân quyền quản trị hệ thống</h4>
                <p class="result-global">Số lượng phân quyền hệ thống: {{ total }}</p>
            </div>
            <div>
                <button class="btn-add-global" mat-raised-button (click)="showFormPermission('add')"><mat-icon>add</mat-icon>Thêm phân quyền</button>
            </div>
        </div>
    </div>
    <div fxFlex>
        <table mat-table [dataSource]="dataPermission.data" matSort class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef>STT</th>
                <td mat-cell *matCellDef="let element">{{element?.stt}}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef>Tên phân quyền</th>
                <td mat-cell *matCellDef="let element" class="text-important-table">{{element?.fullname}}</td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>Mã phân quyền</th>
                <td mat-cell *matCellDef="let element">{{element?.code}}</td>
            </ng-container>
            <ng-container matColumnDef="module">
                <th mat-header-cell *matHeaderCellDef>Module áp dụng</th>
                <td mat-cell *matCellDef="let element">{{element?.module?.fullname}}</td>
            </ng-container>
            <ng-container matColumnDef="is_active">
                <th mat-header-cell *matHeaderCellDef>Trạng thái</th>
                <td mat-cell *matCellDef="let element">
<!--                    (change)="changeStatusRole($event, element.id)"-->
                    <mat-checkbox [checked]="element?.is_active === 1 ? true : false" ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="tool">
                <th mat-header-cell *matHeaderCellDef>Công cụ</th>
                <td class="border-last-5 text-right" mat-cell *matCellDef="let element" >
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <button mat-menu-item routerLink="detail-permission/{{element.id}}">Chi tiết phân quyền</button>
                        <button mat-menu-item (click)="showFormPermission('edit', element)">Cập nhật quyền thực thi</button>
                        <button mat-menu-item class="text-danger" (click)="confirmDelete(element.fullname, element.id)">Xóa phân quyền</button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" style="line-height: 10px"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns" style="background: white"></tr>
        </table>
        <mat-paginator *ngIf="total > limit" style="background-color: #F2F3F8;" showFirstLastButtons [length]="total" [pageIndex]="page" [pageSize]="limit" (page)="onChangePaginate($event)"></mat-paginator>
    </div>
</div>



