<section class="chart">
    <div class="chart__header">
        <h4 class="title-page-global">Khách hàng</h4>
        <div class="chart__date">
            
        </div>
    </div>
    <div class="chart__content">

    </div>
</section>