<div fxLayout="column" style="height: 100%">
    <div fxFlex="40px" fxLayout="row" fxLayoutAlign="end start">
        <input [(ngModel)]="keyword" #search (keypress)="onKeyPressSearch($event)" (blur)="onBlur($event)" class="input-field-global" style="height: 40px; width: 55%" placeholder="Tên hoặc mã khối"><span class="icon-search"><mat-icon>search</mat-icon></span>
        <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm10]">

        <button class="btn-add-global ml-1 mr-5 mat-elevation-z" mat-raised-button (click)="showFormEditGrade()"><img class="mr-2 mb-1" src="assets/images/icons/icon-21.svg">Chỉnh sửa</button>
        </ng-template>
    </div>
    <div fxFlex class="content-data">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                <td mat-cell *matCellDef="let item;let i = index">{{(pageSize * pageIndex) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="nameGrade">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Tên khối</th>
                <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullname}}</td>
            </ng-container>
            <ng-container matColumnDef="codeGrade">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Mã khối</th>
                <td mat-cell *matCellDef="let item" >{{item.code}}</td>
            </ng-container>
            <ng-container matColumnDef="levelGrade">
                <th mat-header-cell *matHeaderCellDef style="width: 25%">Cấp</th>
                <td mat-cell *matCellDef="let item">Cấp {{item.trainingLevel}}
                    <p *ngIf="item.trainingLevel === 1" class="title-subject ellipsis-text">Mầm non</p>
                    <p *ngIf="item.trainingLevel === 2" class="title-subject ellipsis-text">Tiểu học</p>
                    <p *ngIf="item.trainingLevel === 3" class="title-subject ellipsis-text">Trung học cơ sở</p>
                    <p *ngIf="item.trainingLevel === 4" class="title-subject ellipsis-text">Trung học phổ thông</p>
                    <p *ngIf="item.trainingLevel === 99" class="title-subject ellipsis-text">Khác</p>
                </td>
            </ng-container>
            <ng-container matColumnDef="tool">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">Công cụ</th>
                <td mat-cell style="text-align: center;" *matCellDef="let item">
                   <img src="assets/images/icons/icon-22.svg" style="cursor: pointer" (click)="confirmDelete(item)">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
            <tr mat-row *matRowDef="let row, columns: dataColumn"></tr>
        </table>
        <ng-container *ngIf="totalRecord > pageSize">
            <mat-paginator appStylePaginator class="table-paginate-global" [length]="totalRecord" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
        </ng-container>
    </div>
</div>


<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
</div>