import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-change-status',
  templateUrl: './dialog-change-status.component.html',
  styleUrls: ['./dialog-change-status.component.scss']
})
export class DialogChangeStatusComponent implements OnInit {
  statusUser = [
      {name: 'Chưa kích hoạt', color: '#1355C1'},
      {name: 'Kích hoạt', color: '#FF8038'},
      {name: 'Bị khóa', color: '#283C63'},
      {name: 'Bị xóa', color: '#C4C4C4'}];
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialog: MatDialogRef<DialogChangeStatusComponent>
  ) { }

  ngOnInit(): void {
  }

  confirmStatus(){
    this.dialog.close(true);
  }

}
