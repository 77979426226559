import {Component, OnInit} from '@angular/core';
import {PersonalService} from '@app/_services';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/_modules/accounts-manager/dialogs/dialog-from-account/dialog-form-account.component';
import * as _moment from 'moment';
import {$e} from 'codelyzer/angular/styles/chars';
import {ShowMessage} from '@app/_services';

const moment = _moment;


@Component({
    selector: 'app-post-personal-list',
    templateUrl: './post-personal-list.component.html',
    styleUrls: ['./post-personal-list.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class PostPersonalListComponent implements OnInit {
    dataSource: any = [];
    showSearch: boolean = false;
    showFilter: boolean = false;
    dataSearch: string = '';
    check = false;
    date: any;
    filter: number;
    infoUser;
    caseDate = [
        {fullName: 'Hôm nay', id: 0, isChecked: false, isRead: false},
        {fullName: 'Hôm qua', id: 1, isChecked: false, isRead: false},
        {fullName: 'Khác', id: 2, isChecked: false, isRead: false},
    ];
    dataFilter = {
        startDate: null,
        toDate: null,
    };

    constructor(
        private personalService: PersonalService,
        private showMessage: ShowMessage,
    ) {
    }

    ngOnInit(): void {
        this.date = moment();
        this.getInfoUser();
    }

    changeFilter(event, value) {
        let curr = new Date; // get current date
        let caseDate = parseInt(event.target.value);

        if (caseDate === 0) {
            this.dataFilter.startDate = moment().utc().format('YYYYMMDD');
            this.dataFilter.toDate = null;
        }

        if (caseDate === 1) {
            let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            let last = first + 6; // last day is the first day + 6

            let firstday = moment(Date.parse(new Date(curr.setDate(first)).toUTCString())).utc().format('YYYYMMDD');
            let lastday = moment(Date.parse(new Date(curr.setDate(last)).toUTCString())).format('YYYYMMDD');
            this.dataFilter.startDate = firstday;
            return this.dataFilter.toDate = lastday;
        }

        if (caseDate === 2) {
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.dataFilter.startDate = moment(firstDay).format('YYYYMMDD');
            return this.dataFilter.toDate = moment(lastDay).format('YYYYMMDD');
        }

        this.filter = Number(value);

    }

    getInfoUser() {
        let token = JSON.parse(localStorage.getItem('currentUser'));
        this.personalService.getInfoUserByAccessToken(token).subscribe((res: any) => {
            if (res.status === 1) {
                this.infoUser = res.data;
                return this.getPostOfPersonal();
            }

            return this.showMessage.error(res.msg);
        }, err => {
            return this.showMessage.error(err);
        });
    }

    getPostOfPersonal() {
        let dayTimeNow = moment().utc().format('YYYYMMDD');
        this.personalService.getPostOfPersonal(this.infoUser.id, this.dataFilter.startDate, this.dataFilter.toDate, null, 1).subscribe((res: any) => {
            this.dataSource = res.data;
            this.dataSource.forEach(item => {
                if (item.receivedAtDateNum - parseInt(dayTimeNow) === 0) {
                    item.statusDate = 0;
                    this.caseDate[0].isChecked = true;
                    this.caseDate[0].isRead = true;
                }

                if (parseInt(dayTimeNow) - item.receivedAtDateNum === 1) {
                    item.statusDate = 1;
                    this.caseDate[1].isChecked = true;
                    if (this.caseDate[0].isRead !== true) {
                        this.caseDate[1].isRead = true;
                    }
                }

                if (parseInt(dayTimeNow) - item.receivedAtDateNum > 1) {
                    item.statusDate = 2;
                    this.caseDate[2].isChecked = true;
                    if (this.caseDate[0].isRead !== true && this.caseDate[1].isRead !== true) {
                        this.caseDate[2].isRead = false;
                    }
                }
            });
        });
    }

    filterData() {
        this.getPostOfPersonal();
    }

    changeDateTime(name, event) {
        let date = moment(event.target.value).format('YYYYMMDD');
        if (name === 'startDate') {
            this.dataFilter.startDate = date;
        }

        if (name === 'endDate') {
            this.dataFilter.toDate = date;
        }
    }

    toggleClass(value, check) {
        if (!check) {
            this.showSearch = !this.showSearch;
            (document.getElementById('search-value') as HTMLInputElement).value = '';
        } else {
            this.check = false;
            this.dataSearch = value;
        }
    }

    checkText() {
        let demo = (document.getElementById('search-value') as HTMLInputElement).value;

        if (demo.length > 0) {
            this.check = true;
        } else {
            this.check = false;
        }
    }

    showFilterClick() {
        this.showFilter = !this.showFilter;
        if (this.dataFilter) {
            this.dataFilter.startDate = moment().utc().format('YYYYMMDD');
        } else {
            this.dataFilter.startDate = null;
            this.dataFilter.toDate = null;
        }
    }

    parseMinute(param) {
        let totalTime: number;
        let yearNow = moment().utc().format('YYYY');
        let mouthNow = moment().utc().format('MM');
        let dayNow = moment().utc().format('DD');
        let hoursNow = moment().utc().format('hh');
        let minuteNow = moment().utc().format('mm');
        let secondsNow = moment().utc().format('ss');

        let yearCreateAt = moment(param * 1000).utc().format('YYYY');
        let mountCreateAt = moment(param * 1000).utc().format('MM');
        let dayCreateAt = moment(param * 1000).utc().format('DD');
        let hoursCreateAt = moment(param * 1000).utc().format('hh');
        let minuteCreateAt = moment(param * 1000).utc().format('mm');
        let secondsCreateAt = moment(param * 1000).utc().format('ss');

        if (yearNow > yearCreateAt) {
            return parseInt(yearNow) - parseInt(yearCreateAt) + ' ' + 'năm trước';
        }

        if (yearNow === yearCreateAt) {
            if (mouthNow > mountCreateAt) {
                return parseInt(mouthNow) - parseInt(mountCreateAt) + ' ' + 'tháng trước';
            }

            if (mountCreateAt === mouthNow) {
                if (dayNow > dayCreateAt) {
                    return parseInt(dayNow) - parseInt(dayCreateAt) + ' ' + 'ngày trước';
                }

                if (dayCreateAt === dayNow) {
                    if (hoursNow > hoursCreateAt) {
                        return parseInt(hoursNow) - parseInt(hoursCreateAt) + ' ' + 'giờ trước';
                    }
                    if (hoursNow === hoursCreateAt) {
                        if (minuteNow > minuteCreateAt) {
                            return parseInt(minuteNow) - parseInt(minuteCreateAt) + ' ' + 'phút trước';
                        }

                        return parseInt(secondsNow) - parseInt(secondsCreateAt) + ' ' + 'giây trước';
                    }
                }
            }
        }
    }

}
