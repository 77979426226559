<h2 mat-dialog-title class="text-center">Thêm phân hệ</h2>
<form [formGroup]="addRoleTenant" class="form" (ngSubmit)="onClickSubmit(addRoleTenant.value);">
    <mat-dialog-content class="mat-typography">
        <div class="row row-global">
            <div class="form-group col-md-12 row row-global">
                <div class="col-md-12 pl-0 pr-0 d-flex justify-content-center">
                    <div fxLayout="column" class="select-all-el">
                        <div fxFlex="60%" fxLayout="row" fxLayoutAlign="space-between end">
                            <h4 class="h4-el">Danh sách phân hệ</h4>
                            <mat-checkbox [checked]="select_all" (change)="onSelectAll($event)"><span>Chọn tất cả</span></mat-checkbox>
                        </div>
                        <div fxFlex class="search-item">
                            <input type="text" class="form-control input-field-global" placeholder="Tìm kiếm">
                            <mat-icon class="search-icon">search</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-12 row row-global">
                <div class="col-md-6 col-sm-6 .col-lg-6 item-el" *ngFor="let item of data_role">
                    <div class="item">
                        <div class="item-left">
                            <h4>{{ item.name }}</h4>
                            <p>{{ item.code }}</p>
                        </div>
                        <mat-checkbox [checked]="item.is_selected"></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button>HỦY</button>
        <button mat-button type="submit" mat-raised-button color="primary">THÊM</button>
    </mat-dialog-actions>
</form>