<div style="background-color: #fff; height: 100%;" id="dialog-form-config-noti-component">
  <p class="div__title" mat-dialog-title>Thêm mới thông báo hệ thống
    <span class="icon-close" [mat-dialog-close]="null"><img src="assets/images/icons/Vector-24.png"></span>
  </p>
  <hr>
  <mat-dialog-content class="div__content">
    <form [formGroup]="formConfigNoti">
      <div class="row">
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <label for="title">Tiêu đề <span class="text-danger">(*)</span></label>
        </div>
        <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <input type="text" placeholder="Nhập tiêu đề thông báo" name="title" class="form-control"
            formControlName="title">
          <mat-error *ngFor="let err of validationMessages.title">
            <mat-error
              *ngIf="formConfigNoti.get('title').hasError(err.type) && (formConfigNoti.get('title').dirty || formConfigNoti.get('title').touched)"
              class="message-err">
              {{err.message}}
            </mat-error>
          </mat-error>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <label for="content">Nội dung thông báo <span class="text-danger">(*)</span></label>
        </div>
        <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <textarea name="content" id="" cols="30" rows="7" class="form-control" formControlName="content"></textarea>
          <mat-error *ngFor="let err of validationMessages.content">
            <mat-error
              *ngIf="formConfigNoti.get('content').hasError(err.type) && (formConfigNoti.get('content').dirty || formConfigNoti.get('content').touched)"
              class="message-err">
              {{err.message}}
            </mat-error>
          </mat-error>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <label for="">Loại thông báo <span class="text-danger">(*)</span></label>
        </div>
        <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <mat-radio-group aria-label="Select an option" class="d-flex justify-content-between align-items-center"
            formControlName="type">
            <mat-radio-button value="1" class="success">SUCCESS</mat-radio-button>
            <mat-radio-button value="3" class="danger">DANGER</mat-radio-button>
            <mat-radio-button value="2" class="warning">WARNING</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="div__footer mt-3">
    <button mat-button mat-dialog-close type="submit" class="btn btn-custom-cancel-accept btn-cancel">Hủy</button>
    <button mat-button type="submit" class="btn btn-custom-cancel-accept btn-accept ml-2"
      (click)="onSubmit(formConfigNoti.value)">Lưu</button>
  </mat-dialog-actions>
</div>