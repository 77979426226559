<h2 mat-dialog-title class="title-page-global text-center">Thêm khối</h2>
<div class="container-fluid">
    <div class="row input-search-dialog-list">
        <div class="col-sm-12">
            <input type="text"  class="form-control input-field-global" placeholder=" Tìm kiếm theo Tên hoặc mã khối" id="search-value-dialog" (keydown)="checkText($event, searchDialogList.value)" #searchDialogList >
            <img src="assets/images/icons/icon-23.svg">
        </div>
        <div class="col-sm-12"  fxLayout="row">
            <div><mat-checkbox [checked]="allComplete" (change)="checkAllModule($event)"></mat-checkbox> Chọn tất cả</div>
            <div class="ml-5">Đã chọn: {{length}} khối  mới</div>
        </div>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row mt-1">
            <div class="col-sm-12">
                <p *ngIf="data && data.length === 0" style="font-size: 18px; font-weight: 500">Không có dữ liệu </p>
                <table mat-table [dataSource]="data" class="table-el" *ngIf="data && data.length > 0">
                    <ng-container matColumnDef="stt">
                        <th mat-header-cell *matHeaderCellDef style="width: 5%">Chọn</th>
                        <td mat-cell *matCellDef="let item"><mat-checkbox [ngModel]="item.checked" (change)="updateAllComplete($event, item.id)" ></mat-checkbox></td>
                    </ng-container>
                    <ng-container matColumnDef="grade">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%">Khối</th>
                        <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullname}}</td>
                    </ng-container>
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                        <td mat-cell *matCellDef="let item">{{item.code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="level">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%">Cấp đào tạo</th>
                        <td mat-cell *matCellDef="let item">{{item.trainingLevel}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
                </table>

            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn-cancel mat-elevation-z" mat-raised-button mat-dialog-close>HỦY</button>
    <button class="btn-save-data mat-elevation-z" mat-raised-button  (click)="addGradeBySubject()" [disabled]="isLoading" [style]="isLoading ? 'background: #1355C1;color: white;opacity: 0.5;' : ''">THÊM</button>
</mat-dialog-actions>
<div class="spinner-global" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
