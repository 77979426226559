<div class="spinner-global" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<h2 mat-dialog-title
    class="title-page-global text-center">{{action == 'add' ? 'Thêm môn học' : 'Chỉnh sửa môn học - ' + data.infoSubject.fullname}}</h2>
<form [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm.value)" style="width: 100%" action="">
    <mat-dialog-content class="div-grade-checkbox">
        <div class="row">
            <div class="col-lg-6 form-group row row-global">
                <label class="col-sm-3 lb-global ">Tên môn học <span class="lb-error">(*)</span></label>
                <div class="col-sm-8 pl-0 pr-0 row">
                    <input formControlName="fullname" type="text" class="form-control input-field-global" placeholder="Nhập tên môn học">
                    <div *ngIf="(this.infoForm.get('fullname').invalid && (this.infoForm.get('fullname').dirty || this.infoForm.get('fullname').touched))">
                        <mat-error>
                            <p class="error-message" *ngIf="this.infoForm.get('fullname').errors.required || this.infoForm.get('fullname').errors.trimError">
                                Tên môn học không được bỏ trống
                            </p>
                        </mat-error>
                        <mat-error>
                            <p class="error-message" *ngIf="this.infoForm.get('fullname').errors.pattern">
                                Tên môn học độ dài 2-50 ký tự, a-z, A-Z, 0-9
                            </p>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 form-group row row-global" [style]="action !== 'add' ? 'opacity: 0.6' : ''">
                <label class="offset-1 col-sm-3 lb-global">Mã môn học <span class="lb-error">(*)</span></label>
                <div class="col-sm-8 pl-0 pr-0">
                    <input [readOnly]="action !== 'add' ? true: false" formControlName="code" type="text" class="form-control input-field-global" placeholder="Nhập mã môn học">
                    <div *ngIf="(this.infoForm.get('code').invalid && (this.infoForm.get('code').dirty || this.infoForm.get('code').touched))">
                        <mat-error>
                            <p class="error-message" *ngIf="this.infoForm.get('code').errors.required || this.infoForm.get('code').errors.trimError">
                                Mã môn học không được bỏ trống
                            </p>
                        </mat-error>
                        <mat-error>
                            <p class="error-message" *ngIf="this.infoForm.get('code').errors.pattern">
                                Mã môn học độ dài 2-50 ký tự, a-z, A-Z, 0-9
                            </p>
                        </mat-error>
                    </div>
                    <span class="text-danger pt-2" *ngIf="checkCode !== ''">{{checkCode}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 form-group row row-global">
                <label class="col-sm-3 lb-global ">Cấp</label>
                <div class="col-sm-8 pl-0 pr-0 row" fxLayout="column" fxLayoutAlign="start none">
                    <select class="form-control  input-field-global" formControlName="trainingLevel"
                            (change)="chooseTrainingLever()">
                        <ng-container *ngFor="let grade of selectGrade">
                            <option [value]="grade.id">{{grade.fullName}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 form-group row row-global">
                <label class="offset-1 col-sm-3 lb-global">Nhóm môn học</label>
                <div class="col-sm-8 pl-0 pr-0">
                    <input  disabled readOnly formControlName="isMoetSubject" type="text" class="form-control input-field-global"
                           placeholder="Nhập mã môn học">
                </div>
            </div>
            <div class="col-lg-6 form-group row row-global">
                <label class="col-sm-3 lb-global"></label>
                <div class="col-sm-8 pl-0 pr-0 row " id="check-box-grade-ids">
                    <ng-container *ngFor="let item of dataGrade, let i = index">
                        <div class="pl-0 pr-0 col-lg-4 mt-1">
                            <mat-checkbox (click)="chooseChecked(item.id)" class="mr-2 mt-1 {{gradeIds.indexOf(item.id) === -1 ? '': 'mat-checkbox-checked'}}" value="{{item.id}}"></mat-checkbox>
                            <span class="title-grade-choose-checked">{{item.fullname}}</span>
                        </div>
                    </ng-container>
                    <p class="text-danger" *ngIf="errGrade !== ''">{{errGrade}}</p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 form-group row row-global">
                <label class="col-sm-3 lb-global ">Thứ tự sắp xếp</label>
                <div class="col-sm-8 pl-0 pr-0 row">
                    <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" formControlName="indexOrder" class="form-control input-field-global" placeholder="Nhập thứ tự môn học"
                           
                           >
                    <ng-container
                            *ngIf="infoForm.get('indexOrder').invalid && (infoForm.get('indexOrder').dirty || infoForm.get('indexOrder').touched)">
                        <p class="text-danger" *ngIf="infoForm.get('indexOrder').hasError('pattern')">Bạn phải nhập ký
                            tự số</p>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6 form-group row row-global">
                <label class="offset-1 col-sm-3 lb-global">Trạng thái</label>
                <div class="col-sm-8 pl-0 pr-0" fxLayout="row" fxLayoutAlign=" center" >
                    <mat-checkbox formControlName="status"></mat-checkbox> <span> Kích hoạt</span>
                </div>
            </div>
        </div>
        <div fxLayout="row">
            <label class="lb-global" fxFlex="12%">Dữ liệu</label>
            <div fxFlex class="form-group pr-0 row">
                <textarea class="form-control input-field-global col-sm-12" formControlName="description"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close class="btn btn-cancel mat-elevation-z">HỦY</button>
        <button mat-button type="submit"
                class="btn btn-add-edit ml-2 px-4" [disabled]="isLoading" [style]="isLoading ? 'background: #1355C1;color: white;opacity: 0.5;' : ''"> {{action == 'add' ? 'THÊM' : 'LƯU'}}</button>
    </mat-dialog-actions>
</form>
