import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamListComponent } from "./exam-list/exam-list.component";
import { ExamDetailComponent } from "./exam-detail/exam-detail.component";

const routes: Routes = [
  {
    path: '',
    component: ExamListComponent,
  },
  {
    path: ':id',
    component: ExamDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamsRoutingModule { }
