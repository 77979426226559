<p class="text-center custom-title">Import đơn vị nghành dọc</p>
<div id="custom-form-import" fxLayout="row" fxLayoutAlign="center start">
    <form style="width: 80%" [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm.value)">
        <div class="custom-window">
            <label>Chọn file</label>
            <ng-container *ngIf="fileName == ''">
                <div fxLayout="row" fxLayoutAlign="center center" id="choose-file" class="form-control custom-background" (click)="uploadFile()">
                    <img src="assets/images/svg/icon-upload.svg" alt=""> &nbsp; &nbsp;<span class="label-upload">File tải lên</span>
                </div>
                <input type="file" hidden style="visibility: hidden" id="inputUploadFile" (change)="onFileChange($event)" accept=".xlsx,.xls">
            </ng-container>

            <ng-container *ngIf="fileName != ''">
                <div fxLayout="row" fxLayoutAlign="center center" class="form-control custom-background" id="custom-name-file">
                    <div class="col-md-7">
                        {{fileName}}
                    </div>
                    <div class="col-md-5 text-right">
                        <span (click)="removeFile()">
                            <img src="assets/images/svg/remove.svg" alt=""> &nbsp;Hủy
                        </span>
                    </div>
                </div>
                <div class="loading-progress" style="position: relative; bottom: 4px;"></div>
            </ng-container>
        </div>

        <label class="custom-window title-sample-file">
            Tải xuống file mẫu
        </label>
        <br>

        <div class="custom-window">
            <label>
                Hành động 
            </label>
            <select formControlName="action" class="form-control custom-background select-error" (change)="checkError(choose_error.value)" #choose_error>
                <ng-container *ngFor="let item of listError, let i = index">
                    <option [value]="i" [selected]="keyError == i ? true: false">{{item.name_error}}</option>
                </ng-container>
            </select>
            <p class="text-danger">
                {{listError[keyError].message}}
            </p>
            <br>
        </div>
        <p class="text-center">
            <button mat-dialog-close class="btn btn-close">Hủy</button>
            <button type="submit" class="btn btn-add-edit ml-2 px-4" style="color: #FFFFFF" [disabled]="isFileExcel == false? true : false"> Thêm</button>
        </p>
    </form>

</div>