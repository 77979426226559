import { Component, OnInit } from '@angular/core';
import {lang} from '../../school-years.lang';
import * as moment from 'moment';

@Component({
  selector: 'app-tab-school-year-info',
  templateUrl: './tab-school-year-info.component.html',
  styleUrls: ['./tab-school-year-info.component.scss']
})
export class TabSchoolYearInfoComponent implements OnInit {
  lang = lang;
  infoSchoolYear =  {id: 1, fullname: 'Năm học Sakura',code: 'OMT678', start_date: 1602219262, end_date: 1605168175, status: 1, is_active: 2};
  constructor() { }

  ngOnInit(): void {
  }

  parseTime(param, format = null){
    if (format == null){
      format = 'DD/MM/YYYY';
    }

    return moment(param*1000).format(format);
  }

}
