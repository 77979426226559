<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between start">
        <div>
            <h4 class="title-page-global">Bài viết cá nhân</h4>
        </div>
        <div>
            <input #search type="text" id="search-value" class="text-left btn btn-default button-search
                    {{showSearch == true ? 'transition-search-show': ''}}"
                   placeholder="{{showSearch == true ? 'Tìm kiếm' : ''}}" (keydown)="checkText()">
            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ check ? 'active_filter' : '' }}"
                 (click)="toggleClass(search.value, check)">
            <button class="btn btn-default btn-filter">
                <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}"
                     (click)="showFilterClick()">
            </button>
        </div>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="end start">
        <div fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter" fxFlex class="date-time-range">
            <div fxFlex="70%" fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="filter == 3">
                <span style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;color: #000000;margin-right: 12px;">Từ </span>&nbsp;
                <input (dateChange)="changeDateTime('startDate', $event)" readonly type="text" class="mr-2 btn-date-time" [matDatepicker]="pickerFrom" value="{{date}}">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
                <span style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;color: #000000;margin-right: 12px;">Đến </span>&nbsp;
                <input (dateChange)="changeDateTime('endDate', $event)" readonly type="text" class="mr-2 btn-date-time input-field-global" [matDatepicker]="pickerTo"
                       value="{{date}}">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </div>
            <select #dataFilter class="btn btn-default mr-2 btn-filter-option" (change)="changeFilter($event, dataFilter.value)">
                <option value="0">Hôm nay</option>
                <option value="1">Tuần này</option>
                <option value="2">Tháng này</option>
                <option value="3">Khác</option>
            </select>
            <button class="btn btn-default btn-filter-option-action" (click)="filterData()">Lọc</button>
        </div>
    </div>
    <div fxFlex class="tab-group" fxLayout="column" style="overflow-y: auto">
        <p *ngIf="dataSource.length ===0 ">Bạn chưa có bài viết nào cả!!</p>
        <ng-container *ngFor="let item of caseDate">
            <div *ngIf="item.isChecked === true" fxLayout="column">
                <div fxFlex="30px" fxLayout="row" fxLayoutAlign="space-between none">
                    <p class="dash-board-today ml-2">{{item.fullName}}</p>
                    <span class="mr-2" *ngIf="item.isRead === true">Đánh dấu tất cả là đã đọc</span>
                </div>
                <ng-container  *ngFor="let card of dataSource">
                    <div *ngIf="item.id === card.statusDate">
                        <mat-card class="dash-board-item-noti-user mt-1 mat-elevation-z">
                            <mat-card-header>
                                <img mat-card-avatar src="https://cdn.tgdd.vn/Files/2019/01/01/1142002/s8ori_800x600.jpg"
                                     class="mt-3 ml-2">
                                <mat-card-title class="ml-1 mt-2"><a class="item-noti-user-title"
                                                                     [routerLink]="['detail', card.postId]" [queryParams]="{fullName: card.fullName}">{{card.postTitle}}</a>
                                </mat-card-title>
                                <mat-card-subtitle class="ml-1 mt-1">
                                    <span class="item-noti-user-content">{{card.content}}</span>
                                    <span class="item-noti-user-profile mt-4" fxLayout="row"
                                          fxLayoutAlign="space-between none">
                                <span>
                                    <span><img src="assets/images/svg/comment.svg" alt=""> {{card.totalComment}} comment</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Từ: {{card.fullName}}
                                </span>
                                <span>Đã nhận: {{parseMinute(card.receivedAt)}}</span>
                            </span>
                                </mat-card-subtitle>
                            </mat-card-header>
                        </mat-card>
                    </div>
                </ng-container>
            </div>
        </ng-container>

    </div>
</div>
<!--<router-outlet></router-outlet>-->


