import { log } from 'util';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';
import { NumberInput } from '@angular/cdk/coercion';

@Injectable({
    providedIn: 'root'
})

export class CurriculumManagerService {
    constructor(private http: HttpClient) {

    }
    // danh sach khung chuong trinh
    getListCurriculums(data) {
        let objData ={...data};
        objData.pageIndex++;
        return this.http.post(`${environment.coreApiUrl}/api/khung-chuong-trinh`, objData);
    }
    
    // getSubjects(data) {
    //     return this.http.post(`${environment.examApiUrl}/api/subjects/index`, data);
    // }

    getSubjectByLevelTraining(data:any) {
        return this.http.post(`${environment.coreApiUrl}/api/subjects/simple-moet-list`, data);
    }

    getGradesBySubject(id, data) {
        return this.http.post(`${environment.coreApiUrl}/api/subjects/simple-moet-grades/${id}`, data);
    }

    //chi tiet khung chuong trinh
    getDetailCurriculum(id) {
        return this.http.get(`${environment.coreApiUrl}/api/khung-chuong-trinh/${id}`);
    }

    // tao khung chuong trinh
    createCurriculum(data) {
        return this.http.post(`${environment.coreApiUrl}/api/khung-chuong-trinh/create`, data);
    }
    
    //xoa khung chuong trinh
    deleteCurriculum(data) {
        return this.http.post(`${environment.coreApiUrl}/api/khung-chuong-trinh/delete`, data);
    }
    
    // sua khung chuong trinh
    editCurriculum(data) {
        return this.http.post(`${environment.coreApiUrl}/api/khung-chuong-trinh/update`, data);
    }

    // tao yeu cau/ noi dung hoc cua khung chuong trinh
    createContentCurriculum(data) {
        return this.http.post(`${environment.coreApiUrl}/api/noi-dung-giao-duc/create`, data);
    }
    
    // sua yeu cau/ noi dung cua khung chuong trinh
    editContentCurriculum(data) {
        return this.http.post(`${environment.coreApiUrl}/api/noi-dung-giao-duc/update`, data);
    }
    
    // xoa yeu cau/ noi dung bai hoc cua khung chuong trinh
    deleteContentCurriculm(data) {
        return this.http.post(`${environment.coreApiUrl}/api/noi-dung-giao-duc/delete`, data);
    }

    //lay danh sach yeu cau/ noi dung hoc
    getListContentCurriculum(data) {
        return this.http.get(`${environment.coreApiUrl}/api/noi-dung-giao-duc?KhungChuongTrinhId=${data.khungChuongTrinhId}&Type=${data.type}`);
    }

    //lấy danh sách các tiêu chí đã được chọn suggess theo nội dung học
    getListCriteriaByContent(contentId:string, pageSize: number = 9999, pageIndex: number = 1) {
        return this.http.get(`${environment.coreApiUrl}/api/noi-dung-giao-duc/danh-sach-tieu-chi-theo-noi-dung?NoiDungHocId=${contentId}&PageSize=${pageSize}&PageIndex=${pageIndex}`);
    }

    // lấy danh sách tất cả năm học
    getListShoolYear() {
        return this.http.get(`${environment.coreApiUrl}/api/khung-chuong-trinh/school-years`);
    }

    // lấy danh sách năm học trừ năm đã được gán cho khung chương trình
    getListShoolYearNotAssign(id) {
        return this.http.get(`${environment.coreApiUrl}/api/khung-chuong-trinh/school-years-to-assign/${id}`);
    }

    // lấy danh sách năm học đã được gán cho khung chương trình
    getListShoolYearAssigned(id) {
        return this.http.get(`${environment.coreApiUrl}/api/khung-chuong-trinh/assigned-school-years/${id}`);
    }

    // gán năm học cho khung chương trình
    assginShoolYear(data) {
        return this.http.post(`${environment.coreApiUrl}/api/khung-chuong-trinh/assign-school-year`, data);
    }

    // gỡ năm học đã được gán cho khung chương trình
    removeSchoolYear(data){
        return this.http.post(`${environment.coreApiUrl}/api/khung-chuong-trinh/remove-school-year`, data);
    }
}
