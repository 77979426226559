import { Component, OnInit, Input} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-tab-info-user',
  templateUrl: './tab-info-user.component.html',
  styleUrls: ['./tab-info-user.component.scss']
})
export class TabInfoUserComponent implements OnInit {
  panelOpenState = true;

  @Input() infoUser;
  constructor() { }

  ngOnInit(): void {
  }

  parseTime(date){
    return moment(date * 1000).format('DD/MM/YYYY');
  }
}
