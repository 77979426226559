export const DATA_FAKE = [
    {id: '1', name: 'Toán 1', code:'OMT070', time: '20/03/2010', unit: 'Nhà xuất bản giáo dục', isActive: 1},
    {id: '2', name: 'Toán 1', code:'OMT070', time: '20/03/2010', unit: 'Nhà xuất bản giáo dục', isActive: 1},
    {id: '3', name: 'Toán 1', code:'OMT070', time: '20/03/2010', unit: 'Nhà xuất bản giáo dục', isActive: 1},
    {id: '4    ', name: 'Toán 1', code:'OMT070', time: '20/03/2010', unit: 'Nhà xuất bản giáo dục', isActive: 1},
]
export const DATA_FAKE_GRADE = [
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
    {id: '1', name: 'Khối 1'},
]

export const DATA_TREE_FAKE = [
    {   
        id: '1',
        content: 'Kĩ thuật ',
        childs: [
            {
                id: '1-1',
                content: 'Kĩ thuật đọc',
                childs: [
                    {
                        id: '1-1-1',
                        content: `–Ngồi (hoặc đứng) thẳng lưng; sách, vởmởrộng trên mặt bàn (hoặc trên hai tay). Giữkhoảng cách giữa mắt với sách, vởkhoảng 25cm.
                        –Đọc đúng âm, vần, tiếng, từ, câu (có thểđọc chưa thật đúng một sốtiếng có vần khó, ít dùng).
                        –Đọc đúng và rõ ràng đoạn văn hoặc văn bản ngắn. Tốc độđọc khoảng 40 –60 tiếng trong 1 phút. Biết ngắt hơi ởchỗcó dấu phẩy, dấu kết thúc câu hay ởchỗkết
                        thúc dòng thơ.
                        –Bước đầu biết đọc thầm.
                        –Nhận biết được bìa sách và tên sách.`
                    },
                    {
                        id: '1-1-1-2',
                        content: `–Hỏi và trả lời được những câu hỏi đơn giản liên quan đến các chi tiếtđược thểhiện tường minh.
                        –Trảlời được các câu hỏi đơn giản vềnội dung cơ bản của văn bản dựa vào gợi ý,`
                    },
                    {content: 'Bài tập C1-1.3'},
                ]
            },
            {
                id: '1-2',
                content: 'Chuong 1 - Bài 2: Đạo hàm, giải tích',
                childs: [
                    {
                        id: '1-2-1',
                        content: `–Hỏi và trả lời được những câu hỏi đơn giản liên quan đến các chi tiếtđược thểhiện tường minh.
                        –Trảlời được các câu hỏi đơn giản vềnội dung cơ bản của văn bản dựa vào gợi ý,`,
                        childs: [
                            {
                                id: '1-2-1-1',
                                content: 'C1-2.1.1. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',    
                            },
                            {content: 'C1-2.1.2. Bài tập 1'},
                        ]
                    }, {
                        content: 'C1-2.2.Khái niệm ứng dụng',
                        childs: [
                            {
                                content: 'C1-2.2.1. Ví dụ về ứng dụng của đạo hàm',
                            },
                            {content: 'C1-2.2.2. Bài tập 1'},
                        ]
                    },
                ],
            },
        ]
    },
    {
        id: '1-3',
        content: 'Chương 2: Ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
        childs: [
            {
                content: 'Chuong 2- Bài 1: Đạo hàm, giải tích',
                childs: [
                    {
                        content: 'C2-1.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                    },
                    {
                        content: 'C2-1.2. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                    },
                    {content: 'C2-1.3. Bài tập 1',},
                ]
            },
            {
                content: 'Chuong 2 - Bài 2: Đạo hàm, giải tích',
                
                childs: [
                    {
                        content: 'C2-2.1 Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                       
                        childs: [
                            {
                                content: 'C2-2.1.1. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                               
                            },
                            {content: 'C2-2.1.2. Bài tập 1', },
                        ]
                    }, {
                        content: 'C2-2.2. Khái niệm ứng dụng',
                        
                        childs: [
                            {
                                content: 'C2-2.1.1. Ví dụ về ứng dụng của đạo hàm',
                               
                            },
                            {content: 'C2-2.1.2. Bài tập 1',},
                        ]
                    },
                ],
            },
        ]
    },
    {
        content: 'Kĩ thuật đọc'
    },
    {
        id: '4',
        content: 'Chương 3: Ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
      
        childs: [
            {
                content: 'Chuong 3- Bài 1: Đạo hàm, giải tích',
               
                childs: [
                    {
                        content: 'C3- 1.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                    },
                    {
                        content: 'C3- 1.2. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                    },
                    {content: 'C3- 1.3. Bài tập 1',},
                ]
            },
            {
                content: 'Chuogn 3- Bài 2: Đạo hàm, giải tích',
               
                childs: [
                    {
                        content: 'C3- 2.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                        childs: [
                            {
                                content: 'C3- 2.1.1. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                               
                            },
                            {content: 'C3- 2.1.2. Bài tập 1', },
                        ]
                    }, {
                        content: 'C3- 2.2. Khái niệm ứng dụng',
                        
                        childs: [
                            {
                                content: 'C3- 2.2.1. Ví dụ về ứng dụng của đạo hàm',
                            
                            },
                            {content: 'C3- 2.2.2. Bài tập 1', },
                        ]
                    },
                ],
            },
        ]
    },
    {
        id: '5',
        content: 'Chương 4: Ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
        
        childs: [
            {
                content: 'Chuogn 4- Bài 1: Đạo hàm, giải tích',
                
                childs: [
                    {
                        content: 'C4- 1.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                    },
                    {
                        content: 'C4- 1.2. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                      
                    },
                    {content: 'C4- 1.3. Bài tập 1', },
                ]
            },
            
        ]
    },

];

export const DATA_TREE_FAKE2 = [
    {
        name: 'Kĩ thuật viết',
        subTab: [
            {
                name: 'Kĩ thuật luyện viết',
                subTab: [
                    {
                        name: `–Ngồi (hoặc đứng) thẳng lưng; sách, vởmởrộng trên mặt bàn (hoặc trên hai tay). Giữkhoảng cách giữa mắt với sách, vởkhoảng 25cm.
                        –Đọc đúng âm, vần, tiếng, từ, câu (có thểđọc chưa thật đúng một sốtiếng có vần khó, ít dùng).
                        –Đọc đúng và rõ ràng đoạn văn hoặc văn bản ngắn. Tốc độđọc khoảng 40 –60 tiếng trong 1 phút. Biết ngắt hơi ởchỗcó dấu phẩy, dấu kết thúc câu hay ởchỗkết
                        thúc dòng thơ.
                        –Bước đầu biết đọc thầm.
                        –Nhận biết được bìa sách và tên sách.`
                    },
                    {
                        name: `–Hỏi và trả lời được những câu hỏi đơn giản liên quan đến các chi tiếtđược thểhiện tường minh.
                        –Trảlời được các câu hỏi đơn giản vềnội dung cơ bản của văn bản dựa vào gợi ý,`
                    },
                    {name: 'Bài tập C1-1.3'},
                ]
            },
            {
                name: 'Kĩ thuật tập tô',
                subTab: [
                    {
                        name: `–Hỏi và trả lời được những câu hỏi đơn giản liên quan đến các chi tiếtđược thểhiện tường minh.
                        –Trảlời được các câu hỏi đơn giản vềnội dung cơ bản của văn bản dựa vào gợi ý,`,
                        subTab: [
                            {
                                name: 'C1-2.1.1. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',    
                            },
                            {name: 'C1-2.1.2. Bài tập 1'},
                        ]
                    },
                ],
            },
        ]
    },
    {
        name: 'Kĩ thuật phát âm',
        subTab: [
            {
                name: 'Phát âm l/n',
                subTab: [
                    {
                        name: 'C2-1.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                    },
                    {
                        name: 'C2-1.2. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                    },
                    
                ]
            },
            {
                name: 'Phát âm tr/ch',
                
                subTab: [
                    {
                        name: 'C2-2.1 Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        subTab: [
                            {
                                name: 'C2-2.1.1. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                               
                            },
                            {name: 'C2-2.1.2. Bài tập 1', },
                        ]
                    }, {
                        name: 'C2-2.2. Khái niệm ứng dụng',  
                        subTab: [
                            {
                                name: 'C2-2.1.1. Ví dụ về ứng dụng của đạo hàm',
                               
                            },
                            {name: 'C2-2.1.2. Bài tập 1',},
                        ]
                    },
                ],
            },
        ]
    },
    {
        name: 'Kĩ thuật đọc abc'
    },
    {
        name: 'Chương 3: Ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
      
        subTab: [
            {
                name: 'Chuong 3- Bài 1: Đạo hàm, giải tích',
               
                subTab: [
                    {
                        name: 'C3- 1.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                    },
                    {
                        name: 'C3- 1.2. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                    },
                    {name: 'C3- 1.3. Bài tập 1',},
                ]
            },
        ]
    },
    {
        name: 'Chương 4: Ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
        
        subTab: [
            {
                name: 'Chuogn 4- Bài 1: Đạo hàm, giải tích',
                
                subTab: [
                    {
                        name: 'C4- 1.1. Khái niệm ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                        
                    },
                    {
                        name: 'C4- 1.2. Ví dụ về ứng dụng của đạo hàm để khảo sát và vẽ đồ thị hàm số',
                      
                    },
                    {name: 'C4- 1.3. Bài tập 1', },
                ]
            },
            
        ]
    },

];