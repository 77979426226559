import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SchoolYearsListComponent} from './components/school-years-list/school-years-list.component';
import {SchoolYearDetailsComponent} from './components/school-year-details/school-year-details.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import { SCHOOL_YEAR_PERMISSION } from '@app/_modules/school-years-manager/constant';

const routes: Routes = [
    {
        path: '',
        component: SchoolYearsListComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [SCHOOL_YEAR_PERMISSION.perm1],
                redirectTo: '/accessdenied'
            }
        }
    },
    {
        path: 'detail-school-year/:id',
        component: SchoolYearDetailsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [SCHOOL_YEAR_PERMISSION.perm3],
                redirectTo: '/accessdenied'
            }
        }
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class SchoolYearsManagerRoutingModule {

}
