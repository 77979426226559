import { Component, OnInit } from '@angular/core';
import {lang} from '../../../dialogs/dialog-form/lang-form-subject';

@Component({
  selector: 'app-info-grade',
  templateUrl: './info-grade.component.html',
  styleUrls: ['./info-grade.component.scss']
})
export class InfoGradeComponent implements OnInit {
  lang = lang;
  constructor() { }

  ngOnInit(): void {
  }

}
