import { Component, OnInit } from '@angular/core';

const DATANOTI_TEST = [
  { id:1, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/1/60/60'},
  { id:2, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/10/60/60'},
  { id:3, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/100/60/60'},
  { id:4, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/1005/60/60'},
  //{ id:5, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/1011/60/60'},
  //{ id:6, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/102/60/60'},
  //{ id:7, title:'Thông báo: Điều chỉnh năm học',content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit cursus id tellus habitant et senectus. Ultrices sit ut lectu.', author:'Nguyễn Xuân Ánh (Admin)', date:'06/11/2020', src_img: 'https://picsum.photos/id/1023/60/60'},
]
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  data_noti_test: any = DATANOTI_TEST;
  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = document.body.scrollTop = 1000;
  }

}
