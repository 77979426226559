import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Subject} from '../_models/index';

@Injectable({
    providedIn: 'root'
})

export class SubjectService {
    constructor(
        private http: HttpClient,
    ) {}

    getAllListSubject(size, index, type: number, search: string, filter){
        index = index + 1;
        return this.http.post(`${environment.coreApiUrl}/api/subjects/index`, {
            pageSize: size,
            pageIndex: index,
            keyword: search,
            subject_type: type,
            training_level: filter
        });
    }

    storeSubject(data: any){
        return this.http.post(`${environment.coreApiUrl}/api/subjects`, data);
    }

    updateSubject(subjectId: string, data: any){
        return this.http.put(`${environment.coreApiUrl}/api/subjects/${subjectId}`, data);
    }

    removeSubject(subjectId: string){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/delete`,
            {
                id: subjectId
            });
    }

    changeStatusSubject(subjectId: string, subjectStatus: number){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/change-status`, {
            id: subjectId,
            status: subjectStatus
        });
    }

    getListByTrainingLevel(trainingLevel: number){
        return this.http.get(`${environment.coreApiUrl}/api/grade/list-by-training-level/${trainingLevel}`);
    }

    getListCustomerBySubject(id: string, value: string, statusUser: any){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/${id}/tenants`, {
            keyword: value,
            status: statusUser
         });
    }


    getSubjectById(id: string){
        return this.http.get(`${environment.coreApiUrl}/api/subjects/${id}`);
    }
    getListCustomerNotSubject(id: string, keyword = ''){
        const linkKeyWord = keyword === '' ? '' : `?keyword=${keyword}`;
        return this.http.get(`${environment.coreApiUrl}/api/subjects/${id}/tenants-not-belong-subject${linkKeyWord}`);
    }

    removeCustomer(itemId: string, tenant: string){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/delete-tenant`, {
            subjectId: itemId,
            tenantId: tenant,
        });
    }
    addCustomerBySubject(itemId: string, dataCustomer: any){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/add-tenants`, {
            subjectId: itemId,
            tenantIds: dataCustomer
        });
    }

    getListGradeBySubject(id: string, keyword){
        return this.http.get(`${environment.coreApiUrl}/api/subjects/${id}/grades?keyword=${keyword}`);
    }

    removeGrade(itemId: string, grade: string){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/delete-grade`, {
            subjectId: itemId,
            gradeId: grade,
        });
    }

    getListGradeNotSubject(id: string, keyword = ''){
        const linkKeyWord = keyword === '' ? '' : `?keyword=${keyword}`;
        return this.http.get(`${environment.coreApiUrl}/api/subjects/${id}/grades-not-belong-subject${linkKeyWord}`);
    }

    addGradeBySubject(itemId: string, dataGrade: any){
        return this.http.post(`${environment.coreApiUrl}/api/subjects/add-grades`, {
            subjectId: itemId,
            gradeIds: dataGrade
        });
    }

}
