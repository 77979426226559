import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-type-of-subject',
  templateUrl: './type-of-subject.component.html',
  styleUrls: ['./type-of-subject.component.scss']
})
export class TypeOfSubjectComponent implements OnInit {
  dataType = [];
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    
    
  }

  chooseSubject(type) {
    let linkRoute = this.dataType.find(i => i.id == type).link;
    // return this.router.navigate([`${linkRoute}`], { queryParams: { setOfExamId: this.data, nameForm: 'add'}});
    return this.router.navigate([`${linkRoute}/${this.data}`]);
  }
}
