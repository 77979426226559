import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleManagerRoutingModule } from './module-manager-routing.module';
import { ModuleListComponent } from './components/module-list/module-list.component';
import { SharedModule } from '../../_shared/shared.module';
import { ModuleDetailComponent } from './components/module-detail/module-detail.component';
import { TabPermissionComponent } from './components/tabs/tab-permission/tab-permission.component';
import { TabListCustomerOfDetailModuleComponent } from './components/tabs/tab-list-customer-of-detail-module/tab-list-customer-of-detail-module.component';
import { DialogFormModuleComponent } from './dialogs/dialog-form-module/dialog-form-module.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogAddCustomerComponent } from './dialogs/dialog-add-customer/dialog-add-customer.component';
import { DialogListCustomerComponent } from './dialogs/dialog-list-customers/dialog-list-customer.component';
import { TabCoreAdminComponent } from './components/tabs/tab-core-admin/tab-core-admin.component';
import { TabCustomersComponent } from './components/tabs/tab-customers/tab-customers.component';
import {DialogListPermissionComponent} from './dialogs/dialog-list-permission/dialog-list-permission.component';
import {DialogChangeStatusComponent} from './dialogs/dialog-change-status/dialog-change-status.component';
import { DialogFormPermissionComponent } from './dialogs/dialog-form-permission/dialog-form-permission.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
      ModuleListComponent,
      ModuleDetailComponent,
      TabPermissionComponent,
      TabListCustomerOfDetailModuleComponent,
      DialogFormModuleComponent,
      DialogAddCustomerComponent,
      DialogListCustomerComponent,
      TabCoreAdminComponent,
      TabCustomersComponent,
      DialogListPermissionComponent,
      DialogChangeStatusComponent,
      DialogFormPermissionComponent,
  ],
    imports: [
        CommonModule,
        ModuleManagerRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forChild()
    ],
    providers: [
    ]
})
export class ModuleManagerModule { }
