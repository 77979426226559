export const GENERAL_CONFIGURATIONS_PERMISSION = {
    perm1: "core_admin_config_system",
    perm2: "core_admin_config_system_security",
    perm3: "core_admin_config_system_email_server",
    perm4: "core_admin_config_system_introduction",
    perm5: "core_admin_config_system_privacy_policy",
    perm6: "core_admin_config_system_term_of_service",
    perm7: "core_admin_config_elsa_tenant",
    perm8: "core_admin_config_send_sms",
    perm10: "core_admin_config_system_alert"
}

export const ARR_TYPE_CONFIG_NOTI_SYSTEM = [
  { type: 'SUCCESS', value: 1, color: '#0f5132', borderColor: '#badbcc', background: '#d1e7dd' },
  { type: 'WARNING', value: 2, color: '#664d03', borderColor: '#ffecb5', background: '#fff3cd' },
  { type: 'DANGER', value: 3, color: '#842029', borderColor: '#f5c2c7', background: '#f8d7da' }
]






