import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ForgotPasswordService} from '@app/_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {ShowMessage} from '@app/_services';
import * as moment from 'moment';
import * as $ from 'jquery';
@Component({
    selector: 'app-sent-verify-account',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    public message: string;
    overTime;
    isOverTime = false;
    timeOut;
    countDownTimer;
    resendAfterTime;
    isSpace = false;
    public verifyAccount;

    constructor(
        private fb: FormBuilder,
        private route: Router,
        private forgotPasswordService: ForgotPasswordService,
        private showMessage: ShowMessage,
        private activeRoute: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.verifyAccount = this.fb.group({
            username: ['', [Validators.required]]
        });
        $("#leader-line-defs").remove();
    }

    checkValidateAccount(username) {
        this.message = '';
        this.isOverTime = false;
        this.isSpace = false;
        if(username.trim() === '') {
            return this.isSpace = true;
        }
    }

    sentCode(data) {
        this.message = ''
        this.isOverTime = false;
        let username = data.username.trim()
        if(username === '') {
           return this.message = 'Bạn không được để trống tài khoản';
        }
        this.forgotPasswordService.resetPassword(username).subscribe((res: any) => {
            if (res.status === 1) return this.route.navigate(['verify-account'], { queryParams: {username}});
            if (res.data !== null) this.getTime(res.data.resendAfterTime, res.data.timeNow);
            this.message = res.msg;
        }, error => {
            // if (error.status)
        });
    }

    /**Neu nguoi dung bam da co ma**/
    checkResetCode(username){
        this.forgotPasswordService.checkResetCode(username).subscribe((res: any) => {
            if (res.status === 0) return this.showMessage.error(res.msg);
            return this.route.navigate(['verify-account'], { queryParams: {id: res.data.userId, username}});
        })
    }

    back() {
        return this.route.navigate(['login']);
    }

    parseSeconds(param){
        return parseInt(moment(param*1000).utc().format('HH'))*86400 + parseInt(moment(param*1000).utc().format('mm'))*60 + parseInt(moment(param*1000).utc().format('ss'));
    }

    getTime(resendAfterTime, timeNow){
        this.isOverTime = false;
        let secondNow = this.parseSeconds(timeNow), secondServer = this.parseSeconds(resendAfterTime);
        let timeRemaining = secondServer - secondNow;
        clearInterval(this.countDownTimer);
        if (timeRemaining > 0){
            this.isOverTime = true;
            if (this.isOverTime === true) this.timeUp(timeRemaining);
            setTimeout(() => {
                this.isOverTime = false;
                this.overTime = '';
            },timeRemaining*1000);
        }
    }

    timeUp(duration: number) {
        let timer = duration;
        let minutes;
        let seconds;
        this.countDownTimer = setInterval(() => {
            minutes = parseInt(String(timer / 60), 10)
            seconds = parseInt(String(timer % 60), 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            this.timeOut = minutes + ":" + seconds;

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    }
}
