<div fxLayout="column" class="bg-content-global" *ngIf="dataDetailLocation">
    <div fxLayout="column" fxFlex class="content-declare">
        <div fxFlex="3rem" fxLayout="row" fxLayoutAlign="start start">
            <div>
                <h4 class="title-page-global">{{ dataDetailLocation.fullname }}</h4>
            </div>
        </div>
        <div fxFlex fxLayout="column" class="content__education-lv1" id="content__education-lv1">
            <div class="info__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <h4 class="title__education-lv1">Thông tin cơ bản</h4>
                    <div class="btn-action-detail">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" class="menu-more-global">
                            <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                <button mat-menu-item class="px-5" (click)="clickEditEducationLv1(dataDetailLocation);">Chỉnh sửa</button>
                            </ng-template>
                            <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(dataDetailLocation);">Xóa</button>
                            </ng-template>
                        </mat-menu>
                    </div>
                </div>
                <div class="form__info-education-lv1 row">
                    <div fxLayout="row" fxLayoutAlign="center center" class="col-md-2">
                        <div class="img__info-education-lv1">
                            <img src="https://scontent.fhan2-5.fna.fbcdn.net/v/t1.0-9/126490683_1856067191217227_582269962890364399_n.jpg?_nc_cat=109&ccb=2&_nc_sid=8bfeb9&_nc_ohc=ZOwMdTsVYugAX_d-q0M&_nc_oc=AQkiI2TbBfx5lNPwYoeZgtKP8bwTjTpypqsSMi8eeXPy4lQCO8fbY0buFWbbhBix6e0&_nc_ht=scontent.fhan2-5.fna&oh=1a492bf2a04d1d4910f09a6db80a5aef&oe=5FE39391"
                                alt="">
                        </div>
                    </div>
                    <div class="col-md-4 row custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Tên Tỉnh/Thành phố</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [disabled]="true" [value]="dataDetailLocation.fullname" class="form-control input-field-global" placeholder="Nhập tên tỉnh/thành phố">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Thứ tự sắp xếp</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" [disabled]="true" [value]="dataDetailLocation.indexOrder" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                            </div>
                        </div>
                    </div>
                    <div class="row col-md-4 offset-1 custom-space-right">
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Mã</label>
                            <div class="col-md-9 pl-0 pr-0">
                                <input type="text" [disabled]="true" [value]="dataDetailLocation.code" class="form-control input-field-global" placeholder="Nhập mã">
                            </div>
                        </div>
                        <div class="form-group col-md-12 row row-global">
                            <label class="col-md-3">Trạng thái</label>
                            <div class="col-md-9 pl-0 pr-0 text-left">
                                <mat-checkbox [disabled]="true" [checked]="dataDetailLocation.status == 1 ? true : false" style="display: inline-block;">Kích hoạt</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                <button class="title__education-lv1-custom" mat-raised-button>Đơn vị quản lý</button>
                <div style="display: flex;">
                    <select class="input-field-global search-industry-custom" #option_education>
                        <option [value]="2" class="custom-text-option">Quận/huyện</option>
                        <option [value]="3" class="custom-text-option">Phường/xã</option>
                    </select>
                    <div style="position: relative;">
                        <input (focusout)="searchFocusOut(search.value,option_education.value);" #search type="text" class="text-left btn btn-default button-search {{showSearch ? 'transition-search-show': ''}}" [value]="dataSearch" (input)="onSearchChange($event.target.value)"
                            [readOnly]="showSearch ? false: true" placeholder="{{showSearch ? 'Tìm kiếm' : ''}}">
                        <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass(search.value)">
                        <div class="result-search {{ !checkResultSearch ? '' : 'active' }}">
                            <div class="item" *ngFor="let item of resultSearch" [routerLink]="[linkDetailEducation,item.id]">
                                <h4>{{ item.fullname }}</h4>
                                <p *ngIf="item.type == 2">{{ item.nameCity }}</p>
                                <p *ngIf="item.type == 3">{{ item.nameCity }} > {{ item.nameDistrict }}</p>
                            </div>
                        </div>
                    </div>
                    <ng-template ngxPermissionsOnly="{{permissionLocation.perm2}}">
                        <button class="btn-add-global" mat-raised-button (click)="clickAddEucationLv2();"><mat-icon>add</mat-icon> Thêm mới</button>
                    </ng-template>
                </div>
            </div>
            <div fxFlex class="content-declare" fxLayout="row" fxLayoutAlign="space-between start">
                <div class="lv-2" style="display: flex;flex-direction: column;width: 45%;height: 100%;">
                    <div class="title">Quận/ Huyện ({{ countQuanHuyen ? countQuanHuyen : 0 }})</div>
                    <!-- start quận huyện -->
                    <div class="content-lv-2">
                        <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height: 100%;overflow-y: auto;">
                            <div *ngFor="let item of dataQuanHuyen" class="grade" id="{{ item.id }}" (click)="openLv3(item.id)">
                                <div class="content-grade {{ id_active_lv_2 == item.id ? 'active' : '' }}">
                                    <div class="grade-left">
                                        <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                                        <p>
                                            <span>Phường/xã:{{ item.countWard }}</span>
                                        </p>
                                    </div>
                                    <div class="grade-right">
                                        <ng-template ngxPermissionsOnly="['{{permissionLocation.perm3}}', '{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                <mat-icon>more_horiz</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" class="menu-more-global">
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm3}}">
                                                    <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/administrative-unit/lv2/detail/',item.id]">Chi tiết</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                                    <button mat-menu-item class="px-5" (click)="clickEditEducationLv2(item);">Chỉnh sửa</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                                    <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv2(item);">Chuyển</button>-->
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                                    <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                </ng-template>
                                            </mat-menu>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end quận huyện -->
                </div>
                <div class="lv-3" style="display: flex;flex-direction: column;width: 45%;height: 100%">
                    <div class="title">Phường/ Xã ({{ countPhuongXa ? countPhuongXa : 0 }})</div>
                    <!-- start phường/xã -->
                    <div class="content-lv-3" style="max-height: 33%;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                        <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;height: 50%;">
                            <div *ngFor="let item of dataPhuongXa" class="grade" id="{{ item.id }}">
                                <div class="content-grade {{ id_active == item.id ? 'active' : '' }}">
                                    <div class="grade-left">
                                        <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                                    </div>
                                    <div class="grade-right">
                                        <ng-template ngxPermissionsOnly="['{{permissionLocation.perm3}}', '{{permissionLocation.perm4}}', '{{permissionLocation.perm5}}', '{{permissionLocation.perm6}}']">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                                <mat-icon>more_horiz</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" class="menu-more-global">
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm3}}">
                                                    <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/administrative-unit/lv3/detail/',item.id]">Chi tiết</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm4}}">
                                                    <button mat-menu-item class="px-5" (click)="clickEditEducationLv3(item);">Chỉnh sửa</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm6}}">
                                                    <button mat-menu-item class="px-5" (click)="clickMoveAdminstrativeLv3(item);">Chuyển</button>
                                                </ng-template>
                                                <ng-template ngxPermissionsOnly="{{permissionLocation.perm5}}">
                                                    <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                                </ng-template>
                                            </mat-menu>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end phường/xã -->
                </div>
            </div>
        </div>
    </div>
</div>