import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndustryUnitService } from '@app/_services/industry-unit.service';

@Component({
  selector: 'app-dialog-move-education-lv2',
  templateUrl: './dialog-move-education-lv2.component.html',
  styleUrls: ['./dialog-move-education-lv2.component.scss']
})
export class DialogMoveEducationLv2Component implements OnInit {
  data_move_education:any;
  listEducationLv1:any;
  listEducationLv2:any;
  moveCode:any;
  moveCode2:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRes: MatDialogRef<DialogMoveEducationLv2Component>,
    private industryUnitService:IndustryUnitService
  ) { }

  ngOnInit(): void {
    this.getDataLocationLv1();
    this.data_move_education = this.data;
  }

  selectOption(value:any){
    this.moveCode = value;
    this.getDataLocationLv2(this.moveCode);
  }

  selectOption2(value:any){
    this.moveCode2 = value;
  }

  confirm(){
    let data = {
      id: this.data_move_education.data.id,
      soId: this.moveCode,
      phongId: this.moveCode2,
      unit: this.data_move_education.data.unit
    }
    this.industryUnitService.moveEducation(data).subscribe(
      (res:any)=>{
        if(res.status == 1){
          this.dialogRes.close(true);
        }else{
          this.dialogRes.close(false);
        }
      },
      (err)=>{
        this.dialogRes.close(false);
      }
    )
  }

  getDataLocationLv1(){
    this.industryUnitService.getListEducationLv1().subscribe(
      (res:any)=>{
        this.listEducationLv1 = res.data;
        this.moveCode = res.data[0].code
      },
      (err)=>{

      }
    )
  }

  getDataLocationLv2(code:any){
    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res:any)=>{
        this.listEducationLv2 = res.data;
        this.moveCode2 = res.data[0].code
      },
      (err)=>{

      }
    )
  }


}
