<h2 mat-dialog-title class="title-page-global text-center"> {{ gradeName }} - Môn học</h2>
<mat-dialog-content class="mat-typography">
    <div class="dialog-body">
        <div class="row" style="padding-left: 15px;">
            <p class="col-md-12 text-left m-0 mt-2 mb-2" style="font-size: 16px;">
                Tổng số: {{ length }} môn học
            </p>
            <div class="col-md-12 row d-flex justify-content-center" style="padding-left: 30px;">
                <div class="col-md-12 pr-0 content-search">
                    <div style="cursor: pointer;">
                        <img src="assets/images/svg/search.svg" alt="">
                    </div>
                    <input #searchSubjectOfGrade (focusout)="loadDataItems(searchSubjectOfGrade.value)" (keydown)="searchListSubject($event,searchSubjectOfGrade.value)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Nhập tên, mã môn học">
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <table mat-table [dataSource]="listAccount" matSort class="table-el mt-0">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%;">STT</th>
                    <td mat-cell *matCellDef="let element;let i = index">{{i+1}}</td>
                </ng-container>
                <ng-container matColumnDef="fullname">
                    <th mat-header-cell *matHeaderCellDef style="width: 25%;">Tên môn học</th>
                    <td mat-cell *matCellDef="let element" class="text-important-table">{{element.fullname}}</td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Mã</th>
                    <td mat-cell *matCellDef="let element">{{element.code}}</td>
                </ng-container>
                <ng-container matColumnDef="is_moet">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%;">Môn học MOET</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [checked]="element.isMoetSubject == 1 ? true : false" [disabled]="true"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">Trạng thái</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [checked]="element.status == 1 ? true : false" [disabled]="true">
                            Kích hoạt
                        </mat-checkbox>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" style="line-height: 10px"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedColumns" style="background: white"></tr>
            </table>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn-close custom-outline-button btn-add-global">
        Đóng
    </button>
</mat-dialog-actions>