import {Component, Inject, OnInit} from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import {ShowMessage, SubjectService} from '@app/_services';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-subject-list-customers',
  templateUrl: './dialog-subject-list-customers.component.html',
  styleUrls: ['./dialog-subject-list-customers.component.scss']
})
export class DialogSubjectListCustomersComponent implements OnInit {
  dataColumn = ['stt', 'customer', 'code', 'link', 'phone', 'email'];
  dataSource: any;
  length: number;
  isLoading = false;
  constructor(private subjectService: SubjectService, @Inject(MAT_DIALOG_DATA) public data, private showMessage: ShowMessage) { }

  ngOnInit(): void {
    this.listCustomerBySubject();
  }

  listCustomerBySubject(value?: string){
    let key = value ? value : '';
    this.subjectService.getListCustomerBySubject(this.data.id, key, []).subscribe((ref: any) => {
      this.length = ref.data.length;
      this.dataSource = ref.data;
      this.isLoading = false;
    });
  }


  checkText(event, value){
      // event.keyCode === 13 ? ( document.getElementById('search-value-dialog') as HTMLInputElement).blur() : '';
      if (event.keyCode === 13 || event.keyCode === 9){
        this.isLoading = true;
        this.listCustomerBySubject(value);
      }
  }

}
