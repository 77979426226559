<div fxLayout="column" class="background-content bg-content-global">
    <div fxLayout="column" fxFlex class="content-declare">
        <div fxLayout="row" fxLayoutAlign="space-between start" class="custom-header">
            <div class="custom-title">
                <p class="title-custom">Phân hệ - {{infoSubSystem?.fullname | substring: 50}}</p>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div class="title-info">Thông tin cơ bản</div>
            <div>
                <ng-template ngxPermissionsOnly="{{permissionModules.perm4}}">
                    <button mat-button class="btn btn-add-edit" style="width: 201px" (click)="showFormEdit(infoSubSystem)">
                        <img src="assets/images/svg/edit-icon.svg">
                        Chỉnh sửa thông tin
                    </button>
                </ng-template>
            </div>
        </div>
        <br>
        <div style="width: 100%; border-bottom: 1px solid #F2F3F8">
            <div class="col-md-12 pl-0 pr-0">
                <div class="row">
                    <div class="form-group col-md-4 pr-0 row row-global">
                        <label class="col-md-3 lb-global">Tên phân hệ</label>
                        <div class="col-md-8 pl-0 ">
                            <span class="form-control input-field-global">{{ infoSubSystem?.fullname }}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-4 ml-auto row row-global">
                        <label class="col-md-3 offset-1 lb-global">Mã phân hệ</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <span class="form-control input-field-global">{{ infoSubSystem?.code | substring: 30 }}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-4 ml-auto row row-global">
                        <label class="col-md-3 offset-1 lb-global">Thứ tự sắp xếp</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <span class="form-control input-field-global">{{ infoSubSystem?.indexOrder }}</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-4 row row-global">
                        <label class="col-md-3 lb-global">Trạng thái</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <div>
                                <mat-checkbox class="custom-checkbox ml-1" style="position: relative; top: -1px" [checked]="infoSubSystem?.status == 1 ? true: false" disabled>
                                </mat-checkbox>
                                &nbsp;&nbsp;<label class="lb-global">Kích hoạt</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <label style="width: 9%" class="lb-global">Mô tả</label>
                    <div style="width: 100%" class="pl-0 pr-0">
                        <span style="height: 80px;" class="form-control input-field-global">{{infoSubSystem?.description}}</span>
                    </div>
                </div>
                <br>
            </div>
        </div>
        <br>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div style="position: relative; right: 20px">
                <button mat-button class="btn btn-custom tab-active tab-for-customer">
                    Khách hàng
                </button>
            </div>
            <div style="flex-grow: 1; display: flex; justify-content: flex-end;">
                <div class="search">
                    <input type="text" #search id="search__input" class="text-left btn btn-default" class="{{!showSearch == true ? 'transition-search-show' :'transition-search-hide'}}" (keydown)="keyPressSearch($event, search.value)" placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email">
                    <span class="search__icon" (click)="toggleClass()" class="{{!showSearch == true ? 'active-filter change-color-icon' : ''}}"><img src="assets/images/svg/search.svg" alt=""></span>
                </div>
                <button class="{{ showFilter == true ? 'change-color-icon' : '' }} btn custom-filter" (click)="showFilterClick()">
                    <img class="icon-filter" src="assets/images/svg/filter_img.svg" alt="">
                </button>
                <ng-template ngxPermissionsOnly="{{permissionModules.perm7}}">
                    <button mat-button class="btn btn-add-edit" style="width: 201px" (click)="dialogAddTenant()">
                        <img src="assets/images/svg/add.svg">
                        Thêm khách hàng
                    </button>
                </ng-template>
            </div>
        </div>
        <div style="position: relative; right: 103px" fxLayout="row" fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter">
            <ng-container *ngFor="let item of statusUser, let keyStatus = index">
                <button mat-button class="btn mr-2 btn-filter-option btn-item-filter {{item.checked ? 'filter-active' : 'filter-default'}}" [style]="{background: item.checked ? item.color: 'none'}" (click)="itemOptionClick(keyStatus)">
                    {{item.name}}
                </button>
            </ng-container>
            <button mat-button class="btn custom-btn-filter" (click)="clickFilterStatus()">Lọc</button>
        </div>
        <div class="dialog-body">
            <div class="custom-scroll">
                <table mat-table [dataSource]="dataSource" class="table-el">
                    <ng-container matColumnDef="stt">
                        <th mat-header-cell *matHeaderCellDef> STT</th>
                        <td class="border-first-5" mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="fullname">
                        <th mat-header-cell *matHeaderCellDef> Tên khách hàng</th>
                        <td mat-cell *matCellDef="let element">
                            <a href="tenants-manager/detail/{{element?.id}}?active=tab1" target="_blank">
                                {{element?.fullname}}
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef> Mã</th>
                        <td mat-cell *matCellDef="let element"> {{element?.code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="domain">
                        <th mat-header-cell *matHeaderCellDef> Tên miền</th>
                        <td mat-cell *matCellDef="let element"><a href="{{element?.tenantUrl}}">
                            {{element?.tenantUrl | substring : 15}}
                        </a></td>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef>SĐT</th>
                        <td mat-cell *matCellDef="let element"> {{element?.phone}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%"> Trạng thái</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex">
                                <select disabled class="form-control col-md-8 btn-status" #status [ngStyle]="{'background': getColorStatus(element?.status)}">
                                    <ng-container *ngFor="let item of statusUser , let i = index">
                                        <option class="font-option" [value]="item.id"
                                                [selected]="item.id == element?.status ? true : false">
                                            {{item.name}}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tools">
                        <th class="text-right" mat-header-cell *matHeaderCellDef> Công cụ</th>
                        <td class="border-last-5" mat-cell *matCellDef="let element">
                            <button class="btn btn-block" style="width: 50px; float: right" (click)="openDialogDelete(element)">
                                <img class="icon-remove" src="assets/images/svg/delete.svg" alt="">
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
