import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'parseTime'
})
export class ParseTimePipe implements PipeTransform {

  transform(value: any, formatTime: string): unknown {
    if (isNaN(value)) return moment(value).format(formatTime);
    return moment(value*1000).format(formatTime);
  }

}
