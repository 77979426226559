export const validateSystem = {
    fullname: [
            {type: 'required', message: 'Tên hệ thống không được để trống'},
            {type: 'maxlength', message: 'Không được vượt quá 250 ký tự'},
        ],
    phone: [{type: 'pattern', message: 'Số điện thoại không đúng'}],
    email: [
            {type: 'required', message: 'Email không được để trống'},
            {type: 'email', message: 'Không đúng định dạng email'},
            {type: 'maxlength', message: 'Email không được vượt quá 250 ký tự'},
        ],
    hotline: [{type: 'pattern', message: 'Hotline không đúng'}],
    address: [{type: 'maxlength', message: 'Address không được vượt quá 150 ký tự'}],
}
