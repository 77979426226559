import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TenantManagerService, ShowMessage, GeneralService } from '@app/_services';
import { of } from 'rxjs';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
@Component({
  selector: 'app-tab-tenant-content-detail',
  templateUrl: './tab-tenant-content-detail.component.html',
  styleUrls: ['./tab-tenant-content-detail.component.scss']
})
export class TabTenantContentDetailComponent implements OnInit, OnChanges {
  panelOpenState = true;
  @Input() data:any;
  
  constructor( private tenantManagerService:TenantManagerService,
    private showMessage: ShowMessage,
    private general: GeneralService
    ) { }
    isLoadingResults = false;
    city: {};
    district: {};
    ward: {};
    employeeDeploy: {};
    employeeSale: {};
    employeeDeployData = [
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa1",
        name: "employeeDeploy1"
      },
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa2",
        name: "employeeDeploy2"
      },
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa3",
        name: "employeeDeploy3"
      },
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa4",
        name: "employeeDeploy4"
      },
    ];
  
    employeeSaleData = [
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
        name: "employeeSale1"
      },
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: "employeeSale2"
      },
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
        name: "employeeSale3"
      },
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
        name: "employeeSale4"
      },
    ];
    cities: any[];
    districts: any[];
    wards: any[];

    ngOnInit(): void {
      this.getData();
    }  

    getData () {
      this.isLoadingResults = true;
      const APIDeploymentStaffs = this.tenantManagerService.getDeploymentStaffs();
      const APIBussinessStaffs = this.tenantManagerService.getBussinessStaffs();
      const APIGetCity = this.general.getListCity();

      forkJoin([APIDeploymentStaffs, APIBussinessStaffs, APIGetCity]).subscribe(results => {
        this.employeeDeploy = (results[0] as any).data;
        this.employeeSale = (results[1] as any).data;
        this.cities = (results[2] as any).data;

        if(this.data?.cityId!== null) {
          let selectedCityId = this.data?.cityId;
          // if(selectedCityId != null && selectedCityId != ''){
          //   this.general.getDetailLocation(selectedCityId).subscribe((result:any) => {
          //     this.city = result?.data?.fullname;
          //   });
          // }
        } else {
            this.city = "";
        }

        if(this.data?.districtId !== null) {
          let selectedDistrictId = this.data?.districtId;
          // if(selectedDistrictId != null && selectedDistrictId != ''){
          //   this.general.getDetailLocation(selectedDistrictId).subscribe((result:any) => {
          //     this.district = result?.data?.fullname;
          //   });
          // }
        } else {
          this.district = "";
        }

        if(this.data?.wardId !== null) {
          let selectedWardId = this.data?.wardId;
          // if(selectedWardId != null && selectedWardId != ''){
          //   this.general.getDetailLocation(selectedWardId).subscribe((result:any) => {
          //     this.ward = result?.data?.fullname;
          //   });
          // }
        } else {
          this.ward = "";
        }
        this.isLoadingResults = false;
      }), err => {
        console.log(err);
        this.isLoadingResults = false;
      };
    }
    ngOnChanges(changes) {
      this.getData();
    }
  }
