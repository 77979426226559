<span mat-dialog-title class="title-change-active">
    <p class="dialog-active-title" [style]="data_change_active.status ? 'color: #1355C1': 'color: #FF4848'">{{data_change_active.title}}</p>
    <p class="dialog-active-name">{{ data_change_active.data.name }}</p>
</span>
<mat-dialog-content class="mat-typography">
    <div class="mat-dialog-content-change-active" [innerHTML]="data.html ? data.html : ''"></div>
    <p class="dialog-active-content" [style]="data.html ? 'margin: 20px 0 20px 0 !important;' : ''">{{data_change_active.content}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn  dialog-active-btn-cancel mat-elevation-z" (click)="onDismiss()">{{ data_change_active.btnCancelText }}</button>
    <button mat-button type="submit" class="btn  dialog-active-btn-submit mat-elevation-z" (click)="confirm()"> {{ data_change_active.btnOkText }}</button>
</mat-dialog-actions>
