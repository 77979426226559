import { map } from 'rxjs/operators';
import { REGEX_CODE } from './../../../../_shared/utils/constant';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ShowMessage } from "@app/_services";
import { TIME_OUT_LISTEN_FIREBASE, MESSAGE_ERROR_CALL_API } from "@app/_shared/utils/constant";
import { FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
import { GeneralService } from '@app/_services/general.service';

@Component({
  selector: 'app-dialog-curriculum',
  templateUrl: './dialog-curriculum.component.html',
  styleUrls: ['./dialog-curriculum.component.scss']
})
export class DialogCurriculumComponent implements OnInit {
  isLoading: boolean = false;
  // isValidate: boolean;
  nameForm ='create';
  curriculumForm: FormGroup;
  // listSubjects = [];
  listGradesBySubject = [];
  listShoolYear = [];
  subjectId:string = '';
  gradeId:string = '';
  subjectName:string = '';
  gradeName:string = '';
  curriculumId:string;
  shoolYearId: string = ''
  messageValidate = {
    name: [
      { type: 'required', message: 'Bạn chưa nhập tên khung chương trình' },
      { type: 'pattern', message: 'Tên khung chương trình phải từ 2 - 250 ký tự' },
      { type: 'minlength', message: 'Tên khung chương trình tối thiểu 2 ký tự'},
      { type: 'maxlength', message: 'Tên khung chương trình tối đa 250 ký tự'}
    ],
    code: [
      { type: 'required', message: 'Bạn chưa nhập mã khung chương trình' },
      { type: 'pattern', message: 'Mã khung chương trình chỉ chấp nhận  a-z, A-Z, 2-50, _, - ' },
      { type: 'minlength', message: 'Mã khung chương trình tối thiểu 2 ký tự'},
      { type: 'maxlength', message: 'Mã khung chương trình tối đa 50 ký tự'}
    ],
  };
  infoCurriculum:any;
  
  constructor(
      private activatedRoute: ActivatedRoute,
      public dialogRef: MatDialogRef<DialogCurriculumComponent>,
      private router: Router,
      private showMessage: ShowMessage,
      private fb: FormBuilder,
      private curriculumManagerService: CurriculumManagerService,
      private generalService: GeneralService,
      @Inject(MAT_DIALOG_DATA) public dataDialog,
    
  ) { }

  ngOnInit(): void {
    if(this.dataDialog.nameForm == 'create') {
      this.getCode();
      this.getListGradesBySubject();
      this.getListShoolYear();
    }
    this.curriculumForm = this.fb.group({
      name: [this.dataDialog.data ? this.dataDialog.data.fullName : '', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
      code: [this.dataDialog.data ? this.dataDialog.data.code : '', [Validators.required, Validators.pattern(REGEX_CODE), Validators.minLength(2), Validators.maxLength(50)]],
      grade: null, 
      schoolYear: null,
      status: this.dataDialog.data ? this.dataDialog.data.isApply == 1 ? true : false : true,
    });
  }
  
  getCode() {
    this.generalService.getCodeCurriculm().subscribe((res:any)=> {
      this.curriculumForm.get("code").setValue(res);
    })
  }

  chooseOption(event, nameSelect) {
    if(nameSelect == 'grade') this.gradeId = event.target.value;
    if(nameSelect == 'shoolYear') this.shoolYearId = event.target.value;
  }

  getListGradesBySubject() {
    let dataInput = {
      keyword: "",
      trainingLevel: null
    };
    this.curriculumManagerService.getGradesBySubject(this.dataDialog.data.subjectId,dataInput).subscribe((res: any) => {
      if(res.status == 1) {
        this.listGradesBySubject = res.data;    
      } else {
        this.showMessage.error(res.msg);
      } 
    }, (err:any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  getListShoolYear() {
    this.curriculumManagerService.getListShoolYear().subscribe((res:any) => {
      if(res.status == 1) {
        this.listShoolYear = res.data;
        let currentSchoolYearId = this.listShoolYear.find(item => item.status == 1) ? this.listShoolYear.find(item => item.status == 1).id : null;
        this.curriculumForm.get("schoolYear").setValue(currentSchoolYearId);
        this.shoolYearId = currentSchoolYearId;
      }else {
        this.showMessage.error(res.msg);
      } 
    }, (err:any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  onSubmit(value, validate) {
    console.log(1);
    
    if (!validate) {
      this.isLoading = false;
      return;
    }
    if(this.dataDialog.nameForm == 'create') {
      if(this.gradeId == 'null' || this.gradeId == '' || this.gradeId == null) {
        this.showMessage.warring('Vui lòng chọn khối!');
        return;
      }
      if(this.shoolYearId == 'null' || this.shoolYearId == '' || this.shoolYearId == null) {
        this.showMessage.warring('Vui lòng chọn năm học!');
        return;
      }
      let dataInput = {
        gradeId: this.gradeId, 
        subjectId: this.dataDialog.data.subjectId,
        code: value.code.trim(),
        fullName: value.name.trim(),
        isApply: value.status ? 1 : 0,
        schoolYearId: this.shoolYearId,
        isAwait: 1
      };
      this.curriculumManagerService.createCurriculum(dataInput).subscribe((res: any) => {
        this.isLoading = false;
        if(res.status == 0) {
          this.dialogRef.close(false);
          this.showMessage.error(res.msg);
        } else {
          this.curriculumId = res.data.id;
          this.dialogRef.close(true);
          this.showMessage.success(res.msg); 
          this.router.navigate([`curriculum/form/${this.curriculumId}`], {queryParams: {nameForm:'create', tab:'content'}});
        }
      }, (err:any) => {
        this.isLoading = false;
        this.dialogRef.close(false);
        this.showMessage.error(MESSAGE_ERROR_CALL_API);
      });
    } else {
      let dataInput = {
        id: this.dataDialog.data.id,
        fullName: value.name.trim(),
        isApply: value.status ? 1 : 0,
        isAwait: 1
      }

      this.curriculumManagerService.editCurriculum(dataInput).subscribe((res: any) => {
        this.isLoading = false;
        if(res.status == 0) {
          this.dialogRef.close(false);
          this.showMessage.error(res.msg);
        }  else {
          this.dialogRef.close(true);
          this.showMessage.success(res.msg); 
          this.router.navigate([`curriculum/form/${this.dataDialog.data.id}`], {queryParams: {nameForm:'update', tab:'content'}});
        }
      }, (err:any) => {
        this.isLoading = false;
        this.dialogRef.close(false);
        this.showMessage.error(MESSAGE_ERROR_CALL_API);
      });
    }
    
  }
}
