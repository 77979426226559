<div class="dialog-body">
    <div class="row">
        <div class="col-md-12 text-center title">
            <span>Khối - {{data.infoSubject.fullname}}</span>
        </div>
        <div class="col-md-12 mt-4">
            <div class="row">
                <div class="col-md-12 d-flex justify-content-center">
                    <input (focusout)="blurSearch(input_search.value)" [autofocus]="true" #input_search (keypress)="keyPressSearch($event)" type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp" placeholder="Tìm kiếm theo mã, tên khối">
                </div>
                <img (click)="blurSearch(input_search.value)" style="position: relative; left: 30px; bottom: 30px; cursor: pointer" src="assets/images/svg/search.svg" alt="">
                <span class=" p-0 mt-2 total-account">Tổng số: {{dataSource.length}} khối</span>
            </div>
        </div>
    </div>

    <div class="container-fluid custom-scroll">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt" >
                <th mat-header-cell *matHeaderCellDef style="width: 1%"> STT</th>
                <td class="border-first-5" mat-cell *matCellDef="let element, let i = index"> {{i + 1}} </td>
            </ng-container>
            <ng-container matColumnDef="code" >
                <th class="text-center" mat-header-cell *matHeaderCellDef style="width: 3%"> Mã</th>
                <td class="text-center" mat-cell *matCellDef="let element"> {{element?.code}} </td>
            </ng-container>
            <ng-container matColumnDef="fullName" >
                <th class="text-center" mat-header-cell *matHeaderCellDef style="width: 3%"> Tên khối</th>
                <td class="text-center" mat-cell *matCellDef="let element">
                    {{element?.fullname}}
                </td>
            </ng-container>
            <ng-container matColumnDef="trainingLevel" >
                <th class="text-center" mat-header-cell *matHeaderCellDef style="width: 3%"> Cấp đào tạo</th>
                <td class="text-center" mat-cell *matCellDef="let element">
                    {{element?.trainingLevel}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator *ngIf="length > 15" showFirstLastButtons appStylePaginator (page)="handlePage($event)" [length]="length" [pageIndex]="pageIndex-1" [pageSizeOptions]="[5, 10, 20]" class="table-paginate-global"></mat-paginator>
    </div>
</div>
<br>
<p class="text-center">
    <button mat-dialog-close class="btn btn-close">Đóng</button>
</p>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
