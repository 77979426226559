export const lang = {
    title: 'Danh sách khối học',
    total_grade: 'Tổng số khối học',
    label: {
        stt: 'STT',
        name_grade: 'Tên khối học',
        code_grade: 'Mã khối học',
        grade_traning: 'Cấp đào tạo',
        grade_moet: 'Khối học MOET',
        number_customer_applies: 'Số khách hàng áp dụng',
        status: "Trạng thái",
        tools: 'Công cụ',
        activated: 'Kích hoạt',
    },
    button: {
        total_grade: 'Toàn bộ khối học tạo',
        grade_create_by_guest: 'Khối học do khách tạo',
        grade_create_by_moet: 'Khối học do moet tạo',
        add_grade: 'Thêm mới khối học',
        import_grade: 'Import khối học',
        edit_grade: 'Chỉnh sửa khối học',
        detail_grade: 'Chi tiết khối học',
        delete_grade: 'Xóa khối học',

    }
};
