import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})

export class GeneralService {
    constructor(private http: HttpClient) {

    }

    getCodeRandom(codePrefix){
        return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`, {
            prefix: codePrefix,
            lenghtOfAlphabet: 0,
            lenghtOfUpercase: 0,
            lenghtOfNumber: 6,
            lenghtOfSpecial: 0
        })
    }

    getListCity(){
        return this.http.get(`${environment.coreApiUrl}/api/location/get-list-city`)
    }

    getListDistrict(id: string){
        return this.http.get(`${environment.coreApiUrl}/api/location/get-list-district?id=${id}`)
    }

    getListWard(id: string){
        return this.http.get(`${environment.coreApiUrl}/api/location/get-list-ward?id=${id}`)
    }

    getDetailLocation(code: string){
        return this.http.get(`${environment.coreApiUrl}/api/location/detail/${code}`)
    }

    getCodeCurriculm() {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.http.get(`${environment.coreApiUrl}/api/Common/lay-ma-khung-chuong-trinh`, { headers, responseType: 'text'});
    }

}
