<div fxLayout="column" class="bg-content-global" *ngIf="isImportUser == false">
    <div fxLayout="row result-custom" fxLayoutAlign="space-between start">
        <div class="col-md-2 title-page-global title-custom">
            Người dùng
        </div>
        <div class="col-md-10">
            <div style="float: right !important;">
                <input (keydown)="checkText($event, search.value)" #search type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="pr-5 mr-2 text-left btn btn-default button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? 'Tên, tài khoản, số điện thoại, email người dùng...' : ''}}">
                <img src="assets/images/svg/search.svg" alt="" class="icon-search mr-2 {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass()">
                <button class="btn btn-default btn-filter" style="margin-right: 10px">
                    <img src="assets/images/svg/filter_img.svg" alt=""
                         class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilterClick()">
                </button>
                <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm6]">

                    <button class="btn btn-default button-import {{button.is_active_import_user == true ? 'tab-active': ''}}" style="margin-right: 10px" (click)="openDialogImportUser()">
                        <img src="assets/images/svg/import_file.svg" alt=""> &nbsp;Import danh sách
                    </button>
                </ng-template>
                <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm7]">

                    <button (click)="exportExcel()" class="btn btn-default button-import {{button.is_active_export_user == true ? 'tab-active': ''}}">
                        <img src="assets/images/svg/export.svg" alt=""> &nbsp;Export danh sách
                    </button>
                </ng-template>
                <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm2]">

                    <button class="btn btn-add-edit" (click)="openDialogAddAccount()">
                        <img src="assets/images/svg/add.svg"> &nbsp;Thêm người dùng
                    </button>
                </ng-template>
            </div>

        </div>
    </div>
    <!--    <div fxFlex class="row">-->
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="end start" *ngIf="showFilter">
        <div fxFlex fxLayout="row" fxLayoutAlign="center none">
            <!--            <mat-select [formControl]="roleUserControls" multiple class="btn btn-default mr-2 btn-filter-option">-->
            <!--                <mat-select-trigger>-->
            <!--                    <mat-chip-list>-->
            <!--                        <mat-chip style="background-color: #FF8038"-->
            <!--                                  *ngFor="let role of roleUserControls.value.slice(0, 2)" [removable]="true"-->
            <!--                                  (removed)="removeRoleFilter(role)">{{role.roleName | substring: 8}}-->

            <!--                            <mat-icon matChipRemove>cancel</mat-icon>-->
            <!--                        </mat-chip>-->
            <!--                        <mat-chip style="background-color: #FF8038" *ngIf="roleUserControls.value.length > 2">-->
            <!--                            + {{roleUserControls.value.length - roleUserControls.value.slice(0, 2).length}}-->
            <!--                            <mat-icon matChipRemove>cancel</mat-icon>-->
            <!--                        </mat-chip>-->
            <!--                    </mat-chip-list>-->
            <!--                </mat-select-trigger>-->

            <!--                <mat-option *ngFor="let role of roles" [value]="role"-->
            <!--                            class="option-checkbox">{{role.roleName}}</mat-option>-->
            <!--            </mat-select>-->
            <!--            <mat-select [formControl]="statusUserControls" multiple class="btn btn-default mr-2 btn-filter-option">-->
            <!--                <mat-select-trigger>-->
            <!--                    <mat-chip-list>-->
            <!--                        <mat-chip style="background-color: #FF8038"-->
            <!--                                  *ngFor="let user of statusUserControls.value.slice(0, 2); let i = index"-->
            <!--                                  [removable]="true"-->
            <!--                                  (removed)="removeUserFilter(user, i)">{{user.name}}-->
            <!--                            <mat-icon matChipRemove>cancel</mat-icon>-->
            <!--                        </mat-chip>-->
            <!--                        <mat-chip style="background-color: #FF8038" *ngIf="statusUserControls.value.length > 2">-->
            <!--                            + {{statusUserControls.value.length - statusUserControls.value.slice(0, 2).length}}-->
            <!--                            <mat-icon matChipRemove>cancel</mat-icon>-->
            <!--                        </mat-chip>-->
            <!--                    </mat-chip-list>-->
            <!--                </mat-select-trigger>-->
            <!--                <mat-option *ngFor="let user of statusUserControls" [value]="user"-->
            <!--                            class="option-checkbox">{{user.name}}</mat-option>-->
            <!--            </mat-select>-->
            <mat-form-field class="col-md-2 pl-2 pb-2">
                <mat-label style="padding-left: 8px" *ngIf="roleUserControls.value === null || roleUserControls.value.length === 0">Quyền</mat-label>
                <mat-select [formControl]="roleUserControls" multiple>
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.roleName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-md-2 pl-2 pb-2">
                <mat-label style="padding-left: 8px" *ngIf="statusUserControls.value === null || statusUserControls.value.length === 0">Trạng thái</mat-label>
                <mat-select [formControl]="statusUserControls" multiple>
                    <mat-option *ngFor="let status of statusUser" [value]="status.id">{{status.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button class="btn btn-default btn-filter-option-action" (click)="filter(search)">Lọc</button>
        </div>
    </div>
    <div fxFlex class="content-data">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 5%"> STT</th>
                <td class="border-first-5" mat-cell *matCellDef="let element, let stt = index"> {{pageIndex * pageSize + stt + 1}} </td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Tên người dùng</th>
                <td mat-cell *matCellDef="let element">
                    <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm3]">
                        <a routerLink="info-account/{{element.id}}" [queryParams]="{tab: 'info-account'}">
                            {{element.fullName | substring: 20}}
                        </a>
                    </ng-template>
                    <ng-template [ngxPermissionsExcept]="[USER_PERMISSION.perm3]">
                        <p>{{element.fullName | substring: 20}}</p>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Tài khoản</th>
                <td mat-cell *matCellDef="let element"> {{element.userName | substring: 15}} </td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> SĐT</th>
                <td mat-cell *matCellDef="let element"> {{element.phone == null ? '#' : element.phone}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Email</th>
                <td mat-cell *matCellDef="let element"> {{element.email | substring: 20}} </td>
            </ng-container>
            <ng-container matColumnDef="decentralization">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Phân quyền</th>
                <td mat-cell *matCellDef="let element" class="td-custom">
                    <span class="show-more" (click)="dialogOpenListRole(element)">{{element.numberRole}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">Khách hàng</th>
                <td mat-cell *matCellDef="let element" class="td-custom">
                    <span class="show-more" (click)="dialogOpenListCustomer(element)">{{element.numberTenant}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 8%"> Trạng thái</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex" *ngIf="element.status < 4">
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm4]">
                            <select class="form-control col-md-8 btn-status" #status (change)="dialogOpenChangeStatus(element, status, $event)" [ngStyle]="{'background': statusUser[element.status]?.color}">
                                <ng-container *ngFor="let item of statusUser">
                                    <option class="font-option" [value]="item.id"
                                            [selected]="item.id == element.status">{{item.name}}</option>
                                </ng-container>
                            </select>
                            <img style="position: relative; right: 20px" src="assets/images/svg/dropdown.svg" alt="">
                        </ng-template>
                        <ng-template [ngxPermissionsExcept]="[USER_PERMISSION.perm4]">
                            <select disabled class="form-control col-md-8 btn-status" #status (change)="dialogOpenChangeStatus(element, status, $event)" [ngStyle]="{'background': statusUser[element.status]?.color}">
                                <ng-container *ngFor="let item of statusUser">
                                    <option class="font-option" [value]="item.id"
                                            [selected]="item.id == element.status">{{item.name}}</option>
                                </ng-container>
                            </select>
                        </ng-template>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="log_data">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Dữ liệu đăng nhập</th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.lastLogedin }}</span>
                    <!--                    <br>-->
                    <!--                    <span>IP: {{element.logedinIpAddress}}</span>-->
                </td>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th class="text-right" mat-header-cell *matHeaderCellDef style="width: 5%"> Công cụ</th>
                <td class="border-last-5 text-right" mat-cell *matCellDef="let element">
                    <button class="btn btn-block" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon #icon_more class="icon-more">more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm4]">

                            <button mat-menu-item class="px-5" (click)="openDialogUpdateAccount(element)">Chỉnh sửa
                            </button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm9]">

                            <button mat-menu-item class="px-5" (click)="openDialogResetPassword(element)">Đặt lại mật
                                khẩu
                            </button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm13]">

                            <button mat-menu-item class="px-5" [routerLink]="['info-account', element.id]" [queryParams]="{tab: 'decentralization-applies'}">Phân quyền
                            </button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm10]">

                            <button mat-menu-item class="px-5" [routerLink]="['info-account', element.id]" [queryParams]="{tab: 'tenants'}">Khách hàng
                            </button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm8]">

                            <button mat-menu-item class="px-5" [routerLink]="['info-account', element.id]" [queryParams]="{tab: 'action-history'}">Lịch sử
                            </button>
                        </ng-template>
                        <ng-template [ngxPermissionsOnly]="[USER_PERMISSION.perm5]">

                            <button mat-menu-item class="text-danger px-5" (click)="openDialogDelete(element)">Xóa
                            </button>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator *ngIf="totalRecord > pageSize" showFirstLastButtons appStylePaginator class="table-paginate-global" [length]="totalRecord" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)"></mat-paginator>
    </div>
    <!--    </div>-->
</div>
<app-import-users (isImport)="getCloseImport($event)" *ngIf="isImportUser == true" [dataSource]="dataImport"></app-import-users>

<div class="show-loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>