<span mat-dialog-title class="title-change-active">
    <p class="dialog-active-title" >{{ data_notification.title }}</p>
    <p class="dialog-active-name">{{ data_notification.data.name }}</p>
</span>
<mat-dialog-content class="mat-typography ">
    <div class="mat-dialog-content-change-active" [innerHTML]="data_notification.html ? data_notification.html : ''"></div>
    <p class="dialog-active-content" [style]="data.html ? 'margin: 20px 0 20px 0 !important;' : ''">{{ data_notification.message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="dialog-notification-btn-close btn">{{ data_notification.btnCancelText }}</button>
</mat-dialog-actions>
