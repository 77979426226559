import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModulesManagerService} from '@app/_services';

@Component({
  selector: 'app-dialog-list-customer',
  templateUrl: './dialog-list-customer.component.html',
  styleUrls: ['./dialog-list-customer.component.scss']
})
export class DialogListCustomerComponent implements OnInit {
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email'];
  dataSource = [];
  keyword = '';
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private modulesManagerService: ModulesManagerService) { }

  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems() {
    const data = {moduleId: this.data.infoModule.id, keyword: this.keyword, status: null};
    this.modulesManagerService.listTenantOfModule(data).subscribe((res: any) => {
      this.dataSource = res.data;
    });
  }

  blurSearch(value){
    this.keyword = value;
    this.loadDataItems();
  }

  keyPressSearch(event,keyword){
    if (event.code == 'Enter' || event.code == "Tab"){
      this.keyword = keyword;
      this.loadDataItems();
    }
  }
}
