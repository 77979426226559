import { Component, OnInit, Renderer2, EventEmitter, Input, Output} from '@angular/core';
import {NgbCalendar, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

@Component({
  selector: 'app-datetime-picker-toggle',
  templateUrl: './datetime-picker-toggle.component.html',
  styleUrls: ['./datetime-picker-toggle.component.scss']
})
export class DatetimePickerToggleComponent implements OnInit{
  date: { year: number; month: number; day: number};
  time = {hour: null, minute: null};
  model: NgbDateStruct;
  meridian = true;
  @Input() typeTime = '';
  @Input() background = 'none';
  @Input() filter = 'none';
  @Input() color = 'none';
  @Output() dataTime = new EventEmitter<any>();

  constructor(
      private renderer: Renderer2,
      private calendar: NgbCalendar
  ) { }
  isMenuOpen = false;

  ngOnInit(): void {

  }

  getTime(){
    let param = null;
    // if (this.time.hour === null) this.time.hour = 0;
    // if (this.time.minute === null) this.time.minute = 0;

    if(this.model !== undefined){
      let timeStamp = this.model.year+'-'+
          (this.model.month < 10 ? '0'+this.model.month: this.date.month)
          +'-'+(this.model.day < 10 ? '0'+this.model.day: this.model.day)
          +' '+(this.time.hour < 10 ? '0'+this.time.hour : this.time.hour)
          +':'
          +(this.time.minute < 10 ? '0'+this.time.minute : this.time.minute)+':'+'00';

      // @ts-ignore
      param = Date.parse(new Date(timeStamp));
      return this.dataTime.emit(param);
    }

    return this.dataTime.emit(param);
  }

  toggleMenu(event) {
    this.isMenuOpen = !this.isMenuOpen;
    event.stopPropagation();
    if (!this.isMenuOpen) this.getTime();
  }

  close() {
    this.isMenuOpen = false;
    this.getTime();
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }


}
