import { Component, OnInit, Inject } from '@angular/core';
import {lang} from '../dialog-account-list-applies/lang-account-list-applies';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as $ from 'jquery';
import { GradeManagerService } from '@app/_services/grade.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dialog-add-account-applies',
  templateUrl: './dialog-add-account-applies.component.html',
  styleUrls: ['./dialog-add-account-applies.component.scss']
})
export class DialogAddAccountAppliesComponent implements OnInit {
  displayedColumns: string[] = ['stt','fullname', 'code', 'domain', 'phone', 'email'];
  dataTenantNotAssign:any;
  isChecked = false;
  countCheck:number = 0;
  lengthTenant:number;
  arrayTenant = [];
  arraySelectAll = [];
  dataTemp:any;
  constructor(
    private dialogRef: MatDialogRef<DialogAddAccountAppliesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gradeManagerService:GradeManagerService
  ) { }

  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(keyword = ''){
    // lay danh sach tenant chua assign
    let dataInput = {
      "keyword": keyword
    }
    this.gradeManagerService
      .getTenantNotAssign(this.data.idGrade,dataInput)
      .subscribe((data:any) => {
        this.dataTemp = [];
        this.lengthTenant = data.data.length;
        this.dataTenantNotAssign = new MatTableDataSource(data.data);
        this.dataTemp = data.data;
      },
      (error => {
      })
    );
  }

  searchTenantNotAssign(event,keyword){
    if(event.code == 'Enter' || event.code == 'Tab'){
      this.loadDataItems(keyword);
    }
  }

  clickSelectAll(event){
    this.isChecked = event.checked;
    if(event.checked){
      this.dataTemp.forEach(element => {
        if(this.arrayTenant.indexOf(element.id) == -1){
          this.arrayTenant.push(element.id);
        }
      });
      this.countCheck = this.arrayTenant.length;
    }else{
      this.arrayTenant = [];
      this.countCheck = 0;
    }
  }

  clickSelectItem(event,idTenant){
    if(event.checked){
      this.arrayTenant.push(idTenant);
      this.countCheck++;
    }else{
      this.isChecked = false;
      const index = this.arrayTenant.indexOf(idTenant);
      if (index > -1) {
        this.arrayTenant.splice(index, 1);
      }
      this.countCheck--;
    }
  }

  clickAddTenant(){
    let dataAddTenant = {
      "tenantIds": this.arrayTenant,
      "gradeId": this.data.idGrade
    }
    this.dialogRef.close(dataAddTenant);
  }

  closeAddTenant(){
    this.dialogRef.close(false);
  }
}
