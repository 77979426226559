import { Component, OnInit, Inject } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModulesManagerService} from '@app/_services';
import {ShowMessage} from "@app/_services";
import { REGEX_FULL_NAME,REGEX_CODE } from "@app/_shared/utils/constant";

@Component({
  selector: 'app-dialog-form-permission',
  templateUrl: './dialog-form-permission.component.html',
  styleUrls: ['./dialog-form-permission.component.scss']
})
export class DialogFormPermissionComponent implements OnInit {
  permissionForm;
  checkValidate = false;
  action: string;
  codeRandom: string;
  permission: any;
  errCode;

  constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<DialogFormPermissionComponent>,
      @Inject(MAT_DIALOG_DATA) public value,
      private modulesManagerService: ModulesManagerService,
      private showMessage: ShowMessage,
  ) { }

  ngOnInit(): void {
    this.action = this.value.status;
    this.permission = this.value.item ? this.value.item : '';
    this.codeRandom = this.action === 'add' ? '' : this.permission.code;
    this.permissionForm = this.fb.group({
      fullname: [this.permission.fullname, [Validators.required, Validators.pattern(REGEX_FULL_NAME)]],
      code: [this.codeRandom, [Validators.required, Validators.pattern(REGEX_CODE)]],
      description: [this.permission.description],
      indexOrder: this.permission.indexOrder ? this.permission.indexOrder : 0,
    });
  }

  onSubmit(value, validate) {
    if (!validate){
      this.checkValidate = true;
      return false;
    }
    value.indexOrder = value.indexOrder == null ? 0 : Number(value.indexOrder);
    if (this.action === 'add'){
      value.moduleId = this.value.data;
      this.dialogRef.close(value);
    }
    else{
      value.id = this.permission.id;
      delete value.code;
      this.dialogRef.close(value);
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onDisMiss(){
    this.dialogRef.close(false);
  }

   permissionValidate = {
      'fullname': [
        {type: 'required', message: 'Vui lòng nhập tên quyền'},
        {type: 'pattern', message: 'Vui lòng độ dài 2-50 ký tự, a-z, A-Z, 0-9'}],
      'code': [
        {type: 'required', message: 'Vui lòng nhập mã quyền'},
        {type: 'pattern', message: 'Vui lòng độ dài 2-50 ký tự, a-z, A-Z, 0-9'}],
      'description': [{type: 'required', message: 'Vui lòng nhập mô tả'}],
  };
}
