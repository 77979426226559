<table mat-table [dataSource]="dataSource" class="table-el">
    <ng-container matColumnDef="stt">
        <th mat-header-cell *matHeaderCellDef style="width: 3%">STT</th>
        <td mat-cell *matCellDef="let item, let i = index">{{(pageIndex * 15) + i + 1}}</td>
    </ng-container>
    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef style="width: 15%">Tên môn học</th>
        <td mat-cell *matCellDef="let item">
            <ng-template ngxPermissionsOnly="{{permissionSubject.perm3}}">
                <a class="link_table" href="javascript:;" (click)="openDetailSubject(item.id,'tab1');">{{item.fullname | substring : 30}}</a>
            </ng-template>
            <ng-template ngxPermissionsExcept="{{permissionSubject.perm3}}">
                <a class="link_table" href="javascript:;">{{item.fullname | substring : 30}}</a>
            </ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef style="width: 8%">Mã môn học</th>
        <td mat-cell *matCellDef="let item">{{item.code}}</td>
    </ng-container>
    <ng-container matColumnDef="grade">
        <th mat-header-cell *matHeaderCellDef style="width: 8%">Khối </th>
        <td mat-cell *matCellDef="let item"><span *ngFor="let grade of item.grades">{{grade.fullname}}, </span></td>
    </ng-container>
    <ng-container matColumnDef="tenant">
        <th mat-header-cell *matHeaderCellDef style="width: 8%">Khách hàng</th>
        <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;" (click)="openTenant(item.id);">{{item?.tenant?.fullname}}</a></td>
    </ng-container>
    <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef style="width: 5%">Trạng thái</th>
        <td mat-cell *matCellDef="let item"><mat-checkbox readonly disabled  [checked]="item.status === 1" >Kích hoạt</mat-checkbox></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
    <tr mat-row *matRowDef="let row, columns: dataColumn"></tr>
</table>
<mat-paginator *ngIf="countData > 15" appStylePaginator class="table-paginate-global" [length]="countData" [pageIndex]="pageIndex" [pageSize]="15" (page)="onChangePaginate($event)" ></mat-paginator>
