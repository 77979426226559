import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/_shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { RolesManagerRoutingModule } from './roles-manager-routing.module';
import { RoleManagerListComponent } from '@app/_modules/roles-manager/components/role-manager-list/role-manager-list.component';
import { RoleListUsersComponent } from '@app/_modules/roles-manager/components/tabs/role-list-users/role-list-users.component';
import { RoleDetailComponent } from '@app/_modules/roles-manager/components/role-detail/role-detail.component';
import { DialogRoleAddUserComponent } from './dialogs/dialog-role-add-user/dialog-role-add-user.component';
import { DialogRoleFormAddEditComponent } from './dialogs/dialog-role-form-add-edit/dialog-role-form-add-edit.component';
import { DialogRoleListUsersComponent } from './dialogs/dialog-role-list-users/dialog-role-list-users.component';
import {ReactiveFormsModule} from '@angular/forms';
import { DialogRoleListPermissionsComponent } from './dialogs/dialog-role-list-permissions/dialog-role-list-permissions.component';
import { RoleListModulePermissionComponent } from './components/tabs/role-list-module-permission/role-list-module-permission.component';
import { DialogRoleAddModuleComponent } from './dialogs/dialog-role-add-module/dialog-role-add-module.component';
import { NgxPermissionsModule } from 'ngx-permissions';
@NgModule({
  declarations: [
      RoleManagerListComponent,
      RoleDetailComponent,
      RoleListUsersComponent,
      DialogRoleAddUserComponent,
      DialogRoleFormAddEditComponent,
      DialogRoleListUsersComponent,
      DialogRoleListPermissionsComponent,
      RoleListModulePermissionComponent,
      DialogRoleAddModuleComponent],
    imports: [
        CommonModule,
        RolesManagerRoutingModule,
        SharedModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forChild()
    ]
})
export class RolesManagerModule { }
