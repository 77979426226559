<div fxLayout="column">
    <div fxFlex="60px">
        <div class="title-content-page-tab">Phân quyền</div>
    </div>
    <div fxFlex >
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                <td mat-cell *matCellDef="let item;let i = index">{{ dataRoles.size*(dataRoles.page - 1) + i + 1 }}</td>
            </ng-container>
            <ng-container matColumnDef="nameRole">
                <th mat-header-cell *matHeaderCellDef style="width: 35%">Tên phân quyền</th>
                <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.name}}</td>
            </ng-container>
            <ng-container matColumnDef="codeRole">
                <th mat-header-cell *matHeaderCellDef style="width: 17%">Mã</th>
                <td mat-cell *matCellDef="let item" >{{item.code}}</td>
            </ng-container>
            <ng-container matColumnDef="permission">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">Quyền</th>
                <td mat-cell *matCellDef="let item" >
                    <span class="custom-box-list">{{1}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="statusRole">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Trạng thái</th>
                <td mat-cell *matCellDef="let item" >
                    <mat-checkbox [disabled]="true" [checked]="item?.status ? true: false">
                        Kích hoạt
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="tool">
                <th mat-header-cell *matHeaderCellDef style="width: 8%">Công cụ</th>
                <td mat-cell *matCellDef="let item">
                    <img src="assets/images/icons/icon-22.svg" style="cursor: pointer" (click)="confirmDelete(item)">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
            <tr mat-row *matRowDef="let row, columns: dataColumn"></tr>
        </table>
    </div>
</div>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
