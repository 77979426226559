<h4 mat-dialog-title class="title-dialog" class="title-delete">{{ data_change_status.title }} <br> <b>{{ data_change_status.data.name }}</b></h4>
<mat-dialog-content class="mat-typography mat-dialog-content-global">
    <div class="mat-dialog-content-global" [innerHTML]="data.html ? data.html : ''"></div>
    <div class="content-change-status">
        <span [ngStyle]="{'color': data_change_status.data.color_current}">{{ data_change_status.data.name_current }}</span>
        <img class="img-change-status" src="assets/images/icons/next_icon.png" alt="">
        <span [ngStyle]="{'color': data_change_status.data.color_change}">{{ data_change_status.data.name_change }}</span>
    </div>
    <p class="message-delete">{{ data_change_status.message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button class="btn-custom btn-cancel-confirm" mat-dialog-close mat-raised-button>{{ data_change_status.btnCancelText }}</button>
    <button mat-button class="btn-custom btn-accept-confirm" [mat-dialog-close]="true" cdkFocusInitial mat-raised-button (click)="confirm()">{{ data_change_status.btnOkText }}</button>
</mat-dialog-actions>