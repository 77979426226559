import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FilterPipe } from './pipe/filter.pipe';
import { OrderByPipe } from './pipe/order-by.pipe';
import { TimeagoPipe } from './pipe/timeago.pipe';
import { ConvertStringPipe } from './pipe/convert-string-list-role.pipe';
import { ConvertNameTrainingLevelPipe } from './pipe/convert-name-training-level.pipe';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DialogDeleteComponent } from './dialogs/delete/dialog-delete.component';
import { DialogChangeStatusComponent } from './dialogs/change-status/dialog-change-status.component';
import { DialogNotificationComponent } from './dialogs/notification/notification.component';
import { DialogChangePasswordComponent } from '@app/_shared/dialogs/change-password/dialog-change-password.component';
import { NavService } from '../_services/nav.service';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StylePaginatorDirective } from './pagination/style-pagination.derective';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {ParseTimePipe} from './pipe/parse-time.pipe';
import {SubStringPipe} from '@app/_shared/pipe/subString.pipe';
import {ConfirmComponent} from '@app/_shared/dialogs/confirm/confirm.component';
// import { FileManagerModule } from './components/file-manager/file-manager.module';
import { NewFolderDialogComponent } from './components/file-manager/modals/newFolderDialog/newFolderDialog.component';
import { RenameDialogComponent } from './components/file-manager/modals/renameDialog/renameDialog.component';
import {OnlyNumberDirective} from './directive/only-number.directive';
import {AppTextareaAutoresizeDirective} from './directive/app-textarea-autoresize.directive';
import { MathModule } from './directive/math/math.module';

import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogSelectUnitVerticalComponent } from './dialogs/dialog-select-unit-vertical/dialog-select-unit-vertical.component';
import { DebounceClickDirective } from '@app/_shared/directive/debounceTime.directive';
@NgModule({
  declarations: [
    FilterPipe,
    OrderByPipe,
    TimeagoPipe,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DialogDeleteComponent,
    DialogChangeStatusComponent,
    DialogChangeActiveComponent,
    DialogNotificationComponent,
    DialogChangePasswordComponent,
    StylePaginatorDirective,
    ConvertStringPipe,
    ConvertNameTrainingLevelPipe,
    ParseTimePipe,
    NewFolderDialogComponent,
    RenameDialogComponent,
    OnlyNumberDirective,
    AppTextareaAutoresizeDirective,
    SubStringPipe,
    ConfirmComponent,
    DialogSelectUnitVerticalComponent,
    DebounceClickDirective
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    NgxPermissionsModule.forChild(),
    MathModule
  ],
  exports: [
    FilterPipe,
    OrderByPipe,
    TimeagoPipe,
    CommonModule,
    FormsModule,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MaterialModule,
    FlexLayoutModule,
    DialogDeleteComponent,
    DialogChangeStatusComponent,
    DialogNotificationComponent,
    DialogChangePasswordComponent,
    StylePaginatorDirective,
    ConvertStringPipe,
    ConvertNameTrainingLevelPipe,
    ParseTimePipe,
    NewFolderDialogComponent,
    RenameDialogComponent,
    OnlyNumberDirective,
    SubStringPipe,
    ConfirmComponent,
    MathModule,
    AppTextareaAutoresizeDirective,
    DialogSelectUnitVerticalComponent,
    DebounceClickDirective
  ],
  providers: [
      NavService,
  ]
})
export class SharedModule{}
