export class Subject {
    id: string;
    fullname: null;
    code: string;
    description: string;
    trainingLevel: number;
    gradeId: string;
    isMoetSubject: number;
    indexOrder: number;
    status: number
}
