import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Router, ActivatedRoute} from '@angular/router';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-dialog-import-administrative',
    templateUrl: './dialog-import-administrative.component.html',
    styleUrls: ['./dialog-import-administrative.component.scss']
})
export class DialogImportAdministrativeComponent implements OnInit {
    fileName: string = '';
    isFileExcel = false;
    message = '';
    isLoading = false;
    fileExcel = null;
    base64FileUploadLocation:any;

    listError = [
        {name_error: 'Báo lỗi khi trùng dữ liệu', message: '*Hệ thống sẽ thực hiện kiểm tra và báo lỗi khi phát hiện dữ liệu trùng lặp'},
        {
            name_error: 'Ghi đè dữ liệu trùng lặp',
            message: '*Hệ thống sẽ thực hiện kiểm tra và tự động ghi đè khi phát hiện dữ liệu trùng lặp'
        },
        {
            name_error: 'Bỏ qua dữ liệu trùng lặp',
            message: '*Hệ thống sẽ thực hiện kiểm tra và tự động bỏ qua khi phát hiện dữ liệu trùng lặp'
        },
    ];

    listColumn = [
        'Mã', 'Tài khoản', 'Mật khẩu', 'Email', 'SĐT', 'Ngày sinh', 'Giới tính', 'Đìa chỉ', 'Phường xã'
    ];

    keyError = 0;

    infoForm;

    constructor(
        private  fb: FormBuilder,
        private route: Router,
        private dialogRef: MatDialogRef<DialogImportAdministrativeComponent>,
        private administrativeUnitService:AdministrativeUnitService
    ) {
    }

    ngOnInit(): void {
        this.infoForm = this.fb.group({
            check_column: ['Tên đăng nhập'],
            action: [0],
            check_column_more: ['']
        });
    }

    onFileChange(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            this.base64FileUploadLocation = reader.result;
            if(this.base64FileUploadLocation){
                this.isFileExcel = true;
            }
        };
        if (event.target.files.length > 0) {
            if (event.target.files[0].name.slice(-5) == '.xlsx' || event.target.files[0].name.slice(-4) == '.xls') {
                this.message = '';
                this.fileName = event.target.files[0].name;
                this.loaderProgress('loading-progress')
                this.isFileExcel = true;
                this.fileExcel = event.target.files[0];
                return this.message = 'Không đúng định dạng file';
            } else {
                console.log('không đúng định dạng');
            }
        }
    }

    uploadFile() {
        this.isLoading = false;
        document.getElementById('inputUploadFile').click();
    }

    checkError(value) {
        return this.keyError = value;
    }

    loaderProgress(paramElement) {
        let width = 1;
        let id = setInterval(frame, 10);

        function frame() {
            if (width > 100) {
                clearInterval(id);
            } else {
                $('.' + paramElement).css('background', '#FF8038');
                $('.' + paramElement).css('width', width + '%');
                $('.' + paramElement).css('height', '4px');
                width++;
            }
        }

        this.isLoading = true;
    }

    removeFile() {
        this.fileExcel = null;
        this.fileName = '';
        this.isFileExcel = false;
    }

    onSubmit(data) {
        // var strArray = this.base64FileUploadLocation.split("base64,");
        // let dataInput = {
        //     oldSessionFile:JSON.parse(localStorage.getItem('currentUser')),
        //     fileBase64:strArray[1]
        // }
        // this.administrativeUnitService.importBase64Location(dataInput).subscribe(
        //     (res:any)=>{
        //         this.dialogRef.close({fileName:this.fileName,data:res.data});
        //     },
        //     (err)=>{
        //         console.log(err);
        //     }
        // )

        var strArray = this.base64FileUploadLocation.split("base64,");
        let dataInput = {
            oldSessionFile:localStorage.getItem('sessionFile') ? localStorage.getItem('sessionFile') : '',
            fileBase64:strArray[1]
        }
        this.dialogRef.close({fileName:this.fileName,dataInput:dataInput});
    }
}
