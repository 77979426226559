import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';

@Component({
  selector: 'app-dialog-move-administrative',
  templateUrl: './dialog-move-administrative.component.html',
  styleUrls: ['./dialog-move-administrative.component.scss']
})
export class DialogMoveAdministrativeComponent implements OnInit {
  data_move_adminstrative:any;
  listLocationLv1:any;
  moveCode:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRes: MatDialogRef<DialogMoveAdministrativeComponent>,
    private administrativeUnitService:AdministrativeUnitService
  ) { }

  ngOnInit(): void {
    this.getDataLocationLv1();
    this.data_move_adminstrative = this.data;
  }

  selectOption(value:any){
    this.moveCode = value;
  }

  confirm(){
    let data = {
      id: this.data_move_adminstrative.data.id,
      cityId: this.moveCode,
      districtId: null,
      type: 2
    }
    this.administrativeUnitService.moveLocation(data).subscribe(
      (res:any)=>{
        if(res.status == 1){
          this.dialogRes.close(true);
        }else{
          this.dialogRes.close(false);
        }
      },
      (err)=>{
        this.dialogRes.close(false);
      }
    )
  }

  getDataLocationLv1(){
    this.administrativeUnitService.getLocationLv1().subscribe(
      (res:any)=>{
        this.listLocationLv1 = res.data;
        this.moveCode = res.data[0].code
      },
      (err)=>{

      }
    )
  }
}
