import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ShowMessage } from "@app/_services";
import { REGEX_FULL_NAME, TIME_OUT_LISTEN_FIREBASE, MESSAGE_ERROR_CALL_API } from "@app/_shared/utils/constant";
import { DATA_FAKE} from '../../constant';
import { MatTreeFlattener } from '@angular/material/tree';
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';

interface ExampleFlatNode {
  expandable: boolean;
  content: string;
  level: number;
}
interface Node {
  id: string;
  parentId: string;
  content: string;
  childs?: Node[];
  
}
@Component({
  selector: 'app-curriculum-detail',
  templateUrl: './curriculum-detail.component.html',
  styleUrls: ['./curriculum-detail.component.scss']
})


export class CurriculumDetailComponent implements OnInit {
  isLoading: boolean = false;
  data = DATA_FAKE;
  active_tab: string;
  id:string;
  subjectId: string;
  subjectName: string;
  gradeName: string ='';
  gradeId: string = '';
  infoCurriculum:any;
  private _transformer = (node: Node, level: number) => {
    return {
        expandable: !!node.childs && node.childs.length > 0,
        content: node.content,
        level: level,
    };
  }


  treeControl = new NestedTreeControl<any>(node => node.childs);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.childs);

  
  dataSource = new MatTreeNestedDataSource<any>();
  constructor(
      private activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      private router: Router,
      private showMessage: ShowMessage,
      private curriculumManagerService: CurriculumManagerService,

  ) {}

  hasChild = (_: number, node: any) => !!node.childs && node.childs.length > 0;
  ngOnInit(): void {
    // this.id = this.activatedRoute.snapshot.params.idCurriculum;
    // this.subjectId = this.activatedRoute.parent.snapshot.params.subjectId;
    this.activatedRoute.params.subscribe(res => {
      this.id = res.idCurriculum;
      this.getInfoCurriculum();
      this.getDataCurriculumContent(); 
    })
    //this.active_tab = this.activatedRoute.snapshot.queryParams.tab !== undefined ? this.activatedRoute.snapshot.queryParams.tab : 'required';
    // this.subjectName = this.activatedRoute.snapshot.queryParams.subjectName;
    // this.gradeName = this.activatedRoute.snapshot.queryParams.gradeName;
    this.activatedRoute.queryParams.subscribe(res => {
      this.active_tab = res.tab !== undefined ? this.activatedRoute.snapshot.queryParams.tab : 'content';
      this.subjectName = res.subjectName;
      this.getDataCurriculumContent(); 
      // this.gradeName = res.gradeName;
      // this.gradeId = res.gradeId;
    })
    // this.getDataCurriculumContent(); 
  }

  getInfoCurriculum() {
    this.curriculumManagerService.getDetailCurriculum(this.id).subscribe((res:any) => {
      this.isLoading = false;
      this.infoCurriculum = res.data;
    }, (err:any) => {
      this.isLoading = false;
    })
  }
  clickTab(tab?: string){
    this.active_tab = tab;
    this.router.navigate([], { 
      relativeTo: this.activatedRoute, 
      queryParams: { tab: tab, subjectName:this.subjectName}, 
      //queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.getDataCurriculumContent(); 
  }

  editLink() {
    this.router.navigate([`curriculum/form/${this.id}`], {queryParams: { nameForm:'update', tab: this.active_tab}});
  }

  // lay noi dung khung chuong trinh (gom noi dung bai hoc va yeu cau can dat)
  getDataCurriculumContent() {
    this.isLoading = true;
    let dataInput = {
      khungChuongTrinhId: this.id,
      type: this.active_tab == 'required' ? 2 : 1
    }
    this.curriculumManagerService.getListContentCurriculum(dataInput).subscribe((res:any)=> {
      this.dataSource.data = res.data;
      this.isLoading = false;
    }, (err:any)=> {
      this.isLoading = false;
    })
  }
  
}

