<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between none" >
        <div>
            <p class="title-page-global">Danh sách Môn học</p>
        </div>
        <div fxFlex="70%" fxLayout="row" fxLayoutAlign="end none" >
            <input (keydown)="checkText($event, search.value)" #search type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="text-left btn button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? 'Tìm kiếm theo tên môn học, mã môn học' : ''}}"  (blur)="checkText($event, search.value)" >
            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass()">
            <button class="btn btn-default btn-filter ml-3">
                <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilterClick()">
            </button>
<!--            <button class="btn btn-import mat-elevation-z" mat-raised-button> <mat-icon>save</mat-icon> Import môn học </button>-->
            <ng-template ngxPermissionsOnly="{{permissionSubject.perm2}}">
                <button class="btn btn-add-global mat-elevation-z" (click)="showFormModuleSubject('add')" mat-raised-button><mat-icon>add</mat-icon>Thêm môn học mới </button>
            </ng-template>
        </div>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between start" >
        <div>
            <button class="btn mr-2" [class]="tab === 'moet' ? 'btn-filter-subject-select' : 'btn-filter-subject'"  (click)="onChangeTab('moet', 1)" >MOET</button>
            <button class="btn mr-2" [class]="tab === 'other' ? 'btn-filter-subject-select' : 'btn-filter-subject'"  (click)="onChangeTab('other', 2)" >Môn riêng</button>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter" style="padding-right: 3.1rem;">
            <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 1)">Mầm Non</button>
            <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 2)">Tiểu học</button>
            <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 3)">Trung học cơ sở</button>
            <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 4)">Trung học phổ thông</button>
            <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClick($event, 99)">Khác</button>
            <button class="btn btn-default btn-filter-option-action" (click)="filter()">Lọc</button>
        </div>
    </div>
    <div fxFlex fxLayout="column" class="content-data">
        <app-subjects-list-moet *ngIf="tab === 'moet'" [pageIndex]="pageIndex"  (reloadDataSource)="reloadData($event)" [dataSource]="data" [countData]="totalRecord" (reloadDataSourcePaginate)="changePaginate($event)"></app-subjects-list-moet>
        <app-subjects-list-customer *ngIf="tab === 'other'" [pageIndex]="pageIndex" (reloadDataSource)="reloadData($event)" [dataSource]="data" [countData]="totalRecord" (reloadDataSourcePaginate)="changePaginate($event)"></app-subjects-list-customer>
    </div>
</div>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

