import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

const DATATEST = [
  {"id": "1","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 2},
  {"id": "2","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 3},
  {"id": "3","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 1},
  {"id": "4","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 5},
  {"id": "5","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 6},
  {"id": "6","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 4},
  {"id": "7","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 5},
  {"id": "8","name": "Sở giáo dục Hà Nội","userCode": 'OMT0791',"domain": "SGDhn.com.vn","phoneNumber": "02517301619","email": "info@omt.edu.vn","status": 3},
];
@Component({
  selector: 'app-new-customer-list',
  templateUrl: './new-customer-list.component.html',
  styleUrls: ['./new-customer-list.component.scss']
})
export class NewCustomerListComponent implements OnInit {
  displayedColumns: string[] = ['stt', 'name', 'code', 'domain', 'phone','email','status','tool'];
  dataSource:any;
  dataListCustomers:any = DATATEST;
  //data-test status
  dataStatus =[
    {id:1, status: "Chưa kích hoạt", isChecked: false},
    {id:2, status: "Đang sử dụng", isChecked: false},
    {id:3, status: "Hết hạn sử dụng", isChecked: false},
    {id:4, status: "Hết hạn dùng thử", isChecked: false},
    {id:5, status: "Dùng thử",isChecked: false},
    {id:6, status: "Khác", isChecked: false},
  ];
  isColorOption:number;
  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(DATATEST);
  }

   // change select
 changeOption(event, id:number){
  this.isColorOption= event.target.value;
  let list = document.querySelectorAll("#select-status"); 
  for(let i=1; i<this.dataListCustomers.length+1;i++){
    if(i==id) {
      list[i-1].className='';
      list[i-1].classList.add(`bg-color-status-${this.isColorOption}`);
    }
  }
 }


}
