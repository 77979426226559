import {MatTableDataSource} from '@angular/material/table';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
// import {trainingLevel} from '../../lib';
import {SubjectService} from '@app/_services';
@Component({
  selector: 'app-dialog-subject-list-grades',
  templateUrl: './dialog-subject-list-grades.component.html',
  styleUrls: ['./dialog-subject-list-grades.component.scss']
})
export class DialogSubjectListGradesComponent implements OnInit {
  selectGrade = [
    {id: 1, fullName: 'Mầm Non'},
    {id: 2, fullName: 'Tiểu học'},
    {id: 3, fullName: 'Trung học cơ sở'},
    {id: 4, fullName: 'Trung học phổ thông'},
    {id: 99, fullName: 'Khác'},
  ];
  keyword = '';
  dataSource = [];
  displayedColumns = ['stt', 'fullName', 'code', 'trainingLevel'];
  // trainingLevel = trainingLevel.dataTraining();
  pageIndex = 1;
  length;
  isLoading = false;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private subjectService: SubjectService,
  ) { }

  ngOnInit(): void {
    console.log(this.data.infoSubject);
    this.loadDataItems();
  }

  handlePage(event){
    this.pageIndex = event.pageIndex + 1;
    this.loadDataItems();
  }

  loadDataItems(){
    this.isLoading = true;
    this.subjectService.getListGradeBySubject(this.data.infoSubject.id, this.keyword).subscribe((ref: any) => {
      ref.data.forEach(i => {
        i.trainingLevel = this.selectGrade.find(a => a.id === i.trainingLevel).fullName;
      });
      this.dataSource = ref.data;
      this.length = ref.data.length;
      this.isLoading = false;
    });
  }

  blurSearch(value){
    this.keyword = value;
    this.loadDataItems();
  }

  keyPressSearch(event){
    if (event.code === 'Enter'){
      this.keyword = event.target.value;
      this.loadDataItems();
    }
  }
}
