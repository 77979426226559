import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import {NgxPermissionsService} from 'ngx-permissions';
// import { SharedModule } from '@app/_shared/shared.module';
import { DATA_FULL_PERMISSION_MODULE, MESSAGE_ERROR_CALL_API } from '@app/_shared/utils/constant';
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
import { ShowMessage } from '@app/_services/index';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  permissionRole = DATA_FULL_PERMISSION_MODULE;
  constructor(
    private router: Router, 
    private permissionsService: NgxPermissionsService,
    private curriculumManagerService: CurriculumManagerService,
    private showMessage: ShowMessage,
    ) {}

  menu:string = 'grades-manager';
  activeMenuCurriculum:any;
  activeSubMenuSubject:any;
  listSubjects = [];
  trainningLevel:any = [
    {id: 1, name: 'Mầm non', arrSubjects: []},
    {id: 2, name: 'Tiểu học', arrSubjects: []},
    {id: 3, name: 'Trung học cơ sở', arrSubjects: []},
    {id: 4, name: 'Trung học phổ thông', arrSubjects: []},
    {id: 99, name: 'Khác', arrSubjects: []},
  ]

  ngOnInit() {
    // if (this.permissionsService.getPermission(this.permissionRole) || this.permissionsService.getPermission(this.permissionRole.perm5) || this.permissionsService.getPermission(this.permissionRole.perm3)){
    //   this.displayedColumns.push('tool');
    // }
    this.trainningLevel.forEach((element) => {
      this.getAllSubjects(element.id);
    });
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          // end of loading paegs
          if(event.url.search('curriculum') != -1) {
            this.activeMenuCurriculum = true;
          } else this.activeMenuCurriculum = false;
        }
    });
  }

  activeMenu(menu: string){
    this.menu = menu;
    this.router.navigate([menu]);
  }
  
  showMenu() {
    let sidebar_el = document.querySelector('.sidebar-el');
    sidebar_el[0].classList.add('isShow');
  }

  getAllSubjects(level) {
    let dataInput = {
      keyword: "",
      trainingLevel: level
    }
    this.curriculumManagerService.getSubjectByLevelTraining(dataInput).subscribe((res: any) => {
      if(res.status == 1) {
        this.listSubjects = res.data;
        this.trainningLevel.find(el => el.id == level).arrSubjects = res.data;
      } else {
        this.showMessage.error(res.msg);
      }
      
    }, (err:any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  openLinkCurriculum(value) {
    // this.activeMenuCurriculum = `curriculum/list/${value.id}`;
    this.activeMenuCurriculum = true;
    this.activeSubMenuSubject = value.id;
    this.router.navigate(
      [`curriculum/list/${value.id}`], 
      {queryParams: {subjectName: value?.name}}
    );
  }

  removeActiveSubMenu() {
    this.activeSubMenuSubject = '';
  }

}
