import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {ForgotPasswordService} from '@app/_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShowMessage} from '@app/_services';
import * as moment from 'moment';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {
  public checkStatus = true;
  public message: string;
  isOverTime = false;
  overTime;
  countDownTimer
  username;
  isSpace = false;
  public verifyAccount = this.fb.group({
    code: ['', Validators.required],
  });
  constructor(
      private fb: FormBuilder,
      private route: Router,
      private forgotPasswordService: ForgotPasswordService,
      private _snackBar: MatSnackBar,
      private showMessage: ShowMessage,
      private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getParamUrl();
  }



  getParamUrl(){
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.username = res.username;
      if (res.username === '') return this.username = null;
      this.forgotPasswordService.checkResetCode(res.username).subscribe((res: any) => {
        if (res.status === 0) {
          this.showMessage.error(res.msg);
          return this.route.navigate(['forgot-password']);
        }

        if(res.status === 1){
          if (res.data && res.data.resendAfterTime !== 0){
            this.isOverTime = true;
            return this.getTime(res.data.resendAfterTime, res.data.timeNow);
          }
          this.isOverTime = false;
        }else {
          this.isOverTime = false;
        }
      });
    });
  }

  sentCode() {
    this.message = ''
    this.isOverTime = false;

    this.forgotPasswordService.resetPassword(this.username).subscribe((res: any) => {
      if (res.status == 1 ) {
        this.showMessage.success(res.msg);
        return this.getParamUrl()
      }
      this.message = res.msg;
    }, error => {
      // this.showMessage.error(error);
    });
  }

  confirmCode(data) {
    this.forgotPasswordService.verifyCode(this.username, data.code).subscribe((res: any) => {
      if (res.status == 1) return this.route.navigate(['setup-new-password', res.data]);

      return this.message = res.msg;
    }, error => {
      this.showMessage.error(error)
      return this.message = error.message;
    });
  }

  turnOffMessage(code){
    this.message = '';
    this.isSpace = false;
    if(code.trim() === '') this.isSpace = true;
  }

  parseSeconds(param){
    return parseInt(moment(param*1000).utc().format('mm'))*60 + parseInt(moment(param*1000).utc().format('ss'));
  }

  getTime(resendAfterTime, timeNow){
    let secondNow = this.parseSeconds(timeNow);
    let secondServer = this.parseSeconds(resendAfterTime);
    let timeRemaining = secondServer - secondNow;
    clearInterval(this.countDownTimer);
    if (timeRemaining > 0){
      this.isOverTime = true;
      if (this.isOverTime === true) this.timeUp(timeRemaining);
      setTimeout(() => {
        this.isOverTime = false;
        this.overTime = '';
      },timeRemaining*1000);
    }
  }

  timeUp(duration: number) {
    let timer = duration, minutes, seconds;
    this.countDownTimer = setInterval(() => {
      minutes = parseInt(String(timer / 60), 10)
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.overTime = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }
}
