<div class="wrapper">
  <div class="title">
    <h3>Danh sách cấu hình Elsa</h3>
    <button (click)="add()" class="btn">Thêm</button>
  </div>
  <!--  danh sach  -->
  <table class="table table-bordered table-el">
    <thead style="background: #EEEEEE;">
      <tr>
        <th scope="col" class="text-center text-table" style="width: 5%;">STT</th>
        <th scope="col" class="text-table" style="width: 28%;">Tên khách hàng</th>
        <th scope="col" class="text-table" style="width: 26%;">ĐVND/ĐVGD</th>
        <th scope="col" class="text-table" style="width: 9%;">Kích hoạt</th>
        <th scope="col" class="text-table" style="width: 20%;">Api key</th>
        <th scope="col" class="text-table" style="width: 8%;"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of items.controls; let i=index">
        <tr [formGroup]="item">
          <!-- stt -->
          <td scope="row" class="text-center stt">{{i+1}}</td>

          <!-- ten khach hang -->
          <td>
            <select class="form-control" #tenant formControlName="tenantId"
              [attr.disabled]="item.get('disableControl').value ? '' : null">
              <option value="" [disabled]="item.get('tenantId').value !=''">Chọn khách hàng</option>
              <ng-container *ngFor="let tenant of listTenants">
                <option [value]="tenant.id">{{tenant.fullname}}</option>
              </ng-container>
            </select>
          </td>

          <!-- moet unit -->
          <td>
              <input formControlName="moetName" class="form-control moet-id" readOnly (click)="chooseOption(i)" 
              [attr.disabled]="item.get('disableControl').value ? '' : null">
          </td>

          <!-- trang thai -->
          <td class="text-center stt">
            <mat-checkbox [checked]="item.get('status').value" [disabled]="item.get('disableControl').value" formControlName="status">Kích hoạt</mat-checkbox>
          </td>

          <!-- api key -->
          <td>
            <input [readOnly]="item.get('disableControl').value" class="form-control" type="text"
              placeholder="Nhập api key" formControlName="api_key">
          </td>

          <!-- tool -->
          <td class="text-center stt align-middle">
            <ng-container>
              <img src="assets/images/svg/icon-tich.svg" (click)="submit(i, item)" style="cursor: pointer; width: 20px; height: 20px;" 
              *ngIf="!item.get('disableControl').value">
              <img src="assets/images/svg/icon-close-circle.svg" (click)="clear(i)" style="cursor: pointer; width: 20px; height: 20px;" 
              *ngIf="!item.get('disableControl').value &&  !item.get('isCanEditControl').value && isCreate" class="ml-2">
              <img src="assets/images/svg/remove-icon2.svg" (click)="delete(i, item.get('id').value)" class="mr-2" style="cursor: pointer" 
              *ngIf="item.get('disableControl').value">
              <img src="assets/images/svg/icon-edit.svg" (click)="update(i)" style="cursor: pointer" *ngIf="item.get('disableControl').value">
            </ng-container>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="items.controls.length == 0" class="text-no-data">
        <td colspan="6">Không có dữ liệu</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>