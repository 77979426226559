<form [formGroup]="curriculumForm" *ngIf="!isLoading" style="background-color: #fff; height: 100%;">
  <p class="form__title" mat-dialog-title>Thông tin khung chương trình
    <span class="icon-close" mat-dialog-close><img src="assets/images/icons/Vector-24.png"></span>
  </p>
  <hr>
  <mat-dialog-content class="form__content">
    <div class="row">
      <div class="form-group col-md-6 row row-global group-field">
        <label class="col-md-3 lb-global lb-global-form">Mã chương trình <span class="text-danger">(*)</span></label>
        <div class="col-md-8 pl-0 pr-0">
          <input class="form-control input-field-global custom-background-input"
            placeholder="Nhập mã khung chương trình" formControlName="code"
            [readOnly]="dataDialog.nameForm == 'update'">
          <ng-container *ngFor="let validate of messageValidate.code">
            <p class="error-message"
              *ngIf="curriculumForm.get('code').hasError(validate.type) && (curriculumForm.get('code').touched || curriculumForm.get('code').dirty)">
              {{validate.message}}
            </p>
          </ng-container>
        </div>

      </div>
      <div class="form-group col-md-6 row row-global group-field">
        <label class="col-md-2 lb-global lb-global-form">Môn <span class="text-danger">(*)</span></label>
        <div class="col-md-10 pl-0 pr-0">
          <span
            class="form-control input-field-global custom-background-input">{{this.dataDialog.data?.subjectName}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6 row row-global group-field">
        <label class="col-md-3 lb-global lb-global-form">Tên chương trình <span class="text-danger">(*)</span></label>
        <div class="col-md-8 pl-0 pr-0">
          <input class="form-control input-field-global custom-background-input"
            placeholder="Nhập tên khung chương trình" formControlName="name">
          <ng-container *ngFor="let validate of messageValidate.name">
            <p class="error-message"
              *ngIf="curriculumForm.get('name').hasError(validate.type) && (curriculumForm.get('name').touched || curriculumForm.get('name').dirty)">
              {{validate.message}}
            </p>
          </ng-container>
        </div>
      </div>
      <div class="form-group col-md-6 row row-global group-field" fxLayout="row">
        <label class="col-md-2 lb-global lb-global-form">Khối <span class="text-danger">(*)</span></label>
        <div class="col-md-10 pl-0 pr-0">
          <select class="form-control input-field-global custom-background-input" formControlName="grade"
            (change)="chooseOption($event, 'grade')" *ngIf="dataDialog.nameForm == 'create'">
            <option value="null"> -- Chọn khối học -- </option>
            <ng-container *ngFor="let item of listGradesBySubject">
              <option [value]="item.id">{{item.fullname}}</option>
            </ng-container>
          </select>
          <span class="form-control input-field-global custom-background-input"
            *ngIf="dataDialog.nameForm == 'update'">{{this.dataDialog.data?.gradeName}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6 row row-global group-field" *ngIf="dataDialog.nameForm == 'create'">
        <label class="col-md-3 lb-global lb-global-form">Năm học<span class="text-danger">(*)</span></label>
        <div class="col-md-8 pl-0 pr-0">
          <select class="form-control input-field-global custom-background-input" formControlName="schoolYear"
            (change)="chooseOption($event, 'shoolYear')">
            <option value="null"> -- Chọn năm học -- </option>
            <ng-container *ngFor="let item of listShoolYear">
              <option [value]="item.id">{{item.name}}</option>
            </ng-container>
          </select>
          <!-- <span class="form-control input-field-global custom-background-input"  *ngIf="dataDialog.nameForm == 'update'">{{this.dataDialog.data?.schoolYears[0].name}}</span> -->
        </div>
      </div>
      <!-- <div class="col-md-6 col-lg-6" style="padding-left: 15px; font-size: 16px; font-weight: 400;">
                <mat-checkbox [checked]="true" formControlName="status">Áp dụng cho thời điểm hiện tại</mat-checkbox>
            </div> -->
    </div>
  </mat-dialog-content>

  <hr>
  <div mat-dialog-action style="display: flex; justify-content: flex-end;" class="form__footer">
    <button class="btn btn-custom-cancel-accept btn-cancel" mat-dialog-close>Huỷ</button>
    <button class="btn btn-custom-cancel-accept btn-accept ml-2" appDebounceClick
      (debounceClick)="onSubmit(curriculumForm.value, curriculumForm.valid)">Lưu</button>
  </div>
</form>