import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services/authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PersonalService {

    constructor(private http: HttpClient) { }

    getInfoUserByAccessToken(accessToken){
        return this.http.post(`${environment.authApiUrl}/api/personal/detail`, {accessToken});
    }

    getPostOfPersonal(KeyWord: string, FromDateNum: number, ToDateNum: number, StartIndex: number, PageSize: number){
        return this.http.get(`${environment.authApiUrl}/api/notification`);
    }
}
