import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getDataNotification(limit: number, page: number){
    return this.http.get(`${environment.authApiUrl}/api/notification?StartIndex=${page}&PageSize=${limit}`);
  }

  filterNotification(limit: number, page: number,KeyWord:string,fromDate:number,toDate:number){
    return this.http.get(`${environment.authApiUrl}/api/notification?KeyWord=${KeyWord}&FromDateNum=${fromDate}&ToDateNum=${toDate}&StartIndex=${page}&PageSize=${limit}`);
  }

  checkAllNotificationRead(data:any){
    return this.http.post(`${environment.authApiUrl}/api/notification/mark-read`,data);
  }

}


