import { Component, OnInit, Inject, NgModule} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {ModulesManagerService} from '@app/_services';
import {MatTableDataSource} from '@angular/material/table';
import * as $ from 'jquery';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {MatDialog} from '@angular/material/dialog';
import {ShowMessage} from "@app/_services";
import * as _ from 'lodash';

@Component({
  selector: 'app-dialog-add-customer',
  templateUrl: './dialog-add-customer.component.html',
  styleUrls: ['./dialog-add-customer.component.scss']
})
export class DialogAddCustomerComponent implements OnInit {
  dataColumn = ['stt', 'fullName', 'code','domain', 'phone', 'email'];
  dataSource = [];
  checkAll: boolean;
  checkStatus: boolean;
  keyword = '';
  arrayTenant = [];
  dataTemp:any;
  countCheck:number = 0;
  isChecked = false;
  isLoading:boolean = false;

  constructor(private dialogRes: MatDialogRef<DialogAddCustomerComponent>,
              private modulesManagerService: ModulesManagerService,
              @Inject(MAT_DIALOG_DATA) private data,
              private fb: FormBuilder,
              private showMessage: ShowMessage) {
  }


  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(){
    this.isLoading = true;
    let data = [];
    this.modulesManagerService.lisTenantNotBelongModule(this.data.moduleId, this.keyword).subscribe((res: any) => {
      res.data.forEach(item => {
        item.is_active = false;
        data.push(item);
      });
      this.dataSource = data;
      this.dataTemp = res.data;
      this.isLoading = false;
    },
    (err)=>{
      this.isLoading = false;
    });
  }

  checkCheckBox(event, idTenant) {
    if(event.checked){
      this.arrayTenant.push(idTenant);
      this.countCheck++;
    }else{
      this.isChecked = false;
      const index = this.arrayTenant.indexOf(idTenant);
      if (index > -1) {
        this.arrayTenant.splice(index, 1);
      }
      this.countCheck--;
    }
  }

  checkAllCheckBox(event) {
    this.isChecked = event.checked;
    if(event.checked){
      this.dataTemp.forEach(element => {
        if(this.arrayTenant.indexOf(element.id) == -1){
          this.arrayTenant.push(element.id);
        }
      });
      this.countCheck = this.arrayTenant.length;
    }else{
      this.arrayTenant = [];
      this.countCheck = 0;
    }
  }

  getTenantIds(){
    const tenantIds = [];
    const matCheckboxChecked = $('#customer').find('.mat-checkbox-checked');
    if (matCheckboxChecked.length > 0) {
      matCheckboxChecked.each((index, element) => {
        tenantIds.push($(element).find('input')[0].attributes[4].value);
      });
    }
    return tenantIds;
  }

  blurSearch(value){
    this.keyword = value;
    this.loadDataItems();
  }

  clickSearch(event,value){
    if(event.code == "Enter" || event.code == "Tab"){
      this.isLoading = true;
      this.keyword = value;
      this.loadDataItems();
    }
  }

  onSubmit() {
    const tenantIds = this.getTenantIds();
    if (this.countCheck === 0) return this.showMessage.warring('Bạn chưa chọn khách hàng !!!');
    this.dialogRes.close({moduleId: this.data.moduleId, tenantIds});
  }

}


