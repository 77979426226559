import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ItemNode } from '@app/_modules/curriculum/tabs/tab-content-curriculum/tab-content-curriculum.component';
import { GeneralConfigService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { MESSAGE_ERROR_CALL_API, TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { Subscriber } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-tab-config-sms',
  templateUrl: './tab-config-sms.component.html',
  styleUrls: ['./tab-config-sms.component.scss']
})
export class TabConfigSMSComponent implements OnInit {
  isLoading: boolean = false;
  keyword: string = '';
  listConfig: Array<any> = [];
  isChecked: boolean;

  constructor(
    public dialog: MatDialog,
    private generalConfigService: GeneralConfigService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,
  ) { }

  ngOnInit(): void {
    this.getListConfigSMS();
  }

  changeActive(value) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '35%',
      data: {
        title: 'Thay đổi trạng thái cấu hình sms',
        message: value.isUsed == 0 ? 'Bạn có chắc muốn kích hoạt cấu hình sms của khách hàng này không ?' : 'Bạn có chắc muốn hủy kích hoạt cấu hình sms của khách hàng này không ?',
        btnOkText: 'TIẾP TỤC',
        btnCancelText: 'HỦY',
        data: {
          name: value.tenantName
        }
      }
    })
    dialogRef.afterClosed().subscribe((ref: any) => {
      if (ref && ref != undefined) {
        let dataInput = {
          tenantId: value.tenantId,
          isUsed: Number(!value.isUsed),
        }
        this.listenFireBase('update_send_sms_forgot_password_config', value);
        this.setTimOutFireBase();
        this.generalConfigService.changeActiveSMS(dataInput).subscribe((res: any) => {
          if (res.status == 0) {
            this.showMessage.error(res.msg);
          }
        }, (err: any) => {
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      } else {
        let index = this.listConfig.findIndex(item => item.tenantId == value.tenantId);
        if (index != -1) {
          this.listConfig[index].isDisplayActive = !!this.listConfig[index].isUsed;
        }
      }
    });
  }

  // lấy danh sách cấu hình
  getListConfigSMS() {
    this.isLoading = true;
    this.generalConfigService.getListConfigSMS().subscribe((res: any) => {
      if(res.status == 1) {
        this.isLoading = false;
        this.listConfig = res.data;
        this.listConfig.forEach(item => {
          item['isDisplayActive'] = item.isUsed == 1 ? true : false;
        });
      } else {
        this.isLoading = false;
        this.showMessage.error(res.msg);
      }
    }, (err: any) => {
      this.isLoading = false;
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    })
  }

  listenFireBase(action, value) {
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(action, 'admin_config', subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref === true) {
        this.isLoading = false;
        this.getListConfigSMS();
      } else {
        this.isLoading = false;
        let index = this.listConfig.findIndex(item => item.tenantId == value.tenantId);
        if (index != -1) {
          this.listConfig[index].isDisplayActive = !!this.listConfig[index].isUsed;
        }
      }
    });
  }

  setTimOutFireBase() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
}
