<div fxLayout="column" class="bg-content-global ">
    <div fxLayout="row" fxLayoutAlign="space-between start" style="height: 40px">
        <div>
            <p class="title-page-global title-custom">Phân hệ</p>
        </div>
        <div class="div-right">
            <div class="search">
                <input type="text" #search id="search__input" class="text-left btn btn-default" class="{{showSearch == true ? 'transition-search-show' :'transition-search-hide'}}"
                    (keydown)="keyPressSearch($event, search.value)"
                    placeholder="Tên, mã phân hệ"
                    >
                <span class="search__icon" (click)="toggleClass()" class="{{showSearch == true ? 'active-filter' : ''}}"><img src="assets/images/svg/search.svg" alt=""></span>
            </div>
            <ng-template ngxPermissionsOnly="{{permissionModules.perm2}}">
                <button class="btn btn-add-edit" (click)="openDialogAddSoftwareModule()">
                    <img src="assets/images/svg/add.svg">
                    Thêm phân hệ
                </button>
            </ng-template>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex class="bg-table-global content-data" style="height: auto; overflow-y: auto">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 5%">STT</th>
                <td mat-cell *matCellDef="let element, let i = index">{{(pageSize * pageIndex) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Tên phân hệ</th>
                <td mat-cell *matCellDef="let element" class="text-important-table-global">
                    <ng-template ngxPermissionsOnly="{{permissionModules.perm3}}">
                        <a href="module-software-manager/detail/{{element.id}}">
                            {{element.fullname | substring : 30}}
                        </a>
                    </ng-template>
                    <ng-template ngxPermissionsExcept="{{permissionModules.perm3}}">
                        <a>
                            {{element.fullname | substring : 30}}
                        </a>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 15%">Mã phân hệ</th>
                <td mat-cell *matCellDef="let element">{{element.code | substring: 30}}</td>
            </ng-container>
            <ng-container matColumnDef="user_count">
                <th mat-header-cell *matHeaderCellDef style="width: 15%">Khách hàng</th>
                <td mat-cell *matCellDef="let element">
                    <ng-template ngxPermissionsOnly="{{permissionModules.perm6}}">
                        <span class="show-more" (click)="showListUserSoftware(element)">{{element.tenantCount}}</span>
                    </ng-template>
                    <ng-template ngxPermissionsExcept="{{permissionModules.perm6}}">
                        <span class="show-more" >{{element.tenantCount}}</span>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="is_active">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">Trạng thái</th>
                <td mat-cell *matCellDef="let element">
                    <ng-template ngxPermissionsOnly="{{permissionModules.perm4}}">
                        <mat-checkbox (change)="changeStatusSubSystem($event, element)" [checked]="element.status === 1 ? true: false"></mat-checkbox> Kích hoạt
                    </ng-template>
                    <ng-template [ngxPermissionsExcept]="[permissionModules.perm4]" >
                        <mat-checkbox [checked]="element.status === 1 ? true: false" disabled></mat-checkbox> Kích hoạt
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="tool" >
                <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-right">Công cụ</th>
                <td mat-cell *matCellDef="let element" class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <ng-template ngxPermissionsOnly="{{permissionModules.perm4}}">
                            <button mat-menu-item  (click)="dialogUpdateSubSystem(element)">Chỉnh sửa</button>
                        </ng-template>
                        <ng-template ngxPermissionsOnly="{{permissionModules.perm5}}">
                            <button mat-menu-item class="text-danger" (click)="confirmDelete(element)">Xóa</button>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
            <tr mat-row *matRowDef="let row, columns: dataColumn"></tr>
        </table>
        <mat-paginator *ngIf="totalRecord > pageSize" appStylePaginator class="table-paginate-global" [length]="totalRecord" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
    </div>
</div>


