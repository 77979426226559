export const REGEX_PHONE = /^(((0|0084|\+84)(3[2|3|4|5|6|7|8|9]|5[6|8|9]|7[0|6|7|8|9]|8[1|2|3|4|5|6|7|8|9]|9[0|1|2|3|4|6|7|8|9])([0-9]{7})|((1900|1800)([0-9]{4}))|((1900|1800)([0-9]{6}))|((02)([0-9]{9}))))$/;
export const REGEX_FULL_NAME = /^([0-9]*)([a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý\s\w|\_|\-|\[|\]|\(|\)]*)$/;
export const REGEX_DOMAIN = /^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const REGEX_CODE = /^([0-9]*)([a-zA-Z\S|\_|\-]*)$/;
export const REGEX_USER_NAME = /^[a-z0-9\\_]+$/;
export const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PASSWORD=/^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;
export const TIME_OUT_LISTEN_FIREBASE = 10000;
export const MESSAGE_ERROR_CALL_API = 'Có lỗi xảy ra trong quá trình xử lý';
export const DATA_FULL_PERMISSION = [
    "core_admin_tenants",
    "core_admin_tenant_add",
    "core_admin_tenant_edit",
    "core_admin_tenant_detail",
    "core_admin_tenant_change_admin_password",

    "core_admin_tenant_subject",
    "core_admin_tenant_subject_update",
    "core_admin_tenant_subject_delete",

    "core_admin_tenant_grades",
    "core_admin_tenant_grade_update",
    "core_admin_tenant_grade_delete",

    "core_admin_tenant_school_years",
    "core_admin_tenant_school_year_update",
    "core_admin_tenant_school_year_delete",

    "core_admin_tenant_subsystems",
    "core_admin_tenant_subsystem_update",
    "core_admin_tenant_subsystem_delete",

    "core_admin_tenant_modules",
    "core_admin_tenant_module_update",
    "core_admin_tenant_module_delete",

    "core_admin_tenant_service",
    "core_admin_tenant_delete",

    "core_admin_subsystems",
    "core_admin_subsystem_add",
    "core_admin_subsystem_detail",
    "core_admin_subsystem_edit",
    "core_admin_subsystem_delete",
    "core_admin_subsystem_tenants",
    "core_admin_subsystem_tenant_add",
    "core_admin_subsystem_tenant_delete",

    "core_admin_grades",
    "core_admin_grade_add",
    "core_admin_grade_detail",
    "core_admin_grade_edit",
    "core_admin_grade_delete",

    "core_admin_grade_tenants",
    "core_admin_grade_tenant_add",
    "core_admin_grade_tenant_delete",

    "core_admin_grade_subjects",
    "core_admin_grade_subject_add",
    "core_admin_grade_subject_delete",

    "core_admin_subjects",
    "core_admin_subject_add",
    "core_admin_subject_detail",
    "core_admin_subject_edit",
    "core_admin_subject_delete",
    "core_admin_subject_import",

    "core_admin_subject_tenants",
    "core_admin_subject_tenant_add",
    "core_admin_subject_tenant_delete",

    "core_admin_subject_grades",
    "core_admin_subject_grade_add",
    "core_admin_subject_grade_delete",

    "core_admin_school_years",
    "core_admin_school_year_add",
    "core_admin_school_year_detail",
    "core_admin_school_year_edit",
    "core_admin_school_year_delete",

    "core_admin_school_year_tenants",
    "core_admin_school_year_tenant_add",
    "core_admin_school_year_tenant_delete",

    "core_admin_moet_units",
    "core_admin_moet_unit_add",
    "core_admin_moet_unit_detail",
    "core_admin_moet_unit_edit",
    "core_admin_moet_unit_delete",
    "core_admin_moet_unit_move",
    "core_admin_moet_unit_import",

    "core_admin_locations",
    "core_admin_location_add",
    "core_admin_location_detail",
    "core_admin_location_edit",
    "core_admin_location_delete",
    "core_admin_location_move",
    "core_admin_location_import",

    "core_admin_posts",
    "core_admin_post_add",
    "core_admin_post_detail",
    "core_admin_post_edit",
    "core_admin_post_delete",
    "core_admin_post_hide",
    "core_admin_post_resend",
    "core_admin_post_cancel",
    "core_admin_post_receivers",

    "core_admin_post_comments",
    "core_admin_post_comment_add",
    "core_admin_post_comment_delete",

    "core_admin_config_system",
    "core_admin_config_system_security",
    "core_admin_config_system_email_server",
    "core_admin_config_system_introduction",
    "core_admin_config_system_privacy_policy",
    "core_admin_config_system_term_of_service",
    "core_admin_config_system_alert",
    "core_admin_config_send_sms",
    "core_admin_config_elsa_tenant",

    "core_admin_users",
    "core_admin_user_add",
    "core_admin_user_detail",
    "core_admin_user_edit",
    "core_admin_user_delete",
    "core_admin_user_import",
    "core_admin_user_export",
    "core_admin_user_log_histories",
    "core_admin_user_reset_password",

    "core_admin_user_tenants",
    "core_admin_user_tenant_add",
    "core_admin_user_tenant_delete",

    "core_admin_user_roles",
    "core_admin_user_role_add",
    "core_admin_user_role_delete",

    "core_admin_modules",
    "core_admin_module_add",
    "core_admin_module_detail",
    "core_admin_module_edit",
    "core_admin_module_delete",

    "core_admin_module_tenants",
    "core_admin_module_tenant_add",
    "core_admin_module_tenant_delete",

    "core_admin_module_permissions",
    "core_admin_module_permission_add",
    "core_admin_module_permission_edit",
    "core_admin_module_permission_delete",

    "core_admin_roles",
    "core_admin_role_add",
    "core_admin_role_detail",
    "core_admin_role_edit",
    "core_admin_role_delete",

    "core_admin_role_users",
    "core_admin_role_user_add",
    "core_admin_role_user_delete",

    "core_admin_role_permissions",
    "core_admin_role_permission_update",
];

export let DATA_FULL_PERMISSION_MODULE = [
    "module_core_role",
    "module_core_admin_user",
    "module_core_admin_subsystem"
    ,"module_core_module",
    "module_core_admin_config",
    "module_core_admin_post",
    "module_core_admin_location",
    "module_core_admin_moet_unit",
    "module_core_admin_school_year",
    "module_core_admin_subject",
    "module_core_admin_grade",
    "module_core_admin_tenant",
    "module_admin_grade",
    "module_admin_subject",
    "module_admin_school_year",
    "module_admin_post",
    "module_admin_user",
    "module_role",
    "module_book_set",
    "module_education_unit",
    "module_teacher",
    "module_student",
    "module_parent",
    "module_set_of_exam"
];
