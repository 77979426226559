<h2 mat-dialog-title class="title-page-global text-center">Thêm người dùng</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row input-search-dialog-list">
            <div class="col-sm-12">
                <input type="text" [autofocus]="false" class="form-control input-field-global"
                       placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email" id="search-value-dialog"
                       (keydown)="checkText($event, searchDialogList.value)" #searchDialogList >
                <img src="assets/images/icons/icon-23.svg">
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-12" fxLayout="row">
                <div class="text-select-all">
                    <mat-checkbox id="check-all" [checked]="checkAll" (change)="checkAllCheckBox($event)"></mat-checkbox> Chọn tất cả</div>
                <div class="ml-5 text-select-all">Đã chọn: {{length}} người dùng mới</div>
            </div>
        </div>
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <form class="row" id="customer" style="z-index: 1;">
            <table mat-table [dataSource]="dataSource" class="table-el">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                    <td mat-cell *matCellDef="let item; let i = index">
                        <mat-checkbox class="check-box" value="{{item.id}}" [checked]="item.status" (change)="checkCheckBox($event, item.id)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">Người dùng</th>
                    <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullName}}</td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                    <td mat-cell *matCellDef="let item">{{item.userCode}}</td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                    <td mat-cell *matCellDef="let item">{{item.phone}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                    <td mat-cell *matCellDef="let item">{{item.email}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
            </table>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close mat-raised-button class="btn-cancel mat-elevation-z">HỦY</button>
    <button type="submit" class="btn btn-add-edit ml-2 px-4 mat-elevation-z" mat-raised-button (click)="onSubmit()">LƯU</button>
</mat-dialog-actions>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
