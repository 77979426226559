<mat-dialog-content class="mat-typography">
    <div class="content">
        <span class="title">{{lang.form.label.title_info_basic}}</span>
        <div class="row space">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 lb-global">{{lang.form.label.full_name}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input disabled type="text" class="form-control input-field-global"
                           placeholder="{{lang.form.placeholder.full_name}}" [value]="infoSchoolYear.fullname">
                </div>
            </div>
            <div class="form-group col-md-6 ml-auto row row-global">
                <label class="col-md-3 offset-1 lb-global">{{lang.form.label.code}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input disabled type="text" class="form-control input-field-global" [value]="infoSchoolYear.code"
                           placeholder="{{lang.form.placeholder.code}}">

                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 lb-global">{{lang.form.label.start_date}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input disabled type="text" class="form-control input-field-global" [value]="parseTime(infoSchoolYear.start_date)">
                    <img src="assets/images/svg/lich.svg" alt="" class="icon-calendar">
                </div>
            </div>
            <div class="form-group col-md-6 ml-auto row row-global">
                <label class="col-md-3 offset-1 lb-global">{{lang.form.label.end_date}}</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input disabled type="text" class="form-control input-field-global" [value]="parseTime(infoSchoolYear.end_date)">
                    <img src="assets/images/svg/lich.svg" alt="" class="icon-calendar">
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
