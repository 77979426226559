<div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<!-- Mô tả module -->
<div class="panel-content-global">
    <div class="panel-header col-log-6"
                                style="margin-left: 0; padding-left: 0">

        <div class="panel-title">{{lang.label.system_intro}}</div>
</div>
    <div class="row ml-0">
        <div class="form-group col-md-12 row row-global pl-0">
            <div class="col-md-12 pl-0 pr-0" *ngIf="isLoading === false">
                <ckeditor [editor]="Editor" data="{{content}}" [(ngModel)]="content" #intro></ckeditor>
            </div>
            <!-- <html><math xmlns="http://www.w3.org/1998/Math/MathML"><mstyle mathsize="18px"><mi>f</mi><mfenced><mi>x</mi></mfenced><mo>=</mo><msup><mi>x</mi><mn>2</mn></msup></mstyle></math></html> -->
            {{content | json}}
            <div [innerHTML]="content" ></div>
<img class="Wirisformula" style="max-width:none;vertical-align:-4px;" role="math" 
alt="open parentheses open square brackets open curly brackets 2313 close curly brackets close square brackets close parentheses to the power of blank" height="22" width="75" 
data-mathml="«math xmlns=¨http://www.w3.org/1998/Math/MathML¨»«msup»«mfenced»«mfenced open=¨[¨ close=¨]¨»«mfenced open=¨{¨ close=¨}¨»«mn»2313«/mn»«/mfenced»«/mfenced»«/mfenced»«mrow»§#160;«/mrow»«/msup»«/math»" align="middle">
        </div>
    </div>
</div>
<br>
<!-- Mô tả module -->
<div fxLayout="row" fxLayoutAlign="center end">
    <button class="btn custom-btn custom-btn-close" (click)="cancel()">HỦY</button>&nbsp;&nbsp;
    <button class="btn custom-btn custom-btn-add" (click)="onSubmit()">LƯU</button>
</div>
