import {Component, OnInit, Inject, Input} from '@angular/core';
import {FormBuilder, Validators, FormControl, AbstractControl, FormGroup, FormArray} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {ModulesManagerService} from '@app/_services';
import {ShowMessage, Base64Service} from '@app/_services';
import * as _moment from 'moment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Observable, Subscriber } from 'rxjs';

@Component({
    selector: 'app-dialog-form-module',
    templateUrl: './dialog-form-module.component.html',
    styleUrls: ['./dialog-form-module.component.scss'],
})

export class DialogFormModuleComponent implements OnInit {
    infoModel: FormGroup;
    checkValidate = false;
    urlImage: SafeResourceUrl;
    selectedFile: File;
    items = [];
    convertImg = null;
    fileName = '';
    isLoadingResults = false;
    resErrorCode = '';
    messageValidate = {
        fullname: [
            { type: 'required', message: "Bạn chưa nhập tên module" },
            { type: 'minlength', message: "Độ dài tên module từ 2-250 ký tự" },
            { type: 'maxlength', message: "Độ dài tên module từ 2-250 ký tự" }
        ],
        code: [
            { type: 'required', message: "Bạn chưa nhập mã module" },
            { type: 'minlength', message: "Độ dài mã module từ 2-250 ký tự" },
            { type: 'maxlength', message: "Độ dài mã module từ 2-250 ký tự" }
        ],
        indexOrder: 'Bạn chỉ có thể nhập số',
        error_server: null,
    };

    panelOpenState = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private moduleManagerModule: ModulesManagerService,
        private sanitizer: DomSanitizer,
        private showMessage: ShowMessage,
        private base64: Base64Service,
        private dialogRef: MatDialogRef<DialogFormModuleComponent>) {
    }

    ngOnInit(): void {
        this.infoUser();
        this.urlImage = this.data.infoModule && this.data.infoModule.image != '' ? this.data.infoModule.image : '/assets/images/png/avatar.png';
    }

    infoUser() {
        this.infoModel = this.fb.group({
        fullname: [this.data.infoModule ? this.data.infoModule.fullname : '', [Validators.required,Validators.minLength(2), Validators.maxLength(250)]],
            code: [this.data.infoModule ? this.data.infoModule.code : '', [Validators.required,Validators.minLength(2), Validators.maxLength(250)]],
            description: [this.data.infoModule ? this.data.infoModule.description : ''],
            isActive: [this.data.infoModule ? this.data.infoModule.isActive : 1],
            indexOrder: [this.data.infoModule ? this.data.infoModule.indexOrder : 1, Validators.pattern(/^\d{1,8}$/)],
            group_module: [this.data.infoModule ? this.data.infoModule.isApplyCore : this.data.groupModule],
        });
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
      }

    get f() {
        return this.infoModel.controls;
    }

    myimage: Observable<any>;
    onFileChange($event) {
        const file = ($event.target as HTMLInputElement).files[0];
        this.selectedFile = file;
        this.fileName = this.selectedFile.name;
        
        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event: any) => { // called once readAsDataURL is completed
            this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
        };

        this.myimage = new Observable((subscriber: Subscriber<any>) => {
            this.base64.readFile(file, subscriber);
        });
        this.myimage.subscribe((resultBase64) => {
            this.convertImg = resultBase64;
        });
    }

    uploadImage() {
        document.getElementById('inputUploadFile').click();
    }

    formatErr(){
        this.resErrorCode = '';
    }

    onSubmit(data) {
        let uploadData : any = {
            fullname: data.fullname,
            description: data.description,
            image: this.convertImg === null ? '' : this.convertImg,
            fileName: this.fileName === null ? '' : this.fileName,
            isApplyCore: Number(data.group_module),
            indexOrder: data.indexOrder == '' ? data.indexOrder = 1 : parseInt(data.indexOrder),
            isActive: data.isActive ? 1 : 0,
            code:data.code
        };

        if (this.infoModel.invalid) {
            return this.checkValidate = true;
        }
        
        if (this.data.status == 'add') {
            this.dialogRef.close(uploadData);
        } else {
            this.dialogRef.close({
                id:this.data.infoModule.id,
                data:uploadData
            });
        }
    }

    onDissmiss(){
        this.dialogRef.close(false);
    }
}
