<div fxFlex="column" style="margin-top: 15px;">
    <!-- <div class="item-toggle">
        <span><img class="img-svg" src="/assets/images/icons/icon-24.svg" alt=""></span>
    </div> -->
    <div class="dropdown dropright dropdown-inline">
        <a class="item-menu" [routerLink]="['']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="removeActiveSubMenu()">
            <span><img class="img-svg" src="/assets/images/icons/icon-1.svg" alt=""></span>
            <h4>Trang chủ</h4>
        </a>
    </div>
    <div class="dropdown dropright dropdown-inline" id="curriculum">
        <a class="item-menu" href="#" data-toggle="dropdown" data-hover="dropdown" data-animations="zoomIn zoomIn zoomIn zoomIn"  class="{{activeMenuCurriculum ? 'active' : ''}}">
            <span><img class="img-svg" src="/assets/images/svg/sidebar-icon-11.svg" alt=""></span>
            <h4>Khung chương trình</h4>
        </a>
        <ul class="dropdown-menu-sub sub-menu row" role="menu">
            <ng-container *ngFor="let level of trainningLevel">
                <li class="col-sm-12 col-md-12 col-lg-12 col-xl-12 sub-menu__item">
                    <a class="sub-menu__link">{{level?.name}}</a>
                    <ul class="dropdown-menu-sub2 sub-menu row" *ngIf="level.arrSubjects.length > 0">
                        <ng-container *ngFor="let item of level.arrSubjects">
                            <li class="col-sm-12 col-md-3 col-lg-3 col-xl-3 sub-menu__item">          
                                <a href="javascript:;" class="sub-menu__link" 
                                (click)="openLinkCurriculum(item)" routerLinkActive="active"
                                class="{{this.activeSubMenuSubject == item.id ? 'active-submenu' : ''}}">                                     
                                    {{item?.name}}
                                </a>
                            </li>
                        </ng-container>
                    </ul>    
                </li>
            </ng-container>
        </ul>  
    </div>
    <ng-template ngxPermissionsOnly="module_core_admin_tenant">
        <div class="dropdown dropright dropdown-inline">
            <a class="item-menu" [routerLink]="['tenants-manager']" routerLinkActive="active" (click)="removeActiveSubMenu()">
                <span><img class="img-svg" src="/assets/images/icons/icon-2.svg" alt=""></span>
                <h4>Khách hàng</h4>
            </a>
        </div>
    </ng-template>
    <ng-template ngxPermissionsOnly="module_core_admin_user">
        <div class="dropdown dropright dropdown-inline">
            <a class="item-menu" [routerLink]="['accounts-manager']" routerLinkActive="active">
                <span><img class="img-svg" src="/assets/images/icons/icon-3.svg" alt=""></span>
                <h4>Người dùng</h4>
            </a>
        </div>
    </ng-template>
    <ng-template ngxPermissionsOnly="module_core_module">
        <div class="dropdown dropright dropdown-inline">
            <a class="item-menu" [routerLink]="['modules-manager']" routerLinkActive="active" (click)="removeActiveSubMenu()">
                <span><img class="img-svg" src="/assets/images/icons/icon-4.svg" alt=""></span>
                <h4>Module</h4>
            </a>
        </div>
    </ng-template>
    <ng-template ngxPermissionsOnly="module_core_role">
        <div class="dropdown dropright dropdown-inline">
            <a class="item-menu" [routerLink]="['roles-manager']" routerLinkActive="active" (click)="removeActiveSubMenu()">
                <span><img class="img-svg" src="/assets/images/icons/icon-5.svg" alt=""></span>
                <h4>Phân quyền</h4>
            </a>
        </div>
    </ng-template>
    <ng-template ngxPermissionsOnly="module_core_admin_post">
        <div class="dropdown dropright dropdown-inline">
            <a class="item-menu" [routerLink]="['post-manager']" routerLinkActive="active" (click)="removeActiveSubMenu()">
                <span><img class="img-svg" src="/assets/images/icons/icon-31.svg" alt=""></span>
                <h4>Bài Viết</h4>
            </a>
        </div>
    </ng-template>
    <div *ngxPermissionsOnly="['module_core_admin_subsystem', 'module_core_admin_subject', 'module_core_admin_grade', 'module_core_admin_school_year', 'module_core_admin_moet_unit', 'module_core_admin_location']" class="dropdown dropright dropdown-inline">
            <a class="item-menu dropdown-toggle" [routerLink]="[menu]" routerLinkActive="active" href="#" data-toggle="dropdown" data-hover="dropdown" data-animations="zoomIn zoomIn zoomIn zoomIn">
                <span><img class="img-svg" src="/assets/images/icons/icon-6.svg" alt=""></span>
                <h4>Khai báo</h4>
            </a>
            <ul class="dropdown-menu" role="menu">
                <ng-template ngxPermissionsOnly="module_core_admin_subsystem">
                    <li><a href="javascript:;" routerLinkActive="active" (click)="activeMenu('module-software-manager');" (click)="removeActiveSubMenu()">Phân hệ</a></li>
                </ng-template>
                <ng-template ngxPermissionsOnly="module_core_admin_subject">
                    <li><a href="javascript:;" routerLinkActive="active" (click)="activeMenu('subjects-manager');" (click)="removeActiveSubMenu()">Môn</a></li>
                </ng-template>
                <ng-template ngxPermissionsOnly="module_core_admin_grade">
                    <li><a href="javascript:;" routerLinkActive="active" (click)="activeMenu('grades-manager');" (click)="removeActiveSubMenu()">Khối</a></li>
                </ng-template>
                <ng-template ngxPermissionsOnly="module_core_admin_school_year">
                    <li><a href="javascript:;" routerLinkActive="active" (click)="activeMenu('school-years-manager');" (click)="removeActiveSubMenu()">Năm học</a></li>
                </ng-template>
                <ng-template ngxPermissionsOnly="module_core_admin_moet_unit">
                    <li><a href="javascript:;" routerLinkActive="active" (click)="activeMenu('industry-unit/education-lv1');" (click)="removeActiveSubMenu()">Đơn vị ngành dọc</a></li>
                </ng-template>
                <ng-template ngxPermissionsOnly="module_core_admin_location">
                    <li><a href="javascript:;" routerLinkActive="active" (click)="activeMenu('administrative-unit/lv1');"(click)="removeActiveSubMenu()">Đơn vị hành chính</a></li>
                </ng-template>
            </ul>
        </div>
    <ng-template ngxPermissionsOnly="module_core_admin_config">
        <div class="dropdown dropright dropdown-inline">
            <a class="item-menu" href="#" (click)="removeActiveSubMenu()">
                <span><img class="img-svg" src="/assets/images/icons/icon-7.svg" alt=""></span>
                <h4>Báo cáo- Thống kê</h4>
            </a>
        </div>
    </ng-template>
    <ng-template ngxPermissionsOnly="module_core_admin_config">
    <div class="dropdown dropright dropdown-inline">
        <a class="item-menu" href="#" [routerLink]="['general-configuration']" routerLinkActive="active" (click)="removeActiveSubMenu()">
            <span><img class="img-svg" src="/assets/images/icons/icon-8.svg" alt=""></span>
            <h4>Cấu hình chung</h4>
        </a>
    </div>
    </ng-template>
</div>
