import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const urlForgotPassword = '/api/core/v1/reset-password';

@Injectable({
    providedIn: 'root'
})

export class ForgotPasswordService {
    domain;
    constructor(private http: HttpClient) {

        var domainName = `${window.location.hostname}:${window.location.port}`;
        domainName = `${window.location.hostname}`;
        var port = `${window.location.port}`;
        if (port == '80' || port == '443') {
            domainName = `${window.location.hostname}`;
        }
        this.domain = domainName;
    }
    /**Gui user de lay ma**/
    resetPassword(username: string) {
        return this.http.post(`${environment.authApiUrl}/api/forgot-password/get-code`, { username, domain: this.domain });
    }

    /**nguoi dung bam da co ma**/
    checkResetCode(username: string) {
        return this.http.post(`${environment.authApiUrl}/api/forgot-password/check-reset-code`, { username, domain: this.domain });
    }

    /**xac nhan ma**/
    verifyCode(username: string, code: string) {
        return this.http.post(`${environment.authApiUrl}/api/forgot-password/confirm-code`, { username, code, domain: this.domain });
    }

    savePassword(data: {
        userId: string,
        password: string,
        confirmedPassword: string
        domain: null,
    }) {
        data.domain = this.domain;
        return this.http.post(`${environment.authApiUrl}/api/forgot-password/change-password`, data);
    }

    checkChangePassword(id: string) {
        return this.http.post(`${environment.authApiUrl}/api/forgot-password/check-change-password/${id}`, {});
    }
}
