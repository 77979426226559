import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogFormAccountComponent} from '../../dialogs/dialog-from-account/dialog-form-account.component';
import {DialogAddDecentralizationComponent} from '../../dialogs/dialog-add-decentralization/dialog-add-decentralization.component';
import {DialogAddTenantComponent} from '../../dialogs/dialog-add-tenant/dialog-add-tenant.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountManagerService, ListenFirebaseService} from '@app/_services/index';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {ShowMessage} from '@app/_services/index';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, Subscriber} from 'rxjs';
import * as moment from 'moment';
import {Location} from '@angular/common';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';
import { USER_PERMISSION } from '../../constants';

@Component({
    selector: 'app-detail-account',
    templateUrl: './detail-account.component.html',
    styleUrls: ['./detail-account.component.scss'],
})

export class DetailAccountComponent implements OnInit {
    checkStatus = true;
    activeLink: string;
    userId = null;
    roleIds: any;
    infoUser: any;
    dataTenant: any = [];
    dataRoles: any = [];
    dataHistories: any = [];
    isLoading = false;
    USER_PERMISSION= USER_PERMISSION;
    links = [
        {
            route: 'info-account',
            name: 'Thông tin',
            permission: USER_PERMISSION.perm3
        },
        {
            route: 'decentralization-applies',
            name: 'Phân quyền',
            permission: USER_PERMISSION.perm13
        },
        {
            route: 'tenants',
            name: 'Khách hàng',
            permission: USER_PERMISSION.perm10
        },
        {
            route: 'action-history',
            name: 'Lịch sử thao tác',
            permission: USER_PERMISSION.perm8
        }
    ];

    statusUser = [
        {id: 0, name: 'Chưa kích hoạt', color: '#FF8038'},
        {id: 1, name: 'Kích hoạt', color: '#1355C1'},
        {id: 2, name: 'Bị khóa', color: '#283C63'},
        {id: 3, name: 'Bị xóa', color: '#C4C4C4'},
    ]

    pageSize: number = 15;
    pageIndex: number = 0;
    showSearch: boolean = true;
    typeModule = 'user';
    showFilter = false;
    typeTime: any = '';
    keyWord = '';
    startDate: number = 0;
    endDate: number = 0;
    dataLocation = {
        city: null,
        district: null,
        ward: null,
    }

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private accountManagerService: AccountManagerService,
        private showMessage: ShowMessage,
        private route: Router,
        public db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService,
        private location: Location
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((res: any) => {
            this.activeLink = res.tab;
            this.getParamUrl()
        });
    }

    setTimOutFireBase(){
        setTimeout(() => {
            if (this.isLoading) this.isLoading = false;
        }, TIME_OUT_LISTEN_FIREBASE);

    }

    getParamUrl() {
      this.activatedRoute.paramMap.subscribe((ref: any) => {
        this.userId = ref.params.id;
        this.getInfoUser();
        this.loadDataHistory();
        this.loadDataRole();
        this.loadDataTenant();
      });
    }

    getInfoUser() {
        if (this.activeLink == 'info-account') this.isLoading = true;
        this.accountManagerService.infoUser(this.userId).subscribe((res: any) => {
            if (res.status === 0){
                this.showMessage.error(res.msg);
                return this.route.navigate(['accounts-manager']);
            }
            this.infoUser = res.data;
            if (this.activeLink == 'info-account') this.isLoading = false;
        });

    }

    showFilterClick(){
        this.showFilter = !this.showFilter;
    }

    loadDataHistory() {
        // get list history
        if (this.activeLink == 'action-history') this.isLoading = true;
        let data = {UserId: this.userId, KeyWord: this.keyWord, StartDateTime: this.startDate, EndDateTime: this.endDate, PageSize: this.pageSize, PageIndex: this.pageIndex}
        this.accountManagerService.getHistoryByUserId(data).subscribe((res: any) => {
            this.dataHistories = res.data;
            if (this.activeLink == 'action-history') this.isLoading = false;
        });
    }

    loadDataTenant() {
        // get list tenant
        if (this.activeLink == 'tenants') this.isLoading = true;

        this.accountManagerService.getCustomerByUser(this.pageSize, this.pageIndex, this.userId, this.keyWord).subscribe((res: any) => {
            this.dataTenant = res.data;
            if (this.activeLink == 'tenants') this.isLoading = false;
        });
    }

    loadDataRole() {
        // get list role
        if (this.activeLink == 'decentralization-applies') this.isLoading = true;
        this.accountManagerService.getRoleByUser(this.pageSize, this.pageIndex, this.userId, this.keyWord).subscribe((res: any) => {
            this.dataRoles = res.data;
            if (this.activeLink == 'decentralization-applies') this.isLoading = false;
        });
    }

    changeTime(event){
        this.typeTime = event.target.value;
        let startDate, endDate;
        if (this.typeTime == '0'){
            startDate = moment(moment().format('YYYY-MM-DD 00:00:00')).format('X');
            endDate = moment(moment().format('YYYY-MM-DD 23:59:59')).format('X');
        }

        if (this.typeTime == '1') {
            startDate = moment(moment().startOf('week').toDate()).format('X');
            endDate = moment(moment().endOf('week').toDate()).format('X');
        }

        if (this.typeTime == '2'){
            startDate = moment(moment().startOf('month').toDate()).format('X');
            endDate = moment(moment().endOf('month').toDate()).format('X');
        }

        this.startDate = startDate;
        this.endDate = endDate;
    }

    onChangePaginate(event) {
        this.pageIndex = event.pageIndex;
        if (this.activeLink === 'tenants')
          this.loadDataTenant();
        if (this.activeLink === 'decentralization-applies')
          this.loadDataRole();
        if (this.activeLink === 'action-history')
          this.loadDataHistory();
        // console.log(event)
    }

    openDialogDelete() {
        const dialogRef = this.dialog.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: 'Bạn đang thực hiện xóa người dùng',
                message: 'Bạn có chắc muốn xóa người dùng này không ?',
                btnOkText: 'XÓA',
                btnCancelText: 'HỦY',
                data: {
                    name: `${this.infoUser.userName} (${this.infoUser.userCode})`
                },
                html: `<img src="${this.infoUser.logo}" alt="Ảnh đại diện">`
            }
        });
        dialogRef.afterClosed().subscribe((confirm: any) => {
            this.isLoading = true;
            if (confirm) {
                this.accountManagerService.deleteUser(this.infoUser.id).subscribe((res: any) => {
                    if (res.status === 0) {
                        this.showMessage.error(res.msg);
                        this.isLoading = false;
                    }
                }, error => this.isLoading = false);
                this.setTimOutFireBase();
                const listenFb = new Observable((subscriber: Subscriber<any>) => {
                    this.listenFirebaseService.checkFireBase('delete', this.typeModule, subscriber);
                });
                listenFb.subscribe((refg) => {
                    if (refg === true) {
                        return this.route.navigate(['accounts-manager']);
                    }else this.isLoading = false;
                });
            }else  this.isLoading = false;
        });
    }

    openDialogUpdateAccount(data: any) {
        const dialogRef = this.dialog.open(DialogFormAccountComponent, {
            width: '70%',
            height: 'auto',
            data: {
                status: 'update',
                infoUser: this.infoUser,
                code: ''
            },
        });
        this.setTimOutFireBase();
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase('update', this.typeModule, subscriber);
        });
        listenFb.subscribe((refg) => {
            if (refg === true) {
                this.getInfoUser();
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            this.isLoading = false;
        });
    }

    openDialogAddDecentralized() {
        const dialogRef = this.dialog.open(DialogAddDecentralizationComponent, {
            width: '70%',
            height: 'auto',
            data: {
                status: 'add',
                userId: this.userId,
                roleIds: this.roleIds
            },
            autoFocus: false,
        });

        const listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase('assign_role_to_user', this.typeModule, subscriber);
        });
        listenFb.subscribe((refg) => {
            if (refg === true) {
                this.loadDataRole();
            }
        });
        this.setTimOutFireBase();

        dialogRef.afterClosed().subscribe(res => {
            this.isLoading = false;
        });

    }

    getInfoRoles(value) {
        return this.roleIds = value;
    }

    openDialogAddTenant() {
        const dialogRef = this.dialog.open(DialogAddTenantComponent, {
            width: '70%',
            height: 'auto',
            data: {
                status: 'add',
                userId: this.userId,
                roleIds: this.roleIds
            },
            autoFocus: false
        });

        const listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase('assign_tenant_to_user', this.typeModule, subscriber);
        });
        listenFb.subscribe((refg) => {
            if (refg === true) {
                this.loadDataTenant();
            }
        });
        this.setTimOutFireBase();

        dialogRef.afterClosed().subscribe(res => {
            this.isLoading = false;
        });


    }

    toggleClass(event) {
        this.showSearch = !this.showSearch;
        if (!this.showSearch) {
            event.value = '';
            if (this.keyWord !== ''){
                this.keyWord= '';
                this.pageIndex = 0;
                if (this.activeLink === 'tenants')
                    this.loadDataTenant();
                if (this.activeLink === 'decentralization-applies')
                    this.loadDataRole();
                if (this.activeLink === 'action-history')
                    this.loadDataHistory();
            }

        }
    }

    checkRemove(event) {
        this.loadDataTenant();
    }

    checkRemoveRole(event) {
        this.loadDataRole();
    }

    filter(){
        this.loadDataHistory();
    }

    search(event) {
        if (event.key == 'Enter' || event.key == 'Tab'){
            this.keyWord = event.target.value.trim();
            this.pageIndex = 0;
            if (this.activeLink === 'tenants')
                this.loadDataTenant();
            if (this.activeLink === 'decentralization-applies')
                this.loadDataRole();
            if (this.activeLink === 'action-history')
                this.loadDataHistory();
        }
    }

    changeTab(link) {
        this.activeLink = link;
        this.pageSize = 15;
        this.pageIndex = 0;
    }


}
