<div fxLayout="column" class="bg-content-global" *ngIf="isImportIndustry == false">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <h4 class="title-page-global">Danh sách đơn vị nghành dọc</h4>
            </div>
            <div style="display: flex;">
                <select class="input-field-global search-industry-custom" #option_education>
                    <option [value]="1" class="custom-text-option">Sở giáo dục</option>
                    <option [value]="2" class="custom-text-option">Phòng giáo dục</option>
                    <option [value]="3" class="custom-text-option">Trường trung học phổ thông</option>
                    <option [value]="4" class="custom-text-option">Trường trung học cơ sở</option>
                    <option [value]="5" class="custom-text-option">Trường tiểu học</option>
                    <option [value]="6" class="custom-text-option">Trường mầm non</option>
                </select>
                <div style="position: relative;">
                    <input (focusout)="searchFocusOut(search.value,option_education.value);" #search type="text" class="p-0 text-left btn btn-default button-search {{showSearch == true ? 'transition-search-show': ''}}" [value]="dataSearch" (input)="onSearchChange($event.target.value)"
                        [readOnly]="showSearch ? false: true" placeholder="{{showSearch ? 'Tìm kiếm' : ''}}">
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass(search.value)">
                    <div class="result-search {{ !checkResultSearch ? '' : 'active' }}">
                        <div class="item" *ngFor="let item of resultSearch" [routerLink]="[linkDetailEducation,item.id]">
                            <h4>{{ item.name }}</h4>
                            <p>{{ item.code }}</p>
                        </div>
                    </div>
                </div>
                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm7]">

                    <button class="btn-file-global mr-2" mat-raised-button (click)="openDialogImportIndustry();">
                        <mat-icon>note_add</mat-icon> Import
                    </button>
                </ng-template>
                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm2]">

                    <button class="btn-add-global" mat-raised-button (click)="clickCreateEducationLv1();">
                        <mat-icon>add</mat-icon> Thêm mới
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex class="content-declare" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="lv-1" style="display: flex;flex-direction: column;width: 28%;">
            <div class="title">Sở giáo dục</div>
            <div class="grades" style="display: flex;flex-direction: column;width: 100%;height: 100%;max-height: 75vh;overflow-y: auto;">
                <div *ngFor="let item of dataEducation1" class="grade" id="{{ item.code }}" (click)="openLv2(item.code,item.name)">
                    <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                        <div class="grade-left">
                            <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            <p>
                                <span>Phòng GD:{{ item.countPhongGD }}</span>
                                <span>Trường:{{ item.countTHPT }}</span>
                            </p>
                        </div>
                        <div class="grade-right">
                            <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu" class="menu-more-global">
                                    <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                        <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv1/detail/',item.id]">Chi
                                            tiết</button>
                                    </ng-template>
                                    <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                        <button mat-menu-item class="px-5" (click)="clickEditEducationLv1(item);">Chỉnh
                                            sửa</button>
                                    </ng-template>
                                    <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                        <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                    </ng-template>
                                </mat-menu>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lv-2" style="display: flex;flex-direction: column;width: 28%;">
            <div class="title">Phòng GD, Trường <i>{{ nameLv1 }}</i></div>
            <!-- start PGD -->
            <div class="content-lv-2" style="max-height: 50%;max-height: 75vh;" [style]="checkClickLv1 ? 'visibility: visible;' : 'visibility: hidden;'" *ngIf="checkClickLv1">
                <div class="header-lv-2" (click)="schowPGD(parent_lv2);" id="content-pgd">
                    <div class="grades">
                        <div class="grade">
                            <div class="content-grade">
                                <div class="grade-left">
                                    <h4><img [src]="checkOpenPGD ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countPGD }} Phòng giáo dục</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height: 50vh;overflow-y: auto;" *ngIf="checkOpenPGD">
                    <div *ngFor="let item of dataPGD" class="grade" id="{{ item.code }}" (click)="openLv3(item.code,item.name)">
                        <div class="content-grade {{ code_active_lv_2 == item.code ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                                <p>
                                    <span>Trường MN:{{ item.countMamNon }}</span>
                                    <span>Trường TH:{{ item.countTH }}</span>
                                    <span>Trường THCS:{{ item.countTHCS }}</span>
                                </p>
                            </div>
                            <div class="grade-right">
                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5]">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">
                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv2/detail/',item.id]">Chi
                                                tiết</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">
                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv2(item);">Chỉnh
                                                sửa</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">
                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv2(item);">Chuyển</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">
                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end PGD -->
            <!-- start THPT -->
            <div class="content-lv-2" style="max-height: 50%;max-height: 75vh;" [style]="checkClickLv1 ? 'visibility: visible;' : 'visibility: hidden;'" *ngIf="checkClickLv1">
                <div class="header-lv-2" (click)="schowTHPT(parent_lv2);" id="content-thpt">
                    <div class="grades">
                        <div class="grade">
                            <div class="content-grade">
                                <div class="grade-left">
                                    <h4><img [src]="checkOpenTHPT ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countTHPT }} Trường</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height: 50vh;overflow-y: auto;" *ngIf="checkOpenTHPT">
                    <div *ngFor="let item of dataTHPT" class="grade" id="{{ item.code }}">
                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            </div>
                            <div class="grade-right">
                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv2/detail/',item.id]">Chi
                                                tiết</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv2(item);">Chỉnh sửa</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv2(item);">Chuyển</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end THPT -->
        </div>
        <div class="lv-3" style="display: flex;flex-direction: column;width: 28%">
            <div class="title">Trường <i>{{ nameLv2 }}</i></div>
            <!-- start MG -->
            <div class="content-lv-3" style="max-height: 33%;max-height: 75vh;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                <div class="header-lv-3" (click)="schowMG(parent_lv3);" id="content-mg">
                    <div class="grades">
                        <div class="grade">
                            <div class="content-grade">
                                <div class="grade-left">
                                    <h4><img [src]="checkOpenMG ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countMG }} Trường MG</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height:30vh;overflow-y: auto;" *ngIf="checkOpenMG">
                    <div *ngFor="let item of dataMG" class="grade" id="{{ item.code }}">
                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            </div>
                            <div class="grade-right">
                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv3/detail/',item.id]">Chi
                                                tiết</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3();">Chỉnh
                                                sửa</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv3(item);">Chuyển</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end MG -->
            <!-- start TH -->
            <div class="content-lv-3" style="max-height: 33%;max-height: 75vh;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                <div class="header-lv-3" (click)="schowTH(parent_lv3);" id="content-th">
                    <div class="grades">
                        <div class="grade">
                            <div class="content-grade">
                                <div class="grade-left">
                                    <h4><img [src]="checkOpenTH ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countTH }} Trường TH</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height:30vh;overflow-y: auto;" *ngIf="checkOpenTH">
                    <div *ngFor="let item of dataTH" class="grade" id="{{ item.code }}">
                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            </div>
                            <div class="grade-right">
                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv3/detail/',item.id]">Chi
                                                tiết</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3();">Chỉnh
                                                sửa</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv3(item);">Chuyển</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end TH -->
            <!-- start THCS -->
            <div class="content-lv-3" style="max-height: 33%;max-height: 75vh;" [style]="checkClickLv1 && checkClickLv2 ? 'visibility: visible;' : 'visibility: hidden;'">
                <div class="header-lv-3" (click)="schowTHCS(parent_lv3);" id="content-thcs">
                    <div class="grades">
                        <div class="grade">
                            <div class="content-grade">
                                <div class="grade-left">
                                    <h4><img [src]="checkOpenTHCS ? 'assets/images/svg/up-icon.svg' : 'assets/images/svg/down-icon.svg'" alt="" class="mr-2"> {{ countTHCS }} Trường THCS</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grades pl-4" style="display: flex;flex-direction: column;width: 100%;max-height:30vh;overflow-y: auto;" *ngIf="checkOpenTHCS">
                    <div *ngFor="let item of dataTHCS" class="grade" id="{{ item.code }}">
                        <div class="content-grade {{ code_active == item.code ? 'active' : '' }}">
                            <div class="grade-left">
                                <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                            </div>
                            <div class="grade-right">
                                <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3, ADMIN_MOET_UNIT_PERMISSION.perm4, ADMIN_MOET_UNIT_PERMISSION.perm5, ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" class="menu-more-global">
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm3]">

                                            <button mat-menu-item class="px-5" (click)="removeLineLeader();" [routerLink]="['/industry-unit/education-lv3/detail/',item.id]">Chi
                                                tiết</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm4]">

                                            <button mat-menu-item class="px-5" (click)="clickEditEducationLv3();">Chỉnh
                                                sửa</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm6]">

                                            <button mat-menu-item class="px-5" (click)="clickMoveEducationLv3(item);">Chuyển</button>
                                        </ng-template>
                                        <ng-template [ngxPermissionsOnly]="[ADMIN_MOET_UNIT_PERMISSION.perm5]">

                                            <button mat-menu-item class="text-danger px-5" (click)="clickDeleteEducation(item);">Xóa</button>
                                        </ng-template>
                                    </mat-menu>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end THCS -->
        </div>
    </div>
</div>
<!-- <div style="display: flex;justify-content: space-between;">
    <div id="start-1" style="height: 400px;display: flex;align-items: center;"><img src="https://anseki.github.io/leader-line/img/ex-210-0.png" alt=""></div>
    <div id="end-1" style="background-color: green;height: 400px;">end 11</div>
    <div id="end-3" style="height: 400px;display: flex;align-items: center;"><img src="https://anseki.github.io/leader-line/img/ex-210-1.png" alt=""></div>
</div> -->

<app-import-industry (isImport)="getCloseImport($event)" *ngIf="isImportIndustry == true" [dataSource]="dataImport" [fileName]="fileName"></app-import-industry>