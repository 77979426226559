<div class="wrapper">
  <div class="title d-flex justify-content-between align-item-center mb-2">
    <h3>Danh sách thông báo bảo trì hệ thống</h3>
    <button (click)="add()" class="btn btn-add-global btn-add">Thêm</button>
  </div>
  <!-- danh sach -->
  <div class="list">
    <table class="table table-bordered table-el">
      <thead style="background: #EEEEEE;">
        <tr>
          <th scope="col" class="text-center text-table" style="width: 7%;">STT</th>
          <th scope="col" class="text-table" style="width: 10%;">Tiêu đề</th>
          <th scope="col" class="text-table" style="width: 34%;">Nội dung thông báo</th>
          <th scope="col" class="text-table" style="width: 15%;">Thời gian tạo</th>
          <th scope="col" class="text-table" style="width: 15%;">Loại</th>
          <th scope="col" class="text-table text-center" style="width: 12%;">Trạng thái</th>
          <th scope="col" class="text-table" style="width: 7%;"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of listNoti; let i=index">
          <tr>
            <!-- stt -->
            <td scope="row" class="text-center stt">{{i+1}}</td>
  
            <!-- tieu de thong bao -->
            <td>
              {{item.title}}
            </td>

            <!-- noi dung thong bao -->
            <td>
              {{item.content}}
            </td>

            <!-- thoi gian tao -->
            <td>
              {{item.createdAt | parseTime: 'HH:mm:ss DD/MM/YYYY'}}
            </td>

            <!-- loai -->
            <td [style.color]="item.color" style="font-weight: 500;">
              {{item.nameType}}
            </td>
  
            <!-- trang thai -->
            <td class="text-center">
              <label class="switch">
                <input
                  type="checkbox" [(ngModel)]="item.isDisplayActive"
                  [checked]="item.isDisplayActive ? true : null"
                  (change)="changeActive(item)"
                />
                <span class="slider round"></span>
              </label>
            </td>
            <!-- cong cu -->
            <td class="text-center">
              <img src="assets/images/svg/remove-icon2.svg" (click)="delete(item)" class="mr-2" style="cursor: pointer">
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="listNoti.length == 0" class="text-no-data">
          <td colspan="6">Không có dữ liệu</td>
        </tr>
      </tbody>
    </table>
  </div>
 
</div>

<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>