import { Component, OnInit } from '@angular/core';
// import { NavigationStart, Router } from '@angular/router';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from './_services';
import { User } from './_models';
import { NgxPermissionsService } from 'ngx-permissions';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'corebase';
  currentUser: User;
  constructor(
      public router: Router,
      private permissionsService: NgxPermissionsService,
      private authenticationService: AuthenticationService,
      private titleService: Title) {
      router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      }
    });
  }

  ngOnInit(): void {

    this.authenticationService.currentModulePermission.subscribe(x => {
      this.permissionsService.addPermission(x, (permissionName, permissionsObject) => {
        return !!permissionsObject[permissionName];
      });
    });
    this.authenticationService.currentElementPermission.subscribe(x => {
      this.permissionsService.addPermission(x, (permissionName, permissionsObject) => {
        return !!permissionsObject[permissionName];
      });
    });
    //
    this.setTitle();
  }
  setTitle() {
    let config = JSON.parse(localStorage.getItem('config')) ? JSON.parse(localStorage.getItem('config')) : 'Hệ thống quản lý';
    this.titleService.setTitle(config.fullname);
  }
}

