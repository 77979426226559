import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogTenantEditSchoolYearComponent} from '@app/_modules/tenants-manager/dialog/dialog-tenant-edit-school-year/dialog-tenant-edit-school-year.component';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import { TenantManagerService,  ListenFirebaseService, ShowMessage } from '@app/_services';
import {ActivatedRoute} from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {TENANT_PERMISSION} from '../../../constants';

@Component({
  selector: 'app-tab-tenant-school-year-list',
  templateUrl: './tab-tenant-school-year-list.component.html',
  styleUrls: ['./tab-tenant-school-year-list.component.scss']
})
export class TabTenantSchoolYearListComponent implements OnInit {
  dataSource = new MatTableDataSource();
  tenantId = "";
  dataColumn = ['stt', 'schoolYear', 'codeSchoolYear', 'startSchoolYear', 'endSchoolYear', 'tool'];
  public page: number = 1;
  public length: number = 2;
  public pageSize: number = 10;
  public pageIndex: number = 0;
  public limit:number = 10;
  public pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  keyword: string = '';
  typeModule = 'tenant_school_year';
  isLoadingResults = false;
  TENANT_PERMISSION = TENANT_PERMISSION;
  @Input() data:any;
  constructor(
    private dialog: MatDialog,
    private tenantManagerService: TenantManagerService,
    private activatedRoute: ActivatedRoute,
    private showMessage:ShowMessage,
    private listenFirebaseService: ListenFirebaseService
    ) { }

    ngOnInit(): void {
      this.listSchoolYear();
    }
  
    listSchoolYear(search?: string, filter?: []) {
      this.isLoadingResults = true;

      this.activatedRoute.paramMap.subscribe((res: any) => {
        // get info user
        this.tenantId = res.params.id;
        search = search ? search : '';
        filter = filter ? filter : [];
        this.tenantManagerService.getAllListSchoolYear(search, filter, this.tenantId).subscribe((ref: any) => {
          this.dataSource = new MatTableDataSource(ref.data);
          this.length = ref.data.length;
          this.isLoadingResults = false;

        });
      })
  
    }


  showFormEditSchoolYear(){
    const dialogRef = this.dialog.open(DialogTenantEditSchoolYearComponent, {
      width: '70%',
      height: 'auto',
      panelClass: 'custom-modalbox',
      data: {
        tenantId: this.tenantId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.listSchoolYear();
    });
}


  confirmDelete(item: any) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
        height: 'auto',
        width: '40%',
        data: {
          title:'Bạn đang thực hiện gỡ năm học ra khỏi danh sách áp dụng',
          message:'Bạn có chắc muốn gỡ năm học này không ?',
          btnOkText:'XÁC NHẬN',
          btnCancelText:'HỦY',
            data: {
              id:item.id,
              name:item.fullname
            },
        }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
        if (confirm === true) {
          this.isLoadingResults = true;
          let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
          });
  
          listenFb.subscribe((result) => {
            if (result === true) {
              this.listSchoolYear();
            }
            this.isLoadingResults = false;
          });
  
          this.tenantManagerService.deleteSchoolYearTenant(this.tenantId, item.id).subscribe((res: any) => {
            if (res.status == 0) {
              if (this.isLoadingResults) {
                this.isLoadingResults = false;
                this.showMessage.error(res.msg);
              }
            }
          }, error => {
            this.isLoadingResults = false;
          });
          setTimeout(() => {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
        }

    });
  }

  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.pageIndex = 0;
      this.listSchoolYear(this.keyword);
    }
  }
  
  onBlur(event) {
    event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
    if (event.type === 'blur') {
      this.pageIndex = 0;
      this.listSchoolYear(this.keyword);
    }
  }

}
