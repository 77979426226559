import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ShowMessage} from '@app/_services';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';

@Component({
  selector: 'app-import-administrative',
  templateUrl: './import-administrative.component.html',
  styleUrls: ['./import-administrative.component.scss']
})
export class ImportAdministrativeComponent implements OnInit {
  // 'error',
  displayedColumns = [ 'name1', 'code1', 'name2', 'code2', 'name3', 'code3'];
  @Input() dataSource;
  @Input() fileName;
  @Output() isImport = new EventEmitter<boolean>();
  constructor(
      private message: ShowMessage,
      private administrativeUnitService:AdministrativeUnitService
  ) { }
  ngOnInit(): void {
  }

  cancel(){
    this.isImport.emit(false);
    this.message.error('Đã hủy')
  }

  addDataImport(){
    this.isImport.emit(false);
  }

}
