import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {PermissionManagerService} from '@app/_services';
import * as moment from 'moment';
import { Permission } from '@app/_models/permission';
import {DialogFormAddEditPermissionComponent} from '@app/_modules/permission-manager/dialogs/dialog-form-add-edit-permission/dialog-form-add-edit-permission.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-permission-detail',
  templateUrl: './permission-detail.component.html',
  styleUrls: ['./permission-detail.component.scss']
})
export class PermissionDetailComponent implements OnInit {
  permission: Permission;
  date: any;
  constructor(private router: ActivatedRoute, private permissionsService: PermissionManagerService, private dialogRes: MatDialog) { }

  ngOnInit(): void {
    this.showPermission();
  }
  showPermission(){
    this.router.params.subscribe(params => {
      this.permissionsService.getPermissionById(params['id']).subscribe( (data: any) => {
        this.permission = data.permission;
        this.date = new Date(this.permission.created_at);
        this.permission.created_at = moment(this.permission.created_at).format('DD-MM-YYYY');
        this.permission.updated_at = moment(this.permission.updated_at).format('DD-MM-YYYY');
      });
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showFormPermission(item?: any){
      this.permissionsService.getPermissionById(item.id).subscribe((ref: any) => {
            if (ref.http_status_code === 200){
              item.module_id = item.module.id;
              const dialog = this.dialogRes.open(DialogFormAddEditPermissionComponent, {
                width: '60%',
                panelClass: 'custom-modalbox',
                data: {
                  action: 'edit',
                  item: item,
                }
              });
              dialog.afterClosed().subscribe(res => {
                if (res && res.http_status_code === 200){
                  alert(res.message);
                  this.showPermission();
                }
              });
            }
          },
          (error: any) => {
            alert(error.message);
          });
  }

}
