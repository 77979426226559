import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import { AuthorizationService } from '../_services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot
        ): Observable<boolean> | Promise<boolean> | boolean  {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && JSON.parse(localStorage.getItem('currentUser'))) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canActivateChild(
      route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && JSON.parse(localStorage.getItem('currentUser'))) {
            return true;
        }
        const allowedRoles = route.data.allowedRoles;
        const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);
        if (!isAuthorized) {
          // if not authorized, show access denied message
          this.router.navigate(['/accessdenied']);
        }

        return isAuthorized;
      }
}
