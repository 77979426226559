<div class="text-center">
    <span class="title-page-global custom-title-password">Thay đổi mật khẩu</span>
</div>
<br>
<div fxLayout="row" fxLayoutAlign="center center">
    <form id="custom-form" [formGroup]="verifyAccount" (ngSubmit)="confirmPassword(verifyAccount.value)">
        <div class="form-group">
            <p class="custom-label">Mật khẩu hiện tại</p>
            <input [readOnly]="validate.checkPasswordOld === 1" [type]="iconEyeOldPass ? 'text' : 'password'" (click)="checkOldPassword(oldPassword.value)"
                   #oldPassword (keyup)="checkOldPassword(oldPassword.value)" class="form-control custom-input" id="password-old-account">
            <div class="position-show-password">
                <img (click)="iconEyeOldPass = !iconEyeOldPass" class="icon-eye" [src]="iconEyeOldPass ? '../../../../assets/images/svg/eye-hide.svg' : '../../../../assets/images/svg/eye-show.svg'" alt="">
            </div>
            <div style="margin-top: 5px">
                <p *ngIf="(validate.checkPasswordOld !== 0)" [class]="validate.checkPasswordOld === 2 || validate.checkPasswordOld === 3? 'text-danger' : 'text-success'">
                    <img [src]="validate.checkPasswordOld === 2 || validate.checkPasswordOld === 3 ? 'assets/images/svg/icon-error-validate.svg' : 'assets/images/svg/success_validate.svg'">&nbsp;
                    <span *ngIf="(validate.checkPasswordOld == 2)">Mật khẩu không đúng</span>
                    <span *ngIf="(validate.checkPasswordOld == 1)">Mật khẩu đúng</span>
                    <span *ngIf="(validate.checkPasswordOld == 3)">Vui lòng nhập mật khẩu hiện tại</span>
                </p>
            </div>
        </div>
        <div class="form-group">
            <p class="custom-label">Nhập mật khẩu mới</p>
            <input [readOnly]="validate.checkPasswordOld === 2 || validate.checkPasswordOld === 0 || validate.checkPasswordOld === 3" placeholder="Nhập mật khẩu mới" [type]="iconEyeNewPass ? 'text' : 'password'" (click)="checkNewPassword(newPassword.value)" #newPassword (keyup)="checkNewPassword(newPassword.value)" formControlName="verify_account" class="form-control custom-input" id="verify-account">
                <!--Mật khẩu không để trống-->
                <img (click)="iconEyeNewPass = !iconEyeNewPass" class="icon-eye" [src]="iconEyeNewPass ? '../../../../assets/images/svg/eye-hide.svg' : '../../../../assets/images/svg/eye-show.svg'" alt="">
            <div  *ngIf="(validate.verify_account.is_required == 2)" style="margin-top: 5px">
                <span class="text-danger">
                    <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu không được để trống<br>
                </span>
            </div>
            <div *ngIf="validate.verify_account.is_required == 1" style="margin-top: 5px">
                    <!--Mật khẩu phải ít nhất 6 ký tự-->
                    <span class="text-danger"
                          *ngIf="(validate.verify_account.is_min_length == 2) || verifyAccount.get('verify_account').hasError('required') && (verifyAccount.get('verify_account').touched || verifyAccount.get('verify_account').dirty)">
                        <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu phải ít nhất 6 ký tự<br>
                    </span>
                    <span class="text-success" *ngIf="(validate.verify_account.is_min_length == 1)">
                        <img src="assets/images/svg/success_validate.svg" alt=""> Mật khẩu phải ít nhất 6 ký tự<br>
                    </span>
                    <!--Mật khẩu phải ít nhất 6 ký tự-->
                    <!--1 ký tự số-->
                    <span class="text-danger" *ngIf="(validate.verify_account.is_number == 2)">
                        <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự số<br>
                    </span>
                    <span class="text-success" *ngIf="(validate.verify_account.is_number == 1)">
                        <img src="assets/images/svg/success_validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự số<br>
                    </span>
                    <!--1 ký tự số-->

                    <!--1 ký tự thường hoặc 1 ký tự hoa-->
                    <span class="text-danger" *ngIf="(validate.verify_account.is_chart == 2)">
                        <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự chữ (chữ hoa hoặc thường)
                        <br>
                    </span>
                    <span class="text-success" *ngIf="(validate.verify_account.is_chart == 1)">
                        <img src="assets/images/svg/success_validate.svg" alt=""> Mật khẩu phải chứa ít nhất 1 ký tự chữ (chữ hoa hoặc thường)
                        <br>
                    </span>
                    <!--1 ký tự số-->

                    <!--Qua ky tu cho phep-->
                    <span class="text-danger" *ngIf="(validate.verify_account.is_max_length == true)">
                        <img src="assets/images/svg/icon-error-validate.svg" alt=""> Mật khẩu vượt quá 50 ký tự
                        <br>
                    </span>
                    <!--Qua ky tu cho phep-->
                </div><br>
        </div>
        <div class="form-group">
            <label class="card-title custom-label">Nhập lại mật khẩu mới</label>
            <input [readOnly]="validate.checkPasswordOld === 2 || validate.checkPasswordOld === 0 || validate.checkPasswordOld === 3" placeholder="Nhập lại mật khẩu" [type] = "iconEyeRepeatPass ? 'text' : 'password'" #repeatPassword (click)="checkRepeatPassword(repeatPassword.value)" (keyup)="checkRepeatPassword(repeatPassword.value)"
                   formControlName="repeat_password" class="form-control custom-input" >
                <img (click)="iconEyeRepeatPass = !iconEyeRepeatPass" class="icon-eye" [src]="iconEyeRepeatPass ? '../../../../assets/images/svg/eye-hide.svg' : '../../../../assets/images/svg/eye-show.svg'" alt="">
            <div style="margin-top: 5px" *ngIf="verifyAccount.get('repeat_password').hasError('required') &&
                               this.verifyAccount.get('repeat_password').dirty || validate.is_repeat_password_required === 1">
<!--                (this.verifyAccount.get('repeat_password').touched || )-->

                <span class="text-danger"><img src="assets/images/svg/icon-error-validate.svg" alt=""> Nhập lại mật khẩu không được để trống <br></span>
            </div>
            <div *ngIf="validate.is_repeat_password_required === 0" style="margin-top: 5px">
                <span class="text-danger" *ngIf="(validate.is_repeat_password == 1)">
                    <img src="assets/images/svg/icon-error-validate.svg"> Mật khẩu không khớp
                </span>
                <span class="text-success" *ngIf="(validate.is_repeat_password == 2)">
                    <img src="assets/images/svg/success_validate.svg"> Đã khớp mật khẩu
                </span>
            </div>
        </div>
        <mat-dialog-actions align="center">
            <button mat-button class="btn-cancel mat-elevation-z" mat-dialog-close mat-raised-button>Hủy</button>
            <button type="submit" class="btn btn-update sent-code font-helvetica-neue" [disabled]="verifyAccount.invalid">Xác nhận</button>
        </mat-dialog-actions>
    </form>
</div>

