<mat-dialog-content>
    <div class="container-fluid">
        <div fxLayout="row">
            <div fxFlex="25%"></div>
            <div fxFlex="50%">
                <h4 class="title-page-global text-center">Chỉnh sửa khối - {{tenantInfor?.fullname}}</h4>
            </div>
            <div fxFlex="25%"></div>
        </div>
        <div fxLayout="row">
            <div fxFlex="25%"></div>
            <div fxFlex="50%"  fxLayout="row" fxLayoutAlign="space-between none">
                <p class="total-item">Danh sách khối</p>
                <mat-checkbox [(ngModel)]="checkStatus" (change)="checkAll($event)" 
                    class="example-margin font"><span class="total-item">Chọn tất cả</span>
                </mat-checkbox>
            </div>
            <div fxFlex="25%"></div>
        </div>
        <div fxLayout="row">
            <div fxFlex="25%"></div>
            <div fxFlex="50%" style="position: relative;">
                <input (keypress)="onKeyPressSearch($event)" 
                (blur)="onBlur($event)" type="text" class="form-control input-field-global" placeholder="Tên hoặc mã khối">
                <img src="assets/images/svg/search.svg" class="icon-search" alt="">
            </div>
            <div fxFlex="25%"></div>
        </div>
        <div class="mt-1" fxLayout="row" fxLayoutAlign="space-around start">
            <div class="grade-item" *ngFor="let item of data">
                <div class="item-subject mt-1" fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [checked]="item.is_selected" [value]="item.trainingLevel"
                        (change)="checkAllModule($event, item.trainingLevel)"></mat-checkbox>
                    <label class="ml-3 mt-2" fxLayout="column" fxLayoutAlign="start start" style="width: calc(100% - 70px)">
                        <p *ngIf="item.trainingLevel === '1'" class="title-subject ellipsis-text">Mầm non</p>
                        <p *ngIf="item.trainingLevel === '2'" class="title-subject ellipsis-text">Tiểu học</p>
                        <p *ngIf="item.trainingLevel === '3'" class="title-subject ellipsis-text">Trung học cơ sở</p>
                        <p *ngIf="item.trainingLevel === '4'" class="title-subject ellipsis-text">Trung học phổ thông</p>
                        <p *ngIf="item.trainingLevel === '99'" class="title-subject ellipsis-text">Khác</p>
                    </label>
                </div>
                <div *ngFor="let itemsubject of item.subjectArray" class="item-subject mt-2" fxLayout="row"
                    fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="itemsubject.is_selected" (ngModelChange)="updateAllComplete(item.trainingLevel)">
                    </mat-checkbox>
                    <label class="ml-3 mt-2" fxLayout="column" fxLayoutAlign="start start" style="width: calc(100% - 70px)">
                        <p class="title-subject ellipsis-text"> {{itemsubject.fullname}}</p>
                        <p class="subtitle-subject ellipsis-text">{{itemsubject.code}}</p>
                    </label>
                </div>
            </div>
            
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mt-3">
    <button mat-button mat-dialog-close mat-raised-button class="mat-elevation-z btn-cancel px-5">Hủy</button>
    <button type="submit" (click)="submitUpdate()" mat-raised-button class="mat-elevation-z btn-save px-5">Lưu</button>
</mat-dialog-actions>
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
</div>