<div fxLayout="column" class="bg-content-global">
    <div fxFlex="60px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
                <p class="title-page-global">Năm học</p>
                <p class="result-global">Tổng số năm học: {{dataSource.length}}</p>
            </div>
            <div>
                <span class="{{showSearch == true ? 'transition-search-show': ''}}">
                        <input (keydown)="keyPressSearch($event, search.value)" #search type="text" class="text-left btn btn-default button-search"
                               [value]="showSearch == true? stringSearch : ''" [readOnly]="showSearch == true ? false: true" placeholder="{{showSearch == true ? 'Tên, mã năm học' : ''}}">
                    <img src="assets/images/svg/search.svg" alt="" class="icon-search" (click)="toggleClass(search)">
                </span>
                <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm2}}">
                    <button class="btn btn-add-edit" (click)="openDialogCreateSchoolYear()">
                        <img src="assets/images/svg/add.svg"> Thêm năm học mới
                    </button>
                </ng-template>
            </div>
        </div>
    </div>

    <div style="width: 100%">
        <ng-container *ngFor="let item of dataSource">
            <div class="col-md-12 d-flex title-custom
                {{item.status == 2 ? 'background-for-next-school-year' :
                 item.status == 1 ?
                'background-for-current-school-year': 'background-for-pass-school-year'
                }}">
                <div class="col-md-6">
                    <div>
                        <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm2}}">
                            <p class="custom-title" [routerLink]="['detail-school-year/', item.id]">{{item.fullname}}
                                <span class="font-info cusom">&nbsp;&nbsp;({{item.code}})</span>
                            </p>
                        </ng-template>
                        <ng-template ngxPermissionsExcept="{{permissionSchoolYear.perm3}}">
                            <p class="custom-title" >{{item.fullname}}
                                <span class="font-info cusom">&nbsp;&nbsp;({{item.code}})</span>
                            </p>
                        </ng-template>
                    </div>
                    <div class="font-info"><span class="custom-font-bold">{{lang.label_manager_list.start_date}}</span>: {{item.startDate | parseTime: 'DD/MM/YYYY'}}</div>
                    <div class="font-info"><span class="custom-font-bold">{{lang.label_manager_list.end_date}}</span>: {{item.endDate | parseTime: 'DD/MM/YYYY'}}</div>
                    <div class="font-info d-flex"><span class="custom-font-bold">{{lang.label_manager_list.customer}}</span>: &nbsp;&nbsp;
                        <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm6}}">
                            <div class="custom-box" (click)="openDialogListCustomer(item)">{{item.tenantNumber}}</div>
                        </ng-template>
                        <ng-template ngxPermissionsExcept="{{permissionSchoolYear.perm6}}">
                            <div class="custom-box" >{{item.tenantNumber}}</div>
                        </ng-template>
                    </div>
                    <div class="font-info" style="line-height: 22px;"><span class="custom-font-bold">{{lang.label_manager_list.status}}</span>&nbsp;&nbsp;
                        <ng-template [ngxPermissionsOnly]="[permissionSchoolYear.perm4]">
                            <mat-checkbox [disabled]="item.status == 1" class="text-center" #status (change)="changeStatusSchoolYear(status, item)" [checked]="item.isActive == 1? true: false">
                                {{lang.label_manager_list.active}}
                            </mat-checkbox>
                        </ng-template>
                        <ng-template [ngxPermissionsExcept]="[permissionSchoolYear.perm4]">
                            <mat-checkbox disabled="" class="text-center" [checked]="item.isActive == 1? true: false">
                                {{lang.label_manager_list.active}}
                            </mat-checkbox>
                        </ng-template>
                </div>
                </div>
                <div class="col-md-6 text-right">
                    <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm4}}">
                        <div class="font-info">{{lang.button.btn_edit_info_school_year}} &nbsp;
                            <img src="assets/images/svg/edit.svg" alt="" class="btn-icon" (click)="openDialogUpdateSchoolYear(item)">
                        </div>
                    </ng-template>
                    <ng-template ngxPermissionsOnly="{{permissionSchoolYear.perm5}}">
                        <p *ngIf="item.status == 2" class="font-info">{{lang.button.btn_delete_school_year}}  &nbsp;
                            <img src="assets/images/svg/delete.svg" alt="" class="btn-icon" (click)="openDialogDelete(item)">
                        </p>
                    </ng-template>
                    <br *ngIf="item.status != 2">
                    <p class="title-page-global" style="position: relative; top: 2.5em">
                       <span [style]="{color: this.statusSchoolYear[item.status].color}">{{this.statusSchoolYear[item.status].name}}</span>
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="dataSource.length === 0">
            <br>
            <p>Không có dữ liệu</p>
        </ng-container>
    </div>

</div>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>



