import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogTenantEditGradeComponent } from '@app/_modules/tenants-manager/dialog/dialog-tenant-edit-grade/dialog-tenant-edit-grade.component';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantManagerService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { Observable, Subscriber } from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import {TENANT_PERMISSION} from '../../../constants';

@Component({
  selector: 'app-tab-tenant-grade-list',
  templateUrl: './tab-tenant-grade-list.component.html',
  styleUrls: ['./tab-tenant-grade-list.component.scss']
})
export class TabTenantGradeListComponent implements OnInit {
  dataSource: any;
  dataColumn = ['stt', 'nameGrade', 'codeGrade', 'levelGrade', 'tool'];
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  tenantId = '';
  keyword: string = '';
  typeModule = 'tenant_grade';
  isLoadingResults = false;
  TENANT_PERMISSION = TENANT_PERMISSION;

  @Input() data: any;
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private tenantManagerService: TenantManagerService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService
  ) { }

  ngOnInit(): void {
    this.listGrade();
  }

  listGrade(search?: string, filter?: []) {
    this.isLoadingResults = true;
    this.activatedRoute.paramMap.subscribe((res: any) => {
      // get info user
      this.tenantId = res.params.id;
      search = search ? search : '';
      filter = filter ? filter : [];
      this.tenantManagerService.getAllListGrade(this.pageSize, this.pageIndex, search, filter, this.tenantId).subscribe((ref: any) => {
        this.dataSource = ref.data.data;
        this.totalRecord = ref.data.totalItems;
        this.isLoadingResults = false;
      });
    });
  }

  onChangePaginate(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.listGrade();
  }

  showFormEditGrade() {
    const dialogRef = this.dialog.open(DialogTenantEditGradeComponent, {
      height: 'auto',
      width: '70%',
      panelClass: 'custom-modalbox',
      data: {
        tenantId: this.tenantId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.listGrade();
    });
  }

  confirmDelete(item: any) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện gỡ khối ra khỏi danh sách áp dụng',
        message: 'Bạn có chắc muốn gỡ khối này không ?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'HỦY',
        data: {
          id: item.id,
          name: item.fullname
        },
      }
    });
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm === true) {
        this.isLoadingResults = true;
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase("delete", this.typeModule, subscriber);
        });

        listenFb.subscribe((result) => {
          if (result === true) {
            this.listGrade();
          }
          this.isLoadingResults = false;
        });

        this.tenantManagerService.deleteGradeTenant(this.tenantId, item.id).subscribe((res: any) => {
          if (res.status == 0) {
            if (this.isLoadingResults) {
              this.isLoadingResults = false;
              this.showMessage.error(res.msg);
            }
          }
        }, error => {
          this.isLoadingResults = false;
        });
        setTimeout(() => {
          if (this.isLoadingResults) {
            this.isLoadingResults = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }

    });
  }
  onKeyPressSearch(event: any) {
    let code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      this.pageIndex = 0;
      this.listGrade(this.keyword);
    }
  }

  onBlur(event) {
    event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
    if (event.type === 'blur') {
      this.pageIndex = 0;
      this.listGrade(this.keyword);
    }
  }

}
