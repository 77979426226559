import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogFormProfileComponent } from '../../dialogs/dialog-form-profile/dialog-form-profile.component';
import {UserPersonalService} from '@app/_services/user-personal.service';
import * as moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import * as $ from 'jquery';
import {ShowMessage} from '@app/_services';
import { Location } from '@angular/common';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-detail-account',
  templateUrl: './detail-account.component.html',
  styleUrls: ['./detail-account.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DetailAccountComponent implements OnInit {
  showFilter = false;
  showSearch = false;
  dataSearch = '';
  dataSource: any;
  typeTime: number;
  changeSearch: number = 0;
  activeLink;
  links = [
    {
      route: 'tab1',
      name: 'Thông tin người dùng',
    },
    {
      route: 'tab2',
      name: 'Lịch sử thao tác',
    },
  ];
  infoUser: any;
  queryParams: any;
  dataFilter = {
    startDate: null,
    toDate: null,
  };
  date: any;
  maxDate: any;
  constructor(
      private userPersonalService: UserPersonalService,
      public dialog: MatDialog,
      private route: ActivatedRoute,
      public router: Router, private showMessage: ShowMessage, private location: Location) { }

  ngOnInit(): void {
    this.date = moment();
    this.getPersonalUser();
    this.queryParams = this.route.snapshot.queryParams;
    this.activeLink = this.queryParams.active;
    const minCurrentDate = new Date();
    this.maxDate = minCurrentDate;
    $("#leader-line-defs").remove();
  }

  getPersonalUser(){
    const token = JSON.parse(localStorage.getItem('currentUser'));
    this.userPersonalService.getPersonalUser(token).subscribe((ref: any) => {
      if (ref.status === 0){
        this.showMessage.error(ref.msg);
        this.location.back();
        this.router.navigate(['/']);
      }
      else{
        this.infoUser = ref.data;
        this.infoUser.birthday = new Date(ref.data.birthday);
        this.infoUser.createAt = new Date(ref.data.createAt * 1000);
        this.infoUser.lastLogin = new Date(ref.data.lastLogin * 1000);
      }
    });
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
    if (this.showFilter === false){
      this.changeSearch = this.changeSearch + 1;
      this.dataFilter.startDate = null;
      this.dataFilter.toDate = null;
    }
  }
  changeTab(tab){
    this.activeLink = tab;
    this.showFilter = false;
    this.dataFilter.startDate = null;
    this.dataFilter.toDate = null;
    this.dataSearch = '';
    this.router.navigate([`${'profile'}`], { queryParams: {active: tab}});
  }

  parseTime(date){
    return moment(date).format('HH:mm:ss DD/MM/YYYY');
  }

  toggleClass(){
    this.showSearch = !this.showSearch;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
    if (this.showSearch === false){
      this.dataSearch = '';
      this.changeSearch = this.changeSearch + 1;
    }
  }
  checkFilter(value){
    if (value !== ''){
      this.typeTime = value;
      if (this.typeTime == 1){
        this.dataFilter.startDate = this.toTimestamp(moment().clone().startOf('day'));
        this.dataFilter.toDate = this.toTimestamp(moment());
      }
      else if (this.typeTime == 2){
        this.dataFilter.startDate = this.toTimestamp(moment().clone().startOf('week'));
        this.dataFilter.toDate = this.toTimestamp(moment());
      }
      else if (this.typeTime == 3){
        this.dataFilter.startDate = this.toTimestamp(moment().clone().startOf('month'));
        this.dataFilter.toDate = this.toTimestamp(moment());
      }
    }
  }

  checkText(event, value) {
    if (this.showSearch === true) {
      event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
      if (event.type === 'blur') {
        this.dataSearch = value;
        this.changeSearch = this.changeSearch + 1;
      }
    }
  }
  filter(){
    this.changeSearch = this.changeSearch + 1;
  }

  changeDateTime(name, event){
    let date = moment(event.target.value);
    if (name === 'startDate'){
      date = date.clone().startOf('day');
      this.dataFilter.startDate =  this.toTimestamp(date);
    }
    if (name === 'endDate'){
      date = date.clone().endOf('day');
      this.dataFilter.toDate = this.toTimestamp(date);
    }
  }

  toTimestamp(model){
    const datum = new Date(Date.UTC(model.year(), model.month(), model.date(), model.hour(), model.minute(),0));
    return datum.getTime()/1000;
  }


  openDialogUpdateProfile(infoAccount){
    const dialogRef = this.dialog.open(DialogFormProfileComponent, {
      height: 'auto',
      width: '70%',
      data: {
        infoUser: infoAccount,
      }
    });
    dialogRef.afterClosed().subscribe((ref: any) => {
      this.getPersonalUser();
    });
  }
}
