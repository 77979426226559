<span mat-dialog-title class="title-delete">
    <p class="text-danger">{{ data_delete.title }}</p>
    <p class="title-name">{{ data_delete.data.name  | substring: 40}}</p>
</span>
<mat-dialog-content class="mat-typography">
    <div class="mat-dialog-content-delete" [innerHTML]="data.html ? data.html : ''"></div>
    <p class="message-delete" [style]="data.html ? 'margin: 20px 0 20px 0 !important;' : ''">{{ data_delete.message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button class="btn-custom btn-accept" [mat-dialog-close]="true" cdkFocusInitial mat-raised-button (click)="confirm()">{{ data_delete.btnOkText }}</button>
</mat-dialog-actions>
