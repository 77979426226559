<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="custom-scroll">
    <table mat-table [dataSource]="dataSource" class="table-el">
        <ng-container matColumnDef="stt">
            <th mat-header-cell *matHeaderCellDef style="width: 5%"> STT</th>
            <td class="border-first-5" mat-cell *matCellDef="let element, let i = index"> {{i + 1}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef style="width: 8%"> Tên quyền</th>
            <td mat-cell *matCellDef="let element">
                {{element.fullname}}
            </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef style="width: 8%"> Mã</th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
        </ng-container>
        <ng-container matColumnDef="describe">
            <th mat-header-cell *matHeaderCellDef style="width: 30%"> Mô tả</th>
            <td mat-cell *matCellDef="let element">
                {{element.description}}
            </td>
        </ng-container>
        <ng-container matColumnDef="order_index">
            <th mat-header-cell *matHeaderCellDef style="width: 5%">Thứ tự sắp xếp</th>
            <td mat-cell *matCellDef="let element">
                {{element.indexOrder}}
            </td>
        </ng-container>
        <ng-container matColumnDef="tools">
            <th class="text-right" mat-header-cell *matHeaderCellDef style="width: 8%"> Công cụ</th>
            <td class="border-last-5 text-right" mat-cell *matCellDef="let element">
                <button class="btn btn-block" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu-more-global">
                    <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm11]">
                        <button mat-menu-item class="px-5" (click)="openDialogUpdatePermission(element)">Chỉnh sửa</button>
                    </ng-template>
                    <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm12]">
                        <button mat-menu-item class="text-danger px-5" (click)="openDialogDelete(element.fullname, element.id)">Xóa</button>
                    </ng-template>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!-- <ng-container *ngIf="length > 10">
        <mat-paginator appStylePaginator showFirstLastButtons [length]="length" [pageSize]="10" [pageSizeOptions]="[15,20,225]" [pageIndex]="currentPage" class="table-paginate-global"></mat-paginator>
    </ng-container> -->
</div>