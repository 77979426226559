import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators, ValidatorFn, FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
const moment = _moment;
import { REGEX_PHONE, REGEX_FULL_NAME, REGEX_CODE, REGEX_USER_NAME } from '@app/_shared/utils/constant';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value !== null){
    let out = control.value.replace(/\s/g, '');
    if (out === '') {
      return {
        'required': true
      };
    }
    return null;
  }
};


import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {UserPersonalService} from '@app/_services/user-personal.service';
import {Observable, Subscriber} from 'rxjs';
import {Base64Service, GeneralService, ShowMessage} from '@app/_services';
import {Router} from '@angular/router';


@Component({
  selector: 'app-dialog-form-profile',
  templateUrl: './dialog-form-profile.component.html',
  styleUrls: ['./dialog-form-profile.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class DialogFormProfileComponent implements OnInit {
  public infoUser;
  panelOpenState = true;
  dataBase64Image: SafeResourceUrl;
  selectedFile: File;
  checkImage = false;
  isValidate: boolean;
  checkCode: string;
  isLoading: boolean = false;
  messageValidate = {
    fullName: [
      {type: 'required', message: 'Bạn chưa nhập tên'},
      {type: 'minLength', message: 'Tối thiểu 2 kí tự' },
      {type: 'maxLength', message: 'Tối đa 250 kí tự' },
    ],
    address: [
      {type: 'maxLength', message: 'Địa chỉ không được nhập quá 500 kí tự'}
    ],
    email: [
      {type: 'required', message: 'Bạn chưa nhập email'},
      {type: 'email', message: 'Email chưa đúng định dạng'}
    ],
    gender: [
      {type: 'required', message: 'Bạn chọn giới tính'}
    ],
    phone: [
      {type: 'required', message: 'Bạn chưa nhập số điện thoại'},
      {type: 'pattern', message: 'Không đúng số điện thoại'},
    ],
  };
  city: any;
  district: any;
  ward: any;
  changeImage: boolean = false;
  maxDate: any;
  constructor(public dialogRef: MatDialogRef<DialogFormProfileComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private fb: FormBuilder,
              private userPersonalService: UserPersonalService,
              private sanitizer: DomSanitizer,
              private base64: Base64Service,
              private generalService: GeneralService,
              public router: Router,
              private showMessage: ShowMessage
  ) {
  }

  ngOnInit() {
    this.dataBase64Image = this.data.infoUser.avtatar !== '' ? this.data.infoUser.avtatar : 'assets/images/png/avatar.png';
    const birthday = new Date(this.data.infoUser.birthday * 1000);
    this.infoUser = this.fb.group({
      fullName: [this.data.infoUser.fullName, [Validators.required, Validators.minLength(2), Validators.maxLength(250), Validators.pattern(REGEX_FULL_NAME), trimValidator]],
      userCode: [this.data.infoUser.code, [Validators.required, Validators.pattern(REGEX_CODE)]],
      userName: [this.data.infoUser.userName, [Validators.required, Validators.pattern(REGEX_USER_NAME)]],
      gender: [this.data.infoUser.gender, [Validators.required]],
      birthday: [birthday, [Validators.required]],
      email: [this.data.infoUser.email, [Validators.required, Validators.email]],
      phone: [this.data.infoUser.phone, [Validators.required, Validators.pattern(REGEX_PHONE)]],
      cityId: [this.data.infoUser.cityId],
      ward: [this.data.infoUser.wardId],
      district: [this.data.infoUser.districtId],
      address: [this.data.infoUser.address, [Validators.maxLength(500)]],
    });
    this.getAllCity();
    this.data.infoUser.cityId === null || this.data.infoUser.cityId === 'null' || this.data.infoUser.cityId === '' ? 'a' : this.changeDistrictByCity(this.data.infoUser.cityId, 0);
    this.data.infoUser.districtId === null || this.data.infoUser.districtId === 'null' || this.data.infoUser.cityId === '' ? 'a' : this.changeWardByDistrict(this.data.infoUser.districtId, 0);
    const today = new Date();
    const minCurrentDate = new Date(today.setDate(today.getDate() - 1));
    this.maxDate = minCurrentDate;
  }

  get f() { return this.infoUser.controls; }

  onSubmit(value, validate){
    this.isLoading = true;
    if (!this.infoUser.get('phone').invalid || !this.infoUser.get('email').invalid) {
      this.infoUser.controls['email'].setErrors({'incorrect': false});
      this.infoUser.controls['phone'].setErrors({'incorrect': false});
    }
    if (!this.infoUser.invalid || this.infoUser.get('fullName').invalid){
      this.isValidate = true;
      this.isLoading = false;
      return false;
    }
    this.isLoading = false;

    value.birthday = this.toTimestamp(moment(value.birthday));
    value.gender = Number(value.gender);
    value.cityId = String(value.cityId);
    value.district = String(value.district);
    value.ward = String(value.ward);
    value.logo = this.changeImage ? this.dataBase64Image : '';
    value.id = this.data.infoUser.id;
    value.userStatus = Number(this.data.infoUser.status);
    value.fullName = value.fullName.trim();
    value.address = value.address.trim();

    const token = JSON.parse(localStorage.getItem('currentUser'));
    this.userPersonalService.updateProfileUser(value).subscribe((ref: any) => {
      if (ref.status){
        this.isLoading = false;
        this.userPersonalService.getPersonalUser(token).subscribe((res: any) => {
          const user = {
            id: res.data.id,
            fullname: res.data.fullName,
            avatar: res.data.avtatar
          };
          localStorage.setItem('user', JSON.stringify(user));
          location.reload();
        });
        this.dialogRef.close(ref);
      }
      else{
        this.checkCode = ref.msg;
      }
      },
    );
  }
  toTimestamp(model){
    const datum = new Date(Date.UTC(model.year(), model.month(), model.date(), model.hour(), model.minute(),0));
    return datum.getTime() / 1000;
  }


  myimage: Observable<any>;
  onFileChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
      this.myimage = new Observable((subscriber: Subscriber<any>) => {
        this.base64.readFile(file, subscriber);
      });
      this.myimage.subscribe((resultBase64) => {
        this.dataBase64Image = resultBase64;
        this.changeImage = true;
      });
      this.checkImage = false;
    }
    else{
      this.showMessage.error(' Vui lòng chọn đúng định dạng ảnh');
    }
  }

  uploadImage() {
    document.getElementById('create-post-avatar').click();
  }

  getAllCity(){
    this.generalService.getListCity().subscribe((ref: any) => {
      this.city = ref.data;
    });
  }

  changeDistrictByCity(itemId: string, flag){
    if (flag !== 0){
      this.infoUser.patchValue({
        district: '',
        ward: '',
      });
    }
    if (itemId !== ''){
      this.generalService.getDetailLocation(itemId).subscribe((res: any) => {
        this.generalService.getListDistrict(res.data.id).subscribe((ref: any) => {
          this.district = ref.data;
          this.ward = [];
        });
      });
    }
    else{
      this.infoUser.patchValue({
        district: '',
        ward: '',
      });
      this.district = [];
      this.ward = [];
    }
  }

  changeWardByDistrict(itemId: string, flag){
    if (flag !== 0){
      this.infoUser.patchValue({
        ward: '',
      });
    }
    if (itemId !== '') {
      this.ward = [];
      this.generalService.getDetailLocation(itemId).subscribe((res: any) => {
        this.generalService.getListWard(res.data.id).subscribe((ref: any) => {
          this.ward = ref.data;
        });
      });
    }
    else{
      this.ward = [];
    }
  }

}
