import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { IndustryUnitService } from "@app/_services";
import { MatDialogRef } from "@angular/material/dialog";
interface FoodNode {
  name: string;
  code?: string;
  parent_code?: string;
  children?: FoodNode[];
}
interface FoodNode1 {
  name: string;
  parent_code_lv1?: string;
  parent_code?: string;
  children?: FoodNode[];
}
@Component({
  selector: 'app-dialog-select-unit-vertical',
  templateUrl: './dialog-select-unit-vertical.component.html',
  styleUrls: ['./dialog-select-unit-vertical.component.scss']
})
export class DialogSelectUnitVerticalComponent implements OnInit {
  dataEducation1: any;
  code_active_lv_3: string;
  dataMoetUnit: any = { name: '', id: '' };
  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  treeControl2 = new NestedTreeControl<FoodNode1>(node2 => node2.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  dataSource2 = new MatTreeNestedDataSource<FoodNode1>();
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
  hasChild2 = (_: number, node2: FoodNode1) => !!node2.children && node2.children.length > 0;
  code_active = '';
  code_active_lv_2 = '';
  close_lv_2 = false;
  close_lv_3 = false;
  check_child_lv_1 = false;
  check_child_lv_2 = false;
  button = {
    is_active_import_user: false,
    is_active_export_user: false,
  }

  dataPhong: any = undefined;
  valueFilterSo: any;
  valueFilterPhong: any;
  valueSearch: any;
  resultSearch: any = undefined;
  checkResultSearch: boolean = false;
  isLoading: boolean = false;

  constructor(
    private industryUnitService: IndustryUnitService,
    private dialogRef: MatDialogRef<DialogSelectUnitVerticalComponent>) { }
  ngOnInit() {
    this.getDataEducation1();
  }

  // click level 1
  activeLv1(code: string, itemName?: string, itemId?: string) {
    this.dataMoetUnit = { name: itemName, id: itemId }

    this.code_active_lv_2 = '';
    this.code_active_lv_3 = '';

    this.industryUnitService.getListEducationLv2(code).subscribe(
      (res: any) => {
        let count_phong_gd: number = 0;
        let count_thpt: number = 0;
        let code_parent: string;
        let childs_phong_gd = [];
        let childs_thpt = [];
        (res.data).forEach((e: any) => {
          code_parent = e.soGD;
          if (e.unit == 2) {
            count_phong_gd++;
            childs_phong_gd.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon: e.countMamNon,
              countTH: e.countTH,
              countTHCS: e.countTHCS,
              nameSoGD: e.nameSoGD,
              namePhongGD: e.namePhongGD
            });
          } else {
            count_thpt++;
            childs_thpt.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon: e.countMamNon,
              countTH: e.countTH,
              countTHCS: e.countTHCS,
              nameSoGD: e.nameSoGD,
              namePhongGD: e.namePhongGD
            });
          }
        });
        let resultEducationLv2 = [
          {
            name: `${count_phong_gd} Phòng giáo dục`,
            parent_code: `${code_parent}`,
            children: childs_phong_gd
          },
          {
            name: `${count_thpt} Trường THPT`,
            parent_code: `${code_parent}`,
            children: childs_thpt
          }
        ];
        this.dataSource.data = resultEducationLv2;

        this.check_child_lv_1 = this.checkDataLv1(resultEducationLv2, code);
        this.code_active = code;
        this.closeTree(code);
        this.close_lv_2 = true;
      },
      (err) => {
      }
    )
  }
  // click level 2
  activeLv2(code2: string, itemName?: string, itemId?: string) {

    this.dataMoetUnit = { name: itemName, id: itemId }
    this.code_active_lv_3 = '';
    this.industryUnitService.getListEducationLv3(code2).subscribe(
      (res: any) => {
        let count_thcs: number = 0;
        let count_tieu_hoc: number = 0;
        let count_mau_giao: number = 0;
        let code_parent: string;
        let code_parent_lv1: string;
        let childs_thcs = [];
        let childs_tieu_hoc = [];
        let childs_mau_giao = [];
        (res.data).forEach((e: any) => {
          code_parent = e.phongGD;
          code_parent_lv1 = e.soGD;
          if (e.unit == 4) {
            count_thcs++;
            childs_thcs.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon: e.countMamNon,
              countTH: e.countTH,
              countTHCS: e.countTHCS,
              nameSoGD: e.nameSoGD,
              namePhongGD: e.namePhongGD
            });
          } else if (e.unit == 5) {
            count_tieu_hoc++;
            childs_tieu_hoc.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon: e.countMamNon,
              countTH: e.countTH,
              countTHCS: e.countTHCS,
              nameSoGD: e.nameSoGD,
              namePhongGD: e.namePhongGD
            });
          } else {
            count_mau_giao++;
            childs_mau_giao.push({
              code: e.code,
              id: e.id,
              logo: e.logo,
              name: e.name,
              parent_code: null,
              parent_code_lv1: e.soGD,
              sort: 0,
              status: 0,
              unit: e.unit,
              countMamNon: e.countMamNon,
              countTH: e.countTH,
              countTHCS: e.countTHCS,
              nameSoGD: e.nameSoGD,
              namePhongGD: e.namePhongGD
            })
          }
        });
        let resultEducationLv3 = [
          {
            name: `${count_thcs} Trường THCS`,
            parent_code_lv1: `${code_parent_lv1}`,
            parent_code: `${code_parent}`,
            children: childs_thcs
          },
          {
            name: `${count_tieu_hoc} Trường tiểu học`,
            parent_code_lv1: `${code_parent_lv1}`,
            parent_code: `${code_parent}`,
            children: childs_tieu_hoc
          },
          {
            name: `${count_mau_giao} Trường mầm non`,
            parent_code_lv1: `${code_parent_lv1}`,
            parent_code: `${code_parent}`,
            children: childs_mau_giao
          },
        ];
        this.dataSource2.data = resultEducationLv3;
        this.check_child_lv_2 = this.checkDataLv2(resultEducationLv3, code2);
        this.code_active_lv_2 = code2;
        this.closeTree2(code2);
        this.close_lv_3 = true;
      },
      (err) => {
      }
    )
  }

  activeLv3(itemId: string, itemName: string) {
    this.dataMoetUnit = { name: itemName, id: itemId }
    this.code_active_lv_3 = itemId;
    // this.dataMoetUnit.unit3 = itemName;
  }

  closeTree(code: string) {
    if (this.code_active == code) {
      this.close_lv_2 = !this.close_lv_2;
    } else {
      this.activeLv1(code);
    }
  }

  checkDataLv1(data: any, code: string) {
    let check = false;
    data.forEach(element => {
      if (element.parent_code == code) {
        check = true;
      }
    });
    return check;
  }

  closeTree2(code: string, code_lv1?: string) {
    if (this.code_active_lv_2 == code) {
      this.close_lv_3 = !this.close_lv_3;
    } else {
      this.activeLv2(code);
      this.closeTree(code_lv1);
    }
  }

  checkDataLv2(data: any, code: string) {
    let check = false;
    data.forEach(element => {
      if (element.parent_code == code) {
        check = true;
      }
    });
    return check;
  }

  getDataEducation1() {
    this.isLoading = true;
    this.industryUnitService.getListEducationLv1().subscribe(
      (res: any) => {
        this.dataEducation1 = res.data;
        this.isLoading = false;
      }, (err: any) => {
        this.isLoading = false;
      }
    )
  }

  getSearchUnit(value, phong, so) {
    this.industryUnitService.searchListEducation(value, phong, so).subscribe((ref: any) => {
      this.checkResultSearch = true;
      this.resultSearch = ref.data;
    });
  }

  changeStatusSo(value) {
    this.valueFilterSo = value;
    if (value === '') {
      this.dataPhong = undefined;
      this.valueFilterPhong = '';
    }
    else {
      this.industryUnitService.getListEducationLv2(value).subscribe((ref: any) => {
        this.dataPhong = ref.data
      });
      this.getSearchUnit(this.valueSearch, this.valueFilterPhong, this.valueFilterSo)
    }
  }

  changeStatusPhong(value) {
    if (value === '') {
      this.dataPhong = undefined;
      this.valueFilterPhong = '';
    }
    else {
      this.valueFilterPhong = value
      this.getSearchUnit(this.valueSearch, this.valueFilterPhong, this.valueFilterSo)
    }
  }

  searchDialogListUnit(event, value) {
    event.keyCode === 13 ? (document.getElementById('search-value-dialog-select-unit') as HTMLInputElement).blur() : '';
    if (event.type === 'blur' && value !== '') {
      this.valueSearch = value;
      this.getSearchUnit(value, this.valueFilterPhong, this.valueFilterSo);
    }
    else {
      this.valueSearch = '';
      this.checkResultSearch = false;
    }
  }

  confirm() {
    let data: any;
    if (this.dataMoetUnit.name === '') {
      data = { status: false }
    } else {
      data = { unit: this.dataMoetUnit, status: true }
    }
    this.dialogRef.close(data)
  }

  selectUnit(item) {
    let data: any;
    this.dataMoetUnit = { name: item.name, id: item.id }
    if (this.dataMoetUnit.name === '') {
      data = { status: false }
    } else {
      data = { unit: this.dataMoetUnit, status: true }
    }
    this.dialogRef.close(data)
  }
}
