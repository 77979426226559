<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between start" style="height: 40px">
        <div>
            <p class="title-page-global title-custom">Danh sách module</p>
        </div>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between start">
        <div style="padding: 8px 0 8px 0">
            <button (click)="changeTab('tab-customer')" class="btn custom-button--tab-module {{activeTab === 'tab-customer' ? 'tab-active' : 'tab-default'}}">
                Theo khách hàng
            </button>
            <button (click)="changeTab('tab-core-admin')" class="btn custom-button--tab-module {{activeTab === 'tab-core-admin' ? 'tab-active' : 'tab-default'}} tab-for-customer">
                Theo phân hệ core
            </button>
        </div>
        <div>
            <span class="{{!showSearch == true ? 'transition-search-show': ''}}" style="margin-right: -5px;">
                    <input (keydown)="onKeyPressSearch($event, input_search.value)" #input_search type="text"
                               class="text-left btn btn-default button-search"
                               [value]="!showSearch == true? keyWord : ''"
                               [readOnly]="!showSearch == true ? false: true"
                               placeholder="{{!showSearch == true ? 'Tên, mã module' : ''}}">

                    <img src="assets/images/svg/search.svg" alt="" class="icon-search"
                         (click)="toggleClass(input_search.value)">
                </span>
            <button class="{{ showFilter == true ? 'active-filter' : '' }} btn custom-filter" (click)="showFilterClick()" style="margin-right: -10px;">
                <img class="icon-filter" src="assets/images/svg/filter_img.svg" alt="">
            </button>
            <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm2]">

                <button class="btn btn-add-edit" (click)="openDialogAddModule()">
                    <img src="assets/images/svg/add.svg">
                    Thêm module
                </button>
            </ng-template>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="flex-end center" style="padding-right: 11rem; position: relative; right: 25px" *ngIf="showFilter">
        <ng-container>
            <button class="btn btn-default mr-2 btn-filter-option {{isActive === 1 ? 'tab-active': ''}}" (click)="itemOptionClick1(1)">Kích hoạt</button>
            <button class="btn btn-default mr-2 btn-filter-option {{isActive === 0 ? 'tab-active': ''}}" (click)="itemOptionClick2(0)">Chưa kích hoạt</button>
        </ng-container>
    </div>
    <div fxFlex style="background-color: #FFFFFF;" class="content-data">
        <app-tab-core-admin *ngIf="activeTab === 'tab-core-admin' && tabActive === 0" [dataSearch]="keyWord" [isActive]="isActive" [openDialog]="openDialog"></app-tab-core-admin>
        <app-tab-customers *ngIf="activeTab === 'tab-customer' && tabActive === 1" [dataSearch]="keyWord" [isActive]="isActive" [openDialog]="openDialog"></app-tab-customers>
    </div>
</div>