import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {AccountManagerService, ListenFirebaseService} from '@app/_services/index';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Base64Service} from '@app/_services/base-64.service';
import {Observable, Subscriber} from 'rxjs';
import {ShowMessage} from '@app/_services/index';

import * as _moment from 'moment';
import {GeneralService} from '@app/_services/general.service';
import {REGEX_PHONE, REGEX_FULL_NAME, REGEX_CODE, REGEX_EMAIL} from '@app/_shared/utils/constant.ts';

const moment = _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-form-account',
    templateUrl: './dialog-form-account.component.html',
    styleUrls: ['./dialog-form-account.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class DialogFormAccountComponent implements OnInit {
    public infoUser;
    panelOpenState = true;
    urlImage: SafeResourceUrl;
    selectedFile: File;
    isIconEye: boolean = false;
    isValidate: boolean;
    codeRandom: string;
    city: any;
    district: any;
    ward: any;
    colorStatus: string;
    messageValidate = {
        fullName: [
            {type: 'required', message: 'Bạn chưa nhập tên'},
            {type: 'pattern', message: 'Tên người dùng chỉ chấp nhân  a-z, A-Z'},
            {type: 'maxlength', message: 'Không vượt quá 250 ký tự'},
            {type: 'minlength', message: 'Ít nhất 2 ký tự trở lên'},
        ],
        userCode: [
            {type: 'required', message: 'Bạn chưa nhập mã người dùng'},
            {type: 'pattern', message: 'Mã người dùng chỉ chấp nhân  a-z, A-Z, _, - '},
            {type: 'maxlength', message: 'Không vượt quá 50 ký tự'},
            {type: 'minlength', message: 'Ít nhất 2 ký tự trở lên'},
        ],
        userName: [
            {type: 'required', message: 'Bạn chưa nhập tên tài khoản'},
            {type: 'pattern', message: 'Mã người dùng chỉ chấp nhân  a-z, A-Z, _, - '},
            {type: 'maxlength', message: 'Không vượt quá 50 ký tự'},
            {type: 'minlength', message: 'Ít nhất 2 ký tự trở lên'},
        ],
        password: [
            {type: 'required', message: 'Bạn chưa nhập mật khẩu'},
            {
                type: 'pattern',
                message: 'Mật khẩu phải chứa ít nhất 6 ký tự, Mật khẩu phải chứa ít nhất 1 chữ số, Mật khẩu phải chứa ít nhất 1 ký tự chữ (chữ hoa hoặc thường)'
            }
        ],
        address: [
            {type: 'maxlength', message: 'Chỉ tối đa là 500 ký tự'}
        ],
        email: [
            {type: 'required', message: 'Bạn chưa nhập email'},
            {type: 'email', message: 'Email chưa đúng định dạng'}
        ],
        gender: [
            {type: 'required', message: 'Bạn chọn giới tính'}
        ],
        phone: [
            {type: 'required', message: 'Bạn chưa nhập số điện thoại'},
            {type: 'pattern', message: 'Không đúng số điện thoại'},
        ],
    };

    statusUser = [
        {id: 0, name: 'Chưa kích hoạt', color: '#FF8038'},
        {id: 1, name: 'Kích hoạt', color: '#1355C1'},
        {id: 2, name: 'Bị khóa', color: '#283C63'},
        {id: 3, name: 'Bị xóa', color: '#C4C4C4'},
    ];

    isLoading = false;

    constructor(
        public dialogRef: MatDialogRef<DialogFormAccountComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private fb: FormBuilder,
        private accountManagerService: AccountManagerService,
        private sanitizer: DomSanitizer,
        private base64: Base64Service,
        private showMessage: ShowMessage,
        private generalService: GeneralService,
        private listenFirebaseService: ListenFirebaseService,
    ) {
    }

    ngOnInit() {
        console.log(this.data.infoUser);
        
        let status = this.data.status === 'add' ? true : false;
        this.urlImage = this.data.status === 'update' && this.data.infoUser.avatar ? this.data.infoUser.avatar !== '' : 'assets/images/png/avatar.png';
        this.data.infoUser = this.data.infoUser ? this.data.infoUser : '';
        // this.codeRandom = this.data.status === 'add' ? this.data.code : this.data.infoUser.userCode;
        this.infoUser = this.fb.group({
            fullName: [!status ? this.data.infoUser.fullName : '', [Validators.required, Validators.minLength(2), Validators.maxLength(250), Validators.pattern(REGEX_FULL_NAME)]],
            userCode: [!status ? this.data.infoUser.userCode : '', [Validators.required, Validators.maxLength(50), Validators.minLength(2), Validators.pattern(/^[\w-]{0,}$/)]],
            userName: [!status ? this.data.infoUser.userName : '', [Validators.required, Validators.maxLength(50), Validators.minLength(2),, Validators.pattern(/^[\w-]{0,}$/)]],
            password: [!status ? this.data.infoUser.password : '', status ? [Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$')] : null],
            gender: [!status ? this.data.infoUser.gender : 1, [Validators.required]],
            birthday: [!status ? this.data.infoUser.birthday != null ? _moment(this.data.infoUser.birthday) : '' : ''],
            email: [!status ? this.data.infoUser.email : '', [Validators.required, Validators.email]],
            phone: [!status ? this.data.infoUser.phone : '', [Validators.required, Validators.pattern(REGEX_PHONE)]],
            cityId: [!status ? this.data.infoUser.cityId : ''],
            ward: [!status ? this.data.infoUser.ward : ''],
            district: [!status ? this.data.infoUser.district : ''],
            address: [!status ? this.data.infoUser.address : '', [Validators.maxLength(500)]],
            userStatus: [!status ? this.data.infoUser.userStatus : 0],
            logo: !status ? this.data.infoUser.logo : null,
        });

        this.getColorStatus();
        if (status) {
            this.accountManagerService.getCodeRandom('US').subscribe((res: any) => this.infoUser.get('userCode').setValue(res.data));
        } else {
            this.changeDistrictByCity(this.data.infoUser.cityId);
            this.changeWardByDistrict(this.data.infoUser.district);
        }
        this.getAllCity();
    }

    getColorStatus(event = null) {
        let key = this.statusUser.map(item => item.id).indexOf(event ? parseInt(event.target.value) : this.infoUser.get('userStatus').value);
        return this.colorStatus = this.statusUser[key]?.color;
    }

    showPassword(element) {
        if (element.type === 'password') {
            element.type = 'text';
            this.isIconEye = false;
        } else {
            element.type = 'password';
            this.isIconEye = true;
        }
    }

    toTimestamp(model) {
        var datum = new Date(Date.UTC(model.year(), model.month(), model.date(), model.hour(), model.minute(), 0));
        return datum.getTime() / 1000;
    }

    onSubmit(value) {
        if (value.birthday !== '' && parseInt(value.birthday.format('X')) > parseInt(_moment().format('X'))) {
            return this.showMessage.warring('Ngày sinh không được lớn hơn ngày hiện tại !');
        }

        value.birthday = this.toTimestamp(moment(value.birthday));
        value.gender = Number(value.gender);
        value.userStatus = Number(value.userStatus);
        value.cityId = value.cityId === 'null' || value.cityId === null ? '' : value.cityId;
        value.district = value.district === 'null' || value.district === null ? '' : value.district;
        value.ward = value.ward === 'null' || value.ward === null ? '' : value.ward;
        value.fullName = value.fullName.trim();
        value.email = value.email.trim();
        if (value.fullName.trim() === '') {
            return this.infoUser.get('fullName').setValue(value.fullName.trim());
        }

        this.isLoading = true;
        if (this.data.status === 'add') {
            this.accountManagerService.createUser(value).subscribe((ref: any) => {
                this.isLoading = false;
                if (ref.status === 1) {
                    this.dialogRef.close(ref);
                } else {
                    this.showMessage.error(ref.msg);
                }
            }, err => this.isLoading = false);
        } else {
            value.id = this.data.infoUser.id;
            delete value.password;
            this.accountManagerService.updateUser(value, this.data.infoUser.id).subscribe((ref: any) => {
                this.isLoading = false;
                if (ref.status === 1) {
                    this.dialogRef.close(ref);
                } else {
                    this.showMessage.error(ref.msg);
                }

            }, err => this.isLoading = false);
        }
    }

    getAllCity() {
        this.generalService.getListCity().subscribe((ref: any) => {
            this.city = ref.data;
        });
    }

    changeDistrictByCity(cityId: string, isCheckFirst = false) {
        if (cityId === '') {
            this.infoUser.get('district').setValue('');
            this.infoUser.get('ward').setValue('');
            this.district = [];
            return this.ward = [];
        }
        this.generalService.getListDistrict(cityId).subscribe((ref: any) => {
            this.district = ref.data;
            if (isCheckFirst) {
                this.infoUser.get('district').setValue('');
                this.infoUser.get('ward').setValue('');
                this.ward = [];
            }
        });
    }

    changeWardByDistrict(districtCode: string) {
        if (districtCode === '') {
            this.infoUser.get('ward').setValue('');
            return this.ward = [];
        }
        this.generalService.getListWard(districtCode).subscribe((ref: any) => {
            this.ward = ref.data;
        });
    }

    myimage: Observable<any>;

    onFileChange($event: Event) {

        const file = ($event.target as HTMLInputElement).files[0];

        this.myimage = new Observable((subscriber: Subscriber<any>) => {
            this.base64.readFile(file, subscriber);
        });
        this.myimage.subscribe((resultBase64) => {
            this.infoUser.get('logo').setValue(resultBase64);
        });
        return;

    }

    uploadImage() {
        document.getElementById('create-post-avatar').click();
    }
}
