import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {Router} from '@angular/router';
import {DialogPostManagerListObjectComponent} from '@app/_modules/post-manager/dialogs/dialog-post-manager-list-object/dialog-post-manager-list-object.component';
import {PostManagerService} from '@app/_services/post-manager.service';
import * as _moment from 'moment';
import {ShowMessage} from '@app/_services';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import * as $ from 'jquery';
import {POST_PERMISSION} from "../../constants";
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-post-manager-list',
  templateUrl: './post-manager-list.component.html',
  styleUrls: ['./post-manager-list.component.scss']
})
export class PostManagerListComponent implements OnInit {
  showSearch: boolean = false;
  showFilter: boolean = false;
  dataSearch: string = '';
  scope: number;
  mail: number;
  comment: number;
  check = false;
  dataColumn = ['stt', 'post', 'limit', 'group', 'object', 'select_email', 'select_comment', 'calendar_publish', 'send', 'status'];
  dataSource: any;
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  POST_PERMISSION=POST_PERMISSION;
  constructor(
      private dialog: MatDialog,
      private router: Router,
      private postManagerService: PostManagerService,
      private showMessage: ShowMessage,
      private db: AngularFireDatabase,
      private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit(): void {
    this.getAllPostManager();
    $("#leader-line-defs").remove();
    if (this.permissionsService.getPermission(this.POST_PERMISSION.perm4) 
    || this.permissionsService.getPermission(this.POST_PERMISSION.perm5)
    || this.permissionsService.getPermission(this.POST_PERMISSION.perm6)
    || this.permissionsService.getPermission(this.POST_PERMISSION.perm7)
    || this.permissionsService.getPermission(this.POST_PERMISSION.perm8)){
      this.dataColumn.push('tool');
    }

  }

  checkFireBase(param){
    return this.db.list(`core_admin/post/${param}/${environment.userID}`).valueChanges().subscribe((res: any) => {
      if (res.length > 0) {
        let parseRes = JSON.parse(res[0]);
        if (parseRes.status === true) {
          this.showMessage.success(parseRes.message);
          this.getAllPostManager();
          return this.db.list(`core_admin/post/${param}/${environment.userID}`).remove();
        }

        return this.showMessage.error(parseRes.message);
      }
    });
  }

  getAllPostManager(keyWord?: string, scopePost?: any, comment?: any, senMail?: any){
    this.postManagerService.getDataAllPostManager(this.pageSize, this.pageIndex, keyWord, scopePost, comment, senMail).subscribe((ref: any) => {
      ref.data.data.forEach(function(item) {
        item.timePublish = _moment(item.sendSchedule).format('mm:hh');
        item.datePublish = _moment(item.sendSchedule).format('DD/MM/YYYY');
      });
      this.dataSource = ref.data.data;
      this.totalRecord = ref.data.totalItems;
    });
  }

  onChangePaginate(event){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getAllPostManager();
  }
  toggleClass(){
    this.showSearch = !this.showSearch;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
    this.dataSearch = '';
  }
  checkFilter(value1, value2, value3){
    this.scope = value1;
    this.mail = value2;
    this.comment = value3;
  }

  checkText(event, value) {
    if (this.showSearch === true) {
      event.keyCode === 13 ? (document.getElementById('search-value') as HTMLInputElement).blur() : '';
      this.dataSearch = value;
      if (this.showFilter === false){
          this.scope = undefined;
          this.comment = undefined;
          this.mail = undefined;
      }
      this.getAllPostManager(value, this.scope, this.comment, this.mail);
    }
  }

  filter(){
    this.getAllPostManager(this.dataSearch, this.scope, this.comment, this.mail);
  }


  openForm(action: string, id?: string, ){
    id = id ? id : 'a';
    this.router.navigate([`${'post-manager/form'}/${id}`], { queryParams: {msg: action}});
  }

  confirmDelete(itemName: string, itemId: string){
    const dialogConfirmDelete = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '30%',
      data: {
        title: 'Bạn đang thực hiện xóa bài viết',
        message: 'Bạn có chắc muốn xóa bài viết này không ?',
        btnOkText: 'XÓA',
        btnCancelText: 'HỦY',
        data: {
          name: itemName,
        }
      }
    });
    dialogConfirmDelete.afterClosed().subscribe((ref: any) => {
      if (ref === true){
        this.postManagerService.deletePostManager(itemId).subscribe((res: any) => {
          if (ref.status === 0) return this.showMessage.error(ref.msg);
          this.checkFireBase('delete');
        });
      }
    });
  }
  confirmCancelSend(itemName: string, itemId: string){
    const dialogConfirmCancelSend = this.dialog.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '30%',
      data: {
        title: 'Bạn đang thực hiện huỷ gửi bài viết',
        content: 'Bạn có chắc muốn huỷ gửi bài viết này không ?',
        status: false,
        btnCancelText: 'HỦY',
        btnOkText: 'XÁC NHẬN',
        data: {
          name: itemName,
        }
      }
    });
    dialogConfirmCancelSend.afterClosed().subscribe((ref: any) => {
      if (ref === true){
        this.postManagerService.cancelPostManager(itemId).subscribe((res: any) => {
          this.showMessage.success(res.msg);
          this.getAllPostManager();
        });
      }
    });
  }
  confirmSendTo(itemName: string, itemId: string){
    const dialogConfirmSend = this.dialog.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '30%',
      data: {
        title: 'Bạn đang thực hiện gửi lại bài viết',
        content: 'Bạn có chắc muốn gửi lại bài viết này không ?',
        status: true,
        btnCancelText: 'HỦY',
        btnOkText: 'XÁC NHẬN',
        data: {
          name: itemName,
        }
      }
    });
    dialogConfirmSend.afterClosed().subscribe((ref: any) => {
      if (ref === true){
        this.postManagerService.sendToPostManager(itemId).subscribe((res: any) => {
          this.showMessage.success(res.msg);
          this.getAllPostManager();
        });
      }
    });
  }
  confirmHidden(itemName: string, itemId: string){
    const dialogConfirmHidden = this.dialog.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '30%',
      data: {
        title: 'Bạn đang thực hiện ẩn bài viết',
        content: 'Bạn có chắc muốn ẩn bài viết này không ?',
        status: false,
        btnCancelText: 'HỦY',
        btnOkText: 'XÁC NHẬN',
        data: {
          name: itemName,
        }
      }
    });
    dialogConfirmHidden.afterClosed().subscribe((ref: any) => {
      if (ref === true){
        this.postManagerService.hidePostManager(itemId).subscribe((res: any) => {
          this.showMessage.success(res.msg);
          this.getAllPostManager();
        });
      }
    });
  }

  showListObject(itemId: string){
    const showList = this.dialog.open(DialogPostManagerListObjectComponent, {
      height: 'auto',
      width: '60%',
      data: {id: itemId}
    });
  }
}
