<p style="width: 100%; font-size: 18px;text-align: center; margin-top: 20px" *ngIf="length === 0">Không có khách hàng được áp dụng</p>
<table mat-table [dataSource]="dataSource" class="table-el" *ngIf="length > 0">
    <ng-container matColumnDef="stt">
        <th mat-header-cell *matHeaderCellDef style="width: 5%">STT</th>
        <td mat-cell *matCellDef="let item, let i = index">{{i + 1}}</td>
    </ng-container>
    <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef style="width: 20%">Khách hàng</th>
        <td mat-cell *matCellDef="let item" ><a class="link_table" href="javascript:;" (click)="opendetaiTenant(item.id,'tab1');">{{item.fullname}}</a></td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
        <td mat-cell *matCellDef="let item">{{item.code}}</td>
    </ng-container>
    <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
        <td mat-cell *matCellDef="let item"><a class="link_table" href="javascript:;">{{item.tenantUrl}}</a></td>
    </ng-container>
    <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
        <td mat-cell *matCellDef="let item">{{item.hotline}}</td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
        <td mat-cell *matCellDef="let item">{{item.email}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Trạng thái</th>
        <td mat-cell *matCellDef="let item">
            <span *ngIf="item.status === 0" class="box-user-status" [style]="item.status === 0 ? 'background: #77B6D1' : ''">Chưa kích họat</span>
            <span *ngIf="item.status === 1" class="box-user-status" [style]="item.status === 1 ? 'background: #1355C1' : ''">Dùng thử</span>
            <span *ngIf="item.status === 99" class="box-user-status" [style]="item.status === 99 ? 'background: #8950FC' : ''">Khác</span>
            <span *ngIf="item.status === 3" class="box-user-status" [style]="item.status === 3 ? 'background: #6B6B6B' : ''">Hết hạn dùng thử</span>
            <span *ngIf="item.status === 4" class="box-user-status" [style]="item.status === 4 ? 'background: #CC4D4D' : ''">Hết hạn sử dụng</span>
            <span *ngIf="item.status === 2" class="box-user-status" [style]="item.status === 2 ? 'background: #FF8038' : ''">Đang sử dụng</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="tool">
        <th mat-header-cell *matHeaderCellDef style="width: 5%">Công cụ</th>
        <td mat-cell *matCellDef="let item" ><img style="cursor: pointer; text-align: center" src="assets/images/icons/icon-22.svg" (click)="confirmDelete(item.fullname, item.id)"></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
    <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
</table>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
