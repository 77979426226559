import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {DialogListUserSoftwareComponent} from '@app/_modules/module-software-manager/dialogs/dialog-list-user-software/dialog-list-user-software.component';
import {DialogFormAddEditSoftwareComponent} from '@app/_modules/module-software-manager/dialogs/dialog-form-add-edit-software/dialog-form-add-edit-software.component';
import {ListenFirebaseService, ModuleSoftwareManagerService} from '@app/_services';
import {DialogChangeStatusComponent} from '../../dialogs/dialog-change-status/dialog-change-status.component';
import {ShowMessage} from "@app/_services";
import {AngularFireDatabase} from "@angular/fire/database";
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { DialogDeleteSofwareComponent } from '../../dialogs/dialog-delete-sofware.component/dialog-delete-sofware.component';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import * as $ from 'jquery';
import {NgxPermissionsService} from 'ngx-permissions';
import { MODULE_PERMISSION } from '@app/_modules/module-software-manager/constant';

@Component({
    selector: 'app-software-list',
    templateUrl: './software-list.component.html',
    styleUrls: ['./software-list.component.scss']
})
export class SoftwareListComponent implements OnInit {
    dataColumn = ['stt', 'fullname', 'code', 'user_count', 'is_active'];
    permissionModules = MODULE_PERMISSION;
    dataSource: MatTableDataSource<any>;
    pageSize = 15;
    pageIndex = 0;
    totalRecord = 0;
    showSearch: boolean = true;
    dataSearch: string = '';
    typeModule = "subsystem";
    isLoading:boolean = false;

    constructor(
        private dialog: MatDialog,
        private moduleSoftwareManagerService: ModuleSoftwareManagerService,
        private showMessage: ShowMessage,
        private db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService,
        private permissionsService: NgxPermissionsService
    ) {
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngOnInit(): void {
        if (this.permissionsService.getPermission(this.permissionModules.perm4) || this.permissionsService.getPermission(this.permissionModules.perm5)){
            this.dataColumn.push('tool');
        }
        this.loadDataItems();
        $("#leader-line-defs").remove();
    }

    checkFireBase(param){
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase(param, 'subsystem', subscriber);
        });
        listenFb.subscribe((ref) => {
            if (ref === true) {
                this.isLoading = false;
                this.loadDataItems();
            }else{
                this.isLoading = false;
            }
        });
    }

    loadDataItems() {
        this.isLoading = true;
        this.moduleSoftwareManagerService.getAllSubSystems(this.pageSize, this.pageIndex, this.dataSearch).subscribe((ref: any) => {
            this.dataSource = ref.data.data;
            this.totalRecord = ref.data.totalItems;
            this.isLoading = false;
        });
    }

    onChangePaginate(event){
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.loadDataItems();
    }

    keyPressSearch(event, value){
        if (event.keyCode  === 13 || event.keyCode  === 9){
            this.pageIndex = 0;
            this.dataSearch = value.trim();
            this.loadDataItems();
        }
    }

    confirmDelete(value) {
        if(value.tenantCount > 0) {
            const dialogRef = this.dialog.open(DialogDeleteSofwareComponent, {
                height: 'auto',
                width: '40%',
                data: {
                    title: 'Bạn không thể thực hiện xoá',
                    message: 'Phân hệ đang có dữ liệu của khách hàng',
                    btnOkText: 'ĐÓNG',
                    data: {
                        id: value.id,
                        name: value.fullname,
                    },    
                },
            });
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm){
                }
            });
        } 
        if(value.tenantCount == 0) {
            const dialogRef = this.dialog.open(DialogDeleteComponent, {
                height: 'auto',
                width: '40%',
                data: {
                    title: 'Bạn đang thực hiện xóa phân hệ',
                    message: 'Bạn có chắc muốn xóa dữ liệu này không ?',
                    btnOkText: 'XÓA',
                    btnCancelText: 'HỦY',
                    data: {
                        id: value.id,
                        name: value.fullname,
                    },
                },
            });
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm){
                    this.checkFireBase("delete");
                    this.isLoading = true;
                    this.moduleSoftwareManagerService.removeSubSystem(value.id).subscribe(
                        (res: any) => {                  
                            if (res.status === 0) {
                                this.showMessage.error(res.msg);
                                this.isLoading =false;
                            }
                        },
                        (err) => {
                            this.isLoading = false;
                        });
                    
                    setTimeout(() => {
                        if(this.isLoading){
                            this.isLoading = false;
                        }
                    }, TIME_OUT_LISTEN_FIREBASE);
                };
            });
        }  
    }

    changeStatusSubSystem(event, infoSchoolYear: any){
        this.checkFireBase("change_status");
        let data = {
            status: event.checked,
            infoSchoolYear,
            message: ''
        }
        if(event.source.checked) {
            data.message = 'Bạn đã thực hiện kích hoạt phân hệ';
        } else data.message = 'Bạn đã thực hiện bỏ kích hoạt phân hệ';
        const confirmActive = this.dialog.open(DialogChangeStatusComponent, {
            height: 'auto',
            width: '40%',
            data: data
        });
        confirmActive.afterClosed().subscribe((confirm: boolean) => {
            if (confirm){
                this.isLoading = true;
                this.moduleSoftwareManagerService.changeStatus(infoSchoolYear.id, event.checked === true ? 1 : 0).subscribe(
                    (res: any) => {
                        if (res.status === 0) {
                            this.showMessage.error(res.msg); 
                        }      
                    },
                    (err:any) => {
                        this.isLoading = false;
                    });
                    setTimeout(() => {
                        if(this.isLoading){
                            this.isLoading = false;
                        }
                    }, TIME_OUT_LISTEN_FIREBASE);
            }else {
                event.source.checked = !event.checked;
            }
        });
    }

    showListUserSoftware(infoSubSystem : any) {
        this.dialog.open(DialogListUserSoftwareComponent, {
            height: 'auto',
            width: '60%',
            data: {
                infoSubSystem
            }
        });
    }

    dialogUpdateSubSystem(data: any){
        const dialogRef = this.dialog.open(DialogFormAddEditSoftwareComponent, {
            height: 'auto',
            width: '60%',
            data: {
                data,
                status: 'update'
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            this.checkFireBase('update');
            setTimeout(() => {
                if(this.isLoading){
                    this.isLoading = false;
                }
            }, TIME_OUT_LISTEN_FIREBASE);
        });
    }

    openDialogAddSoftwareModule() {
        const dialogRef = this.dialog.open(DialogFormAddEditSoftwareComponent, {
            height: 'auto',
            width: '60%',
            data: {
                data: null,
                status: 'add'
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            this.checkFireBase("create");
            setTimeout(() => {
                if(this.isLoading){
                    this.isLoading = false;
                }
            }, TIME_OUT_LISTEN_FIREBASE);
        });
    }

    toggleClass() {
        (document.getElementById('search__input') as HTMLInputElement).value = "";
        // console.log(document.getElementById('search__input').value)
        this.showSearch = !this.showSearch;
    }

}
