import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-show-data-action-history',
  templateUrl: './dialog-show-data-action-history.component.html',
  styleUrls: ['./dialog-show-data-action-history.component.scss']
})
export class DialogShowDataActionHistoryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
