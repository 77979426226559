<div class="dialog-body">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="container-fluid custom-scroll">
        <table mat-table [dataSource]="dataSource" class="table-el">

            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef> STT</th>
                <td class="border-first-5" mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef> Tên khách hàng</th>
                <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Mã</th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef> Tên miền</th>
                <td mat-cell *matCellDef="let element"> {{subString(element?.tenantUrl)}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>SĐT</th>
                <td mat-cell *matCellDef="let element"> {{element?.hotline}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Trạng thái</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                        <select disabled class="form-control col-md-8 btn-status" #status [ngStyle]="{'background': statusUser[element?.status]?.color}">
                            <ng-container *ngFor="let item of statusUser , let i = index">
                                <option class="font-option" [value]="i" [selected]="i == element.status ? true : false">
                                    {{item.name}}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="tools">
                <th class="text-right" mat-header-cell *matHeaderCellDef> Công cụ</th>
                <td class="border-last-5" mat-cell *matCellDef="let element">
                    <button class="btn btn-block" style="width: 50px; float: right" (click)="openDialogDelete(element)">
                        <img src="assets/images/svg/delete.svg" alt="">
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <ng-container *ngIf="length == 0">
            <span>Chưa có dữ liệu</span>
        </ng-container>

        <mat-paginator *ngIf="length > 5" appStylePaginator showFirstLastButtons [length]="length" [pageSize]="5" [pageSizeOptions]="[5,10,20]" [pageIndex]="currentPage" (page)="handlePage($event)" class="table-paginate-tab-global"></mat-paginator>
    </div>
</div>