<h2 mat-dialog-title class="text-center" *ngIf="!hideButtonAdd">Thêm khách hàng mới</h2>
<h2 mat-dialog-title class="text-center" *ngIf="hideButtonAdd">Chỉnh sửa thông tin khách hàng</h2>
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<form [formGroup]="tenantForm" class="form" (ngSubmit)="onClickSubmit();" enctype="multipart/form-data">
    <mat-dialog-content class="mat-typography">
        <div class="title-dialog-center-global">
            <div class="avatar-custom">
                <div class="img-module" (click)="uploadImage()">
                    <img [src]="urlImage ? urlImage : 'https://increasify.com.au/wp-content/uploads/2016/08/default-image.png'" class="avatar">
                    <div class="box-image">
                        <label>
                            <mat-icon>photo library</mat-icon>
                            <span class="text-add-image">Thêm ảnh</span>
                        </label>
                    </div>
                    <input type="file" class="avatar-file" name="avatar" style="display: none;" id="inputUploadFile" (change)="onFileChange($event)">
                </div>

            </div>
        </div>
        <!-- <form> -->
        <!--Thông tin cơ bản-->
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header" style="background: #E2F4FC;">
                <mat-panel-title class="panel-title custom-space-left">
                    Thông tin cơ bản
                </mat-panel-title>
            </mat-expansion-panel-header>
            <br>
            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3">Tên khách hàng<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="fullname" type="text" class="form-control input-field-global" placeholder="Nhập tên khách hàng">
                        <div *ngIf="this.tenantForm.get('fullname').invalid && (submitted || this.tenantForm.get('fullname').dirty || this.tenantForm.get('fullname').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('fullname').errors.required">
                                    Tên khách hàng là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('fullname').errors.minlength || this.tenantForm.get('fullname').errors.maxlength">
                                    Tên khách hàng cần nhập 2-250 ký tự
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('fullname').errors.pattern">
                                    Tên khách hàng chỉ chấp nhận các ký tự đặc biệt: _ - ( ) [ ]
                                </p>
                            </mat-error>
                            <!-- <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('fullname').errors.maxlength">
                                    Tên khách hàng nhập tối đa 250 ký tự
                                </p>
                            </mat-error> -->
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Mã khách hàng<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="code" (keyup)="valueCodeChange($event)" type="text" class="form-control input-field-global" placeholder="Nhập mã khách hàng" [attr.disabled]="hideButtonAdd ? 'disabled' : null">
                        <div *ngIf="this.tenantForm.get('code').invalid && (submitted || this.tenantForm.get('code').dirty || this.tenantForm.get('code').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('code').errors.required">
                                    Mã khách hàng là bắt buộc
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('code').errors.minlength || this.tenantForm.get('code').errors.maxlength">
                                    Mã khách hàng cần nhập 2-50 ký tự
                                </p>
                            </mat-error>
                            <!-- <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('code').errors.maxlength">
                                    Mã khách hàng tối đa 50
                                </p>
                            </mat-error> -->
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('code').errors.pattern">
                                    Mã khách hàng chưa đúng định dạng bao gồm các ký tự chữ, số, _, -
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Hotline<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="hotline" type="text" class="form-control input-field-global" placeholder="Nhập hotline">
                        <div *ngIf="this.tenantForm.get('hotline').invalid && (submitted || this.tenantForm.get('hotline').dirty || this.tenantForm.get('hotline').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('hotline').errors.required">
                                    Hotline là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('hotline').errors.pattern">
                                    Hotline chưa đúng định dạng
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Email<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="email" type="text" class="form-control input-field-global" placeholder="Nhập email">
                        <div *ngIf="this.tenantForm.get('email').invalid && (submitted || this.tenantForm.get('email').dirty || this.tenantForm.get('email').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('email').errors.required">
                                    Email là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('email').errors.email">
                                    Email chưa đúng định dạng
                                </p>
                            </mat-error>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Trạng thái<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <div class="col-md-8 pl-0 pr-0">
                            <select class="form-control input-field-global" formControlName="status">
                                <option *ngFor="let item of statusUser" class="font-option" [value]="item.id">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </mat-expansion-panel>
        <!--Thông tin cơ bản-->

        <!--Thông tin nâng cao-->
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header" style="background: #E2F4FC;">
                <mat-panel-title class="panel-title custom-space-left">
                    Thông tin nâng cao
                </mat-panel-title>
            </mat-expansion-panel-header>
            <br>
            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3">Người liên hệ</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="contactFullname" type="text" class="form-control input-field-global" placeholder="Nhập tên người liên hệ">
                        <div *ngIf="this.tenantForm.get('contactFullname').invalid && (submitted || this.tenantForm.get('contactFullname').dirty || this.tenantForm.get('contactFullname').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('contactFullname').errors.maxlength">
                                    Tên người liên hệ nhập tối đa 250 ký tự
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1">Email liên hệ</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="contactEmail" type="text" class="form-control input-field-global" placeholder="Nhập email liên hệ">
                        <div *ngIf="this.tenantForm.get('contactEmail').invalid && (submitted || this.tenantForm.get('contactEmail').dirty || this.tenantForm.get('contactEmail').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('contactEmail').errors.required">
                                    Email liên hệ là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('contactEmail').errors.email">
                                    Email liên hệ chưa đúng định dạng
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3">Điện thoại liên hệ</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="contactPhone" type="text" class="form-control input-field-global" placeholder="Nhập điện thoại liên hệ">
                        <div *ngIf="this.tenantForm.get('contactPhone').invalid && (submitted || this.tenantForm.get('contactPhone').dirty || this.tenantForm.get('contactPhone').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('contactPhone').errors.required">
                                    Điện thoại liên hệ là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('contactPhone').errors.pattern">
                                    Điện thoại liên hệ chưa đúng định dạng
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3">Tỉnh/thành phố</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="cityId" (change)="onSelectCity($event.target.value)">
                            <option value="" disabled [selected]="true">--Chọn tỉnh/thành phố--</option>
                            <option *ngFor="let city of cities" value="{{city.id}}">{{city.name}}</option>

                        </select>
                        <div *ngIf="this.tenantForm.get('cityId').invalid && (submitted || this.tenantForm.get('cityId').dirty || this.tenantForm.get('cityId').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('cityId').errors.required">
                                    Thành phố/Tỉnh là bắt buộc.
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Quận/huyện</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="districtId" (change)="onSelectDistrict($event.target.value)">
                            <option value="" disabled [selected]="true">--Chọn quận/huyện--</option>
                            <option *ngFor="let district of districts " value={{district.id}}>{{district.name}}</option>
                        </select>
                        <div *ngIf="this.tenantForm.get('districtId').invalid && (submitted || this.tenantForm.get('districtId').dirty || this.tenantForm.get('districtId').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('districtId').errors.required">
                                    Quận/Huyện là bắt buộc.
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Phường xã</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="wardId">
                            <option value="" disabled [selected]="true">--Chọn phường/xã--</option>
                            <option *ngFor="let ward of wards " value={{ward.id}}>{{ward.name}}</option>
                        </select>
                        <div *ngIf="this.tenantForm.get('wardId').invalid && (submitted || this.tenantForm.get('wardId').dirty || this.tenantForm.get('wardId').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('wardId').errors.required">
                                    Phường/Xã là bắt buộc.
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="div-address-custom custom-space-right">
                <label class="label-address-custom">Địa chỉ</label>
                <div class="input-address-custom">
                    <input formControlName="address" type="text" class="form-control input-field-global input-address-custom" placeholder="Nhập địa chỉ" style="width: 100%">
                    <div *ngIf="this.tenantForm.get('address').invalid && (submitted || this.tenantForm.get('address').dirty || this.tenantForm.get('address').touched)">
                        <!-- <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('address').errors.required">
                                    Địa chỉ là bắt buộc.
                                </p>
                            </mat-error> -->
                        <mat-error>
                            <p class="error-message" *ngIf="this.tenantForm.get('address').errors.maxlength">
                                Địa chỉ nhập tối đa 500 ký tự
                            </p>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Website</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="tenantWebsite" type="text" class="form-control input-field-global" placeholder="Nhập website">
                        <div *ngIf="this.tenantForm.get('tenantWebsite').invalid && (submitted || this.tenantForm.get('tenantWebsite').dirty || this.tenantForm.get('tenantWebsite').touched)">
                            <!-- <mat-error>
                                    <p class="error-message" *ngIf="this.tenantForm.get('tenantWebsite').errors.required">
                                        Tenant Website là bắt buộc.
                                    </p>
                                </mat-error> -->
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantWebsite').errors.pattern">
                                    Website khách hàng chưa đúng định dạng
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Sắp xếp<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input onlyNumber (keypress)="numberOnly($event)" formControlName="indexOrder" class="form-control input-field-global" placeholder="Nhập sắp xếp">
                        <div *ngIf="this.tenantForm.get('indexOrder').invalid && (submitted || this.tenantForm.get('indexOrder').dirty || this.tenantForm.get('indexOrder').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('indexOrder').errors.required">
                                    Sắp xếp là bắt buộc.
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <!--Thông tin nâng cao-->

        <!--Tên miền-->
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header" style="background: #E2F4FC;">
                <mat-panel-title class="panel-title custom-space-left">
                    Tên miền
                </mat-panel-title>
            </mat-expansion-panel-header>
            <br>
            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Quản trị<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input (keyup)="changeCheckAdminUrl()" formControlName="adminUrl" type="text" class="form-control input-field-global" placeholder="Nhập quản trị">
                        <div *ngIf="this.tenantForm.get('adminUrl').invalid && (submitted || this.tenantForm.get('adminUrl').dirty || this.tenantForm.get('adminUrl').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('adminUrl').errors.required">
                                    Quản trị là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('adminUrl').errors.pattern">
                                    Quản trị chưa đúng định dạng
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('adminUrl').errors.mismatch">
                                    Quản trị là duy nhất
                                </p>
                            </mat-error>

                        </div>
                        <mat-error>
                            <p class="error-message" *ngIf="checkAdminUrl">
                                Tên miền quản trị đã tồn tại trong hệ thống
                            </p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Hệ thống<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input (keyup)="changeCheckTenantUrl()" formControlName="tenantUrl" type="text" class="form-control input-field-global" placeholder="Nhập hệ thống người dùng">
                        <div *ngIf="this.tenantForm.get('tenantUrl').invalid && (submitted || this.tenantForm.get('tenantUrl').dirty || this.tenantForm.get('tenantUrl').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantUrl').errors.required">
                                    Hệ thống là bắt buộc.
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantUrl').errors.pattern">
                                    Hệ thống chưa đúng định dạng
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantUrl').errors.mismatch">
                                    Hệ thống là duy nhất
                                </p>
                            </mat-error>
                        </div>
                        <mat-error>
                            <p class="error-message" *ngIf="checkTenantUrl">
                                Tên miền Hệ thống người dùng đã tồn tại trong hệ thống
                            </p>
                        </mat-error>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <!--Tên miền-->

        <!--Tài khoản quản trị-->
        <mat-expansion-panel *ngIf="action === 'create'" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header" style="background: #E2F4FC;">
                <mat-panel-title class="panel-title custom-space-left">
                    Tài khoản quản trị
                </mat-panel-title>
            </mat-expansion-panel-header>
            <br>
            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Tên đăng nhập<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="tenantAdmin" type="text" class="form-control input-field-global" placeholder="Nhập tên đăng nhập">
                        <div *ngIf="this.tenantForm.get('tenantAdmin').invalid && (submitted || this.tenantForm.get('tenantAdmin').dirty || this.tenantForm.get('tenantAdmin').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantAdmin').errors.required">
                                    Tên đăng nhập là bắt buộc
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantAdmin').errors.minlength || this.tenantForm.get('tenantAdmin').errors.maxlength">
                                    Tên đăng nhập cần nhập 2-50 ký tự
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantAdmin').errors.pattern">
                                    Tên đăng nhập chưa đúng định dạng chỉ gồm kí tự chữ thường, số, _, -
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Mật khẩu<span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <!-- <input formControlName="tenantAdminPassword" type="text" class="form-control input-field-global" placeholder="Nhập mật khẩu" > -->
                        <input [type]="hidePassword ? 'password' : 'text'" class="form-control input-field-global" placeholder="Nhập mật khẩu" formControlName="tenantAdminPassword" id="password-field" placeholder="Nhập mật khẩu của bạn">
                        <mat-icon aria-hidden="false" class="field-icon toggle-password" aria-label="Example home icon" (click)="hidePassword = !hidePassword">
                            {{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                        <div *ngIf="this.tenantForm.get('tenantAdminPassword').invalid && (submitted || this.tenantForm.get('tenantAdminPassword').dirty || this.tenantForm.get('tenantAdminPassword').touched)">
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantAdminPassword').errors.required">
                                    Mật khẩu là bắt buộc
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantAdminPassword').errors.minlength || this.tenantForm.get('tenantAdminPassword').errors.maxlength">
                                    Mật khẩu từ 6-50 ký tự
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.tenantForm.get('tenantAdminPassword').errors.pattern">
                                    Mật khẩu chưa đúng định dạng có ít nhất 1 ký tự chữ thường và 1 ký tự số.
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <!--Tài khoản quản trị-->

        <!--Nhân viên chăm sóc-->
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header" style="background: #E2F4FC;">
                <mat-panel-title class="panel-title custom-space-left">
                    Nhân viên chăm sóc
                </mat-panel-title>
            </mat-expansion-panel-header>
            <br>
            <div class="row custom-space-right">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 ">Triển khai</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="employeeDeployId">
                            <option value="" [selected]="true">Chọn</option>
                            <option *ngFor="let eleEmployeeDeploy of employeeDeploy" value="{{eleEmployeeDeploy.id}}">
                                {{eleEmployeeDeploy.fullname}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 offset-1 ">Kinh doanh</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="employeeSaleId">
                            <option value="" [selected]="true">Chọn</option>
                            <option *ngFor="let eleEmployeeSale of employeeSale" value="{{eleEmployeeSale.id}}">
                                {{eleEmployeeSale.fullname}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <!--Nhân viên chăm sóc-->
        <!-- </form> -->
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button>Huỷ</button>
        <button mat-button mat-raised-button color="primary" type="submit"> {{!hideButtonAdd ? 'Thêm' : 'Chỉnh sửa'}}</button>
    </mat-dialog-actions>
</form>