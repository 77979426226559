<div class="wrap" fxLayout="column">
    <div fxFlex="40px" fxLayout="row" class="mb-2">
        <div fxFlex="60%">
            <h2 class="title-page-global custom-title">{{this.subjectName}} </h2>
        </div>
        <div fxFlex="40%" fxLayout="row" fxLayoutAlign="end none" style="padding-bottom: 10px">
            <button fxFlex="120px" class="btn btn-add-global btn-add" (click)="openCurriculum()">
                <img src="assets/images/svg/add.svg" alt="" class="mr-1">
                Thêm mới
            </button>
        </div>
    </div>
    <div class="bg-content-global custom-bg-content-global">
        <div class="col-sm-12 pl-0 pr-0" *ngFor="let item of listCurriculums;">
            <div class="box" fxLayout="row">
                <div fxFlex="10px">
                </div>
                <div fxFlex fxLayout="column">
                    <div fxFlex="30px" fxLayout="row" fxLayoutAlign="none">
                        <p class="box-title-name" [routerLink]="['/curriculum/list', this.dataFilter.subjectId, item.id]" 
                            [queryParams]="{tab: 'content', subjectName:subjectName, gradeId: item.gradeId}">
                            {{item.fullName |substring:40}}
                            <span class="box-title-code">({{item.code|substring:30}})</span>
                        </p>
                        <mat-icon [matMenuTriggerFor]="menu" class="menu-icon ml-2">more_horiz</mat-icon>
                        <mat-menu #menu="matMenu" class="menu-more-global mat-menu-list">
                            <button mat-menu-item class="px-5" (click)="openLinkEdit(item.id)"><img src="assets/images/svg/icon-edit.svg" class="mr-2"/>Chỉnh sửa </button>
                            <button mat-menu-item class="px-5" (click)="assignSchoolYear(item)"><img src="assets/images/svg/calendar-icon.svg" class="mr-2"/> Gán năm học</button>
                            <button mat-menu-item class="px-5 mat-menu-bottom" (click)="removeShoolYear(item)"><img src="assets/images/svg/icon-person.svg" class="mr-2"/> Gỡ năm học</button>
                            <button mat-menu-item class="px-5 text-danger" (click)="delelete(item)"><img src="assets/images/svg/remove-icon2.svg" class="mr-2"/> Xóa</button>
                        </mat-menu>
                    </div>
                    <div class="row">
                        <p class="col-lg-3 sub-info-category">Thời gian tạo: {{item.createdAt | parseTime:'DD/MM/YYYY'}} </p>
                        <p class="col-lg-8 sub-info-category">Năm học: 
                            <ng-container *ngFor="let shoolyear of item.schoolYears; let i=index">
                                {{shoolyear.schoolYearName}}{{i < item.schoolYears.length -1 ? ', ' : ''}} 
                            </ng-container>
                        </p>
                        <!-- <p class="col-lg-4 sub-info-category">Đơn vị: {{item.author?.fullName}}</p>
                        <p class="col-lg-3 sub-info-category"><mat-checkbox [checked]="item.isApply == 1 ? true : false" [disabled]="true">Áp dụng</mat-checkbox></p> -->  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>


