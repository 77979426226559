import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ModuleListComponent} from './components/module-list/module-list.component';
import {ModuleDetailComponent} from './components/module-detail/module-detail.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import {MODULE_PERMISSION} from './constants';

const routes: Routes = [
  {
    path: '', 
    component: ModuleListComponent,
    canActivate: [NgxPermissionsGuard],
        data: {
        permissions: {
            only: [
              MODULE_PERMISSION.perm1
            ],
            redirectTo: '/accessdenied'
        }
        }
  },
  {
    path: 'detail/:id', 
    component: ModuleDetailComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
    permissions: {
        only: [
          MODULE_PERMISSION.perm3
        ],
        redirectTo: '/accessdenied'
    }
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuleManagerRoutingModule { }
