import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services/authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionManagerService {

  constructor(private authenticationService: AuthenticationService, private http: HttpClient) { }
  getDataPermission(page: number, getTotalPage: boolean, limit: number){
        page = page + 1;
        return this.http.get(`${environment.coreApiUrl}/api/core/v1/permissions?page=${page}&limit=${limit}&getTotalPage=${getTotalPage}`
        );
  }

  deletePermission(id: string){
    return this.http.delete(`${environment.coreApiUrl}/api/core/v1/permissions/${id}`
    );
  }

  getPermissionById(id: string){
    return this.http.get(`${environment.coreApiUrl}/api/core/v1/permissions/${id}`);
  }

    addPermission(data: any){
        return this.http.post(`${environment.coreApiUrl}/api/core/v1/permissions?page=1&limit=10&getTotalPage=true`, data,
        );
  }

    editPermission(data: any, id: string){
        return this.http.put(`${environment.coreApiUrl}/api/core/v1/permissions/${id}`, data,
        );
    }

    getAllModule(){
        return this.http.get(`${environment.coreApiUrl}/api/core/v1/modules?page=1&limit=10&getTotalPage=true`,
        );
    }
}
