<div class="custom-scroll">
    <table mat-table [dataSource]="dataSubject" class="table-el">
        <ng-container matColumnDef="stt">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;">STT</th>
            <td mat-cell *matCellDef="let item; let i = index">{{ i+1 }}</td>
        </ng-container>
        <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef style="width: 40%;">Tên môn học</th>
            <td mat-cell *matCellDef="let item">
                <a class="text-link" [routerLink]="['/grades-manager/detail/1']">{{ item.fullname }}</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;">Mã</th>
            <td mat-cell *matCellDef="let item">{{ item.code }}</td>
        </ng-container>
        <ng-container matColumnDef="is_moet">
            <th mat-header-cell *matHeaderCellDef style="width: 15%;">Môn học MOET</th>
            <td mat-cell *matCellDef="let item">
                <mat-checkbox [checked]="item.isMoetSubject == 1 ? true : false" [disabled]="true"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Trạng thái</th>
            <td mat-cell *matCellDef="let item">
                <mat-checkbox [checked]="item.status == 1 ? true : false" [disabled]="true">
                    Kích hoạt
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="action_table" style="width: 10%;">Công cụ</th>
            <td mat-cell *matCellDef="let item" class="action_table_td">
                <button mat-icon-button (click)="clickDeleteSubject(item,idGradeSubject);">
                <img src="assets/images/svg/remove-icon-line.svg" alt="">
            </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
    </table>
</div>