<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div id="dialog-add-unit-config-elsa">
  <p class="div__title" mat-dialog-title>
    Chọn đơn vị giáo dục 
    <span class="icon-close" [mat-dialog-close]="null"><img src="assets/images/icons/Vector-24.png"></span>
  </p>
  <hr>
    <mat-dialog-content class="div__content">
      <div class="row ml-0 mr-0">
        <div class="col-md-12 pl-0 pr-0">
          <input type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp"
              placeholder="Nhập tên đơn vị giáo dục"  (keydown)="search($event)">
          <div style="float: left; position: relative; left: 10px; bottom: 30px; cursor: pointer">
              <img src="assets/images/svg/search.svg" alt="">
          </div>
      </div>
      </div>
      <div class="row ml-0 mr-0">
        <mat-radio-group (change)="chooseUnit($event)">
          <ng-container *ngFor="let item of listUnits">
              <mat-radio-button class="example-radio-button box" [value]="item">
                <span>{{item.name}}</span>
              </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button class="btn btn-cancel" [mat-dialog-close]="null">Hủy</button>
      <button mat-button type="submit" class="btn btn-add" (click)="add()">Thêm</button>
  </mat-dialog-actions>
</div>