<div fxLayout="column" class="bg-content-global">
    <div fxFlex="60px">
        <div fxLayout="row result-custom" fxLayoutAlign="space-between end">
            <div>
                <p class="title-page-global title-custom">Import người dùng</p>
                <p>File: NguoidungCoreadmin.xlsx</p>
            </div>
            <button *ngIf="isChecked === true" (click)="exportExcel()" class="btn btn-download" style="position: relative; bottom: 30px"><img src="assets/images/svg/download.svg" alt=""> Tải xuống dữ liệu lỗi</button>
        </div>
    </div>
    <br>
    <div fxFlex class="custom-table">
        <table mat-table [dataSource]="dataSource" class="table-el">

            <ng-container matColumnDef="error" *ngIf="isChecked === true">
                <th mat-header-cell *matHeaderCellDef class="text-danger" style="width: 10%"> * Lỗi</th>
                <td class="border-first-5 text-danger" mat-cell *matCellDef="let element"> {{element.errors}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fullname" style="width: 10%">
                <th mat-header-cell *matHeaderCellDef > Tên người dùng</th>
                <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> mã</th>
                <td mat-cell *matCellDef="let element"> {{element.userCode}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Tài khoản</th>
                <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="password">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Mật khẩu</th>
                <td mat-cell *matCellDef="let element"> {{element.password}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Email</th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 7%"> SĐT</th>
                <td mat-cell *matCellDef="let element"> {{element.phone == null ? '#' : element.phone}} </td>
            </ng-container>

            <ng-container matColumnDef="birth">
                <th mat-header-cell *matHeaderCellDef style="width: 7%"> Ngày sinh</th>
                <td mat-cell *matCellDef="let element" class="td-custom">
                    {{element.birthday}}
                </td>
            </ng-container>
            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef style="width: 5%">Giới tính</th>
                <td mat-cell *matCellDef="let element" class="td-custom">
                    {{element.gender === 1 ? 'Nữ' : 'Nam'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Địa chỉ</th>
                <td mat-cell *matCellDef="let element">
                    {{element.address}}
                </td>
            </ng-container>

            <ng-container matColumnDef="ward">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Phường xã</th>
                <td mat-cell *matCellDef="let element">
                    {{element.ward}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <br>
    <p class="text-center">
        <button class="btn btn-cancel" (click)="cancel()">Hủy</button>
        &nbsp;&nbsp;
        <button *ngIf="isChecked === false" class="btn btn-add" (click)="addDataImport()">Lưu</button>
    </p>
</div>
