import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import {AuthenticationService, GeneralConfigService} from '@app/_services';
import {strict} from 'assert';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import {Title} from "@angular/platform-browser";

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    hidePassword = true;
    messages: string;
    password: string;
    colorHidePassword: boolean;
    configCore: any;
    isSaveLogin:boolean = false;
    dataLoginCookie:any;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private generalConfigService: GeneralConfigService,
        private cookieService: CookieService,
        private titleService:Title
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.dataLoginCookie = this.cookieService.get("dataLogin") ? JSON.parse(this.cookieService.get("dataLogin")) : null;
        this.dataLoginCookie ? this.isSaveLogin = true : this.isSaveLogin = false;
        this.dataLoginCookie ? this.password = this.dataLoginCookie.password : this.password = '';
        this.loginForm = this.formBuilder.group({
            username: [this.dataLoginCookie ? this.dataLoginCookie.username : '', Validators.required],
            password: [this.dataLoginCookie ? this.dataLoginCookie.password : '', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        $("#leader-line-defs").remove();
        this.getConfig();
    }

    getConfig(){
        // const end = window.location.href.indexOf('.vn')  + 3;
        // const start = window.location.href.indexOf('//') + 2;
        // const domain = window.location.href.substring(start, end);
        // let dataInput = {
        //     domain: domain
        // }
        this.generalConfigService.getInfoSystemConfig().subscribe((ref: any) => {
            this.configCore = ref.data;
            ref.data.fullname ? this.titleService.setTitle(ref.data.fullname) : this.titleService.setTitle("HỆ THỐNG QUẢN LÝ");
            localStorage.setItem('config', JSON.stringify(this.configCore));
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        let myRe = new RegExp(/^[a-zA-Z\d\_\-]{2,50}$/, 'g');
        let myArray = myRe.test(this.f.username.value);
        if (!myArray){
            this.messages = 'Tài khoản hoặc mật khẩu của bạn không chính xác';
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value,this.isSaveLogin)
            .pipe(first())
            .subscribe(
                (data) => {
                    if (data.status === 1 ){
                        this.router.navigate([this.returnUrl]);
                        this.loading = false;
                    }
                    else{
                        this.messages = data.msg;
                        this.loading = false;
                    }
                    return false;
                });
    }

    checkIsSaveDataLogin(event){
        this.isSaveLogin = event.checked;
    }

    onForgotPassword(){
        this.router.navigate([`forgot-password`]);
    }
}
