<div fxLayout="column" class="bg-content-global">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>
            <h4 class="title-page-global">Cá nhân</h4>
        </div>
        <div>
            <span *ngIf="activeLink == 'tab2'"  class="{{showSearch == true ? 'transition-search-show': ''}}">
                 <input (keydown)="checkText($event, search.value)" #search type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="text-left btn btn-default button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? 'Tìm kiếm theo IP, url, user-agent' : ''}}" (blur)="checkText($event, search.value)" >
            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass()">
                </span>
            <button  *ngIf="activeLink == 'tab2'" class="{{ showFilter == true ? 'change-color-icon' : '' }} btn btn-default custom-filter" (click)="showFilterClick()">
                <img class="icon-filter" src="../../../../../../assets/images/svg/filter_img.svg" alt="">
            </button>
            <button *ngIf="activeLink == 'tab1'" class="btn btn-add-global" style="width: 201px" (click)="openDialogUpdateProfile(infoUser)">
                <img src="../../../../../../assets/images/svg/edit-icon.svg">Chỉnh sửa thông tin
            </button>
        </div>
    </div>
    <div style="position: relative; right: 78px" fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter">
        <div fxFlex="70%" fxLayout="row" fxLayoutAlign="flex-end center" class="date-time-range" *ngIf="typeTime == 4">
            <span style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;color: #000000;margin-right: 12px;">Từ </span>&nbsp;
            <input (dateChange)="changeDateTime('startDate', $event)" readonly type="text" class="mr-2 btn-date-time" [max]="maxDate" [matDatepicker]="pickerFrom" value="{{date}}">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
            <span style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;color: #000000;margin-right: 12px;">Đến </span>&nbsp;
            <input (dateChange)="changeDateTime('endDate', $event)" [max]="maxDate" readonly type="text" class="mr-2 btn-date-time input-field-global" [matDatepicker]="pickerTo"
                   value="{{date}}">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </div>
        <select #valueFilter class="btn btn-default mr-2 btn-filter-option btn-item-filter" (change)="checkFilter(valueFilter.value)">
            <option value="0">Chọn lọc</option>
            <option value="1">Hôm nay</option>
            <option value="2">Tuần này</option>
            <option value="3">Tháng này</option>
            <option value="4">Khác</option>
        </select>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn btn-filter-option custom-btn-filter" (click)="filter()">Lọc</button>
    </div>
    <br>
    <div fxFlex style="overflow-y: auto;">
        <div fxLayout="row" class="tab-group">
            <div fxFlex="25%" class="tab-left" fxLayout="column">
                <div fxFlex="40%" fxLayout="column" fxLayoutAlign="center center" class="tab-header">
                    <div class="container">
                        <div class="circle">
                            <img [src]="infoUser?.avtatar ? infoUser?.avtatar : '../../../../../../assets/images/png/avatar.png'" alt="">
                        </div>
                    </div>
                    <div class="container">
                        <h4 class="fullName-detail-account">{{infoUser?.fullName}}</h4>
                    </div>

                </div>
                <div fxFlex fxLayout="column" fxLayoutAlign="end" class="tab-footer">
                    <div fxLayout="column" fxLayoutAlign="start" class="tab-links">
                        <ng-container *ngFor="let link of links">
                            <div mat-tab-nav-bar>
                                <a class="ml-5" mat-tab-link (click)="changeTab(link.route)" [active]="activeLink == link.route"> {{link.name}} </a>
                            </div>
                        </ng-container>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start end" class="custom-log custom-create">Khởi tạo ngày: {{parseTime(infoUser?.createAt)}}</div>
                </div>
            </div>
            <div fxFlex fxLayout="column" class="tab-right" style="overflow: hidden">
                <div style="overflow-y: auto; height: auto">
                    <app-tab-info-user *ngIf="activeLink=='tab1'" [infoUser]="infoUser" ></app-tab-info-user>
                    <app-tab-manipulation-history *ngIf="activeLink=='tab2'" [dataFilter] = "dataFilter" [change] = "changeSearch" [valueSearch] = "dataSearch"></app-tab-manipulation-history>
                </div>
                <ng-container *ngIf="activeLink=='tab1'">
                    <br>
                    <div fxFlex fxLayout="column" fxLayoutAlign="end">
                        <div fxLayout="row" fxLayoutAlign="start end" class="custom-log custom-border-footer">Đăng nhập lúc gần nhất  {{parseTime(infoUser?.lastLogin)}} từ IP {{infoUser?.ip}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
