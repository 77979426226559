import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {DialogFormSubjectsManagerComponent} from '@app/_modules/subjects/dialogs/dialog-form-subjects-manager/dialog-form-subjects-manager.component';
import {DialogNotificationComponent} from '@app/_shared/dialogs/notification/notification.component';
import {DialogSubjectListCustomersComponent} from '@app/_modules/subjects/dialogs/dialog-subject-list-customers/dialog-subject-list-customers.component';
import {Observable, Subscriber} from 'rxjs';
import {SUBJECT_PERMISSION} from '@app/_modules/subjects/constant';
import {NgxPermissionsService} from 'ngx-permissions';
import {DialogSubjectListGradesComponent} from '@app/_modules/subjects/dialogs/dialog-subject-list-grades/dialog-subject-list-grades.component';

@Component({
  selector: 'app-subjects-list-moet',
  templateUrl: './subjects-list-moet.component.html',
  styleUrls: ['./subjects-list-moet.component.scss']
})
export class SubjectsListMoetComponent implements OnInit {
  dataColumn = ['stt', 'subject', 'code', 'grade', 'count_user', 'is_active'];
  permissionSubject = SUBJECT_PERMISSION;
  @Input() dataSource: any;
  @Input() countData: number;
  @Output() reloadDataSource = new EventEmitter<boolean>();
  @Output() reloadDataSourcePaginate = new EventEmitter<number>();
  @Input() pageIndex = 0;
  typeModule = 'subject';
  isLoading = false;
  constructor(
      private dialog: MatDialog,
      private router: Router,
      private subjectService: SubjectService,
      private showMessage: ShowMessage,
      private listenFirebaseService: ListenFirebaseService,
      private permissionsService: NgxPermissionsService) { }

  ngOnInit(): void {
    if (this.permissionsService.getPermission(this.permissionSubject.perm4) || this.permissionsService.getPermission(this.permissionSubject.perm5)){
      this.dataColumn.push('tool');
    }
    this.listenFireBase();
  }

  openDetailSubject(id, tab) {
    this.router.navigate([`${'subjects-manager/detail'}/${id}`], {queryParams: {active: tab}});
  }

  onChangePaginate(event){
    this.pageIndex = event.pageIndex;
    this.reloadDataSourcePaginate.emit(this.pageIndex);
  }

  openDialogUpdate(infoSubject) {
    const dialogRef = this.dialog.open(DialogFormSubjectsManagerComponent, {
      height: 'auto',
      width: '70%',
      data: {
        action: 'update',
        infoSubject
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res){
        this.listenFireBase();
      }
    });
  }

  confirmDelete(itemId: string, itemName: string) {
    const dialogDelete = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '30%',
      data: {
        title: 'Bạn đang thực hiện xoá  môn học',
        message: 'Bạn có chắc muốn xoá môn học này không ?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'HỦY',
        data: {
          id: itemId,
          name: itemName,
        },
      },
    });
    dialogDelete.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isLoading = true;
        this.subjectService.removeSubject(itemId).subscribe((res: any) => {
          if (res.status === 1) {
          } else if (res.status === 0) {
            this.isLoading = false;
            const dialogAlert = this.dialog.open(DialogNotificationComponent, {
              height: 'auto',
              width: '30%',
              data: {
                title: 'Bạn không thể thực hiện xoá môn học',
                data: {
                  name: itemName,
                },
                message: 'Môn học đang có khách hàng sử dụng',
                btnCancelText: 'ĐÓNG',
              },
            });
          }
        }, error => {
          this.isLoading = false;
          this.showMessage.success(error);
        });
        setTimeout(() => {
          if (this.isLoading){
            this.isLoading = false;
            this.reloadDataSource.emit(true);
          }
        }, 10000);
        this.listenFireBase();
      }
    });
  }

  changeStatusSubject(event, itemId, itemName: string){
    const titleDialog = event.checked ? 'Bạn đã thực hiện kích hoạt môn học' : 'Bạn đã thực hiện bỏ kích hoạt môn học';
    const confirmActive = this.dialog.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '36%',
      data: {
        btnCancelText: 'HỦY',
        btnOkText: 'XÁC NHẬN',
        title: titleDialog,
        content: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
        status: event.checked,
        data: {
          name: itemName,
        }
      }
    });
    confirmActive.afterClosed().subscribe((res: any) => {
      event.source._checked = res ? event.checked : !event.checked;
      if (res){
        this.isLoading = true;
        event.source._checked = event.checked;
        const status = event.checked ? 1 : 0;
        this.subjectService.changeStatusSubject(itemId, status).subscribe((ref: any) => {
          if (res.status === 1) {
            this.isLoading = false;
            this.showMessage.success(ref.msg);
          }
          else if (ref.status === 0) {
            this.isLoading = false;
            this.showMessage.success(ref.msg);
          }
        }, error => {
          this.isLoading = false;
          this.showMessage.success(error);
        });
        setTimeout(() => {
          if (this.isLoading){
            this.isLoading = false;
            this.reloadDataSource.emit(true);
          }
        }, 10000);
        this.listenFireBase();
      }
      else{
        event.source._checked = !event.checked;
      }
      // }
    });
  }

  showListCustomer(item: any) {
    const dialogShow = this.dialog.open(DialogSubjectListCustomersComponent, {
      height: 'auto',
      width: '70%',
      autoFocus: false,
      data: {
        name: item.fullname,
        id: item.id
      },
    });
  }

  listenFireBase(){
    // delete
    this.checkFireBaseModule('delete');
    // change_status
    this.checkFireBaseModule('change_status');
    // update
    this.checkFireBaseModule('update');
  }

  checkFireBaseModule(param){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, 'subject', subscriber);
    });
    listenFb.subscribe((refg) => {
      if (refg === true) {
        this.reloadDataSource.emit(true);
        this.isLoading = false;
      }
    });
  }

  openDialogListGrade(infoSubject){
    const dialogRef = this.dialog.open(DialogSubjectListGradesComponent, {
      width: '70%',
      height: 'auto',
      data: {
        infoSubject
      }
    });
  }
}
