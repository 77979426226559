<h2 mat-dialog-title class="text-center">Chỉnh sửa thông tin cấu hình</h2>
<form [formGroup]="createTenant" class="form" (ngSubmit)="onClickSubmit(createTenant.value);">
    <mat-dialog-content class="mat-typography">
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                    Phân hệ phần mềm hệ thống
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row row-global">
                <div class="form-group col-md-12 row row-global">
                    <label class="col-md-1 lb-global">Hệ thống</label>
                    <div class="col-md-11 row row-global p-0">
                        <div class="col-md-3 pl-0 pr-0 mr-5 mb-2 d-flex align-items-center">
                            <input type="text" class="form-control input-field-global" placeholder="Nhập hệ thống" [disabled]="true">
                            <a href="#" class="ml-2 remove-item-global"><b>x</b> Xóa</a>
                        </div>
                        <div class="col-md-3 pl-0 pr-0 mr-5 mb-2 d-flex align-items-center">
                            <input type="text" class="form-control input-field-global" placeholder="Nhập hệ thống" [disabled]="true">
                            <a href="#" class="ml-2 remove-item-global"><b>x</b> Xóa</a>
                        </div>
                        <div class="col-md-3 pl-0 pr-0 mr-5 mb-2 d-flex align-items-center">
                            <input type="text" class="form-control input-field-global" placeholder="Nhập hệ thống" [disabled]="true">
                            <a href="#" class="ml-2 remove-item-global"><b>x</b> Xóa</a>
                        </div>
                        <div class="col-md-3 pl-0 pr-0 mr-5 mb-2 d-flex align-items-center">
                            <input type="text" class="form-control input-field-global" placeholder="Nhập hệ thống" [disabled]="true">
                            <a href="#" class="ml-2 remove-item-global"><b>x</b> Xóa</a>
                        </div>
                        <div class="col-md-3 pl-0 pr-0 mr-5 mb-2 d-flex align-items-center">
                            <span class="form-control input-field-global add-item-global"><mat-icon>add</mat-icon>Thêm hệ thống mới</span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                    Danh mục khối, môn, năm học
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row row-global">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Năm học</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
            </div>

            <div class="row row-global">
                <div class="form-group col-md-12 row row-global">
                    <label class="col-md-1 lb-global">Cấp học</label>
                    <div class="col-md-11 row row-global p-0">
                        <div class="form-grades">
                            <div class="form-grade">
                                <span class="title-grade">Cấp 3</span>
                                <ul>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Tất cả
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 10
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 11
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 12
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-grade">
                                <span class="title-grade">Cấp 3</span>
                                <ul>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Tất cả
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 10
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 11
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 12
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-grade">
                                <span class="title-grade">Cấp 3</span>
                                <ul>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Tất cả
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 10
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 11
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 12
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-grade">
                                <span class="title-grade">Cấp 3</span>
                                <ul>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Tất cả
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 10
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 11
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Khối 12
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-global">
                <div class="form-group col-md-12 row row-global">
                    <label class="col-md-1 lb-global">Môn học</label>
                    <div class="col-md-11 row row-global p-0">
                        <div class="form-grades">
                            <div class="form-grade">
                                <span class="title-grade">Môn áp dụng trong hệ MOET</span>
                                <ul>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Toán
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Ngữ Văn
                                        </mat-checkbox>
                                    </li>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Tiếng Anh
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-grade">
                                <span class="title-grade">Môn tham khảo</span>
                                <ul>
                                    <li>
                                        <mat-checkbox [disabled]="true">
                                            Tiếng Đức
                                        </mat-checkbox>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                    Danh mục đơn vị nghành học
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row row-global">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Bộ giáo dục</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Sở giáo dục</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
            </div>

            <div class="row row-global">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Phòng giáo dục</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Trường</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
            <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                    Danh mục đơn vị hành chính
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row row-global">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Tỉnh/thành phố</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Quận/huyện</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
            </div>

            <div class="row row-global">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-2 lb-global">Phường/xã</label>
                    <div class="col-md-10 pl-0 pr-0">
                        <input type="text" class="form-control input-field-global" [disabled]="true">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button>HỦY</button>
        <button mat-button type="submit" mat-raised-button color="primary" [disabled]="!createTenant.valid">LƯU</button>
    </mat-dialog-actions>
</form>