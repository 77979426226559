<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<h2 mat-dialog-title class="title-page-global text-center">Thêm khách hàng</h2>
<div class="container-fluid">
    <div class="row input-search-dialog-list">
        <div class="col-sm-12">
            <input type="text" #search (keydown)="clickSearch($event,search.value)" class="form-control input-field-global" placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email">
            <img src="assets/images/icons/icon-23.svg">
        </div>
    </div>
    <div class="row ">
        <div class="col-sm-12" fxLayout="row">
            <div>
                <mat-checkbox id="check-all" [checked]="checkAll" (change)="checkAllCheckBox($event)"></mat-checkbox> Chọn tất cả</div>
            <div class="ml-5">Đã chọn: {{countCheck}} khách hàng mới</div>
        </div>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid">
        <form class="row" id="customer">
            <table mat-table [dataSource]="dataSource" class="table-el">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                    <td mat-cell *matCellDef="let item; let i = index">
                        <mat-checkbox class="check-box" value="{{item.id}}" [checked]="arrayTenant.indexOf(item.id) != -1" (change)="checkCheckBox($event, item.id)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">Khách hàng</th>
                    <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullname}}</td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                    <td mat-cell *matCellDef="let item">{{item.code}}</td>
                </ng-container>
                <ng-container matColumnDef="domain">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
                    <td mat-cell *matCellDef="let item">{{item.tenantUrl}}</td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                    <td mat-cell *matCellDef="let item">{{item.hotline}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                    <td mat-cell *matCellDef="let item">{{item.email}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
            </table>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn btn-close mat-elevation-z" mat-raised-button mat-dialog-close>HỦY</button>
    <button class="btn btn-add mat-elevation-z" mat-raised-button (click)="onSubmit()">THÊM</button>
</mat-dialog-actions>