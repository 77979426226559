<mat-dialog-content>
    <div class="container-fluid">
        <div fxLayout="row">
            <div fxFlex="25%"></div>
            <div fxFlex="50%">
                <h4 class="title-page-global text-center">Chỉnh sửa năm học - {{tenantInfor?.fullname}}</h4>
            </div>
            <div fxFlex="25%"></div>
        </div>
        <div fxLayout="row">
            <div fxFlex="10%"></div>
            <div fxFlex="80%">
                <div class="item-subject mt-3" *ngFor="let item of data" fxLayout="row" fxLayoutAlign="start center" >
                    <mat-checkbox [(ngModel)]="item.is_selected" class="pb-4"></mat-checkbox>
                    <label class="pl-4 pt-3" fxLayout="column" fxLayoutAlign="start start">
                        <span class="title-subject "> Năm học {{item?.fullname}}</span>
                        <span class="subtitle-subject">{{item?.code}}</span>
                        <label>
                            <span class="subtitle-subject">Ngày bắt đầu: {{parseTime(item.startDate)}}</span>
                            <span class="ml-5 subtitle-subject">Ngày kết thúc: {{parseTime(item.endDate)}}</span>
                        </label>
                    </label>
                </div>
            </div>
            <div fxFlex="10%"></div>
        </div>
    </div>
    
</mat-dialog-content>
<mat-dialog-actions align="center" class="mt-3">
    <button mat-button mat-dialog-close mat-raised-button class="mat-elevation-z btn-cancel px-5">Hủy</button>
    <button type="submit"  (click)="submitUpdate()" mat-raised-button class="mat-elevation-z btn-save px-5">Lưu</button>
</mat-dialog-actions>
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
</div>