<div fxLayout="column" class="bg-content-global">
    <div fxLayout="column" fxFlex class="content-declare" *ngIf="dataGrade">
        <div fxFlex="3rem" fxLayout="row" fxLayoutAlign="start start">
            <div>
                <h4 class="title-page-global">Khối - {{ dataGrade.fullname }}</h4>
            </div>
        </div>
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div fxFlex fxLayout="column" class="content__education-lv1">
            <div class="info__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <h4 class="title__education-lv1">Thông tin cơ bản</h4>
                    <button *ngIf="dataGrade.group == 0" class="btn-add-global" mat-raised-button (click)="openDialogChangeUpdateGrade(dataGrade);"><img src="assets/images/svg/edit_grade_icon.svg" alt="" class="mr-2 mb-1">Chỉnh sửa thông tin</button>
                </div>
                <div class="form__info-education-lv1 row">
                    <table style="width: 100%;">
                        <tr>
                            <td class="td-custom-label"><label class="custom-label">Tên khối</label></td>
                            <td class="form-control input-field-global">{{ dataGrade.fullname }}</td>
                            <td class="td-custom-label"><label class="custom-label">Mã khối</label></td>
                            <td class="form-control input-field-global">{{ dataGrade.code }}</td>
                            <td class="td-custom-label"><label class="custom-label">Cấp đào tạo</label></td>
                            <td class="form-control input-field-global">{{ trainingLevel }}</td>
                        </tr>
                        <tr>
                            <td class="td-custom-label"><label class="custom-label">Thứ tự sắp xếp</label></td>
                            <td class="form-control input-field-global">{{ dataGrade.indexOrder }}</td>
                            <td class="td-custom-label"><label class="custom-label">Nhóm khối</label></td>
                            <td class="form-control input-field-global">{{ isMoet }}</td>
                            <td class="td-custom-label"><label class="custom-label">Trạng thái</label></td>
                            <td style="display: flex;justify-content: flex-start;">
                                <mat-checkbox [disabled]="true" [checked]="dataGrade.status == 1 ? true : false">Kích hoạt</mat-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-custom-label"><label class="custom-label">Mô tả</label></td>
                            <td colspan="5" class="custom-text-area">{{ dataGrade.description }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="tree__education-lv1">
                <div class="sticky" fxLayout="row" fxLayoutAlign="space-between start">
                    <div>
                        <button *ngIf="dataGrade.group == 0" class="title__education-lv1-custom {{ activeTab1 ? 'active' : '' }} mr-1" mat-raised-button (click)="clickTab1();">Khách hàng</button>
                        <button class="title__education-lv1-custom {{ activeTab2 ? 'active' : '' }}" mat-raised-button (click)="clickTab2();">Môn học</button>
                    </div>
                    <div *ngIf="activeTab1 && dataGrade.group == 0">
                        <input #searchTenant (keydown)="onKeySearchTenant($event)" type="text" class="text-left btn btn-default button-search {{!showSearch ? 'transition-search-show': 'text-white'}}" [(ngModel)]="dataSearch" [readOnly]="!showSearch == true ? false: true"
                            placeholder="{{!showSearch == true ? 'Nhập tên, mã khách hàng, tên miền, SĐT, email' : ''}}">
                        <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ !showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass(searchTenant.value)">
                        <button class="btn btn-default btn-filter">
                            <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter == true ? 'active_filter' : '' }}" (click)="showFilterClick()">
                        </button>
                        <button class="btn btn-add-global custom-text-btn" mat-raised-button (click)="clickDialogAddCustomer(dataGrade.id);"><mat-icon>add</mat-icon> Thêm khách hàng</button>
                    </div>
                    <div *ngIf="activeTab2">
                        <input #searchSubject (keydown)="onKeySearchSubject($event)" type="text" class="text-left btn btn-default button-search {{!showSearch == true ? 'transition-search-show': ''}}" [(ngModel)]="dataSearch" [readOnly]="!showSearch == true ? false: true"
                            placeholder="{{!showSearch == true ? 'Nhập tên hoặc mã môn học' : ''}}">
                        <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ !showSearch == true ? 'active_filter' : '' }}" (click)="toggleClass(searchSubject.value)">
                        <button class="btn btn-default btn-filter">
                            <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter == true ? 'active_filter' : '' }}" (click)="showFilterClick()">
                        </button>
                        <button class="btn btn-add-global custom-text-btn" mat-raised-button (click)="clickDialogAddSubject(dataGrade.id);"><mat-icon>add</mat-icon> Thêm môn học</button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="flex-end center" class="mt-2 {{ activeTab1 && showFilter && dataGrade.group == 0 ? 'custom-show-customer' : 'custom-hide-customer' }}" style="padding-right: 3.5rem;">
                    <button class="btn btn-default mr-2 btn-filter-option btn-filter-option1" (click)="itemOptionClickTenant($event,0)">Chưa kích hoạt</button>
                    <button class="btn btn-default mr-2 btn-filter-option btn-filter-option2" (click)="itemOptionClickTenant($event,1)">Dùng thử</button>
                    <button class="btn btn-default mr-2 btn-filter-option btn-filter-option3" (click)="itemOptionClickTenant($event,2)">Đang sử dụng</button>
                    <button class="btn btn-default mr-2 btn-filter-option btn-filter-option4" (click)="itemOptionClickTenant($event,3)">Hết hạn sử dụng</button>
                    <button class="btn btn-default mr-2 btn-filter-option btn-filter-option5" (click)="itemOptionClickTenant($event,4)">Hết hạn dùng thử</button>
                    <button class="btn btn-default mr-2 btn-filter-option btn-filter-option6" (click)="itemOptionClickTenant($event,99)">Khác</button>
                    <button class="btn btn-add-global filter" mat-raised-button (click)="clickFilterTenant();">Lọc</button>
                </div>
                <div *ngIf="activeTab2 && showFilter" fxLayout="row" fxLayoutAlign="flex-end center" class="mt-2" style="padding-right: 3.5rem;">
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,1)">Mầm non</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,2)">Tiểu học</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,3)">Trung học cơ sở</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,4)">Trung học phổ thông</button>
                    <button class="btn btn-default mr-2 btn-filter-option" (click)="itemOptionClickSubjectActive($event,99)">Khác</button>
                    <button class="btn btn-add-global filter" mat-raised-button (click)="clickFilterSubject();">Lọc</button>
                </div>

                <div class="items-table content-data">
                    <app-grade-detail-table-customer *ngIf="activeTab1 && dataGrade.group == 0" [dataTenant]="listTenant" [idGradeCustomer]="idGrade" (outCheckRemoveTenant)='checkRemoveTenant($event)'></app-grade-detail-table-customer>
                    <app-grade-detail-table-subject *ngIf="activeTab2" [dataSubject]="listSubject" [idGradeSubject]="idGrade" (outCheckRemoveSubject)='checkRemoveSubject($event)'></app-grade-detail-table-subject>
                </div>

            </div>
        </div>
    </div>
</div>