<p mat-dialog-title
   class="title-dialog text-center">{{infoSoftwareModule.status == 'add' ? 'Thêm phân hệ' : 'Chỉnh sửa phân hệ - ' + infoSoftwareModule.data.fullname | substring: 50}}</p>
<br>
<div class="container-fluid" fxLayout="column">
    <div class="show-loading"
         *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm.value)">
        <div class="mat-typography">
            <!-- Thông tin cơ bản -->
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global custom-label">Tên phân hệ <span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="fullname" type="text" class="form-control input-field-global"
                               placeholder="Nhập tên phân hệ">
                        <ng-container
                                *ngIf="infoForm.get('fullname')?.invalid && (infoForm.get('fullname').touched || infoForm.get('fullname').dirty || checkValidate)">
                            <ng-container *ngFor="let err of messageValidate.fullname">
                                <p class="text-danger pt-2" *ngIf="infoForm.get('fullname').hasError(err.type)">{{err.message}}</p>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global custom-label" [style]="{opacity: infoSoftwareModule.status !== 'add'? 0.4 : 1}">Mã phân hệ <span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input [style]="{opacity:infoSoftwareModule.status !== 'add' ? 0.4: 1}" formControlName="code" type="text" [readOnly]="infoSoftwareModule.status !=='add'? true : false" class="form-control input-field-global"
                               placeholder="Nhập mã phân hệ">
                        <ng-container
                                *ngIf="infoForm.get('code')?.invalid && (infoForm.get('code').touched || infoForm.get('code').dirty || checkValidate)">
                            <ng-container *ngFor="let err of messageValidate.code">
                                <p class="text-danger pt-2" *ngIf="infoForm.get('code').hasError(err.type)">{{err.message}}</p>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global custom-label">Thứ tự sắp xếp <span class="text-danger"> (*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="indexOrder" onlyNumber (keypress)="numberOnly($event)" type="number" min="1" class="form-control input-field-global"
                               placeholder="Thứ tự sắp xếp">
                        <ng-container
                                *ngIf="infoForm.get('indexOrder')?.invalid && (infoForm.get('indexOrder').touched || infoForm.get('indexOrder').dirty || checkValidate)">
                            <ng-container *ngFor="let err of messageValidate.indexOrder">
                                <p class="text-danger pt-2" *ngIf="infoForm.get('indexOrder').hasError(err.type)">{{err.message}}</p>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global custom-label">Trạng thái</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <mat-checkbox formControlName="status" class="custom-status custom-label">
                            Kích hoạt
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="d-flex">
                <label style="width: 11%" class=" lb-global custom-label">Mô tả</label>
                <div style="padding-left: 1%; width: 90%">
                    <textarea formControlName="description" type="text" class="form-control input-field-global" rows="4"></textarea>
                    <ng-container
                            *ngIf="infoForm.get('description')?.invalid && (infoForm.get('description').touched || infoForm.get('description').dirty || checkValidate)">
                        <ng-container *ngFor="let err of messageValidate.description">
                            <p class="text-danger pt-2" *ngIf="infoForm.get('description').hasError(err.type)">{{err.message}}</p>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <!-- Thông tin cơ bản -->

        </div>
        <br>
        <mat-dialog-actions align="center">
            <a mat-dialog-close class="btn btn-close">Hủy</a>
            <button type="submit"
                    class="btn btn-add-edit ml-2 px-4">{{infoSoftwareModule.status === 'add' ? 'Thêm' : 'Lưu'}}
            </button>
        </mat-dialog-actions>
        <br>
    </form>
</div>
