export const lang = {
    title: 'Danh sách khách hàng áp dụng',
    title_add: 'Thêm khách hàng',
    total: 'Tổng số: ',
    label:{
        list_customer: 'Danh sách khách hàng',
        choose_all: 'Chọn tất cả',
    },
    placeholder_search: 'Nhập tên khách hàng hoặc mã, số điện thoại, email',
    button_close: 'Đóng',
    button_add: 'Thêm'
}
