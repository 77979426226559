<div class="tab-content">
    <!--Thông tin cơ bản-->
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title custom-space-left">
                Thông tin cơ bản
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Tên khách hàng</label>
                <div class="col-md-8 pl-0 pr-0 scroll-div">
                    <p style="height: auto;" title={{data?.fullname}}>{{data?.fullname}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Mã khách hàng</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.code}}>{{data?.code}}</p>
                </div>
            </div>
        </div>

        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Hotline</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.hotline}}>{{data?.hotline}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Email</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.email}}>{{data?.email}}</p>
                </div>

            </div>
        </div>

    </mat-expansion-panel>
    <!--Thông tin cơ bản-->

    <!--Thông tin nâng cao-->
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title custom-space-left">
                Thông tin nâng cao
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Người liên hệ</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.contactFullname}}>{{data?.contactFullname}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1">Email liên hệ</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.contactEmail}}>{{data?.contactEmail}}</p>
                </div>
            </div>
        </div>

        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Điện thoại liên hệ</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.contactPhone}}>{{data?.contactPhone}}</p>
                </div>
            </div>
        </div>

        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Tỉnh/thành phố</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title="{{data?.cityName}}">{{data?.cityName}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Quận/huyện</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title="{{data?.districtName}}">{{data?.districtName}}</p>
                </div>
            </div>
        </div>

        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Phường xã</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title="{{data?.wardName}}">{{data?.wardName}}</p>
                </div>
            </div>
        </div>

        <div class="form-group div-address-custom custom-space-right">
            <label class="label-address-custom">Địa chỉ</label>
            <div class="input-address-custom">
                <p class="ellipsis-text" title="{{data?.address}}">{{data?.address}}</p>
            </div>
        </div>

        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Tên miền</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.tenantWebsite}}>{{data?.tenantWebsite}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Sắp xếp</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.indexOrder}}>{{data?.indexOrder}}</p>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <!--Thông tin nâng cao-->

    <!--Tên miền-->
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title custom-space-left">
                Tên miền
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Quản trị</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.adminUrl}}>{{data?.adminUrl}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Hệ thống</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.tenantUrl}}>{{data?.tenantUrl}}</p>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <!--Tên miền-->

    <!--Tài khoản quản trị-->
    <!-- <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title custom-space-left">
                Tài khoản quản trị
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Tài khoản quản trị</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input [value]="data.tenantAdmin" type="text" class="form-control input-field-global" placeholder="Nhập tài khoản quản trị" [disabled]="true">
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Mật khẩu</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input [value]="data.tenantAdminPassword" type="text" class="form-control input-field-global" placeholder="Nhập mật khẩu" [disabled]="true">
                </div>
            </div>
        </div>
    </mat-expansion-panel> -->
    <!--Tài khoản quản trị-->

    <!--Nhân viên chăm sóc-->
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-content">
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="panel-title custom-space-left">
                Nhân viên chăm sóc
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 ">Triển khai</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.employeeDeploy}}>{{employeeDeploy}}</p>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1 ">Kinh doanh</label>
                <div class="col-md-8 pl-0 pr-0">
                    <p class="ellipsis-text" title={{data?.employeeSale}}>{{employeeSale}}</p>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <!--Nhân viên chăm sóc-->
</div>

<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
</div>