<mat-dialog-content>
    <form [formGroup]="permissionForm" (ngSubmit)="submitPermission(permissionForm.valid, permissionForm.value)">
    <div class="container-fluid" fxLayout="column">
        <div fxFlex="10">
            <h4 class="title-page-global text-center">Thêm quyền</h4>
        </div>
        <div fxFlex="30">
            <p class="title-field-dialog">Thông tin quyền</p>
            <div class="row mt-4">
                <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 col-form-label">Tên quyền (<span class="text-danger">*</span>)</label>
                        <div class="col-lg-8">
                            <input type="text" class="form-control input-field-global" value="Admin"  formControlName="fullname">
                            <mat-error *ngFor="let validate of permissionValidate.fullname">
                                <span class="error-message" *ngIf="permissionForm.get('fullname').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                            </mat-error>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                <div class="col-lg-6 form-group row">
                    <div class="col-lg-1"></div>
                    <label class="col-lg-3 col-form-label">Mã quyền (<span class="text-danger">*</span>)</label>
                    <div class="col-lg-8">
                        <input type="text" class="form-control input-field-global" formControlName="code">
                        <mat-error *ngFor="let validate of permissionValidate.code">
                            <span class="error-message" *ngIf="permissionForm.get('code').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                        </mat-error>
                    </div>
                </div>
                <div class="col-lg-6 form-group row">
                    <label class="col-lg-3 col-form-label">Thứ tự sắp xếp</label>
                    <div class="col-lg-8">
                        <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" class="form-control input-field-global" formControlName="index_order">
                    </div>
                    <div class="col-lg-1"></div>
                </div>
                <div class="col-lg-6 form-group row">
                    <div class="col-lg-1"></div>
                    <label class="col-lg-3 col-form-label">Module (<span class="text-danger">*</span>)</label>
                    <div class="col-lg-8">
                        <select class="form-control input-field-global" formControlName="module_id">
                            <option value="" [selected]="true">Chọn</option>
                            <option *ngFor="let module of modules" value="{{module.id}}">{{module.fullname}}</option>
                        </select>
                        <mat-error *ngFor="let validate of permissionValidate.module_id">
                            <span class="error-message" *ngIf="permissionForm.get('module_id').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="30">
            <p class="title-field-dialog" >Mô tả quyền (<span class="text-danger">*</span>)</p>
            <div class="row mt-4">
                <div class="col-lg-12 form-group" >
                    <textarea class="form-control input-field-global" rows="4" cols="1000" formControlName="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries,but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s withthe release of Letraset sheets containing Lorem Ipsum passages,and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </textarea>
                    <mat-error *ngFor="let validate of permissionValidate.description">
                        <span class="error-message" *ngIf="permissionForm.get('description').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                    </mat-error>
                </div>
            </div>
        </div>
        <div fxFlex="10"  fxLayout="row" fxLayoutAlign="start none" class="mt-4">
            <p class="title-field-dialog">Trạng thái phân quyền</p>
            <div class="form-group form-check">
                <mat-checkbox formControlName="is_active" class="mr-2 "></mat-checkbox>
                <label class="form-check-label mt-1" style="font-size: 16px" value="1">Kích hoạt</label>
            </div>
        </div>

        <div fxFlex="20"  fxLayout="row" fxLayoutAlign="center center" class="mb-3" >
<!--            (click)="closeDialog()"-->
<!--            [disabled]="!permissionForm.valid"-->
            <button class="btn btn-cancel mr-2 " (click)="closeDialog()" type="button">Cancel</button>
            <button class="btn ml-2 px-4 btn-submit" type="submit" >Confirm</button>
        </div>
    </div>
    </form>
</mat-dialog-content>
