<p class="text-center custom-title-message">Bạn đang thực hiện cập nhật trạng thái khách hàng</p>
<p class="text-center custom-show-name ellipsis-text" style="max-width: unset;">{{data.user.fullname}} ({{data.user.code}})</p>
<div fxLayout="row" fxLayoutAlign="center center" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center;">
    <img id="avatar" src="{{urlImage}}" alt="">
</div>
<br>
<p class="text-center">
    <span class="custom-old-status ellipsis-text" title={{statusUser[data.user.status]?.name}}>{{statusUser[data.user.status]?.name}}</span>
    &nbsp;&nbsp;
    <img src="assets/images/svg/icon-left.svg" alt="">
    &nbsp;&nbsp;
    <span class="custom-new-status ellipsis-text" title={{data.newStatus.name}}>{{data.newStatus.name}}</span>
</p>
<p class="custom-message text-center">Bạn có chắc muốn cập nhật dữ liệu này không ?</p>
<mat-dialog-actions align="center">
    <button style="outline: none !important;"  mat-button mat-dialog-close mat-raised-button (click)="onDismiss()">Huỷ</button>
    <button  mat-button mat-raised-button color="primary" (click)="confirmStatus()">Xác nhận</button>
</mat-dialog-actions>
