import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/_shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { GeneralConfigurationSystemComponent } from './components/general-configuration-system/general-configuration-system.component';
import { EmailSeverComponent } from './components/tabs/email-sever/email-sever.component';
import { SecurityInformationComponent } from './components/tabs/security-information/security-information.component';
import { SystemInformationComponent } from './components/tabs/system-information/system-information.component';
import { SystemIntroductionComponent } from './components/tabs/system-introduction/system-introduction.component';
import { SystemComponent } from './components/tabs/system/system.component';
import { TabAboutPolicyConfigComponent } from './components/tabs/tab-about-policy-config/tab-about-policy-config.component';
import { TabAboutTermOfServiceComponent } from './components/tabs/tab-about-term-of-service/tab-about-term-of-service.component';
import { TabConfigExamusComponent } from './components/tabs/tab-config-examus/tab-config-examus.component';
import { TabConfigNotiSystemMaintenanceComponent } from './components/tabs/tab-config-noti-system-maintenance/tab-config-noti-system-maintenance.component';
import { TabConfigSMSComponent } from './components/tabs/tab-config-sms/tab-config-sms.component';
import { TabIntegratedElsaComponent } from './components/tabs/tab-integrated-elsa/tab-integrated-elsa.component';
import { DialogAddUnitComponent } from './dialogs/dialog-add-unit/dialog-add-unit.component';
import { DialogFormConfigNotiComponent } from './dialogs/dialog-form-cofig-noti/dialog-form-cofig-noti.component';
import { DialogSelectOptionComponent } from './dialogs/dialog-select-option/dialog-select-option.component';
import { GeneralConfigurationsRoutingModule } from './general-configurations-routing.module';
import { TabConfigPrivateKeyUploadFileComponent } from './components/tabs/tab-config-private-key-upload-file/tab-config-private-key-upload-file.component';

@NgModule({
    declarations: [
        GeneralConfigurationSystemComponent,
        SystemComponent,
        SystemInformationComponent,
        SecurityInformationComponent,
        EmailSeverComponent,
        SystemIntroductionComponent,
        TabAboutPolicyConfigComponent,
        TabAboutTermOfServiceComponent,
        TabIntegratedElsaComponent,
        DialogSelectOptionComponent,
        DialogAddUnitComponent,
        TabConfigSMSComponent,
        TabConfigExamusComponent,
        TabConfigNotiSystemMaintenanceComponent,
        DialogFormConfigNotiComponent,
        TabConfigPrivateKeyUploadFileComponent
    ],
    imports: [
        CommonModule,
        GeneralConfigurationsRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        NgxPermissionsModule.forChild()
    ],
    providers: [
    ]
})

export class GeneralConfigurationsModule {

}
