<div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<form style="width: 100%;" [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm.value)"
    *ngIf="isLoading === false">
    <!-- Thông tin bảo mật -->
    <div class="panel-content-global">
        <div class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">
            <div class="panel-title">{{lang.label.security_info.title}}</div>
        </div>
        <div class="row col-md-11 pl-0">
            <table style="width:100%; margin-left: 15px;">
                <tr>
                    <td style="width: 20%"><label
                            class="lb-global">{{lang.label.security_info.error_log_max.label}}</label>
                    </td>
                    <td style="width: 10%">
                        <label class="lb-global"><input onlyNumber (keypress)="numberOnly($event)" type="number" min="1"
                                class="form-control input-field-global" formControlName="max_login_failed">
                        </label>

                    </td>
                    <td>
                        &nbsp; &nbsp;{{lang.label.security_info.error_log_max.label_after}}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td colspan="2">
                        <mat-error
                            *ngIf="infoForm.get('max_login_failed').errors?.pattern &&
                        (infoForm.get('max_login_failed').dirty || infoForm.get('max_login_failed').touched || isValidate == true)">
                            Bạn chỉ được nhập số tự nhiên tối đa 12 chữ số!
                        </mat-error>
                    </td>
                </tr>
                <tr>
                    <td style="width: 20%">
                        <label class="lb-global">{{lang.label.security_info.password.title}}</label>
                        <label class="lb-global" style="float: right">
                            {{lang.label.security_info.password.from}} &nbsp;
                        </label>
                    </td>
                    <td style="width: 10%">
                        <label class="lb-global">
                            <input   type="number" min="1" onlyNumber (keypress)="numberOnly($event)" 
                                formControlName="password_min_length" class="form-control input-field-global">
                            <mat-error
                                *ngIf="infoForm.get('password_min_length').hasError('pattern') &&
                        (infoForm.get('password_min_length').dirty || infoForm.get('password_min_length').touched || isValidate == true)">
                                Bạn chỉ được nhập số tự nhiên tối đa 12 chữ số!
                            </mat-error>
                        </label>
                    </td>
                    <td fxLayout="row">
                        <label fxLayoutAlign="start center" class="lb-global">
                            &nbsp;
                            {{lang.label.security_info.password.char}}
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <label fxLayoutAlign="start center" class="lb-global text-right"
                            *ngIf="infoForm.get('password_min_length').value !== 0">
                            <span style="margin-right: 10px">{{lang.label.security_info.password.to}}</span>
                        </label>
                        <div class="lb-global" style="width: 120px"
                            *ngIf="infoForm.get('password_min_length').value !== 0">
                            <input type="number" max="50" onlyNumber (keypress)="numberOnly($event)" 
                                formControlName="password_max_length" class="form-control input-field-global">
                            <mat-error
                                *ngIf="infoForm.get('password_max_length').hasError('pattern') &&
                        (infoForm.get('password_max_length').dirty || infoForm.get('password_max_length').touched || isValidate == true)">
                                Bạn chỉ được nhập số tự nhiên tối đa 12 chữ số!
                            </mat-error>
                        </div>
                        <label fxLayoutAlign="start center" class="lb-global"
                            *ngIf="infoForm.get('password_min_length').value !== 0"> &nbsp;
                            {{lang.label.security_info.password.char}}
                        </label>
                    </td>
                </tr>
                <tr>
                    <td style="width: 20%;"></td>
                    <td>
                        <mat-error
                            *ngIf="infoForm.get('password_min_length').value > infoForm.get('password_max_length').value &&
                            (infoForm.get('password_max_length').dirty || infoForm.get('password_max_length').touched || infoForm.get('password_min_length').touched || infoForm.get('password_max_length').dirty)">
                            <!-- Độ dài mật khẩu đến phải lớn hơn độ dài mật khẩu từ! -->
                            Bạn xem lại thiết lập độ dài mật khẩu!
                        </mat-error>
                    </td>
                </tr>
                <tr>
                    <td style="width: 20%"><label class="lb-global">
                            {{lang.label.security_info.password_includes.label}}
                        </label>
                    </td>
                    <td style="width: 10%">
                        <mat-checkbox (change)="itemCheck(0)" [checked]="dataChecked[0].checked">
                            {{lang.label.security_info.password_includes.lowercase_letters}}
                        </mat-checkbox>
                        <br>
                        <mat-checkbox (change)="itemCheck(1)" [checked]="dataChecked[1].checked">
                            Chữ cái in hoa
                        </mat-checkbox>
                    </td>
                    <td class="d-flex">
                        <div>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <mat-checkbox (change)="itemCheck(3)" [checked]="dataChecked[3].checked">
                                Ký tự đặc biệt
                            </mat-checkbox>
                            <br>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <mat-checkbox (change)="itemCheck(2)" [checked]="dataChecked[2].checked">
                                Ký tự số
                            </mat-checkbox>
                        </div>
                        <div>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <mat-checkbox (change)="checkAll()" [checked]="isCheckAll">
                                Tất cả
                            </mat-checkbox>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="width: 20%;">
                        <label class="lb-global">Thời gian gửi mã
                        </label>
                    </td>
                    <td style="width: 10%">
                        <label class="lb-global">
                            <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1"
                                formControlName="resend_code_max_time" class="form-control input-field-global">
                        </label>
                    </td>
                    <td>
                        &nbsp; &nbsp; phút
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td colspan="2">
                        <mat-error
                            *ngIf="infoForm.get('resend_code_max_time').hasError('pattern') &&
                        (infoForm.get('resend_code_max_time').dirty || infoForm.get('resend_code_max_time').touched || isValidate == true)">
                            Bạn chỉ được nhập số tự nhiên tối đa 12 chữ số!
                        </mat-error>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <br>
    <!-- Thông tin bảo mật -->
    <div fxLayout="row" fxLayoutAlign="center end">
        <a class="btn custom-btn custom-btn-close" (click)="cancel()">HỦY</a>&nbsp;&nbsp;
        <button class="btn custom-btn custom-btn-add">LƯU</button>
    </div>

</form>