<div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<form [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm.value)" style="width: 100%" *ngIf="isLoading === false">
    <!-- Hệ thống -->

    <div class="panel-content-global">
        <div class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">

            <div class="panel-title">Hệ thống</div>
        </div>
        <div class="row" style="padding-left: 15px;">
            <div class="row col-md-9">
                <div class="form-group col-md-6 row">
                    <label class="col-md-3 lb-global">Tên hệ thống <span class="text-danger">(*)</span></label>
                    <div class="col-md-8">
                        <input type="text" formControlName="fullname" class="form-control input-field-global">
                        <mat-error *ngFor="let validation of messageError.fullname">
                            <p class="error-message" *ngIf="infoForm.get('fullname').hasError(validation.type) &&
                        (infoForm.get('fullname').dirty || infoForm.get('fullname').touched || isValidate == true)">
                                {{validation.message}}</p>
                        </mat-error>

                    </div>
                </div>
                <div class="form-group col-md-6 row">
                    <label class="col-md-3 offset-1 lb-global">Điện thoại</label>
                    <div class="col-md-8 pr-0">
                        <input formControlName="phone" type="text" class="form-control input-field-global">
                        <mat-error *ngFor="let validation of messageError.phone">
                            <p class="error-message" *ngIf="infoForm.get('phone').hasError(validation.type) &&
                        (infoForm.get('phone').dirty || infoForm.get('phone').touched || isValidate == true)">
                                {{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 row">
                    <label class="col-md-3 lb-global">Email <span class="text-danger">(*)</span></label>
                    <div class="col-md-8">
                        <input type="email" formControlName="email" class="form-control input-field-global">
                        <mat-error *ngFor="let validation of messageError.email">
                            <p class="error-message" *ngIf="infoForm.get('email').hasError(validation.type) &&
                        (infoForm.get('email').dirty || infoForm.get('email').touched || isValidate == true)">
                                {{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 row">
                    <label class="col-md-3 offset-1 lb-global">Hotline</label>
                    <div class="col-md-8 pr-0">
                        <input formControlName="hotline" type="text" class="form-control input-field-global">
                        <mat-error *ngFor="let validation of messageError.hotline">
                            <p class="error-message" *ngIf="infoForm.get('hotline').hasError(validation.type) &&
                        (infoForm.get('hotline').dirty || infoForm.get('hotline').touched || isValidate == true)">
                                {{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 row">
                    <label class="col-md-3 lb-global">Ngôn ngữ</label>
                    <div class="col-md-8">
                        <select class="form-control input-field-global" formControlName="language">
                            <option value="vi">Tiếng Việt</option>
                            <option value="en">Tiếng Anh</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-12 row" fxLayout="row">
                    <label class="lb-global label-address-custom" fxFlex="13.9%">Địa chỉ</label>
                    <div class="pl-0 pr-0 input-address-custom" fxFlex="81.3%">
                        <textarea type="text" class="form-control input-field-global" formControlName="address"></textarea>
                        <mat-error *ngFor="let validation of messageError.address">
                            <p class="error-message" *ngIf="infoForm.get('address').hasError(validation.type) &&
                        (infoForm.get('address').dirty || infoForm.get('address').touched || isValidate == true)">
                                {{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>

            </div>
            <div class="col-md-3" fxLayout="column" fxLayoutAlign="center end">
                <img [src]="fileImage.logo.url" class="logo">
                <div class="custom-title-image btn-logo" (click)="chooseFile(logo)">
                    {{lang.label.info_system.logo}}
                    <input type="file" hidden #logo (change)="onFileChange($event, 'logo')" accept="image/*">
                </div>
            </div>
        </div>
    </div>
    <!-- Hệ thống -->

    <!-- Trang đăng nhập -->
    <div class="panel-content-global">
        <div class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">

            <div class="panel-title">Trang đăng nhập</div>
            </div>
        <div class="row" style="padding-left: 15px;">
            <div class="row col-md-12">
                <label class="col-md-1 lb-global">Nội dung</label>
                <div class="col-md-11" style="padding-right: 5px;">
                    <textarea formControlName="login_welcome" class="form-control input-field-global" rows="4">
                </textarea>
                </div>
            </div>
        </div>
        <br>
        <div class="row" style="padding-left: 15px;">
            <div class="row col-xl-12">
                <label class="col-xl-1 lb-global">Ảnh</label>
                <div class="col-xl-10 row">
                    <div class="col-xl-4 logo">
                        <img [src]="fileImage.login_logo.url" class="logo">
                        <div class="custom-title-image btn-logo" (click)="chooseFile(login_logo)">
                            {{lang.label.info_system.logo}}
                            <input type="file" hidden #login_logo (change)="onFileChange($event, 'login-logo')"
                                accept="image/*">
                        </div>
                    </div>
                    <div class="col-xl-5 logo-image pl-0">
                        <img [src]="fileImage.login_background.url" class="login-background">
                        <div class="custom-title-image btn-login-background" (click)="chooseFile(login_background)">
                            {{lang.label.info_system.logo_app}}
                            <input type="file" hidden #login_background
                                (change)="onFileChange($event, 'login-background')" accept="image/*">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <!-- Trang đăng nhập -->
    <div fxLayout="row" fxLayoutAlign="center end">
        <a class="btn custom-btn custom-btn-close" (click)="cancel()">HỦY</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn custom-btn custom-btn-add">LƯU</button>
    </div>
</form>