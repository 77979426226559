<p class="text-center title-page-global">Thêm khách hàng</p>
<div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <div class="col-md-12">
                <input type="text" class="form-control" id="inputSearch" aria-describedby="emailHelp"
                    placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email"  (keydown)="search($event)">
                <div style="float: left; position: relative; left: 10px; bottom: 30px; cursor: pointer">
                    <img src="assets/images/svg/search.svg" alt="">
                </div>
            </div>
        </div>
        <div class="col-md-12 d-flex justify-content-start">
            <div class="col-md-12">
                <mat-checkbox [checked]="select_all" (change)="onSelectAll($event)">
                    Chọn tất cả
                </mat-checkbox>
                <span class="ml-5">Đã chọn: {{ count_selected }} khách hàng mới</span>
            </div>
        </div>
    </div>
    <div id="custom-scroll">
        <table mat-table [dataSource]="dataCustomer" class="table-el">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-left">Chọn</th>
                <td class="border-first-5" mat-cell *matCellDef="let element"> 
                    <mat-checkbox [value]="element.tenantId" [checked]="element.is_selected" (change)="countSelected($event);"></mat-checkbox>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Tên người dùng</th>
                <td mat-cell *matCellDef="let element"> {{element.tenantName | substring: 25}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Mã</th>
                <td mat-cell *matCellDef="let element"> {{element.tenantCode}} </td>
            </ng-container>

            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Tên miền</th>
                <td mat-cell *matCellDef="let element"> {{element.tenantDomain}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> SĐT</th>
                <td mat-cell *matCellDef="let element"> {{element.tenantPhone == null ? '#' : element.tenantPhone}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"> Email</th>
                <td mat-cell *matCellDef="let element"> {{element.tenantEmail}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-dialog-actions align="center">
        <button mat-button class="btn btn-cancel" mat-dialog-close>HỦY</button>
        <button mat-button type="submit" class="btn btn-add" (click)="clickAddTenant();">THÊM</button>
    </mat-dialog-actions>
</div>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>