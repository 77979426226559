import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GradeManagerService } from '@app/_services/grade.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dialog-subject-applies',
  templateUrl: './dialog-subject-applies.component.html',
  styleUrls: ['./dialog-subject-applies.component.scss']
})
export class DialogSubjectAppliesComponent implements OnInit {
  displayedColumns: string[] = ['stt','fullname', 'code', 'is_moet', 'status'];
  listAccount:any;
  length:number = 0;
  gradeName:string;
  constructor(
      dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data,
      private gradeManagerService:GradeManagerService
  ) { }

  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(keyword = ''){
    let dataFilter = {
      "keyword": keyword
    };
    this.gradeManagerService
      .filterSubjectOfGrade(this.data.id,dataFilter)
      .subscribe((data:any) => {
        this.length = data.data.length;
        this.listAccount = new MatTableDataSource(data.data);
      },
      (error => {
      })
    );

    this.gradeManagerService
      .detailGrade(this.data.id)
      .subscribe((data:any) => {
        this.gradeName = data.data.fullname;
      },
      (error => {
      })
    );
  }

  searchListSubject(event,keyword){
    if(event.key == "Enter"){
      this.loadDataItems(keyword);
    }
  }

}
