<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<table mat-table [dataSource]="dataSource" class="table-el">
    <ng-container matColumnDef="stt">
        <th mat-header-cell *matHeaderCellDef style="width: 5%;"> STT</th>
        <td class="border-first-5" mat-cell *matCellDef="let element, let i = index"> {{(pageSize * pageIndex) + i + 1}}
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="fullname">
        <th mat-header-cell *matHeaderCellDef style="width: 25%;"> Tên Module</th>
        <td mat-cell *matCellDef="let element">
            <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm3]">

                <a routerLink="detail/{{element.id}}" [queryParams]="{ type: 'coreAdmin-tab-permission' }"
                    queryParamsHandling="merge">
                    {{element.fullname}}
                </a>
            </ng-template>
            <ng-template [ngxPermissionsExcept]="[MODULE_PERMISSION.perm3]">
                {{element.fullname}}
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Mã module</th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
    </ng-container>

    <ng-container matColumnDef="permission">
        <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Quyền</th>
        <td mat-cell *matCellDef="let element">
            <div class="space">
                <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm9]">

                    <span class="box-list" (click)="openDialogListCustomer(element)">{{element.permissionCount}}</span>
                </ng-template>
                <ng-template [ngxPermissionsExcept]="[MODULE_PERMISSION.perm9]">
                    <span class="box-list">{{element.permissionCount}}</span>

                </ng-template>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Trạng thái</th>
        <td mat-cell *matCellDef="let element">
            <ng-template [ngxPermissionsOnly]="[MODULE_PERMISSION.perm4]">
                <mat-checkbox (change)="changeStatusRole($event, element)" [color]="'warn'" [checked]="element.isActive == 1? true: false" class="example-margin">Kích hoạt
                </mat-checkbox>
            </ng-template>
            <ng-template [ngxPermissionsExcept]="[MODULE_PERMISSION.perm4]">
                <mat-checkbox disabled [checked]="element.isActive == 1? true: false" class="example-margin">Kích hoạt
                </mat-checkbox>
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th class="text-right" mat-header-cell *matHeaderCellDef style="width: 15%;"> Công cụ</th>
        <td class="border-last-5 text-right" mat-cell *matCellDef="let element">
            <button class="btn" (click)="openDialogUpdateModule(element)">
                <img src="assets/images/svg/edit.svg" alt="">
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ng-container *ngIf="totalRecord > pageSize">

<mat-paginator showFirstLastButtons appStylePaginator class="table-paginate-global" [length]="totalRecord"
    [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)"></mat-paginator>
    </ng-container>
