<h2 mat-dialog-title class="title-dialog text-center">{{action == 'add' ? 'Thêm quyền' : 'Chỉnh sửa quyền'}}</h2>
<div class="container-fluid" fxLayout="column">
    <form [formGroup]="permissionForm" (ngSubmit)="onSubmit(permissionForm.value, permissionForm.valid)">
        <mat-dialog-content class="mat-typography">
            <!-- Thông tin cơ bản -->
            <mat-expansion-panel [expanded]="true" class="panel-content-global mat-elevation-z">
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global">Tên quyền <span class="lb-error"> (*)</span></label>
                        <div class="col-md-8 pl-0 pr-0">
                            <input formControlName="fullname" type="text" class="form-control input-field-global" placeholder="Nhập tên quyền">
                            <mat-error *ngFor="let validate of permissionValidate.fullname">
                                <span class="error-message" *ngIf="permissionForm.get('fullname').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group col-md-6 ml-auto row row-global p-0">
                        <label class="col-md-3 offset-1 lb-global">Mã quyền <span class="lb-error">(*)</span></label>
                        <div class="col-md-8 pl-0 pr-0">
                            <input [readOnly]="action === 'update'" style="background-color: #F2F3F8" formControlName="code" type="text" class="form-control input-field-global" placeholder="Nhập mã quyền">
                            <span class="text-danger">{{errCode}}</span>
                            <mat-error *ngFor="let validate of permissionValidate.code">
                                <span class="error-message" *ngIf="permissionForm.get('code').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6 row row-global p-0">
                        <label class="col-md-3 lb-global">Thứ tự sắp xếp</label>
                        <div class="col-md-8 pl-0 pr-0">
                            <input formControlName="indexOrder" onlyNumber (keypress)="numberOnly($event)" type="number" min="1" class="form-control input-field-global" placeholder="Thứ tự sắp xếp">
                        </div>
                    </div>
                </div>
                <div class="row" fxLayout="row">
                    <div class="col-md-12 d-flex p-0">
                        <label class="custom-label-content lb-global" style="width: 13%;">Mô tả</label>
                        <div style="width: 90%;">
                            <textarea formControlName="description" type="text" class="form-control input-field-global" rows="4" placeholder="Nhập mô tả quyền"></textarea>
                            <mat-error *ngFor="let validate of permissionValidate.description">
                                <span class="error-message" *ngIf="permissionForm.get('description').hasError(validate.type) && checkValidate">{{validate.message}}</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <a mat-dialog-close class="btn-custom-form-permission btn btn-close" (click)="onDisMiss()">HỦY</a>
            <button type="submit" class="btn-custom-form-permission btn btn-add-edit ml-2 px-4">{{action === 'add' ? 'THÊM' : 'LƯU'}}
            </button>
        </mat-dialog-actions>

    </form>
</div>