import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ShowMessage } from "@app/_services";
import { Observable, Subscriber } from "rxjs";
import { ListenFirebaseService } from "@app/_services/listen-firebase.service";
import { DATA_FAKE } from '../../constant';
import { DialogCurriculumComponent } from '../../dialogs/dialog-curriculum/dialog-curriculum.component';
import { CurriculumManagerService } from '@app/_services/curriculm-manager.service';
import { TIME_OUT_LISTEN_FIREBASE, MESSAGE_ERROR_CALL_API } from "@app/_shared/utils/constant";
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { DialogAssginSchoolYearCurriculumComponent } from '../../dialogs/dialog-assgin-shool-year-curriculum/dialog-assgin-shool-year-curriculum.component';
@Component({
  selector: 'app-curriculum-list',
  templateUrl: './curriculum-list.component.html',
  styleUrls: ['./curriculum-list.component.scss']
})
export class CurriculumListComponent implements OnInit {
  isLoading: boolean = false;
  data = DATA_FAKE;
  listCurriculums =[];
  dataFilter:any = {
    subjectId: '',
    gradeId: '',
    keyword: '',
    isApply: 0,
  }
  pageSize: number = 15;
  pageIndex: number = 0;
  subjectName:string ='';
  gradeName:string = '';
  constructor(
      private activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      private router: Router,
      private listenFirebaseService: ListenFirebaseService,
      private showMessage: ShowMessage,
      private curriculumManagerService: CurriculumManagerService,
      ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.dataFilter.subjectId = params['subjectId'];
      this.getListCurriculums();
    });
    this.activatedRoute.queryParams.subscribe(res => {
      this.dataFilter.gradeId = res['gradeId'];
      if(this.dataFilter.gradeId)
      this.getListCurriculums();
      this.subjectName = res.subjectName;
      this.gradeName = res.gradeName;
    })
  }
  
  getListCurriculums() {
    this.isLoading = true;
    let dataInput = {
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      subjectId: this.dataFilter.subjectId,
      gradeId: this.dataFilter.gradeId == '' || this.dataFilter.gradeId == undefined || this.dataFilter.gradeId == 'null' ? null : this.dataFilter.gradeId,
      keyword: this.dataFilter.keyword
    }
    this.curriculumManagerService.getListCurriculums(dataInput).subscribe((res: any) => {
      this.isLoading = false;
      if(res.status == 1) { 
        this.listCurriculums = res.data.data;
      } else {
        this.showMessage.error(res.msg);
      }
      
    }, (err:any) => {
      this.isLoading = false;
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  openLinkEdit(id) {
    this.router.navigate([`curriculum/form/${id}`], {queryParams: {nameForm: 'update', tab: 'content'}});
  }

  delelete(value) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '40%',
      data: {
        data: {...value, name: value.fullName},
        title: 'Xóa khung chương trình',
        message: 'Bạn có chắc chắn muốn xóa khung chương trình này không?',
        btnCancelText: 'Hủy',
        btnOkText: 'Xóa'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.isLoading = true;
        let dataInput = {
          khungChuongTrinhId: value.id
        }
        this.checkFireBase('delete');
        this.setTimeOutFireBase();
        this.curriculumManagerService.deleteCurriculum(dataInput).subscribe((res: any) => {
          if(res.status == 0) {
            this.isLoading = false;
            this.showMessage.error(res.msg);
          }  
        }, (err:any) => {
          this.isLoading = false;
          this.showMessage.error(MESSAGE_ERROR_CALL_API);
        });
      }
    });
  }

  openCurriculum() {
    const dialogRef = this.dialog.open(DialogCurriculumComponent, {
      width: '60%',
      data: {
        nameForm: 'create', 
        data: {
          subjectName:  this.subjectName,
          subjectId: this.dataFilter.subjectId
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getListCurriculums();
      }
    });
  }

  assignSchoolYear(value) {
    const dialogRef = this.dialog.open(DialogAssginSchoolYearCurriculumComponent, {
      width: '60%',
      data: {
        nameForm: 'assign', 
        data: value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getListCurriculums();
      }
    });
  }

  removeShoolYear(value) {
    const dialogRef = this.dialog.open(DialogAssginSchoolYearCurriculumComponent, {
      width: '60%',
      data: {
        nameForm: 'remove', 
        data: value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getListCurriculums();
      }
    });
  }

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, 'khung_chuong_trinh', subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.isLoading = false;
        this.getListCurriculums();
      }
    });
  }
  setTimeOutFireBase() {
    setTimeout(() => {
      if(this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
  
}
