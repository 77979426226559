<h2 mat-dialog-title class="title-page-global text-center">Thêm khách hàng</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <span>Tổng số: {{dataSource.length}} khách hàng</span>
            </div>
        </div>
        <div class="row input-search-dialog-list">
            <div class="col-sm-12">
                <input type="text" #input_search (keydown)="keyPressSearch($event)" class="form-control input-field-global" placeholder="Nhập tên tài khoản hoặc mã, số điện thoại, email">
                <img src="assets/images/icons/icon-23.svg">
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-12"  fxLayout="row">
                <div><mat-checkbox id="check-all" [checked]="isCheckAll" (change)="checkAll()"></mat-checkbox> Chọn tất cả</div>
                <div class="ml-5">Đã chọn: {{countCustom}} khách hàng mới</div>
            </div>
        </div>
        <form class="row" id="customer">
            <table mat-table [dataSource]="dataSource" class="table-el">
                <ng-container matColumnDef="stt">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Chọn</th>
                    <td mat-cell *matCellDef="let item; let i = index">
                        <mat-checkbox class="check-box" value="{{item.id}}" [checked]="item.isChecked" (change)="chooseInfo(i)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">Khách hàng</th>
                    <td mat-cell *matCellDef="let item" class="text-important-table-global">
                        <a href="tenants-manager/detail/{{item.id}}?active=tab1" target="_blank">
                            {{item?.fullname}}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                    <td mat-cell *matCellDef="let item">{{item.code}}</td>
                </ng-container>
                <ng-container matColumnDef="domain">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Tên miền</th>
                    <td mat-cell *matCellDef="let item">
                        <a href="{{item.tenantUrl}}" target="_blank">
                            {{item.tenantUrl | substring: 20}}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">SĐT</th>
                    <td mat-cell *matCellDef="let item">{{item.hotline}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%">Email</th>
                    <td mat-cell *matCellDef="let item">{{item.email}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
                <tr mat-row *matRowDef="let row; columns: dataColumn"></tr>
            </table>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn btn-close mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
    <button class="btn btn-add mat-elevation-z" mat-raised-button (click)="onSubmit()">Thêm</button>
</mat-dialog-actions>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>