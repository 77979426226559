<section class="notification">
    <h4 class="title-page-global notification__title">Thông báo</h4> 
    <div class="notification__inner" *ngFor="let box of data_noti_test">
        <div class="notification__box">
            <img [src]="box.src_img" alt="noti-img"/>
            <div class="notification__content">
                <h4 class="notification__title">{{box.title}}</h4>
                <p class="desc">{{box.content}}</p>
                <div>
                    <span class="author">Từ: {{box.author}}</span>
                    <span class="date">Đã ngày {{box.date}}</span>
                </div>
            </div>
        </div>
        
        
    </div>
</section>