import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsManagerRoutingModule } from './accounts-manager-routing.module';
import {SharedModule} from '../../_shared/shared.module';

import { DetailAccountComponent } from './components/detail-account/detail-account.component';
import { DialogFormAccountComponent } from './dialogs/dialog-from-account/dialog-form-account.component';
import {AccountsManagerListComponent} from './components/accounts-manager-list/accounts-manager-list.component';
import {AccountInfoComponent} from './components/tabs/account-info/account-info.component';
import { AccountTenantListComponent } from './components/tabs/account-tenant-list/account-tenant-list.component';
import { AccountActionHistoryComponent } from './components/tabs/account-action-history/account-action-history.component';

import {ReactiveFormsModule} from '@angular/forms';
import { DecentralizationAppliesComponent } from './components/tabs/decentralization-applies/decentralization-applies.component';
import { DialogAddDecentralizationComponent } from './dialogs/dialog-add-decentralization/dialog-add-decentralization.component';
import { DialogAddTenantComponent } from './dialogs/dialog-add-tenant/dialog-add-tenant.component';
import { DialogListRolesComponent } from './dialogs/dialog-list-roles/dialog-list-roles.component';
import { DialogListCustomersComponent } from './dialogs/dialog-list-customers/dialog-list-customers.component';
import { DialogResetPasswordsComponent } from './dialogs/dialog-reset-passwords/dialog-reset-passwords.component';
import { DialogImportUsersComponent } from './dialogs/dialog-import-users/dialog-import-users.component';
import { DialogChangeStatusComponent } from './dialogs/dialog-change-status/dialog-change-status.component';
import { DialogShowDataActionHistoryComponent } from './dialogs/dialog-show-data-action-history/dialog-show-data-action-history.component';
import { ImportUsersComponent } from './components/import-users/import-users.component';
import {NgbDatepickerModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {OutsideDirective} from './outside.directive';
import {DatetimePickerToggleComponent} from './dialogs/datetime-picker-toggle/datetime-picker-toggle.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
      DetailAccountComponent,
      DialogFormAccountComponent,
      AccountsManagerListComponent,
      AccountInfoComponent,
      AccountTenantListComponent,
      AccountActionHistoryComponent,
      DecentralizationAppliesComponent,
      DialogAddDecentralizationComponent,
      DialogAddTenantComponent,
      DialogListRolesComponent,
      DialogListCustomersComponent,
      DialogResetPasswordsComponent,
      DialogImportUsersComponent,
      DialogChangeStatusComponent,
      DialogShowDataActionHistoryComponent,
      ImportUsersComponent,
      OutsideDirective,
      DatetimePickerToggleComponent

  ],
    imports: [
        CommonModule,
        AccountsManagerRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        NgbTimepickerModule,
        NgbDatepickerModule,
        NgxPermissionsModule.forChild()
    ],
    providers: [
    ],

})
export class AccountsManagerModule { }
