import { Component, OnInit } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/_modules/accounts-manager/dialogs/dialog-from-account/dialog-form-account.component';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import * as _moment from 'moment';
import * as $ from 'jquery';
import * as Editor from '../../../../../assets/js/ckeditor/build/ckeditor';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Post, Tenant} from '@app/_models';
import {PostManagerService} from '@app/_services/post-manager.service';
import {ShowMessage, TenantManagerService} from '@app/_services';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import { PostManagerFileManagerComponent } from '../../dialogs/post-manager-file-manager/post-manager-file-manager.component';
import { REGEX_FULL_NAME } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-post-manager-form',
  templateUrl: './post-manager-form.component.html',
  styleUrls: ['./post-manager-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class PostManagerFormComponent implements OnInit {
  urlImage: SafeResourceUrl;
  selectedFile: File;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  time = {hour: 0, minute: 0};
  meridian = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  hiddenTime = false;
  scopePost: number = 0;
  limitPost: number = 0;
  checkPublish = false;
  postForm: FormGroup;
  content: string;
  post: any;
  checkValidate: boolean = false;
  dateOfBirth: any = '';
  tenants: any;
  dataSelectTenants: any = [];

  public Editor = Editor;
  postValidate = {
    title: [
      {type: 'required', message: 'Vui lòng nhập tiêu đề bài viết'},
      {type: 'pattern', message: 'Vui lòng độ dài 2-350 ký tự, a-z, A-Z, 0-9'}],
    summaryContent: [
      {type: 'required', message: 'Vui lòng nhập tóm tắt bài viết'},
      {type: 'pattern', message: 'Vui lòng độ dài 2-500 ký tự, a-z, A-Z, 0-9'}],
    content: [{type: 'required', message: 'Vui lòng nhập nội dung bài viết'}],
    timeDatePublish: 'Vui lòng chọn thời gian công khai',
  };
  constructor(
      private sanitizer: DomSanitizer,
      private fb: FormBuilder,
      private postManagerService: PostManagerService,
      private tenantManagerService: TenantManagerService,
      private showMessage: ShowMessage,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getAllTenant();

    if (this.activatedRoute.snapshot.queryParams.msg === 'add') {
      this.post = '';
      this.postForm = this.fb.group({
        title: [this.post ? this.post.title : '', [Validators.required, Validators.pattern(REGEX_FULL_NAME)]],
        summaryContent: [this.post ? this.post.summaryContent : '', [Validators.required, Validators.pattern("^[a-z\\d\\A-Z]{2,500}$")]],
        content: [this.post ? this.post.content : '', Validators.required],
        sendRange: this.post ? this.post.sendRange : 0,
        targetGroup: this.post ? this.post.targetGroup : 0,
        recipient: this.post ? this.post.recipient : 0,
        allowSendMail: this.post.allowSendMail,
        allowComment: this.post.allowComment,
        sendNotice: this.post.sendNotice,
      });
    }
    else{
      this.getPostManagerById();
    }


  //   let img = "<img src='https://homepages.cae.wisc.edu/~ece533/images/airplane.png'/>'";
  // this.Editor.instances.editor1.insertHtml(img);
//     this.Editor.editorConfig = function(config) {
//       config.language = 'es';
//       config.uiColor = '#F7B42C';
//       config.height = 300;
//       config.toolbarCanCollapse = true;
    
//     };

//     Editor.replace('editor1');
//let editor = CKEDITOR.instances.editor1;
// editor.ui.addButton('button1', {
//   label: 'Button1',
//   command: 'mycommand1'
// });
// editor.ui.addButton('button2', {
//   label: 'Button2',
//   command: 'mycommand2'
// });
// editor.addCommand('mycommand1', {
//   exec: function(editorInstance) {
//     console.log('button1/command1');
//     editorInstance.insertText('Button 1 pressed ');
//   }
// });
// editor.addCommand('mycommand2', {
//   exec: function(editorInstance) {
//     console.log('button2/command2');
//     editorInstance.insertText('Button 2 pressed ');
//   }
// });
  }

  
  

  openFileManager(){
    const dialogFileManager = this.dialog.open(PostManagerFileManagerComponent, {
      height: '600px',
      width: '70%',
      data: {
        
      }
    });
    dialogFileManager.afterClosed().subscribe((ref: any) => {
      if (ref){
        this.postForm.get('content').setValue(`${this.postForm.get('content').value} <img src= ${ref.url} alt=${ref.url} />`);
      }
    });
  }

  checkFireBase(param){
    return this.db.list(`core_admin/post/${param}/${environment.userID}`).valueChanges().subscribe((res: any) => {
      if (res.length > 0) {
        let parseRes = JSON.parse(res[0]);
        if (parseRes.status === true) {
          this.showMessage.success(parseRes.message);
          this.db.list(`core_admin/post/${param}/${environment.userID}`).remove();
          return this.router.navigate([`${'post-manager'}`], { queryParams: {msg: 'Thêm bài viết thành công'}});
        }

        return this.showMessage.error(parseRes.message);
      }
    });
  }

  // catchEventClick(event) {
  //   console.log(event);
  //   if(event.target.type === "file" && event.target.nodeName === "INPUT"){
  //     let urlImage = this.openFileManager();
  //     // let img = "https://homepages.cae.wisc.edu/~ece533/images/airplane.png";
  //     // $( ".ck-input-text" ).val(img);
  //     event.preventDefault();
  //     return;
  //   }
  //   return;
  // }

  getPostManagerById(){
    this.postManagerService.getPostById(this.activatedRoute.snapshot.params.id).subscribe((ref: any) => {
      this.post = ref.data;
      this.checkPublish = ref.data.sendNotice === 1 ? true : false;
      if (ref.status === 1){
        this.postForm = this.fb.group({
          title: [ref.data.title , [Validators.required, Validators.pattern(REGEX_FULL_NAME)]],
          summaryContent: [ref.data.summaryContent ? ref.data.summaryContent : '', [Validators.required, Validators.pattern("^[a-z\\d\\A-Z]{2,500}$")]],
          content: [ref.data.content, Validators.required],
          sendRange: ref.data.sendRange,
          targetGroup: ref.data.targetGroup,
          recipient: ref.data.recipient,
          allowSendMail: ref.data.allowSendMail === 1 ? true : false,
          allowComment: ref.data.allowComment === 1 ? true : false,
          sendNotice: ref.data.sendNotice === 1 ? true : false,
        });
      }
    });
  }

  changeScope(event){
    this.scopePost = event;
  }
  changeLimit(event){
    this.limitPost = event;
  }
  checkPublishAfter(event){
    this.checkPublish = event.checked;
  }

  getAllTenant(){
    this.tenantManagerService.getDataTenant(100, 0, '').subscribe((ref: any) => {
      this.tenants = ref.data;
    });
  }

  submitPost(validate, value){
    if (!validate){
      this.checkValidate = true;
      return false;
    }
    let dataArray = [];
    this.dataSelectTenants.forEach(function(item) {
      dataArray.push(item.id);
    });
    value.avatar = 'https://khoahocphattrien.vn/Images/Uploaded/Share/2016/12/20/Nhung-buc-anh-dep-nhat-2016-chia-se-tren-Flickr_4.jpg';
    value.allowComment = value.allowComment ? 1 : 0;
    value.allowSendMail = value.allowSendMail ? 1 : 0;
    value.sendNotice = value.sendNotice ? 1 : 0;
    value.sendRange = Number(value.sendRange);
    value.targetGroup = Number(value.targetGroup);
    value.recipient = Number(value.recipient);
    value.recipients = dataArray;
    if (value.sendNotice){
      value.sendSchedule = _moment(this.dateOfBirth._d).add({hour: this.time.hour, minute: this.time.minute}).format();
    }
    if (this.activatedRoute.snapshot.queryParams.msg === 'add'){
      this.postManagerService.storePostManager(value).subscribe((ref: any) => {
        if (ref.status === 0) return this.showMessage.error(ref.msg);
        this.checkFireBase('create');
      });
    }
    else{
      value.id = this.activatedRoute.snapshot.params.id;
      this.postManagerService.editPostManager(value).subscribe((ref: any) => {
        if (ref.status === 0) return this.showMessage.error(ref.msg);
        this.checkFireBase('update');
      });
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
      };

    }
  }

  uploadImage() {
    document.getElementById('create-post-avatar').click();
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.dataSelectTenants.push(event.option.value);
    const index = this.tenants.indexOf(event.option.value);
    if (index >= 0) {
      this.tenants.splice(index, 1);
    }
  }

  remove(fruit): void {
    const index = this.dataSelectTenants.indexOf(fruit);
    if (index >= 0) {
      this.dataSelectTenants.splice(index, 1);
    }
    this.tenants.push(fruit);
  }
}

$(document).click(function() {
  let btnTimePost = $('.btn-time-post');
  let timePost = $('.time-post');
  if (!btnTimePost.is(event.target) && !btnTimePost.has(event.target).length) {
    if (!timePost.is(event.target) && !timePost.has(event.target).length){
      $('.time-post').addClass('hidden-time-post');
    }else{
      // $("#noti-user").addClass('hidden');
    }

  }else{
    $('.time-post').toggleClass('hidden-time-post');
  }
});
