import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Router, ActivatedRoute} from '@angular/router';
import * as $ from 'jquery';
import {AccountManagerService} from '../../../../_services/account-manager.service';
import * as XLSX from 'xlsx';
import {PersonalService, ShowMessage} from '@app/_services';
type AOA = any[][];

@Component({
    selector: 'app-dialog-import-users',
    templateUrl: './dialog-import-users.component.html',
    styleUrls: ['./dialog-import-users.component.scss']
})
export class DialogImportUsersComponent implements OnInit {
    fileName: string | ArrayBuffer = '';
    isFileExcel = false;
    message = '';
    isLoading = false;
    fileExcel = null;
    userId: string;
    data: AOA = [['Mã người dùng', 'Họ và tên', 'Tên đăng nhập', 'Mật khẩu', 'Email', 'Số điện thoại', 'Ngày sinh', 'Giới tính', 'Địa chỉ', 'Phường/Xã', 'Quận/Huyện', 'Tỉnh/TP', 'Trạng thái', 'Phân quyền']];
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    nameFileSample: string = 'fileSampleUser.xlsx';


    listError = [
        {name_error: 'Báo lỗi khi trùng dữ liệu', message: '*Hệ thống sẽ thực hiện kiểm tra và báo lỗi khi phát hiện dữ liệu trùng lặp'},
        {
            name_error: 'Ghi đè dữ liệu trùng lặp',
            message: '*Hệ thống sẽ thực hiện kiểm tra và tự động ghi đè khi phát hiện dữ liệu trùng lặp'
        },
        {
            name_error: 'Bỏ qua dữ liệu trùng lặp',
            message: '*Hệ thống sẽ thực hiện kiểm tra và tự động bỏ qua khi phát hiện dữ liệu trùng lặp'
        },
    ];

    listColumn = [
        'Mã', 'Tài khoản', 'Mật khẩu', 'Email', 'SĐT', 'Ngày sinh', 'Giới tính', 'Đìa chỉ', 'Phường xã'
    ];

    keyError = 0;

    infoForm;

    constructor(
        private  fb: FormBuilder,
        private route: Router,
        private dialogRef: MatDialogRef<DialogImportUsersComponent>,
        private accountManagerService: AccountManagerService,
        private showMessage: ShowMessage,
        private personalService: PersonalService,
    ) {
    }

    ngOnInit(): void {
        this.infoForm = this.fb.group({
            check_column: ['Tên đăng nhập'],
            action: [0],
            check_column_more: ['']
        });
        this.getInfoUserByUsername();
    }

    getInfoUserByUsername(){
        let token = JSON.parse(localStorage.getItem('currentUser'));
        return this.personalService.getInfoUserByAccessToken(token).subscribe((res: any) => {
            if (res.status === 1) return this.userId = res.data.id;

            return this.showMessage.error(res.msg);
        });
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            if (event.target.files[0].name.slice(-5) == '.xlsx' || event.target.files[0].name.slice(-4) == '.xls') {
                let file: File = event.target.files[0];
                let myFile = new FileReader();
                this.fileName = file.name;
                myFile.onload = (e) => {
                    let base64 = myFile.result;
                    if (typeof base64 === 'string') {
                        this.fileExcel = base64.slice(base64.indexOf(',') + 1);
                    }
                }

                myFile.readAsDataURL(file);
                this.message = '';
                // this.loaderProgress('loading-progress')
                this.isFileExcel = true;
                return this.message = 'Không đúng định dạng file';
            } else {
                console.log('không đúng định dạng');
            }
        }
    }

    uploadImage() {
        // this.isLoading = false;
        document.getElementById('inputUploadFile').click();
    }

    checkError(value) {
        return this.keyError = value;
    }

    exportSampleFile(){
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, this.nameFileSample);
    }

    loaderProgress(paramElement) {
        let width = 1;
        let id = setInterval(frame, 10);

        function frame() {
            if (width > 100) {
                clearInterval(id);
            } else {
                $('.' + paramElement).css('background', '#FF8038');
                $('.' + paramElement).css('width', width + '%');
                $('.' + paramElement).css('height', '4px');
                width++;
            }
        }

        // this.isLoading = true;
    }

    removeFile() {
        this.fileExcel = null;
        this.fileName = '';
        this.isFileExcel = false;
    }

    onSubmit(data) {
        if (this.fileExcel === ''){
            return this.message = ' Bạn chưa chọn file';
        }
        this.isLoading = true;
        this.accountManagerService.importCustomer(this.userId, this.fileExcel).subscribe((res: any) => {
            this.isLoading = false;
            if (res.status === 1){
                this.showMessage.success(res.msg);
                return this.dialogRef.close(res.data);
            }

            return this.showMessage.error(res.msg);
        }, error => {
            this.isLoading = false;
            this.showMessage.error(error, 4000);
        });
    }
}
