<div class="new-list-customers">
    <h4 class="title-page-global dashboard__title">Khách hàng mới</h4>
    <div fxFlex class="bg-table-global">
        <table mat-table class="table-el" [dataSource]="dataSource">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 8%">STT</th>
                <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="width: 14%">Tên khách hàng</th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>    

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 14%">Mã khách hàng</th>
                <td mat-cell *matCellDef="let element">{{element.userCode}}</td>
            </ng-container>

            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef style="width: 14%">Tên miền</th>
                <td mat-cell *matCellDef="let element">{{element.domain}}</td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 14%">Số điện thoại</th>
                <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 14%">Email</th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 14%">Trạng thái</th>
                <td mat-cell *matCellDef="let element">
                    <select id="select-status" (change)="changeOption($event, element.id)"
                            class="bg-color-status-{{this.element.status}}">
                        <option *ngFor="let state of dataStatus, let i= index" [value]="state.id" 
                            [attr.selected]="element.status== state.id ? true : null" style="background-color: #fff; color: #000">
                            {{state.status}}
                        </option>                    
                    </select>
                </td>
            </ng-container>

            <ng-container matColumnDef="tool" class="dashboar__tool">
                <th mat-header-cell *matHeaderCellDef style="width: 8%" class="text-center">Công cụ</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_horiz</mat-icon> 
                </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <button mat-menu-item>Chỉnh sửa</button>
                        <button mat-menu-item class="text-danger">Xóa</button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row, columns: displayedColumns"></tr>
        </table>
        <!-- <mat-paginator appStylePaginator showFirstLastButtons [length]="300" [pageSizeOptions]="[ 10, 20, 100]" class="table-paginate-global"></mat-paginator> -->
    </div>
</div>