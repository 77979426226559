import {Component, OnInit, Inject, HostListener, ElementRef} from '@angular/core';
import { RoleManagerService } from '@app/_services/role-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { element } from 'protractor';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-dialog-role-list-permissions',
  templateUrl: './dialog-role-list-permissions.component.html',
  styleUrls: ['./dialog-role-list-permissions.component.scss']
})
export class DialogRoleListPermissionsComponent implements OnInit {
  dataPermissions:any;
  dataPermissionAll:any;
  dataShowPermissions = [];
  arrayModuleIds = [];
  arrayResultPermissions = [];
  checkData: boolean = false;
  flag = true;
  isLoading = false;
  constructor(
      private eRef: ElementRef,
      private roleManagerService: RoleManagerService,
      @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.getDataPermissions();
  }

  getDataPermissions(keyWord?: string){
    this.roleManagerService.getPermissionsOfRole(this.data.roleId, keyWord, 0, 999999, '' ).subscribe(
      (data:any) => {
        this.dataPermissions = data.data.permissionCheck;
        this.dataPermissionAll = data.data.permissionAll;
        // lay ra nhung module id da duoc dung
        this.dataPermissions.forEach((element) => {
          this.arrayModuleIds.push(element.moduleId);
        });
        this.arrayModuleIds = this.unique(this.arrayModuleIds);
        // lay danh sach tat ca permisson thuoc cac module id
        this.arrayModuleIds.forEach((element) => {
          let arrayPermissionsTemp = [];
          let moduleId = '';
          let moduleName = '';
          let moduleCode = '';
          this.dataPermissionAll.forEach((item) => {
            if (element === item.moduleId){
              moduleName = item.moduleName;
              moduleCode = item.moduleCode;
              moduleId = item.moduleId;
              var checkActivePermission = this.dataPermissions.filter(element => element.permissionId === item.permissionId);
              if (checkActivePermission.length !== 0){
                item.status = true;
              }else{
                item.status = false;
              }
              arrayPermissionsTemp.push(item);
            }
          });
          if (arrayPermissionsTemp.length > 0){
            this.dataShowPermissions.push({
              id: moduleId,
              name: moduleName,
              code: moduleCode,
              permissons: arrayPermissionsTemp,
              is_selected: false
            });
          }
          if (this.dataShowPermissions.length > 0){
            this.dataShowPermissions.find(x => x.id  === moduleId).is_selected =
                this.dataShowPermissions.find(x => x.id  === moduleId).permissons != null
                && this.dataShowPermissions.find(x => x.id  === moduleId).permissons.every(t => t.status);
          }
        });
        this.checkData = true;
        this.isLoading = false;
        },
    );
  }

  unique(arr) {
    var obj = {};
    var newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (!obj[arr[i]]) {
        obj[arr[i]] = 1;
        newArr.push(arr[i]);
      }
    }
    return newArr;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  checkText(event, value) {
    setTimeout(() => {
      if (this.flag === true){
        if (event.keyCode === 13 || event.keyCode === 9) {
          this.isLoading = true;
          setTimeout(() => {
            this.dataShowPermissions = [];
            this.arrayModuleIds = [];
            this.getDataPermissions(value);
          }, 1000);
        }
      }
    }, 500);
  }
}
