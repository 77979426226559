import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-dialog-delete',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class DialogNotificationComponent implements OnInit {
  data_notification:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.data_notification = this.data;
  }
}
