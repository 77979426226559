<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between none" >
        <div><p class="title-page-global">Phân quyền</p></div>
        <div fxFlex="70%" fxLayout="row" fxLayoutAlign="end none" >
            <input #search (keydown)="checkText($event, search.value)" type="text" [readOnly]="showSearch == true ? false: true" id="search-value" class="text-left btn button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? 'Tên, mã phân quyền' : ''}}"  >
            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass()">
            <button class="btn btn-default btn-filter ml-2">
                <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilterClick()">
            </button>
            <ng-template ngxPermissionsOnly="{{permissionRole.perm2}}">
                <button [disabled]="disabled" class="btn btn-add-global mat-elevation-z" (click)="showFormRole('add')" mat-raised-button><mat-icon>add</mat-icon>Thêm phân quyền </button>
            </ng-template>
        </div>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="end start">
        <div fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter" style="padding-right: 11rem;">
            <button class="btn btn-default mr-2 btn-filter-option {{ checkActiveStatusRole == 'active' ? 'active' : '' }}" (click)="itemOptionClickRoleActive($event,1)">Kích hoạt</button>
            <button class="btn btn-default mr-2 btn-filter-option {{ checkActiveStatusRole == 'not_active' ? 'active' : '' }}" (click)="itemOptionClickRoleNotActive($event,0)">Chưa kích hoạt</button>
            <button class="btn btn-default btn-filter-option-action" (click)="clickFilterRole();">Lọc</button>
        </div>
    </div>
    <div fxFlex fxLayout="column" class="content-data">
        <table mat-table [dataSource]="dataSource" matSort class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 5%">STT</th>
                <td mat-cell *matCellDef="let element, let i = index">{{(pageSize * pageIndex) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Tên phân quyền</th>
                <td mat-cell *matCellDef="let element">
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm3}}">
                        <a class="link_table" href="javascript:;" (click)="opendetaiRole(element.id, 'tab1')">{{element.roleName | substring : 30}}</a>
                    </ng-template>
                    <ng-template ngxPermissionsExcept="{{permissionRole.perm3}}">

                        <a class="link_table" href="javascript:;" >{{element.roleName | substring : 30}}</a>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">Mã</th>
                <td mat-cell *matCellDef="let element">{{element.roleCode}}</td>
            </ng-container>
            <ng-container matColumnDef="count_permission">
                <th mat-header-cell *matHeaderCellDef class="text-center" style="width: 10%">Quyền</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm9}}">
                        <span class="show-more" (click)="showListPermission(element.id, element.roleName)">{{element?.numberPermission}}</span>
                    </ng-template>
                    <ng-template ngxPermissionsExcept="{{permissionRole.perm9}}">
                        <span class="show-more" >{{element?.numberPermission}}</span>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="user_count">
                <th mat-header-cell *matHeaderCellDef class="text-center" style="width: 10%">Người dùng</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <ng-template ngxPermissionsOnly="{{permissionRole.perm6}}">
                        <span class="show-more" (click)="showListUser(element.id, element.roleName)">{{element?.numberUser}}</span>
                    </ng-template>
                    <ng-template ngxPermissionsExcept="{{permissionRole.perm6}}">
                        <span class="show-more" >{{element?.numberUser}}</span>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="is_active">
                <th mat-header-cell *matHeaderCellDef class="text-center" style="width: 10%">Trạng thái</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <ng-template [ngxPermissionsOnly]="[permissionRole.perm4]">
                        <mat-checkbox [checked]="element.status === 1 ? true : false " (change)="changeStatusRole($event, element)"></mat-checkbox> Kích hoạt
                    </ng-template>
                    <ng-template [ngxPermissionsExcept]="[permissionRole.perm4]">
                        <mat-checkbox [checked]="element.status === 1 ? true : false "disabled></mat-checkbox> Kích hoạt
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="tool">
                <th mat-header-cell *matHeaderCellDef style="width: 5%" class="text-right">Công cụ</th>
                <td class="border-last-5 text-right" mat-cell *matCellDef="let element">
                    <button  class="btn btn-block" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon class="icon-more">more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-more-global">
                        <ng-template ngxPermissionsOnly="{{permissionRole.perm4}}">
                            <button mat-menu-item class="px-5" (click)="showFormRole('edit', element)">Chỉnh sửa</button>
                        </ng-template>
                        <ng-template ngxPermissionsOnly="{{permissionRole.perm3}}">
                            <button mat-menu-item class="px-5" (click)="opendetaiRole(element.id, 'tab1')">Quyền</button>
                            <button mat-menu-item class="px-5" (click)="opendetaiRole(element.id, 'tab2')">Người dùng</button>
                        </ng-template>
                        <ng-template ngxPermissionsOnly="{{permissionRole.perm5}}">
                            <button mat-menu-item class="text-danger px-5" (click)="confirmDelete(element.roleName, element.id)">Xóa</button>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" style="line-height: 10px"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns" style="background: white"></tr>
        </table>
        <ng-container *ngIf="totalRecord > pageSize">
        <mat-paginator appStylePaginator class="table-paginate-global" [length]="totalRecord" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
        </ng-container>
    </div>
</div>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
