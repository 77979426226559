import { Component, OnInit, Inject} from '@angular/core';
import {AccountManagerService, ListenFirebaseService, ShowMessage} from '@app/_services/index';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import {Observable, Subscriber} from 'rxjs';

@Component({
  selector: 'app-dialog-add-decentralization',
  templateUrl: './dialog-add-decentralization.component.html',
  styleUrls: ['./dialog-add-decentralization.component.scss']
})
export class DialogAddDecentralizationComponent implements OnInit {
  dataRolesNotAssign:any;
  checkAll:boolean = false;
  arrayRoles = [];
  count_selected:number = 0;
  isLoading = false;
  keyWord = '';
  typeModule = 'user';
  saveDataRole = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountManagerService:AccountManagerService,
    private matDialogRef:MatDialogRef<DialogAddDecentralizationComponent>,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,
  ) { }

  ngOnInit(): void {
    this.getDataRoles();
  }

  getDataRoles(){
    // get list role not assign
    this.count_selected = 0;
    this.arrayRoles = [];
    this.isLoading = true;
    this.accountManagerService.getRoleNotAssignByUserId(this.data.userId, this.keyWord).subscribe((res: any) => {
      this.dataRolesNotAssign = res.data;
      this.isLoading = false;
    });
  }

  selectAllItem(event){
    if(event.checked){
      this.dataRolesNotAssign.forEach((e)=>{
        this.arrayRoles.push(e.roleId);
      });

      this.checkAll = true;
    }else{
      this.arrayRoles = [];
      this.checkAll = false;
    }

    this.count_selected = this.arrayRoles.length;
  }

  selectItem(e){
    if(e.checked){
      this.arrayRoles.push(e.source.value);
      this.count_selected++;
    }else{
      const index = this.arrayRoles.indexOf(e.source.value);
      if (index > -1) {
        this.arrayRoles.splice(index, 1);
      }
      this.count_selected--;
    }
  }

  clickAddRolesToUser(){
    this.isLoading = true;
    this.accountManagerService.assignRolesToUser(this.data.userId,this.arrayRoles).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.status === 0) return this.showMessage.error(res.msg);
        this.matDialogRef.close(true);
      }, err => this.isLoading = false);


  }

  Search(event){
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.keyWord = event.target.value.trim();
      this.getDataRoles();
    }
  }
}
