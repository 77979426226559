import { Injectable } from '@angular/core';

import { v4 } from 'uuid';
import { FileElement } from '@app/_shared/components/file-manager/model/element';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from './user.service';
export interface IFileService {
  add(fileElement: FileElement);
  delete(id: string);
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
}

@Injectable()
export class FileService implements IFileService {
  private map = new Map<string, FileElement>();

  constructor(private http: HttpClient) { }
  getFolderFile(folderId = "") {
    if (folderId === "") {
      return this.http.get(`${environment.coreApiUrl}/api/manage-files`)
    } else {
      return this.http.get(`${environment.coreApiUrl}/api/manage-files?FolderId=${folderId}`)
    }
  }

  add(fileElement: FileElement) {
    // fileElement.id = v4();
    this.map.set(fileElement.id, this.clone(fileElement));
    // return this.http.post(`${environment.coreApiUrl}/api/manage-files/upload-file`, fileElement)
    // console.log(fileElement);

    return fileElement;
  }

  createFolder(currentFolder: string, newFolder: string) {
    if (currentFolder !== "") {
      currentFolder = currentFolder?.slice(0, -1);
    }
    let data = {
      "currentFolder": currentFolder,
      "newFolder": newFolder
    }
    return this.http.post(`${environment.coreApiUrl}/api/manage-files/upload-folder`, data)
  }

  uploadFile(currentPath, base64, fileName) {
    if (currentPath !== "") {
      currentPath = currentPath?.slice(0, -1);
    }
    let data = {
      "folderPath": currentPath,
      "base64": base64,
      "fileName": fileName
    }
    return this.http.post(`${environment.coreApiUrl}/api/manage-files/upload-file`, data)
    // return fileElement;
  }

  delete(id: string) {
    this.map.delete(id);
    return this.http.post(`${environment.coreApiUrl}/api/manage-files/delete`, {id: id})
  }

  update(id: string, update: Partial<FileElement>, isFolder=true) {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
    if(isFolder) {
      return this.http.post(`${environment.coreApiUrl}/api/manage-files/rename-folder`, {newFolderName: update.name, id: id})
    } else {
      return this.http.post(`${environment.coreApiUrl}/api/manage-files/rename-file`, {newName: update.name, id: id})

    }
    

  }

  private querySubject: BehaviorSubject<FileElement[]>;
  queryInFolder(folderId: string) {
    const result: FileElement[] = [];
    this.map.forEach(element => {
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  get(id: string) {
    return this.map.get(id);
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }
}
