<div fxLayout="column">
    <div class="div-panel-title-dialog" fxFlex="40px">Lịch sử thao tác</div>
    <div fxFlex >
        <span *ngIf="totalRecord === 0">Không tìm thấy bản ghi</span>
        <table mat-table [dataSource]="dataSource" class="table-el" *ngIf="totalRecord > 0">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef  style="width: 5%;">STT</th>
                <td mat-cell *matCellDef="let item; let i = index">{{(pageSize * pageIndex) + i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Thời gian</th>
                <td mat-cell *matCellDef="let item">{{ item.time }} <br> {{item.date}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Hành động</th>
                <td mat-cell *matCellDef="let item">{{ item.action }}</td>
            </ng-container>
            <ng-container matColumnDef="ip">
                <th mat-header-cell *matHeaderCellDef  style="width: 10%;">IP</th>
                <td mat-cell *matCellDef="let item">{{ item.ip }}</td>
            </ng-container>
            <ng-container matColumnDef="url" >
                <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Url</th>
                <td mat-cell *matCellDef="let item">{{ item.url }}</td>
            </ng-container>
            <ng-container matColumnDef="user_agent">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;">User-Agent</th>
                <td mat-cell *matCellDef="let item">{{item.userAgent}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
        </table>
        <mat-paginator  appStylePaginator class="table-paginate-global" [length]="totalRecord" *ngIf="totalRecord > 15" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onChangePaginate($event)" ></mat-paginator>
    </div>
</div>
