<h2 mat-dialog-title class="text-center" *ngIf="hideButtonAdd">Thêm module</h2>
<h2 mat-dialog-title class="text-center" *ngIf="!hideButtonAdd">Chỉnh sửa module</h2>
<form [formGroup]="formTenantModule" class="form" (ngSubmit)="onClickSubmit(formTenantModule.value);">
    <mat-dialog-content class="mat-typography">
        <div class="row row-global">
            <div class="form-group col-md-12 row row-global">
                <label class="col-md-2 lb-global">Tỉnh/thành phố</label>
                <div class="col-md-10 pl-0 pr-0">
                    <select class="form-control input-field-global" formControlName="module_id">
                        <option value="" [selected]="true">--Chọn module--</option>
                        <option value="HN">Bài học</option>
                        <option value="DN">Thi</option>
                        <option value="KH">Sách tham khảo</option>
                    </select>
                    <mat-error *ngFor="let validation of tenant_validation_messages.module_id">
                        <p class="error-message" *ngIf="formTenantModule.get('module_id').hasError(validation.type) && (formTenantModule.get('module_id').dirty || formTenantModule.get('module_id').touched)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-md-12 row row-global">
                <label class="col-md-2 lb-global">Trạng thái</label>
                <div class="col-md-10 pl-0 pr-0">
                    <mat-checkbox formControlName="status" *ngIf="hideButtonAdd" [checked]="true" class="checkbox-status"><span>Kích hoạt</span></mat-checkbox>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button>Cancel</button>
        <button mat-button type="submit" mat-raised-button color="primary" *ngIf="hideButtonAdd" [disabled]="!formTenantModule.valid">Add</button>
        <button mat-button type="submit" mat-raised-button color="primary" *ngIf="!hideButtonAdd" [disabled]="!formTenantModule.valid">Update</button>
    </mat-dialog-actions>
</form>