import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {ListenFirebaseService, ShowMessage, SubjectService} from '@app/_services';
import {Observable, Subscriber} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {SUBJECT_PERMISSION} from '@app/_modules/subjects/constant';
@Component({
  selector: 'app-subject-list-grade',
  templateUrl: './subject-list-grade.component.html',
  styleUrls: ['./subject-list-grade.component.scss']
})
export class SubjectListGradeComponent implements OnInit, OnChanges {
  selectGrade = [
    {id: 1, fullName: 'Mầm Non'},
    {id: 2, fullName: 'Tiểu học'},
    {id: 3, fullName: 'Trung học cơ sở'},
    {id: 4, fullName: 'Trung học phổ thông'},
    {id: 99, fullName: 'Khác'},
  ];
  @Input() itemId;
  @Input() itemName;
  @Input() onChange;
  @Input() keyword;
  dataColumn = ['stt', 'grade', 'code', 'level'];
  dataSource: any;
  length: number;
  isLoading = false;
  permissionSubject = SUBJECT_PERMISSION;
  constructor(
      private dialog: MatDialog,
      private subjectService: SubjectService,
      private showMessage: ShowMessage,
      private listenFirebaseService: ListenFirebaseService,
      private permissionsService: NgxPermissionsService) { }

  ngOnInit(): void {
    if (this.permissionsService.getPermission(this.permissionSubject.perm9)){
      this.dataColumn.push('tool');
    }
    this.getListGradesBySubject();
    this.checkFireBaseModule();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.onChange){
      this.getListGradesBySubject(this.keyword);
    }
  }
  getListGradesBySubject(search?: string){
      search = search ? search : '';
      this.subjectService.getListGradeBySubject(this.itemId, search).subscribe((ref: any) => {
        ref.data.forEach(i => {
          i.trainingLevel = this.selectGrade.find(a => a.id === i.trainingLevel).fullName;
        });
        this.dataSource = new MatTableDataSource<any>(ref.data);
        this.length = ref.data.length;
        this.isLoading = false;
      });
  }
  confirmDelete(itemName: string, itemId: string){
    const confirmDelete = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xoá khối áp dụng môn học',
        message: 'Bạn có chắc muốn gỡ khách hàng này không ?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'Hủy',
        data: {
          name: itemName + ' - ' + this.itemName,
          id: itemId
        },
      }
    });
    confirmDelete.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isLoading = true;
        this.subjectService.removeGrade(this.itemId, itemId).subscribe((res: any) => {
          if (res.status === 1){
          }
          else if (res.status === 0){
            this.isLoading = false;
            this.showMessage.success(res.msg);
          }
        }, error => {
          this.isLoading = false;
          this.showMessage.success(error);
        });
        setTimeout(() => {
          if (this.isLoading){
            this.getListGradesBySubject();
          }
        }, 10000);
        this.checkFireBaseModule();
      }
    });
  }

  checkFireBaseModule(){
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('delete_grade_subject', 'subject_grade', subscriber);
    });
    listenFb.subscribe((refg) => {
      if (refg === true) {
          this.getListGradesBySubject();
      }
    });
  }


}
