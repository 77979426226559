import { Component, OnInit } from '@angular/core';
import {lang} from '../tab-grade.lang';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import { Router } from '@angular/router';

const dataElement = [
  {id: 1, fullname: 'Sở giáo dục Hà Nội', code: 'OMT029', domain: 'SDGhanoi.com.vn', phone: '045677983', email: 'info@omt.edu.vn', status: 1},
  {id: 1, fullname: 'Sở giáo dục Hà Nội', code: 'OMT029', domain: 'SDGhanoi.com.vn', phone: '045677983', email: 'info@omt.edu.vn', status: 0},
  {id: 1, fullname: 'Sở giáo dục Hà Nội', code: 'OMT029', domain: 'SDGhanoi.com.vn', phone: '045677983', email: 'info@omt.edu.vn', status: 0},
  {id: 1, fullname: 'Sở giáo dục Hà Nội', code: 'OMT029', domain: 'SDGhanoi.com.vn', phone: '045677983', email: 'info@omt.edu.vn', status: 0},
  {id: 1, fullname: 'Sở giáo dục Hà Nội', code: 'OMT029', domain: 'SDGhanoi.com.vn', phone: '045677983', email: 'info@omt.edu.vn', status: 1},
  {id: 1, fullname: 'Sở giáo dục Hà Nội', code: 'OMT029', domain: 'SDGhanoi.com.vn', phone: '045677983', email: 'info@omt.edu.vn', status: 0},

];

@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss']
})
export class ListCustomerComponent implements OnInit {
  lang = lang;
  data = dataElement;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'domain', 'phone', 'email', 'status', 'tools'];
  length = this.data.length;
  constructor(
      private dialog: MatDialog,
      private router:Router
  ) { }

  ngOnInit(): void {
  }

  openDialogDelete(data){
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height:'auto',
      width:'40%',
      data: {
        title:'Bạn đang thực hiện xóa khách hàng khỏi khối 1',
        message:'Xác nhận xóa?',
        btnOkText:'Delete',
        btnCancelText:'Cancel',
        data: {
          name:`${data.fullname} (${data.code})`,
        },
        html:`<img src="https://1.bp.blogspot.com/-nHl_GqUD718/XngOmetGs8I/AAAAAAAAZp4/yj0qV6QnG4Ib_smhD0RN6H3hu1NoafpAgCLcBGAsYHQ/s1600/Avatar-Facebook%2B%25283%2529.jpg" alt="Girl in a jacket">`
      }
    })
  }

  opendetaiTenant(id, tab) {
    alert(1111);
    this.router.navigate([`${'tenants-manager/detail'}/${id}`], { queryParams: { active: tab } });
  }

}
