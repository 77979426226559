<h2 mat-dialog-title class="title-page-global text-center">{{action == 'add' ? 'Thêm phân quyền' : 'Chỉnh sửa phân quyền - ' + role.roleName}}</h2>
<form [formGroup]="roleForm" (ngSubmit)="submit(roleForm.value, roleForm.valid)">
    <mat-dialog-content class="mat-typography">
        <mat-expansion-panel [expanded]="true"  class="panel-content-global mat-elevation-z">
            <mat-expansion-panel-header class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">
                <mat-panel-title class="panel-title">Thông tin phân quyền</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-lg-6 form-group row row-global" >
                    <label class="col-sm-3 lb-global ">Tên phân quyền <span class="lb-error">(*)</span></label>
                    <div class="col-sm-8 pl-0 pr-0">
                        <input type="text" formControlName="name"  class="form-control input-field-global" placeholder="Nhập tên phân quyền" >
                        <div *ngIf="(roleForm.get('name').invalid && (roleForm.get('name').dirty || roleForm.get('name').touched)) ">
                            <mat-error>
                                <p class="error-message" *ngIf="roleForm.get('name').errors.required">
                                    Tên phân quyền không được bỏ trống
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="roleForm.get('name').errors.pattern ||roleForm.get('name').errors.minlength || roleForm.get('name').errors.maxlength">
                                    Tên phân quyền độ dài 2-250 ký tự, a-z, A-Z, 0-9
                                </p>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 form-group row row-global" [style]="action === 'edit' ? 'opacity: 60%' : ''">
                    <label class="offset-1 col-sm-3 lb-global">Mã phân quyền <span class="lb-error">(*)</span></label>
                    <div class="col-sm-8 pl-0 pr-0">
                        <input #valueCode (keyup)="changeTextTest(valueCode.value)" [readOnly]="action === 'edit'" type="text" formControlName="code" class="form-control input-field-global" placeholder="Nhập mã phân quyền" style="background: #F2F3F8;">
                        <div *ngIf="(this.roleForm.get('code').invalid && (this.roleForm.get('code').dirty || this.roleForm.get('code').touched))">
                            <mat-error>
                                <p class="error-message" *ngIf="this.roleForm.get('code').errors.required || this.roleForm.get('code').errors.trimError">
                                    Mã phân quyền không được bỏ trống
                                </p>
                            </mat-error>
                            <mat-error>
                                <p class="error-message" *ngIf="this.roleForm.get('code').errors.pattern">
                                    Mã phân quyền độ dài 2-250 ký tự, a-z, A-Z, 0-9
                                </p>
                            </mat-error>
                        </div>
                        <span class="text-danger pt-2" *ngIf="checkCode !== ''">{{checkCode}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 form-group row row-global">
                    <label class="col-sm-3 lb-global ">Thứ tự sắp xếp</label>
                    <div class="col-sm-8 pl-0 pr-0">
                        <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" formControlName="displayNumber" class="form-control input-field-global">
                    </div>
                </div>
                <div class="col-lg-6 form-group row row-global">
                    <label class="offset-1 col-sm-3 lb-global">Trạng thái</label>
                    <div class="col-sm-8 pl-0 pr-0">
                        <mat-checkbox class="checkbox-form-role" formControlName="status">Kích hoạt</mat-checkbox>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="row">
                <div class="col-lg-12 form-group row row-global">
                    <label class="lb-global" fxFlex="12%">Mô tả</label>
                    <div fxFlex class="form-group pr-0 row-global">
                        <textarea class="form-control input-field-global col-sm-12" formControlName="description"></textarea>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button class="btn-cancel mat-elevation-z">HỦY</button>
        <button mat-button type="submit" class="btn btn-add-edit ml-2 px-4" [disabled]="isLoading"> <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span> {{action == 'add' ? 'THÊM' : 'LƯU'}}</button>
    </mat-dialog-actions>
</form>
<div class="spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
