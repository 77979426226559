<p class="title-page-global-custom" mat-dialog-title>
  Danh sách đơn vị ngành dọc
  <span class="icon-close" [mat-dialog-close]="null"><img src="assets/images/icons/Vector-24.png"></span>
</p>
<hr>
<mat-dialog-content>
  <div class="container-fluid">
    <div class="row input-search-dialog-list mt-1">
      <div class="col-sm-6 pl-0">
        <input #searchDialog (keydown)="searchDialogListUnit($event, searchDialog.value)"
          id="search-value-dialog-select-unit" type="text" class="form-control input-field-global"
          placeholder="Nhập tên đơn vị" autocomplete="off"
          (blur)="searchDialogListUnit($event, searchDialog.value)">
        <img src="assets/images/icons/icon-23.svg">
        <div class="result-search {{ !checkResultSearch ? '' : 'active' }}">
          <div class="item" *ngFor="let item of resultSearch" (click)="selectUnit(item)">
            <h4>{{ item.name }}</h4>
            <p>{{ item.code }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <select #searchFilterSoDialog class="form-control input-field-global"
          (change)="changeStatusSo(searchFilterSoDialog.value)">
          <option value="" selected>Tất cả các sở</option>
          <option value="{{item.code}}" *ngFor="let item of dataEducation1">{{ item.name }}</option>
        </select>
      </div>
      <div class="col-sm-3 pr-0">
        <select #searchFilterPhongDialog [disabled]="dataPhong !== undefined ? false : true "
          class="form-control input-field-global" (change)="changeStatusPhong(searchFilterPhongDialog.value)">
          <option value="" selected>Tất cả các phòng</option>
          <option value="{{item.code}}" *ngFor="let item of dataPhong">{{ item.name }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div fxFlex class="content-declare">
        <div class="items">
          <div class="item item-lv-1">
            <div class="title">Sở giáo dục</div>
            <div class="list-all" *ngIf="dataEducation1">
              <div class="grades" *ngFor="let item of dataEducation1">
                <div class="grade {{ code_active == item.code ? 'active' : '' }}"
                  (click)="activeLv1(item.code, item.name, item.id);">
                  <div class="grade-left">
                    <h4><a href="javascript:;">{{ item.name }}</a><span>({{ item.code }})</span></h4>
                    <p>
                      <span>Phòng GD:{{ item.countPhongGD }}</span>
                      <span>Trường THPT:{{ item.countTHPT }}</span>
                    </p>
                  </div>
                </div>
                <span class="line {{ check_child_lv_1 && code_active == item.code ? 'active' : '' }}"></span>
              </div>
            </div>
          </div>
          <div class="item item-lv-2">
            <div class="title">Phòng GD, Trường THPT</div>
            <div class="list-all" *ngIf="check_child_lv_1">
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <!-- children -->
                <!-- class="custom-mat-tree-node" -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <div class="tree-2">
                    <div class="line-tree-2-before {{ code_active == node.parent_code ? 'active' : '' }}"></div>
                    <li class="custom-mat-tree-node">
                      <!-- use a disabled button to provide padding for tree leaf -->
                      <button mat-icon-button disabled></button>
                      <div class="grade-tree {{ code_active_lv_2 == node.code ? 'active' : '' }}"
                        (click)="activeLv2(node.code, node.name, node.id);">
                        <div class="grade-left">
                          <h4><a href="javascript:;">{{node.name}}</a><span>({{node.code}})</span></h4>
                          <p *ngIf="node.unit <= 2">
                            <span>Trường mầm non:{{ node.countMamNon }}</span>
                            <span>Trường tiểu học:{{ node.countTH }}</span>
                            <span>Trường THCS:{{ node.countTHCS }}</span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <div
                      class="line-tree-2-after-lv2 {{ check_child_lv_2 && code_active_lv_2 == node.code ? 'active' : '' }}">
                    </div>
                  </div>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <!-- parent -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                  <li>
                    <div class="tree-2 tree-level-2">
                      <!-- <div class="line-tree-2-before {{ code_active == node.parent_code ? 'active' : '' }}"></div> -->
                      <div class="line-tree-box">
                        <div class="line-tree-box-1 {{ code_active == node.parent_code ? 'active' : '' }}"></div>
                        <div
                          class="line-tree-box-2 line-tree-2-before {{ code_active == node.parent_code ? 'active' : '' }}">
                        </div>
                      </div>
                      <div class="custom-mat-tree-node {{ code_active == node.parent_code ? 'active' : '' }}"
                        style="margin-right: 30px;">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                          (click)="closeTree(node.parent_code);">
                          <mat-icon>
                            {{close_lv_2 && code_active == node.parent_code ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        <div class="grade-tree">
                          <div class="grade-left">
                            <h4>{{node.name}}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul class="{{ close_lv_2 && code_active == node.parent_code ? '' : 'example-tree-invisible' }}">
                      <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                  </li>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
          </div>
          <div class="item item-lv-3">
            <div class="title">Trường THCS, Trưởng Tiểu Học, Trường Mầm Non</div>
            <div class="list-all" *ngIf="check_child_lv_1 && check_child_lv_2">
              <mat-tree [dataSource]="dataSource2" [treeControl]="treeControl2" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <!-- children -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <div class="tree-2 tree-lv-3">
                    <div class="line-tree-2-before"></div>
                    <li class="custom-mat-tree-node">
                      <!-- use a disabled button to provide padding for tree leaf -->
                      <button mat-icon-button disabled></button>
                      <div class="grade-tree" [class]="code_active_lv_3 === node.id ? 'active' : ''"
                        (click)="activeLv3(node.id, node.name);">
                        <div class="grade-left">
                          <h4><a href="javascript:;">{{node.name}}</a><span></span></h4>
                        </div>
                      </div>
                    </li>
                    <div class="line-tree-2-after"></div>
                  </div>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <!-- parent -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild2" class="child-lv3">
                  <li>
                    <div class="tree-2 tree-lv-3">
                      <!-- <div class="line-tree-2-before {{ code_active_lv_2 == node.parent_code ? 'active' : '' }}"></div> -->
                      <div class="line-tree-box">
                        <div class="line-tree-box-1 {{ code_active_lv_2 == node.parent_code ? 'active' : '' }}"></div>
                        <div
                          class="line-tree-box-2 line-tree-2-before {{ code_active_lv_2 == node.parent_code ? 'active' : '' }}">
                        </div>
                      </div>
                      <div class="custom-mat-tree-node {{ code_active_lv_2 == node.parent_code ? 'active' : '' }}"
                        style="margin-right: 30px;">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                          (click)="closeTree2(node.parent_code,node.parent_code_lv1);">
                          <mat-icon>
                            {{close_lv_3 && code_active_lv_2 == node.parent_code ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        <div class="grade-tree">
                          <div class="grade-left">
                            <h4>{{node.name}}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul
                      class="{{ close_lv_3 && code_active_lv_2 == node.parent_code ? '' : 'example-tree-invisible' }}">
                      <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                  </li>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="btn-add-global mat-elevation-z" mat-raised-button mat-dialog-close>Đóng</button>
  <button class="btn-add-global mat-elevation-z" mat-raised-button (click)="confirm()">Xác nhận</button>
</mat-dialog-actions>

<div class="show-loading" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>