import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import { Router } from "@angular/router";
import { DATA_FULL_PERMISSION, DATA_FULL_PERMISSION_MODULE } from '@app/_shared/utils/constant';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public currentModulePermission: Observable<any>;
    public currentElementPermission: Observable<any>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private cookieService: CookieService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        let currentUserModule = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('permissionsModule')));
        this.currentModulePermission = currentUserModule.asObservable();

        let currentUserElement = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('permissionsElement')));
        this.currentElementPermission = currentUserElement.asObservable();
        // let currentUserModule = new BehaviorSubject<User>(DATA_FULL_PERMISSION);
        // this.currentModulePermission = currentUserModule.asObservable();
        //
        // let currentUserElement  = new BehaviorSubject<User>(DATA_FULL_PERMISSION);
        // this.currentElementPermission = currentUserElement.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, isSave = false) {
        var domainName = `${window.location.hostname}:${window.location.port}`;
        domainName = `${window.location.hostname}`;
        var port = `${window.location.port}`;
        if (port == '80' || port == '443') {
            domainName = `${window.location.hostname}`;
        }
        let domain = domainName;
        // const domain = "beta.elearn.vn";
        return this.http.post<any>(`${environment.authApiUrl}/api/authentication/login`, { username, password, domain })
            .pipe(
                map((user) => {
                    if (user.status === 1) {
                        if (user.data.userDataAccess.moduleCodeArr[0] === 'SuperAdmin') {
                            localStorage.setItem('permissionsModule', JSON.stringify(DATA_FULL_PERMISSION_MODULE));
                            localStorage.setItem('permissionsElement', JSON.stringify(DATA_FULL_PERMISSION));
                            let currentUserModule = new BehaviorSubject<any>(DATA_FULL_PERMISSION_MODULE);
                            this.currentModulePermission = currentUserModule.asObservable();
                            let currentUserElement = new BehaviorSubject<any>(DATA_FULL_PERMISSION);
                            this.currentElementPermission = currentUserElement.asObservable();
                        }
                        else {
                            localStorage.setItem('permissionsModule', JSON.stringify(user.data.userDataAccess.moduleCodeArr));
                            localStorage.setItem('permissionsElement', JSON.stringify(user.data.userDataAccess.permissionCodeArr));
                            let currentUserModule = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('permissionsModule')));
                            this.currentModulePermission = currentUserModule.asObservable();
                            let currentUserElement = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('permissionsElement')));
                            this.currentElementPermission = currentUserElement.asObservable();
                        }
                        // @ts-ignore
                        // const decodedPermission = jwt_decode('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.WyJtYW5hZ2VyOnRlbmFudCIsInZpZXc6dGVuYW50Il0.0m3B9I4SgKFrm4k40dLFb_Rli2kl5AAnjVDme5PjV_Y', { payload: true });
                        localStorage.setItem('currentUser', JSON.stringify(user.data.token));
                        localStorage.setItem('userId', JSON.stringify(user.data.userId));
                        localStorage.setItem('userName', JSON.stringify(username));
                        localStorage.setItem('user', JSON.stringify(user.data.userProfile));
                        this.currentUserSubject.next(user.data.token);
                        // save info login in cookie
                        if (isSave) {
                            this.cookieService.set('dataLogin', JSON.stringify({ username, password, domain }));
                        } else {
                            this.cookieService.delete('dataLogin');
                        }
                    }
                    return user;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                }));
    }

    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        // this.currentModulePermission.next(null);
        // this.currentElementPermission.next(null);
        localStorage.clear();
        this.router.navigate(['/login']);
    }

}
