import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { REGEX_CODE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-dialog-form-tenant-config',
  templateUrl: './dialog-form-tenant-config.component.html',
  styleUrls: ['./dialog-form-tenant-config.component.scss']
})
export class DialogFormTenantConfigComponent implements OnInit {
  public createTenant: FormGroup;

  public id:any;
  public tenant_name:number;
  public tenant_code:number;
  public hotline:string;
  public domain:string;
  public email:string;
  public account_manager:string;
  public password:string;
  public contact_name:string;
  public contactEmail:string;
  public phone:string;
  public address_lv1:string;
  public address_lv2:string;
  public address_lv3:string;
  public address:string;
  public order:number;
  public status:number;

  hideButtonAdd:boolean;

  panelOpenState = true;
  constructor(private fb: FormBuilder,
    private dialogref: MatDialogRef<DialogFormTenantConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }
  
  ngOnInit(): void {
    this.data?.status == "update" ? this.hideButtonAdd = false : this.hideButtonAdd = true;
    this.createTenant = this.fb.group({
      id: [this.data?.tenant?.id ? this.data.tenant.id : this.id],
      tenant_name: [this.data?.tenant?.tenant_name ? this.data.tenant.tenant_name : this.tenant_name,[Validators.required]],
      tenant_code: [this.data?.tenant?.tenant_code ? this.data.tenant.tenant_code : this.tenant_code,[Validators.required,Validators.pattern(REGEX_CODE)]],
      hotline:  [this.data?.tenant?.hotline ? this.data.tenant.hotline : this.hotline,[Validators.required]],
      domain:  [this.data?.tenant?.domain ? this.data.tenant.domain : this.domain,[Validators.required]],
      email:  [this.data?.tenant?.email ? this.data.tenant.email : this.email,[Validators.required,Validators.email]],
      account_manager:  [this.data?.tenant?.account_manager ? this.data.tenant.account_manager : this.account_manager,[Validators.required]],
      password:  [this.data?.tenant?.password ? this.data.tenant.password : this.password,[Validators.required]],
      contact_name:  [this.data?.tenant?.contact_name ? this.data.tenant.contact_name : this.contact_name,[Validators.required]],
      contactEmail:  [this.data?.tenant?.contactEmail ? this.data.tenant.contactEmail : this.contactEmail,[Validators.required,Validators.email]],
      phone:  [this.data?.tenant?.phone ? this.data.tenant.phone : this.phone,[Validators.required]],
      address_lv1:  [this.data?.tenant?.address_lv1 ? this.data.tenant.address_lv1 : this.address_lv1,[Validators.required]],
      address_lv2:  [this.data?.tenant?.address_lv2 ? this.data.tenant.address_lv2 : this.address_lv2,[Validators.required]],
      address_lv3:  [this.data?.tenant?.address_lv3 ? this.data.tenant.address_lv3 : this.address_lv3,[Validators.required]],
      address:  [this.data?.tenant?.address ? this.data.tenant.address : this.address,[Validators.required]],
      order:  [this.data?.tenant?.order ? this.data.tenant.order : this.order,[Validators.required]],
      status:  [this.data?.tenant?.status ? this.data.tenant.status : this.status]
    });
  }

  onClickSubmit(value):void{

  }

  // validate
  tenant_validation_messages = {
    'tenant_name': [
      { type: 'required', message: 'chưa nhập tên tenant' }
    ],
    'tenant_code': [
      { type: 'required', message: 'chưa nhập mã tenant' },
      { type: 'pattern', message: 'mã tenant phải từ 5 đến 15 ký tự' }
    ],
    'hotline': [
      { type: 'required', message: 'chưa nhập hotline' }
    ],
    'domain': [
      { type: 'required', message: 'chưa nhập domain' }
    ],
    'email': [
      { type: 'required', message: 'chưa nhập email' },
      { type: 'email', message: 'email chưa đúng định dạng' }
    ],
    'account_manager': [
      { type: 'required', message: 'chưa nhập tên đăng nhập' }
    ],
    'password': [
      { type: 'required', message: 'chưa nhập mật khẩu' }
    ],
    'contact_name': [
      { type: 'required', message: 'chưa nhập tên liên hệ' }
    ],
    'contactEmail': [
      { type: 'required', message: 'chưa nhập email liên hệ' },
      { type: 'email', message: 'contactEmail chưa đúng định dạng' }
    ],
    'phone': [
      { type: 'required', message: 'chưa nhập số điện thoại' }
    ],
    'address_lv1': [
      { type: 'required', message: 'chưa chọn tỉnh/thành phố' }
    ],
    'address_lv2': [
      { type: 'required', message: 'chưa chọn quận/huyện' }
    ],
    'address_lv3': [
      { type: 'required', message: 'chưa chọn phường xã' }
    ],
    'address': [
      { type: 'required', message: 'chưa nhập địa chỉ' }
    ],
    'order': [
      { type: 'required', message: 'chưa nhập thứ tự' }
    ]
  }

}
