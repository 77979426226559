<form [formGroup]="dataEducation" class="form" (ngSubmit)="onClickSubmit(dataEducation.value);" enctype="multipart/form-data">
    <h2 mat-dialog-title class="text-center" *ngIf="hideButtonAdd">Thêm mới Phường/Xã</h2>
    <h2 mat-dialog-title class="text-center" *ngIf="!hideButtonAdd">Chỉnh sửa thông tin - {{ data.education.fullname }}</h2>
    <mat-dialog-content class="mat-typography">
        <div class="title-dialog-center-global">
            <div class="avatar-custom">
                <div class="img-module" (click)="uploadImage()">
                    <img [src]="urlImage ? urlImage : 'https://increasify.com.au/wp-content/uploads/2016/08/default-image.png'" class="avatar">
                    <div class="box-image">
                        <label><mat-icon >photo library</mat-icon>
                            <span>Thay đổi ảnh </span></label>
                    </div>
                    <input type="file" class="avatar-file" name="avatar" style="display: none;" id="inputUploadFile" (change)="onFileChange($event)">
                </div>

            </div>
        </div>
        <!-- <form> -->
        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Tên Phường/Xã</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input formControlName="fullname" type="text" class="form-control input-field-global" placeholder="Nhập tên phường/xã">
                    <mat-error *ngFor="let validation of education_validation_messages.fullname">
                        <p class="error-message" *ngIf="dataEducation.get('fullname').hasError(validation.type) && (dataEducation.get('fullname').dirty || dataEducation.get('fullname').touched)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1">Mã</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input formControlName="code" [readOnly]="!hideButtonAdd" type="text" class="form-control input-field-global" placeholder="Nhập mã">
                    <mat-error *ngFor="let validation of education_validation_messages.code">
                        <p class="error-message" *ngIf="dataEducation.get('code').hasError(validation.type) && (dataEducation.get('code').dirty || dataEducation.get('code').touched)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
        </div>


        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Trực thuộc Tỉnh/Thành phố</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input formControlName="parent" [readOnly]="true" type="text" class="form-control input-field-global" placeholder="Nhập tên tỉnh/thành phố">
                    <mat-error *ngFor="let validation of education_validation_messages.parent">
                        <p class="error-message" *ngIf="dataEducation.get('parent').hasError(validation.type) && (dataEducation.get('parent').dirty || dataEducation.get('parent').touched)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1">Trực thuộc Quận/Huyện</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input formControlName="parent2" [readOnly]="true" type="text" class="form-control input-field-global" placeholder="Nhập tên quận/huyện">
                    <mat-error *ngFor="let validation of education_validation_messages.parent2">
                        <p class="error-message" *ngIf="dataEducation.get('parent2').hasError(validation.type) && (dataEducation.get('parent2').dirty || dataEducation.get('parent2').touched)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row custom-space-right">
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3">Thứ tự sắp xếp</label>
                <div class="col-md-8 pl-0 pr-0">
                    <input formControlName="order" onlyNumber (keypress)="numberOnly($event)" type="number" min="1" class="form-control input-field-global" placeholder="Nhập thứ tự sắp xếp">
                    <mat-error *ngFor="let validation of education_validation_messages.order">
                        <p class="error-message" *ngIf="dataEducation.get('order').hasError(validation.type) && (dataEducation.get('order').dirty || dataEducation.get('order').touched)">{{validation.message}}</p>
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-md-6 row row-global">
                <label class="col-md-3 offset-1">Trạng thái</label>
                <div class="col-md-8 pl-0 pr-0">
                    <mat-checkbox formControlName="status">Kích hoạt</mat-checkbox>
                </div>
            </div>
        </div>
        <!-- </form> -->
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button>Cancel</button>
        <button mat-button type="submit" mat-raised-button color="primary" *ngIf="hideButtonAdd" [disabled]="!dataEducation.valid">Add</button>
        <button mat-button type="submit" mat-raised-button color="primary" *ngIf="!hideButtonAdd" [disabled]="!dataEducation.valid">Update</button>
    </mat-dialog-actions>
</form>