import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/_shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NewCustomerListComponent } from './components/new-customer-list/new-customer-list.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ChartComponent } from './components/chart/chart.component';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DashboardComponent, NewCustomerListComponent, ChartComponent, NotificationComponent ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DashboardModule { }
