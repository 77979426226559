import { NestedTreeControl } from '@angular/cdk/tree';
import { Component,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogFormEducationLv3Component } from '../../dialog/dialog-form-education-lv3/dialog-form-education-lv3.component';
import { ActivatedRoute } from '@angular/router';
import { IndustryUnitService } from '@app/_services/industry-unit.service';
import { ShowMessage } from '@app/_services/show-message.service';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {ListenFirebaseService} from '@app/_services';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import {ADMIN_MOET_UNIT_PERMISSION} from '../../constants';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';

@Component({
  selector: 'app-detail-education-lv3',
  templateUrl: './detail-education-lv3.component.html',
  styleUrls: ['./detail-education-lv3.component.scss']
})
export class DetailEducationLv3Component implements OnInit {
  constructor(
    public dialog: MatDialog,
    private activatedRoute:ActivatedRoute,
    private industryUnitService:IndustryUnitService,
    private showMessage:ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService
  ){
  }

  ngOnInit(){
    this.getDataDeatilEducation();
  }

  showSearch: boolean = false;
  dataSearch: string = '';
  idEducation3:any;
  dataDetailEducation3:any;
  isLoading:boolean = false;
  typeModule = 'moet_unit';
  ADMIN_MOET_UNIT_PERMISSION= ADMIN_MOET_UNIT_PERMISSION;

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.getDataDeatilEducation();
        this.isLoading = false;
      }
      this.isLoading = false;
    });
  }

  getDataDeatilEducation(){
    this.isLoading = true;
    //get param
    this.idEducation3 = this.activatedRoute.snapshot.params.id;
    this.industryUnitService.getDetailEducation(this.idEducation3).subscribe(
      (res:any)=>{
        this.dataDetailEducation3 = res.data;
        this.dataDetailEducation3.status = Number(this.dataDetailEducation3.status);
        this.isLoading = false;
      },
      (err)=>{
        this.isLoading = false;
      }
    );
  }

  toggleClass(value){
    this.dataSearch = value;
    this.showSearch = !this.showSearch;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clickEditEducationLv3(data:any){
    const dialogRef = this.dialog.open(DialogFormEducationLv3Component,{
      height:'auto',
      width:'80%',
      data: {
        status:"update",
        education:data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
        this.checkFireBase('update');
      } 
      setTimeout(() => {
        if(this.isLoading == true){
          this.isLoading = false;
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    });
  }
}

