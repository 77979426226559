export const ROLES_PERMISSION = {
    perm1: 'core_admin_roles',
    perm2: 'core_admin_role_add',
    perm3: 'core_admin_role_detail',
    perm4: 'core_admin_role_edit',
    perm5: 'core_admin_role_delete',
    perm6: 'core_admin_role_users',
    perm7: 'core_admin_role_user_add',
    perm8: 'core_admin_role_user_delete',
    perm9: 'core_admin_role_permissions',
    perm10: 'core_admin_role_permission_update',
};
