import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogRoleListUsersComponent } from '@app/_modules/roles-manager/dialogs/dialog-role-list-users/dialog-role-list-users.component';
import { DialogRoleFormAddEditComponent } from '@app/_modules/roles-manager/dialogs/dialog-role-form-add-edit/dialog-role-form-add-edit.component';
import { DialogRoleAddUserComponent } from '@app/_modules/roles-manager/dialogs/dialog-role-add-user/dialog-role-add-user.component';
import {ListenFirebaseService, RoleManagerService, ShowMessage} from '@app/_services';
import { Role } from '@app/_models/role';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { DialogChangeActiveComponent } from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import { DialogRoleListPermissionsComponent } from '@app/_modules/roles-manager/dialogs/dialog-role-list-permissions/dialog-role-list-permissions.component';
import { Router } from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import {Subscriber} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import * as $ from 'jquery';
import {DialogNotificationComponent} from '@app/_shared/dialogs/notification/notification.component';
import { ROLES_PERMISSION } from '@app/_modules/roles-manager/constant';
import {NgxPermissionsService} from 'ngx-permissions';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';

@Component({
  selector: 'app-role-manager-list',
  templateUrl: './role-manager-list.component.html',
  styleUrls: ['./role-manager-list.component.scss']
})
export class RoleManagerListComponent implements OnInit{
  permissionRole = ROLES_PERMISSION;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'count_permission', 'user_count', 'is_active'];
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  public checked = false;
  status: number;
  showSearch: boolean = true;
  showFilter: boolean = false;
  dataSearch: string = '';
  dataStatus: string = '';
  check = false;
  dataSource: any;
  checkActiveStatusRole = '';
  statusSearchRole = [];
  isLoading:boolean = false;
  txtSearchRole:string = '';
  typeModule = 'role';
  disabled = false;

  constructor(
      private dialogRes: MatDialog,
      private rolesManagerService: RoleManagerService,
      private router: Router,
      private showMessage: ShowMessage,
      public db: AngularFireDatabase,
      private listenFirebaseService: ListenFirebaseService,
      private permissionsService: NgxPermissionsService) { }

  ngOnInit() {
    if (this.permissionsService.getPermission(this.permissionRole.perm4) || this.permissionsService.getPermission(this.permissionRole.perm5) || this.permissionsService.getPermission(this.permissionRole.perm3)){
      this.displayedColumns.push('tool');
    }
    this.listRole();
    $("#leader-line-defs").remove();
    this.checkFireBase('change_status');
  }

  showFilterClick(){
    this.showFilter = !this.showFilter;
    this.dataStatus = '';
    if (this.showFilter === false){
      if (this.dataStatus !== ''){
        this.isLoading = true;
        this.checkActiveStatusRole = '';
        this.dataStatus = '';
        setTimeout(() => {
          this.listRole(this.dataSearch, this.dataStatus);
        }, 1000);
      }
    }
  }

  toggleClass() {
    this.showSearch = !this.showSearch;
    (document.getElementById('search-value') as HTMLInputElement).value = '';
    if (this.showSearch === false){
      if (this.dataSearch !== ''){
        this.dataSearch = '';
        this.isLoading = true;
        setTimeout(() => {
          this.listRole(this.dataSearch, this.dataStatus);
        }, 1000);
      }
    }
  }

  checkText(event, value) {
    if (this.showSearch === true) {
      if (event.keyCode === 13 || event.keyCode === 9) {
        this.isLoading = true;
        this.dataSearch = value;
        setTimeout(() => {
          this.listRole(this.dataSearch, this.dataStatus);
        }, 1000);
      }
    }
  }

  itemOptionClickRoleNotActive(event, status){
    if (event.srcElement.classList.contains('active')){
      event.srcElement.classList.remove('active');
      this.statusSearchRole = [];
      this.dataStatus = '';
    }else{
      this.dataStatus = status;
      this.checkActiveStatusRole = 'not_active';
      this.statusSearchRole = [];
      this.statusSearchRole.push(status);
      event.srcElement.classList.add('active');
    }
  }

  itemOptionClickRoleActive(event, status){
    if(event.srcElement.classList.contains('active')){
      this.dataStatus = '';
      event.srcElement.classList.remove('active');
      this.statusSearchRole = [];
    }else{
      this.dataStatus = status;
      this.checkActiveStatusRole = 'active';
      this.statusSearchRole = [];
      this.statusSearchRole.push(status);
      event.srcElement.classList.add('active');
    }

  }

  clickFilterRole(){
    this.isLoading = true;
    setTimeout(() => {
     this.listRole(this.dataSearch, this.dataStatus);
    }, 1000);

  }

  listRole(keyWord?: string, status?: string){
    this.rolesManagerService.getDataRole(this.pageSize, this.pageIndex, keyWord, status).subscribe((ref: any) => {
      this.dataSource = ref.data.data;
      this.totalRecord = ref.data.totalItems;
      this.isLoading = false;
    });
  }

  onChangePaginate(event){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.listRole();
  }

  confirmDelete(itemName: string, id: string) {
    const confirmDelete = this.dialogRes.open(DialogDeleteComponent, {
      height: 'auto',
      width: '30%',
      data: {
        title: 'Bạn đang thực hiện xóa phân quyền',
        message: 'Bạn có chắc muốn xóa dữ liệu này không ?',
        btnOkText: 'XÓA',
        btnCancelText: 'HỦY',
        data: {
          name: itemName
        }
      }
    });
    confirmDelete.afterClosed().subscribe((res: any) => {
      if (res){
        this.checkFireBase('delete');
        this.isLoading = true;
        this.rolesManagerService.deleteRole(id).subscribe((ref: any) => {
          if (ref.status === 1) {}
          else if (ref.status === 0) {
                this.isLoading = false;
                this.showMessage.error(ref.msg);
              }
            },
            (error: any) => {
              this.isLoading = false;
            }
            );
        setTimeout (() => {
          if (this.isLoading){
            this.isLoading = false;
            this.listRole();
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }
    });
  }

  changeStatusRole(test: any, item: any){
    let titleLabel = test.checked ? 'Bạn đã thực hiện kích hoạt phân quyền' : 'Bạn đang thực hiện xóa phân quyền';
    const confirmActive = this.dialogRes.open(DialogChangeActiveComponent, {
      height: 'auto',
      width: '36%',
      data: {
        btnCancelText: 'HỦY',
        btnOkText: 'XÁC NHẬN',
        status: test.checked,
        content: 'Bạn có chắc muốn thực hiện thay đổi này không ?',
        title: titleLabel,
        data: {
          name: item.roleName,
        }
      }
    });
    confirmActive.afterClosed().subscribe((res: any) => {
      test.source._checked = res ? test.checked : !test.checked;
      if (res){
        this.checkFireBase('change_status');
        this.isLoading = true;
        const status = test.source._checked ? 1 : 0;
        this.rolesManagerService.changeStatusRole(item.id, status).subscribe((ref: any) => {
              if (res.status === 1) {}
              else {
                this.isLoading = false;
              }
            },
            (error: any) => {
              this.isLoading = false;
            });
        setTimeout (() => {
          if (this.isLoading){
            this.isLoading = false;
            this.listRole();
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }

    });
  }

  showListUser(id: string, name: string){
    const listUser = this.dialogRes.open(DialogRoleListUsersComponent, {
      width: '50%',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        roleId: id,
        roleName: name,
      },
    });
  }

  showListPermission(id: string, name: string){
    const listPermission = this.dialogRes.open(DialogRoleListPermissionsComponent, {
      width: '80%',
      autoFocus: false,
      data: {
        roleId: id,
        roleName: name,
      },
    });
  }

  showFormRole(status: string, role?: any){
    if (status === 'add'){
        this.disabled = true;
        const dialog = this.dialogRes.open(DialogRoleFormAddEditComponent, {
          width: '65%',
          height: 'auto',
          panelClass: 'custom-modalbox',
          autoFocus: false,
          data: {
            action: status,
            item: role,
          }
        });
        dialog.afterClosed().subscribe(res => {
            this.disabled = false;
            this.checkFireBase('create');
        });
        setTimeout (() => {
        if (this.isLoading){
          this.isLoading = false;
          this.listRole();
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    }
    else{
      this.rolesManagerService.getRoleById(role.id).subscribe((ref: any) => {
        if (ref.status === 1){
          this.disabled = true;
          const dialog = this.dialogRes.open(DialogRoleFormAddEditComponent, {
            width: '65%',
            height: 'auto',
            panelClass: 'custom-modalbox',
            autoFocus: false,
            data: {
              action: status,
              item: role,
            }
          });
          dialog.afterClosed().subscribe(res => {
            this.disabled = false;
            this.checkFireBase('update');
          });
        }
        },
          (error: any) => {
      });
      setTimeout(() => {
        if (this.isLoading){
          this.isLoading = false;
          this.listRole();
        }
      }, TIME_OUT_LISTEN_FIREBASE);
    }
  }

  opendetaiRole(id, tab){
    this.router.navigate([`${'roles-manager/detail'}/${id}`], { queryParams: {active: tab}});
  }

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.listRole();
      }
      else{
        this.isLoading = false;
        this.listRole();
      }
    });
  }
}
