<p class="text-center custom-title-message">Bạn đang thực hiện cập nhật trạng thái người dùng</p>
<p class="text-center custom-show-name">{{data.infoUser.fullname}} ({{data.infoUser.code}})</p>
<div fxLayout="row" fxLayoutAlign="center center">
    <img id="avatar" src="assets/images/png/avatar.png" alt="">
</div>
<br>
<p class="text-center">
    <span class="custom-old-status">{{statusUser[data.infoUser.status].name}}</span>
    &nbsp;&nbsp;
    <img src="assets/images/svg/icon-left.svg" alt="">
    &nbsp;&nbsp;
    <span class="custom-new-status">{{data.newStatus.name}}</span>
</p>
<p class="custom-message text-center">Bạn có chắc muốn cập nhật dữ liệu này không ?</p>
<p class="text-center">
    <button mat-dialog-close class="btn btn-close-custom">Hủy</button>&nbsp;&nbsp;
    <button class="btn btn-add" (click)="confirmStatus()">Xác nhập</button>
</p>
