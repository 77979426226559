import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Grade } from '@app/_models/grade';

@Injectable({
  providedIn: 'root'
})
export class AdministrativeUnitService {

  constructor(private http: HttpClient) { }

  getLocationLv1(){
    return this.http.get(`${environment.coreApiUrl}/api/location/get-list-city`);
  }

  getLocationLv2(id:any){
    return this.http.get(`${environment.coreApiUrl}/api/location/get-list-district?id=${id}`);
  }

  getLocationLv3(id:any){
    return this.http.get(`${environment.coreApiUrl}/api/location/get-list-ward?id=${id}`);
  }

  generateCode(prefix:any){
    return this.http.post(`${environment.coreApiUrl}/api/Common/generate-random-key`,{
      "prefix": prefix,
      "lenghtOfAlphabet": 0,
      "lenghtOfUpercase": 0,
      "lenghtOfNumber": 6,
      "lenghtOfSpecial": 0
    });
  }

  storeLocation(data:any){
    return this.http.post(`${environment.coreApiUrl}/api/location/store`,data);
  }

  updateLocation(data:any){
    return this.http.post(`${environment.coreApiUrl}/api/location/update?id=${data.id}`,data);
  }
  
  getDetailLocation(id:any){
    return this.http.get(`${environment.coreApiUrl}/api/location/detail/${id}`);
  }

  deleteLocation(id:any){
    return this.http.post(`${environment.coreApiUrl}/api/location/delete`,{id});
  }

  filterLocation(keyWord:string,type:number,cityId:string,districtId:string){
    return this.http.post(`${environment.coreApiUrl}/api/location/all-location`, {keyWord, type, cityId, districtId});
  }

  moveLocation(data){
    return this.http.post(`${environment.coreApiUrl}/api/location/update-administrative-units`,data);
  }

  importBase64Location(data){
    const headers = new HttpHeaders({'Content-Type':'application/json'});
    return this.http.post(`${environment.coreApiUrl}/api/location/import-base64`,data,{headers});
  }

  importLocation(data){
    return this.http.post(`${environment.coreApiUrl}/api/location/import-location`,data);
  }
}
