export const POST_PERMISSION = {
    perm1: "core_admin_posts",
    perm2: "core_admin_post_add",
    perm3: "core_admin_post_detail",
    perm4: "core_admin_post_edit",
    perm5: "core_admin_post_delete",
    perm6: "core_admin_post_hide",
    perm7: "core_admin_post_resend",
    perm8: "core_admin_post_cancel",
    perm9: "core_admin_post_receivers",
    perm10: "core_admin_post_comments",
    perm11: "core_admin_post_comment_add",
    perm12: "core_admin_post_comment_delete"
}













