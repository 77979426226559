import { Component, OnInit, Inject, NgModule} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {ListenFirebaseService, SchoolYearsManagerService} from '@app/_services';
import {MatTableDataSource} from '@angular/material/table';
import * as $ from 'jquery';
import {DialogChangeActiveComponent} from '@app/_shared/dialogs/change-active/dialog-change-active.component';
import {MatDialog} from '@angular/material/dialog';
import {ShowMessage} from "@app/_services";
import * as _ from 'lodash';
import {Observable, Subscriber} from "rxjs";
import {TIME_OUT_LISTEN_FIREBASE} from "@app/_shared/utils/constant";

@Component({
  selector: 'app-dialog-add-customer',
  templateUrl: './dialog-add-customer.component.html',
  styleUrls: ['./dialog-add-customer.component.scss']
})
export class DialogAddCustomerComponent implements OnInit {
  dataColumn = ['stt', 'fullName', 'code', 'domain', 'phone', 'email'];
  dataSource = [];
  isCheckAll = false;
  checkStatus: boolean;
  keyword = '';
  isLoading = false;
  countCustom = 0;

  constructor(private dialogRes: MatDialogRef<DialogAddCustomerComponent>,
              private schoolYearsManagerService: SchoolYearsManagerService,
              @Inject(MAT_DIALOG_DATA) private data,
              private fb: FormBuilder,
              private listenFirebaseService: ListenFirebaseService,
              private showMessage: ShowMessage
  ) {
  }


  ngOnInit(): void {
    this.loadDataItems();
  }

  loadDataItems(){
    this.schoolYearsManagerService.getTenantNotAssignedTenants(this.data.infoSchoolYear.id, this.keyword).subscribe((res: any) => {
      this.dataSource = res.data;
      return this.dataSource.forEach(item => item.isChecked = false);
    })
  }

  checkAll(){
    this.isCheckAll = !this.isCheckAll;
    this.dataSource.forEach(item => item.isChecked = this.isCheckAll);
    this.countCustom = this.dataSource.filter(item => item.isChecked === true).length;
  }

  chooseInfo(keyDataSource: number){
    this.dataSource[keyDataSource].isChecked = !this.dataSource[keyDataSource].isChecked;
    let check = this.dataSource.map(e => e.isChecked).indexOf(false);

    if (check === -1)this.isCheckAll = true;
    else this.isCheckAll = false;

    this.countCustom = this.dataSource.filter(item => item.isChecked === true).length;
  }

  keyPressSearch(event){
    if (event.code === 'Enter' || event.key === 'Tab'){
      this.keyword = event.target.value.trim();
      this.loadDataItems()
    }
  }

  checkAllCheckBox(event) {
    $.each(this.dataSource, (index, item) => {
      item.is_active = event.checked;
    });
    this.checkAll = event.checked;
    this.checkStatus = event.checked;
  }

  onSubmit() {
    this.isLoading = true;
    let ids = [];
    this.dataSource.forEach(item => {
      if (item.isChecked) ids.push(item.id);
    });
    if (ids.length === 0) {
      this.isLoading = false;
      return this.showMessage.warring('Bạn chưa chọn khách hàng nào !!');
    }

    setTimeout(() => {
      if (this.isLoading) this.isLoading = false;
    }, TIME_OUT_LISTEN_FIREBASE);

    this.isLoading = true;
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase('create', 'school_year_tenant', subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref) {
        this.isLoading = false;
        this.dialogRes.close(true);
      }else this.isLoading = false
    });

    this.schoolYearsManagerService.assignTenant({schoolYearId: this.data.infoSchoolYear.id, tenantIds: ids}).subscribe((res: any) => {
      this.isLoading = false
      if (res.status === 0) this.showMessage.error(res.msg);
    }, err => this.isLoading = false);
  }

}
