import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SoftwareListComponent} from '@app/_modules/module-software-manager/components/software-list/software-list.component';
import {SoftwareDetailComponent} from '@app/_modules/module-software-manager/components/software-detail/software-detail.component';
import {TenantManagerListComponent} from '@app/_modules/tenants-manager/components/tenant-manager-list/tenant-manager-list.component';
import {TenantManagerDetailComponent} from '@app/_modules/tenants-manager/components/tenant-manager-detail/tenant-manager-detail.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import { MODULE_PERMISSION } from '@app/_modules/module-software-manager/constant';

const routes: Routes = [
  {
    path: '',
    component: SoftwareListComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [MODULE_PERMISSION.perm1],
        redirectTo: '/accessdenied'
      }
    }
  },
  {
    path: 'detail/:id',
    component: SoftwareDetailComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [MODULE_PERMISSION.perm3],
        redirectTo: '/accessdenied'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuleSoftwareManagerRoutingModule { }
