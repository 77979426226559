<section class="container-fluid main-wrapper" fxLayout="column">
    <div fxFlex="10%" fxLayout="row" fxLayoutAlign="end start">
        <!--        <div class="mt-2 div-label-language" >-->
        <!--            <label class="label-language" mat-icon-button [matMenuTriggerFor]="language" #x="matMenuTrigger">-->
        <!--                <div>-->
        <!--                    <img src="https://cdn.webshopapp.com/shops/94414/files/54968390/vietnam-flag-icon-free-download.jpg" style="height: 20px; width: 30px">-->
        <!--                    <span>Tiếng Việt</span>-->
        <!--                </div>-->
        <!--                <img [src]="x.menuOpen ? 'assets/images/icons/icon-32.svg' : 'assets/images/icons/icon-18.svg'" width="14px">-->
        <!--            </label>-->
        <!--            <mat-menu #language="matMenu" class="select-language" >-->
        <!--                <button mat-menu-item><img src="https://cdn.webshopapp.com/shops/94414/files/54968390/vietnam-flag-icon-free-download.jpg" style="height: 20px; width: 30px"> Tiếng Việt </button>-->
        <!--                <button mat-menu-item><img src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg" style="height: 20px; width: 30px"> Cambodia</button>-->
        <!--            </mat-menu>-->
        <!--        </div>-->
    </div>
    <div fxFlex="80%" class="row mb-5">
        <div class="col-12 col-sm-12 col-lg-2"></div>
        <div class="col-12 col-lg-4 col-sm-12 login-image">
            <img [src]="configCore?.login_logo ? configCore?.login_logo: 'assets/public/image/logo.png'" width="30%" class="mt-5 mb-4 ml-5">
            <p class="mt-3 ml-5 mb-5 col-lg-12 desc-tenant">{{configCore?.login_welcome}}</p>
            <img [src]="configCore?.login_background ? configCore?.login_background: 'assets/public/image/minhhoa4_4x-1.png'" width="88%" height="450px" class="col-lg-12">
        </div>
        <div class="col-12 col-sm-12 col-lg-1"></div>
        <div class="col-12 col-lg-3 bg-white col-lg-3 col-sm-12" style="border-radius: 10px">
            <div class="container">
                <p class="pt-3 pb-2 dn-login">Đăng Nhập</p>
                <label class="text-danger pb-2" *ngIf="messages">* {{messages}}</label>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="title-input-form">Tài khoản</label>
                        <input type="text" formControlName="username" class="form-control input-field-global" placeholder="Nhập tài khoản của bạn">
                    </div>
                    <div class="form-group">
                        <label class="title-input-form">Mật khẩu</label><span (click)="onForgotPassword()" class="custom-control float-right text-primary" style="font-size: 14px; text-decoration: none">Quên mật khẩu ?</span>
                        <input [(ngModel)]="password" [type]="hidePassword ? 'password' : 'text'" class="form-control input-field-global" formControlName="password" id="password-field" placeholder="Nhập mật khẩu của bạn">
                        <mat-icon aria-hidden="false" class="field-icon toggle-password" aria-label="Example home icon" [style]="!password ?'color: lightgray': 'color: black' " (click)="hidePassword = !hidePassword">
                            {{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </div>
                    <div class="form-group" fxLayout="row" fxLayoutAlign="space-between end">
                        <mat-checkbox [disabled]="!loginForm.valid" [checked]="isSaveLogin" (change)="checkIsSaveDataLogin($event)"><span class="remember-me-text">Lưu thông tin đăng nhập</span></mat-checkbox>
                    </div>
                    <div class="form-group mt-4">
                        <button [disabled]="loading" type="submit" class="btn btn-primary text-white py-2" style="font-size: 16px" [disabled]="!loginForm.valid">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Đăng Nhập
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
    <div fxFlex="10%"></div>
</section>