<div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<!-- Mô tả module -->
<div class="panel-content-global">
    <div class="panel-header col-log-6"
                                style="margin-left: 0; padding-left: 0">

        <div class="panel-title">Chính sách bảo mật</div>
</div>
    <div class="row ml-0">
        <div class="form-group col-md-12 row row-global pl-0">
            <div class="col-md-12 pl-0 pr-0" *ngIf="isLoading === false">
                <ckeditor [editor]="Editor" data="{{content}}" [(ngModel)]="content" #intro></ckeditor>
            </div>
        </div>
    </div>
</div>
<br>
<!-- Mô tả module -->
<div fxLayout="row" fxLayoutAlign="center end">
    <button class="btn custom-btn custom-btn-close" (click)="cancel()">HỦY</button>&nbsp;&nbsp;
    <button class="btn custom-btn custom-btn-add" (click)="onSubmit()">LƯU</button>
</div>
