import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ListenFirebaseService, RoleManagerService} from '@app/_services';
import {Role} from '@app/_models/role';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {ShowMessage} from '@app/_services';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';

@Component({
    selector: 'app-role-list-users',
    templateUrl: './role-list-users.component.html',
    styleUrls: ['./role-list-users.component.scss']
})
export class RoleListUsersComponent implements OnInit, OnChanges {
    displayedColumns: string[] = ['stt', 'fullname', 'username', 'phone', 'email', 'tool'];
    // dataSource = new MatTableDataSource(DATA_ROLE_USER);
    @Input() roleId: string;
    @Input() reloadTabUser;
    @Input() dataSearch = '';
    @Input() change;
    status: number;
    total: number;
    page: number;
    limit: number;
    isLoading:boolean = false;
    dataSource = [];


    constructor(
        private rolesService: RoleManagerService,
        private dialogRes: MatDialog,
        private showMessage: ShowMessage,
        private db: AngularFireDatabase,
        private listenFirebaseService: ListenFirebaseService
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        setTimeout(() => {
            this.getUsersOfRole();
            }, 1500);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.change !== 0) {
            this.isLoading = true;
            setTimeout(() => {
                this.getUsersOfRole(this.dataSearch);
            }, 1500);
        }
    }

    getUsersOfRole(keyWord?: string) {
        this.rolesService.getUsersOfRole(this.roleId, keyWord, 0, 99999, '').subscribe((res: any) => {
            if (res.status === 1){
                this.dataSource = res.data;
                this.isLoading = false;
            }
            else if (res.status === 0){
                this.showMessage.error(res.msg);
                this.isLoading = false;
            }
        });
    }

    confirmDelete(infoUser) {
        const avatar = infoUser.avartar !== '' ? infoUser.avartar : 'assets/images/png/avatar.png';
        const dialogConfirm = this.dialogRes.open(DialogDeleteComponent, {
            height: 'auto',
            width: '40%',
            data: {
                title: 'Bạn đang thực hiện xoá người dùng',
                message: 'Bạn có chắc muốn gỡ tài khoản này không ?',
                btnOkText: 'XÁC NHẬN',
                btnCancelText: 'HỦY',
                data: {
                    name: infoUser.fullName,
                },
                html: `<img src="` + avatar + `" alt="Girl in a jacket">`
            }
        });
        dialogConfirm.afterClosed().subscribe(confirm => {
            if (confirm){
                this.checkFireBase('remove_user_from_role');
                this.isLoading = true;
                this.rolesService.removeUser(this.roleId, infoUser.id).subscribe((ref: any) => {
                        if (ref.status === 1) {}
                        else {
                            this.isLoading = false;
                        }
                    },
                    (error: any) => {
                        this.isLoading = false;
                    });
                setTimeout (() => {
                    if (this.isLoading){
                        this.isLoading = false;
                        this.getUsersOfRole();
                    }
                }, 10000);
            }
        });
    }

    checkFireBase(param){
        let listenFb = new Observable((subscriber: Subscriber<any>) => {
            this.listenFirebaseService.checkFireBase(param, 'role', subscriber);
        });
        listenFb.subscribe((res) => {
            if (res === true) {
                setTimeout(() => {
                    this.getUsersOfRole();
                }, 1500);
            }
        });
    }
}
