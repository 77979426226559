import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostManagerRoutingModule } from './post-manager-routing.module';
import { PostManagerListComponent } from './components/post-manager-list/post-manager-list.component';
import {SharedModule} from '@app/_shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import { PostManagerDetailComponent } from './components/post-manager-detail/post-manager-detail.component';
import { PostManagerFormComponent } from './components/post-manager-form/post-manager-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogPostManagerListObjectComponent } from './dialogs/dialog-post-manager-list-object/dialog-post-manager-list-object.component';
import { DialogPostManagerListUserComponent } from './dialogs/dialog-post-manager-list-user/dialog-post-manager-list-user.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FileManagerComponent } from '@app/_shared/components/file-manager/file-manager.component';
import { PostManagerFileManagerComponent } from './dialogs/post-manager-file-manager/post-manager-file-manager.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [FileManagerComponent, PostManagerListComponent, PostManagerDetailComponent, PostManagerFormComponent, DialogPostManagerListObjectComponent, DialogPostManagerListUserComponent, PostManagerFileManagerComponent],
  imports: [
    CommonModule,
    PostManagerRoutingModule,
    SharedModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgbModule,
    CKEditorModule,
    NgxPermissionsModule.forChild()
  ]
})
export class PostManagerModule { }
