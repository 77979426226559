import {Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';
import {DialogFormPermissionComponent} from '../../../dialogs/dialog-form-permission/dialog-form-permission.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import * as moment from 'moment';
import {ListenFirebaseService, ModulesManagerService, ShowMessage} from '@app/_services';
import {MatTableDataSource} from '@angular/material/table';
import {environment} from '@environments/environment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/internal/Observable';
import {Subscriber} from 'rxjs';
import { TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { NgxPermissionsService } from 'ngx-permissions';
import {MODULE_PERMISSION} from '../../../constants';

@Component({
  selector: 'app-tab-permission',
  templateUrl: './tab-permission.component.html',
  styleUrls: ['./tab-permission.component.scss']
})
export class TabPermissionComponent implements OnInit, OnChanges {
  @Input() moduleId;
  @Input() openDialogAdd;
  @Input() search;
  @Input() moduleName;
  displayedColumns: string[] = ['stt', 'fullname', 'code', 'describe', 'order_index'];
  currentPage = 0;
  pageSize = 2;
  permissions = [];
  panelOpenState = false;
  dataSource: any;
  length: number;
  codeRandom: string;
  typeModule = "module";
  isLoading:boolean = false;
  MODULE_PERMISSION= MODULE_PERMISSION;
  constructor(private dialog: MatDialog,
    private modulesManagerService: ModulesManagerService,
    private showMessage: ShowMessage,
    private db: AngularFireDatabase,
    private listenFirebaseService: ListenFirebaseService,
    private permissionsService: NgxPermissionsService
  ) {
  }

  ngOnInit(): void {
    this.listPermission();
    if (this.permissionsService.getPermission(this.MODULE_PERMISSION.perm11) 
    || this.permissionsService.getPermission(this.MODULE_PERMISSION.perm12) 
        ){
            const index = this.displayedColumns.indexOf('tools');
            if (index == -1) {
              this.displayedColumns.push('tools');
            }
        }
  }

  checkFireBase(param){
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, this.typeModule, subscriber);
    });
    listenFb.subscribe((res) => {
      if(res === true) {
        this.isLoading = false;
        this.listPermission(this.search);
      }else{
        this.isLoading = false;
      }
    });
  }

  listenFirebase(){
    this.checkFireBase('create');
    this.checkFireBase('update');
    this.checkFireBase('delete');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.openDialogAdd > 0) {
      this.openDialogAddPermission();
    }
    this.listPermission(this.search);
  }
  
  listPermission(search='') {
    this.isLoading = true;
    this.modulesManagerService.getListPermissionByModule(this.moduleId, search).subscribe((res: any) => {
      if(res.status == 1){
        this.dataSource = new MatTableDataSource(res.data);
        this.length = res.data.length;
        this.isLoading = false;
      }else{
        this.isLoading = false;
      }
    },
    (err)=>{
      this.isLoading = false;
    });
  }


  openDialogAddPermission() {
    const dialogRef = this.dialog.open(DialogFormPermissionComponent, {
      width: '70%',
      height: 'auto',
      data: {
        status: 'add',
        data: this.moduleId,
        item: '',
        code: ''
      }
    });
    dialogRef.afterClosed().subscribe(
      (value) => {
        if(value == false || typeof value == 'undefined'){
          return;
        }else{
          this.listenFirebase();
          this.isLoading = true;
          this.modulesManagerService.storePermissionByModule(value).subscribe((res: any) => {
            if(res.status == 0){
              this.showMessage.error(res.msg);
              this.isLoading = false;
            }
          },
          (err)=>{
              this.isLoading = false;
          });
          setTimeout(() => {
            if(this.isLoading){
              this.isLoading = false;
            }
          }, TIME_OUT_LISTEN_FIREBASE);
        }
    });
  }

  openDialogUpdatePermission(data) {
    const dialogRef = this.dialog.open(DialogFormPermissionComponent, {
      width: '70%',
      height: 'auto',
      data: {
        status: 'update',
        data: this.moduleId,
        item: data,
        code: ''
      }
    });

    dialogRef.afterClosed().subscribe((value: any) => {
      if(value == false || typeof value == 'undefined'){
        return;
      }else{
        this.listenFirebase();
        this.isLoading = true;
        this.modulesManagerService.updatePermissionByModule(value).subscribe(
          (res: any) => {
            if(res.status == 0){
              this.showMessage.error(res.msg);
              this.isLoading = false;
            }
          },
          (err)=>{
              this.isLoading = false;
          }
        );
        setTimeout(() => {
          if(this.isLoading){
            this.isLoading = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }
    });
  }

  openDialogDelete(itemName: string, itemId: string) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xóa quyền',
        message: 'Bạn có chắc muốn xóa quyền này không?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'HỦY',
        data: {
          name: `${itemName} - ${this.moduleName}`
        },
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.listenFirebase();
        this.isLoading = true;
        this.modulesManagerService.deletePermissionByModule(itemId).subscribe((res: any) => {
          if(res.status == 0){
            this.showMessage.error(res.msg);
            this.isLoading = false;
          }
        },
        (err)=>{
            this.isLoading = false;
        });
        setTimeout(() => {
          if(this.isLoading){
            this.isLoading = false;
          }
        }, TIME_OUT_LISTEN_FIREBASE);
      }
    });
  }
}
