import { Component, OnInit } from '@angular/core';
import { FileElement } from '@app/_shared/components/file-manager/model/element';
import { ListenFirebaseService, ShowMessage, FileService } from '@app/_services';
import { Folder } from '@app/_models';
import * as _ from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';
import {Observable, Subscriber} from 'rxjs';

@Component({
  selector: 'app-post-manager-file-manager',
  templateUrl: './post-manager-file-manager.component.html',
  styleUrls: ['./post-manager-file-manager.component.scss']
})
export class PostManagerFileManagerComponent implements OnInit {

  public fileElements: Observable<FileElement[]>;

  constructor(
    public fileService: FileService,
    public dialogRef: MatDialogRef<PostManagerFileManagerComponent>,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService
  ) { }

  currentRoot: FileElement;
  currentPath: string;
  canNavigateUp = false;
  data: any;
  typeModule = 'file_manager';

  ngOnInit() {
    this.showFileAndFolderByID();
  }

  renderFolders = () => {

  }

  renderFiles = () => {

  }

  convertPath = (obj) => {
    return { ...obj, "pathLength": obj.path.split("/").length };
  }

  getFilename = (path) => {
    return path.split("/").filter(function (value) {
      return value && value.length;
    }).reverse()[0];
  }


  addFolder(folder: { name: string }) {
    this.fileService.createFolder(this.currentPath, folder.name).subscribe((ref: any) => {
      if (ref.status === 1) {
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase('create', this.typeModule, subscriber);
        });
        listenFb.subscribe((ref) => {
            if (ref === true) {
              this.fileService.add({ id: Date.now().toString(), isFolder: true, name: folder.name, parent: this.currentRoot ? this.currentRoot.id : 'root' });
              this.updateFileElementQuery();
            }
        });
      } else {
        this.showMessage.error(ref.msg);
      }
    }), (error) => {
      this.showMessage.error(error);
    }
  }

  addFile(file) {
    this.fileService.uploadFile(this.currentPath, file.base64, file.fileName).subscribe((ref: any) => {
      if (ref.status === 1) {
        const listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase('create', this.typeModule, subscriber);
        });
        listenFb.subscribe((ref) => {
            if (ref === true) {
              this.fileService.add({ id: Date.now().toString(), isFolder: false, name: file.fileName, parent: this.currentRoot ? this.currentRoot.id : 'root', url: "https://homepages.cae.wisc.edu/~ece533/images/airplane.png" });
              this.updateFileElementQuery();
            }
        });
      } else {
        this.showMessage.error(ref.msg);
      }
    }), (error) => {
      this.showMessage.error(error);
    }
  }

  showFileAndFolderByID(folderId = "") {
    this.fileService.getFolderFile(folderId).subscribe((ref: any) => {
      if (ref.status === 1) {
        // this.showMessage.success(ref.msg);
        this.data = ref.data;
        this.data?.folders.forEach((element) => {
          if (element.path !== null) {
            this.fileService.add({ id: element.id, name: this.getFilename(element.path), isFolder: true, parent: this.currentRoot ? this.currentRoot.id : 'root' });
          }
        });
        this.data?.files.forEach(element => {
          if (this.data?.currentFolder === "/") {
            this.fileService.add({ id: element.id, name: element.name, isFolder: false, parent: 'root', url: element.url });

          } else {
            this.fileService.add({ id: element.id, name: element.name, isFolder: false, parent: element.folderId, url: element.url });

          }
        });
        this.updateFileElementQuery();

      } else {
        this.showMessage.error(ref.msg);

      }
    }, (error) => {
      this.showMessage.error(error);
    });
  }

  fileChoose(element: FileElement) {
    this.dialogRef.close(element);
  }

  removeElement(element: FileElement) {
    
    this.fileService.delete(element.id).subscribe((ref: any) => {
      if (ref.status === 1) {
        this.showMessage.success(ref.msg);
        this.updateFileElementQuery();
      } else {
        this.showMessage.error(ref.msg);
      }
    }, (error) => {
      this.showMessage.error(error);
    });
  }

  navigateToFolder(element: FileElement) {
    // console.log(this.currentRoot); // root null  {id: "3fa85f64-5717-4562-b3fc-2c963f66afa2", name: "child1", isFolder: true, parent: "root"}
    // console.log(this.currentPath); // root " "  child1/ 
    // child1/ 
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.name);
    this.canNavigateUp = true;
    this.showFileAndFolderByID(element.id);
  }

  navigateUp() {
    // console.log(this.currentRoot);
    // console.log(this.currentPath);
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.parent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  moveElement(event: { element: FileElement; moveTo: FileElement }) {
    this.fileService.update(event.element.id, { parent: event.moveTo.id });
    this.updateFileElementQuery();
  }

  renameElement(element: FileElement) {
    this.fileService.update(element.id, { name: element.name }, element.isFolder).subscribe((ref: any) => {
      if (ref.status === 1) {
        this.showMessage.success(ref.msg);
        this.updateFileElementQuery();
      } else {
        this.showMessage.error(ref.msg);
      }
    }, (error) => {
      this.showMessage.error(error);
    });
    this.updateFileElementQuery();
  }

  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

}
