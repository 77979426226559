import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DialogSelectUnitVerticalComponent } from '@app/_shared/dialogs/dialog-select-unit-vertical/dialog-select-unit-vertical.component';
import { DialogAddUnitComponent } from '../dialog-add-unit/dialog-add-unit.component';

@Component({
  selector: 'app-dialog-select-option',
  templateUrl: './dialog-select-option.component.html',
  styleUrls: ['./dialog-select-option.component.scss']
})
export class DialogSelectOptionComponent implements OnInit {
  
  dataResult: any = {
    id: null,
    name: ''
  } 
  isChoose: any = null;

  constructor(
      public dialogRef: MatDialogRef<DialogSelectOptionComponent>,
      @Inject(MAT_DIALOG_DATA) public dataDialog,
      public dialog: MatDialog,
    
  ) { }

  ngOnInit(): void {
  }

  selectOption(event) {
    //  chon don vi nganh doc
    if(event.target.value == 1) {
      const dialogRef = this.dialog.open(DialogSelectUnitVerticalComponent, {
        height: 'auto',
        width: '80%',
      })
      dialogRef.afterClosed().subscribe((ref: any) => {
        console.log(ref);
        if (ref != undefined && ref) {
          this.dataResult.id = ref.unit.id;
          this.dataResult.name = ref.unit.name;
          this.dialogRef.close(this.dataResult);
          return;
        } else {
          this.isChoose = null;
          return;
        }
      });
    } 
    // chon don vi giao duc
    if(event.target.value == 2) {
      const dialogRef = this.dialog.open(DialogAddUnitComponent, {
        height: 'auto',
        width: '60%',
      })
      dialogRef.afterClosed().subscribe((ref: any) => {
        if (ref != undefined && ref) {
          this.dataResult.id = ref.id;
          this.dataResult.name = ref.name;
          this.dialogRef.close(this.dataResult);
          return;
        } else {
          this.isChoose = null;
          return;
        }
      })
    }
  }
  
}
