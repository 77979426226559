import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@app/_shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import { SchoolYearsListComponent } from './components/school-years-list/school-years-list.component';
import {SchoolYearsManagerRoutingModule} from './school-years-manager-routing.module';
import { DialogFormSchoolYearsComponent } from './dialogs/dialog-form-school-years/dialog-form-school-years.component';
import {SchoolYearDetailsComponent} from './components/school-year-details/school-year-details.component';
import { TabSchoolYearInfoComponent } from './tabs/tab-school-year-info/tab-school-year-info.component';
import { TabListCustomerComponent } from './tabs/tab-list-customer/tab-list-customer.component';
import {DialogAddCustomerComponent} from './dialogs/dialog-add-customer/dialog-add-customer.component';
import { DialogListCustomersComponent } from './dialogs/dialog-list-customers/dialog-list-customers.component';
import {DialogChangeStatusComponent} from './dialogs/dialog-change-status/dialog-change-status.component';
import { NgxPermissionsModule } from 'ngx-permissions';
@NgModule({
    declarations: [
        SchoolYearsListComponent,
        DialogFormSchoolYearsComponent,
        SchoolYearDetailsComponent,
        TabSchoolYearInfoComponent,
        TabListCustomerComponent,
        DialogAddCustomerComponent,
        DialogListCustomersComponent,
        DialogChangeStatusComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        SchoolYearsManagerRoutingModule,
        NgxPermissionsModule.forChild()
    ],
})

export class SchoolYearsManagerModule {

}
