import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MESSAGE_ERROR_CALL_API, TIME_OUT_LISTEN_FIREBASE } from '@app/_shared/utils/constant';
import { Subscriber } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { GeneralConfigService, ListenFirebaseService, ShowMessage } from '@app/_services';
import { ARR_TYPE_CONFIG_NOTI_SYSTEM } from '../../constants';
@Component({
  selector: 'app-dialog-form-cofig-noti',
  templateUrl: './dialog-form-cofig-noti.component.html',
  styleUrls: ['./dialog-form-cofig-noti.component.scss']
})
export class DialogFormConfigNotiComponent implements OnInit {

  formConfigNoti: FormGroup;
  isLoading: boolean = false;
  arrType: Array<object> = ARR_TYPE_CONFIG_NOTI_SYSTEM;

  constructor(
    public dialogRef: MatDialogRef<DialogFormConfigNotiComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private generalConfigService: GeneralConfigService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,

  ) { }

  ngOnInit(): void {
    this.formConfigNoti = this.fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      type: 1,
    });
  }

  onSubmit(valueForm) {
    console.log(valueForm);
    let dataInput = {
      title: valueForm.title,
      content: valueForm.content,
      type: Number(valueForm.type)
    }
    this.listenFireBase('create_system_alert');
    this.setTimOutFireBase();
    this.generalConfigService.addConfigNoti(dataInput).subscribe((res: any) => {
      if (res.status == 0) {
        this.showMessage.error(res.msg);
      }
    }, (err: any) => {
      this.showMessage.error(MESSAGE_ERROR_CALL_API);
    });
  }

  listenFireBase(action) {
    const listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(action, 'admin_config', subscriber);
    });
    listenFb.subscribe((ref) => {
      if (ref === true) {
        this.isLoading = false;
        this.dialogRef.close(true);
      } else {
        this.isLoading = false;
        this.dialogRef.close(false);
      }
    });
  }

  setTimOutFireBase() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }

  validationMessages = {
    title: [
      { type: 'required', message: 'Tiêu đề thông báo bắt buộc phải nhập!' },
    ],
    content: [
      { type: 'required', message: 'Nội dung thông báo bắt buộc phải nhập!' },
    ],
  };

}
