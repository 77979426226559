import { Component, Injectable, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ShowMessage } from "@app/_services";
import { Observable, Subscriber, BehaviorSubject, of as ofObservable } from "rxjs";
import { ListenFirebaseService } from "@app/_services/listen-firebase.service";
import { TIME_OUT_LISTEN_FIREBASE, MESSAGE_ERROR_CALL_API } from "@app/_shared/utils/constant";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { CurriculumManagerService } from "@app/_services/curriculm-manager.service";
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '@app/_shared/dialogs/delete/dialog-delete.component';
import { MatTreeSelectOptionComponent } from '@app/_modules/curriculum/components/mat-tree-select-option/mat-tree-select-option.component';
// import * as $ from "jquery";
import * as Editor from '../../../../../assets/js/ckeditor/build/ckeditor';
export class ItemNode {
  id: string;
  content: string;
  childs: ItemNode[];
  isCheckEdit: boolean; //check có click btn sửa node
  isCheckClear: boolean; // check có click btn icon clear giá trị ô nhập k
  suggestTieuChiDanhGiaIds: any; // mang id tiêu chí BE trả về
  suggestTieuChiDanhGiaIdsNode: any; // màng id tiêu chí node lưu khi có sự thay đổi khi chọn
  isDisableAdd: boolean // disable btn add - khi add item mới nhưng chưa tích icon tích
}

/** Flat to-do item node with expandable and level information */
export class TreeNode {
  id: string;
  content: string;
  level: number;
  expandable: boolean;
  isCheckEdit: boolean;
  suggestTieuChiDanhGiaIds: any;
  childs: any;
  suggestTieuChiDanhGiaIdsNode: any;
  isDisableAdd: boolean
}

@Injectable()
export class ChecklistDatabase {
  dataChange: BehaviorSubject<ItemNode[]> = new BehaviorSubject<ItemNode[]>([]);
  TREE_DATA = [];

  get data(): any[] {
    return this.dataChange.value;
  }

  constructor(
    private curriculumManagerService: CurriculumManagerService,
    private activeRoute: ActivatedRoute
  ) {
    this.activeRoute.params.subscribe((param) => {
      let id = param.idCurriculum;
      this.activeRoute.queryParams.subscribe((queryParam) => {
        let type = queryParam.tab == "required" ? 2 : 1;
        this.getCurriculumContent(id, type);
      });
    });
  }

  getCurriculumContent(id, type) {
    let dataInput = {
      khungChuongTrinhId: id,
      type: type,
    };
    this.curriculumManagerService.getListContentCurriculum(dataInput).subscribe(
      (res: any) => {
        this.TREE_DATA = [];
        res.data.forEach((element) => {
          this.TREE_DATA.push({
            id: element.id,
            content: element.content,
            isCheckEdit: false,
            isCheckClear: false,
            childs: element.childs,
            suggestTieuChiDanhGiaIds: element.suggestTieuChiDanhGiaIds
          });
        });
        this.dataChange.next(this.TREE_DATA);
      },
      (err: any) => { }
    );
  }

  /** Add an item to to-do list */
  insertItem(parent: ItemNode, name: string) {
    const child = <ItemNode>{ content: name };
    if (parent.childs) {
      parent.childs.push(child);
      this.dataChange.next(this.data);
    } else {
      parent.childs = [];
      parent.childs.push(child);
      this.dataChange.next(this.data);
    }
  }

  insertRootItem(parent: ItemNode, name: string) {
    const child = <ItemNode>{ content: name };
    if (parent == undefined) {
      //  parent is a leaf node
      parent = {
        id: null,
        content: "",
        childs: [],
        isCheckEdit: false,
        isCheckClear: false,
        suggestTieuChiDanhGiaIds: [],
        suggestTieuChiDanhGiaIdsNode: [],
        isDisableAdd: false,

      };
      parent.childs.push(child);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: ItemNode, name: string, data: any) {
    node.content = name;
    node.id = data.id;
    node.isCheckEdit = false;
    node.suggestTieuChiDanhGiaIdsNode = data.suggestTieuChiDanhGiaIdsNode;
    node.suggestTieuChiDanhGiaIds = data.suggestTieuChiDanhGiaIds;
    
    this.dataChange.next(this.data);
  }

  deleteItem(parent: ItemNode, id: string): void {
    if (parent.childs) {
      parent.childs = parent.childs.filter((c) => c.id !== id);
      this.dataChange.next(this.data);
    } else {
    }
  }
}
@Component({
  selector: "app-tab-content-curriculum",
  templateUrl: "./tab-content-curriculum.component.html",
  styleUrls: ["./tab-content-curriculum.component.scss"],
  providers: [ChecklistDatabase],
})
export class TabContentCurriculumComponent {
  dataChange: BehaviorSubject<ItemNode[]> = new BehaviorSubject<ItemNode[]>([]);
  public Editor = Editor;

  get data(): any[] {
    return this.dataChange.value;
  }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap: Map<TreeNode, ItemNode> = new Map<TreeNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap: Map<ItemNode, TreeNode> = new Map<ItemNode, TreeNode>();

  treeControl: FlatTreeControl<TreeNode>;

  treeFlattener: MatTreeFlattener<ItemNode, TreeNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, TreeNode>;

  showBtnIcon = false;
  showInputRoot = 0; // 0 chưa có itemroot nào - ẩn, 1 thêm thành công -hiển thị
  showBtnAddRoot: number = 0;
  isLoading = false;
  dataTree: any;
  curriculumId: string = "";
  type: number;
  isDisableAdd = false;
  @Input() subjectId: string;
  @Input() gradeId: string;
  @Input() idKCT: string;
  listCheckBox: any = []; // mang tieu chi chon gan khi them node
  pageSize: number = 9999;
  pageIndex: number = 1;
  canAddNewNode: boolean = true; // chỉ cho thêm từng node
  canEditNode: boolean = true; // chỉ cho sửa từng node
  valueNodeRoot: any = null; // value node root
  valueAddNewNode: any = null; // value khi them node moi, khong phai node root

  constructor(
    private database: ChecklistDatabase,
    private curriculumManagerService: CurriculumManagerService,
    private showMessage: ShowMessage,
    private listenFirebaseService: ListenFirebaseService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
      if (this.dataSource.data.length > 0) {
        this.showBtnAddRoot = 1;
        this.showInputRoot = 0;
      } else {
        this.showBtnAddRoot = 0;
        this.showInputRoot = 1;
      }
    });
    this.activatedRoute.params.subscribe((res) => (this.curriculumId = res.idCurriculum));
    this.activatedRoute.queryParams.subscribe((queryParam) => {
      this.type = queryParam.tab == "required" ? 2 : 1;
      this.canAddNewNode = true;
      this.canEditNode = true;
      this.valueNodeRoot = null;
    });
  }
  ngOnChanges() {
    this.database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  getCurriculumContent(id, type) {
    let dataInput = {
      khungChuongTrinhId: id,
      type: type,
    };
    this.curriculumManagerService.getListContentCurriculum(dataInput).subscribe(
      (res: any) => {
        this.dataSource.data = []
        res.data.forEach((element) => {
          this.dataSource.data.push(element);
        });
        this.dataChange.next(this.dataSource.data);
      },
      (err: any) => { }
    );
  }

  clickBtnEdit(node) {
    node.isCheckEdit = !node.isCheckEdit;
    this.canEditNode = false;
  }

  clickClearTextEdit(node) {
    node.isCheckEdit = false;
    this.canEditNode = true;
    node.suggestTieuChiDanhGiaIdsNode = node.suggestTieuChiDanhGiaIds;
  }

  // clear text when add node
  clickClearTextCreate(node) {
    this.canAddNewNode = true;
    if (node) {
      const currentNode = this.getParentNode(node);
      const parentNode = this.flatNodeMap.get(currentNode);
      this.valueAddNewNode = null;
      this.deleteItem(node);
      // if (typeof node == "string") {
      //   this.showInputRoot = 0;
      //   this.showBtnAddRoot = 1;
      //   this.valueNodeRoot = null;
      // } else {
      //   $(".no-content .btn--icon--not-oke").click(function () {
      //     $(this).attr("id");
      //     this.deleteItem(node);
      //   });
      //   this.deleteItem(node);
      // }
    } else {
      this.showInputRoot = 0;
      this.showBtnAddRoot = 1;
      this.valueNodeRoot = null;
    }
  }

  clickDoneCreate(node, value) {
    let dataOutput: any;
    if (value == "") {
      this.showMessage.warring("Vui lòng nhập nội dung!");
      return;
    }

    let parentNode = this.getParentNode(node);
    this.isLoading = true;
    let dataInput = {
      khungChuongTrinhId: this.curriculumId,
      parentId: parentNode ? parentNode.id : null,
      content: value,
      type: this.type,
      noiDungGiaoDucIds: this.listCheckBox,
      isAwait: 1,
    };
    
    this.curriculumManagerService.createContentCurriculum(dataInput).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.status == 0) {
          this.showMessage.error(res.msg);
          node.content = "";
          this.showInputRoot = 1;
          this.showBtnAddRoot = 0;
        } else {
          this.isLoading = false;
          const currentNode = this.getParentNode(node);
          const parentNode = this.flatNodeMap.get(currentNode);
          dataOutput = node;
          dataOutput.id = res.data;
          dataOutput.suggestTieuChiDanhGiaIds = node.suggestTieuChiDanhGiaIdsNode;
          dataOutput.suggestTieuChiDanhGiaIdsNode = node.suggestTieuChiDanhGiaIdsNode;
          dataOutput.isDisableAdd = false;
          dataOutput.isCheckEdit = false;
          this.canAddNewNode = true;
          this.showBtnAddRoot = 1;
          this.showInputRoot = 0;
          this.listCheckBox = [];
          this.valueNodeRoot = null;
          if (parentNode) {
            this.saveNode(node, value, dataOutput);
            this.showMessage.success(res.msg);
          } else {
            this.saveRootNode(node, value, dataOutput);
            this.showMessage.success(res.msg);
          }
        }
      },
      (err: any) => {
        this.isLoading = false;
        this.showMessage.error(MESSAGE_ERROR_CALL_API);
      }
    );
  }

  clickDoneEdit(node, value) {
    if (value == '' || value == undefined || value == null) {
      this.type == 1 ? this.showMessage.warring('Vui lòng nhập nội dung bài học!') : this.showMessage.warring('Vui lòng nhập yêu cầu cần đạt!');
      node.content = node.oldValue;
      node.childs.length > 0 ? node.expandable = true : node.expandable = false;
      node.isCheckEdit = false;
      return;
    }
  
    this.canEditNode = true;
    this.isLoading = true;
    let dataInput = {
      noiDungGiaoDucId: node.id,
      content: value,
      isAwait: 1,
      noiDungGiaoDucIds: node.suggestTieuChiDanhGiaIdsNode
    };
    this.curriculumManagerService.editContentCurriculum(dataInput).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.status == 0) {
          this.showMessage.error(res.msg);
        } else {
          node.isCheckEdit = false;
          node.oldValue = res.data.content;
          let dataOutput = node;
          dataOutput.suggestTieuChiDanhGiaIds = node.suggestTieuChiDanhGiaIdsNode;
          dataOutput.suggestTieuChiDanhGiaIdsNode = node.suggestTieuChiDanhGiaIdsNode;
          this.saveNode(node, value, dataOutput);
          this.showMessage.success(res.msg);
        }
      },
      (err: any) => {
        this.isLoading = false;
        this.showMessage.error(MESSAGE_ERROR_CALL_API);
      }
    );
  }

  getLevel = (node: TreeNode) => {
    return node.level;
  };

  isExpandable = (node: TreeNode) => {
    return node.expandable;
  };

  getChildren = (node: ItemNode): Observable<ItemNode[]> => {
    return ofObservable(node.childs);
  };

  hasChild = (_: number, _nodeData: TreeNode) => {
    return _nodeData.expandable;
  };

  hasNoContent = (_: number, _nodeData: TreeNode) => {
    return _nodeData.content === "";
  };

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    let flatNode =
      this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.content === node.content
        ? this.nestedNodeMap.get(node)!
        : new TreeNode();
    flatNode.id = node.id;
    flatNode.content = node.content;
    flatNode.level = level;
    flatNode.isCheckEdit = false;
    flatNode.isDisableAdd = false;
    flatNode.expandable = node.childs ? (node.childs.length > 0 ? true : false) : false;
    flatNode.suggestTieuChiDanhGiaIds = node.suggestTieuChiDanhGiaIds;
    flatNode.suggestTieuChiDanhGiaIdsNode = node.suggestTieuChiDanhGiaIds;
    flatNode.childs = node.childs ? node.childs : [];
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /* Get the parent node of a node */
  public getParentNode(node: TreeNode): TreeNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(event, node: TreeNode) {
    this.valueAddNewNode = null;
    const parentNode = this.flatNodeMap.get(node);
    event.stopPropagation();
    node.isDisableAdd = true;
    this.canAddNewNode = false;
    this.database.insertItem(parentNode!, "");
    this.treeControl.expand(node);
  }

  addRootItem(node: TreeNode) {
    this.showInputRoot = 1;
    node.isDisableAdd = true;
    this.canAddNewNode = false;
  }

  /** Save the node to database */
  saveNode(node: TreeNode, itemValue: string, dataNode) {
    const nestedNode = this.flatNodeMap.get(node);
    node.expandable = true;
    node.id = dataNode.id;
    this.database.updateItem(nestedNode!, itemValue, dataNode);
  }

  saveRootNode(node: ItemNode, value: string, dataNode: any) {
    node.content = value;
    node.id = dataNode.id;
    this.dataSource.data.push(node);
    const data = this.dataSource.data;
    this.database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  deleteItem(node: TreeNode): void {
    if (node.level == 0) {
      // xoa node root
      let index = this.dataSource.data.findIndex((item) => item.id == node.id);
      this.dataSource.data.splice(index, 1);
      this.database.dataChange.subscribe((data) => {
        this.dataSource.data = data;
      });
    } else {
      // Get the parent node of the selected child node
      const parentNode = this.getParentNode(node);

      // Map from flat node to nested node.
      const parentFlat = this.flatNodeMap.get(parentNode);
      this.database.deleteItem(parentFlat!, node.id);
      this.treeControl.expand(node);
    }
  }

  deleleNode(node: TreeNode) {
    // call api và check nếu status = 1 thì gọi hàm xóa của mat-tree ( deleteItem)
    let dataInput = {
      khungChuongTrinhId: this.curriculumId,
      noiDungGiaoDucIds: node.id,
      type: this.type,
      isAwait: 1,
    };
    let mess: string = '';
    if (this.type == 1) {
      if (node.childs.length > 0) {
        mess = 'Nội dung này đang có chứa dữ liệu. Bạn có chắc chắn muốn xóa nội dung này không?';
      } else {
        mess = 'Bạn có chắc chắn muốn xóa nội dung này không?'
      }
    } else {
      if (node.childs.length > 0) {
        mess = 'Yêu cầu này đang có chứa dữ liệu. Bạn có chắc chắn muốn xóa yêu cầu cần đạt này không?';
      } else {
        mess = 'Bạn có chắc chắn muốn xóa yêu cầu cần đạt này không?'
      }
    }
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        data: dataInput,
        title: this.type == 1 ? 'Xóa nội dung' : 'Xóa yêu cầu cần đạt',
        message: mess,
        btnCancelText: 'Hủy',
        btnOkText: 'Xóa'
      },
      width: '30%',
      height: 'auto',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.checkFireBase("delete");
        this.curriculumManagerService.deleteContentCurriculm(dataInput).subscribe(
          (res: any) => {
            if (res.status == 0) {
              this.showMessage.error(res.msg);
            } else {
              this.deleteItem(node);
              this.showMessage.success(res.msg);
            }
          },
          (err: any) => {
            this.showMessage.error(MESSAGE_ERROR_CALL_API);
          }
        );
      }
    })

  }

  openChooseContent(node, canCheck) {
    console.log(node);
    
    const dialogRef = this.dialog.open(MatTreeSelectOptionComponent, {
      data: {
        id: this.curriculumId,
        type: 2,
        listCheckBox: node.suggestTieuChiDanhGiaIdsNode ? node.suggestTieuChiDanhGiaIdsNode : [],
        canCheck: canCheck,
      },
      width: '60%',
      height: 'auto',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == undefined) {
        if (!canCheck) { // khi tạo
          node.suggestTieuChiDanhGiaIdsNode = node.suggestTieuChiDanhGiaIdsNode ? node.suggestTieuChiDanhGiaIdsNode : [];
        } else { // khi sửa
          node.suggestTieuChiDanhGiaIdsNode = [];
        }
      } else {
        node.suggestTieuChiDanhGiaIdsNode = res;
        this.listCheckBox = node.suggestTieuChiDanhGiaIdsNode ? node.suggestTieuChiDanhGiaIdsNode : [];
      }
      
    })
  }

  assignContent(node, canCheck) {
    const dialogRef = this.dialog.open(MatTreeSelectOptionComponent, {
      data: {
        id: this.curriculumId,
        type: 2,
        listCheckBox: node.suggestTieuChiDanhGiaIdsNode ? node.suggestTieuChiDanhGiaIdsNode : [],
        canCheck: canCheck,
      },
      width: '60%',
      height: 'auto',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == undefined) {
        return;
      } else {
        node.suggestTieuChiDanhGiaIdsNode = res;
        this.clickDoneEdit(node, node?.content);
        return;
      }
    })
  }

  checkFireBase(param) {
    let listenFb = new Observable((subscriber: Subscriber<any>) => {
      this.listenFirebaseService.checkFireBase(param, "noi_dung_giao_duc", subscriber);
    });
    listenFb.subscribe((res) => {
      if (res === true) {
        this.isLoading = false;
        this.showMessage.success(res.msg);
        return res;
      }
    });
  }
  setTimeOutFireBase() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, TIME_OUT_LISTEN_FIREBASE);
  }
}
