import { Component, OnInit, Input } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {AccountManagerService, GeneralService} from '@app/_services';
import {any} from 'codelyzer/util/function';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  hidePassword = true;
  isLoading = true;
  panelOpenState = true;
  @Input() infoUser;
  @Input() dataLocation;

  constructor(
      private accountManagerService: AccountManagerService,
      private generalService: GeneralService
  ) {
  }
  ngOnInit(): void {

  }

}
