
import { ModuleWithProviders,NgModule } from "@angular/core";
import { MathDirective } from "./math.directive";
import { MathTypeQuestionDirective } from "./mathTypeQuestion.directive";
import { MathService } from "./math.service";

@NgModule({
  declarations: [MathDirective, MathTypeQuestionDirective],
  exports: [MathDirective, MathTypeQuestionDirective]
})
export class MathModule {
  constructor(mathService: MathService) {
  }


  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: MathModule,
      providers: [{provide: MathService, useClass: MathService}]
    };
  }
}
