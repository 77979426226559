<h2 mat-dialog-title class="title-page-global text-center">{{data.status == 'add' ? 'Thêm tài khoản' : 'Chỉnh sửa thông tin người dùng'}}
</h2>
<div class="show-loading"
     *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<form [formGroup]="infoUser" (ngSubmit)="onSubmit(infoUser.value)">
    <mat-dialog-content class="mat-typography">

        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="img-module" (click)="uploadImage()">
                <img [src]="(infoUser.get('logo').value != 'ImageDefault' && infoUser.get('logo').value != null) ? infoUser.get('logo').value : 'assets/images/png/image-default.jpg'" class="avatar">
                <div class="box-image"><label><mat-icon >photo library</mat-icon><span>Thay đổi ảnh </span></label></div>
                <input type="file" class="avatar-file" accept=".png, .jpg, .jpeg" name="avatar" hidden id="create-post-avatar" (change)="onFileChange($event)">
            </div>
        </div>

        <mat-expansion-panel [expanded]="true"  class="panel-content-global mat-elevation-z">
            <mat-expansion-panel-header class="panel-header col-log-6" style="margin-left: 0; padding-left: 0">
                <mat-panel-title class="panel-title">Thông tin cơ bản</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tên người dùng <span class="text-danger">(*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="fullName" type="text" class="form-control input-field-global" placeholder="Nhập tên người dùng">
                        <mat-error *ngFor="let validation of messageValidate.fullName">
                            <p class="error-message" *ngIf="infoUser.get('fullName').hasError(validation.type) &&
                            (infoUser.get('fullName').dirty || infoUser.get('fullName').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global" [style]="{opacity: data.status !== 'add' ? 0.6: 1}"  style="">Mã <span class="text-danger">(*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input [style]="{opacity: data.status !== 'add' ? 0.6: 1}" formControlName="userCode" type="text" class="form-control input-field-global" placeholder="Nhập mã tài khoản" [readOnly]="data.status !== 'add'" style="background-color: #F2F3F8">
                        <mat-error *ngFor="let validation of messageValidate.userCode">
                            <p class="error-message" *ngIf="infoUser.get('userCode').hasError(validation.type) &&
                            (infoUser.get('userCode').dirty || infoUser.get('userCode').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Ngày sinh</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="birthday" readonly type="text" class="form-control input-field-global" placeholder="Chọn ngày sinh" [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker" style="position: relative; float: right; bottom: 40px;"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Giới tính</label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="gender">
                            <option value="1">Nam</option>
                            <option value="2">Nữ</option>
                        </select>
                        <mat-error *ngFor="let validation of messageValidate.gender">
                            <p class="error-message" *ngIf="infoUser.get('gender').hasError(validation.type) &&
                            (infoUser.get('gender').dirty || infoUser.get('gender').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Email <span class="text-danger">(*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="email" type="email" class="form-control input-field-global" placeholder="Nhập email" email>
                        <mat-error *ngFor="let validation of messageValidate.email">
                            <p class="error-message" *ngIf="infoUser.get('email').hasError(validation.type) &&
                            (infoUser.get('email').dirty || infoUser.get('email').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global" [style]="{opacity: data.status !== 'add' ? 0.6: 1}">Tài khoản <span class="text-danger">(*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input [style]="{opacity: data.status !== 'add' ? 0.6: 1}" formControlName="userName" type="text" class="form-control input-field-global"
                               placeholder="Tên tài khoản" [readOnly]="data.status !== 'add'" style="background-color: #F2F3F8">
                        <mat-error *ngFor="let validation of messageValidate.userName">
                            <p class="error-message" *ngIf="infoUser.get('userName').hasError(validation.type) &&
                            (infoUser.get('userName').dirty || infoUser.get('userName').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Số điện thoại <span class="text-danger">(*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="phone" type="text" class="form-control input-field-global"
                               placeholder="Nhập số điện thoại">
                        <mat-error *ngFor="let validation of messageValidate.phone">
                            <p class="error-message" *ngIf="infoUser.get('phone').hasError(validation.type) &&
                            (infoUser.get('phone').dirty || infoUser.get('phone').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global" *ngIf="data.status == 'add'">
                    <label class="col-md-3 offset-1 lb-global">Mật khẩu <span class="text-danger">(*)</span></label>
                    <div class="col-md-8 pl-0 pr-0">
                        <input formControlName="password" type="password" #password
                               class="form-control input-field-global"
                               placeholder="Nhập mật khẩu">
                        <img *ngIf="isIconEye==false" src="assets/images/svg/eye-show.svg" alt="" class="icon-eye-show"
                             (click)="showPassword(password)">
                        <img *ngIf="isIconEye==true" src="assets/images/svg/eye-hide.svg" alt="" class="icon-eye-show"
                             (click)="showPassword(password)">
                        <mat-error *ngFor="let validation of messageValidate.password">
                            <p class="error-message" *ngIf="infoUser.get('password').hasError(validation.type) &&
                            (infoUser.get('password').dirty || infoUser.get('password').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Tỉnh/thành phố </label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select #valueCity class="form-control input-field-global" formControlName="cityId" (change)="changeDistrictByCity(valueCity.value, true)">
                            <option value="">Chọn tỉnh/thành phố</option>
                            <option *ngFor="let c of city" value="{{c.code}}">{{c.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-6 ml-auto row row-global">
                    <label class="col-md-3 offset-1 lb-global">Quận/huyện </label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select #valueDistrict class="form-control input-field-global" formControlName="district" (change)="changeWardByDistrict(valueDistrict.value)">
                            <option value="">Chọn quận/huyện</option>
                            <option *ngFor="let d of district" value="{{d.code}}" >{{d.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Phường/xã </label>
                    <div class="col-md-8 pl-0 pr-0">
                        <select class="form-control input-field-global" formControlName="ward">
                            <option value="">Chọn phường/xã</option>
                            <option *ngFor="let w of ward" value="{{w.code}}" >{{w.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" fxLayout="row">
                <div class="col-md-12">
                    <label class="lb-global" style="width: 12%">Địa chỉ </label>
                    <div fxFlex class="form-group" style="width: 90%">
                        <textarea class="form-control input-field-global" formControlName="address"></textarea>
                        <mat-error *ngFor="let validation of messageValidate.address">
                            <p class="error-message" *ngIf="infoUser.get('address').hasError(validation.type) &&
                            (infoUser.get('address').dirty || infoUser.get('address').touched || isValidate == true)">{{validation.message}}</p>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 row row-global">
                    <label class="col-md-3 lb-global">Trạng thái</label>
                    <div class="col-md-4 pl-0 pr-0">
                        <select [ngStyle]="{background: colorStatus}" class="form-control custom-status" (change)="getColorStatus($event)" formControlName="userStatus">
                            <ng-container *ngFor="let status of statusUser">
                                <option [value]="status?.id">{{status?.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close mat-raised-button class="btn custom-btn custom-btn-cancel">Hủy</button>
        <button mat-button type="submit" class="btn custom-btn custom-btn-add ml-2 px-4" [disabled]="infoUser.invalid"> {{data.status == 'add' ? 'Thêm' : 'Lưu'}}</button>
    </mat-dialog-actions>
</form>


