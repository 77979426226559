export const MODULE_PERMISSION = {
    perm1: "core_admin_modules",
    perm2: "core_admin_module_add",
    perm3: "core_admin_module_detail",
    perm4: "core_admin_module_edit",
    perm5: "core_admin_module_delete",
    
    perm6: "core_admin_module_tenants",
    perm7: "core_admin_module_tenant_add",
    perm8: "core_admin_module_tenant_delete",

    perm9: "core_admin_module_permissions",
    perm10: "core_admin_module_permission_add",
    perm11: "core_admin_module_permission_edit",
    perm12: "core_admin_module_permission_delete",
}




