import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserPersonalService} from '@app/_services/user-personal.service';

import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/_modules/accounts-manager/dialogs/dialog-from-account/dialog-form-account.component';

@Component({
  selector: 'app-tab-manipulation-history',
  templateUrl: './tab-manipulation-history.component.html',
  styleUrls: ['./tab-manipulation-history.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TabManipulationHistoryComponent implements OnInit, OnChanges {
  @Input() typeTime = 0;
  @Input() change;
  @Input() valueSearch;
  @Input() dataFilter = null;
  displayedColumns: string[] = ['stt', 'time', 'action', 'ip', 'url', 'user_agent'];
  pageSize = 15;
  pageIndex = 0;
  totalRecord = 0;
  dataSource: any;
  constructor( private userPersonalService: UserPersonalService) { }

  ngOnInit(): void {
    this.getDataLogUser();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.change > 0 ){
      this.pageIndex = 0;
      this.getDataLogUser(this.valueSearch);
    }
  }
  getDataLogUser(search?: string){
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.userPersonalService.getAllManipulationHistory(this.pageSize, this.pageIndex, user, search, this.dataFilter.startDate, this.dataFilter.toDate).subscribe((ref: any) => {
      this.dataSource = ref.data.data;
      this.dataSource.forEach( function(element){
        const time = new  Date(element.createAtAction * 1000);
        element.time = _moment(time).format('HH:mm');
        element.date = _moment(time).format('DD/MM/YYYY');
      });
      this.totalRecord = ref.data.totalItems;
    });
  }
  onChangePaginate(event){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getDataLogUser(this.valueSearch);
  }
}
