<div fxLayout="column" class="bg-content-global">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="space-between none">
        <div>
            <h4 class="title-page-global">Thông báo</h4>
        </div>
        <div>
            <input #search (focusout)="filterNotification(search.value);" type="text" id="search-value" class="text-left btn btn-default button-search
                    {{showSearch == true ? 'transition-search-show': ''}}" placeholder="{{showSearch == true ? 'Tìm kiếm' : ''}}" (keydown)="checkText()">
            <img src="assets/images/svg/search.svg" alt="" class="icon-search {{ showSearch ? 'active_filter' : '' }}" (click)="toggleClass(search.value, check)">
            <button class="btn btn-default btn-filter">
                <img src="assets/images/svg/filter_img.svg" alt="" class="{{ showFilter ? 'active_filter' : '' }}" (click)="showFilterClick()">
            </button>
        </div>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="end start">
        <div fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="showFilter" fxFlex class="date-time-range">
            <div fxFlex="70%" fxLayout="row" fxLayoutAlign="flex-end center" *ngIf="filter === 4">
                <span style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;color: #000000;margin-right: 12px;">Từ </span>&nbsp;
                <input readonly type="text" class="mr-2 btn-date-time" [matDatepicker]="pickerFrom" [value]="date" [(ngModel)]="customFromDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
                <span style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;color: #000000;margin-right: 12px;">Đến </span>&nbsp;
                <input readonly type="text" class="mr-2 btn-date-time input-field-global" [matDatepicker]="pickerTo" [value]="date" [(ngModel)]="customToDate">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </div>
            <select class="btn btn-default mr-2 btn-filter-option" (change)="changeFilter($event)">
                <option value="1" selected>Hôm nay</option>
                <option value="2">Tuần này</option>
                <option value="3">Tháng này</option>
                <option value="4">Khác</option>
            </select>
            <button class="btn btn-default btn-filter-option-action" (click)="clickBtnFilterNotification(search.value);">Lọc</button>
        </div>
    </div>
    <div fxFlex class="tab-group" fxLayout="column" style="overflow-y: auto">
        <div fxFlex="50%" fxLayout="column">
            <div fxFlex="30px" fxLayout="row" fxLayoutAlign="space-between none">
                <p class="dash-board-today ml-2">Hôm nay</p>
                <span class="mr-2">Đánh dấu tất cả là đã đọc</span>
            </div>
            <div fxFlex *ngIf="array">
                <mat-card class="dash-board-item-noti-user mt-1 mat-elevation-z" *ngFor="let card of array" [ngClass]="card.status == 0 ? 'not-read' : ''">
                    <mat-card-header>
                        <img mat-card-avatar [src]="card.featuredImage" class="mt-3 ml-2">
                        <mat-card-title class="ml-1 mt-2"><span class="item-noti-user-title">{{card.postTitle}}</span></mat-card-title>
                        <mat-card-subtitle class="ml-1 mt-1">
                            <span class="item-noti-user-content">{{card.shortDescription}}</span>
                            <span class="item-noti-user-profile mt-4" fxLayout="row" fxLayoutAlign="space-between none">
                                <span>Từ: {{card.fullName}} ({{ card.userName }})</span>
                            <span>Đã nhận: {{card.receivedAt | date : "dd/MM/yyyy"}}</span>
                            </span>
                        </mat-card-subtitle>
                    </mat-card-header>
                </mat-card>
            </div>
        </div>
        <div fxFlex="50%">

        </div>
    </div>
</div>