import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-dialog-change-active',
  templateUrl: './dialog-change-active.component.html',
  styleUrls: ['./dialog-change-active.component.scss']
})
export class DialogChangeActiveComponent implements OnInit {
  data_change_active:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<DialogChangeActiveComponent>) { }

  ngOnInit(): void {
    this.data_change_active = this.data;
  }
  confirm(){
    this.dialogRef.close(true);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
