import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdministrativeUnitService } from '@app/_services/administrative-unit.service';

@Component({
  selector: 'app-dialog-move-administrative-lv3',
  templateUrl: './dialog-move-administrative-lv3.component.html',
  styleUrls: ['./dialog-move-administrative-lv3.component.scss']
})
export class DialogMoveAdministrativeLv3Component implements OnInit {
  data_move_adminstrative:any;
  listLocationLv1:any;
  listLocationLv2:any;
  moveId:any;
  moveId2:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRes: MatDialogRef<DialogMoveAdministrativeLv3Component>,
    private administrativeUnitService:AdministrativeUnitService
  ) { }

  ngOnInit(): void {
    this.getDataLocationLv1();
    this.data_move_adminstrative = this.data;
  }

  selectOption(value:any){
    this.moveId = value;
    this.getDataLocationLv2(this.moveId);
  }

  selectOption2(value:any){
    this.moveId2 = value;
  }

  confirm(){
    let data = {
      id: this.data_move_adminstrative.data.id,
      cityId: this.moveId,
      districtId: this.moveId2,
      type: 3
    }
    this.administrativeUnitService.moveLocation(data).subscribe(
      (res:any)=>{
        if(res.status == 1){
          this.dialogRes.close(true);
        }else{
          this.dialogRes.close(false);
        }
      },
      (err)=>{
        this.dialogRes.close(false);
      }
    )
  }

  getDataLocationLv1(){
    this.administrativeUnitService.getLocationLv1().subscribe(
      (res:any)=>{
        this.listLocationLv1 = res.data;
        this.moveId = res.data[0].id
      },
      (err)=>{

      }
    )
  }

  getDataLocationLv2(id:any){
    this.administrativeUnitService.getLocationLv2(id).subscribe(
      (res:any)=>{
        this.listLocationLv2 = res.data;
        this.moveId2 = res.data[0].id
      },
      (err)=>{

      }
    )
  }
}
