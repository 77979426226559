import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ForgotPasswordService} from '@app/_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShowMessage} from '@app/_services';
import {log} from 'util';
import * as moment from 'moment';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public type = 'password';
    public message: string;
    iconEyeNewPass = false;
    iconEyeRepeatPass = false;
    checkValidate = false;
    userId: string;
    username: string;
    validate = {
        /**0 hidden, 2 fail, 1 success**/
        is_repeat_password: 0,
        is_repeat_password_required: 0,
        verify_account: {
            is_required: 0,
            is_min_length: 0,
            is_max_length: false,
            is_chart: 0,
            is_number: 0,
        }
    };
    isRepeatPass = 0;
    public verifyAccount = this.fb.group({
        verify_account: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(/^(?=.*\d)(?=.*[a-zA-Z]).{6,50}$/)]],
        repeat_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(/^(?=.*\d)(?=.*[a-zA-Z]).{6,50}$/)]]
    });

    constructor(
        private fb: FormBuilder,
        private route: Router,
        private forgotPasswordService: ForgotPasswordService,
        private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private showMessage: ShowMessage,
    ) {
    }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe((params: any) => this.userId = params.params.id);
    }

    checkChangePassword(){
        this.forgotPasswordService.checkChangePassword(this.userId).subscribe((res: any) => {
            if (res.status === 0){
                return this.route.navigate(['setup-new-password', this.userId], { queryParams: {username: this.username}});
            }
        });
    }

    confirmPassword(data) {
        if (this.verifyAccount.invalid) {
            return this.checkValidate = true;
        }

        let dataPost: (any) ={
            userId: this.userId,
            password: data.verify_account,
            confirmedPassword: data.repeat_password,
        };

        if (data.verify_account !== data.repeat_password) {
            return this.isRepeatPass == 1;
        }
        this.forgotPasswordService.savePassword(dataPost).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    this.showMessage.success(res.msg);
                    return this.route.navigate(['login']);
                }
                this.showMessage.error(res.msg);
            },
            (error: any) => {
                this.showMessage.error(error);
            });
    }


    checkRepeatPassword(repeat_password: string) {
        if (repeat_password == '' || repeat_password.trim() === '') {
            this.validate.is_repeat_password = 0;
            this.validate.is_repeat_password_required = 1;
        }else  this.validate.is_repeat_password_required = 0;

        if (repeat_password === this.verifyAccount.value.verify_account) {
            return this.validate.is_repeat_password = 2;
        }

        return this.validate.is_repeat_password = 1;

    }

    checkNewPassword(password: string) {
        let regPatternNumber = password.toLowerCase().match(/(\d)/g);
        let regPatternChart = password.toLowerCase().match(/([a-z])/g);

        if (password.length === 0 || password.trim() === '')this.validate.verify_account.is_required = 2;
        else  this.validate.verify_account.is_required = 1;

        if (password.length >= 6) {
            this.validate.verify_account.is_min_length = 1;
        } else {
            this.validate.verify_account.is_min_length = 2;
        }

        if (password.length > 50) {
            this.validate.verify_account.is_max_length = true;
        } else {
            this.validate.verify_account.is_max_length = false;
        }


        if (regPatternChart !== null && regPatternChart.length > 0) {
            this.validate.verify_account.is_chart = 1;
        } else {
            this.validate.verify_account.is_chart = 2;
        }

        if (regPatternNumber !== null && regPatternNumber.length > 0) {
            this.validate.verify_account.is_number = 1;
        } else {
            this.validate.verify_account.is_number = 2;
        }

        if (password === this.verifyAccount.value.repeat_password && this.verifyAccount.value.repeat_password != '') {
            this.validate.is_repeat_password = 2;
        }

        if (password !== this.verifyAccount.value.repeat_password && this.verifyAccount.value.repeat_password != '') {
            this.validate.is_repeat_password = 1;
        }

    }

    showPassword(isButton: string, element) {
        if (element.type === 'password') {
            if (isButton == 'verify_account') {
                this.iconEyeNewPass = true;
            } else {
                this.iconEyeRepeatPass = true;
            }
            element.type = 'text';
        } else {

            if (isButton == 'verify_account') {
                this.iconEyeNewPass = false;
            } else {
                this.iconEyeRepeatPass = false;
            }
            element.type = 'password';
        }
    }
}
