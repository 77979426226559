<div fxLayout="column" style="height: 100%">
    <div fxFlex="40px" fxLayout="row" fxLayoutAlign="end start">
        <input [(ngModel)]="keyword" #search (keypress)="onKeyPressSearch($event)" (blur)="onBlur($event)" class="input-field-global" style="height: 40px; width: 55%" placeholder="Tên hoặc mã năm học"><span class="icon-search"><mat-icon>search</mat-icon></span>
        <ng-template [ngxPermissionsOnly]="[TENANT_PERMISSION.perm13]">

        <button class="btn-add-global ml-1 mr-5 mat-elevation-z" mat-raised-button (click)="showFormEditSchoolYear()"><img class="mr-2 mb-1" src="assets/images/icons/icon-21.svg"/>Chỉnh sửa</button>
            </ng-template>
    </div>
    <div fxFlex class="content-data">
        <table mat-table [dataSource]="dataSource" class="table-el">
            <ng-container matColumnDef="stt">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">STT</th>
                <td mat-cell *matCellDef="let item;let i = index">{{ i+1 }}</td>
            </ng-container>
            <ng-container matColumnDef="schoolYear">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Tên năm học</th>
                <td mat-cell *matCellDef="let item" class="text-important-table-global">{{item.fullname}}</td>
            </ng-container>
            <ng-container matColumnDef="codeSchoolYear">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Mã năm học</th>
                <td mat-cell *matCellDef="let item" >{{item.code}}</td>
            </ng-container>
            <ng-container matColumnDef="startSchoolYear">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Bắt đầu</th>
                <td mat-cell *matCellDef="let item">{{item.startDate | parseTime: "DD/MM/YYYY"}}</td>
            </ng-container>
            <ng-container matColumnDef="endSchoolYear">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">Kết thúc</th>
                <td mat-cell *matCellDef="let item">{{item.endDate | parseTime: "DD/MM/YYYY"}}</td>
            </ng-container>
            <ng-container matColumnDef="tool">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">Công cụ</th>
                <td style="text-align: center;" mat-cell *matCellDef="let item">
                    <img src="assets/images/icons/icon-22.svg" style="cursor: pointer" (click)="confirmDelete(item)">
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataColumn"></tr>
            <tr mat-row *matRowDef="let row, columns: dataColumn"></tr>
        </table>
        <ng-container *ngIf="length > pageSize">
            <mat-paginator appStylePaginator showFirstLastButtons [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" class="table-paginate-global"></mat-paginator>
        </ng-container>
    </div>
</div>
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
</div>