import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PermissionManagerService} from '@app/_services';

@Component({
  selector: 'app-dialog-form-add-edit-permission',
  templateUrl: './dialog-form-add-edit-permission.component.html',
  styleUrls: ['./dialog-form-add-edit-permission.component.scss'],
  // providers: [
  //   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  //   {provide: MAT_DATE_FORMATS, useValue: FORMAT_DATE},
  // ]
})
export class DialogFormAddEditPermissionComponent implements OnInit {
  permissionForm: FormGroup;
  permission: any;
  action: string;
  modules: any;
  checkValidate: boolean = false;
  checkCode: string;
  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data, private permissionsService: PermissionManagerService,
              private dialogRes: MatDialogRef<DialogFormAddEditPermissionComponent>) {
  }
  ngOnInit(): void {
    this.action = this.data.action;
    this.permission = this.data.item ? this.data.item : '';
    // this.permission.module_id = this.data.item ? this.permission.module.id : '';
    // this.permission.module = 0;
    this.permissionForm = this.fb.group({
      fullname: [this.permission.fullname, Validators.required],
      code: [this.permission.code, Validators.required],
      index_order: this.permission.index_order,
      module_id: [this.permission.module_id, Validators.required],
      description: [this.permission.description, Validators.required],
      is_active: this.permission.is_active,
    });
    this.listAllModule();
  }

  permissionValidate = {
      'fullname': [{type: 'required', message: 'Vui lòng nhập tên quyền'}],
      'code': [{type: 'required', message: 'Vui lòng nhập tên quyền'}],
      'module_id': [{type: 'required', message: 'Vui lòng nhập tên quyền'}],
      'description': [{type: 'required', message: 'Vui lòng nhập tên quyền'}],
  };
  listAllModule(){
      return this.permissionsService.getAllModule().subscribe((ref: any) => {
      this.modules = ref.data.modules;
    });
  }
  closeDialog(){
   this.dialogRes.close();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  submitPermission(validate: boolean, value: any ){
    if (!validate){
      this.checkValidate = true;
      return false;
    }
    if (this.data.action === 'add'){
      value.is_active = value.is_active ? 1 : 0;
      this.permissionsService.addPermission(value).subscribe(
          (ref: any) => {
            this.dialogRes.close(ref);
          },
          (error: any) => {
            this.checkCode = error.http_status_code === 409 ? error.message : '';
          }
      );
    }
    else{
        value.is_active = value.is_active ? 1 : 0;
        this.permissionsService.editPermission(value, this.data.item.id).subscribe(
          (ref: any) => {
            this.dialogRes.close(ref);
          },
          (error: any) => {
            this.checkCode = error.http_status_code === 409 ? error.message : '';
          }
      );
    }
  }
}
