import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '@app/_shared/dialogs/delete/dialog-delete.component';
import {AccountManagerService, ListenFirebaseService, ShowMessage} from '@app/_services';

import {Observable, Subscriber} from 'rxjs';
import {TIME_OUT_LISTEN_FIREBASE} from '@app/_shared/utils/constant';
/** Flat node with expandable and level information */
import { NgxPermissionsService } from 'ngx-permissions';
import { USER_PERMISSION } from "../../../constants";

@Component({
  selector: 'app-decentralization-applies',
  templateUrl: './decentralization-applies.component.html',
  styleUrls: ['./decentralization-applies.component.scss']
})
export class DecentralizationAppliesComponent implements OnInit, OnChanges{
  dataColumn = ['stt', 'nameRole', 'codeRole', 'permission', 'statusRole'];
  @Output() pageIndex = new EventEmitter<any>();
  dataSource: any;
  totalRecord = 0;
  USER_PERMISSION = USER_PERMISSION;
  @Input() userId;
  @Input() dataRoles;
  @Output() roleIds = new EventEmitter<any>();
  @Output() checkRemoveRole = new EventEmitter<boolean>();
  isLoading = false;
  typeModule = 'user';
  constructor(
    private dialog: MatDialog,
    private accountManagerService: AccountManagerService,
    private listenFirebaseService: ListenFirebaseService,
    private showMessage: ShowMessage,
    private permissionsService: NgxPermissionsService
    ) { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = this.dataRoles?.data;
    this.totalRecord = this.dataRoles?.totalItems;
    if (this.permissionsService.getPermission(this.USER_PERMISSION.perm15)
        ){
          this.dataColumn.push('tool');
        }
  }

  confirmDelete(item) {
    const dialogDelete = this.dialog.open(DialogDeleteComponent, {
      height: 'auto',
      width: '40%',
      data: {
        title: 'Bạn đang thực hiện xoá phân quyền ra khỏi danh sách quản lí',
        message: 'Bạn có chắc muốn xoá phân quyền này không ?',
        btnOkText: 'XÁC NHẬN',
        btnCancelText: 'HỦY',
        data: {
          name: item.roleName,
        },
      }
    });

    dialogDelete.afterClosed().subscribe(dialogResult => {
      if (dialogResult){
        this.isLoading = true;
        this.accountManagerService.removeRoleFromUser(this.userId, item.roleId).subscribe((res: any) => {
          if (res.status === 0) {
            this.isLoading = false;
            return this.showMessage.error(res.msg);
          }

        }, error => this.isLoading = false);

        setTimeout(() => {
          if (this.isLoading) {
            this.isLoading = false;
            this.checkRemoveRole.emit(true);
          }
        }, TIME_OUT_LISTEN_FIREBASE);

        const listenFb = new Observable((subscriber: Subscriber<any>) => {
          this.listenFirebaseService.checkFireBase('remove_role_from_user', this.typeModule, subscriber);
        });

        listenFb.subscribe((refg) => {
          this.isLoading = false;
          if (refg) {
            this.checkRemoveRole.emit(true);
          }
        });

      }
    });
  }
}
