<div fxLayout="column" class="main-container container-fluid">
    <div fxFlex="10">
        <div class="row mt-3">
            <div class="col-lg-4">
                <h4 class="title-page-global">Chi tiết phân quyền quản trị hệ thống</h4>
            </div>
            <div class="col-lg-5"></div>
            <div class="col-lg-3">
                <button class="btn float-right btn-add-global" mat-raised-button (click)="showFormPermission(permission)"><mat-icon>edit</mat-icon>Chỉnh sửa quyền</button>
            </div>
        </div>
    </div>
    <div fxFlex class="bg-white rounded" fxLayout="row">
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-between" >
            <div>
                <p class="mt-5 text-center h2-role-manager">{{permission?.fullname}}</p>
                <div class="form-group text-center mt-3">
                    <mat-checkbox [checked]="permission?.is_active == 1 ? true : false" class="mr-1" ></mat-checkbox>
                    <span class="text-active">Kích hoạt</span>
                </div>
            </div>
            <div>
                <a class="row text-dark text-center ml-3 mb-2 label-tab-permission-delete">Xóa quyền</a>
            </div>
        </div>
        <div fxFlex="80" class="verticalLine mt-2 mb-2 col-lg-9">
            <mat-expansion-panel class="bg-danger" class="panel-content" [expanded]="true">
                <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title pt-3">
                        <p class="title-tab">Thông tin quyền</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-1">
                    <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 title-tab-label">Tên quyền</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.fullname}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="col-lg-6 form-group row">
                        <div class="col-lg-1"></div>
                        <label class="col-lg-3 title-tab-label ">Mã quyền</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.code}}">
                        </div>
                    </div>
                    <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 title-tab-label">Thứ tự sắp xếp</label>
                        <div class="col-lg-8">
                            <input onlyNumber (keypress)="numberOnly($event)" type="number" min="1" disabled class="form-control input-field-global" value="{{permission?.index_order}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="col-lg-6 form-group row">
                        <div class="col-lg-1"></div>
                        <label class="col-lg-3 title-tab-label">Thứ tự sắp xếp</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.index_order}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="bg-danger" class="panel-content" [expanded]="true">
                <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title pt-3">
                        <p class="title-tab">Mô tả quyền</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-1">
                    <div class="col-lg-12 form-group" >
                        <textarea disabled class="form-control input-field-global" rows="4" cols="1000">{{permission?.description}}</textarea>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="bg-danger" class="panel-content">
                <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title pt-3">
                        <p class="title-tab">Thông tin nâng cao</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-1">
                    <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 title-tab-label">Người tạo</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.created_by?.fullname}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 title-tab-label">Ngày tạo</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.created_at}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 title-tab-label">Người cập nhật cuối cùng</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.updated_by?.fullname}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="col-lg-6 form-group row">
                        <label class="col-lg-3 title-tab-label">Thời gian cập nhật lần cuối</label>
                        <div class="col-lg-8">
                            <input type="text" disabled class="form-control input-field-global" value="{{permission?.updated_at}}" >
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>

