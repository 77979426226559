<div fxLayout="column" style="height: 100vh;">
    <div fxFlex="70px">
        <app-header flexLayout="column"></app-header>
    </div>
    <div fxFlex fxLayout="row">
        <div fxFlex="4%" class="sidebar-el">
            <app-sidebar class="custom_sidebar"></app-sidebar>
        </div>
        <div fxFlex="96%" style="background-color: #F2F3F8;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>