<div class="container-fluid" fxLayout="column" fxLayoutAlign="center center">
    <div>
        <p class="text-center text-content-confirm">Bạn đang thực hiện xóa quyền</p>
        <p class="text-center text-content-confirm">Thêm mới người dùng</p>
    </div>
    <div class="mt-4">
        <p class="text-center text-confirm">Xác nhận xóa</p>
    </div>
    <div class="row d-flex justify-content-center mt-3 mb-2">
        <button class="btn btn-cancel mr-2 px-4" (click)="closeDialog()" >Cancel</button>
        <button type="submit" class="btn btn-confirm ml-2 px-4" (click)="deletePermission()">Xóa</button>
    </div>
</div>
<div class="example-loading-shade"
     *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isLoading">
    </div>
</div>
